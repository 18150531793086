import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CertChannel } from './certChannel.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from '../../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class CertChannelService extends ThsBaseService<CertChannel> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-certification-channels`);
  }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}
findAllByDictNo(dictNo: string): Observable<HttpResponse<any[]>>  {
  const dictUrl = '/thsadmin/api/sys-dicts';
  const url = dictUrl + '/findAllByDictNo';
  return this.http.get<any>(`${url}/${dictNo}`, { observe: 'response' });
}

}

