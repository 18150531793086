import { Component, Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimInfoService } from './bim-info.service';
import { BimInfo } from './bim-info.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimInfoEditComponent} from './edit/edit.component';
import { Router } from '@angular/router';
import { WorkManageService } from '../work-manage/work-manage.service';
import * as moment from 'moment';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ProvinceService } from '../provinces/provinces.service';

@Component({
  selector: 'app-bim-biminfo',
  templateUrl: './bim-info-list.component.html',
  styleUrls: ['./bim-info-list.component.less']
})
export class BimInfoListComponent implements OnInit {
 
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimInfo[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];

  isloadding=false;
  public fetchLoading = false;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    // sort固定
     sort: ['startDate,desc']
  };
  accountName: string;
  userProvinceList=[];

  selfProvince = ['西藏', '宁夏', '新疆', '广西', '内蒙古'];
  selfCity = ['北京', '重庆', '天津', '上海'];


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '大赛名称', index: 'name' , width: '30%'},
      // { title: '截图', index: 'imgurl' },
    { title: '生效时间', index: 'startDate', width: '12%',
      format: (item: any) => {
        let str = null;
        if (item.startDate) {
          str = this.formatDate(item.startDate);
        }
        if (item.endDate) {
          str = str + '至' + this.formatDate(item.endDate);
        }
        return str;
      },
    },
    {
      title: '状态', index: 'status', className: 'text-center', width: '8%',
      format: (item: any) => {
        if (item.status === 1) {
          return '发布';
        } else if (item.status === 2) {
          return '关闭';
        }else {
          return '草稿';
        }
      },
      filters: [
        { text: '发布', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '草稿', value: 0 } ,
        { text: '关闭', value: 2 },  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    // { title: '缩略图片', index: 'pagePhoto', width: '20%'},
    // { title: 'banner图片', index: 'bannerPhoto', width: '20%'},
    {
      title: '操作', className: 'text-center',
      buttons: [
         { text: '编辑',click: (item: any) => { this.edit(item); } },
         { text: '删除', click: (item: any) => { this.remove(item); } },
         { text: '大赛赛项', click: (item: any) => { this.bimInfoEvents(item); } },
         { text: '官方沟通渠道', click: (item: any) => { this.bimInfoQq(item); } },
         { text: '大赛广告位', click: (item: any) => { this.bimInfoAdvertisement(item); } },
         { text: '大赛流程', click: (item: any) => { this.bimInfoProcess(item); } },
         { text: '制度文件', click: (item: any) => { this.bimInfoInstitutional(item); } },
         { text: '联系方式', click: (item: any) => { this.bimInfoContact(item); } },
         { text: '软件下载', click: (item: any) => { this.bimInfoSoft(item); } },

        // { text: '大赛赛项', type: 'modal', component: BimInfoEventsListComponent , paramName: 'record', click: () => this.getDataList(), },
        // { text: '大赛广告位', type: 'modal', component: EduCourseSummerManComponent, paramName: 'record', click: () => this.getDataList(), },
        // { text: '大赛QQ群', type: 'modal', component: CourseAppointStuManComponent,   size: 1000 , paramName: 'record', click: () => this.getDataList(), },
      ],
    }
  ];

  constructor(
    private router: Router,
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimInfoService: BimInfoService,
    public provinceService: ProvinceService,
    public workManageService: WorkManageService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.accountName = tokenService.get().account_name;
  }

  ngOnInit() {
    if(this.accountName === 'admin') {
      this.getDataList();
    } else {
      this.getUserProvinces();
    }
  }


  /**
   * 获取用户地区信息
   */
    getUserProvinces(){
      this.provinceService.getUserProvinces().subscribe((res: any) => {
          res.body.forEach(element => {
            const name = element.provinceName;
            if (this.selfProvince.includes(name)) {
              this.userProvinceList.push(name);
            } else if (this.selfCity.includes(name)) {
              this.userProvinceList.push(name + '市');
            } else {
              this.userProvinceList.push(name + '省');
            }
          });
          this.getDataList();
      });
    }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    if (this.userProvinceList && this.userProvinceList.length > 0) {
      copyParams['province.in'] = this.userProvinceList;
    }
    copyParams['isDelete.equals'] = 0;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.bimInfoService.query(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;

      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(BimInfoEditComponent, { record: { id: null }, isAdd: true, userProvinceList: this.userProvinceList  })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }




  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit(item) {
    this.modal
      .static(BimInfoEditComponent, { record: item,isAdd: false, userProvinceList: this.userProvinceList  })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  bimInfoEvents(res:any){
   // this.router.navigateByUrl('/business/bim/bim-info-events-list?BimInfoId=' + res.id);
    this.router.navigate(['/business/bim/bim-info-events-list'], {queryParams: {bimInfoId:res.id, bimInfoName:res.name, type: res.type}});
  }

  bimInfoQq(res:any){
   // this.router.navigateByUrl('/business/bim/bim-info-events-list?BimInfoId=' + res.id);
    this.router.navigate(['/business/bim/bim-info-qq-list'], {queryParams: {bimInfoId:res.id, bimInfoName:res.name}});
  }

  bimInfoAdvertisement(res:any){
   // this.router.navigateByUrl('/business/bim/bim-info-events-list?BimInfoId=' + res.id);
    this.router.navigate(['/business/bim/bim-info-advertisement-list'], {queryParams: {bimInfoId:res.id, bimInfoName:res.name}});
  }

  bimInfoProcess(item) {
    this.router.navigate(['/business/bim/bim-info-process-list'], {queryParams: {bimInfoId: item.id, bimInfoName: item.name}});
  }

  bimInfoInstitutional(item) {
    this.router.navigate(['/business/bim/bim-info-institutional'], {queryParams: {bimInfoId: item.id, bimInfoName: item.name}});
  }

  /**
   * 联系方式
   * @param item 
   */
  bimInfoContact(item){
    this.router.navigate(['/business/bim/bim-info-contact'], {queryParams: {bimInfoId: item.id, bimInfoName: item.name}});
  }

  /**
   * 软件下载
   * @param item 
   */
  bimInfoSoft(item){
    this.router.navigate(['/business/bim/bim-info-soft'], {queryParams: {bimInfoId: item.id, bimInfoName: item.name}});
  }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  /**
  * 删除
  */
  remove(res: any) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        res.isDelete = 1;
        this.bimInfoService.update(res).subscribe(() => {
          this.getDataList();
          this.st.clearCheck();
        });
        // this.bimInfoService.delete(res.id)
        //   .subscribe(() => {
        //     this.getDataList();
        //     this.st.clearCheck();
        //   });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimInfoService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


  searche(){
    this.page = 0;
    this.getDataList();
  }

  fetchWorkFileLocal() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要获取的大赛！');
    if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据！');
    this.fetchLoading = true;
    const selData = this.selectedRows[0];
    this.workManageService.downWorkFileLocal({
      eventType: '0',
      bimInfoId: selData.id
    }).subscribe(res => {
      this.fetchLoading = false;
      const data = res.body;
      this.msg.success(data['data']);
    });
  }

  formatDate(str: string) {
    return moment(str).format('YYYY/MM/DD');
  }

}
