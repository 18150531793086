import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { User } from './user.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class UserService extends ThsBaseService<User > {
  constructor(http: HttpClient , httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-users`);
  }

  hasUserAccount(userAccount: string): Observable<any> {
    const url = `${this.resourceUrl}/hasUserAccount/${userAccount}`;
    return this.http.get(url);
  }

  updateMultiTenancyIdByEntityId(entityId: string, multiTenancyId: string): Observable<any> {
    const url = `${this.resourceUrl}/updateMultiTenancyId/${entityId}/${multiTenancyId}`;
    return this.http.get(url);
  }

}

