import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CourseAppointStuManService extends ThsBaseService<any> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-course-allow-students`);

  }

  queryCourseAllowStudent(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getCourseAllowStudent`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }

  querySelectiveStudent(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getCourseSelectiveStudent`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }

saveStudentInfo(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/saveStudentInfo`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
}

