import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkdetailsComponent } from './workdetails/workdetails.component';
import { ExamdetailsComponent } from './examdetails/examdetails.component';

const routes: Routes = [
  {
    path: '',
    children: [
       { path: 'detailst/workdetails', component: WorkdetailsComponent },
       { path: 'detailst/examdetails',  component: ExamdetailsComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DetailstRoutingModule { }
