import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import {  _HttpClient } from '@delon/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpParams, HttpResponse, HttpRequest } from '@angular/common/http';
import { CertExamineManageService } from '../certExamineTime.service';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-certExamineMan-edit',
  templateUrl: './edit.component.html',
})
export class EduCertExamineManageEditComponent implements OnInit {
  form: FormGroup;
  record: any = {};
  submitting = false;
  resourceType = '';

  // 字典类型 - 树形下拉
  viewType = '1';
  // 字典编码
  dictNo = 'BIMAutodeskAuth';

  authbool = false;
  examineSubject = '';
  authProduct = '';
  authGrade = '';
  type = '';
  certChannelType = [];
  constructor(
    private modal: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private certExamineManageService: CertExamineManageService,
    private dictDetailService: DictDetailService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      eduCertChannelId: [null, []],
      examBatch: [null, [Validators.required]],
      registDeadline: [null, []],
      examTime: [null, []],
      announceTime: [null, []],
      isOk: ['1', [Validators.required]],
      authId: [null, []],
      chargeAmount: [null, [Validators.required]],
    });
    this.getCertChannelType();
    if (this.record.id != null && this.record.id !== '') {
      this.certExamineManageService.find(this.record.id).subscribe(res => {
        this.record = res.body;
        this.resourceType = res.body.authId;
         this.examineSubject = res.body.examineSubject ;
        this.authProduct = res.body.authProduct;
        this.authGrade = res.body.authGrade;
        this.authbool = true;
        this.form = this.createGroup();
      });
    }
  }

  createGroup() {
    const group = this.fb.group({});
    // tslint:disable-next-line:forin
    for (const key in this.record) {
      group.addControl(key, this.fb.control(this.record[key]));
    }
    return group;
  }

  getCertChannelType(queryParams?: any) {
    this.certExamineManageService.findAllChannel().subscribe(res => {
      const list =  res.body;
          if (list && list.length) {
            list.forEach(element => {
              this.certChannelType.push({ label: element.title, value: element.id });
            });
      }
    });
    return this.certChannelType;
  }

  submit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    const eduCertChannelId = this.form.value.eduCertChannelId;
    console.log(this.form.value);
    if (!eduCertChannelId) {
      this.msg.error('认证类型未选择，请检查');
      return ;
    }
    const examBatch = this.form.value.examBatch;
    if (!examBatch) {
      this.msg.error('考试批次未填写，请检查');
      return ;
    }
    const registDeadline = this.form.value.registDeadline;
    if (!registDeadline) {
      this.msg.error('报名截止时间未填写，请检查');
      return ;
    }
    const examTime = this.form.value.examTime;
    if (!examTime) {
      this.msg.error('考试时间未填写，请检查');
      return ;
    }
    const announceTime = this.form.value.announceTime;
    if (!announceTime) {
      this.msg.error('成绩公布时间未填写，请检查');
      return ;
    }
    const chargeAmount = this.form.value.chargeAmount;
    if (!chargeAmount) {
      this.msg.error('收费金额未填写，请检查');
      return ;
    }

    if (!this.authbool) {
      this.msg.error('考试类型未选择至等级，请检查');
      return ;
    }
    setTimeout(() => {
    }, 2000);
    this.form.value.examineSubject = this.examineSubject;
    this.form.value.authProduct = this.authProduct;
    this.form.value.authGrade = this.authGrade;
    this.form.value.authId = this.resourceType ;
    this.record = Object.assign(this.record, this.form.value);

    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));

  }
  saveResource() {
    const _this_ = this;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
       if (_this_.form.value.id == null) {
        _this_.certExamineManageService.create(_this_.form.value)
          .subscribe(
            (response) => _this_.onSaveSuccess(),
            (res: HttpResponse<any>) => _this_.onError(res.body)
            );
      } else {
        _this_.certExamineManageService.update(_this_.form.value)
          .subscribe((response) => _this_.onSaveSuccess(),
          (res: HttpResponse<any>) => _this_.onError(res.body)
          );
      }
    });
  }

  formatIsOk() {
    if (this.form.value.isOk === true) {
      this.form.value.isOk = '1';
    } else if (this.form.value.isOk === false) {
      this.form.value.isOk = '0';
    }
  }
   /**
   * 保存成功
   * @param response
   */
   onSaveSuccess() {
    this.msg.success('保存成功');
    this.submitting = false;
    this.close(true);
  }
 /**
   * 请求异常
   * @param error
   */
   onError(error) {
    this.msg.create('error', error.message);
    this.submitting = false;
  }

  /**
   * 关闭窗口
   * @param result 传递参数
   */
  close(result: any) {
    this.modal.destroy(result);
  }



  typeChange (obj: any) {
    this.resourceType = obj;
      this.getchangeVal();
  }


  getchangeVal() {
    if (!this.resourceType) {
     this.authbool = false;
      return ;
    }
   this.dictDetailService.find(this.resourceType).subscribe(res => {
    const parentId = res.body.parentId;
    const itemValue2 = res.body.itemValue2;
    if (!!itemValue2) { // 判断是不是最后一级
     this.form.patchValue({chargeAmount: itemValue2});
     this.authGrade = res.body.itemText;
     this.dictDetailService.find(parentId).subscribe(res2 => { // 查询倒数第二级
       const pparentId = res2.body.parentId;
       this.authProduct = res2.body.itemText;
       if (!!pparentId) {
        this.dictDetailService.find(pparentId).subscribe(res3 => { // 查询倒数第三级
          const ppparentId = res3.body.parentId;
          if (!!ppparentId) {
            this.examineSubject = res3.body.itemText;
          }
        });
       }
      });
      this.authbool = true;
    } else {
     this.authbool = false;
      }
    });

   return this.authbool;
  }


}
