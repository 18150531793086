import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {Course } from './course.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
@Injectable({ providedIn: 'root' })
export class CourseService extends ThsBaseService<Course> {
  courseType = [];
  private  dictDetailService: DictDetailService;
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-courses`);

  }
 
  // getTypeFromDict(typeId :string){
  //   this.http.get('/thsadmin/api/sys-dicts/' + typeId, {observe: 'response'}).subscribe((res: HttpResponse<any[]>) => {
  //     this.count = parseInt(res.headers.get('x-total-count'));
  //   });
  // }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}



getRepositoryOfOptionData(keyword?: string): Observable<string[]> {
     return this.http
      .get(`/thsedu/api/teachers/` + keyword)
      .pipe(
        map((resp: any) => {
          const arr = [];
          const list = resp.data;
          if (list && list.length) {
            list.forEach(element => {
              console.log(element.name + ',' + element.college + ',' + element.department);
              arr.push({ label: element.name + '-' + element.college + '-'
              + element.department, value: element.id + '-' + element.name + '-' + element.college });
            });
          }
          return arr;
        }),
      );
      }
      getcourseClassify() {
        const param = {
          'parentId.equals' : '564f16fa-545f-43b5-9f02-8da5eb5ddc8f',
          'isOk.equals' : '1',
          sort: ['orderNum,asc']
         };
         const courseClassify = [];
         this.http.get<DictDetail[]>('/thsadmin/api/sys-dict-details', { params: param, observe: 'response' }).subscribe(res => {
           const list =  res.body;
           if (list && list.length) {
            list.forEach(element => {
              courseClassify.push({ label: element.itemText, value: element.itemValue1 });
            });
          }
           console.log(courseClassify);
         });
         return courseClassify;
       }

       getcourseClassifyChange(any) {
        const param = {
          'parentId.equals' : '564f16fa-545f-43b5-9f02-8da5eb5ddc8f',
          'isOk.equals' : '1',
          sort: ['orderNum,asc']
         };
        // this.courseType = [];
         this.http.get<DictDetail[]>('/thsadmin/api/sys-dict-details', { params: param, observe: 'response' }).subscribe(res => {
           const list =  res.body;
           if (list && list.length) {
            list.forEach(element => {
              this.courseType.push({ label: element.itemText, value: element.itemValue1 });
            });
          }
           console.log(this.courseType);
         });
         return this.courseType;
       }

}

