import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WorkflowService } from '@shared/components/workflow/workflow.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

import { ModalHelper } from '@delon/theme';
import { WFAuditResult } from './workflowform.model';


export interface TreeNodeInterface {
    id: number;
    auditStep: string;
    auditerName: string;
    auditResult: string;
    auditIdea: string;
    auditDate: string;
    auditType: string;
    level: number;
    expand: boolean;
    children?: TreeNodeInterface[];
}
/**
 *工作流通用按钮组件
 *
 * @export
 * @class WorkflowComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'ths-workflow-auditlist-cus',
    templateUrl: './workflowauditlist_cus.component.html',
    styleUrls: ['./workflowauditlist_cus.component.less']
})

export class WorkflowAuditListCusComponent implements OnInit, OnChanges {

    @Input() customAuditList: any[] = []; // 审批意见列表
    auditList: any[] = [];
    @Input() businessId = ''; // 业务ID
    @Input() isContainsTempAuditIdea = false;//是否包含暂存意见
    thingsList: any[] = [];

    wfAuditResult = WFAuditResult;

    constructor(
        public modalService: NzModalService,
        public modalHelper: ModalHelper,
        public message: NzMessageService,
        public http: HttpClient,
        public workflowService: WorkflowService
    ) {
    }

    expandDataCache = {};

    convertTreeToList(root: object): TreeNodeInterface[] {
        const stack = [];
        const array = [];
        const hashMap = {};
        stack.push({ ...root, level: 0, expand: true });
        while (stack.length !== 0) {
            const node = stack.pop();
            this.visitNode(node, hashMap, array);
            if (node.children) {
                for (let i = node.children.length - 1; i >= 0; i--) {
                    stack.push({ ...node.children[i], level: node.level + 1, expand: true, parent: node });
                }
            }
        }

        return array;
    }
    visitNode(node: TreeNodeInterface, hashMap: object, array: TreeNodeInterface[]): void {
        if (!hashMap[node.id]) {
            hashMap[node.id] = true;
            array.push(node);
        }
    }

    ngOnInit(): void {

        this.initData();

    }

    initData() {
        this.workflowService.getThingsListForAuditList(this.businessId).subscribe(
            (res) => {
                this.thingsList = res.body;
                this.thingsList.forEach(item => {
                    item.auditStep = item.stepName;
                    item.auditType = item.stepName;
                    item.auditerName = item.receiver;
                    item.auditResult = '待办';
                    item.auditIdea = '';
                    item.auditDate = '';
                    item.isWfAuditIdea = item.taskId != '' && item.taskId != null && item.taskId != undefined;
                });

                this.getAuditList();
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        // tslint:disable-next-line:forin
        for (let propName in changes) {

            let changedProp = changes[propName];
            let value = changedProp.currentValue
            if (propName == "customAuditList") {
                if (value.length > 0) {
                    this.getAuditList();
                }
            }
        }
    }
    collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
        if ($event === false) {
            if (data.children) {
                data.children.forEach(d => {
                    const target = array.find(a => a.id === d.id);
                    target.expand = false;
                    this.collapse(array, target, false);
                });
            } else {
                return;
            }
        }
    }

    combineAuditList(auditList: any[]) {
        let idx = 0;
        let stack = [];
        let group = [];
        let map = new Map();
        let result = [];
        //分组及合并二审三审意见
        auditList.forEach(item => {
            let curItem = item;
            if (stack.length > 0) {
                let lastItem = stack.pop();
                if (curItem.isWfAuditIdea != lastItem.isWfAuditIdea ||
                    (curItem.isWfAuditIdea == lastItem.isWfAuditIdea &&
                        curItem.auditType != lastItem.auditType)
                ) {
                    idx++;
                }
            }
            else {
                idx++;
            }

            if (item.isWfAuditIdea == false) {
                group.push(idx);
                if (!map.get(idx)) {
                    let step = {
                        id: idx,
                        auditStep: item.auditType,
                        // auditerName:item.auditerName,
                        // auditResult:item.auditResult,
                        // auditIdea: item.auditIdea,
                        // auditDate: item.auditDate,
                        auditerName: '',
                        auditResult: '',
                        auditIdea: '',
                        auditDate: '',
                        children: []
                    };
                    step.children.push(item);
                    map.set(idx, step);
                }
                else {
                    let step = map.get(idx);
                    step.children.push(item);
                }
            }
            item.groupId = idx;
            stack.push(item);
        });


        //将二审三审意见按 工程及时间排序
        let keys = Array.from(map.keys());
        keys.forEach(function (key) {
            let value = map.get(key)
            if (value != null) {
                value.children = value.children.sort((item1, item2) => {
                    return item1.auditStep > item2.auditStep ? 1 : item1.auditStep == item2.auditStep ? 0 : -1;
                })
                map.set(key, value);
            }
        });
        keys.forEach(function (key) {
            let value = map[key];
            if (value != null) {
                value.children = value.children.sort((item1, item2) => {
                    return item1.auditDate < item2.auditDate ? 1 : item1.auditDate == item2.auditDate ? 0 : -1;
                })
                map.set(key, value);
            }
        });

        //重置序号 插入二审三审意见
        idx = 1;
        auditList.forEach(item => {
            if (group.includes(item.groupId)) {
                if (!stack.includes(item.groupId)) {
                    let tmpItem = map.get(item.groupId)
                    tmpItem.id = idx;
                    result.push(tmpItem);
                    stack.push(item.groupId);
                    idx++;
                }
            }
            else {
                item.id = idx;
                result.push(item);
                idx++;
            }

        })
        return result;
    }
    getAuditIdeaDesc(id: number, auditer: string, auditTime: string, auditType: string, auditStep: string) {
        let result: string = '';
        if (id == this.auditList.length) {
            result += `<div class="t-1">审批人：${auditer}</div>`;
            return result;
            // return `<div class="t-1">审批人：${auditer}</div>于 <font color='#1890ff'>${auditTime}</font> <font color='#2793ef'>流程发起</font>`;
        }
        if (id == 1 && auditType != '待办') {
            result += `<div class="t-1">审批人：${auditer}</div> `;
            return result;
            //return `<font color='#1890ff'>${auditer}</font> 于 <font color='#1890ff'>${auditTime}</font> <font color='#2793ef'>${auditType}</font>`;
        }
        switch (auditType) {
            case `待办`:
                result = `等待 <font color='#1890ff'> ${auditer} </font> 办理`;
                break;
            case `同意`:
            case `转办`:
                result += `<div class="t-1">审批人：${auditer}</div> `;
                //result = `<font color='#1890ff'>${auditer}</font> 于 <font color='#1890ff'>${auditTime}</font> <font color='#49ca8e'>${auditType}</font>`;
                break;
            case `驳回`:
            case `不同意`:
                result += `<div class="t-1">审批人：${auditer}</div> `;
                //result = `<font color='#1890ff'>${auditer}</font> 于 <font color='#1890ff'>${auditTime}</font> <font color='#f5222d'>${auditType}</font>`;
                break;
            default:
                break;
        }
        return result;
    }


    getAuditList() {
        if (this.businessId != '') {
            this.workflowService.getAuditIdeaList(this.businessId).subscribe(
                (res) => {
                    let result = res.body.result;
                    if(!this.isContainsTempAuditIdea){
                        result = result.filter(w=>w.auditType != 'TempSave');
                    }
                    this.auditList = result;
                    this.auditList.forEach(item => {
                        item.isWfAuditIdea = true;
                    });
                    if (this.customAuditList.length > 0) {
                        this.customAuditList.forEach(item => {
                            item.isWfAuditIdea = false;
                        });
                        this.auditList = this.auditList.concat(this.customAuditList);
                    }
                    if (this.thingsList.length > 0) {
                        const ele = this.auditList[this.auditList.length - 1];
                        if (ele && ele.auditStep === '流程结束') {
                        } else {
                            this.auditList = this.auditList.concat(this.thingsList);
                        }
                    }
                    this.auditList = this.auditList.sort((item1, item2) => {
                        if (item1.auditDate == '') {
                            return -1;
                        } else if (item2.auditDate == '') {
                            return 1;
                        } else {
                            return item1.auditDate < item2.auditDate ? 1 : item1.auditDate == item2.auditDate ? 0 : -1;
                        }
                    });

                    this.auditList = this.combineAuditList(this.auditList);

                    this.auditList.forEach(item => {
                        this.expandDataCache[item.id] = this.convertTreeToList(item);
                    });
                }
            );
        }
    }
}
