import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import {BimWorkCoresService} from '../../bim-work-cores/bim-work-cores.service';
import { BimAdvisorWorkReplyEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-bim-advisor-reply-work',
  templateUrl: './bim-advisor-reply-work.component.html',
  styleUrls: []
})
export class BimAdvisorReplyWorkComponent implements OnInit {

  public bimInfoName = '';
  public bimInfoEventName = '';
  public advisorName = '';
  public advisorId = '';
  public schoolName = '';
  public bimEventId = '';

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];


  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;



  public queryParams = {
    // sort固定
    sort: ['id,desc']
  };

  columns: SimpleTableColumn[] = [
    { title: '作品名称', index: 'workName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '团队名称', index: 'teamName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '所属学校', index: 'schoolName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },

    { title: '学校类型', index: 'schoolType' , width: '10%',
    format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else {
          return item.schoolType;
        }
      }
    },

    { title: '参赛专项', index: 'itemName', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '提交时间', index: 'uploadDate', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },

    { title: '区域', index: 'regionName', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col)  },
    { title: '省份', index: 'provinceName', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col)  },
    { title: '评分', index: 'core', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col)  },

    {
      title: '操作', width: '10%',
      buttons: [
        { text: '删除', click: (item: any) => this.remove(item) },
      ],
    }
  ];



  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private bimWorkCoresService: BimWorkCoresService,
  ) {
   }

  ngOnInit(): void {
     this.getDataList();

  }



  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimWorkCoresService.getAdvisorWorkDataList(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key !== 'bimInfoEventsId') {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });

    copyParams['advisorId'] = this.advisorId;

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }



  /**
  * 新增页面
  */
  add() {
      this.modal.static(BimAdvisorWorkReplyEditComponent, {
        bimInfoName: this.bimInfoName,
        bimInfoEventName: this.bimInfoEventName,
        schoolName: this.schoolName,
        advisorName: this.advisorName,
        advisorId: this.advisorId,
        bimEventId: this.bimEventId,

      }, 1003).subscribe((res) => {

          if (res !== null && res.length > 0) {
              let workIds = '';
              res.forEach(element => {
                   if (workIds.length <= 0) {
                     workIds = element.id;
                   } else {
                    workIds = workIds + ',' + element.id;
                   }
              });

              this.bimWorkCoresService.saveAdvisorWork(this.advisorId, workIds).subscribe(() => {
                  this.msg.success('添加成功');
                  this.getDataList();
              });
          }


      });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isloadding = true;
        this.bimWorkCoresService.delete(item.id).subscribe(() => {
          this.isloadding = false;
          this.msg.success('删除成功！');
          this.getDataList();
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

}
