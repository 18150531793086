import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { CourseAppointStuManService } from '../courseAppointStuMan.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-couresSelectStu',
  templateUrl: './couresSelectStu.component.html',
})
export class EduCouresSelectStuComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: any[] = [];
  record: any = {};
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];
  courseId = this.record.courseId;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    // sort固定
    // sort: ['orderNum,asc']
  };

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '姓名', index: 'name', width: '20%' },
    { title: '手机号', index: 'mobile', width: '20%'},
    { title: '学校', index: 'college', width: '30%'},
    {
      title: '操作', className: 'text-center',
      buttons: [
        { text: '确定',  click: (item: any) => { this.save(item); } }
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public courseAppointStuManService: CourseAppointStuManService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    this.courseAppointStuManService.querySelectiveStudent(copyParams)
      .subscribe((res: any) => {
      this.list = res.body.data;
      this.total = res.headers.get('X-Total-Count');

      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }
  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    // tslint:disable-next-line:prefer-const
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  save(resource ?: any) {
    const copyParams = {};
    copyParams['eduCourseId'] = this.record.courseId;
    copyParams['eduStudentId'] = resource.id;
    this.courseAppointStuManService.saveStudentInfo(copyParams)
    .subscribe((res: any) => {
      this.msg.success('操作成功');
      this.getDataList();
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


}
