
import { Component, OnInit } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ArrayService } from '@shared/utils/array.service';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { BimSoftDownloadTypeService } from '../bim-soft-type.service';
import { BimSoftDownloadType } from '../bim-soft-type.model';
@Component({
  selector: 'app-soft-type-node',
  templateUrl: './soft-type-node.component.html',
  styleUrls: ['./soft-type-node.component.less']
})
export class SoftTypeNodeComponent implements OnInit {
  public processNodeList = [];
  record: any = {};

  public delNodeList = [];

  public isloading = false;

  constructor(
    private cacheService: CacheService,
    public http: _HttpClient,
    private modal: NzModalRef,
    public bimSoftDownloadTypeService: BimSoftDownloadTypeService,
    public msgSrv: NzMessageService,
    private fb: FormBuilder,
    private arrayService: ArrayService,
    private modalHelper: ModalHelper,
  ) {
  }
  // 初始化方法
  ngOnInit() {
    console.log(this.record);
    this.loadProcessNode();
  }



  // 关闭事件
  close(type) {
    this.modal.destroy(type);
  }


  submit() {
    if (this.processNodeList != null && this.processNodeList.length > 0) {
      let i = 0;
      const saveDataList = JSON.parse(JSON.stringify(this.processNodeList));
      this.isloading = true;
      let names = [];
      for (let i = 0; i < saveDataList.length; i++) {
        const node = saveDataList[i];
        if (node.name && node.name.length > 20) {
          this.msgSrv.warning(`软件名称【${node.name}】长度不可超过20`);
          this.isloading = false;
          return;
        }
        if (isNullOrUndefined(node.name) || node.name.length == 0) {
          this.msgSrv.warning(`软件名称不可为空`);
          this.isloading = false;
          return;
        }
        if (names.includes(node.name)) {
          this.msgSrv.warning(`软件名称【${node.name}】重复，请修改名称`);
          this.isloading = false;
          return;
        }
        names.push(node.name);
      }
      if (!this.isloading) {
        return;
      }
      if (this.delNodeList.length > 0) {
        this.delNodeList.forEach(e => {
          this.bimSoftDownloadTypeService.delete(e).subscribe();
        });
      }
      saveDataList.forEach(node => {
        i++;
        if (node.id != null && node.id.length > 0) {
          this.bimSoftDownloadTypeService.update(node).subscribe();
        } else {
          this.bimSoftDownloadTypeService.create(node).subscribe();
        }
        if (i === this.processNodeList.length) {
          this.isloading = false;
          this.msgSrv.success('保存成功!');
          this.close(true);
        }
      });
    }

  }

  loadProcessNode() {
    const resultQueryParams = {
      sort: ['orderNum,asc']
    };
    if (!isNullOrUndefined(this.record.id)) {
      resultQueryParams['parentId.equals'] = this.record.id;
    }
    this.isloading = true;
    this.bimSoftDownloadTypeService.query(resultQueryParams).subscribe((res: any) => {
      this.isloading = false;
      if (res.ok) {
        const data = res.body;
        if (data.length === 0) {
          this.addProcessNode();
        } else {
          this.processNodeList = data;
        }
      }
    });
  }

  addProcessNode() {
    const data = new BimSoftDownloadType();
    data.bimInfoId = this.record.bimInfoId;
    data.isAble = '1';
    data.parentId = this.record.id;
    data.orderNum = this.processNodeList.length + 1;
    this.processNodeList.push(data);
    
  }

  subProcessNode(i) {
    const processNode = this.processNodeList[i];
    if (processNode.id && processNode.id !== null) {
      this.delNodeList.push(processNode.id);
      // this.bimSoftDownloadTypeService.delete(processNode.id).subscribe();
    }
    this.processNodeList = this.processNodeList.filter(e => e !== processNode);
  }
}
