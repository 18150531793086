import { Component, OnInit, ViewChild } from '@angular/core';
  import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
  import { _HttpClient } from '@delon/theme';
  import { map } from 'rxjs/operators';
import { SFUISchema, SFSchema, SFComponent } from '@delon/form';
import { NotificationService } from '../notification.service';
import * as moment from 'moment';

  @Component({
    selector: 'app-admin-notification-view',
    templateUrl: './view.component.html',
    styles:[`
      :host ::ng-deep .ant-radio-button-wrapper-checked {
        color: #fff;
        background-color: #40a9ff;
        border-color: #40a9ff;
      }
    `]
  })
  export class NotificationViewComponent implements OnInit {
  @ViewChild('sf') sf: SFComponent;
  record: any = {};
  formData: any;
  allRoles:any[] = [];
  selectedRoles =['1'];
  originName = '';
  schema: SFSchema = {
    properties: {
      senderName: {
        type: 'string', title: '发送人',
        ui: { 
          widget: 'text', defaultText: '系统通知' 
        }
      },
      priority: {
        type: 'string', title: '紧急程度',
        ui: {
            'widget': 'custom'
        },
        default: '普通'
      },
      sendTime: {
        type: 'string', title: '发送时间', 
        ui: { 
          widget: 'text', defaultText: '无' ,
        }
      },
      readTime: {
        type: 'string', title: '阅读时间', 
        ui: { 
          widget: 'text', defaultText: '现在' ,
        }
      },
      summary: {
        type: 'string', title: '消息摘要',
        ui: { 
          widget: 'text', defaultText: '无' ,
          grid: { span: 24 }
        }
      },
      content: {
        type: 'string', title: '消息正文', 
        ui: { 
          widget: 'text', defaultText: '无' ,
          grid: { span: 24 }
        }
      },
      // isRead: { 
      //    type: 'boolean', title: '是否阅读', readOnly: true
      // },
    },
    // required: ['content']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };

    constructor(
      private modal: NzModalRef,
      public msgSrv: NzMessageService,
      public http: _HttpClient,
      private notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
      this.notificationService.find(this.record.id)
        .subscribe(res => {
          this.formData = res.body;
          moment.locale('zh-cn');
          if(this.formData.readTime){
            this.formData.readTime = moment(res.body.readTime).format("YYYY-MM-DD HH:mm:ss ") + "(" + moment(res.body.readTime).fromNow() + ")";
          }else{
            this.formData.readTime = moment().format("YYYY-MM-DD HH:mm:ss ") + "(刚刚)";
          }
          this.formData.sendTime = moment(res.body.sendTime).format("YYYY-MM-DD HH:mm:ss ") + "(" +moment(res.body.sendTime).fromNow() + ")";
          
          switch (res.body.priority) {
            case 1:
              this.formData.priority = '普通';
              break;
            case 2:
              this.formData.priority = '优先';
              break;
            case 3:
              this.formData.priority = '紧急';
              break;
            default:
              this.formData.priority = '普通';
              break;
          };
          if(!res.body.isRead){
              this.notificationService.setRead(res.body.id).subscribe();
          }
        });
    }

    close() {
      this.modal.destroy();
    }
  }
