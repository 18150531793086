import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient } from '@angular/common/http';
import { IucceProjectFundPay } from './projectFundPay.model';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class  IucceProjectFundPayService extends ThsBaseService<IucceProjectFundPay>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.IucceServe + '/iucce-project-fund-pays');
  }
}
