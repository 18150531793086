import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BimWorkCores } from './bim-work-cores.model';
import { ThsBaseService } from '../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimWorkCoresService extends ThsBaseService<BimWorkCores> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-work-cores`);
    }


    /** 自动分配作品 */
    autoDistribution(queryParams?: any): Observable<HttpResponse<any>> {
        const url = this.resourceUrl + `/autoDistribution`;
        return this.http.get<any>(url, { params: queryParams, observe: 'response' });
    }


    // 获取评委的作品分配数据列表
    getAdvisorWorkDataList(param: any) {
        return this.http.get<any[]>(`${this.resourceUrl}/getAdvisorWorkDataList`, { params: param, observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }


    // 获取没有分配给评委的作品列表
    getWorkNotAdvisorDataList(param: any) {
        return this.http.get<any[]>(`${this.resourceUrl}/getWorkNotAdvisorDataList`, { params: param, observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }


    // 保存选择的作品列表
    saveAdvisorWork(advisorId: string, workIds: string | string[]): Observable<HttpResponse<any>> {
        let id;
        if (workIds instanceof Array) {
            id = workIds.join(',');
        } else {
            id = workIds;
        }

        const param = {};
        param['advisorId'] = advisorId;

        param['workIds'] = id;


        return this.http.get<any[]>(`${this.resourceUrl}/saveAdvisorWork`, { params: param, observe: 'response' });
    }

          
        //获取作品分配的评委数据列表
        getWorkAdvisorDataList(param: any) {
            return this.http.get<any[]>(`${this.resourceUrl}/getWorkAdvisorDataList`, {params: param ,  observe: 'response'})
                .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
          }


         //获取没有分配给改作品的评委列表
         getAdvisorNotWorkDataList(param: any) {
            return this.http.get<any[]>(`${this.resourceUrl}/getAdvisorNotWorkDataList`, {params: param ,  observe: 'response'})
                .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
          }




    // 获取决赛参赛作品数据列表
    getBimWorkDataByBimInfo(param: any) {
        return this.http.get<any[]>(`${this.resourceUrl}/getBimWorkDataByBimInfo`, { params: param, observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }



          //保存选择的评委列表
          saveWorkAdvisor (workId: string, advisorIds:  string | string[]): Observable<HttpResponse<any>> {
            let id;
            if(advisorIds instanceof Array){
                id =  advisorIds.join(",") ;
            }else{
                id = advisorIds;
            }
            
            let param = {}
            param["advisorIds"] = id;

            param["workId"] = workId;
            
           
            return this.http.get<any[]>(`${this.resourceUrl}/saveWorkAdvisor`, {params: param ,  observe: 'response'});
        }

    flushCountryWorkScore(bimInfoEventsId) {
        return this.http.get<any[]>(`${this.resourceUrl}/flushCountryWorkScore/${bimInfoEventsId}`, { observe: 'response' });
    }

    exportWorkData(bimInfoEventsId: string, workType: number, fileName: string) {
      const url = `${this.resourceUrl}/exportWorkData/${bimInfoEventsId}/${workType}`;

      // 需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
      this.http.get(url ,  {
        headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
        observe: 'response',
        responseType: 'blob',
        withCredentials: true,
      }).subscribe(
        data => {
          const blob = new Blob([data.body]);
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.setAttribute('href', objectUrl);
          a.setAttribute('download', fileName + '.xlsx');
          a.click();
          URL.revokeObjectURL(objectUrl);
        },
        error => {
          console.error('StatementDailyTemperatureComponent list Error ->', error);
        },
      );
    }



    /** 自动分配作品 */
    autoDistributionReply(queryParams?: any): Observable<HttpResponse<any>> {
        const url = this.resourceUrl + `/autoDistributionReply`;
        return this.http.get<any>(url, { params: queryParams, observe: 'response' });
    }

}

