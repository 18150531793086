/**
 * 项目立项申报
 */
export class Project   {
    constructor(
        public id?: string,
        public year  ?: number,
        public regionId  ?: string,//大区id
        public region  ?: string,//大区
        public provinceId  ?: string,//省份id
        public province  ?: string,//省份
        public schoolName   ?: string,//学校名称
        public projectName ?: string,//项目名称
        public batchId ?: string,//批次id
        public batch  ?: string,//批次
        public projectTypeId ?: string,//项目类别id
        public projectType ?: string,//项目类别
        public applicant ?: string,//申请人
        public applicantTel ?: string,//申请人联系方式
        public applicantDuty ?: string,//申请人职务
        public contractStatus ?: string,//合同状态
        public startDate ?: Date,//项目开始时间
        public endDate ?: Date,//项目结束时间
        public isDelay ?: string,//是否延迟
        public delayEndDate?: Date,//延迟具体时间
        public prjStatus ?: number,//项目状态
        public establishStatus ?: string,//立项状态 0 待结项 1 申报书已提交 2 申报书审批中 3 申报书审核通过 4 申报书不通过 5 协议书审核中  6 协议书审核通过  7 协议书审核不通过
        public closingStatus ?: string,//结项状态
        public orderStatus ?: string,//成单状态(0 未成单 1 部分成单 3 全部成单)
        public orderAmount ?: number,//成单金额
        public establishBy ?: string,//立项提交人
        public establishSubmitDate ?: Date,//立项提交日期
        public establishAuditBy ?: string,//立项通过/不通过人
        public establishAuditDate ?: Date,//立项通过/不通过时间
        public establishDate ?: Date,//项目立项时间
        public closingBy ?: string,//结项提交人
        public closingSubmitDate ?: Date,//结项提交时间
        public closingAuditBy ?: string,//结项通过/不通过验收人
        public closingAuditDate ?: Date,//结项通过/不通过验收时间的
        public closingDate ?: Date,//项目结项时间
        public prjDeclarationAuditstate ?: string,//项目申报书审核结果(其他表示没有还审核结果 3 通过 4 不通过)
        public prjDeclarationAuditdate ?: Date,//项目申报书审核结束时间
        public prjAgreementAuditstate ?: string,//项目协议书审核状态(其他表示没有还审核结果 3 通过 4 不通过)
        public prjAgreementAuditdate ?: Date,//项目协议书审核结束时间
        public isHaveSoftwarePay ?: string,//是否有软件支付明细
        public remark ?: string,//备注
        public createdBy ?: string,
        public createdDate ?: Date,
        public lastModifiedBy	?: string,
        public lastModifiedDate ?: Date,

        public flowInfos ?: string//项目跟进人，多个用,号分隔
    ) {}
}
