import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { SFSchema, SFUISchema, SFComponent} from '@delon/form';

import { ArrayService } from '@shared/utils/array.service';

  @Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-admin-courseFile-view',
    templateUrl: './courseFile.component.html',
  })
  export class CourseFileComponent implements OnInit {

    @ViewChild('nzTree') nzTree: NzTreeComponent;
    @ViewChild('sf') sf: SFComponent;
    record: any = {};
    formData: any;
    originCode: any;
    loading = false;
    extParamsVideo = {
      'extField1.equals': '2'
    };
    extParamsPPt = {
      'extField1.equals': '1'
    };
    extParamsWord = {
      'extField1.equals': '0'
    };
    rolePermission: any[] = [];
    permissionTreeNodes ;

    constructor(
      private modal: NzModalRef,
      public msgSrv: NzMessageService,
      public http: _HttpClient,

      private arrService: ArrayService
    ) {}

    ngOnInit(): void {


    }
    close() {
      this.modal.destroy();
    }

  }
