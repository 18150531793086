import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent} from '@delon/form';
import { Course } from '../course.model';
import { CourseService } from '../course.service';
import { map } from 'rxjs/operators';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { PersonnelService } from 'app/routes/admin/organization/personnel/personnel.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { HttpAddressService } from '@shared/session/http-address.service';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
@Component({
  selector: 'app-edu-resource-edit',
  templateUrl: './edit.component.html',
})
export class EduCourseEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any ;
  record: any = {};
  submitting = false;
  formData: any;
  imgUrl = '';
  originName = '';
  type = '';
  resourceType = '';
  dictNo = 'CourseType';
  courseType = '';
  classifyName = '';
  classifyId = '';
  classifybool = null;
  courseTypeId = '';
  schema: SFSchema = {
    properties: {
      courseName: {
        type: 'string', title: '课程名称', maxLength: 100
      },
    /*   classifyId: {
        type: 'string', title: '课程分类',maxLength: 50,
        ui: {
          widget: 'select',
          allowClear: false,
          serverSearch: false,
          asyncData: () => of(this.courseService.getcourseClassify()).pipe(delay(1200)),
          change:(any) => of(this.courseService.getcourseClassifyChange(any))
          asyncData: () => of([
              { label: '待支付', value: 'WAIT_BUYER_PAY' },
              { label: '已支付', value: 'TRADE_SUCCESS' },
              { label: '交易完成', value: 'TRADE_FINISHED' },
        ]),
        } ,
      }, */
      /* courseType: {
        type: 'string', title: '课程类别', maxLength: 20 , */
         /*  ui: {
            widget: 'select',
            asyncData: () => of(this.courseService.courseType),
          } */
     /*  }, */
     /*  teacherId: {
        type: 'string', title: '授课教师',
        ui: {
          widget: 'select',
          allowClear: false,
          serverSearch: false,
          onSearch: (keyword: string) => this.courseService.getRepositoryOfOptionData(keyword).toPromise(),
        },
      },  */
      teacherName: {
        type: 'string', title: '授课教师', maxLength: 50,
      },
      introduction: {
        type: 'string', title: '课程简介', maxLength: 2000,
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
    /*   summarize: {
        type: 'string', title: '课程概述',
        ui: {
          widget: 'ueditor',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      }, */
      target: {
        type: 'string', title: '课程目标',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      startDate: {
        type: 'string', title: '开始日期', ui: { widget: 'date', end: 'end' }
      },
      endDate: {
        type: 'string', title: '结束日期', ui: { widget: 'date', end: 'end' }
      },
      totalHours: {
        type: 'number', title: '总学时'
      },
      updateSection: {
        type: 'string', title: '更新章节', maxLength: 20
      },
      courseStatus: {
        type: 'string', title: '课程状态',
         ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '进行中', value: '1' }, { label: '即将结束', value: '2' }, { label: '已结束', value: '3' }]),
          change: console.log,
          } ,
        default: '1',
      },
      isCharge: {
        type: 'string', title: '是否收费',
        ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '是', value: '1' }, { label: '否', value: '0' }]),
          change: console.log,
          } ,
        default: '0',
      },
      price: {
        type: 'string', title: '收费金额', maxLength: 100
      },
      vipPrice: {
        type: 'string', title: 'vip金额', maxLength: 100
      },

      privilege: {
        type: 'string', title: '优惠说明',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      isPublish: {
        type: 'string', title: '是否发布',
        ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '是', value: '1' }, { label: '否', value: '0' }]),
          change: console.log,
          } ,
        default: '1',
      },
      isDelete: {
        type: 'string', title: '是否删除',
        ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '是', value: '1' }, { label: '否', value: '0' }]),
          change: console.log,
          } ,
        default: '1',
      },
      isLimit: {
        type: 'string', title: '是否指定学生',
        ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '是', value: '1' }, { label: '否', value: '0' }]),
          change: console.log,
          } ,
        default: '1',
      },
      subscibeCount: {
        type: 'number', title: '订阅数量', default: 0
      },
      favoriteCount: {
        type: 'number', title: '收藏数量', default: 0
      },
      orderNum: {
        type: 'integer', title: '排序号', default: 1
      }
    },
    required: ['courseName', 'teacherName', 'introduction', 'target', 'totalHours', 'courseStatus', 'isCharge']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };


  tokenData: any;
  queryParams2 = {
    fileFk : '',
  };
  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private courseService: CourseService,
    private dictDetailService: DictDetailService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService
  ) {}

  ngOnInit(): void {
    const _this_ = this;
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.courseService.find(this.record.id)
        .subscribe(res => {
          this.formData = this.formatRecord(res.body);
          console.log(this.formData);
           this.type =  this.formData.courseTypeId;
           this.resourceType = this.formData.courseTypeId;
           this.courseType = this.formData.courseType;
           this.classifyId = this.formData.classifyId;
           this.classifyName = this.formData.classifyName;
           this.courseTypeId = this.formData.courseTypeId;
           this.classifybool = true;
        });
    }
  }
  save(resource ?: Course) {
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
    if (!this.sf.valid) {
      // 手动触发所有检验项的提示信息 无效
      // this.ui['*'].firstVisual = true;
      // this.sf.refreshSchema();
      this.msgSrv.error('表单校验失败，请检查');
      return ;
    }

    if (!this.classifybool) {
      this.msgSrv.error('课程分类子类未选择，请检查');
      return ;
    }
      this.submitting = true;
      this.saveResource().then(function () {
        this.msgSrv.success('保存成功');
        this.submitting = false;
        this.modal.close(true);
      }.bind(this));
  }

  orgChange(value) {
    // this.personalService.find(value).subscribe((res) => {
    //   this.formData['entityName'] = res.body.perName;
    //   this.formData['entityId'] = res.body.id;
    //   this.sf.reset();
    // });
  }

  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }
  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }

  uploadFinish(file: any) {
    if (file && file.success && file.success === true) {
      this.sf.value.imgurl = file.file.id;
    }
  }

  saveResource() {
    const _this_ = this;
  _this_.sf.value.courseTypeId = this.resourceType;
  _this_.sf.value.courseType = this.courseType;
  _this_.sf.value.classifyId = this.classifyId;
  _this_.sf.value.classifyName = this.classifyName;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.courseService.create(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存成功');
          });
      } else {
        // if (_this_.imgUrl !== ''){
        //   _this_.sf.value.imgUrl = _this_.imgUrl;
        // }
        // _this_.sf.value.type = this.this.resourceType;
        _this_.courseService.update(_this_.sf.value)
          .subscribe(res => {
            resolve('更新成功');
            _this_.imgUrl = '';
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }

  typeChange (obj) {
    this.resourceType = obj;
      this.getchangeVal();
  }


   teacherChange(value) {
      alert(value);
   }


   getchangeVal() {
     if (!this.resourceType) {
      this.classifybool = false;
       return ;
     }

    this.dictDetailService.find(this.resourceType)
    .subscribe(res => {
     const parentId = res.body.parentId;
     if (!!parentId) {
      this.dictDetailService.find(parentId)
      .subscribe(res2 => {
        const pparentId = res2.body.parentId;
        if (!!pparentId) {
          this.dictDetailService.find(pparentId)
          .subscribe(res3 => {
            this.classifyId = res3.body.itemValue2;
            this.classifyName = res3.body.itemText;
            this.courseType = res.body.itemText;
           });
          } else {
            this.classifyId = res2.body.itemValue2;
           this.classifyName = res2.body.itemText;
           this.courseType = res.body.itemText;
          }
       });
       this.classifybool = true;
     } else {
      this.classifybool = false;
       }
     });

    return this.classifybool;
   }


    }
