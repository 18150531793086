import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoInstitutionalEditComponent } from './edit/edit.component';
import { ThsNewsService } from '../../ths-news/ths-news.service';

@Component({
  selector: 'app-bim-info-institutional',
  templateUrl: './bim-info-institutional.component.html',
  styleUrls: []
})
export class BimInfoInstitutionalComponent implements OnInit {

  public BimInfoId:String;
  public BimInfoName:String;
  /**
   * contentType
  */
  @Input() contentType = 'institutional';

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public queryParams = {
    // 查询搜索框的字段要预置
    'title.contains': null,
    // sort固定
    sort: ['isreport,asc']
  };

  columns: SimpleTableColumn[] = [
    { title: '类型', index: 'title', width: '30%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '是否启用', index: 'istop', width: '25%', format: (item: any) => {
      if (item.istop === 1) {
        return '启用';
      } else {
        return '未启用';
      }
    }},
    { title: '序号', index: 'isreport', width: '20%' },
    {
      title: '操作',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.remove(item) }
      ],
    }
  ];

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private routeInfo: ActivatedRoute,
    private modalService: NzModalService,
    private fileUploadService: FileUploadService,
    private thsNewsService: ThsNewsService,
  ) { 
    this.routeInfo.queryParams.subscribe(parms => {
      if (parms.bimInfoId) {
         this.BimInfoId = parms.bimInfoId;
         this.getDataList();
      }

      if(parms.bimInfoName){
        this.BimInfoName = parms.bimInfoName;
      }
    });
  }

  ngOnInit(): void {
    console.log(this.contentType);
    this.getDataList();
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.thsNewsService.query(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    if (!isNullOrUndefined(this.contentType)) {
      copyParams['contenttype.equals'] = this.contentType;
    }
    if (!isNullOrUndefined(this.BimInfoId)) {
      copyParams['bimInfoId.equals'] = this.BimInfoId;
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
  * 新增页面
  */
  add() {
    const dataId = new GuidUtil().toString();
    this.modal.static(BimInfoInstitutionalEditComponent, {
      isAdd: true,
      record: { id: null},
      dataId: dataId,
      bimInfoId:this.BimInfoId,
      contentType: this.contentType
    }).subscribe((res) => {
      console.log(res);
      if (res === 'success') {
        this.getDataList();
      } else {
        this.fileUploadService.deleteAllByFk(dataId).subscribe();
      }
    });
  }

  /**
   * 编辑页面
   */
  edit(item) {
    this.modal.static(BimInfoInstitutionalEditComponent, {
      isAdd: false,
      record: item,
      bimInfoId:this.BimInfoId,
      contentType: this.contentType
    }).subscribe((res) => {
      if (res === 'success') {
        this.getDataList();
      }
    });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteData(item.id);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteData(dataId) {
    this.isloadding = true;
    this.thsNewsService.delete(dataId).subscribe(() => {
      this.isloadding = false;
      this.fileUploadService.deleteAllByFk(dataId).subscribe();
      this.msg.remove();
      this.msg.info('删除成功！');
      this.getDataList();
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];
}
