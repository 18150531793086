import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent} from '@delon/form';
import { CourseCatalog } from '../../courseCatalog/courseCatalog.model';
import { CourseCatalogService } from '../../courseCatalog/courseCatalog.service';
import { CourseAuditService } from '../courseAudit.service';
import { map } from 'rxjs/operators';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { PersonnelService } from 'app/routes/admin/organization/personnel/personnel.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-courseCatalogAudit-edit',
  templateUrl: './courseCatalogAudit.component.html',
})
export class EduCourseCatalogAuditEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any ;
  record: any = {};
  submitting = false;
  formData: any;
  imgUrl = '';
  originName = '';
  type = '';
  resourceType = '';
  extParamsVideo = {
    'extField1.equals': '2'
  };
  extParamsPPt = {
    'extField1.equals': '1'
  };
  extParamsWord = {
    'extField1.equals': '0'
  };
  schema: SFSchema = {
    properties: {
      courseName: {
        type: 'string', title: '课程名称', readOnly: true
      },
      name: {
        type: 'string', title: '目录名称', readOnly: true
      },
      isTrial: {
        type: 'string', title: '是否试看', readOnly: true,
        ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '是', value: '1' }, { label: '否', value: '0' }]),
          change: console.log,
          } ,
        default: '1',
      },
      uploadTime: {
        type: 'string', title: '上传时间', readOnly: true
      },
      remark: {
        type: 'string', title: '备注', readOnly: true,
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      auditResult: {
        type: 'string', title: '审核结果',
        ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '通过', value: '1' }, { label: '不通过', value: '0' }]),
          change: console.log,
          } ,
        default: '1',
      },
      auditOpinion: {
        type: 'string', title: '审核意见',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      }
    },
    required: ['auditResult']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };


  tokenData: any;
  queryParams2 = {
    fileFk : '',
  };
  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private courseCatalogService: CourseCatalogService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService,
    private courseAuditService: CourseAuditService,
  ) {}

  ngOnInit(): void {
    // this.formatRecord();
    // console.log(this.record);
// debugger
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.courseAuditService.findCourseCatalogInfo(this.record.id)
        .subscribe(res => {
          console.log(res.body.data[0]);
          this.formData = this.formatRecord(res.body.data[0]);
          this.type =  this.formData.type;
        });
    }
  }
  save(resource ?: CourseCatalog) {
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
    if (!this.sf.valid) {
      // 手动触发所有检验项的提示信息 无效
      // this.ui['*'].firstVisual = true;
      // this.sf.refreshSchema();
      this.msgSrv.error('表单校验失败，请检查');
      return ;
    }
    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));
  }

  orgChange(value) {
    console.log(value);
    // this.personalService.find(value).subscribe((res) => {
    //   this.formData['entityName'] = res.body.perName;
    //   this.formData['entityId'] = res.body.id;
    //   this.sf.reset();
    // });
  }

  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }
  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    if (record.auditResult !== '1') {
      record.auditResult = '0';
    }
    return record;
  }

  uploadFinish(file: any) {
    if (file && file.success && file.success === true) {
      this.sf.value.imgurl = file.file.id;
    }
  }

  saveResource() {
    const _this_ = this;
    _this_.sf.value.type = this.resourceType;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.courseAuditService.saveAuditResult(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存成功');
          });
      } else {
        _this_.courseAuditService.saveAuditResult(_this_.sf.value)
          .subscribe(res => {
            resolve('更新成功');
            _this_.imgUrl = '';
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }
}
