import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { EduStudentEditComponent } from "./edit/edit.component";
import { EduStudentCourseNumComponent } from "./courseNum/courseNum.component";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { StudentService } from "./student.service";
import { Student } from "./student.model";
import { APP_PERMISSIONS } from "@shared/app.permissions";

@Component({
  selector: 'app-edu-student',
  templateUrl: './student.component.html',
})
export class EduStudentComponent implements OnInit{
  public page: number = 0;
  public size: number = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: Student[] = [];
  
  public totalCallNo: number = 0;
  public selectedRows: SimpleTableData[] = [];

  //HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    //查询搜索框的字段要预置
    'name.contains': '',
    'idCard.contains': '',
    'college.contains': '',
    //sort固定
    // sort: ['orderNum,asc']
  };


  columns: SimpleTableColumn[] = [
  
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public studentService: StudentService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
     // this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    }else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    };
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.studentService.query(copyParams)
      .subscribe((res: any) => {
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
       // this.list = res.body;

      });
  }

  /**
  * 页码数量变动事件
  * @param 
  */
 paginationChange(event) {
  this.page = 0;
  this.size = event;
  this.getDataList();
}
  
 /**
  * 页码变动事件
  * @param 
  */
 changePageIndex(pageIndex ) {
  this.page = pageIndex- 1;
  this.getDataList();
}

  /**
  * 过滤器变动事件 支持多选过滤
  * @param 
  */
  filterChange(event) {
    let i = 0;
    let _value = [];
    let _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey +'.'+ _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param 
  */
  sortChange(event) {
    let array = this.queryParams['sort'];
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value == 'descend') {
      value = 'desc';
    } else if (event.value == 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + "," + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + "," + value);
    }
    //排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(EduStudentEditComponent, { record: { id: null } })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    if (this.selectedRows.length > 1) return this.msg.warning("只能选择一条数据再编辑！");
    if (this.selectedRows.length < 1) return this.msg.warning("请先选择一条数据再编辑！");
    let tmp = this.formatIsOk(this.selectedRows[0]);
    debugger
    this.modal
      .static(EduStudentEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }

  formatIsOk (item: any) {
    debugger
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }
  // /**
  // * 查看页面
  // * @returns {NzMessageDataFilled}
  // */
  // view() {
  //   if (this.selectedRows.length > 1) return this.msg.warning("只能选择一条数据编辑！");
  //   if (this.selectedRows.length < 1) return this.msg.warning("请先选择一条数据再编辑！");
  //   this.modal
  //     .static(AdminUsersViewComponent, { record: this.selectedRows[0] })
  //     .subscribe(() => this.st.reload());
  // }
  /**
  * 删除
  */
  remove(res: any) {
    if(res.userName == 'admin'){
      this.msg.info("管理员账号不能删除");
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.studentService.delete(res.id)
          .subscribe(() => {
            this.msg.info("删除成功");
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning("请先选择您要删除的信息！");
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if(ele.userName == "admin"){
        this.msg.info("管理员账号不能删除");
        flag = true;
      }
    });
    if(flag){
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.studentService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



  editStudent(data:any) {
    let tmp =data;
    this.modal
      .static(EduStudentEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }
 
  viewCourse(data){
    let tmp =data;
    this.modal
        .static(EduStudentCourseNumComponent, { record: tmp })
        //     .pipe(filter(w => w === true))
        .subscribe(() => this.getDataList()); 
  }


}
