import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Project } from './projectReport.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectReportService extends ThsBaseService<Project> {

    projectFlowInfoUrl = "";
    projectNicheInfoUrl = "";
    uploadFileAddress = "";
    downLoadFileAddress = "";
    fileUrl = "";
    busiformApprovalUrl = "";

    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.IucceServe + `/iucce-projects`);

        this.projectFlowInfoUrl = httpAddressService.IucceServe + `/iucce-project-followinfos`;
        this.projectNicheInfoUrl = httpAddressService.IucceServe + `/iucce-project-niches`;
        this.uploadFileAddress = httpAddressService.systemServe + `/sys-files/upload`;
        this.downLoadFileAddress = httpAddressService.systemServe + `/sys-files/download/`;
        this.fileUrl = httpAddressService.systemServe + `/sys-files`;
        this.busiformApprovalUrl = httpAddressService.IucceServe + `/iucce-busform-approvals`;

    }

    /** GET: 获取项目信息 */
    getAllIucceProjectsByPage (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.resourceUrl+`/getAllIucceProjectsByPage`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 获取可结项项目信息 */
    getAllIucceProjectClosingsByPage (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.resourceUrl+`/getAllIucceProjectClosingsByPage`, {params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 获取项目跟进人数据 */
    findProjectFlowinfos (projectId:string): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.projectFlowInfoUrl, {params: {'iucceProjectId.in':projectId,sort:'orderNum,asc'} ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 获取项目商机数据 */
    findProjectNicheinfos (projectId:string): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.projectNicheInfoUrl, {params: {'iucceProjectId.in':projectId,sort:['orderNum,asc','createdDate,asc']} ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

     /** GET: 根据区域和省份id获取区域管理员人员id列表 */
    getProjectRegionUserIds (regionId:string,provinceId:string): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.resourceUrl+`/getProjectRegionUserIds`, {params: { regionId:regionId,provinceId:provinceId} ,  observe: 'response'});
    }

    /**
     * 获取当前登录用户大区和省份数据
     * @returns 
     */
    getUserRegionAndProvince(): Observable<HttpResponse<any[]>>{
        return this.http.get<any[]>(this.resourceUrl+`/getUserRegionAndProvinces`, {params:{} ,  observe: 'response'});
    }

    /**
     * 获取系统人员信息
     * @returns 
     */
    getSystemPersons(): Observable<HttpResponse<any[]>>{
        return this.http.get<any[]>(this.resourceUrl+`/getSystemPersonList`, {params:{} ,  observe: 'response'});
    }

     /** POST: 添加一条新记录 */
     saveData (record: any,prjFollowInfoList:any[],prjNicheList:any[]): Observable<HttpResponse<any>> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/saveData`, copy, { params:{ prjFollowInfoList : JSON.stringify(prjFollowInfoList),prjNicheList : JSON.stringify(prjNicheList) } , observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

     /** POST: 提交一条新记录 */
     submitData (record: any,workflowForm:any,prjFollowInfoList:any[],prjNicheList:any[]): Observable<HttpResponse<any>> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/submitData`, copy, { params:{ prjFollowInfoList : JSON.stringify(prjFollowInfoList),wfForm:JSON.stringify(workflowForm),prjNicheList : JSON.stringify(prjNicheList) } , observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }


    /** DELETE: 根据id、id数组来删除记录 */
    deleteProjectFile (record:  string | string[]): Observable<HttpResponse<any>> {
        let id;
        if(record instanceof Array){
            id =  record.join(",") ;
        }else{
            id = record;
        }
        const url = `${this.fileUrl}/${id}`;

        return this.http.delete<HttpResponse<any>>(url, { observe: 'response'});
    }
    
    /** 根据id删除项目数据 */
    deleteProjectById(id:  string){
        return this.http.delete<any[]>(this.resourceUrl+`/${id}/deleteProject`, {params:{} ,  observe: 'response'});
    }


    /** GET: 获取项目业务流程信息 */
    getAllBusiformApprovalList (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.busiformApprovalUrl, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 项目查询-导出项目表 */
    exportProjectQueryDetailExcel(params:any,obj) {
        this.http.get(this.resourceUrl+`/exportProjectQueryDetailExcel`, {
            params: params,
            headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
            observe: 'response',
            responseType: 'blob',
            withCredentials: true,
          }).subscribe(
            data => {
              let blob = new Blob([data.body]);
              let objectUrl = URL.createObjectURL(blob);
              let a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display:none');
              a.setAttribute('href', objectUrl);
              a.setAttribute('download', (params.fileName ? params.fileName : '数据导出') + '.xlsx');
              a.click();
              URL.revokeObjectURL(objectUrl);
              obj.exportingPrjDetail = false;
            },
            error => {
              console.error('导出项目总表失败：', error);
              obj.exportingPrjDetail = false;
            },
          );
    }
}

