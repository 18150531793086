import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimInfoQq } from '../bim-info-qq.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimInfoQqService } from '../bim-info-qq.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { GuidUtil } from '@shared/utils/guid.util';

@Component({
  selector: 'app-bim-bimInfo-qq-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoQqEditComponent implements OnInit, OnDestroy {

  record: any = {};
  bimInfoId:string;
   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';


  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  public talkTypeList = {'QQ' : 'QQ', '微信' : '微信',  '抖音' : '抖音', '视频' : '视频'};
  extField1QRCode = {'extField1.equals': "BIM_QQ_QRCODE"};
  toUpdateFkQRCode = [];
  // 要更新附件外键的集合
  hasToUpdate = false;

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimInfoQqService: BimInfoQqService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId:[this.bimInfoId, []],
      name: [null, [Validators.required, Validators.maxLength(100) ]],
      qq: [null, [Validators.maxLength(150) ]],    
      userDefine1: [null, [Validators.required]],
      userDefine2: [null, []],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
   // this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
   // this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';

  }
  ngOnDestroy(): void {
    if (null !== this.toUpdateFkQRCode && this.toUpdateFkQRCode.length > 0 && !this.hasToUpdate) {
          const idArray = new Array();
          this.toUpdateFkQRCode.forEach((d) => {
            idArray.push(d.response.id);
          });
          this.fileUploadService.delete(idArray).subscribe((res: any) => {
            console.log(idArray);
          });
    }
  }

  ngOnInit() {
    if (!this.isAdd) {
      this.fileId = this.record.id;
      this.loadData();
    } else {
      this.fileId = new GuidUtil().toString();
    }
  }




  loadData(): void {
       this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查信息是否完整');
      return;
    }
    
    param["bimInfoId"]  = this.bimInfoId;
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {
      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    this.bimInfoQqService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.hasToUpdate = true;
        this.msg.info('修改成功');
        this.Cancel();
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimInfoQqService.create(param).subscribe(res => {
      this.record = res.body;
      if (this.record) {
        this.hasToUpdate = true;
        this.fileId = this.record.id;
        this.msg.info('保存成功');
        this.Cancel();
      } else {
        this.msg.error('保存失败');
      }
    });
  }


  Cancel(){
    if (this.hasToUpdate  && '' !== this.record.id && this.isAdd) { // need
      let isChangeData = false;
      if ( this.toUpdateFkQRCode.length > 0) {
        isChangeData = true;
        for (const i of Object.keys(this.toUpdateFkQRCode)) {
          this.fileUploadService.updateFkBatch(this.toUpdateFkQRCode[i].response.fileFk, this.record.id)
            .subscribe((res: any) => {  this.nzModalRef.destroy();}, (res: HttpResponse<any>) => this.msg.create('error', '二维码上传失败'));
        }
      }
      if(!isChangeData){
        this.nzModalRef.destroy();
      }
    } else {
      this.nzModalRef.destroy();
    }
  }
  /**
    * 上传成功回调
    * @param file
    */
   onSuccessfulQRCode(file: any) {
    const thisSuccessFile = file.successFile;
    if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
      thisSuccessFile.forEach(item => {
        this.toUpdateFkQRCode.push(item);
        this.form.controls['userDefine2'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
      });
    }
  }

  /**
   * 删除成功回调
   * @param file
   */
  onDeleteFileQRCode(file: any) {
    if (file.file) {
      this.toUpdateFkQRCode = this.toUpdateFkQRCode.filter(d => d.uid !== file.file.uid);
    }
    this.form.controls['userDefine2'].setValue(null);
  }
}
