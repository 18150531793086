import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { HomeworkService } from '../homework.service';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { SelectsubjectComponent } from '../selectsubject/selectsubject.component';
import { CourseService } from '../../course/course.service';
import { SelectCourseComponent } from '../../topicManager/selectCourse/selectCourse.component';
@Component({
  selector: 'app-homeworkaddedit',
  templateUrl: './homeworkaddedit.component.html',
  styleUrls: ['./homeworkaddedit.component.less']
})
export class HomeworkaddeditComponent implements OnInit {
  isAdd: boolean;
  isSetSubject = true; // 是否设置了题目选项
  countSubjectScore = 0; // 计算总分数
  countSubject = 0; // 计算题目总数
  manualSubList = [  // 手动选择的题目信息
  ];
  autoSubList = [  // 自动选择的题目信息
  ];
  copyParams: any = {};
  manualTopicDataSet = [];
  autoTopicDataSet = [];
  haveSelected = false;
  loading = false;
  // 实体id
  entityId = '';
  public homeworkId: string;
  form: FormGroup;
  manualTypeDataSet = [];
  autoTypeDataSet = [];
  courseList = [];
  catalogList = [];
  homeworkType = [];
  manualTypeId0 = ''; // 手动单选题选择的题目id
  manualTypeId1 = ''; // 手动多选题选择的题目id
  manualTypeId2 = ''; // 手动判断题选择的题目id
  autoTypeId0 = ''; // 自动单选题选择的题目id
  autoTypeId1 = ''; // 自动多选题选择的题目id
  autoTypeId2 = ''; // 自动判断题选择的题目id
  topicIds = ''; // 题目id
  courseId = ''; // 课程id
  dataSet = [
    { queType: '单选题' },
    { queType: '多选题' },
    { queType: '判断题' },
  ];
  tokenData: any;
  accountName: String;
  constructor(
    private fb: FormBuilder,
    private homeworkService: HomeworkService,
    private nzModalRef: NzModalRef,
    public msg: NzMessageService,
    private modalHelper: ModalHelper,
    public courseService: CourseService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      eduCourseName: [null, [Validators.required]],
      eduCatalogId: [null, [Validators.required]],
      homeworkName: [null, [Validators.required]],
      homeworkHours: [null, [Validators.required]],
      totalScore: [null, [Validators.required]],
      isOk: ['1', [Validators.required]],
    });
    this.entityId = this.tokenService.get().entity_id;
    this.tokenData = this.tokenService.get();
    this.accountName = this.tokenService.get().account_name;
  }

  ngOnInit() {
   // this.getCourseMsg();
    if (!this.isAdd) {
      this.loadData();
      this.getExamType();
      this.getTopics();
     // this.getCourseMsg();
    } else {
      this.manualTypeDataSet = [
        { id: null, subject_type: '0', score: null, subject_quantity: null },
        { id: null, subject_type: '1', score: null, subject_quantity: null },
        { id: null, subject_type: '2', score: null, subject_quantity: null },
      ];
      this.autoTypeDataSet = [
        { id: null, subject_type: '0', score: null, subject_quantity: null },
        { id: null, subject_type: '1', score: null, subject_quantity: null },
        { id: null, subject_type: '2', score: null, subject_quantity: null },
      ];
    }

  }

  loadData(): void {
    this.homeworkService.find(this.homeworkId).subscribe((res: any) => {
      const record = res.body;
      this.msg.remove();
      if (record && record !== null) {
        const val = record;
       this.courseId = val.eduCourseId;
        this.form.controls.eduCatalogId.setValue(val.eduCatalogId);
        this.form.controls.homeworkName.setValue(val.homeworkName);
        this.form.controls.homeworkHours.setValue(val.homeworkHours);
        this.form.controls.totalScore.setValue(val.totalScore);
        this.form.controls.isOk.setValue(val.isOk);
        this.homeworkId = val.id;
        this.getCourseMsgById(this.courseId);
        this.getCatalogMsg(this.courseId);
      }
    });
  }
  // 获取课程数据
  getCourseMsg() {
    if (this.accountName === 'admin') {
      // 当登录为admin时查询所有的课程信息
      const isDelete = '0';
      this.homeworkService.queryCourseAdmin(isDelete)
        .subscribe((res: any) => {
          if (res.body && res.body.length) {
            this.courseList = res.body;
          }
        });
    } else {
      this.homeworkService.queryCourse(this.entityId).subscribe(res => {
        const reconds = res.body;
        if (reconds && reconds !== null && reconds.length > 0) {
          this.courseList = reconds;
        }
      });
    }
  }

 // 获取课程数据
 getCourseMsgById(courseId: string) {
  this.courseService.find(courseId).subscribe(res => {
    const reconds = res.body.courseName;
      this.form.controls.eduCourseName.setValue(reconds);
  });

}



  // 获取试卷类型
  getExamType() {
    this.homeworkService.queryHomeworkType(this.homeworkId).subscribe(res => {
      const reconds = res.body;
      if (reconds && reconds !== null && reconds.length > 0) {
        this.homeworkType = reconds;
        this.homeworkType.forEach(element => {
          const choose_type = element.choose_type;
          const t = {
            id: element.id, 'subject_type': element.subject_type,
            'score': element.score, 'subject_quantity': element.subject_quantity
          };
          if ('0' === choose_type) {
            this.manualTypeDataSet.push(t);
          } else {
            this.autoTypeDataSet.push(t);
          }
        });
      }
    });
  }
  courseChange(courseId: string) {
    this.catalogList = [];
    this.form.controls.eduCatalogId.setValue(null);
    this.getCatalogMsg(courseId);
  }


  // 获取课程章节数据
  getCatalogMsg(courseId: string) {
    this.homeworkService.queryCatalog(courseId).subscribe(res => {
      const reconds = res.body;
      if (reconds && reconds !== null && reconds.length > 0) {
        this.catalogList = reconds;
      }
    });
  }
  // 获取选中的题目id
  getTopics() {
    this.homeworkService.queryTopicsByHomeworkId(this.homeworkId).subscribe(res => {
      const reconds = res.body;
      if (reconds && reconds !== null && reconds.length > 0) {
        reconds.forEach(element => {
          const choose_type = element.choose_type;
          const subject_type = element.subject_type;
          if ('0' === choose_type) {
            if ('0' === subject_type) {
              this.manualTypeId0 = element.ids;
            } else if ('1' === subject_type) {
              this.manualTypeId1 = element.ids;
            } else if ('2' === subject_type) {
              this.manualTypeId2 = element.ids;
            }
          } else if ('1' === choose_type) {
            if ('0' === subject_type) {
              this.autoTypeId0 = element.ids;
            } else if ('1' === subject_type) {
              this.autoTypeId1 = element.ids;
            } else if ('2' === subject_type) {
              this.autoTypeId2 = element.ids;
            }
          }
        });
      }
    });
  }
  // 手动选题
  openSelectSubjectModal(data) {
    const courseId = this.form.controls.eduCourseId.value;
    if (!courseId) {
      this.msg.info('请先选择课程，再选择题目');
      return;
    }
    const catalogId = this.form.controls.eduCatalogId.value;
    if (!catalogId) {
      this.msg.info('请先选择课时，再选择题目');
      return;
    }
    const subjectType = data.subject_type;
    const subjectQuantity = data.subject_quantity;
    const score = data.score;
    if (!(subjectQuantity > 0 && score > 0)) {
      this.msg.info('请先设置分数和题目数量并且大于0，再选择题目');
      return;
    }

    let ids = null;
    let selIds = null;
    if ('0' === subjectType) {
      selIds = this.manualTypeId0;
      ids = this.autoTypeId0;
    } else if ('1' === subjectType) {
      selIds = this.manualTypeId1;
      ids = this.autoTypeId1;
    } else if ('2' === subjectType) {
      selIds = this.manualTypeId2;
      ids = this.autoTypeId2;
    }

    // 选择题目
    this.modalHelper.open(SelectsubjectComponent, {
      isAdd: true, courseId: courseId, catalogId: catalogId, subjectType: subjectType,
      subjectQuantity: subjectQuantity, ids: ids, selIds: selIds
    }, 'xl', { nzClassName: 'select-modal' }).subscribe(res => {
      const subjectId = res.subjectId;
      const type = subjectType;
      if ('0' === type) {
        this.manualTypeId0 = subjectId;
      } else if ('1' === type) {
        this.manualTypeId1 = subjectId;
      } else if ('2' === type) {
        this.manualTypeId2 = subjectId;
      }
    });

  }

  // 自动选题
  autoSelectSubjectModal(data) {
    const courseId = this.form.controls.eduCourseId.value;
    if (!courseId) {
      this.msg.info('请先选择课程，再选择题目');
      return;
    }
    const catalogId = this.form.controls.eduCatalogId.value;
    if (!catalogId) {
      this.msg.info('请先选择课时，再选择题目');
      return;
    }
    const subjectType = data.subject_type;
    const subjectQuantity = data.subject_quantity;
    const score = data.score;
    if (!(subjectQuantity > 0 && score > 0)) {
      this.msg.info('请先设置分数和题目数量并且大于0，再选择题目');
      return;
    }

    let ids = null;
    if ('0' === subjectType) {
      ids = this.manualTypeId0;
    } else if ('1' === subjectType) {
      ids = this.manualTypeId1;
    } else if ('2' === subjectType) {
      ids = this.manualTypeId2;
    }
    const copyParams = {};
    copyParams['subjectType'] = subjectType;
    copyParams['subjectQuantity'] = subjectQuantity;
    copyParams['courseId'] = courseId;
    copyParams['catalogId'] = catalogId;
    copyParams['ids'] = ids;
    this.homeworkService.querySubjectToAutoSelect(copyParams)
      .subscribe((res: any) => {
        const isSuccess = res.body.success;
        if (isSuccess) {
          const subjectId = res.body.data;
          const type = subjectType;
          if ('0' === type) {
            this.autoTypeId0 = subjectId;
          } else if ('1' === type) {
            this.autoTypeId1 = subjectId;
          } else if ('2' === type) {
            this.autoTypeId2 = subjectId;
          }
          this.msg.info('抽取成功');
        } else {
          this.msg.info(res.body.message);
        }
      });
  }
  // 保存前校验
  dataVerify() {
    this.isSetSubject = true;
    this.countSubjectScore = 0;
    this.countSubject = 0;
    try {
      this.manualTypeDataSet.forEach(element => {
        //    { subject_type: '0', score: null, subject_quantity: null },
        const subject_type = element.subject_type;
        const score = element.score;
        const subject_quantity = element.subject_quantity;
        this.topicIds = null;
        if (!!score || !!subject_quantity) {
          if (!score && '0' === subject_type) {
            this.msg.info('请填写手动配题中单选题单道分数');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!score && '1' === subject_type) {
            this.msg.info('请填写手动配题中多选题单道分数');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!score && '2' === subject_type) {
            this.msg.info('请填写手动配题中判断题单道分数');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!subject_quantity && '0' === subject_type) {
            this.msg.info('请填写手动配题中单选题数量');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!subject_quantity && '1' === subject_type) {
            this.msg.info('请填写手动配题中多选题数量');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!subject_quantity && '2' === subject_type) {
            this.msg.info('请填写手动配题中判断题数量');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if ('0' === subject_type) {
            this.topicIds = this.manualTypeId0;
            const idArr = this.topicIds.split(',');
            if (!!subject_quantity && subject_quantity !== idArr.length) {
              this.msg.info('手动配题设置单选题题目数量为' + subject_quantity + ',实际选择题目数为 ' + idArr.length + ',请修改题目数或重新选取');
              this.isSetSubject = false;
              throw new Error('error');
            }
          }
          if ('1' === subject_type) {
            this.topicIds = this.manualTypeId1;
            const idArr = this.topicIds.split(',');
            if (!!subject_quantity && subject_quantity !== idArr.length) {
              this.msg.info('手动配题设置多选题题目数量为' + subject_quantity + ',实际选择题目数为 ' + idArr.length + ',请修改请修改题目数或重新选取');
              this.isSetSubject = false;
              throw new Error('error');
            }
          }
          if ('2' === subject_type) {
            this.topicIds = this.manualTypeId2;
            const idArr = this.topicIds.split(',');
            if (!!subject_quantity && subject_quantity !== idArr.length) {
              this.msg.info('手动配题设置判断题题目数量为' + subject_quantity + ',实际选择题目数为 ' + idArr.length + ',请修改请修改题目数或重新选取');
              this.isSetSubject = false;
              throw new Error('error');
            }
          }
          this.countSubjectScore += parseFloat(subject_quantity) * parseFloat(score);
          this.countSubject += parseInt(subject_quantity, 10);
        }
      });
    } catch (error) {
    }
    try {
      this.autoTypeDataSet.forEach(element => {
        //    { subject_type: '0', score: null, subject_quantity: null },
        const subject_type = element.subject_type;
        const score = element.score;
        const subject_quantity = element.subject_quantity;
        if (!!score || !!subject_quantity) {
          if (!score && '0' === subject_type) {
            this.msg.info('请填写自动配题中单选题单道分数');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!score && '1' === subject_type) {
            this.msg.info('请填写自动配题中多选题单道分数');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!score && '2' === subject_type) {
            this.msg.info('请填写自动配题中判断题单道分数');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!subject_quantity && '0' === subject_type) {
            this.msg.info('请填写自动配题中单选题数量');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!subject_quantity && '1' === subject_type) {
            this.msg.info('请填写自动配题中多选题数量');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if (!subject_quantity && '2' === subject_type) {
            this.msg.info('请填写自动配题中判断题数量');
            this.isSetSubject = false;
            throw new Error('error');
          }
          if ('0' === subject_type) {
            this.topicIds = this.autoTypeId0;
            const idArr = this.topicIds.split(',');
            if (!!subject_quantity && subject_quantity !== idArr.length) {
              this.msg.info('自动配题设置单选题题目数量为' + subject_quantity + ',实际抽取题目数为 ' + idArr.length + ',请修改题目数或重新抽取');
              this.isSetSubject = false;
              throw new Error('error');
            }
          }
          if ('1' === subject_type) {
            this.topicIds = this.autoTypeId1;
            const idArr = this.topicIds.split(',');
            if (!!subject_quantity && subject_quantity !== idArr.length) {
              this.msg.info('自动配题设置多选题题目数量为' + subject_quantity + ',实际抽取题目数为 ' + idArr.length + ',请修改题目数或重新抽取');
              this.isSetSubject = false;
              throw new Error('error');
            }
          }
          if ('2' === subject_type) {
            this.topicIds = this.autoTypeId2;
            const idArr = this.topicIds.split(',');
            if (!!subject_quantity && subject_quantity !== idArr.length) {
              this.msg.info('自动配题设置判断题题目数量为' + subject_quantity + ',实际抽取题目数为 ' + idArr.length + ',请修改题目数或重新抽取');
              this.isSetSubject = false;
              throw new Error('error');
            }
          }
          this.countSubjectScore += parseFloat(subject_quantity) * parseFloat(score);
          this.countSubject += parseInt(subject_quantity, 10);
        }
      });
    } catch (error) {
    }

  }
  submit() {
    this.dataVerify();
    if (!this.isSetSubject) {
      return;
    }
    const totalScore = this.form.controls.totalScore.value;
    // tslint:disable-next-line:triple-equals
    if (0 != this.countSubjectScore && totalScore != this.countSubjectScore) {
      this.msg.info('作业总分与配题中分数总分不一致，请修改');
      return;
    }

    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (!this.form.controls[key].value) {
        return;
      }
      param[key] = this.form.controls[key].value;
    }
    param['manualType'] = this.manualTypeDataSet;
    param['autoType'] = this.autoTypeDataSet;
    param['id'] = this.homeworkId;
    param['countSubject'] = this.countSubject;
    param['homeworkHours'] = this.form.value.homeworkHours + '';
    param['totalScore'] = this.form.value.totalScore + '';
    param['eduCourseId'] = this.courseId;
    this.manualSubList.push({ 'type': '0', 'ids': this.manualTypeId0 });
    this.manualSubList.push({ 'type': '1', 'ids': this.manualTypeId1 });
    this.manualSubList.push({ 'type': '2', 'ids': this.manualTypeId2 });
    param['manualSubList'] = this.manualSubList;
    this.autoSubList.push({ 'type': '0', 'ids': this.autoTypeId0 });
    this.autoSubList.push({ 'type': '1', 'ids': this.autoTypeId1 });
    this.autoSubList.push({ 'type': '2', 'ids': this.autoTypeId2 });
    param['autoSubList'] = this.autoSubList;

    this.homeworkService.updateHomeworkInfo(param).subscribe(res => {
      const record = res.body;
      if (record) {
        this.msg.info('修改成功');
        this.nzModalRef.destroy(true);
      } else {
        this.msg.error('修改失败');
      }
    });
  }

  selectCourse() {
    // 选择课程
    this.modalHelper.open(SelectCourseComponent, {
      isAdd: true}, 'xl', { nzClassName: 'select-modal' }).subscribe(res => {
        this.form.controls.eduCatalogId.setValue(null);
        this.catalogList = [];
        this.getCatalogMsg(res.courseId);
        this.form.controls.eduCourseName.setValue(res.courseName);
      //  this.form.controls.eduCourseId.setValue(res.courseId);
      this.courseId = res.courseId;
    });
    }



}
