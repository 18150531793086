export class BimSchools {
  constructor(
    public  id?: string,
    public  bimInfoId?: string,
    public  location?: string,
    public  name?: string,
    public  email?: string,
    public  regCode?: string,
    public  sendMail?: string,
    public  schoolType?: string,
    public  logoPath?: string,
    public  college?: string,
    public  professional?: string,
    public  address?: string,
    public  collegeContact?: string,
    public  freqContact?: string,
    public  recommendSchool?: string,
    public  recommendContry?: string,
    public  audit?: number,
    public  createTime?: Date,
    public  creator?: string,
    public  updateTime?: Date,
    public  updator?: string,
    public  userDefine1?: string,
    public  userDefine2?: string,
    public  userDefine3?: string,
    public  userDefine4?: string,
    public  userDefine5?: string,
    public  userDefine6?: string,
    public  belongType?: string,
    public  cfmPwd?: string,
    public  province?: string,
    public  pwd?: string,
    public  schoolBsType?: number,
  ) {}
}



export class SchoolPerson {
  constructor(
    public id?:string,
    public  name?: string,
    public  zw?: string,
    public  tel?: string,
    public  mobile?: string,
    public  email?: string,
    public  qq?: string,
    public  card?: string,
    public  type?: string,
  ) {}
}
