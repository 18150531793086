import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {Teacher } from './teacher.model';
import {ThsBaseService } from "@shared/components/thsBaseService";
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { School } from '@shared/components/provincesColleges/school.model';

@Injectable({ providedIn: 'root' })
export class TeacherService extends ThsBaseService<Teacher> {
 
  private schoolUrl='';
   
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-teachers`);
      this.schoolUrl=httpAddressService.EduServe +'/edu-schooltree';
  }
 
  // getTypeFromDict(typeId :string){
  //   this.http.get('/thsadmin/api/sys-dicts/' + typeId, {observe: 'response'}).subscribe((res: HttpResponse<any[]>) => {
  //     this.count = parseInt(res.headers.get('x-total-count'));
  //   });
  // }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}

queryStuent (queryParams ?: any): Observable<HttpResponse<any>> {
  
  const url = `${this.resourceUrl}/getStudents/`;
  return this.http.get<any>(url, {params: queryParams});
  
}
  // hasUserAccount(userAccount : string): Observable<any>{
  //   const url = `${this.resourceUrl}/hasUserAccount/${userAccount}`;
  //   return this.http.get(url);
  // }

  // updateMultiTenancyIdByEntityId(entityId: string, multiTenancyId: string): Observable<any>{
  //   const url = `${this.resourceUrl}/updateMultiTenancyId/${entityId}/${multiTenancyId}`;
  //   return this.http.get(url);
  // }


  getSchoolTree() {
    const schoolTree = [];
    this.http.get<School[]>(this.schoolUrl+'/tree', {  observe: 'response' }) .subscribe(res => {
      const list =  res.body;
     
      schoolTree.push(list);
      console.log(schoolTree);
     /*  if (list && list.length) {
       list.forEach(element => {
        schoolTree.push();
        })
       
       } */
    });
    return schoolTree;
  }


}

