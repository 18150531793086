import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ThsNewsEditComponent } from '../ths-news/edit/edit.component';
import { ThsNewsService } from '../ths-news/ths-news.service';
import { BimCoopCollegeEditComponent } from './edit/edit.component';
import { COMMON_DATA } from '@shared/app.commonData';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';

@Component({
  selector: 'app-ths-coop-college',
  templateUrl: './bim-coop-college.component.html',
  styleUrls: []
})
export class BimCoopCollegeComponent implements OnInit {

  /**
   * contentType
  */
  @Input() contentType = ['coopCollege','coopCollegeProvince','coopCollegeEnterprise'];
  CONTENT_TYPE = COMMON_DATA.sysNewsContentType;

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public currentBimInfo = null;
  public bimInfoStatus = -1;

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  bimInfoList = [];

  public queryParams = {
    // 查询搜索框的字段要预置
    'content.contains': null,
    'searchValue.contains': null,
    'contenttype.equals': null,
    'bimInfoId.in': null,
    // sort固定
    sort: ['istop,desc', 'author,asc', 'createTime,desc']
  };

  columns: SimpleTableColumn[] = [
    { title: '学校名称', index: 'title', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '内容类型', index: 'contenttype', width: '8%', format: (item: any, col: any) => this.formatContentTypeCol(item, col) },
    { title: '所属赛事', index: 'bimInfoName', width: '25%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '排序', index: 'author', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '链接网址', index: 'remark', width: '25%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '是否显示', index: 'istop', width: '6%',
      format: (item: any) => {
        return item.istop === 1 ? '是' : '否';
      }
    },
    {
      title: '操作', width: '10%',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.remove(item) }
      ],
    }
  ];

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private fileUploadService: FileUploadService,
    private thsNewsService: ThsNewsService,
    private bimInfoService: BimInfoService
  ) { }

  ngOnInit(): void {
    console.log(this.contentType);

    if(this.contentType && this.contentType.length > 0){
      this.CONTENT_TYPE = COMMON_DATA.sysNewsContentType.filter(w=>this.contentType.includes(w.value));
      this.getBimInfoList(this.contentType);
    }
    
    this.getDataList();
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.thsNewsService.query(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }else{
        //获取所属赛事字段
        const bimInfoIds =  res.body.filter(w=>!isNullOrUndefined(w.bimInfoId)).map(w=>w.bimInfoId);
        if(bimInfoIds && bimInfoIds.length > 0){
          const params = {
            'id.in': bimInfoIds,
            page:0,
            size: bimInfoIds.length
          }
          this.bimInfoService.query(params).subscribe(res => {
            const datas = res.body;
            const listData = this.list;
            listData.forEach(item => {
              if(!isNullOrUndefined(item.bimInfoId)){
                const selDatas = datas.filter(w => w.id === item.bimInfoId);
                item['bimInfoName'] = !selDatas || selDatas.length <= 0 ? '' : selDatas[0].name;
              }
            })

            this.list = [].concat(listData);
          })
        }
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else if(key != 'sort'){
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    if (!isNullOrUndefined(this.contentType)) {
      //copyParams['contenttype.equals'] = this.contentType;
      copyParams['contenttype.in'] = this.contentType;
    }
    // if (!isNullOrUndefined(this.currentBimInfo)) {
    //   copyParams['bimInfoId.equals'] = this.currentBimInfo.id;
    // }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  logContentType(event){
    this.queryParams['bimInfoId.in'] = null;
    
    const datas = this.CONTENT_TYPE.filter(w=>w.value === event);
    if(datas && datas.length > 0){
      const type = datas[0].type;
      if(type != 'bim'){
        this.getBimInfoList([type]);
      }
    }
  }

  getBimInfoList(type) {
    this.bimInfoService.query({
      'size' : '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'type.in': type,
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
      this.bimInfoList = res.body;
    });
  }

  /**
  * 新增页面
  */
  add() {
    debugger;
    const dataId = new GuidUtil().toString();
    this.modal.static(BimCoopCollegeEditComponent, {
      isAdd: true,
      record: { id: null },
      dataId: dataId,
      contentType: this.queryParams['contenttype.equals']
      // contentType: Array.isArray(this.contentType) ? this.contentType.join(',') : this.contentType
    }).subscribe((res) => {
      console.log(res);
      if (res === 'success') {
        this.getDataList();
      } else {
        this.fileUploadService.deleteAllByFk(dataId).subscribe();
      }
    });
  }

  /**
   * 编辑页面
   */
  edit(item) {
    this.modal.static(BimCoopCollegeEditComponent, {
      isAdd: false,
      record: item,
      contentType: item.contenttype
      // contentType: Array.isArray(this.contentType) ? this.contentType.join(',') : this.contentType
    }).subscribe((res) => {
      if (res === 'success') {
        this.getDataList();
      }
    });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteData(item.id);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteData(dataId) {
    this.isloadding = true;
    this.thsNewsService.delete(dataId).subscribe(() => {
      this.isloadding = false;
      this.fileUploadService.deleteAllByFk(dataId).subscribe();
      this.msg.remove();
      this.msg.info('删除成功！');
      this.getDataList();
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

  formatContentTypeCol(item: any, col: any) {
    const selList = this.CONTENT_TYPE.filter(w=>w.value == item[col.indexKey]);
    if(selList && selList.length > 0){
      return selList[0].itemText;
    }
    return '';
  }

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    // this.getDataList();
  }

  searche(){
    this.page = 0;
    this.getDataList();
  }
}
