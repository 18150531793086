import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BimSoftDownloadDetailsService } from '../bim-info-soft.service';

@Component({
  selector: 'app-bim-info-soft-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoSoftEditComponent implements OnInit, OnDestroy {

  record: any = {};
   // 是否增加
  isAdd: boolean;

  form: FormGroup;
  public isSaving = false;


  constructor(
    private fb: FormBuilder,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimSoftDownloadDetailsService: BimSoftDownloadDetailsService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [null, []],
      softTypeId: [null, []],
      name: [null, [Validators.required, Validators.maxLength(20)]],
      isAble: [null, []],
      orderNum: [null, []],
    });
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      param[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      return;
    }
    this.checkData(param);
  }

  checkData(param) {
    this.isSaving = true;
    this.bimSoftDownloadDetailsService.query({
      'softTypeId.equals': param['softTypeId'],
      'name.equals': param['name']
    }).subscribe(res => {
      const data = res.body;
      let isRepeat = false; 
      if (data.length > 0) {
        if (!this.isAdd) {
          if (param['id'] != data[0].id) {
            isRepeat = true;
          }
        } else {
          isRepeat = true;
        }
      }
      if (isRepeat) {
        this.isSaving = false;
        this.msg.remove();
        this.msg.warning('该标签已存在，请修改名称！');
      } else {
        this.saveData(param);
      }
    })
  }

  saveData(param) {
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {
      this.modifyDataInfo(param);
    }
  }


  modifyDataInfo(param: any) {
    this.isSaving = true;
    this.bimSoftDownloadDetailsService.updateSoftInfoById(param).subscribe(res => {
      this.isSaving = false;
      const record = res.body;
      if (record  ) {
        this.msg.info('修改成功');
        this.close(true);
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.isSaving = true;
    this.bimSoftDownloadDetailsService.create(param).subscribe(res => {
      this.isSaving = false;
      this.record = res.body;
      if (this.record) {
        this.msg.info('保存成功');
        this.close(true);
      } else {
        this.msg.error('保存失败');
      }
    });
  }

   // 关闭事件
   close(type?) {
    this.nzModalRef.destroy(type);
   }

   ngOnDestroy(): void {
   }
}
