import {Component, Inject, OnInit} from '@angular/core';
import {NzMessageService, NzModalRef, UploadFile, UploadFilter, UploadXHRArgs} from "ng-zorro-antd";
import {HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {HttpAddressService} from "@shared/session/http-address.service";
import {DA_SERVICE_TOKEN, ITokenService} from "@delon/auth";
import {BimReceiptInstrictor} from "../../bim-receipt-info.model";
import {GuidUtil} from "@shared/utils/guid.util";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-receipt-upload-file',
  templateUrl: './receipt-upload-file.component.html',
  styles: []
})
export class ReceiptUploadFileComponent implements OnInit {

  receiptInfo: BimReceiptInstrictor;

  uploadAddress = '/thsadmin/api/sys-files/upload';
  uploadFileType: string[] = ['.pdf','.jpeg','.jpg','.png'];//后缀
  fileList = [];
  uploading = false;
  filters: UploadFilter[] = [
    {
      name: 'type',
      fn  : (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => {
          const fileSuffix = w.name.substring(w.name.lastIndexOf('.'));
          if (!isNullOrUndefined(fileSuffix)) {
            return this.uploadFileType.includes(fileSuffix.toLocaleLowerCase());
          } else {
            return false;
          }
        });
        if (filterFiles.length !== fileList.length) {
          this.msg.error(`包含文件格式不正确，只支持 ${this.uploadFileType.join('、 ')} 格式`);
          return filterFiles;
        }
        return fileList;
      }
    }
  ];
  currentUserId = '';

  previewImage = '';
  previewVisible = false;

  imageList = [];
  pdfList = [];

  constructor(
    private nzModalRef: NzModalRef,
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
    private http: HttpClient,
    private httpAddressService: HttpAddressService,
    private msg: NzMessageService,
    private thsadminFileService: ThsadminFileService,
  ) {
    this.currentUserId = this.tokenService.get().account_id;
  }

  ngOnInit() {
    this.getFiles();
  }

  getFiles() {
    const params = {
      'fileFk.equals': this.receiptInfo.id,
    };
    this.thsadminFileService.queryFiles(params).subscribe(res => {
      if (res && res.body) {
        this.fileList = [];
        this.imageList = [];
        this.pdfList = [];
        res.body.forEach(item => {
          let file = {
            uid: item.id,
            name: item.fileName,
            size: item.fileSize,
            url: this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id,
            path: item.fileSavePath
          };
          if (item.fileType === '.pdf') {
            this.pdfList.push(file);
          } else {
            this.imageList.push(file);
          }
          this.fileList.push(file);
        });
      }
    })
  }

  onClose() {
    this.nzModalRef.destroy();
  }

  beforeUpload = (file: File) => {
    if (this.receiptInfo && this.receiptInfo.id && this.receiptInfo.id.length > 0) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.'));
      if (!isNullOrUndefined(fileSuffix) && !this.uploadFileType.includes(fileSuffix.toLocaleLowerCase())) {
        this.msg.error('请上传后缀为 ' + this.uploadFileType.join(',') + ' 的文件');
        return false;
      }
      if (file.size > 10 * 1024 * 1024) {
        this.msg.error('文件最大不能超过 10M');
        return false;
      }
      return true;
    } else {
      this.msg.warning('请先保存回执信息！');
      return false;
    }

  };

  handleChange(info: { file: UploadFile }): void {
    if (info.file.status === 'uploading') {
      this.uploading = true;
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file.originFileObj);
      // this.getBase64(info.file.originFileObj, (img: string) => {
      //   this.loading = false;
      //   this.avatarUrl = img;
      // });
    }
  }

  upload = () => {
    this.nzModalRef.destroy();
    this.msg.success('上传成功！');
    

  }

  customReq = (item: UploadXHRArgs) => {
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('multipartFile', item.file as any);
    formData.append('name', item.file.name || '');
    formData.append('fileFk', this.receiptInfo.id);
    formData.append('fileDesc', item.file.name);
    formData.append('uploader', this.currentUserId);
    // formData.append('extField1',this.uploadType);
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress : true,
      withCredentials: true
    });
    // 始终返回一个 `Subscription` 对象，nz-upload 会在适当时机自动取消订阅
    return this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total > 0) {
          // tslint:disable-next-line:no-any
          (event as any).percent = event.loaded / event.total * 100;
        }
        // 处理上传进度条，必须指定 `percent` 属性来表示进度
        item.onProgress(event, item.file);
      } else if (event instanceof HttpResponse) {
        // 处理成功
        item.onSuccess(event.body, item.file, event);
      }
      if (event.type === HttpEventType.Response) {
        item.file.uid = event.body.id;
        item.file.url = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + event.body.id;
        this.fileList.push(item.file);
        console.log('fileList:',this.fileList);
        if (item.file.type === 'application/pdf') {
          this.pdfList.push(item.file);
        } else {
          this.imageList.push(item.file);
        }
      }
    }, (err) => {
      // 处理失败
      item.onError(err, item.file);
    });
  };

  handlePreview = (url: any) => {
    this.previewImage = url;
    this.previewVisible = true;
  };

  remove(file) {
    // this.fileList.splice(this.fileList.indexOf(file),1);
    // this.avatarUrl = null;
    this.thsadminFileService.deleteFileById(file.uid).subscribe(res => {
      const index1 = this.fileList.indexOf(file);
      // const index2 = this.fileList2.indexOf(file);
      if (index1 > -1) {
        this.fileList.splice(index1,1);
        // } else if (index2 > -1) {
        //   this.fileList2.slice(index2, 1);
      }
      const index2 = this.pdfList.indexOf(file);
      if (index2 > -1) {
        this.pdfList.splice(index2,1);
      }
      const index3 = this.imageList.indexOf(file);
      if (index3 > -1) {
        this.imageList.splice(index3,1);
      }
    })
  }

  openPdf(url) {
    window.open(url);
  }

}
