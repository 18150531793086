import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { WorkdetailsComponent } from './workdetails/workdetails.component';
import { ExamdetailsComponent } from './examdetails/examdetails.component';
import { DetailstRoutingModule } from './detailst-routing.module';
import { HomeworkdetailComponent } from './workdetails/homeworkdetail/homeworkdetail.component';
import { ExamindetailsComponent } from './examdetails/examindetails/examindetails.component';


const COMPONENTS = [
  WorkdetailsComponent,
  ExamdetailsComponent,
];
const COMPONENTS_NOROUNT = [
  HomeworkdetailComponent,
  ExamindetailsComponent,
];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DetailstRoutingModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  providers: [
    WorkdetailsComponent,
    // ExamdetailsComponent,
    HomeworkdetailComponent,
    ExamindetailsComponent,
  ],
  entryComponents: COMPONENTS_NOROUNT
})
export class DetailstModule { }
