import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalRef,NzModalService } from 'ng-zorro-antd';
import { HttpResponse } from '@angular/common/http';

import { YearGoalsReportService } from "./../yearGoalsReport.service";
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { DictService } from 'app/shared/components/dict/dict.service';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-iucce-yeargoals-report-edit',
  templateUrl: './edit.component.html'
})
export class YearGoalsReportEditComponent implements OnInit {
  
    validateForm: FormGroup;
    isAdd = true;
    isView = false;//查看
    record: any;
    yearList = [];//年份
    batchList = [];//批次
    prjTypeList = [];//项目类别
    submitting = false;

  constructor(public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public nzModalRef: NzModalRef,
    private modalService: NzModalService,
    private dictService: DictService,
    private dictDetailService: DictDetailService,
    private fb: FormBuilder,
    private yearGoalsReportService : YearGoalsReportService) { }

  ngOnInit(): void {
    this.getYearListDict();

    this.validateForm = this.fb.group({
        year: [null, [Validators.required]],
        batchId: [!this.isAdd || this.isView ? this.record.batchId : null, [Validators.required]],
        prjTypeId: [!this.isAdd || this.isView ? this.record.prjTypeId : null, [Validators.required]],
        declareQuantity: [!this.isAdd || this.isView ? this.record.declareQuantity : null, [Validators.required]],
        declareAmount: [!this.isAdd || this.isView ? this.record.declareAmount : null, [Validators.required]],
        totalAmount: [!this.isAdd || this.isView ? this.record.totalAmount : 0, [Validators.required]],
        batch:[!this.isAdd || this.isView ? this.record.batch : null],
        prjType:[!this.isAdd || this.isView ? this.record.prjType : null]

      });
  }

  /**
   * 获取年份数据
   */
  getYearListDict(){
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
          
          if(!this.isAdd && this.record.year != null){
            this.validateForm.get('year').setValue(this.record.year.toString());
          }
      }
    });
  }

  /**
   * 选择年份触发
   * @param item 
   */
  fnChangeYear(item){
    //加载批次和项目类别
     this.validateForm.controls['batch'].setValue('');
     this.validateForm.controls['prjType'].setValue('');
     this.validateForm.controls['batchId'].setValue('');
     this.validateForm.controls['prjTypeId'].setValue('');

    this.batchList = [];
    this.prjTypeList = [];
    this.dictService.findAllByDictNo("batchList").subscribe(res => {
      if (res.body && res.body.length) {
          const result = res.body;
          const resultQuery = result.filter(w=>w.itemValue2 === item);
          if(resultQuery && resultQuery.length > 0){
            this.dictDetailService.query({ 'parentId.equals':resultQuery[0].id,'size':1000,'sort': 'orderNum,asc' }).subscribe((res: any) => {
              this.batchList = res.body;
              if(!this.isAdd){
                this.validateForm.get('batchId').setValue(this.record.batchId);
              }
            });
          }
      }
    });

    //获取项目类别
    this.dictService.findAllByDictNo("prjTypes").subscribe(res => {
      if (res.body && res.body.length) {
          const result = res.body;
          const resultQuery = result.filter(w=>w.itemValue2 === item);
          if(resultQuery && resultQuery.length > 0){
            this.dictDetailService.query({ 'parentId.equals':resultQuery[0].id,'size':1000,'sort': 'orderNum,asc' }).subscribe((res: any) => {
              this.prjTypeList = res.body;
              if(!this.isAdd){
                this.validateForm.get('prjTypeId').setValue(this.record.prjTypeId);
              }
            });
          }
      }
    });
   
  }

  /**
   * 选择批次改变事件
   * @param item 
   */
  fnChangeBatch(item){
    if(!item){
        return;
    }
    let queryList = this.batchList.filter(w=>w.id == item);
    if(queryList && queryList.length > 0){
        let control = this.validateForm.get('batch');
        if(control){
            control.setValue(queryList[0].itemValue1);
        }
    }
  }
  /**
   * 选择项目类别改变事件
   * @param item 
   */
  fnChangePrjType(item){
    if(!item){
        return;
    }
    let queryList = this.prjTypeList.filter(w=>w.id == item);
    if(queryList && queryList.length > 0){
        let control = this.validateForm.get('prjType');
        if(control){
            control.setValue(queryList[0].itemValue1);
        }
    }
  }
  
  isNumber(number) {
	return typeof number === 'number';
  }

  /**
   * 数量改变事件
   * @param control 
   */
  fnChangeQuantity(control){
    let quantity = this.isNumber(control) ? control : control.target.value;
    let amount = this.validateForm.controls['declareAmount'].value;
    this.fnChangeTotalAmount(quantity,amount);
  }
  /**
   * 金额改变事件
   * @param control 
   */
  fnChangeAmount(control){
    let quantity = this.validateForm.controls['declareQuantity'].value;
    let amount = this.isNumber(control) ? control : control.target.value;
    this.fnChangeTotalAmount(quantity,amount);
  }

  /**
   * 计算总金额
   * @param quantity 数量
   * @param amount 金额
   */
  fnChangeTotalAmount(quantity,amount){
    let quantity2 = parseFloat(quantity);
    let amount2 = parseFloat(amount);
    if(isNaN(quantity2)){
        quantity2 = 0;
    }
    if(isNaN(amount2)){
        amount2 = 0;
    }
    
    this.validateForm.controls['totalAmount'].setValue((quantity2 * amount2).toFixed(2)); 
  }
  
  /**
   * 保存
   * @returns 
   */
  submit(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return;
    this.submitting = true;

    //let recordInfo = Object.assign(this.record, this.validateForm.value);
    let recordInfo = Object.assign({},this.record);
    recordInfo = Object.assign(recordInfo, this.validateForm.value);
    let queryParams = {
        'year.in':recordInfo.year,
        'batchId.in':recordInfo.batchId,
        'prjTypeId.in':recordInfo.prjTypeId,
        size : 1000
    };
    
    this.yearGoalsReportService.query(queryParams)
        .subscribe(res=>{
            let result = res.body;
            if(this.record.id == null){
                if(result && result.length > 0){
                    this.msg.error('该年已添加相同批次和项目类别的数据，无需重复添加！');
                    this.submitting = false;
                }else{
                    this.yearGoalsReportService.create(recordInfo)
                        .subscribe((response) => {
                            setTimeout(() => {
                                this.msg.success('保存成功');
                                this.record = recordInfo;
                                this.submitting = false;
                                this.close(true);
                            }, 1000);
                        },
                        (res: HttpResponse<any>) => {
                            this.submitting = false;
                            this.msg.success('保存失败');
                            this.close(false);
                        }
                    );
                }
            }else{
                if(result && result.length > 0){
                    let queryResult = result.filter(w=>w.id != this.record.id);
                    if(queryResult && queryResult.length > 0){
                        this.msg.error('该年已添加相同批次和项目类别的数据，无需重复添加！');
                        this.submitting = false;
                        return;
                    }
                }

                this.yearGoalsReportService.update(recordInfo).subscribe((response) => {
                    setTimeout(() => {
                        this.msg.success('更新成功');
                        this.record = recordInfo;
                        this.submitting = false;
                        this.close(true);
                    }, 1000);
                  }, (res: HttpResponse<any>) => {
                    setTimeout(() => {
                        this.msg.success('更新失败');
                        this.submitting = false;
                        this.close(false);
                    }, 1000);
                  });
            }
        }
    );
  }

  /**
   * 关闭
   * @param result 
   */
  close(result: any): void {
    this.nzModalRef.destroy(result);
  }

}
