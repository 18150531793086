import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimItems } from './bim-items.model';
import {ThsBaseService } from  '../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimItemsService extends ThsBaseService<BimItems> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-items`);
    }


           /** GET: 分页、多条件查询记录列表 */
        getAllBimItemsInBimEvents (eventId:string,queryParams ?: any): Observable<HttpResponse<BimItems[]>> {
            let url = this.resourceUrl + `/getAllBimItemsInBimEvents/${eventId}`;
            return this.http.get<BimItems[]>(url, {params: queryParams ,  observe: 'response'})
            .pipe(map((res: HttpResponse<BimItems[]>) => this.convertDateArrayFromServer(res)));
        }

}

