import { Component, OnInit } from "@angular/core";
import { SimpleTableData, SimpleTableColumn } from "@delon/abc";
import { MessageHelper } from "@shared/helpers/MessageHelper";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { isNullOrUndefined } from "util";
import { BimInfoEventsService } from "../bim-info/bim-info-events/bim-info-events.service";
import { BimInfoService } from "../bim-info/bim-info.service";
import { BimItemsService } from "../bim-items/bim-items.service";
import { BimWorks } from "../work-manage/work-manage.model";
import { BimTeamsService } from "../bim-info/bim-teams/bim-teams.service";
import { ModalHelper } from "@delon/theme";
import { WorkReplyFileAddComponent } from "./add/add.component";
import { GroupNumListComponent } from "./group-num-list/group-num-list.component";
import { WorkManageService } from "../work-manage/work-manage.service";
import { ReplyFileSuppleComponent } from "./reply-file-supple/reply-file-supple.component";
import { ExportProgressComponent } from "../components/export-progress/export-progress.component";
import { GuidUtil } from '@shared/utils/guid.util';

@Component({
    selector: 'app-work-reply-file-manage',
    templateUrl: './work-reply-file-manage.component.html',
    styles: []
  })
  export class WorkReplyFileManageComponent implements OnInit {

    bimInfoList: any = [{ id: '', name: '' }];
    bimInfoEventsList: any = [{ id: '', name: '' }];
  
    public oriBimInfoEvent = [];
  
    isQueryEvent = false;
  
    curBimInfoName = '';
  
    page = 0;
    size = 20;
    total = 0;
    public pageSizeOptions = [10, 20, 50, 100];
    selectedRows: SimpleTableData[] = [];
    list: BimWorks[] = [];
    loading = false;
    allChecked = false;
    indeterminate = false;
  
    public currentBimInfo = null;
    public bimInfoStatus = -1;
  
    public items = [];
    
    exportLoading = false;//导出
    exportRarLoading = false;//导出答辩材料
    exportFileRarMutiKey = '';//导出答辩材料key

    queryParams = {
      // 查询搜索框的字段要预置
      'teamName': null,
      'schoolName': null,
      'eventId': null,
      'itemId': null,
    };
  
    columns: SimpleTableColumn[] = [
      { title: '', index: 'id', width: '1%', type: 'checkbox' },
      { title: '组别', index: 'groupName', width: '4%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '序号', index: 'groupNum', width: '4%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '团队名称', index: 'teamName', width: '11%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '大区', index: 'regionName', width: '4%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '省份', index: 'provinceName', width: '4%', format: (item: any, col: any) => this.formatIeCol(item, col) }, 

      { title: '学校名称', index: 'schoolName', width: '11%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '学校类型', index: 'schoolType', width: '5%', format: (item: any, col: any) => item[col.indexKey] === '0' ? '本科' : (item[col.indexKey] === '1' ? '专科' : '中职') },
      { title: '团队邮箱', index: 'teamEmail', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '赛项', index: 'eventName', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '参赛专项', index: 'itemName', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      { title: '上传时间', index: 'uploadDate', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col) },
      {
        title: '操作', width: '5%',
        buttons: [
          { text: '编辑', click: (item: any) => this.edit(item) },
          { text: '删除', click: (item: any) => this.remove(item), iif: (item:any) => item.schoolTop != '1' },
        ],
      }
    ];
  
    constructor(
      private modal: ModalHelper,
      private msg: NzMessageService,
      private modalService: NzModalService,
      private bimInfoService: BimInfoService,
      private bimTeamService: BimTeamsService,
      private bimItemsService: BimItemsService,
      private bimInfoEventsService: BimInfoEventsService,
      private workManageService: WorkManageService,
    ) {
    }
  
    ngOnInit() {
      
    }
  
    /**
     * 获取所有大赛
     */
    getBimInfo() {
      const params = {
        page: 0,
        size: 1000,
        sort: 'createTime,desc',
        'isDelete.equals': 0
      };
      this.bimInfoService.query(params).subscribe(res => {
        if (res && res.body) {
          this.bimInfoList = [{ id: '-1', name: '全部赛事' }];
          this.bimInfoList.push(...res.body);
        }
      });
    }
  
    /**
     * 根据赛事ID获取赛项
     */
    getBimInfoEvents(value, isReset?) {
      if (value === '-1') {
        this.bimInfoEventsList = [];
        this.oriBimInfoEvent = [];
        return;
      }
      const params = {
        'bimInfoId.equals': value,
        'eventsType.equals': '0',
        'isDelete.equals': 0,
        page: 0,
        size: 1000,
        sort: 'createTime,desc',
      };
      this.loading = true;
      this.isQueryEvent = true;
      this.bimInfoEventsService.query(params).subscribe(res => {
        this.isQueryEvent = false;
        if (res.ok && res.body.length > 0) {
          this.bimInfoEventsList = res.body;
          this.queryParams['eventId'] = this.bimInfoEventsList[0].id;
          this.loadItems();
          this.getList(isReset);
        } else {
          this.loading = false;
          this.bimInfoEventsList = [];
          this.queryParams['eventId'] = '-1';
          this.list = [];
          this.page = 0;
          this.size = 20;
          this.total = 0;
        }
      });
    }
  
    loadItems() {
      this.items = [];
      this.bimItemsService.getAllBimItemsInBimEvents(this.queryParams['eventId'], {
        'isDelete.equals': 0,
        'sort': ['type,asc', 'orderSn,asc']
      }).subscribe((res: any) => {
        const data = res.body;
        data.forEach(e => e['percent'] = 0);
        this.items = data;
      });
    }
  
    rest() {
      this.bimInfoEventsList = [];
      this.getBimInfoEvents(this.currentBimInfo.id, true);
    }
  
    search() {
      this.page = 0;
      this.getList();
    }

    getParams(pageRest){
      let copyParams = {};
      const q = this.queryParams;
      if (pageRest === true) {
        this.page = 0;
        Object.keys(q).forEach(function (key) {
          if (key != 'eventId') {
            q[key] = null;
          }
        });
      } else {
        Object.keys(q).forEach(function (key) {
          if (q[key] !== '' &&　q[key] !== null) {
            copyParams[key] = q[key];
          }
        });
      }
  
      copyParams['bimInfoId'] = this.currentBimInfo.id;
      copyParams['page'] = this.page;
      copyParams['size'] = this.size;
      return copyParams
    }
  
    getList(pageRest?: boolean) {
      let copyParams = this.getParams(pageRest);
      if (this.isQueryEvent) {
        this.msg.warning('请等待参赛项目查询完成');
        return;
      }
      this.loading = true;
      this.bimTeamService.getReplyWorkFilePage(copyParams).subscribe(res => {
        this.loading = false;
        if (res && res.body) {
          this.list = res.body;
          if (this.list.length === 0 && this.page > 0) {
            this.page --;
            this.getList();
          }
          // tslint:disable-next-line:radix
          this.total = parseInt(res.headers.get('X-Total-Count'));
          this.list.forEach(item => {
            item.checked = false;
          });
        }
      }, error1 => {
        this.loading = false;
        console.error(error1.message);
        MessageHelper.error('获取数据错误', error1.status);
      });
    }
  
    refreshStatus(): void {
      const allChecked = this.list.every(value => value.checked === true);
      const allUnChecked = this.list.every(value => !value.checked);
      this.allChecked = allChecked;
      this.indeterminate = (!allChecked) && (!allUnChecked);
    }
  
    checkAll(value: boolean): void {
      this.list.forEach(data => data.checked = value);
      this.refreshStatus();
    }
  
    clickContest(bimInfo) {
      this.currentBimInfo = bimInfo;
      this.getBimInfoEvents(this.currentBimInfo.id, true);
    }
  
    /**
    * 复选框事件
    * @param {SimpleTableData[]} list
    */
    checkboxChange(list: SimpleTableData[]) {
      this.selectedRows = list;
    }
  
    /**
    * 页码数量变动事件
    * @param
    */
    paginationChangePage(event) {
      this.page = event - 1;
      this.getList();
    }
  
    /**
    * 页码数量变动事件
    * @param
    */
    paginationChangeSize(event) {
      this.size = event;
      this.getList();
    }
  
    remove(item) {
      this.modalService.confirm({
        nzTitle: '确认删除?',
        nzContent: '',
        nzOkText: '是',
        nzOkType: 'danger',
        nzOnOk: () => {
          this.workManageService.deleteBimWork(item.workId).subscribe(res => {
            this.msg.success('删除成功');
            this.getList();
          });
        },
        nzCancelText: '否',
        nzOnCancel: () => console.log('Cancel')
      });
    }
  
    /**
     * ie列表显示null格式化
     */
    formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];
  
    /**
    * 新增页面
    */
    add() {
      if (this.queryParams['eventId'] == '-1') {
        this.msg.warning('请先维护大赛团队赛项数据！')
        return;
      }
      this.modal.static(WorkReplyFileAddComponent, {
        bimInfoId: this.currentBimInfo.id,
        bimEventId: this.queryParams['eventId'],
      }, 1003).subscribe((res) => {
        if (res !== null && res.length > 0) {
          console.log(res);
          this.workManageService.createReplyWork({
            teamList: res
          }).subscribe(() => {
            this.msg.success('保存成功');
            this.getList();
          });
        }
      });
    }

    /**
     * 导出excel
     * @returns 
     */
    exportExcel(){
      if(isNullOrUndefined(this.currentBimInfo) == null){
        MessageHelper.warn('请选择一个赛事再导出');
        return;
      }
      // const params = {
      //   'bimInfoId.equals': this.currentBimInfo.id,
      //   'eventsId.equals': this.queryParams['eventId'],
      //   'workType.equals': '3',//全国答辩材料管理
      //   sort: ['itemId','upload_date,desc'],
      // };
      
      let params = this.getParams(false);
      
      this.exportLoading = true;
      
      this.workManageService.exportReplyWorkFileData(params).subscribe(
        data => {
          let blob = new Blob([data.body]);
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.setAttribute('href', objectUrl);
          a.setAttribute('download',  '全国答辩材料上传信息-' + this.currentBimInfo.name  + '.xlsx');
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.exportLoading = false;
        },
        error => {
          this.exportLoading = false;
          MessageHelper.error('下载失败，请稍后重试');
          console.error('全国答辩材料上传信息下载失败 ->', error);
        },
      );
    }

    /**
     * 导出答辩材料
     */
    exportFileRar(){
      this.exportFileRarMutiKey = "export_replayfile_rarMuti" +  new GuidUtil().toString();

      const progressDialog = this.exportProgress(this.exportFileRarMutiKey);
      let params = this.getParams(false);
      if(this.selectedRows && this.selectedRows.length > 0){
        params['ids'] = this.selectedRows.map(w=>w.id).join(',');
      }
      params['exportExcelKeyName'] = this.exportFileRarMutiKey;

      this.exportRarLoading = true;

      this.workManageService.exportReplyWorkFileRar(params).subscribe(
        (res:any) => {
          this.exportRarLoading = false;
          if(res.success){
            const reletivePath = res.message;//相对路径
            if(progressDialog != null){
              window.setTimeout(() => {
                const instance = progressDialog.getContentComponent();
                debugger;
                if(instance != null){
                  instance['relativePath'] = reletivePath;
                  instance['fileName'] = res.data;//文件名称
                }
              }, 2000);
            }
            MessageHelper.success('生成答辩文件压缩包成功，请下载','提示');
          }else{
            progressDialog.close();
            MessageHelper.warn(res.message ? res.message : '下载失败','提示');
          }
        },
        error => {
          this.exportRarLoading = false;
          MessageHelper.error('下载失败，请稍后重试');
          console.error('全国答辩材料文件下载失败 ->', error);
        },
      );
    }

    exportProgress(exportKey){
      const progressDialog = this.modalService.create({
        nzTitle: '导出进度',
        nzContent: ExportProgressComponent,
        nzWidth:700,
        nzMaskClosable: false,
        nzComponentParams: {
          exportKeyName : this.exportFileRarMutiKey,
          isShowTitle: false,
          isShowProgress: true,
          isShowDownLoad: true,
          btnCloseVisible : false
        },
        nzFooter: [{
          label: '关闭',
          onClick: (componentInstance:ExportProgressComponent) => {
            componentInstance.cancel();
          }
        }]
      });
      
     
      progressDialog.afterOpen.subscribe((res) => console.log(res));
  
  
      return progressDialog;
    }
  
    eventChange() {
      this.loadItems();
    }

    addGroupNum() {
      if (this.queryParams['eventId'] == '-1') {
        this.msg.warning('请先维护大赛团队赛项数据！')
        return;
      }
      this.modal.static(GroupNumListComponent, {
        bimInfoId: this.currentBimInfo.id,
        bimInfoEventsList: this.bimInfoEventsList,
        bimEventId: this.queryParams['eventId'],
      }, 1003).subscribe((res) => {
      });
    }

    openSupplement() {
      this.modal.static(ReplyFileSuppleComponent, {}, 1200, {}).subscribe(res => {
      })
    }

    /**
     * 编辑页面
     */
    edit(item) {
      this.modal.static(ReplyFileSuppleComponent, {
        bimInfo: this.currentBimInfo,
        workInfo: item,
        isEdit: true,
      }, 1200, {}).subscribe(res => {
        this.getList();
      })
    }
  }