import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({
  providedIn: 'root'
})
export class ThsadminFileService {

  adminFileUrl = '';

  thsFileUrl = '';

  constructor(
    private http: HttpClient,
    httpAddressService: HttpAddressService,
  ) {
    this.adminFileUrl = '/thsadmin/api/sys-files';
    this.thsFileUrl = httpAddressService.FileServer + '/sys-files';
  }

  deleteFileById(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.adminFileUrl}/${id}`, { observe: 'response'});
  }

  queryFiles(params): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.adminFileUrl,{ params: params, observe: 'response'});
  }

  updateFileFkBatch(data): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.adminFileUrl + '/updateFkBatch', {}, { params: data, observe: 'response'});
  }

  deleteOSSFileById(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.thsFileUrl}/deleteOSSFile/${id}`, { observe: 'response'});
  }
}
