import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { IucceRoutingModule } from './iucce-routing.module';

import { YearGoalsReportComponent } from './yearGoalsReport/yearGoalsReport.component';
import { YearGoalsReportEditComponent } from './yearGoalsReport/edit/edit.component';
// import { IucceRegionInfoComponent } from "./component/region-info.component";
import { ProjectReportComponent } from './projectReport/projectReport.component';
import { ProjectReportEditComponent } from './projectReport/edit/edit.component';
import { ProjectReportContractEditComponent } from "./projectReport/contract/constract.component";
import { UtilsIucceModule } from '@shared/utils/iucce/utils-iucce.module';
import { ProjectReportAuditComponent } from './projectReportAudit/projectReportAudit.component';
import { ApproveComponent } from './projectReportAudit/approve/approve';
import { ApproveFlowComponent } from "./projectReportAudit/approveFlow/approveFlow";
import { ProjectReportAuditViewComponent } from './projectReportAudit/view/view.component';
import { ProjectFundPayComponent } from './projectFundPay/projectFundPay.component';
import { ProjectFundPayViewComponent } from './projectFundPay/view/view.component';
import { ProjectFundPayEditComponent } from './projectFundPay/edit/edit.component';
import { ProjectNicheComponent } from './projectNiche/projectNiche.component';
import { ProjectNicheEditComponent } from './projectNiche/niche/niche.component';
import { ProjectNicheInfoComponent } from "./projectNiche/edit/edit.component";
import { NicheEditComponent } from "./component/niche-edit/edit.component";
import { ProjectNicheViewComponent } from './projectNiche/view/view.component';
import { ProjectClosingComponent } from './projectClosing/projectClosing.component';
import { ProjectClosingEditComponent } from './projectClosing/closing/closing.component';
import { ProjectClosingViewComponent } from './projectClosing/view/view.component';
import { ProjectClosingAuditComponent } from './projectClosingAudit/projectClosingAudit.component';
import { ProjectClosingApproveFlowComponent } from "./projectClosingAudit/approveFlow/approveFlow.component";
import { ProjectClosingAuditApproveComponent } from './projectClosingAudit/approve/approve';
import { ProjectClosingAuditViewComponent } from './projectClosingAudit/view/view.component';
import { ProjectQueryComponent } from './projectQuery/projectQuery.component';
import { ProjectQueryViewComponent } from './projectQuery/view/view.component';
import { ProjectCockpitComponent } from './projectCockpit/projectCockpit.component';
import { ProjectInfoViewComponent } from './../iucce/component/project-info/view/project-info-view.component';
import { UtilsModule } from '@shared/utils/utils.module';

const COMPONENTS = [];
const COMPONENTS_NOROUNT = [
  ProjectInfoViewComponent,
  YearGoalsReportComponent,
  YearGoalsReportEditComponent,
  // IucceRegionInfoComponent,
  ProjectReportComponent,
  ProjectReportEditComponent,
  YearGoalsReportEditComponent,
  ProjectReportAuditComponent,
  ProjectReportContractEditComponent,
  ApproveComponent,
  ApproveFlowComponent,
  ProjectReportAuditViewComponent,
  ProjectFundPayComponent,
  ProjectFundPayEditComponent,
  ProjectFundPayViewComponent,
  ProjectNicheComponent,
  ProjectNicheEditComponent,
  ProjectNicheInfoComponent,
  NicheEditComponent,
  ProjectNicheViewComponent,
  ProjectClosingComponent,
  ProjectClosingEditComponent,
  ProjectClosingViewComponent,
  ProjectClosingAuditComponent,
  ProjectClosingAuditApproveComponent,
  ProjectClosingApproveFlowComponent,
  ProjectClosingAuditViewComponent,
  ProjectQueryComponent,
  ProjectQueryViewComponent,
  ProjectCockpitComponent,
];

@NgModule({
  imports: [SharedModule,UtilsModule, IucceRoutingModule, UtilsIucceModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [],

  entryComponents: COMPONENTS_NOROUNT,
})
export class IucceModule {}
