import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';

@Component({
  selector: 'app-bim-contest-list',
  templateUrl: './bim-contest-list.component.html',
  styleUrls: ['../../../../styles/hazardSources.less'],
})
export class BimContestListComponent implements OnInit {

  @Output() clickContest = new EventEmitter();

  @Input() bimInfoStatus = 1;
  
  @Input() bimInfoType = -1;


  contestDataList: any[] = [];

  searchValue = '';
  bimInfoId = '';
  constructor(
    private bimInfoService: BimInfoService,
    private route: ActivatedRoute,
  ) {
    // this.route.queryParamMap.subscribe((params: ParamMap) => {
    //   const initClickBimInfoId = params.get('initClickBimInfoId');
    //   const isFristEnterPage = (this.bimInfoId === null || this.bimInfoId === '' || this.bimInfoId === undefined);
    //   if (isFristEnterPage && initClickBimInfoId === null) { // 第一次进入,且不带initClickBimInfoId,则点击第一个大赛
    //     this.getBimInfoList();
    //   }
    //   if (isFristEnterPage && initClickBimInfoId !== null) { // 第一次进入,且带initClickBimInfoId,则点击且带initClickBimInfoId对应的大赛
    //     this.getBimInfoList(initClickBimInfoId);
    //   }
    //   if (!isFristEnterPage && initClickBimInfoId === null) { // 非第一次进入,且不带initClickBimInfoId,则不处理

    //   }
    //   // 非第一次进入,且带initClickBimInfoId,则判断当前大赛id和且带initClickBimInfoId是否相同,相同则不处理,不相同则点击initClickBimInfoId大赛
    //   if (!isFristEnterPage && initClickBimInfoId !== null) {
    //     if (initClickBimInfoId === this.bimInfoId) {

    //     } else {
    //       this.getBimInfoList(initClickBimInfoId);
    //     }
    //   }
    // });
  }

  ngOnInit() {
    console.log(this.bimInfoStatus);
    this.getBimInfoList();
  }

  getBimInfoList(clickBimInfoId?: any) {
    const params = {
      'size': 1000,
      'sort': ['startDate,desc'],
    };
    if (this.bimInfoStatus && this.bimInfoStatus !== -1) {
      params['status.equals'] = this.bimInfoStatus;
    }
    if (this.bimInfoType && this.bimInfoType !== -1) {
      params['type.equals'] = this.bimInfoType;
    }
    if (this.searchValue) {
      params['name.contains'] = this.searchValue;
    }
    params['isDelete.equals'] = 0;
    this.bimInfoService.query(params).subscribe((res: any) => {
      this.contestDataList = res.body;
      if (this.contestDataList.length > 0) {
        if (clickBimInfoId) { // 指定点击某个大赛
          const filterArray = this.contestDataList.filter(item => item.id === clickBimInfoId);
          if (filterArray.length !== 0 ) {
            this.bimInfoId = filterArray[0].id;
            this.clickContest.emit(filterArray[0]);
          } else { // 找不到大赛则选中第一个大赛
            this.bimInfoId = this.contestDataList[0].id;
            this.clickContest.emit(this.contestDataList[0]);
          }
        } else { // 不传入参数默认点击第一个大赛
          this.bimInfoId = this.contestDataList[0].id;
          this.clickContest.emit(this.contestDataList[0]);
        }
      }
    });
  }

  // 大赛点击事件 -- 回调
  contestClick(item: any) {
    this.bimInfoId = item.id;
    this.clickContest.emit(item);
  }
}
