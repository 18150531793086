import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EduRoutingModule } from './edu-routing.module';
import { EduUserComponent } from './user/user.component';
import { UserService } from './user/user.service';
import { EduUserEditComponent } from './user/edit/edit.component';

import { EduResourceComponent } from './resource/resource.component';
import { ResourceService } from './resource/resource.service';
import { EduResourceEditComponent } from './resource/edit/edit.component';

import { EduBannerComponent } from './bannerManager/banner.component';
import { BannerService } from './bannerManager/banner.service';
import { EduBannerEditComponent } from './bannerManager/edit/edit.component';

import { EduCourseClassifyComponent } from './courseClassify/courseClassify.component';
import { CourseClassifyService } from './courseClassify/courseClassify.service';
import { EduCourseClassifyEditComponent } from './courseClassify/edit/edit.component';

import { EduTeacherComponent } from './teacher/teacher.component';
import { TeacherService } from './teacher/teacher.service';
import { EduTeacherEditComponent } from './teacher/edit/edit.component';
import { EduCourseNumComponent } from './teacher/courseNum/courseNum.component';
import { EduStudentNumComponent } from './teacher/studentNum/studentNum.component';

import { EduCourseComponent } from './course/course.component';
import { CourseService } from './course/course.service';
import { EduCourseEditComponent } from './course/edit/edit.component';

import { EduCourseSummerComponent } from './course/courseOverview/courseSummer.component';
// import { CourseSummerService } from './course/courseSummer/courseSummer.service';
import { EduCourseSummerEditComponent } from './course/courseOverview/edit/edit.component';
import { EduCourseSummerManComponent } from './course/courseSummer/courseSummerMan.component';
import { CourseSummerManService } from './course/courseSummer/courseSummerMan.service';
import { EduCourseSummerManEditComponent } from './course/courseSummer/edit/edit.component';


import { EduCourseCatalogComponent } from './courseCatalog/courseCatalog.component';
import { CourseCatalogService } from './courseCatalog/courseCatalog.service';
import { EduCourseCatalogEditComponent } from './courseCatalog/edit/edit.component';
import { CourseFileComponent } from './courseCatalog/courseFileView/courseFile.component';

import { EduStudentComponent } from './student/student.component';
import { StudentService } from './student/student.service';
import { EduStudentEditComponent } from './student/edit/edit.component';
import { EduStudentCourseNumComponent } from './student/courseNum/courseNum.component';

import { EduStudyHistoryComponent } from './studyHistory/studyHistory.component';
import { StudyHistoryService } from './studyHistory/studyHistory.service';


import { EduCourseAuditComponent } from './courseAudit/courseAudit.component';
import { CourseAuditService } from './courseAudit/courseAudit.service';
import { EduCourseCatalogAuditEditComponent } from './courseAudit/edit/courseCatalogAudit.component';
import { EduCourseAuditEditComponent } from './courseAudit/edit/edit.component';
import { CourseAppointStuManComponent } from './course/appointStudent/courseAppointStuMan.component';
import { CourseAppointStuManService } from './course/appointStudent/courseAppointStuMan.service';
import { EduCouresSelectStuComponent } from './course/appointStudent/selectStudent/couresSelectStu.component';


import { EduTopicPushComponent } from './topicPush/topicPush.component';
import { EduTopicPushService } from './topicPush/topicPush.service';
import { EduTopicPushEditComponent } from './topicPush/edit/edit.component';
// import { EduTopicCourseNumComponent } from './topicManager/topic/topicCourseNum.component';

import { EduTopicManagerComponent } from './topicManager/topicManager.component';
import { EduTopicManagerService } from './topicManager/topicManager.service';
import { EduTopicManagerEditComponent } from './topicManager/edit/edit.component';
import { EduTopicReplyComponent } from './topicManager/topicReply/topicReply.component';
import { EduTopicReplyService } from './topicManager/topicReply/topicReply.service';
import { SelectCourseComponent } from './topicManager/selectCourse/selectCourse.component';
import { EduSelectCourseService } from './topicManager/selectCourse/selectCourse.service';

import { SubjectComponent } from './subject/subject.component';
import { SubjectaddeditComponent } from './subject/subjectaddedit/subjectaddedit.component';
import { HomeworkComponent } from './homework/homework.component';
import { HomeworkaddeditComponent } from './homework/homeworkaddedit/homeworkaddedit.component';



import { EduCertChannelComponent } from './cert/certChannelManage/certChannel.component';
import { CertChannelService } from './cert/certChannelManage/certChannel.service';
import { EduCertChannelEditComponent } from './cert/certChannelManage/edit/edit.component';


import { EduCertExamineTimeComponent } from './cert/certExamineTimeManage/certExamineTime.component';
import { CertExamineManageService } from './cert/certExamineTimeManage/certExamineTime.service';
import { EduCertExamineManageEditComponent } from './cert/certExamineTimeManage/edit/edit.component';

import { EduCertIntroduceComponent } from './cert/certIntroduceManage/certIntroduce.component';
import { CertIntroduceService } from './cert/certIntroduceManage/certIntroduce.service';
import { EduCertIntroduceEditComponent } from './cert/certIntroduceManage/edit/edit.component';
import { EduOrderModule } from './order/order.module';
import { CertificationComponent } from './cert/certIntroduceManage/priview/certification.component';
import { CertificationService } from './cert/certIntroduceManage/priview/certification.service';

import { EduCertRegedistComponent } from './cert/certRegedistManage/certRegedistManage.component';
import { CertRegedistManageService } from './cert/certRegedistManage/certRegedistManage.service';
import { EduCertRegedistManageEditComponent } from './cert/certRegedistManage/edit/edit.component';


import { EertExamineResultManComponent } from './cert/certExamineResultMan/certExamineResultMan.component';
import { CertExamineResultManService } from './cert/certExamineResultMan/certExamineResultMan.service';
import { EduCertExamineResultManEditComponent } from './cert/certExamineResultMan/edit/edit.component';
import { CertResultImportComponent } from './cert/certExamineResultMan/resultImportMan/certExamineResultImport.component';

import { EduCertRegedistPayComponent } from './cert/certRegedistPayManage/certRegedistPayManage.component';
import { CertRegedistPayManageService } from './cert/certRegedistPayManage/certRegedistPayManage.service';
import { ExamComponent } from './exam/exam.component';
import { ExamaddteditComponent } from './exam/examaddtedit/examaddtedit.component';
import { PriviewComponent } from './homework/priview/priview.component';
import { SelectsubjectComponent } from './homework/selectsubject/selectsubject.component';
import { ExampriviewComponent } from './exam/exampriview/exampriview.component';
import { ExamsubjetComponent } from './exam/examsubjet/examsubjet.component';
import { DetailstModule } from './detailst/detailst.module';
import { TopicaddeditComponent } from './homeworktopic/topicaddedit/topicaddedit.component';
import { HomeworktopicComponent } from './homeworktopic/homeworktopic.component';
import { TypeaddeditComponent } from './homeworktype/typeaddedit/typeaddedit.component';
import { HomeworktypeComponent } from './homeworktype/homeworktype.component';
import { EduSoftwareModule } from './software-download/software-download.module';
import { QRCodeModule } from 'angular2-qrcode';


const COMPONENTS = [EduUserComponent,
  EduResourceComponent,
  EduBannerComponent,
  EduCourseClassifyComponent,
  EduTeacherComponent,
  EduCourseComponent,
  EduCourseCatalogComponent,
  EduStudentComponent,
  EduStudyHistoryComponent,
  EduCourseAuditComponent,
  EduCourseAuditEditComponent,
  EduTopicPushComponent,
  EduTopicManagerComponent,
  EduCertChannelComponent,
  EduCertExamineTimeComponent,
  EduCertIntroduceComponent,
  EduCertRegedistComponent,
  EertExamineResultManComponent,
  EduCertRegedistPayComponent,
  TopicaddeditComponent,
  TypeaddeditComponent,
  HomeworktypeComponent,
  HomeworktopicComponent
];
const COMPONENTS_NOROUNT = [
  EduUserEditComponent,
  EduResourceEditComponent,
  EduBannerEditComponent,
  EduCourseClassifyEditComponent,
  EduTeacherEditComponent,
  EduCourseNumComponent,
  EduStudentNumComponent,
  EduCourseEditComponent,
  EduCourseCatalogEditComponent,
  CourseFileComponent,
  EduStudentEditComponent,
  EduStudentCourseNumComponent,
  EduCourseCatalogAuditEditComponent,
  EduTopicPushEditComponent,
  EduTopicManagerEditComponent,
  EduTopicReplyComponent,
  SelectCourseComponent,
  SubjectComponent,
  SubjectaddeditComponent,
  HomeworkComponent,
  HomeworkaddeditComponent,
  EduCertChannelEditComponent,
  EduCertExamineManageEditComponent,
  EduCertIntroduceEditComponent,
  EduCertRegedistManageEditComponent,
  EduCourseSummerManEditComponent,
  EduCourseSummerManComponent,
  EduCourseSummerComponent,
  EduCourseSummerEditComponent,
  EduCertExamineResultManEditComponent,
  CertResultImportComponent,
  CertificationComponent,
  ExamComponent,
  ExamaddteditComponent,
  PriviewComponent,
  SelectsubjectComponent,
  ExampriviewComponent,
  ExamsubjetComponent,
  CourseAppointStuManComponent,
  EduCouresSelectStuComponent,
];


@NgModule({
  imports: [
    SharedModule,
    EduRoutingModule,
    EduOrderModule,
    DetailstModule,
    EduSoftwareModule,
    QRCodeModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  providers: [
    UserService,
    ResourceService,
    BannerService,
    CourseClassifyService,
    TeacherService,
    CourseService,
    CourseCatalogService,
    StudentService,
    StudyHistoryService,
    CourseAuditService,
    EduTopicPushService,
    EduTopicManagerService,
    EduSelectCourseService,
    EduTopicReplyService,
    CertChannelService,
    CertExamineManageService,
    SubjectaddeditComponent,
    EduTopicReplyService,
    HomeworkaddeditComponent,
    CertIntroduceService,
    CertRegedistManageService,
    CourseSummerManService,
    CertExamineResultManService,
    CertRegedistPayManageService,
    CertificationService,
    ExamaddteditComponent,
    PriviewComponent,
    ExampriviewComponent,
    ExamsubjetComponent,
    CourseAppointStuManService,
  ],

  entryComponents: COMPONENTS_NOROUNT

})
export class EduModule { }
