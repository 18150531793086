import { Component, OnInit } from '@angular/core';
import {NzMessageService, NzModalRef, NzModalService} from "ng-zorro-antd";
import {WorkManageService} from "../work-manage.service";
import {BimWorkCoresDTO, BimWorkScoresVM} from "../work-manage.model";
import { BimScoringRuleService } from '../../bim-scoring-rule/bim-scoring-rule.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-score-details',
  templateUrl: './score-details.component.html',
  styleUrls: []
})
export class ScoreDetailsComponent implements OnInit {

  workId: string = '';
  scoresList: BimWorkCoresDTO[] = [];

  isVisible = false;
  advisorId = '';
  scoreDetailList = [];
  public totalScore = 0;
  public bimInfoId = null;
  public isEditScore = false;
  public totalScoreLimit = null;//最大分数
  public loading = false;

  public okText = null;
  public cancelText= null;

  constructor(
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private modalService: NzModalService,
    private workManageService: WorkManageService,
    private bimScoringRuleService: BimScoringRuleService,
  ) { }

  ngOnInit() {
    this.getScores()
  }

  onClose() {
    this.nzModalRef.close();
  }

  getScores() {
    const params = {
      'workId.equals': this.workId,
      'submit.equals': 1
    };
    this.workManageService.queryWorkScores(params).subscribe(res => {
      if (res && res.body) {
        this.scoresList = res.body;
      }
    })
  }

  showModal(data: BimWorkCoresDTO, isEdit: boolean): void {
    this.isVisible = true;
    this.advisorId = data.advisorId;
    this.isEditScore = isEdit;
    if (isEdit) {
      this.okText = '提交';
      this.cancelText = '关闭';
    } else {
      this.okText = '确定';
      this.cancelText = null;
    }
    this.initData();
  }

  handleOk(): void {
    if (this.isEditScore) {
      this.submitScore();
    } else {
      this.advisorId = '';
      this.isVisible = false;
    }
  }

  handleCancel(): void {
    this.advisorId = '';
    this.isVisible = false;
    if (this.isEditScore) {
      this.getScores()
    }
  }

  getScoreDetails() {
    const params = {
      'advisorId.equals': this.advisorId,
      'workId.equals': this.workId,
    };
    this.workManageService.queryBimWorkCoresDetails(params).subscribe(res => {
      if (res && res.body) {
        this.scoreDetailList = res.body;
        this.scoreDetailList.forEach(item => {
          item.cols = 1;
          item.colBool = true;
          if (item.itemName2 && item.itemName2.length > 0) {
            item.colBool = false;
          }
        })
      }
    })
  }

  getItemSoftRows(itemSoft) {
    const row = this.scoreDetailList.filter(item => item.itemSoft === itemSoft);
    return row.length > 0 ? row.length : 1;
  }

  getItemName1Rows(itemName1) {
    const row = this.scoreDetailList.filter(item => item.itemName1 === itemName1);
    return row.length > 0 ? row.length : 1;
  }
  

  initData() {
    this.loading = true;
    const fields = ['itemName2', 'itemRule', 'answer', 'remark'];
    this.bimScoringRuleService.getRuleItemByWorkId({
      'bimWorkId': this.workId,
      'bimInfoId': this.bimInfoId,
      'advisorId': this.advisorId
    }).subscribe(res => {
      this.loading = false;
      const data = res.body;
      if (!isNullOrUndefined(data.bimScoringRule)) {
        const bimScoringRule = data.bimScoringRule;
        const scoreLimit = parseFloat(bimScoringRule.userDefine1);
        if(!isNaN(scoreLimit)){
          this.totalScoreLimit = scoreLimit;
        }
      }
      if (!isNullOrUndefined(data.ruleItemData)) {
        const ruleItemData = data.ruleItemData;
        const fields = ['itemName2', 'itemRule', 'answer', 'remark'];
        if (ruleItemData && ruleItemData.length > 0) {
          ruleItemData.forEach(e => {
            for (let i = 0; i < fields.length; i++) {
              const field = fields[i];
              if (!isNullOrUndefined(e[field])) {
                e[field] = e[field].replaceAll('\n', '<br>');
              }
            }
          })
        }
        this.scoreDetailList = ruleItemData;
      }
      this.scoreChange();
    });
  }

  scoreChange(item?, index?) {
    let totalScore = 0;
    this.scoreDetailList.forEach(e => {
      if (!isNullOrUndefined(e.getScore) && e.getScore !== '') {
        totalScore += e.getScore * 100;
      }
    });
    this.totalScore = totalScore / 100;
  }

  submitScore() {
    if(!isNullOrUndefined(this.totalScoreLimit) && this.totalScore > this.totalScoreLimit){
      //总分验证（实际评分不能超过总得分）
      this.modalService.warning({
        nzTitle: '提示',
        nzContent: `作品总得分不能超过总分：${this.totalScoreLimit} 分`,
        nzOkText: '确定',
      });
      return;
    }
    const param = [];
    this.scoreDetailList.forEach(e => {
      if (!isNullOrUndefined(e.getScore) && e.getScore !== '') {
        param.push({
          'bimScoringRuleItemId': e.id,
          'core': e.getScore
        });
      }
    });
    const subParam = {
      'bimWorkId': this.workId,
      'advisorId': this.advisorId,
      'scoreList': param,
      'saveType': 1
    };
    this.saveData(subParam);
  }

  saveData(subParam) {
    this.loading = true;
    this.bimScoringRuleService.saveWorkCores(subParam).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        const data = res.body;
        if (data.success) {
          this.msg.info('保存成功');
        } else {
          this.msg.warning(data.message);
        }
      }
    }, () => this.loading = false);
  }

}
