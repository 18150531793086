import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatProjectOrderStatus' })
/**
 * 格式化项目订单状态管道
 */
export class formatProjectOrderStatusPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        // 0 未成单 1 部分成单 3 全部成单
        const valueNew = typeof value == 'number' ? value.toString() : value;
        switch (valueNew) {
            case '0':
                return "待成单";
            case '1':
                return "部分成单";
            case '3':
                return '全部成单';
            default:
                return '待成单';
        }
    }
}
