import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoursePayDetails } from './details.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoursePayDetailsService extends ThsBaseService<CoursePayDetails> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-course-pay-details`);
  }
  queryByOrderNo(orderNo: string ): Observable<HttpResponse<CoursePayDetails[]>> {
    return this.http.get<CoursePayDetails[]>(this.resourceUrl + '/admin/' + orderNo, { observe: 'response'});
}
 }