import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoContactEditComponent } from './edit/edit.component';
import { BimInfoLinkEditComponent } from './edit-link/edit.component';
import { BimInfoCompanyService } from '../bim-info-company/bim-info-company.service';

@Component({
  selector: 'app-bim-info-contact',
  templateUrl: './bim-info-contact.component.html',
  styleUrls: []
})
export class BimInfoContactComponent implements OnInit {

  public BimInfoId:String;
  public BimInfoName:String;
  
  public page = 0;
  public pageIndex = 0;
  public size = 10;
  public total = '0';

  public page2 = 0;
  public pageIndex2 = 0;
  public size2 = 10;
  public total2 = '0';

  public pageSizeOptions = [10, 20, 50, 100];
  loading = false;
  loading2 = false;
  allChecked = false;
  indeterminate = false;
  selectedRows: SimpleTableData[] = [];

  
  allChecked2 = false;
  indeterminate2 = false;
  selectedRows2: SimpleTableData[] = [];

  public list: any[];
  public list2: any[];
  selectedIndex = 0;
  // @ViewChild('st') st: SimpleTableComponent;

  public queryParams = [{
    // 查询搜索框的字段要预置
    'searchValue.contains': null,
    sort: ['orderNum,asc']
  },
  {
    // 查询搜索框的字段要预置
    'searchValue.contains': null,
    sort: ['orderNum,asc']
  }];


  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private routeInfo: ActivatedRoute,
    private modalService: NzModalService,
    private fileUploadService: FileUploadService,
    private bimInfoCompanyService: BimInfoCompanyService,
  ) { 
    this.routeInfo.queryParams.subscribe(parms => {
      if (parms.bimInfoId) {
         this.BimInfoId = parms.bimInfoId;
         this.getDataList();
      }

      if(parms.bimInfoName){
        this.BimInfoName = parms.bimInfoName;
      }
    });
  }

  ngOnInit(): void {
    this.getDataList();
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    if(this.selectedIndex == 0){
      this.loading = true;
    }else{
      this.loading2 = true;
    }
    
    const copyParams = this.getParams(isReset, isSearch);
    this.bimInfoCompanyService.query(copyParams).subscribe(res => {
      debugger;
      if(this.selectedIndex == 0){
        this.loading = false;
        this.list = res.body;
        if (this.list.length === 0 && this.page > 0) {
          this.page--;
          this.pageIndex = this.page + 1;
          this.getDataList();
        }
        this.selectedRows = [];
        this.total = res.headers.get('X-Total-Count');
      }else{
        this.loading2 = false;
        this.list2 = res.body;
        if (this.list2.length === 0 && this.page2 > 0) {
          this.page2--;
          this.pageIndex2 = this.page2 + 1;
          this.getDataList();
        }
        this.selectedRows2 = [];
        this.total2 = res.headers.get('X-Total-Count');
      }
    }, () => {
      if(this.selectedIndex === 0){
        this.loading = false;
      }else{
        this.loading2 = false;
      }
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams[this.selectedIndex];
    if (isReset === true) {
      if(this.selectedIndex === 0){
        this.page = 0;
        this.pageIndex = this.page + 1;
        this.selectedRows = [];
      }else{
        this.page2 = 0;
        this.pageIndex2 = this.page2 + 1;
        this.selectedRows2 = [];
      }
      
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    
    if (!isNullOrUndefined(this.BimInfoId)) {
      copyParams['bimInfoId.equals'] = this.BimInfoId;
    }
    
    if(this.selectedIndex === 0){
      copyParams['type.equals'] = 5;
      copyParams['page'] = this.page;
      copyParams['size'] = this.size;
      if (isSearch) {
        this.page = 0;
        copyParams['page'] = 0;
      }
      
    }else if(this.selectedIndex === 1){
      copyParams['type.equals'] = 6;
      copyParams['page'] = this.page2;
      copyParams['size'] = this.size2;
      if (isSearch) {
        this.page2 = 0;
        copyParams['page'] = 0;
      }
    }
    return copyParams;
  }

  logTab(tabIndex){
    if(this.selectedIndex == 0){
      this.getDataList(false,true);
    }else if(this.selectedIndex == 1){
      this.getDataList(false,true);
    }
  }


 

  /**
  * 新增联系人页面
  */
  addContact() {
    debugger;
    // const dataId = new GuidUtil().toString();
    this.modal.static(BimInfoContactEditComponent, {
      isAdd: true,
      record: { id: null},
      dataId: null,
      bimInfoId:this.BimInfoId,
      total: parseInt(this.total)
    },800).subscribe((res) => {
      if (res) {
        this.getDataList();
      }
    });
  }

  /**
   * 编辑联系人页面
   */
  editContact(item) {
    this.modal.static(BimInfoContactEditComponent, {
      isAdd: false,
      record: item,
      bimInfoId:this.BimInfoId,
    },800).subscribe((res) => {
      if (res) {
        this.getDataList();
      }
    });
  }

  deleteContact(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteData(item.id);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteData(dataId) {
    if(this.selectedIndex == 0){
      this.loading = true;
    }else{
      this.loading2 = true;
    }
    
    this.bimInfoCompanyService.delete(dataId).subscribe(() => {
      if(this.selectedIndex == 0){
        this.loading = false;
      }else{
        this.loading2 = false;
      }
      this.fileUploadService.deleteAllByFk(dataId).subscribe();
      this.msg.remove();
      this.msg.info('删除成功！');
      this.getDataList();
    });
  }


  /**
  * 新增联系人页面
  */
  addRelatedLink() {
    // const dataId = new GuidUtil().toString();
    this.modal.static(BimInfoLinkEditComponent, {
      isAdd: true,
      record: { id: null},
      dataId: null,
      bimInfoId:this.BimInfoId,
      total: parseInt(this.total2)
    }).subscribe((res) => {
      if (res) {
        this.getDataList();
      }
    });
  }

  /**
   * 编辑联系人页面
   */
  editRelatedLink(item) {
    this.modal.static(BimInfoLinkEditComponent, {
      isAdd: false,
      record: item,
      bimInfoId:this.BimInfoId,
    }).subscribe((res) => {
      if (res) {
        this.getDataList();
      }
    });
  }

  deleteRelatedLink(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteRelatedLinkData(item.id);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteRelatedLinkData(dataId) {
    if(this.selectedIndex == 0){
      this.loading = true;
    }else{
      this.loading2 = true;
    }
    
    this.bimInfoCompanyService.delete(dataId).subscribe(() => {
      if(this.selectedIndex == 0){
        this.loading = false;
      }else{
        this.loading2 = false;
      }
      this.fileUploadService.deleteAllByFk(dataId).subscribe();
      this.msg.remove();
      this.msg.info('删除成功！');
      this.getDataList();
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    if(this.selectedIndex === 0){
      this.page = event - 1;
    }else{
      this.page2 = event - 1;
    }
    
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    if(this.selectedIndex === 0){
      this.size = event;
    }else{
      this.size2 = event;
    }
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  checkAll(value: boolean): void {
    if(this.selectedIndex === 0){
      this.list.forEach(data => {
        if (!data.disabled) {
          data.checked = value;
        }
      });
    }else{
      this.list2.forEach(data => {
        if (!data.disabled) {
          data.checked = value;
        }
      });
    }
    
    this.refreshStatus();
  }

  refreshStatus(): void {
    if(this.selectedIndex === 0){
      const validData = this.list.filter(value => !value.disabled);
      const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
      const allUnChecked = validData.every(value => !value.checked);
      this.allChecked = allChecked;
      this.indeterminate = (!allChecked) && (!allUnChecked);
    }else{
      const validData = this.list2.filter(value => !value.disabled);
      const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
      const allUnChecked = validData.every(value => !value.checked);
      this.allChecked2 = allChecked;
      this.indeterminate2 = (!allChecked) && (!allUnChecked);
    }
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];
}
