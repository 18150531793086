import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimInfoEventsMutex } from './bim-info-events-mutex.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class  BimInfoEventsMutexService extends ThsBaseService<BimInfoEventsMutex>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-info-events-mutexes');
  }

  getAllDataPageList(param: any) {
      return this.http.get<any[]>(`${this.resourceUrl}/getAllDataPageList`, { params: param, observe: 'response' })
          .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  saveMutexItemData(param: any) {
    const url =  `${this.resourceUrl}/saveMutexItemData`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }
}
