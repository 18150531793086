/**
 * 组织机构/人员选择组件
 * @author huanggx
 * @since 2018-11-19
 * @description 可以用[(selectedId)]进行双向绑定,(selectedName)进行单向绑定
 */
import { OnInit, Component, Input, Output, EventEmitter, forwardRef, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { ArrayService } from "@shared/utils/array.service";
import { NzTreeNode, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DutyService } from 'app/routes/admin/organization/duty/duty.service';
import { PersonnelService } from 'app/routes/admin/organization/personnel/personnel.service';
import { DeptService } from "app/routes/admin/organization/department/department.service";
import { OrganizationService } from "app/routes/admin/organization/organization/organization.service";
import { Organization } from "app/routes/admin/organization/organization/organization.model";
import { Dept } from "app/routes/admin/organization/department/department.model";
import { Duty } from "app/routes/admin/organization/duty/duty.model";
import { Personnel } from "app/routes/admin/organization/personnel/personnel.model";
import { FormGroup, FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'ths-org-select',
    templateUrl: './orgSelector.component.html',
    providers: [ 
        DutyService,
        PersonnelService,
        DeptService,
        OrganizationService,
    ],
    styles  : [ `
    .custom-node {
      padding: 2px 8px;
      cursor: pointer;
    }
    :host ::ng-deep span.custom-node:hover{
        background-color: #e6f7ff;;
    }

    .active {
      background-color: #bae7ff;
    }

    .anticon {
      padding-left: 4px;
      padding-right: 4px;
    }

    :host ::ng-deep .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      width: calc(100% - 8px);
    }

    :host ::ng-deep .ant-tree li span[draggable], :host ::ng-deep .ant-tree li span[draggable="true"] {
      width: calc(100% - 8px);
    }
  ` ]
})
export class ThsOrgSelector  implements OnInit,OnChanges {
    
    
    /**
     * 选择器类型 单位 | 部门 | 职位 | 人员(未实现)
     */
    @Input() selectType: "organization" | "dept" | "duty" | "person" = "person";
    /**
     * 组件大小 (未实现)
     */
    @Input() size: "small" | "default" | "lager" = "default";   
    /**
     * 人员查询参数
     */
    @Input()  personQueryParams:{} = {};
    /**
     * 单位查询参数
     */
    @Input() orgQueryParams:{} ;
    /**
     * 部门查询参数
     */
    @Input() deptQueryParams:{} ;
    /**
     * 岗位查询参数
     */
    @Input() dutyQueryParams:{} ;
    /**
     * 选择数量限制 -1无限制
     */
    @Input() maxMultipleCount:number = -1;
    /**
     * 选中的id字符串（逗号隔开），双向绑定，回显数据需有值
     */
    @Input()  selectedId:string = '';
    @Output() selectedIdChange = new EventEmitter<any>();
    selectedIdOrigin:string = '';
    selectedIdArr = [];
    /**
     * 选中的name字符串（逗号隔开），可双向绑定[(selectedName)]，建议只单向绑定(selectedName)
     */
    @Input()  selectedName:string = '';
    @Output() selectedNameChange = new EventEmitter<any>();
    /**
     * 选中的{id:xxx,name:xxx}数组，可双向绑定[(selectedData)]，建议只单向绑定(selectedData)
     */
    @Input()  selectedData:any = [];
    /**
     * 确定事件的回调事件, 返回slectedData
     */
    @Output() selectedDataChange = new EventEmitter<any>();
    /**
     * 点击确定按钮之前的确认消息标题（NzModalService-confirm的标题）
     */
    @Input() btnOkMessageTtile: string = '确认修改？';
    /**
     * 点击确定按钮之前的确认消息内容（NzModalService-confirm的内容）如需确认框，该项必需有值
     */
    @Input() btnOkMessageContent: string = null;

    @Input() selectorDisabled: boolean = false;
    
    selectedDataOrigin:any = [];
    selectedMap:Map<String,String> = new Map();
    selectedMapOrigin:Map<String,String> = new Map();
    selectNodes: string;
    treeNodes: NzTreeNode[];
    modalIsVisible:boolean = false;
    treeSearchValue;  
    searchPersonForm: FormGroup;
    organizationList: Organization[] = [];
    deptList: Dept[] = [];
    dutyList: Duty[] = [];
    personList: Personnel[] = [];
    personListOrigin: Personnel[] = null;
    treeList:any[] = [];
    personPage: number = 1;
    personSize: number = 10;
    personTotal: any;
    isCollapsed = false;
    triggerTemplate = null;
    selectedPath:string = '';
    allChecked = false;
    indeterminate = false;
    tableLoading: boolean = false;
    nzTitle = "选择人员";
    nzCheckable = false;
    constructor(
        private http: _HttpClient,
        private personnelService: PersonnelService,
        private deptService: DeptService,
        private organizationService: OrganizationService,
        private dutyService: DutyService,
        private arrayService: ArrayService,
        private fb: FormBuilder,
        private msg: NzMessageService,
        private modalService: NzModalService,
        private cdr: ChangeDetectorRef
    ) { 
    }

    ngOnInit(): void {
        this.searchPersonForm = this.fb.group({
            'perName': [ null]
          });
        this.initSelectedId();
        this.initTreeAndTable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['selectedId'] && changes['selectedId'].currentValue){
            this.initSelectedId();
        }
        if(changes['selectType'] && changes['selectType'].currentValue){
            let type = changes['selectType'].currentValue;
            switch (type) {
                case 'person':
                    this.nzTitle = "选择人员";
                    break;
                case 'duty':
                    this.nzTitle = "选择岗位";
                    this.nzCheckable = true;
                    break;
                case 'dept':
                    this.nzTitle = "选择部门";
                    break;
                case 'organization':
                    this.nzTitle = "选择单位";
                    break;
                default:
                    this.nzTitle = "选择人员";
                    break;
            }
        }
    }

    initSelectedId(){
        //根据已选择id自动回显实体名
        if(this.selectedId && this.selectedId !=''){
            this.selectedIdArr = this.selectedId.split(',');
            let tempQueryParams = {
                'id.in' : this.selectedId,
            };
            switch (this.selectType) {
                case 'person':
                    this.personnelService.queryWithIgnoreAuth(tempQueryParams).subscribe((res) => {
                        this.selectedData = [];
                        res.body.forEach(element => {
                            this.selectedData.push({'name': element.perName, 'id': element.id});
                            this.selectedMap.set(element.id, element.perName);
                        });
                        this.selectedMapOrigin = new Map(this.selectedMap);
                        this.selectedIdOrigin = this.selectedId;
                        [...this.selectedDataOrigin] = this.selectedData;
                    });
                    break;
                case 'duty':
                    this.dutyService.queryWithIgnoreAuth(tempQueryParams).subscribe((res) => {
                        this.selectedData = [];
                        res.body.forEach(element => {
                            this.selectedData.push({'name': element.dutyName, 'id': element.id});
                            this.selectedMap.set(element.id, element.dutyName);
                        });
                        this.selectedMapOrigin = new Map(this.selectedMap);
                        this.selectedIdOrigin = this.selectedId;
                        [...this.selectedDataOrigin] = this.selectedData;
                    });
                    break;
                default:
                    this.personnelService.queryWithIgnoreAuth(tempQueryParams).subscribe((res) => {
                        this.selectedData = [];
                        res.body.forEach(element => {
                            this.selectedData.push({'name': element.perName, 'id': element.id});
                            this.selectedMap.set(element.id, element.perName);
                        });
                        this.selectedMapOrigin = new Map(this.selectedMap);
                        this.selectedIdOrigin = this.selectedId;
                        [...this.selectedDataOrigin] = this.selectedData;
                    });
                    break;
            }
        }
    }

    initTreeAndTable(){
        this.personQueryParams = Object.assign({
            'page': this.personPage-1,
            'size': this.personSize,
            'isOk.equals': '1',
        }, this.personQueryParams);
        this.treeList = [];
        let httpArray = [];
        let _this_ = this;
        //根据类型加载数据（待完善）
        switch (this.selectType) {
            case 'person':
                this.getPersonList();
            case 'duty':
                httpArray.push(this.getDutyList());
            case "dept":
                httpArray.push(this.getDeptList());
            case "organization":
                httpArray.push(this.getOrganiztaionList());
            default:
                Promise.all(httpArray).then((res) => {
                    res.forEach((item) => {
                        this.treeList = this.treeList.concat(item);
                    });
                    this.treeNodes = this.arrayService.arrToTreeNode(this.treeList, {
                        parentIdMapName: 'pid',
                        titleMapName: 'treeName'
                    });
                    
                    if(this.selectType == 'duty'){
                        this.initDutyTree();
                    }
                });
                break;
        }
    }
    
    getOrganiztaionList(){
        let _this_ = this;
        this.orgQueryParams = Object.assign({
            'size': '10000',
            'isOk.equals': '1',
            'sort': 'orderNum,asc',
        }, this.orgQueryParams);
        return new Promise(function(resolve,reject){
            _this_.organizationService.queryWithIgnoreAuth(_this_.orgQueryParams).subscribe((res) => {
                _this_.organizationList = res.body;
                _this_.organizationList.forEach((item) => {
                    item['pid'] = item.parentId;
                    item['treeName'] = item.orgName;
                    item['originType'] = 'organization';
                });
                resolve(_this_.organizationList);
            }, error =>{
                reject('组织机构人员选择组件-----获取单位列表失败');
            });
        });
    }

    getDeptList(){
        let _this_ = this;
        this.deptQueryParams = Object.assign({
            'size': '10000',
            'isOk.equals': '1',
        }, this.deptQueryParams);
        return new Promise(function(resolve,reject){
            _this_.deptService.queryWithIgnoreAuth(_this_.deptQueryParams).subscribe((res) => {
                _this_.deptList = res.body;
                _this_.deptList.forEach((item) => {
                       item['pid'] = item.parentDept || item.orgOrganizationId;
                       item['treeName'] = item.deptName;
                       item['originType'] = 'dept';
                });
                resolve(_this_.deptList);
            }, error =>{
                reject('组织机构人员选择组件-----获取部门列表失败');
            });
        });
    }

    getDutyList(){
        let _this_ = this;
        this.dutyQueryParams = Object.assign({
            'size': '10000',
            'isOk.equals': '1',
        }, this.dutyQueryParams);
        return new Promise(function(resolve,reject){
            _this_.dutyService.queryWithIgnoreAuth(_this_.dutyQueryParams).subscribe((res) => {
                _this_.dutyList = res.body;
                _this_.dutyList.forEach((item) => {
                    item['pid'] = item.parentDuty || item.orgDeptId;
                    item['treeName'] = item.dutyName;
                    item['originType'] = 'duty';
                });
                resolve(_this_.dutyList);
            }, error =>{
                reject('组织机构人员选择组件-----获取岗位列表失败');
            });
        });
    }

    getPersonList(){
        let _this_ = this;
        this.allChecked = false;
        this.indeterminate = false;
        this.tableLoading = true;
        this.personnelService.queryWithIgnoreAuth(this.personQueryParams).subscribe((res) => {
            this.personList = res.body;
            if(this.personListOrigin == null){
                [...this.personListOrigin] = this.personList;
            }
            this.personTotal = res.headers.get('x-total-count');
            this.personList.forEach((item) => {
                item['pid'] = item.ownerDept || item.ownerOrganization;
                item['treeName'] = item.perName;
                if(_this_.selectedMap.has(item.id)){
                    item['checked'] = true;
                }else{
                    item['checked'] = false;
                }
            });
            let selectedCount = this.selectedMap.size;
            
            //限制数量一则不置灰，勾选后自动覆盖
            if(this.maxMultipleCount == 1){

            }else if(this.maxMultipleCount > 0 && selectedCount == this.maxMultipleCount){
                this.personList.forEach((ele) => {
                    if(ele['checked'] != true){
                        ele['disabled'] = true;
                    }
                });
            }
            this.tableCheckedChange();
            this.tableLoading = false;
        }, error => {
            this.tableLoading = false;

        });
    }

    initDutyTree(){
        let _this_ = this;
        if(this.selectedData.length >= this.maxMultipleCount){
            this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                item.isSelectable = false;
                if(_this_.selectedId.indexOf(item.key) > -1){
                    item.isDisableCheckbox = false;
                }else{
                    item.isDisableCheckbox = true;
                }
            });
        }else{
            this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                item.isSelectable = false;
                if(item.origin.dutyName != undefined){
                    item.isDisableCheckbox = false;
                }else{
                    item.isDisableCheckbox = true;
                }
            });
        }
    }

    /**
     * 页码数量变动事件
     * @param 
     */
    paginationChange(event?) {
        this.personQueryParams = Object.assign(this.personQueryParams,{
            'page': this.personPage-1,
            'size': this.personSize,
            'isOk.equals': '1',
        });
        this.getPersonList();
    }


    
    /**
     * table checkbox点击事件
     * @param id 实体id
     * @param checked 是否勾选
     * @param name 实体名
     */
    tableCheckedChange(id?, checked?, name?): void {
        if(id){
            if(checked){
                if(this.selectedData.length == this.maxMultipleCount){
                    let tempId = Array.from(this.selectedMap.keys())[0];
                    this.personList.forEach(data => {
                        if(data['id'] == tempId){
                            data['checked'] = false;
                            this.selectedMap.delete(tempId);
                        }
                      });
                }
                this.selectedMap.set(id, name);
            }else{
                this.selectedMap.delete(id);
            }
            this.selectedId = Array.from(this.selectedMap.keys()).join(",");
            this.selectedIdArr = this.selectedId.split(',');
            const selectedData = [];
            this.selectedMap.forEach(function(value, key, map){
                selectedData.push({'name': value, 'id': key});
            });
            this.selectedData = selectedData;
        }
        const allChecked = this.personList.filter(value => !value['disabled']).every(value => value['checked'] === true);
        const allUnChecked = this.personList.filter(value => !value['disabled']).every(value => !value['checked']);
        this.allChecked = allChecked;
        if(this.personList.length == 0){
            this.allChecked = false;
        }
        this.indeterminate = (!allChecked) && (!allUnChecked);
        
        let selectedCount = this.selectedMap.size;
        
        //限制数量一则不置灰，勾选后自动覆盖
        if(this.maxMultipleCount == 1){
            return ;
        }
        if(this.maxMultipleCount > 0 && selectedCount >= this.maxMultipleCount){
            this.personList.forEach((ele) => {
                if(ele['checked'] != true){
                    ele['disabled'] = true;
                }
            });
        //    this.msg.info("当前选择数量已达上限");
        }else if(this.maxMultipleCount > 0){
            this.personList.forEach((ele) => {
                ele['disabled'] = false;
            });
        }
    }
    
    /**
     * table 全选事件
     * @param value 全选/反选
     */
    checkAll(value: boolean): void {
        this.personList.forEach(data => {
          if (!data['disabled']) {
            data['checked'] = value;
          }
          if(value == true){
            this.selectedMap.set(data.id, data.perName);
          }else{
            this.selectedMap.delete(data.id);
          }
        });
        this.selectedId = Array.from(this.selectedMap.keys()).join(",");
        this.selectedIdArr = this.selectedId.split(',');
        const selectedData = [];
        this.selectedMap.forEach(function(value, key, map){
            selectedData.push({'name': value, 'id': key});
        });
        this.selectedData = selectedData;
        this.tableCheckedChange();
    }

    treeMouseAction(name: string, e: any): void {
     //   console.log(name, e);

    }

    nzCheckBoxChange(name: string, e: any){
        let _this_ = this;
        this.selectedId = e.checkedKeys.map(i => i.key).join(",");
        this.selectedMap.clear();
        this.selectedData = e.checkedKeys.map(i => { 
            this.selectedMap.set(i.key, i.title);
            return {'id': i.key, 'name': i.title};
        });
        this.selectedIdArr = this.selectedId.split(',');
        //数量上限
        if(this.selectedData.length >= this.maxMultipleCount){
            this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                if(item.isChecked == true){
                    item.isDisableCheckbox = false;
                }else{
                    item.isDisableCheckbox = true;
                }
            });
        }else{
            this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                if(item.origin.dutyName != undefined){
                    item.isDisableCheckbox = false;
                }else{
                    item.isDisableCheckbox = true;
                }
            });
        }
    }

    /**
     * 左侧树点击后，刷新右侧table事件
     * @param name 
     * @param e 
     */
    refreshPersonList(name: string, e: any): void {
        console.log(name, e);
        let selectedKey = e.selectedKeys[0];
        this.selectedPath = '';
        while(selectedKey != null){
            this.selectedPath = selectedKey.title +  " / " + this.selectedPath;
            selectedKey = selectedKey.parentNode;
        }
        this.selectedPath = this.selectedPath.substr(0, this.selectedPath.length - 3);
        this.personPage = 0;
        this.personSize = 10;
        this.personQueryParams = {
            'page': this.personPage,
            'size': this.personSize,
            'isOk.equals': '1',
        };
        let originType = e.node.origin.originType;
        let id = e.node.origin.id;
        switch (originType) {
            case 'organization':
                this.personQueryParams['ownerOrganization.equals'] = id;
                break;
            case 'dept':
                this.personQueryParams['ownerDept.equals'] = id;
                break;
            case 'duty':
                this.personQueryParams['hostPost.equals'] = id;
                // this.personQueryParams['deputyPost.equals'] = id;
                break;
        
            default:
                break;
        }
        this.getPersonList();
        
    }

    searchPerson(){
        this.personQueryParams['perName.contains'] = this.searchPersonForm.get('perName').value;
        this.getPersonList();
    }

    /**
     * 下方select点击事件
     * @param newIds 点击后的新id数组
     * @param outClick 是否外部点击
     */
    selectedChange(newIds?, outClick?){
        let _this_ = this;
        if(this.selectType == 'duty'){
            this.selectedId = newIds.join(",");
            this.selectedIdArr = newIds;
            this.selectedMap.clear();
            this.selectedData = [];
            this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                if(newIds.indexOf(item.key) > -1){
                    item.checked = true;
                    _this_.selectedMap.set(item.key, item.title);
                    _this_.selectedData.push({'id': item.key, 'name': item.title});
                }else{
                    item.checked = false;
                }
            });
            //数量上限
            if(this.selectedData.length >= this.maxMultipleCount){
                this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                    if(item.isChecked == true){
                        item.isDisableCheckbox = false;
                    }else{
                        item.isDisableCheckbox = true;
                    }
                });
            }else{
                this.arrayService.visitTree(this.treeNodes, function (item, parent, deep) {
                    if(item.origin.dutyName != undefined){
                        item.isDisableCheckbox = false;
                    }else{
                        item.isDisableCheckbox = true;
                    }
                });
            }
        }else{
            this.selectedMap.forEach(function(value, key, map){
                let hasEle = newIds.some(element => {
                    return element == key; 
                });    
                if(!hasEle){
                    _this_.selectedMap.delete(key);
                    _this_.personList.forEach(ele => {
                        if(ele.id == key){
                            ele['checked'] = false;
                        }
                    });
                    for(let i = _this_.selectedData.length - 1; i>= 0; i--){
                        if(_this_.selectedData[i]['id'] == key){
                            _this_.selectedData.splice(i,1);
                        }
                    }
                }
            });    
            this.tableCheckedChange();
        }
        
        this.cdr.detectChanges();
        if(outClick == true){
            this.handleOk();
        }
    }

    openSelectModal(){
        this.modalIsVisible = true;
    }

    handleCancel(){
        //深拷贝
        this.selectedMap = new Map(this.selectedMapOrigin);
        this.selectedId = this.selectedIdOrigin;
        this.selectedIdArr = this.selectedId.split(',');
        [...this.selectedData] = this.selectedDataOrigin;
        this.personList.forEach((item) => {
            if(this.selectedMap.has(item.id)){
                item['checked'] = true;
            }else{
                item['checked'] = null;
            }
        });
        this.tableCheckedChange();
        this.modalIsVisible = false;
    }
    
    handleOk(){
        
        this.cdr.detectChanges();       
        if(this.btnOkMessageContent == null){
            this.confirmOk();
        }else{
            let _this_ = this;
            this.modalService.confirm({
                nzTitle: this.btnOkMessageTtile,
                nzContent: this.btnOkMessageContent,
                nzOnOk: () => this.confirmOk(),
                // nzStyle:  {
                //     'top': 'flex',
                //     'align-items': 'center',
                //     'justify-content': 'center',
                // },
                // nzFooter: [{
                //     label: '确定', // 按钮文本
                //     type: 'primary',// 类型
                //     // 按钮点击回调
                //     onClick: function () {
                //         _this_.confirmOk();
                //     },
                //   },{
                //     label: '取消', // 按钮文本
                //     type: 'default',// 类型
                // }],
              });
        }
    }

    confirmOk(){
        //深拷贝
        this.selectedMapOrigin = new Map(this.selectedMap);
        this.selectedIdOrigin = this.selectedId;
        [...this.selectedDataOrigin] = this.selectedData;
        this.selectedName = Array.from(this.selectedMap.values()).join(",");
        this.selectedIdChange.emit(this.selectedId);
        this.selectedNameChange.emit(this.selectedName);
        this.selectedDataChange.emit(this.selectedData);
        this.modalIsVisible = false;

        this.cdr.detectChanges();
    }
}