
import { Component, OnInit } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ArrayService } from '@shared/utils/array.service';
import { CacheService } from '@delon/cache';
import { BimInfoProcessNodeService } from './bim-info-process-node.service';
import { BimInfoProcessNode } from './bim-info-process-node.model';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-bim-info-process-node',
  templateUrl: './bim-info-process-node.component.html',
  styleUrls: ['./bim-info-process-node.component.less']
})
export class BimInfoProcessNodeComponent implements OnInit {
  public processNodeList = [];
  dateFormat = 'yyyy/MM/dd';
  record: any = {};

  constructor(
    private cacheService: CacheService,
    public http: _HttpClient,
    private modal: NzModalRef,
    public bimInfoProcessNodeService: BimInfoProcessNodeService,
    public msgSrv: NzMessageService,
    private fb: FormBuilder,
    private arrayService: ArrayService,
    private modalHelper: ModalHelper,
  ) {
  }
  // 初始化方法
  ngOnInit() {
    this.loadProcessNode();
  }



  // 关闭事件
  close(type) {
    this.modal.destroy(type);
  }


  submit() {
    if (this.processNodeList != null && this.processNodeList.length > 0) {
      let i = 0;
      const saveDataList = JSON.parse(JSON.stringify(this.processNodeList));
      saveDataList.forEach(processNode => {
        i++;
        processNode.bimInfoProcessId = this.record.id;
        processNode.orderNum = i;
        if (!isNullOrUndefined(processNode['startTime'])) {
          const time = processNode['startTime'];
          processNode.startTime = null;
          processNode['startTime'] = time[0];
          processNode['endTime'] = time[1];
        }
        if (processNode.id != null && processNode.id.length > 0) {
          this.bimInfoProcessNodeService.update(processNode).subscribe();
        } else {
          this.bimInfoProcessNodeService.create(processNode).subscribe();
        }
        if (i === this.processNodeList.length) {
          this.msgSrv.success('保存成功!');
          this.close(true);
        }
      });
    }

  }

  loadProcessNode() {
    const resultQueryParams = {
      sort: ['orderNum,asc']
    };
    if (!isNullOrUndefined(this.record.id)) {
      resultQueryParams['bimInfoProcessId.equals'] = this.record.id;
    }
    this.bimInfoProcessNodeService.query(resultQueryParams).subscribe((res: any) => {
      if (res.ok) {
        const data = res.body;
        if (data.length === 0) {
          this.addProcessNode();
        } else {
          data.forEach(e => {
            if (!isNullOrUndefined(e.startTime) && !isNullOrUndefined(e.endTime)) {
              e.startTime = [e.startTime, e.endTime];
            }
          });
          this.processNodeList = data;
        }
      }
    });
  }

  // levelNameChange($event, i) {
  //   const target = $event.target;
  //   const prizeLevel = this.processNodeList.length >= i ? this.processNodeList[i] : null;
  //   if (prizeLevel != null) {
  //     prizeLevel.levelName = target.value;
  //   }
  // }

  // levelNumChange($event, i) {
  //   const target = $event.target;
  //   const prizeLevel = this.processNodeList.length >= i ? this.processNodeList[i] : null;
  //   if (prizeLevel != null) {
  //     prizeLevel.levelNum = target.value;
  //   }
  // }

  // onInput($event) {
  //   if (!$event) {
  //     return;
  //   }
  //   const target = $event.target;
  //   const regexp = /[^(0-9)]/ig;
  //   target.value = target.value.replace(regexp, '');
  // }

  addProcessNode() {
    // const tmpProcessNode = new BimInfoProcessNode();
    // tmpProcessNode.orderNum = this.processNodeList.length + 1;
    this.processNodeList.push(new BimInfoProcessNode());
  }

  subProcessNode(i) {
    const processNode = this.processNodeList[i];
    if (processNode.id && processNode.id !== null) {
      this.bimInfoProcessNodeService.delete(processNode.id).subscribe();
    }
    this.processNodeList = this.processNodeList.filter(e => e !== processNode);
  }
}
