import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { EduLiveBroadcasts } from '../edu-live-broadcasts.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import {EduLiveBroadcastsService } from '../edu-live-broadcasts.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';

import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-bim-bimInfo-qq-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EduLiveBroadcastsEditComponent implements OnInit {

  record: any = {};
  bimInfoId:string;
   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';
  hasToUpdate = false;

  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  extField1Banner={'extField1.equals': "EDU_LIVE_BROADCASTS"};

    // 文件id
    picTitle="上传教师头像图片";

    toUpdateFkFileList = [];

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private eduLiveBroadcastsService: EduLiveBroadcastsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],   
      name: [null, [Validators.required, Validators.maxLength(200) ]],
      lecturerName: [null, [Validators.required, Validators.maxLength(100) ]],    
      liveBroadcastExplain:[this.bimInfoId, [Validators.required ]],
      url: [null, [Validators.required, Validators.maxLength(600)  ]],
      startTime: [null, [Validators.required ]],    
      endTime:[this.bimInfoId, [Validators.required ]],
      isValid: [null, [Validators.required ]],
      jhiOrder: [null, [Validators.required ]],    
      userDefine5: [null, []],

    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;

    this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';


  }

  ngOnInit() {
    if (!this.isAdd) {
      this.fileId = this.record.id;
      this.loadData();
    }else{
      this.fileId =  this.entityId;
    }

  }




  loadData(): void {
       this.record.isValid = this.record.isValid + '';
       this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    debugger; 
    const param = {};
      for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查直播信息是否完整');
      return;
    }

    if(this.isAdd &&( this.toUpdateFkFileList===null ||  this.toUpdateFkFileList == undefined || this.toUpdateFkFileList.length <=0))
    {
      this.msg.error('没有上传讲师头像，不能保存！');
      return;
    }

    if(param["startTime"] > param["endTime"])
    {
      this.msg.error('开始时间不能晚于结束时间，不能保存！');
      return;
    }
    
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    this.eduLiveBroadcastsService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.hasToUpdate = true;
        this.msg.info('修改成功');
        this.close(true);
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.eduLiveBroadcastsService.create(param).subscribe(res => {
      this.record = res.body;
      debugger;
      if (this.record  ) {
        this.hasToUpdate = true;
        this.fileId = this.record.id;
        this.msg.info('保存成功');
        this.close(true);
      } else {
        this.msg.error('保存失败');
      }
    });
  }


  Cancel(){
   // this.nzModalRef.destroy();

    this.close(false);
  }


    // 上传文件参数
    fileParam = (file: UploadFile) => {
      const tmp = {
        name: file.name,
        fileFk: this.record.id,
        fileDesc: file.name,
        uploader: this.entityName,
        fileType: file.name.substring(file.name.indexOf('.') + 1)
      };
      return tmp;
    }
  
  
    beforeUpload = (file: UploadFile): boolean => {
      const format = [ 'bmp', 'jpg', 'png', 'gif', 'jpeg'];
      const type = file.name.substring(file.name.indexOf('.') + 1).toLowerCase();
      if (format.indexOf(type) < 0) {
        this.msg.error('格式错误,仅支持' + format + '格式');
        return false;
      }
      this.fileList.push(file);
      return true;
    }
  
  
    handleUploadChange(item: any) {
      if (item.type === 'success') {
        const res = item.file.response;
        this.form.controls['fileId'].setValue(res.id);
      }
    }


    /**
      * 上传成功回调
      * @param file
      */
     onSuccessfulBanner(file: any) {
      debugger;
      const thisSuccessFile = file.successFile;
      if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
        
        thisSuccessFile.forEach(item => {
             this.toUpdateFkFileList.push(item.response);
             this.form.controls['userDefine5'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
          });
      }
    }

    /**
     * 删除成功回调
     * @param file
     */
    onDeleteFileBanner(file: any) {

        if (file.file) {
          this.toUpdateFkFileList = this.toUpdateFkFileList.filter(d => d.uid !== file.file.uid);
        }
        this.form.controls['userDefine5'].setValue(null);
     }


         /**
    * 上传成功回调
    * @param file
    */
   onSuccessfulPageImg(file: any) {

    const thisSuccessFile = file.successFile;
    if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
      thisSuccessFile.forEach(item => {
        this.toUpdateFkFileList.push(item);
       });
    }
  }

  /**
   * 删除成功回调
   * @param file
   */
  onDeleteFilePageImg(file: any) {

    if (file.file) {
      this.toUpdateFkFileList = this.toUpdateFkFileList.filter(d => d.uid !== file.file.uid);
    }
  }




   // 关闭事件
   close(type?) {
    if (this.hasToUpdate  && '' !== this.record.id) { // need
      let isChangeData = false;
      if ( this.toUpdateFkFileList.length > 0)
      {
        isChangeData = true;
          for (const i of Object.keys(this.toUpdateFkFileList)) {
            this.fileUploadService.updateFkBatch(this.toUpdateFkFileList[i].fileFk, this.record.id)
              .subscribe((res: any) => {  this.nzModalRef.destroy(type);}, (res: HttpResponse<any>) => this.msg.create('error', '教师头像上传失败'));
          }
      }

      if(!isChangeData){
        this.nzModalRef.destroy(type);
      }

    } else {
      this.nzModalRef.destroy(type);
    }
  }

  
  /**
 * 销毁时删除附件，防止文件表过多
 */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    debugger;
    if (null !== this.toUpdateFkFileList && this.toUpdateFkFileList.length > 0 && !this.hasToUpdate) {
          const idArray = new Array();
          this.toUpdateFkFileList.forEach((d) => {
            idArray.push(d.id);
          });
          this.fileUploadService.delete(idArray).subscribe((res: any) => {
            console.log(idArray);
          });
    }
  }


}
