import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { EduSelectCourseService } from './selectCourse.service';
import { SimpleTableComponent} from '@delon/abc';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-selectCourse',
  templateUrl: './selectCourse.component.html',
  styles: []
})
export class SelectCourseComponent implements OnInit {
  queryParams = {
    // 查询搜索框的字段要预置
    'courseName.contains': '',
    'isDelete.contains' : 0,
    'isPublish.contains': 1
    // sort固定
    // sort: ['orderNum,asc']
  };
  public page  = 0;
  public size  = 10;
  courseList: any[] = [];
  allChecked = false;
  indeterminate = false;

  public subjectType: string; // 题目类型
  public subjectQuantity: number; // 题目数量
  public courseId: string; // 课程id
  public catalogId: string; // 章节id
  public selIds: string; // 选中的id
  public ids: string; // 自动选择选中的id


  public total: number;
  @ViewChild('st') st: SimpleTableComponent;
  constructor(
    private modalHelper: ModalHelper,
    private nzModalRef: NzModalRef ,
    public msg: NzMessageService,
    public eduSelectCourseService: EduSelectCourseService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    ) {
    }

  ngOnInit() {
    this.getDataList();
  }

  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
   this.eduSelectCourseService.queryCourse(copyParams)
      .subscribe((res: any) => {
        console.log(res);
      this.courseList = res.body;
      this.total = res.headers.get('X-Total-Count');
      });
  }


  /**
 * 页码变动事件
 * @param
 */
changePageIndex(pageIndex) {
  this.page = pageIndex - 1;
  this.getDataList();
}

  /**
  * 页码数量变动事件
  * @param
  */
 paginationChange(event) {
  this.page = event.pi - 1;
  this.size = event.ps;
  this.getDataList();
}


confirm(data: any) {

   const subjedtIdStr = '';
  const ret = {
    'courseId' : data.id,
    'courseName': data.courseName
  };
    this.nzModalRef.destroy(ret);
 }

}
