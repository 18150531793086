import { Component,Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { GuidUtil } from '@shared/utils/guid.util';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimPersonService } from './bim-person.service';
import { BimPerson } from './bim-person.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimPersonEditComponent} from './edit/edit.component';
import { UserService } from "../../../../admin/system/users/users.service";
import {ProvinceService} from '../../provinces/provinces.service';
import {UcommonService} from '../../ucommon//ucommon.server';
import {BimInfoEventsService} from '../bim-info-events/bim-info-events.service';

import {BimPersonTeacherListComponent} from './bim-person-teacher/bim-person-teacher-list.component';
import {BimPersonItemsService} from './bim-person-items/bim-person-items.service';
import { isNullOrUndefined } from 'util';
import { MessageHelper } from '@shared/helpers/MessageHelper';
import {BimPersonProgressComponent} from './progress/progress.component';
import {BimInfoEventsItemsService} from '../bim-info-events/bim-info-events-items/bim-info-events-items.service';

@Component({
  selector: 'app-bim-bimPerson',
  templateUrl: './bim-person-list.component.html',
  styleUrls: ['./bim-person-list.component.less']
})
export class BimPersonListComponent implements OnInit {
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科', '2': '中职'};

  public schoolAuditeList = {'':'全部','0': '未通过',  '1' : '通过','false':'未审核'};
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimPerson[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];
  provinceListAll = [];
  bimEventsList=[];
  bimItemsList = [];

  userLocationList = [];
  userProvinceList=[];
  userPersonItemList = [];//选中参赛赛项
 
  entityId:string;
  entityName:String;
  accountName:String;

  exportExcelKeyName = "";

  public bimInfoStatus = 1;//-1 全部;
  
  public currentBimInfo = null;
  curBimEventId:string;

  isloadding = false;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'keyword.contains': '',
    'schoolType.contains': '',
    'regionId.contains': '',
    'province.contains': '',
    'userDefine1.equals': '',
    'bimInfoEventsId.contains':'',
    'itemId.equals': '',

    // sort固定
     sort: ['createTime,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '姓名', index: 'personName' , width: '9%'},
    { title: '学校', index: 'schoolName' , width: '15%'},
    { title: '类别', index: 'schoolType' , width: '5%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else {
          return item.schoolType;
        }
      }
    },

    { title: '邮箱', index: 'personEmail' , width: '10%'},

    { title: '联系电话', index: 'personTel' , width: '8%'},
    { title: '毕业时间', index: 'grade' , width: '8%'},
    { title: '专业', index: 'profession' , width: '8%'},

    { title: '参赛专项',  width: '6%',buttons: [
        { text: '点击查看',  click: (item:any) => this.viewTeamItems(item), },
      ]
    },
    { title: '确认参赛专项',  width: '6%',buttons: [
        { text: '点击查看',  click: (item:any) => this.viewTeamItems(item, 1), },
      ]
    },
    { title: '区域', index: 'regionId' , width: '5%',
      format: (item: any) => {
         return this.findLocationName(item.regionId);
      }
    },
    { title: '省份', index: 'province' , width: '5%',
      format: (item: any) => {
        return this.findProvinceName(item.province); 
      }
    },
   
    
    
    // { title: '状态', index: 'userDefine1' , width: '5%',
    //   format: (item: any) => {
    //     if (item.userDefine1 === '1') {
    //       return '通过';
    //     } else if (item.userDefine1 === '0') {
    //       return '未通过';
    //     } else{
    //       return '未审核';
    //     }
    //   }
    // },

  
    {
      title: '操作', className: 'text-center', width: '12%',
      buttons: [
        { text: '指导老师',  click: (item:any) => this.editTeamTeacher(item), },

        { text: '编辑',  click: (item:any) => this.edit(item), },
        { text: '删除', click: (item: any) => { this.remove(item); } },
        // { text: '审核通过',  click: (item:any) => this.audit(item,1), },
        // { text: '取消审核',  click: (item:any) => this.audit(item,0), },
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimPersonService: BimPersonService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    public bimInfoEventsService: BimInfoEventsService,
    private bimPersonItemsService:BimPersonItemsService,
    private bimInfoEventsItemsService:BimInfoEventsItemsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;


  }

  ngOnInit() {
    if(this.accountName === 'admin')
    {
      this.loadLocationList();
      this.loadprovinceList();

      this.getDataList();
    }else{
      this.getUserProvinces();
    }
  }


  viewTeamItems(person, status?){

    // let copyParams = {};
    // copyParams['personId.contains'] = person.id;
    // if (!isNullOrUndefined(status)) {
    //   copyParams['status.equals'] = status;
    // }
    this.bimPersonItemsService.getBimPersonItemByPerId(person.id).subscribe((res: any) => {
       let record =  res.body;
       let itemNames = "";
       if(record && record.length > 0){
        for (let i = 0; i < record.length; i++) {
          const element = record[i];
          if (!isNullOrUndefined(status) && element.status !== status) {
            continue;
          }
          if (itemNames === '') {
           itemNames = element.itemName;
          } else {
           itemNames = itemNames + '，' +  element.itemName;
          }
        }
        if (itemNames === '') {
          this.msg.info(isNullOrUndefined(status) ? '没有报名专项!' : '无确认参赛专项!');
        } else {
          MessageHelper.success(itemNames, isNullOrUndefined(status) ? '报名参赛专项' : '确认参赛专项');
        }
          //  this.msg.info(itemNames);
       }else{
        this.msg.info(isNullOrUndefined(status) ? '没有报名专项!' : '无确认参赛专项!');
       }
    });
  }


/**
 * 获取用户地区信息
 */
  getUserProvinces(){
     this.userProvinceList = [];
     this.userLocationList = [];
    this.provinceService.getUserProvinces()
    .subscribe((res: any) => {
      
        res.body.forEach(element => {
            this.userProvinceList.push(element.id);
            this.userLocationList.push(element.fkregionId);

            
        });
        this.loadLocationList();
        this.loadprovinceList();
        this.getDataList();

        //this.userProvinceList = res.body;
        console.log(res.body);
    });
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     if(this.bimEventsList != null && this.bimEventsList.length >0)
     {
       
     }else{

      this.list = [];
      this.total = 0;
      this.isloadding = false;

       return false;
     }

     let copyParams = {};
    const q = this.queryParams;
    
    if (isReset === true) {
     // this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
      Object.keys(q).forEach(function (key) {
        if(key != 'sort' && key != 'bimInfoEventsId.contains'){
          q[key] = '';
        }
      });
      this.queryParams = Object.assign(this.queryParams,q);
      this.queryParams["bimInfoEventsId.contains"] = this.bimEventsList[0].id;
      copyParams["bimInfoEventsId.contains"] = this.bimEventsList[0].id;
      this.userPersonItemList = [];
      this.curBimEventId = this.bimEventsList[0].id;
      this.provinceList = this.provinceListAll;
      this.getEventItemsList();
      this.page = 0;
      this.st.reset();
    } else {
      Object.keys(q).forEach(function (key) {
        if(key ==='keyword.contains' && q[key] !== '' &&　q[key] !== null )
        {
          copyParams['personName.contains'] = q[key];
          copyParams['schoolName.contains'] = q[key];
          copyParams['personEmail.contains'] = q[key];
          copyParams['personTel.contains'] = q[key];
        }if(key ==='userDefine1.equals' && q[key] === 'false'){
          copyParams['userDefine1.specified'] = q[key];
        }
        else if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;


    this.curBimEventId =  copyParams["bimInfoEventsId.contains"];

    let tmplocation = copyParams['regionId.contains'];

    if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
    {   
      //delete copyParams['location.contains'];
       copyParams['regionId.in'] = this.userLocationList;
    }

    let tmpprovince = copyParams['province.contains'];

    if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
    {
      //delete copyParams['province.contains'];
      copyParams['province.in'] = this.userProvinceList;
    }
    copyParams['isDelete.equals'] = 0;

    if(copyParams['itemId.equals']){
      copyParams['itemId.in'] = [copyParams['itemId.equals']];
    }
    
    // this.bimPersonService.queryNew(copyParams)
    this.bimPersonService.getAllBimTeamsDatas(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
  
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {

    if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
    {
       this.msg.info('当前赛事没有个人赛项，不能添加个人参赛数据!');
       return false;
    }

    this.modal
      .static(BimPersonEditComponent, { record: { id: null,bimInfoEventsId:this.curBimEventId },isAdd: true, 
        bimInfoEventsId: this.curBimEventId, bimInfoType: this.currentBimInfo.type === 'province' ? 'province' : null })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  edit(record){
    this.modal
    .static(BimPersonEditComponent, { record: record,isAdd: false, bimInfoEventsId: this.curBimEventId, 
      bimInfoType: this.currentBimInfo.type === 'province' ? 'province' : null })

    .subscribe((res) => {
      if(res === true)
      {
        this.getDataList();
      }
      
    });
  }


  /**
   * 老师管理
   * @param record 
   */
  editTeamTeacher(record){
    this.modal
    .static(BimPersonTeacherListComponent, {  personId: record.id, schoolName:record.schoolName, personName:record.personName})

    .subscribe((res) => {
      if(res === true)
      {
        this.getDataList();
      }
      
    });
  }


/**
 * 审核或取消审核
 * @param record 
 * @param flag 
 */
  audit(record, flag){
     record.userDefine1=flag;
     this.bimPersonService.update(record).subscribe(res => {
        if (record  ) {
          if(flag === 1)
          {
            this.msg.info('审核成功！');
          }else{
            this.msg.info('取消审核成功!');
          }
          
          this.getDataList();
        } else {
          if(flag === 1)
          {
            this.msg.info('审核失败！');
          }else{
            this.msg.info('取消审核失败!');
          }
        }
      });
    
  }

  


  /**
  * 删除
  */
  remove(res: any) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        res.isDelete = 1;
        this.bimPersonService.update(res).subscribe(() => {
          this.getDataList();
          this.st.clearCheck();
        });
        // this.bimPersonService.delete(res.id)
        //   .subscribe(() => {
        //     this.getDataList();
        //     this.st.clearCheck();
        //   });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimPersonService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



    /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      this.userService.queryRegionList(dataParams).subscribe(res => {
        
        if (res && res.body) {
          this.locationList = res.body;
        }
      });
  }


  
  /**
   * 加载省份列表
   */
  loadprovinceList(){
    this.provinceList = [];
    let regionIds = [];
    if(this.queryParams['regionId.contains']){
      regionIds = [this.queryParams['regionId.contains']];
    }

    const params = {
      'id.in':this.userProvinceList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };
    if(regionIds && regionIds.length > 0){
      params['fkregionId.in'] = regionIds;
    }
    
    this.userService.queryProvinceList(params).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.provinceList = data;
         this.provinceListAll = data;
      }
    });
  }

/**
 * 查询区域名
 * @param location 
 */
  findLocationName(location){
     let tmpList = this.locationList.filter(d=>d.id===location);
     if(tmpList.length >0)
     {
        return tmpList[0].regionName;
     }else{
       return '';
     }
  }

  /**
   * 查询省份名称
   * @param location 
   */

  findProvinceName(location){
    let tmpList = this.provinceList.filter(d=>d.id===location);
    if(tmpList.length >0)
    {
       return tmpList[0].provinceName;
    }else{
      return '';
    }
 }

 /**
  * 导出excel
  */

 exportExcel(){

      if(this.bimEventsList != null && this.bimEventsList.length >0)
      {
        
      }else{
        return false;
      }

      let copyParams = {};
      const q = this.queryParams;

      Object.keys(q).forEach(function (key) {
        if(key ==='keyword.contains' && q[key] !== '' &&　q[key] !== null )
        {
          copyParams['personName.contains'] = q[key];
          // copyParams['schoolName.contains'] = q[key];
          // copyParams['personEmail.contains'] = q[key];
          // copyParams['personTel.contains'] = q[key];
        }else if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });


      this.curBimEventId =  copyParams["bimInfoEventsId.contains"];

      let tmplocation = copyParams['regionId.contains'];

      if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0)))
      {   
         copyParams['regionId.in'] = this.userLocationList;
      }

      let tmpprovince = copyParams['province.contains'];

      if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)))
      {
        copyParams['province.in'] = this.userProvinceList;
      }
      copyParams['isDelete.equals'] = 0;


      this.exportExcelKeyName = "person_Export_" +  new GuidUtil().toString();
      copyParams["exportExcelKeyName"] =  this.exportExcelKeyName;
      copyParams['bimInfoType'] = this.currentBimInfo.type;//enterprise/bim

      if(copyParams['itemId.equals']){
        copyParams['itemId.in'] = [copyParams['itemId.equals']];
      }

      this.bimPersonService.exportExcel(copyParams);

      this.ExportProgress(this.exportExcelKeyName);
 }


/**
 * 选择赛事事件
 * @param bimInfo 
 */
  clickContest(bimInfo) {
      this.currentBimInfo = bimInfo;
      //this.queryParams["bimInfoId"] = this.currentBimInfo.id;
      this.getEventsDataList();
  }

/**
 * 获取赛事赛项
 * @param isReset 
 */
  public getEventsDataList(isReset?: boolean) {
      let copyParams = {sort: ['createTime,desc']};
      const q = this.queryParams;
    
      copyParams['page'] = 0;
      copyParams['size'] = 1000;
      copyParams['bimInfoId.contains'] = this.currentBimInfo.id;
      copyParams['eventsType.equals'] ='1';
      copyParams['isDelete.equals'] = 0;
      
      this.bimInfoEventsService.query(copyParams)
        .subscribe((res: any) => {
            if(res.ok && res.body.length >0)
            {
              this.bimEventsList = res.body;
              this.curBimEventId = this.bimEventsList[0].id;
              this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
              this.getDataList();
              this.getEventItemsList();
            }else{
              this.bimEventsList = [];
              this.bimItemsList = [];
              this.curBimEventId = "-1";
              this.queryParams["bimInfoEventsId.contains"] = "-1";
              this.getDataList();
            }
          });   
   }

   /**
   * 加载赛项的专项列表
   */
  getEventItemsList(){
    this.bimItemsList = [];
    if(!this.curBimEventId){
      return;
    }

    this.bimInfoEventsItemsService.getBimItemInfoByEventId({
      'bimInfoEventsId':this.curBimEventId,
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.bimItemsList = data;
      }
    });
  }

/**
 * 赛项选择框改变
 * @param $event 
 */
   bimEventsChange($event){
     this.curBimEventId = $event;
     this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
     this.queryParams["itemId.equals"] = null;
     this.userPersonItemList = [];
     this.getDataList();
     this.getEventItemsList();
   }

    /**
        * 区域改变
        * @param $event 
        */
    localtionChange($event){
      // console.log('$event:',$event);
      this.queryParams['province.contains'] = "";
      if(!this.provinceListAll || this.provinceListAll.length <= 0){
        this.loadprovinceList();
      }else{
        this.provinceList = !$event ? this.provinceListAll : this.provinceListAll.filter(w=>w.fkregionId == $event);
      }
      this.page = 0;
      this.getDataList();
    }

   /**
    * 其他选项改变
    * @param $event 
    */
   schoolTypeChange($event){
    this.page = 0;
    this.getDataList();
  }

  searche(){
    this.page = 0;
    this.getDataList();
  }

  ExportProgress(exportExcelKeyName){

    this.modal.static(BimPersonProgressComponent,{exportExcelKeyName:exportExcelKeyName},600,{ nzClosable: false}).subscribe((res) => {
        if(res)
        {
          let queryParams = {};
          queryParams["exportExcelKeyName"] = exportExcelKeyName;
            this.bimPersonService.downloadExportExcelFile(queryParams);
        }
    });
  }
}
