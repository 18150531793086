import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimSoftDownloadDetails } from './bim-info-soft.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BimSoftDownloadDetailsService extends ThsBaseService<BimSoftDownloadDetails> {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-soft-download-details');
  }
  
  updateSoftDownloadUrl (record: BimSoftDownloadDetails): any {
    const copy = this.convertDateFromClient(record);
    return this.http.put(this.resourceUrl + '/updateSoftDownloadUrl', copy, { observe: 'response'})
    .pipe(map((res: HttpResponse<BimSoftDownloadDetails>) => this.convertDateFromServer(res)));
  } 
  
  updateSoftInfoById (record: BimSoftDownloadDetails): any {
    const copy = this.convertDateFromClient(record);
    return this.http.put(this.resourceUrl + '/updateSoftInfoById', copy, { observe: 'response'})
    .pipe(map((res: HttpResponse<BimSoftDownloadDetails>) => this.convertDateFromServer(res)));
  } 

}
