import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ReuseTabService } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { NicheEditComponent } from 'app/routes/business/iucce/component/niche-edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-project-closing-view',
  templateUrl: './view.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less','./view.component.less']
})
export class ProjectClosingViewComponent implements OnInit {

  validateForm: FormGroup;
  dataSet = [{}];
  dataSet1 = [{}];
  dataSet2 = [{
    id:1,
    year: '2023',
    month: '3',
    nicheInfo: '商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况',
    completionRate: '100%',
    completionInfo: 1,
    orderStatus:1,
    time: '2023-01-20',
    amount: 9600000,
    orderNumber: 'Y12345678910112',

  }];
  countRemark = 0;
  opportunitys = [];
  countRemark2 = 0;

  showUploadListSwitch1 = true;
  showUploadListSwitch2 = true;
  showUploadListSwitch3 = true;

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private reuseTabService: ReuseTabService,
    private modal: ModalHelper,
  ) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      accepter: [{value:null,disabled:true}, [Validators.required]],
      projectExpirationTime: [{value:null,disabled:true}, [Validators.required]],
    });
    
  }

  //查看
  viewNiche(data) {
    this.modal.static(NicheEditComponent, {
      pageType: 'view',
      record:data
    }, 1080).subscribe();
  }

  return() {
    this.reuseTabService.replace('/business/iucce/projectClosing');
  }

}
