import { Component, OnInit, ViewChild } from '@angular/core';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { SimpleTableComponent } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { ExamdetailsService } from './examdetails.service';
import { ExamindetailsComponent } from './examindetails/examindetails.component';

@Component({
  selector: 'app-examdetails',
  templateUrl: './examdetails.component.html',
  styles: []
})
export class ExamdetailsComponent implements OnInit {

  public page = 0;
  public size = 15;
  public total: number;
  queryParams = {
    // 查询搜索框的字段要预置
    'courseName': '',
    // 'catalogName': '',
    'examName': '',
    'studentName': '',
    'mobile': '',
    'studentemail': '',
  };
  Result: any[] = [];
  allChecked = false;
  indeterminate = false;
  setOfCheckedId = new Set<string>();
  public APP_PERMISSIONS = APP_PERMISSIONS;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;
  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    private modalHelper: ModalHelper,
    public modalService: NzModalService,
    public examdetailsService: ExamdetailsService,
  ) { }

  ngOnInit() {
    this.getDataList();
  }
  /**
* 获取数据列表
* @param {string} url
*/
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    this.examdetailsService.queryExamByStuAndCourseAndUser(copyParams)
      .subscribe((res: any) => {
        this.Result = res.body.data;
        this.total = res.headers.get('X-Total-Count');
      });
  }
  /**
  * 页码变动事件
  * @param
  */
  changePageIndex(pageIndex) {
    this.page = pageIndex - 1;
    this.getDataList();
  }
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }
  checkAll(value: boolean): void {
    this.Result.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.Result.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }
  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshStatus();
  }
  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  examdetails(data: any) {
    const tmp = data;
    console.log(tmp, 'tmp');
    this.modal
      .static(ExamindetailsComponent, { examId: tmp.eduExamId, studentId: tmp.studentId, })
      .subscribe(() => this.getDataList());
  }

}
