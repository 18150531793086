import { workflowModules } from '@shared/app.constants';

/**
 * 工作流
 */
export class  WorkFlowHelper {

    /**
     * 获取app.constants.ts中的对象
     * @param key 
     * @returns 
     */
    static getWorkFlowParams(key:string,data:any): any {
        if(!key){
            return {};
        }
        const wfModules = workflowModules[key];
        if(!wfModules){
            return {};
        }
        let param = {
            moduleId : wfModules.moduleId,
            busiUrl : wfModules.busiUrl,
            businessCode : wfModules.businessCode
        };
        
        Object.assign(param,data);
        
        if(data){
            if(param.busiUrl){
                if(param.busiUrl.indexOf("?") == -1){
                    param.busiUrl += "?";
                }else{
                    param.busiUrl += "&";
                }
                
            }
            param.busiUrl += "id="+data.id;// id为项目id
        }
        
        return param;
    }
}