import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegionInfo } from './region-info.model';
import {ThsBaseService } from  '../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class RegionInfoService extends ThsBaseService<RegionInfo> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/region-infos`);
    }



    
}

