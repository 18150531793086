
export enum ServiceEnum {
  WfHolidayService = 'WfHolidayService',
  PersonnelService = 'PersonnelService',
}
export enum OperateTypeEnum {
   Save=1,
   Send,
   Submit,
   Reject,
   Revoke
}