import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { BimScoringRuleItemService } from './bim-scoring-rule-item.service';

@Component({
  selector: 'app-bim-scoring-rule-item',
  templateUrl: './bim-scoring-rule-item.component.html',
  styleUrls: []
})
export class BimScoringRuleItemComponent implements OnInit {

  record: any = {};
  public title = '';
  public isloadding = false;

  public dataList = [];

  ngOnInit(): void {
    this.initData();
  }

  constructor(
    private bimScoringRuleItemService: BimScoringRuleItemService,
  ) { }

  initData() {
    const fields = ['itemName2', 'itemRule', 'answer', 'remark'];
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.title = (isNullOrUndefined(this.record.itemName) ? '' : this.record.itemName) + '评分规则';
      this.isloadding = true;
      this.bimScoringRuleItemService.getRuleItemData(this.record.id).subscribe(res => {
        this.isloadding = false;
        const data = res.body;
        if (data && data.length > 0) {
          data.forEach(e => {
            for (let i = 0; i < fields.length; i++) {
              const field = fields[i];
              if (!isNullOrUndefined(e[field])) {
                e[field] = e[field].replaceAll('\n', '<br>');
              }
            }
          })
          this.dataList = data;
        }
        console.log(this.dataList);
      });
    }
  }

}
