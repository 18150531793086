import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Project } from './../projectReport/projectReport.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectReportAuditService extends ThsBaseService<Project> {

    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.IucceServe + `/iucce-project-audits`);
    }

    /** GET: 获取项目信息 */
    getNotictingProjectByPage (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.resourceUrl+`/getNotictingProjectByPage`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /**
     * 暂存表单数据
     * @param record 
     * @returns 
     */
     saveTempData(record:any,wfParams:any): Observable<any> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/saveTempData`,copy, { params : { wfParams : JSON.stringify(wfParams) }, observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

    /**
     * 流程发送
     * @param record 
     * @returns 
     */
    send (record:any,workflowForm:any): Observable<any> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/send`,copy,{ params:{ wfForm:JSON.stringify(workflowForm) } , observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

    /**
     * 提交
     * @param params
     * @returns 
     */
    submit (record:any): Observable<any> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/submit`,copy, {observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

    /**
     * 撤回
     * @param params
     * @returns 
     */
     revoke (record:any): Observable<any> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/revoke`,copy, {observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

    /**
     * 通过项目id获取对应业务的办理意见
     * @param projectId 
     * @param businessCode 
     */
    getAuditIdeaByProjectId(projectId:string,businessCode:string,pageable:any): Observable<HttpResponse<any[]>>{
        let queryParams = { 
            projectId : projectId , 
            businessCode : businessCode
        };
        debugger;
        const copy = Object.assign(queryParams,pageable);
        return this.http.get<any[]>(this.resourceUrl+`/getAuditIdeaByProjectId`,{ params : copy ,observe: 'response'});
    }
    
}

