import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ACLGuard } from '@delon/acl';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { EduSoftwareAuthComponent } from './auth/auth.component';
import { EduSoftwareDownloadComponent } from './download/download.component';



const routes: Routes = [
  {
    path: '',
    children: [
       { path: 'software-download/auth', component: EduSoftwareAuthComponent ,data: { title: '授权管理'}},
       { path: 'software-download/download', component: EduSoftwareDownloadComponent ,data: { title: '下载管理'}},
       
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EduSoftwareRoutingModule { }
