export class CoursePay   {
    constructor(
        public  id?: string,
        public  orderNo?: string,
        public  serialNumber?: string,
        public  eduCourseId?: string,
        public  eduStudentId?: string,
        public  eduCourseName?: string,
        public  eduStudentName?: string,
        public  payType?: string,
        public  price?: string,
        public  payNum?: number,
        public  totalPrice?: string,
        public  status?: number,
        public  payResult?: string,
        public  payTime?: Date,
        public  orderNum?: number,
        public  remark?: string,
        public  createdBy?: string,
        public  createdDate?: Date,
        public  lastModifiedBy?: string,
        public  lastModifiedDate?: Date,
        public  invoiceStatus?: string,
        public  invoiceReceivingName?: string,
        public  invoiceReceivingTel?: string,
        public  invoiceReceivingAddress?: string,
        public  invoiceType?: number,
        public  invoiceTitle?: string,
        public  invoiceNum?: string,
        public  taxpayerSn?: string,
        public  invoiceAddress?: string,
        public  invoiceTel?: string,
        public  bankAccount?: string,
        public  bankName?: string,
        public  province?: string,
        public  city?: string,
        public  region?: string,
        public orderTime?:Date,
    ) {}
}
