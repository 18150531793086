import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError,  tap } from 'rxjs/operators';
import {NzMessageService} from "ng-zorro-antd";

import { Module } from './modules.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class ModuleService {

  private resourceUrl = `/thsuaa/api/sys-modules`;  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: NzMessageService) { }


  /** GET module by id. Will 404 if id not found */
  get(id: number): Observable<Module> {
    const url = `${this.resourceUrl}/${id}`;
    return this.http.get<Module>(url).pipe(
      tap(_ => this.log(`fetched module id=${id}`)),
      catchError(this.handleError<Module>(`getModule id=${id}`))
    );
  }

  /** POST: add a new module to the server */
  create (module: Module): Observable<Module> {
    return this.http.post<Module>(this.resourceUrl, module, httpOptions).pipe(
      tap((module: Module) => this.log(`added module  id=${module.id}`)),
      catchError(this.handleError<Module>('addModule'))
    );
  }

  /** PUT: update the module on the server */
  update (module: Module): Observable<any> {
    return this.http.put(this.resourceUrl, module, httpOptions).pipe(
      tap(_ => this.log(`updated module id=${module.id}`)),
      catchError(this.handleError<any>('updateModule'))
    );
  }
  
  /** GET module from the server */
  list (queryParams ?: any): Observable<HttpResponse<Module[]>> {
    return this.http.get<Module[]>(this.resourceUrl, {params: queryParams ,  observe: 'response'}).pipe(
      tap(_ => this.log(`get modulelist`)),
      catchError(this.handleError<any>('get modulelist'))
    );
  }


  /** DELETE: delete the module from the server */
  delete (module: Module | string | Module[] | string[]): Observable<Module> {
    let id;
    if(module instanceof Array){
      id = (typeof module[0] === 'string') ? module.join(",") : (<Module[]>module).map(i => i.id).join(",");
    }else{
      id = typeof module === 'string' ? module : module.id;
    }
    const url = `${this.resourceUrl}/${id}`;

    return this.http.delete<Module>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted module id=${id}`)),
      catchError(this.handleError<Module>('deleteModule'))
    );
  }


  hasModuleCode(moduleCode : string): Observable<any>{
    const url = `${this.resourceUrl}/hasModuleCode/${moduleCode}`;
    return this.http.get(url).pipe(
//      tap(_ => this.log(`hasModuleName name=${modulename}`)),
      catchError(this.handleError<Module>('hasModuleCode'))
    );
  }



  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ModuleService: ' + message);
  }
}
