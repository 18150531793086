import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as echarts from 'echarts';
import Tools from './tool';
import { zip } from 'rxjs';
import * as moment from 'moment';
import { UserService } from "app/routes/admin/system/users/users.service";
import { ProjectCockpitService } from "./projectCockpit.service";

@Component({
  selector: 'app-project-cockpit',
  templateUrl: './projectCockpit.component.html',
  styleUrls: ['./projectCockpit.component.less'],
})
export class ProjectCockpitComponent implements OnInit {
  validateForm: FormGroup;
  @ViewChild('chart1') chart1: ElementRef;
  @ViewChild('chart2') chart2: ElementRef;
  @ViewChild('chart3') chart3: ElementRef;
  @ViewChild('chart4') chart4: ElementRef;
  @ViewChild('chart5') chart5: ElementRef;
  @ViewChild('chart6') chart6: ElementRef;
  @ViewChild('chart7') chart7: ElementRef;
  @ViewChild('chart8') chart8: ElementRef;
  @ViewChild('chart9') chart9: ElementRef;

  radioValue = 'C';
  regionList: any;//大区
  provinceList: any;//省份（根据大区选择进行筛选）
  provinceResultList: any;//省份（所有数据）

  yearGoalsTotalInfo = {
    totalDelareQuantity: null,
    planInvestAmount: null,
    payAmount: null,
    planInvestSoftAmount: null,
    softwareAmount: null,
    closingPassCount: null,
    orderNicheCount: null,
    orderAmount: null,
    sjConvertRate: null,

  };//申报指南数据
  projectTotalInfo = {
    establishCount : null, //立项数量
    notClosingCount : null, //未结项数量
    planInvestAmount: null,
    payAmount: null,
    planInvestSoftAmount: null,
    softwareAmount: null,
    closingPassCount: null,
    orderNicheCount: null,
    orderAmount: null,
    sjConvertRate: null,
  };//项目统计信息
  batchList = [];//批次所有数据
  batchData = [];//批次数据
  
  regionAllList = [];//区域所有数据
  regionData = [];//区域数据

  regionPrjStatus = "待结项";
  regionPrjCountDataArray = [];//区域项目数量数据
    
  fastYear = 5;//默认为近5年数据
  exportingPrjDetail = false;
  exportingPrjTotal = false;


  constructor(private fb: FormBuilder,
    private projectCockpitService: ProjectCockpitService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      fastYear:[this.fastYear.toString()],
      startYear: [moment().subtract(this.fastYear,'year').format(" YYYY-MM-DD ")],
      endYear: [moment().format(" YYYY-MM-DD ")],
      region: [null],
      province: [null],
    });

    this.initSearchForm();
  }

  initSearchForm() {

    this.getRegionProvinceInfo();
    //加载汇总统计数据
    this.getProjectInfoTotal();
    //加载项目类别项目统计数据
    this.getProjectInfoTotalProjectType();
    //加载批次项目数据
    this.getProjectBatchData();
    //加载大区数据
    this.getProjectRegionData();
  }

  /**
   * 获取大区、省份数据
   */
  getRegionProvinceInfo(){
    // 加载区域与省份数据
    const dataParams = {
      'size' : '10000',
      'sort': 'orderNum,asc'
    };
    zip(
      this.userService.queryRegionList(dataParams),
      this.userService.queryProvinceList(dataParams)
    ).subscribe(([regionRes,provinceRes]) => {
      let regionDataList = regionRes.body;
      let provinceDataList = provinceRes.body;

      this.regionList = regionDataList;
      this.provinceResultList = provinceDataList;
    })

  }

  getQueryParams(isReset = false){
    if(isReset){
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[ i ].setValue(null);
      }
    }
    
    const startYear = this.validateForm.get('startYear').value;
    const endYear = this.validateForm.get('endYear').value;
    const region = this.validateForm.get('region').value;
    const province = this.validateForm.get('province').value;

    const queryParams = {
      startYear : startYear != null ? moment(startYear).year() : "",
      endYear : endYear != null ? moment(endYear).year() : "",
      region : region == null ? "" : region,
      province : province == null ? "" : province.join(',')
    };

    return queryParams;
  }

  /**
   * 获取申报指南汇总数据和其他汇总数据
   */
  getProjectInfoTotal(){
    
    // const startYear = this.validateForm.get('startYear').value;
    // const endYear = this.validateForm.get('endYear').value;
    // // const region = this.validateForm.get('region').value;
    // // const province = this.validateForm.get('province').value;

    // const queryParams = {
    //   startYear : startYear != null ? moment(startYear).year() : "",
    //   endYear : endYear != null ? moment(endYear).year() : "",
    //   // region : region == null ? "" : region,
    //   // province : province == null ? "" : province.join(',')
    // };

    const queryParams = this.getQueryParams();

    this.projectCockpitService.getYearGoalsTotal(queryParams).subscribe(res => {
      this.yearGoalsTotalInfo = res.body;
    });
    
    this.projectCockpitService.getProjectInfoTotal(queryParams).subscribe(res => {
      debugger;
      this.projectTotalInfo = res.body;
    });

    // zip(
    //   this.projectCockpitService.getYearGoalsTotal(queryParams),
    //   this.projectCockpitService.getProjectInfoTotal(queryParams)
    // ).subscribe(([goalsRes, prjTotalRes]) => {
    //   this.yearGoalsTotalInfo = goalsRes.body;
    //   let projectTotalInfoResult = prjTotalRes.body;
      
    //   let totalDelareQuantity = this.yearGoalsTotalInfo['totalDelareQuantity'];//年度申报数量
    //   totalDelareQuantity = parseInt(totalDelareQuantity);
    //   if(isNaN(totalDelareQuantity)){
    //     totalDelareQuantity = 0;
    //   }
      
    //   let establishNotPass = projectTotalInfoResult['establishNotPassCount'];//未立项数量
    //   establishNotPass = parseInt(establishNotPass);
    //   if(isNaN(establishNotPass)){
    //     establishNotPass = 0;
    //   }

    //   const establishCount = totalDelareQuantity - establishNotPass;//立项数量（年度申报数量-未立项数量）
    //   projectTotalInfoResult['establishCount'] = establishCount;////立项数量（年度申报数量-未立项数量）

    //   let closingPassCount = parseInt(projectTotalInfoResult['closingPassCount']);//结项数量
    //   if(isNaN(closingPassCount)){
    //     closingPassCount = 0;
    //   }
    //   projectTotalInfoResult['notClosingCount'] = establishCount - closingPassCount;//未结项数量（立项数量-结项数量）

    //   //商机转化率(成单院校数量/立项数量)
    //   let orderCount = parseInt(projectTotalInfoResult['orderCount']);//成单院校数量
    //   if(isNaN(orderCount)){
    //     orderCount = 0;
    //   }
    //   let sjConvert = null;//商机转化率
    //   if(orderCount > 0 && establishCount != 0){
    //     sjConvert = ((orderCount / establishCount)*100).toFixed(2);
    //   }
    //   if(isNaN(sjConvert)){
    //     sjConvert = null;
    //   }

    //   projectTotalInfoResult['sjConvert'] = sjConvert;
    //   this.projectTotalInfo = projectTotalInfoResult;
    // });

  }
  /**
   * 获取项目类别统计数据
   */
  getProjectInfoTotalProjectType(){
    const queryParams = this.getQueryParams();

    this.projectCockpitService.getProjectInfoTotalByProjectType(queryParams).subscribe(res=>{
      let result = res.body;
      let datas = [];
      result.forEach(item=>{
        datas.push({
          name : item.projectType,
          value : item.establishCount
        });
      })
      this.initPeiChart(this.chart6.nativeElement,datas);
    });
  }

  getProjectBatchData(){
    const queryParams = this.getQueryParams();


    this.projectCockpitService.getProjectInfoTotalByBatch(queryParams).subscribe(res=>{
      this.batchList = res.body;
      this.batchData = this.batchList.map(w=>w.batch);
      this.getProjectBatchQuantityData();
      this.getProjectBatchAmountData();
      this.getProjectBatchSoftAmountData();
      this.getProjectBatchOrderData();
    });
  }

  onChangeRegionPrjStatus(event){
    this.regionPrjStatus = event;
    this.getProjectRegionData();
  }
  
  getProjectRegionData(){
    const queryParams = this.getQueryParams();


    this.projectCockpitService.getProjectInfoTotalByRegion(queryParams).subscribe(res=>{
      this.regionAllList = res.body;
      this.regionData = this.regionAllList.map(w=>w.region);
      this.getProjectRegionQuantityData();
      this.getProjectRegionAmountData();
      this.getProjectRegionOrderAmountData();
      this.getProjectRegionOrderCountData();
    });
  }

  /**
   * 待结项数量、结项数量、立项数量 图表
   */
  getProjectBatchQuantityData(){
    let closingNotCountList = [];//待结项数量
    let closingCountList = [];//结项数量
    let establishCountList = [];//立项数量
    this.batchList.forEach(item => {
      closingNotCountList.push(item.closingNotCount);
      closingCountList.push(item.closingPassCount);
      establishCountList.push(item.establishCount);
    });

    //获取chart1、chart2、chart3数据
      //加在项目个数
      this.initBarChart(this.chart1.nativeElement, [
        'rgba(255, 138, 103, 1)', 'rgba(84, 205, 213, 1)', 'rgba(56, 148, 233, 1)'
      ], ['未结项数量','结项数量','立项数量'], this.batchData, {
        data1: closingNotCountList,
      data2: closingCountList,
      data3: establishCountList
    });
  }

  /**
   * 项目资金（排除软硬件价值） 图表
   */
  getProjectBatchAmountData(){
    let payAmountNotCountList = [];//待付资金
    let payAmountList = [];//已付资金
    let establishAmountList = [];//预计投入资金
    this.batchList.forEach(item => {
      let payAmountNot = parseFloat(item.payAmountNot);
      let payAmount = parseFloat(item.payAmount);
      let planInvestAmount = parseFloat(item.planInvestAmount);
      if(isNaN(payAmountNot)){
        payAmountNot = 0;
      }else{
        payAmountNot = (payAmountNot / 10000);
      }

      if(isNaN(payAmount)){
        payAmount = 0;
      }else{
        payAmount = (payAmount / 10000);
      }

      if(isNaN(planInvestAmount)){
        planInvestAmount = 0;
      }else{
        planInvestAmount = (planInvestAmount / 10000);
      }

      payAmountNotCountList.push(Math.round(payAmountNot));
      payAmountList.push(Math.round(payAmount));
      establishAmountList.push(Math.round(planInvestAmount));
    });

    //获取chart1、chart2、chart3数据
    this.initBarChart(this.chart2.nativeElement, [
      'rgba(74, 207, 133, 1)', 'rgba(255, 178, 103, 1)', 'rgba(66, 203, 253, 1)'
      ], ['已付资金','待付资金','预计投入资金'],this.batchData, {
      data1: payAmountList,
      data2: payAmountNotCountList,
      data3: establishAmountList
      }, Tools.chart2, 2);
  }

  
  /**
   * 项目软硬件价值 图表
   */
  getProjectBatchSoftAmountData(){
    let payAmountNotCountList = [];//待付软硬件价值
    let payAmountList = [];//已付软硬件价值
    let establishAmountList = [];//预计投入软硬件价值
    this.batchList.forEach(item => {
      let softwareAmountNot = parseFloat(item.softwareAmountNot);
      let softwareAmount = parseFloat(item.softwareAmount);
      let planInvestSoftAmount = parseFloat(item.planInvestSoftAmount);
      if(isNaN(softwareAmountNot)){
        softwareAmountNot = 0;
      }else{
        softwareAmountNot = (softwareAmountNot / 10000);
      }

      if(isNaN(softwareAmount)){
        softwareAmount = 0;
      }else{
        softwareAmount = (softwareAmount / 10000);
      }

      if(isNaN(planInvestSoftAmount)){
        planInvestSoftAmount = 0;
      }else{
        planInvestSoftAmount = (planInvestSoftAmount / 10000);
      }

      payAmountNotCountList.push(Math.round(softwareAmountNot));
      payAmountList.push(Math.round(softwareAmount));
      establishAmountList.push(Math.round(planInvestSoftAmount));
    });

    this.initBarChart(this.chart3.nativeElement, [
      'rgba(148, 192, 105, 1)', 'rgba(246, 127, 152, 1)', 'rgba(100, 124, 249, 1)'
      ], ['已供软硬件价值','待供软硬件价值','预计投入软硬件价值'], this.batchData, {
      data1: payAmountList,
      data2: payAmountNotCountList,
      data3: establishAmountList
    }, Tools.chart3, 2);

  }

  /**
   * 按批次显示项目软硬件价值 图表
   */
  getProjectBatchOrderData(){
    let orderNicheCountList = [];//已成单商机数量
    let sjConvertRateList = [];//商机转化率
    let orderAmountList = [];//成单金额
    this.batchList.forEach(item => {
      orderNicheCountList.push(item.orderNicheCount);
      sjConvertRateList.push(!item.sjConvertRate ? 0 : item.sjConvertRate);
      let orderAmount = parseFloat(item.orderAmount);
      if(isNaN(orderAmount)){
        orderAmount = 0;
      }else{
        orderAmount = orderAmount / 10000;
      }
      orderAmountList.push(orderAmount.toFixed(2));
    });

    
    this.initLineChart2(this.chart9.nativeElement,this.batchData,{
      data1: orderNicheCountList,
      data2: sjConvertRateList,
      data3: orderAmountList
    })
  } 

  /**
   * 按大区：总计待结项/立项/结项数量
   */
  getProjectRegionQuantityData(){
    let closingNotCountList = [];//待结项数量
    let closingCountList = [];//结项数量
    let establishCountList = [];//立项数量
    let initRadars = [];
    let datas = [];
    let regionPrjCountDatas = {};
    this.regionPrjCountDataArray = [];
    let totalCount = 0;
    this.regionAllList.forEach(item => {
      let closingNotCount = parseInt(item.closingNotCount);
      let closingPassCount = parseInt(item.closingPassCount);
      let establishCount = parseInt(item.establishCount);
      if(isNaN(closingNotCount)){
        closingNotCount = 0;
      }
      if(isNaN(closingPassCount)){
        closingPassCount = 0;
      }
      if(isNaN(establishCount)){
        establishCount = 0;
      }
      closingNotCountList.push(closingNotCount);
      closingCountList.push(closingPassCount);
      establishCountList.push(establishCount);
      let count = 0;
      if(this.regionPrjStatus === '立项'){
        initRadars.push({ name : item.region, max : (establishCount + 2000) });
        datas = establishCountList;
        count = establishCount;
      }else if(this.regionPrjStatus === '结项'){
        initRadars.push({ name : item.region, max : (closingPassCount + 2000) });
        datas = closingCountList;
        count = closingPassCount;
      }else{
        initRadars.push({ name : item.region, max : (closingNotCount + 2000) });
        datas = closingNotCountList;
        count = closingNotCount;
      }
      
      totalCount += count;
      //右侧显示大区数据
      let obj = regionPrjCountDatas[item.region];
      if(!obj){
        obj = {
          region : item.region,
          count : count,
          rate : 0
        }
      }
      regionPrjCountDatas[item.region] = obj;
    });

    for(const key in regionPrjCountDatas){
      let item = regionPrjCountDatas[key];
      item.rate = totalCount != 0 ? ((item.count / totalCount)*100).toFixed(2) : 0;
      regionPrjCountDatas[key] = item;
      this.regionPrjCountDataArray.push(item);
    }
    //获取chart5数据
    this.initRadarChart(this.chart5.nativeElement,initRadars,datas,this.regionPrjStatus);
  }
  /**
   * 大区 资金数据（预计投入资金、已付资金、待付资金, 预计投入软硬件价值、已付软硬件价值、待付软硬件价值）
   */
  getProjectRegionAmountData(){
    let planInvestAmountList = [];//预计投入
    let payAmountList = [];//已付资金
    let payAmountNotList = [];//未付资金

    let planInvestSoftAmountList = [];//预计投入软硬件价值
    let softwareAmountList = [];//已供软硬件价值
    let softwareAmountNotList = [];//未供软硬件价值

    let planInvestAmount = 0;
    let payAmount = 0;
    let payAmountNot = 0;
    this.regionAllList.forEach(item=>{
      planInvestAmount = item.planInvestAmount;
      if(isNaN(planInvestAmount)){
        planInvestAmount = 0;
      }

      payAmount = item.payAmount;
      if(isNaN(payAmount)){
        payAmount = 0;
      }
      payAmountNot = item.payAmountNot;
      if(isNaN(payAmountNot)){
        payAmountNot = 0;
      }
      planInvestAmountList.push(Math.round(planInvestAmount / 10000));
      payAmountList.push(Math.round(payAmount / 10000));
      payAmountNotList.push(Math.round(payAmountNot / 10000));

      planInvestAmount = item.planInvestSoftAmount;
      if(isNaN(planInvestAmount)){
        planInvestAmount = 0;
      }

      payAmount = item.softwareAmount;
      if(isNaN(payAmount)){
        payAmount = 0;
      }
      payAmountNot = item.softwareAmountNot;
      if(isNaN(payAmountNot)){
        payAmountNot = 0;
      }
      planInvestSoftAmountList.push(Math.round(planInvestAmount / 10000));
      softwareAmountList.push(Math.round(payAmount / 10000));
      softwareAmountNotList.push(Math.round(payAmountNot / 10000));
    });
  
    this.initComBarchart(this.chart4.nativeElement,this.regionData,{
      data1 : planInvestAmountList,
      data2 : payAmountList,
      data3 : payAmountNotList,
      data4 : planInvestSoftAmountList,
      data5 : softwareAmountNotList,
      data6 : softwareAmountList
    });
  }
  /**
   * 大区成单金额、转化率数据
   */
  getProjectRegionOrderAmountData(){
    let dataOrderAmountList = [];
    let dataOrderRateList = [];
    this.regionAllList.forEach(item => {
      let orderAmount = isNaN(item.orderAmount) ? 0 : parseFloat(item.orderAmount);
      if(isNaN(orderAmount)){
        orderAmount = 0;
      }
      dataOrderAmountList.push((orderAmount / 10000).toFixed(2));

      let sjConvertRate = isNaN(item.sjConvertRate) ? 0 : parseFloat(item.sjConvertRate);
      if(isNaN(sjConvertRate)){
        sjConvertRate = 0;
      }
      dataOrderRateList.push(sjConvertRate)
    });

    this.initLineChart(this.chart8.nativeElement,this.regionData,{
      data1 : dataOrderAmountList,
      data2 : dataOrderRateList
    });
  }

  /**
   * 大区成单数量
   */
  getProjectRegionOrderCountData(){
    let data = [];
    this.regionAllList.forEach(item => {
      data.push({ name : item.region, value : isNaN(item.orderCount) ? 0 : item.orderCount });
    });

    this.initPeiChart2(this.chart7.nativeElement,data);
  }

  /**
   * 大区改变事件
   */
  fnChangeRegion(itemSelect){
    
    this.provinceList = [];
    this.validateForm.get('province').setValue([]);

    if(this.provinceResultList && itemSelect){
      let items = typeof itemSelect == 'object' ? itemSelect : [itemSelect];
      let regionIds = [];//将现在用的值和原来用过的值拼接一起查询
      items.forEach(item => {
        let regionQueryList = this.regionList.filter(w=>w.id == item);
        let region = regionQueryList[0];
        let queryProvinces = this.provinceResultList.filter(w=>w.fkregionId == region.id);
        queryProvinces.forEach(element => {
          this.provinceList.push(element);
        });
      });
    }
  }
  getDataListByFast(event){
    let value = parseInt(event);
    if(isNaN(value)){
      value = null;
    }
    if(!value){
      return;
    }

    this.validateForm.get('startYear').setValue(moment().subtract(value,'year').format(" YYYY-MM-DD "));
    this.validateForm.get('endYear').setValue(moment().format(" YYYY-MM-DD "));

    this.search(false);
  }

  search(isReset:boolean){
    if(isReset){
      for (const i in this.validateForm.controls) {
        if(i === 'startYear' || i === 'endYear'){
          continue;
        }
        if(i === 'fastYear'){
          this.validateForm.controls[ i ].setValue(this.fastYear.toString());
        }else{
          this.validateForm.controls[ i ].setValue(null);
        }
      }

      return;
    }
    
    this.initSearchForm();
  }

  exportProjectDetailExcel(){
    const this_ = this;
    let queryParams = this.getQueryParams();
    queryParams['fileName'] = "数据公示项目明细表";
    this.exportingPrjDetail = true;
    this.projectCockpitService.exportProjectDetailExcel(queryParams,this_);
  }

  exportProjectTotalExcel(){
    const this_ = this;
    let queryParams = this.getQueryParams();
    queryParams['fileName'] = "数据公示项目汇总表";
    this.exportingPrjTotal = true;
    this.projectCockpitService.exportProjectTotalExcel(queryParams,this_);
  }

  ngAfterViewInit() {
    this.initBarChart(this.chart1.nativeElement, [
      'rgba(255, 138, 103, 1)', 'rgba(84, 205, 213, 1)', 'rgba(56, 148, 233, 1)'
    ], ['未结项数量','结项数量','立项数量'], ['2022年','2022年','2022年','2022年','2022年','2022年','2022年'], {
      data1: [320, 302, 301, 0, 0, 330, 320],
    data2: [30, 132, 101, 134, 90, 230, 210],
    data3: [30, 182, 191, 234, 290, 330, 310]
  });
    this.initBarChart(this.chart2.nativeElement, [
      'rgba(74, 207, 133, 1)', 'rgba(255, 178, 103, 1)', 'rgba(66, 203, 253, 1)'
    ], ['已付资金','待付资金','预计投入资金'], ['2022年','2022年','2022年','2022年','2022年','2022年','2022年'], {
      data1: [320, 302, 301, 334, 390, 330, 320],
    data2: [30, 132, 101, 134, 90, 230, 210],
    data3: [30, 182, 191, 234, 290, 330, 310]
  }, Tools.chart2, 2);
    this.initBarChart(this.chart3.nativeElement, [
      'rgba(148, 192, 105, 1)', 'rgba(246, 127, 152, 1)', 'rgba(100, 124, 249, 1)'
    ], ['已供软硬件价值','待供软硬件价值','预计投入软硬件价值'], ['2022年','2022年','2022年','2022年','2022年','2022年','2022年'], {
      data1: [320, 302, 301, 334, 390, 330, 320],
    data2: [30, 132, 101, 134, 90, 230, 210],
    data3: [30, 182, 191, 234, 290, 330, 310]
  }, Tools.chart3, 2);
    this.initComBarchart(this.chart4.nativeElement,['周一', '周二', '周三', '周四', '周五', '周六', '周日'],{
      data1 : [320, 302, 301, 334, 390, 330, 320],
      data2 : [30, 132, 101, 134, 90, 230, 210],
      data3 : [30, 132, 101, 134, 90, 230, 210],
      data4 : [320, 302, 301, 334, 390, 330, 320],
      data5 : [30, 132, 101, 134, 90, 230, 210],
      data6 : [30, 132, 101, 134, 90, 230, 210]
    });
    this.initRadarChart(this.chart5.nativeElement,[
        { name: '销售（sales）', max: 6500 },
        { name: '管理（Administration）', max: 16000 },
        { name: '信息技术（Information Techology）', max: 30000 },
        { name: '客服（Customer Support）', max: 38000 },
        { name: '研发（Development）', max: 52000 },
        { name: '市场（Marketing）', max: 25000 } ]
    ,[4300, 10000, 28000, 35000, 50000, 19000],this.regionPrjStatus);
    this.initPeiChart(this.chart6.nativeElement,[
      { value: 335, name: '新工科、新医科、新农科、新文科建设项目' },
      { value: 310, name: '教学内容和课程体系改革项目' },
      { value: 234, name: '创新创业教育改革' },
      { value: 135, name: '实践条件和实践基地建设' },
      { value: 1548, name: '其他' }
    ]);
    this.initPeiChart2(this.chart7.nativeElement,[
      { value: 335, name: '广东区' },
      { value: 310, name: '华东区' },
      { value: 234, name: '东北区' },
      { value: 135, name: '华北区' },
      { value: 1548, name: '中南区' },
      { value: 1548, name: '西北区' },
      { value: 1548, name: '西南区' }
    ]);
    this.initLineChart(this.chart8.nativeElement,['周一', '周二', '周三', '周四', '周五', '周六', '周日'],{
      data1 : [320, 302, 301, 334, 390, 330, 320],
      data2 : [30, 132, 101, 134, 90, 230, 210]
    });

    this.initLineChart2(this.chart9.nativeElement,['2022年','2022年','2022年','2022年','2022年','2022年','2022年'],{
      data1 : [320, 302, 301, 334, 390, 330, 320],
      data2 : [30, 132, 101, 134, 90, 230, 210],
      data3 : [30, 182, 191, 234, 290, 330, 310]
    });
  }

  rebuild(data){
    let max = data[0];
    data.forEach(item=>{max=item>max?item:max});
    return data.map(item=>{
      return item?item:{
        value: item,
        label: {show: false}
      }
    })
  }

  bindResize(chart) {
    window.addEventListener('resize', () => {
      chart.resize();
    });
  }

  initBarChart(chartIns, color = [
    'rgba(74, 207, 133, 1)',
    'rgba(255, 178, 103, 1)',
    'rgba(66, 203, 253, 1)',
  ], names, axisData, datas, icons = Tools.chart1, type = 1) {
    let chart = echarts.init(chartIns);
    const {data1, data2, data3} = datas;

    let picData1 = data2.map((item, index) => item + data1[index]);
    let picData2 = data3.map((item, index) => item + picData1[index]);

    let option: any = {
      color,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',
          z: -1
        },
        formatter: function (value: any) {
          let str = value[0].name + '<br/>';
          value.forEach(item => {
            if (item.componentSubType !== 'pictorialBar') {
              const { marker, seriesName, value } = item;
              str += `${marker} ${seriesName}：${value}<br/>`;
            }
          });
          return str;
        },
      },
      xAxis: {
        type: type !== 1 ? 'value' : 'category',
        axisLabel: { color: 'rgba(153, 153, 153, 1)' },
        axisLine: {
          show: type == 1,
          lineStyle: { color: 'rgba(204, 204, 204, 1)' },
        },
        axisTick: { show: false },
        splitLine: { show: type !== 1 },
      },
      yAxis: {
        type: type !== 1 ? 'category' : 'value',
        axisLabel: { color: 'rgba(153, 153, 153, 1)' },
        axisLine: { lineStyle: { color: 'rgba(204, 204, 204, 1)' } },
        axisTick: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          name: names[0],
          type: 'bar',
          stack: '总量',
          barWidth: type !== 1 ? 16 : 24,
          label: { show: true, position: 'inside' },
          data: this.rebuild(data1),
        },
        {
          name: names[1],
          type: 'bar',
          stack: '总量',
          label: { show: true, position: 'inside' },
          data: this.rebuild(data2),
        },
        {
          name: names[2],
          type: 'bar',
          stack: '总量',
          label: { show: true, position: 'inside' },
          data: this.rebuild(data3),
        },
        {
          type: 'pictorialBar',
          symbol: 'image://' + icons[0],
          symbolSize: type !== 1 ? [8, 16] : [24, 12],
          symbolPosition: 'end',
          symbolOffset: type !== 1 ? [8, 0] : [0, -11],
          data: data1,
          z: 4,
        },
        {
          type: 'pictorialBar',
          symbol: 'image://' + icons[1],
          symbolSize: type !== 1 ? [8, 16] : [24, 12],
          symbolPosition: 'end',
          symbolOffset: type !== 1 ? [8, 0] : [0, -11],
          data: picData1,
          z: 3,
        },
        {
          type: 'pictorialBar',
          symbol: 'image://' + icons[2],
          symbolSize: type !== 1 ? [8, 16] : [24, 12],
          symbolPosition: 'end',
          symbolOffset: type !== 1 ? [8, 0] : [0, -11],
          data: picData2,
          z: 2,
        },
      ],
    };
    if (type == 1) {
      option.xAxis.data = axisData;
      option.legend = { top: 6, right: 10, itemWidth: 14 };
      option.grid = { left: 10, right: 10, bottom: 10, top: 50, containLabel: true };
    } else {
      option.yAxis.data = axisData;
      option.legend = { top: 25, right: 20, itemWidth: 14 };
      option.grid = { left: 10, right: 20, bottom: 10, top: 60, containLabel: true };
    }
    chart.setOption(option);
    this.bindResize(chart);
  }

  initComBarchart(chartIns,axisData,datas, color = [
    'rgba(74, 207, 133, 1)',
    'rgba(255, 178, 103, 1)',
    'rgba(66, 203, 253, 1)',
    'rgba(148, 192, 105, 1)',
    'rgba(246, 127, 152, 1)',
    'rgba(100, 124, 249, 1)',
  ], icons = Tools.chart4) {
    let chart = echarts.init(chartIns);
    
    // let data1 = [320, 302, 301, 334, 390, 330, 320];
    // let data2 = [30, 132, 101, 134, 90, 230, 210];
    // let data3 = [30, 182, 191, 234, 290, 330, 310];
    const { data1,data2,data3,data4,data5,data6 } = datas;

    let picData1 = data2.map((item, index) => item + data1[index]);
    let picData2 = data3.map((item, index) => item + picData1[index]);

    let option: any = {
      color,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',
          z: -1
        },
        formatter: function (value: any) {
          let str = value[0].name + '<br/>';
          value.forEach(item => {
            if (item.componentSubType !== 'pictorialBar') {
              const { marker, seriesName, value } = item;
              str += `${marker} ${seriesName}：${value}<br/>`;
            }
          });
          return str;
        },
      },
      legend: { top: 5, right: 10, itemWidth: 14, width: '70%' },
      grid: [
        { left: 10, right: 10, bottom: '40%', top: 50, containLabel: true },
        { left: 10, right: 10, bottom: 10, top: '60%', containLabel: true }
      ],
      xAxis: [
        {
          gridIndex: 0,
          type: 'category',
          axisLabel: { color: 'rgba(153, 153, 153, 1)' },
          axisLine: {
            show: true,
            lineStyle: { color: 'rgba(204, 204, 204, 1)' },
          },
          axisTick: { show: false },
          splitLine: { show: false },
          data: axisData
        }, {
          gridIndex: 1,
          position: 'top',
          type: 'category',
          axisLabel: { show: false, color: 'rgba(153, 153, 153, 1)' },
          axisLine: {
            show: true,
            lineStyle: { color: 'rgba(204, 204, 204, 1)' },
          },
          axisTick: { show: false },
          splitLine: { show: false },
          data: axisData
        }
      ],
      yAxis: [
        {
          gridIndex: 0,
          type: 'value',
          axisLabel: { color: 'rgba(153, 153, 153, 1)' },
          axisLine: { lineStyle: { color: 'rgba(204, 204, 204, 1)' } },
          axisTick: { show: false },
          splitLine: { show: false },
        },
        {
          gridIndex: 1,
          inverse: true,
          type: 'value',
          axisLabel: { color: 'rgba(153, 153, 153, 1)' },
          axisLine: { lineStyle: { color: 'rgba(204, 204, 204, 1)' } },
          axisTick: { show: false },
          splitLine: { show: false },
        }
      ],
      series: [
        {
          name: '已付资金',
          type: 'bar',
          stack: '总量',
          barWidth: 24,
          label: { show: true, position: 'inside' },
          data: this.rebuild(data1),
        },
        {
          name: '待付资金',
          type: 'bar',
          stack: '总量',
          label: { show: true, position: 'inside' },
          data: this.rebuild(data2),
        },
        {
          name: '预计投入资金',
          type: 'bar',
          stack: '总量',
          label: { show: true, position: 'inside' },
          data: this.rebuild(data3),
        },
        {
          xAxisIndex: 1, yAxisIndex: 1,
          name: '已供软硬件价值',
          type: 'bar',
          stack: '总量2',
          barWidth: 24,
          label: { show: true, position: 'inside' },
          data: this.rebuild(data4),
        },
        {
          xAxisIndex: 1, yAxisIndex: 1,
          name: '待供软硬件价值',
          type: 'bar',
          stack: '总量2',
          label: { show: true, position: 'inside' },
          data: this.rebuild(data5),
        },
        {
          xAxisIndex: 1, yAxisIndex: 1,
          name: '预计投入软硬件价值',
          type: 'bar',
          stack: '总量2',
          label: { show: true, position: 'inside' },
          data: this.rebuild(data6),
        },
        {
          type: 'pictorialBar',
          symbol: 'image://' + icons[0],
          symbolSize: [24, 12],
          symbolPosition: 'end',
          symbolOffset: [0, -11],
          data: data1,
          z: 4,
        },
        {
          type: 'pictorialBar',
          symbol: 'image://' + icons[1],
          symbolSize: [24, 12],
          symbolPosition: 'end',
          symbolOffset: [0, -11],
          data: picData1,
          z: 3,
        },
        {
          type: 'pictorialBar',
          symbol: 'image://' + icons[2],
          symbolSize: [24, 12],
          symbolPosition: 'end',
          symbolOffset: [0, -11],
          data: picData2,
          z: 2,
        },
        {
          xAxisIndex: 1, yAxisIndex: 1,
          type: 'pictorialBar',
          symbol: 'image://' + icons[3],
          symbolSize: [24, 12],
          symbolPosition: 'end',
          symbolOffset: [0, 11],
          data: data1,
          z: 4,
        },
        {
          xAxisIndex: 1, yAxisIndex: 1,
          type: 'pictorialBar',
          symbol: 'image://' + icons[4],
          symbolSize: [24, 12],
          symbolPosition: 'end',
          symbolOffset: [0, 11],
          data: picData1,
          z: 3,
        },
        {
          xAxisIndex: 1, yAxisIndex: 1,
          type: 'pictorialBar',
          symbol: 'image://' + icons[5],
          symbolSize: [24, 12],
          symbolPosition: 'end',
          symbolOffset: [0, 11],
          data: picData2,
          z: 2,
        },
      ],
    };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initRadarChart(chartIns,indicatorDatas,datas,type) {
    let chart = echarts.init(chartIns);
    let option = {
      color: ['rgba(24, 144, 255, 1)'],
      tooltip: {},
      radar: {
        // shape: 'circle',
        name: {
          textStyle: {
            color: '#666',
          }
        },
        indicator: indicatorDatas
      },
      series: [{
        name: '总计'+type+'数量',
        type: 'radar',
        symbol: '',
        symbolSize: 10,
        emphasis: {
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3
          }
        },
        areaStyle: {
          color: 'rgba(24, 144, 255, 1)',
          opacity: .3
        },
        data: [
          {
            value: datas,
            name: '总计'+type+'数量：'
          }
        ]
      }]
    };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initPeiChart(chartIns,data) {
    let chart = echarts.init(chartIns);
    
    let option = {
      color: ['rgba(91, 172, 255, 1)', 'rgba(33, 201, 163, 1)', 'rgba(255, 203, 91, 1)', 'rgba(255, 153, 91, 1)', 'rgba(147, 136, 234, 1)'],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        right: 0,
        top: 'center',
        itemWidth: 14,
        width: '50%',
        formatter: value => {
          return `{a|${value.split('').length > 15 ? value.substr(0, 15) + '\n' + value.substr(15) : value}} {b|${data.filter(item => item.name == value)[0].value}}`
        },
        textStyle: {
          rich: {
            a: {
              color: 'rgba(75, 103, 129, 1)',
              fontSize: 14,
              lineHeight: 20
            },
            b: {
              color: 'rgba(51, 51, 51, 1)',
              fontSize: 14,
              lineHeight: 20,
              fontWeight: 600
            }
          }
        }
      },
      series: [
        {
          name: '项目类别立项统计',
          type: 'pie',
          radius: ['90px', '110px'],
          center: ['23%', '47%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3,
          },
          data
        }
      ]
    };
    chart.setOption(option);
    this.bindResize(chart);

  }

  initPeiChart2(chartIns,data) {
    let chart = echarts.init(chartIns);
    let option = {
      color: ['rgba(147, 136, 234, 1)', 'rgba(91, 172, 255, 1)', 'rgba(88, 105, 240, 1)', 'rgba(33, 201, 163, 1)', 'rgba(255, 203, 91, 1)','rgba(255, 153, 91, 1)', 'rgba(236, 144, 231, 1)'],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      series: [
        {
          name: '大区成单数量',
          type: 'pie',
          radius: ['90px', '110px'],
          avoidLabelOverlap: false,
          minShowLabelAngle: 18,
          label: {
            show: true,
            formatter: e => {
              const { name, value, percent } = e
              return `{a|${name}}：{b|${value}}\n{c| ${percent}%}`
            },
            textStyle: {
              rich: {
                a: {
                  color: 'rgba(75, 103, 129, 1)',
                  fontSize: 14,
                  lineHeight: 24
                },
                b: {
                  color: 'rgba(51, 51, 51, 1)',
                  fontSize: 14,
                  lineHeight: 24,
                  fontWeight: 'bold'
                },
                c: {
                  fontSize: 14,
                  color: 'rgba(255, 139, 92, 1)',
                  backgroundColor: 'rgba(255, 233, 224, 1)',
                  padding: [2, 5]
                }
              }
            }
          },
          labelLine: {
            show: true,
            lineStyle: {
              color: 'rgba(177, 201, 223, 1)'
            }
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3,
          },
          data
        }
      ]
    };

    chart.setOption(option);
    this.bindResize(chart);

  }

  initLineChart(chartIns,xAxisDatas,datas) {
    let chart = echarts.init(chartIns);
    // let data1 = [320, 302, 301, 334, 390, 330, 320];
    // let data2 = [30, 132, 101, 134, 90, 230, 210];
    // let data3 = [30, 182, 191, 234, 290, 330, 310];
    const {data1, data2, data3} = datas;

    let option = {
      color: ['rgba(74, 207, 133, 1)', 'rgba(255, 178, 103, 1)', 'rgba(66, 203, 253, 1)'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',        // 默认为直线，可选为：'line' | 'shadow'
          z: -1
        },
      },
      legend: {
        top: 1,
        right: 0,
        itemWidth: 14
      },
      grid: {
        left: 30,
        right: 30,
        bottom: 10,
        top: 65,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          color: 'rgba(153, 153, 153, 1)'

        },
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(204, 204, 204, 1)'
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        data: xAxisDatas
      },
      yAxis: [
        {
          type: 'value',
          name: '成单金额（万元）',
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true
          },
        },
        {
          type: 'value',
          name: '转化率（%）',
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)',


            formatter: '{value}%'
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
        }
      ],
      series: [
        {
          name: '成单金额',
          type: 'bar',
          barWidth: 16,
          itemStyle: {
            color: 'rgba(143, 120, 216, 1)',
            barBorderRadius: [15, 15, 0, 0]
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data1,
        },
        {
          yAxisIndex: 1,
          name: '转化率',
          type: 'line',
          smooth: true,
          symbol: '',
          symbolSize: 10,
          itemStyle: {
            color: 'rgba(43, 78, 225, 1)'
          },
          lineStyle: {
            color: 'rgba(43, 78, 225, 1)',
            width: 3
          },
          emphasis: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3,
            }
          },
          label: {
            show: true,
            position: 'top',
            color: 'rgba(43, 78, 225, 1)',
            formatter: '{c}%'
          },
          data: data2,
        },
      ]
    };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initLineChart2(chartIns, axisData, datas) {
    let chart = echarts.init(chartIns);
    // let data1 = [320, 302, 301, 334, 390, 330, 320];
    // let data2 = [30, 132, 101, 134, 90, 230, 210];
    // let data3 = [30, 182, 191, 234, 290, 330, 310];
    const {data1, data2, data3} = datas;

    let option = {
      color: ['rgba(74, 207, 133, 1)', 'rgba(255, 178, 103, 1)', 'rgba(66, 203, 253, 1)'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',       // 默认为直线，可选为：'line' | 'shadow'

          z: -1
        },
      },
      legend: {
        top: 1,
        right: 20,
        itemWidth: 14
      },
      grid: [
        {
          left: 50,
          right: 50,
          bottom: '50%',
          top: 65,
        },
        {
          left: 50,
          right: 50,
          bottom: 30,
          top: '57%',
        }],
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: axisData
        }, {
          gridIndex: 1,
          type: 'category',
          axisLabel: {
            show: false,
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: axisData
        }],
      yAxis: [
        {
          type: 'value',
          name: '已成单商机数量',
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(230, 230, 230, 1)'
            }
          },
        },
        {
          position: 'right',
          type: 'value',
          name: '商机转化率',
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)',

            formatter: '{value}%'
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
        },
        {
          gridIndex: 1, inverse: true,
          position: 'top',
          type: 'value',
          name: '成单金额',
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(230, 230, 230, 1)'
            }
          },
        },
      ],
      series: [
        {
          name: '已成单商机数量',
          type: 'bar',
          barWidth: 16,
          itemStyle: {
            color: 'rgba(72, 167, 255, 1)',
            barBorderRadius: [15, 15, 0, 0]
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data1,
        },
        {
          xAxisIndex: 0, yAxisIndex: 1,
          name: '商机转化率',
          type: 'line',
          smooth: true,
          symbol: '',
          symbolSize: 10,
          itemStyle: {
            color: 'rgba(255, 135, 103, 1)'
          },
          lineStyle: {
            color: 'rgba(255, 135, 103, 1)',
            width: 3,
            shadowBlur: 5,
            shadowColor: 'rgba(255, 135, 103, .6)',
            shadowOffsetY: 5
          },
          emphasis: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3,
            }
          },
          label: {
            show: true,
            position: 'top',
            color: 'rgba(255, 135, 103, 1)',
            formatter: '{c}%'
          },
          data: data2,
        },
        {
          xAxisIndex: 1, yAxisIndex: 2,
          name: '成单金额',
          type: 'bar',
          barWidth: 16,
          itemStyle: {
            color: 'rgba(255, 147, 68, 1)',
            barBorderRadius: [0, 0, 15, 15]
          },
          label: {
            show: true,
            position: 'bottom'
          },
          data: data3,
        },
      ]
    };
    chart.setOption(option);
    this.bindResize(chart);
  }
}
