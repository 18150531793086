import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalHelper } from "@delon/theme";
import { BimInfoSoftTypeComponent } from "./soft-type/bim-soft-type.component";
import { NzMessageService, NzModalService, NzTreeNodeOptions } from "ng-zorro-antd";
import { BimSoftDownloadTypeService } from "./soft-type/bim-soft-type.service";
import { ArrayService } from "@delon/util";
import { BimSoftDownloadDetailsService } from "./bim-info-soft.service";
import { BimInfoSoftEditComponent } from "./edit/edit.component";
import { isNullOrUndefined } from "util";
import { BimInfoSoftDownComponent } from "./soft-download/edit.component";
import { SyncSoftDataComponent } from "./sync-data/sync-soft-data.component";

@Component({
  selector: 'app-bim-info-soft',
  templateUrl: './bim-info-soft.component.html',
  styleUrls: []
})
export class BimInfoSoftComponent implements OnInit {

  public bimInfoId: String;
  public bimInfoName: String;
  public softTypeList: any;

  public curSelSoftTypeId = null;
  public softDetailList = [];

  public isloadding = false;

  public curTabIndex = null;

  public softDetailContent = null;
  public isSaving = false;

  public isTypeLoadding = false;

  public oriRecord = null;

  constructor(
    public modal: ModalHelper,
    public msg: NzMessageService,
    public routeInfo: ActivatedRoute,
    public modalService: NzModalService,
    public bimSoftDownloadTypeService: BimSoftDownloadTypeService,
    public bimSoftDownloadDetailsService: BimSoftDownloadDetailsService,
    private arrayService: ArrayService,
  ) {
    this.routeInfo.queryParams.subscribe(parms => {
      if (parms.bimInfoId) {
        this.bimInfoId = parms.bimInfoId;
      }

      if (parms.bimInfoName) {
        this.bimInfoName = parms.bimInfoName;
      }
    });
  }

  ngOnInit(): void {
    this.getSoftTypeData();
  }

  getSoftTypeData() {
    this.isTypeLoadding = true;
    this.bimSoftDownloadTypeService.query({
      'bimInfoId.equals': this.bimInfoId,
      page: 0,
      size: 1000,
      sort: ['orderNum','name']
    }).subscribe(res => {
      this.curSelSoftTypeId = null;
      this.softDetailList = [];
      const data = res.body;
      this.softTypeList = this.arrayService.arrToTreeNode(data, {
        parentIdMapName: "parentId",
        titleMapName: 'name',
      });
      this.softTypeList.forEach(e => {
        e.isSelectable = false;
      })
      this.isTypeLoadding = false;
    });
  }

  addSoftType() {
    this.modal.static(BimInfoSoftTypeComponent, {
      bimInfoId: this.bimInfoId
     }, 'lg').subscribe(() => {
      this.getSoftTypeData();
    });
  }

  delSoftType() {
    if (isNullOrUndefined(this.curSelSoftTypeId)) {
      this.msg.remove();
      this.msg.error('请选择待删除的软件！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '确认删除该软件?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isTypeLoadding = true;
        this.softDetailList = [];
        this.bimSoftDownloadTypeService.delete(this.curSelSoftTypeId)
          .subscribe(() => {
            this.msg.remove();
            this.msg.success('删除成功！');
            this.getSoftTypeData();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  syncSoftType() {
    this.modal.static(SyncSoftDataComponent, {
      bimInfoId: this.bimInfoId,
      bimInfoName: this.bimInfoName
     }, 'md').subscribe(() => {
      this.getSoftTypeData();
    });
  }

  selSoftType(event) {
    if (event.eventName == 'click') {
      const node = event.node;
      if (node.level > 0) {
        if (node.isSelected) {
          // 选中叶子节点 加载信息
          this.curSelSoftTypeId = node.key;
          // this.curTabIndex = null;
          this.getSoftDetailData(true);
        } else {
          this.curSelSoftTypeId = null;
          this.softDetailList = null;
        }
      }
    }
  }

  getSoftDetailData(isReset?) {
    this.isloadding = true;
    this.bimSoftDownloadDetailsService.query({
      'softTypeId.equals': this.curSelSoftTypeId,
      page: 0,
      size: 1000,
      sort: ['orderNum','name']
    }).subscribe(res => {
      this.isloadding = false;
      const data =res.body;
      if (data.length > 0) {
        data.forEach(e => {
          e['isEdit'] = false;
        })
        this.softDetailList = data;
        if (isReset || this.curTabIndex == null) {
          this.curTabIndex = 0;
        }
      } else {
        this.softDetailList = [];
        this.curTabIndex = null;
      }
    });
  }

  tabLick(item) {
    this.curTabIndex = item.index;
  }

  add() {
    if (isNullOrUndefined(this.curSelSoftTypeId)) {
      this.msg.remove();
      this.msg.error('无选中软件数据！');
      return;
    }
    this.modal.static(BimInfoSoftEditComponent, 
      { 
        record: {
          bimInfoId: this.bimInfoId, 
          softTypeId: this.curSelSoftTypeId,
          isAble: '1',
          orderNum: this.softDetailList.length + 1
        },
        isAdd: true }, 500)
      .subscribe((res) => {
        if (res) {
          this.getSoftDetailData(false);
        }
      });
  }

  edit() {
    if (isNullOrUndefined(this.curSelSoftTypeId) || isNullOrUndefined(this.curTabIndex)) {
      this.msg.remove();
      this.msg.error('暂无可编辑的标签页！');
      return;
    }
    this.modal.static(BimInfoSoftEditComponent, 
      { 
        record: this.softDetailList[this.curTabIndex],
        isAdd: false }, 500)
      .subscribe((res) => {
        if (res) {
          this.getSoftDetailData(false);
        }
      });
  }

  del() {
    if (isNullOrUndefined(this.curSelSoftTypeId) || isNullOrUndefined(this.curTabIndex)) {
      this.msg.remove();
      this.msg.error('暂无可删除的标签页！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '确认删除该标签?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isloadding = true;
        this.bimSoftDownloadDetailsService.delete(this.softDetailList[this.curTabIndex].id)
          .subscribe(() => {
            this.msg.remove();
            this.msg.success('删除成功！');
            this.getSoftDetailData(true);
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  editDetail() {
    this.oriRecord = JSON.parse(JSON.stringify(this.softDetailList[this.curTabIndex]));
    this.softDetailList[this.curTabIndex].isEdit = true;
    console.log(this.oriRecord);
  }

  saveDetail() {
    if (isNullOrUndefined(this.curSelSoftTypeId) || isNullOrUndefined(this.curTabIndex)) {
      return;
    }
    if (!this.softDetailList[this.curTabIndex].isEdit) {
      return;
    }
    const data = this.softDetailList[this.curTabIndex];
    this.isSaving = true;
    this.bimSoftDownloadDetailsService.update(data).subscribe(res => {
      this.isSaving = false;
      this.softDetailList[this.curTabIndex].isEdit = false;
      const data = res.body;
      if (data) {
        this.msg.info('保存成功');
      } else {
        this.msg.error('保存失败');
      }
    });
  }

  softDownload() {
    if (isNullOrUndefined(this.curSelSoftTypeId) || isNullOrUndefined(this.curTabIndex)) {
      return;
    }
    const data = this.softDetailList[this.curTabIndex];
    this.modal.static(BimInfoSoftDownComponent, 
      { 
        record: data,
      }, 500)
      .subscribe((res) => {
        if (res) {
          this.getSoftDetailData(false);
        }
      });
  }

  cancelDetail() {
    if (isNullOrUndefined(this.curSelSoftTypeId) || isNullOrUndefined(this.curTabIndex)) {
      return;
    }
    if (!this.softDetailList[this.curTabIndex].isEdit) {
      return;
    }
    if (this.oriRecord != null) {
      this.softDetailList[this.curTabIndex].isEdit = false;
      this.softDetailList[this.curTabIndex].softDetail = this.oriRecord.softDetail;
      this.oriRecord = null;
    }
  }

}
