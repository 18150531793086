import {Component, Inject, Input, OnInit} from '@angular/core';
import {NzMessageService, UploadFile, UploadFilter, UploadXHRArgs} from 'ng-zorro-antd';
import {HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {HttpAddressService} from '@shared/session/http-address.service';
import {ThsadminFileService} from '@shared/components/f-upload/thsadmin-file.service';
import { FileUploadService } from '../fileUpload/fileUpload.service';
import {GuidUtil} from '@shared/utils/guid.util';
import { deepCopy } from '@delon/util';
import * as OSS from 'ali-oss';
import {Md5} from 'ts-md5';
import * as moment from 'moment';
import { WorkManageService } from 'app/routes/business/bim/work-manage/work-manage.service';

@Component({
  selector: 'app-f-upload',
  templateUrl: './f-upload.component.html',
  styleUrls: ['./f-upload.component.less'],
})
export class FUploadComponent implements OnInit {

  @Input() uploadTxt = '上传';
  @Input() uploadType = 'team_works_model';
  @Input() uploadFileType: string[] = []; // 后缀 .xls
  @Input() uploadFileSize: number = 10 * 1024 * 1024; // 文件最大限制 默认10M
  @Input() fileNumLimit = 1; // 文件数量限制
  @Input() fileFk = '';
  @Input() isView = false;
  @Input() uploadTips = ''; // 上传提示

  maxSizeStr = '10MB';

  // uploadAddress = '/thsadmin/api/sys-files/upload';
  uploadAddress = '/thsfiles/api/sys-files/uploadFileOSS';
  loading = false;
  avatarUrl: any =
    'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png';

  @Input() fileList = [];

  fileList2 = [];

  previewImage = '';
  previewVisible = false;

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn  : (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => {
          const fileSuffix = w.name.substring(w.name.lastIndexOf('.'));
          return this.uploadFileType.includes(fileSuffix);
        });
        if (filterFiles.length !== fileList.length) {
          this.msg.error(`包含文件格式不正确，只支持 ${this.uploadFileType.join('、 ')} 格式`);
          return filterFiles;
        }
        return fileList;
      }
    }
  ];

  currentUserId = '';

  currentIndex = 0;
  successNum = 0;
  needNum = 0;
  // 已上传文件大小
  uploadSize = 0;
  curVideo: any;
  // 当前操作文件id
  curFileId = '';
  // 展示上传进度条
  showPercent = false;
  // 上传百分比
  percent = 0;
  // 已上传数组
  needUploadIndex = [];
  videoUploadAddress: string;
  uploadBeforeFile: string;
  adminUploadAddress: string; // admin服务文件上传地址
  isUpload = false;

  public ossClient;
  public ossFileUploadId;

  constructor(
    private msg: NzMessageService,
    private http: HttpClient,
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
    private thsadminFileService: ThsadminFileService,
    private httpAddressService: HttpAddressService,
    private fileUploadService: FileUploadService,
    private workManageService: WorkManageService,
  ) {
    this.uploadBeforeFile = httpAddressService.apiGateway + httpAddressService.FileServer + '/sys-files/upload/before';
    this.videoUploadAddress = httpAddressService.apiGateway + httpAddressService.FileServer + '/sys-file-details/upload/video';
    this.adminUploadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/upload';

    this.ossClient = new OSS(this.httpAddressService.ossServe);
  }

  ngOnInit() {
    this.currentUserId = this.tokenService.get().account_id;
    this.getMaxSizeStr();
  }

  getMaxSizeStr() {
    if (this.uploadFileSize < 1024) {
      this.maxSizeStr = this.uploadFileSize + 'B';
    } else if (this.uploadFileSize < 1024 * 1024) {
      this.maxSizeStr = this.uploadFileSize / 1024 + 'KB';
    } else if (this.uploadFileSize < 1024 * 1024 * 1024) {
      this.maxSizeStr = this.uploadFileSize / (1024 * 1024) + 'MB';
    } else if (this.uploadFileSize < 1024 * 1024 * 1024 * 1024) {
      this.maxSizeStr = this.uploadFileSize / (1024 * 1024 * 1024) + 'GB';
    } else {
      this.maxSizeStr = this.uploadFileSize / (1024 * 1024 * 1024 * 1024) + 'TB';
    }
  }

  beforeUpload = (file: UploadFile) => {
    if (this.showPercent && (this.uploadTxt === '上传视频' || this.uploadTxt === '上传模型')) {
      this.msg.warning('请等待文件上传完成');
      return false;
    }
    const fileSuffix = file.name.substring(file.name.lastIndexOf('.'));
    if (!this.uploadFileType.includes(fileSuffix)) {
      this.msg.error('请上传后缀为 ' + this.uploadFileType.join(',') + ' 的文件');
      return false;
    }
    if (file.size > this.uploadFileSize) {
      this.msg.error('文件最大不能超过 ' + this.maxSizeStr);
      return false;
    }
    if (this.fileList.length >= this.fileNumLimit) {
      this.msg.error('最多可上传 ' + this.fileNumLimit + ' 个文件');
      return false;
    }
    if (this.fileList2.length >= this.fileNumLimit) {
      this.msg.error('最多可上传 ' + this.fileNumLimit + ' 个文件');
      return false;
    }
    file['showPercent'] = true;
    file['percent'] = 0;
    // const isJPG = file.type === 'image/jpeg';
    // if (!isJPG) {
    //   this.msg.error('You can only upload JPG file!');
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   this.msg.error('Image must smaller than 2MB!');
    // }
    return true;
  }

  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: UploadFile }): void {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file.originFileObj);
      // this.getBase64(info.file.originFileObj, (img: string) => {
      //   this.loading = false;
      //   this.avatarUrl = img;
      // });
    }
  }

  remove(file) {
    this.avatarUrl = null;
    const index = this.fileList.indexOf(file);
    if (index > -1) {
      this.fileList.splice(index, 1);
    }
    console.log(file);
    if (file.extField2 === 'AliOss') {
      this.thsadminFileService.deleteOSSFileById(file.uid).subscribe(res => {
      });
    } else {
      this.thsadminFileService.deleteFileById(file.uid).subscribe(res => {
        // const index1 = this.fileList.indexOf(file);
        // const index2 = this.fileList2.indexOf(file);
        // if (index1 > -1) {
        //   this.fileList.splice(index1, 1);
          // } else if (index2 > -1) {
          //   this.fileList2.slice(index2, 1);
        // }
      });

    }
  }

  pictureHandleChange(info: { file: UploadFile }): void {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info.file.originFileObj);
      this.getBase64(info.file.originFileObj, (img: string) => {
        this.loading = false;
        // this.avatarUrl = img;
      });
    }
  }

  handlePreview = (url: any) => {
    this.previewImage = url;
    this.previewVisible = true;
  }

  customReq = (item: UploadXHRArgs) => {
    if (this.showPercent) {
      this.msg.warning('请等待当前文件上传完成');
      this.fileList.pop();
      return;
    }
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('multipartFile', item.file as any);
    formData.append('name', item.file.name || '');
    formData.append('fileFk', this.fileFk || GuidUtil.getNewGUIDString());
    formData.append('fileDesc', item.file.name);
    formData.append('uploader', this.currentUserId);
    formData.append('extField1', this.uploadType);
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress : true,
      withCredentials: true
    });
    // 始终返回一个 `Subscription` 对象，nz-upload 会在适当时机自动取消订阅
    return this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total > 0) {
          // tslint:disable-next-line:no-any
          (event as any).percent = event.loaded / event.total * 100;
        }
        // 处理上传进度条，必须指定 `percent` 属性来表示进度
        item.onProgress(event, item.file);
      } else if (event instanceof HttpResponse) {
        // 处理成功
        item.onSuccess(event.body, item.file, event);
      }
      if (event.type === HttpEventType.Response) {
        item.file.uid = event.body.id;
        item.file.url = event.body.extField2 === 'AliOss' ? event.body.fileDesc : this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + event.body.id;
        this.fileList.pop();
        this.fileList.push(item.file);
        console.log('fileList:', this.fileList);
      }
    }, (err) => {
      // 处理失败
      item.onError(err, item.file);
    });
  }

  // -------------- 大文件上传部分-----------------//

  // 自定义上传
  customRequest = (item) => {
    if (this.showPercent) {
      this.msg.warning('请等待当前文件上传完成');
      this.fileList.pop();
      return;
    }
    this.showPercent = true;
    const file = deepCopy(item.file);
    let current = 0;
    const size = 5 * 1024 * 1024;
    let length = file.size;
    item.data.extField5 = Math.ceil(length / size);
    this.needNum = item.data.extField5;

    this.curVideo = file;
    const __this = this;
    // 分片数组
    const blobArr = [];
    // 第几片分片
    let index = 1;
    // --------------- 分片 --------------//
    while (length > size) {
      const next = current + size;
      const blob = file.slice(current, next);
      const tmp = {
        blob: blob,
        orderNum: index,
        size: size
      };
      blobArr.push(tmp);
      length = length - size;
      current = next;
      index++;
    }

    if (length > 0) {
      const blob = file.slice(current, file.size);
      const tmp = {
        blob: blob,
        orderNum: index,
        size: length
      };
      blobArr.push(tmp);
    }
    this.currentIndex = 0;
    this.percent = 0;
    this.successNum = 0;
    this.currentIndex = 0;
    // --------------- 分片结束 --------------//


    // 定义sysfile数据添加完之后 回调函数
    const onload = function (e) {
      // 第一次上传
      if (e.target.status === 201) {
        const sysFileObj = JSON.parse(e.target.response);
        item.data.sysFileId = sysFileObj.id;
        item.data.fileSavePath = sysFileObj.fileSavePath;
        item.data.fileType = sysFileObj.fileType;
        __this.curVideo.tmpId = sysFileObj.id;
        __this.curFileId = sysFileObj.id;

        // -------------同步发送-----------------//
        const token = __this.tokenService.get().token;
        const _doUpload = function () {
          const xhr = new XMLHttpRequest();
          xhr.open('post', __this.videoUploadAddress, true);
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          xhr.onprogress = function (event) {
          };
          const blob = blobArr[__this.currentIndex++];
          const fileObj = __this.getFormData(item, blob);
          const read = new FileReader();
          read.readAsBinaryString(blob.blob);
          // tslint:disable-next-line: no-shadowed-variable
          read.onload = function (e1: any) {
            fileObj.append('md5', Md5.hashStr(e1.target.result).toString());
            xhr.send(fileObj);
          };
          const fun = function (e3) {
            if (this.status === 200) {
              const res = JSON.parse(this.response);
              if (res.code === '1') {
                __this.percent = 100;
                __this.uploadSize += blob.size;
                __this.curVideo.fileSizeForRead = __this.fileUploadService.readablizeBytes(__this.uploadSize);
                __this.showPercent = false;
                __this.fileList.forEach(f => {
                  if (f.uid === item.file.uid) {
                    f.uid = sysFileObj.id;
                  }
                });
              } else if (res.code === '0') {
                // 递归上传
                _doUpload();
                __this.successNum++;
                __this.percent = Math.floor((__this.successNum / __this.needNum) * 100);
                __this.uploadSize += blob.size;
                __this.curVideo.fileSizeForRead = __this.fileUploadService.readablizeBytes(__this.uploadSize);
              }
            }
          };
          xhr.onload = fun;
          xhr.onerror = function (evt) {
            console.log('error： ', __this.currentIndex - 1);
          };
        };
        _doUpload();
      }
    };

    // 获取文件md5 并开始往admin服务上传 ，上传完成之后开始调用 onload 分片上传
    const allRead = new FileReader();
    // 读取大文件md5耗时较长，此处读取完整文件的第一段与最后一段分片md5
    allRead.readAsBinaryString(new Blob([blobArr[0].blob, blobArr[blobArr.length - 1].blob]));
    // 加载两个分片之后执行
    allRead.onload = function (e: any) {
      const sysFile = {
        blob: null,
        extField3: Md5.hashStr(e.target.result).toString()
      };
      __this.needUploadIndex = blobArr.map(v => v.orderNum);
      // 上传之前的检验 回调
      const beforeOnload = function (e2) {
        if (e2.target.status === 200) {
          const sysFileObj = JSON.parse(e2.target.response);
          // 不需要上传
          if (sysFileObj.code === '1') {
            __this.showPercent = false;
            __this.fileList.pop();
            __this.msg.info(sysFileObj.message);
            return;
          }
          // 续传
          if (sysFileObj.code === '2') {
            const needArr = sysFileObj.data.needArr;
            __this.successNum = __this.needNum - needArr.length;
            // __this.needNum = needArr.length;
            let tmpIndex = 0;
            __this.currentIndex = needArr[tmpIndex++] - 1;

            const sysFileDTO = sysFileObj.data.sysFileDTO;
            item.data.sysFileId = sysFileDTO.id;
            item.data.fileSavePath = sysFileDTO.fileSavePath;
            item.data.fileType = sysFileDTO.fileType;
            __this.msg.info('开始续传,准备资源中');
            const token = __this.tokenService.get().token;
            const _doUpload = function () {
              const xhr = new XMLHttpRequest();
              xhr.open('post', __this.videoUploadAddress, true);
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
              xhr.onprogress = function (event) {
              };

              const blob = blobArr[__this.currentIndex];
              const fileObj = __this.getFormData(item, blob);

              const read = new FileReader();
              read.readAsBinaryString(blob.blob);
              read.onload = function (e1: any) {
                fileObj.append('md5', Md5.hashStr(e1.target.result).toString());
                xhr.send(fileObj);
              };

              const fun = function (e3) {
                if (this.status === 200) {
                  const res = JSON.parse(this.response);
                  if (res.code === '1') {
                    __this.percent = 100;
                    __this.uploadSize += blob.size;
                    __this.curVideo.fileSizeForRead = __this.fileUploadService.readablizeBytes(__this.uploadSize);
                    __this.showPercent = false;
                    __this.fileList.forEach(f => {
                      if (f.uid === item.file.uid) {
                        f.uid = sysFileObj.id;
                      }
                    });
                  } else if (res.code === '0') {
                    __this.currentIndex = needArr[tmpIndex++] - 1;
                    // 递归上传
                    _doUpload();
                    __this.successNum++;
                    __this.percent = Math.floor((__this.successNum / __this.needNum) * 100);
                    __this.uploadSize += blob.size;
                    __this.curVideo.fileSizeForRead = __this.fileUploadService.readablizeBytes(__this.uploadSize);
                  }
                }
              };
              xhr.onload = fun;
              xhr.onerror = function (evt) {
                console.log('error： ', __this.currentIndex - 1);
              };
              // xhr.send(fileObj);
            };
            _doUpload();
            return;
          }

          // 首次上传
          if (sysFileObj.code === '0') {
            __this.msg.info('开始上传文件,准备资源中');
            __this.doUpload(__this.getFormData(item, sysFile), __this.adminUploadAddress, -1, item.data.extField5, onload);
            return;
          }
        }
      };
      // 上传之前的检验
      __this.doUpload(__this.getFormData(item, sysFile), __this.uploadBeforeFile, -1, item.data.extField5, beforeOnload);
    };

    return this.fileUploadService.query({ 'id.equals': '123' }).subscribe(res => {
    });
  }

  // 上传操作
  doUpload(fileObj: FormData, address: string, index: number, len: number, onload: any) {
    const token = this.tokenService.get().token;
    const xhr = new XMLHttpRequest();
    xhr.open('post', address, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.onprogress = function (event) {
      if (event.lengthComputable) {
        const progress = Number((event.loaded * 100 / event.total).toString().split('.')[0]);
      }
    };
    xhr.onload = onload;
    xhr.onerror = function (evt) {
      console.log('error： ', index);
    };
    xhr.send(fileObj);
  }

  // 生成表单数据
  getFormData(item, file) {
    const fileObj = new FormData();
    if (item.data) {
      // tslint:disable-next-line: forin
      for (const key in item.data) {
        fileObj.append(key, item.data[key]);
      }
    }
    // tslint:disable-next-line: forin
    for (const key in file) {
      fileObj.append(key, file[key]);
    }
    return fileObj;
  }

  // ------------------------OSS文件上传-----------------------------

  fileUploadProgress = (p, checkPoint) => {
    this.ossFileUploadId = checkPoint.uploadId;

    const objectName = checkPoint.name;
    const prefix = objectName.substring(0, objectName.lastIndexOf('.'));
    // 生成用于记录断点续传上传的文件。
    const blob = new Blob([JSON.stringify(checkPoint)]);
    const file = new File([blob], prefix.substring(0, objectName.lastIndexOf('/')) + '.txt', { type: 'text/plain;charset=utf-8' });

    const fileName = prefix + '.txt';
    this.ossClient.put(fileName, file).catch((err) => {
      if (err) {
        console.log(err);
        return;
      }
    }).then((result) => {
      console.log(result);
    });

    this.percent = Number(Number(p * 100).toFixed(0));
    this.fileList.forEach(e => {
      if (e.uid === checkPoint.file.uid) {
        e.percent = this.percent;
        if (p === 1) {
          e.showPercent = false;
        } else {
          e.showPercent = true;
        }
      }
    });
    console.log(this.fileList);
    if (p === 1) {
      this.showPercent = false;
    }
  }

  customOSSFileReq = (item: UploadXHRArgs) => {
    if (this.showPercent) {
      this.msg.warning('请等待当前文件上传完成');
      this.fileList.pop();
      return;
    }
    this.showPercent = true;
    this.fileList.forEach(e => {
      if (e.uid === item.file.uid) {
        e.showPercent = true;
        e.percent = 0;
      }
    });
    const fileName = item.file.name;

    const suffix = fileName.substring(fileName.lastIndexOf('.'));
    const uid = new GuidUtil().toString();
    const realName = uid + suffix;

    const nowTime = moment(new Date()).format('YYYYMMDD');
    const filePath = nowTime + '/' + realName;

    // 是否需要分片上传
    const isNeedMutipart = item.file.size > 20 * 1024 * 1024;
    // 先初始化分片上传数据 initiateMultipartUpload
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('name', fileName || '');
    formData.append('fileType', suffix);
    formData.append('fileFk', this.fileFk || GuidUtil.getNewGUIDString());
    formData.append('fileDesc', item.file.name);
    formData.append('fileSavePath', filePath);
    formData.append('size', item.file.size.toString());
    formData.append('uploader', this.currentUserId);
    formData.append('extField1', this.uploadType);
    formData.append('extField2', 'AliOss');
    formData.append('extField3', Md5.hashStr(this.fileFk + fileName + item.file.size).toString());
    formData.append('extField4', isNeedMutipart ? '0' : '1');
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress : true,
      withCredentials: true
    });

    this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.Response) {
        const res = event.body;
        const data = event.body.data;
        // 不需要上传
        if (res.code === '1') {
          this.showPercent = false;
          item.file.showPercent = false;
          this.fileList.pop();
          this.msg.info(res.message);
          return;
        }
        if (!isNeedMutipart) {
          this.ossClient.put(filePath, item.file).catch((err) => {
            if (err) {
              console.log(err);
              // 上传出错
              this.msg.error('上传失败，请刷新网页或更换浏览器再次尝试');
              this.fileList.pop();
              this.thsadminFileService.deleteFileById(data.id).subscribe();
              return;
            }
          }).then((result) => {
            if (result !== undefined) {
              this.workManageService.updateUploadDate(this.fileFk).subscribe();
              item.file.uid = data.id;
              item.file.url = this.ossClient.signatureUrl(filePath);
              item.file.extField2 = data.extField2;
              item.file.showPercent = false;
              this.fileList.pop();
              this.fileList.push(item.file);
            }
            this.showPercent = false;
          });
          return;
        }
        // 续传
        if (res.code === '2') {
          const sysFile = res.data;
          const checkFilePath = sysFile.fileSavePath;
          const checkPointFilePath = checkFilePath.substring(0, checkFilePath.lastIndexOf('.')) + '.txt';


          // 检查文件是否存在
          this.ossClient.head(checkPointFilePath).catch((err) => {
            if (err.code === 'NoSuchKey') {
              // 续传文件找不到，重新上传
              this.mutipartUploadOSSFile(checkFilePath, item, event, null);
              return;
            }
          }).then((result) => {
            if (result !== undefined) {
              // 续传
              const filename = 'fileCheckpoint.txt';
              const response = {
                'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
              };
              const fileUrl = this.ossClient.signatureUrl(checkFilePath.substring(0, checkFilePath.lastIndexOf('.')) + '.txt', {response});
              const xhr = new XMLHttpRequest();
              xhr.open('get', fileUrl, true);
              xhr.onload = (fRes: any) => {
                  console.log(fRes);
                  const target = fRes.target;
                  if (target.status === 200) {
                    const text = target.responseText;
                    const checkPoint = JSON.parse(text);
                    this.mutipartUploadOSSFile(checkFilePath, item, event, checkPoint);
                  }
              };
              xhr.send();
            }
          });
        }
        // 初次上传
        if (res.code === '0') {
          this.mutipartUploadOSSFile(filePath, item, event, null);
        }
      }
    });

    return this.fileUploadService.query({ 'id.equals': '123' }).subscribe(res => {
    });
  }

  mutipartUploadOSSFile(filePath, item, event, checkPoint) {
    const data = event.body.data;
    // 通过multipartUpload上传选中的文件，并通过progress参数设置进度条。
    const options = {
      progress: this.fileUploadProgress,
      partSize: 5 * 1024 * 1024,
    };
    if (checkPoint !== null) {
      options['checkpoint'] = checkPoint;
    }
    this.ossClient.multipartUpload(filePath, item.file, options).catch((err) => {
      if (err) {
        console.log(err);
        // 上传出错
        this.msg.error('上传失败，请刷新网页或更换浏览器再次尝试');
        this.fileList.pop();
        if (checkPoint === null) {
          this.thsadminFileService.deleteFileById(data.id).subscribe();
        }
        return;
      }
    }).then((result) => {
      console.log(result);
      if (result !== undefined) {
        this.workManageService.updateUploadDate(this.fileFk).subscribe();
        item.file.uid = data.id;
        item.file.showPercent = false;
        this.fileList.pop();
        this.fileList.push(item.file);
        data.extField4 = '1';
        this.fileUploadService.update(data).subscribe();
        // 删除oss记录断点续传文件
        const objectName = data.fileSavePath;
        this.deleteOSSFile(objectName.substring(0, objectName.lastIndexOf('.')));
      }
      this.showPercent = false;
    });
  }

  deleteOSSFile(objectName) {
    this.ossClient.delete(objectName + '.txt').catch((err) => {
      if (err) {
        console.log(err);
        return;
      }
    }).then((res) => {
      console.log(res);
    });
  }
  
  downFile(item){
    console.log(item.url);
    let a = document.createElement('a');
    let filename = item.name;
    a.href = item.url;
    a.download = filename;
    a.click();
  }
}
