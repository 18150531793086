import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { YearGoalsReportService } from "./yearGoalsReport.service";
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { YearGoalsReportEditComponent } from "./edit/edit.component";
import { APP_PERMISSIONS_IUCCE } from '@shared/app.permissions';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-iucce-yeargoals-report',
  templateUrl: './yearGoalsReport.component.html',
  styleUrls: ['./yearGoalsReport.component.less']
})
export class YearGoalsReportComponent implements OnInit {
  dtLoading = false;
  queryParams = {
    'year.in':'',
    'batch.in':'',
    'prjType.in':''
  };
  isCollapse = true;
  yearList: any;//年份
  batchList:any;//批次
  batchResultList: any;//批次全部数据
  prjTypeList:any;//项目类别
  prjTypeResultList: any;//项目类别全部数据

  allChecked = false;
  indeterminate = false;
  dataList: any;
  dataListTotal:any;//合计数据
  total = 0;
  page = 0;
  size = 10;
  pageSizeOptions = [10, 20, 50, 100];
  APP_PERMISSIONS = APP_PERMISSIONS_IUCCE;
  
  sortList = {};//排序

  constructor(public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    private dictService: DictService,
    private dictDetailService: DictDetailService,
    private yearGoalsReportService : YearGoalsReportService) { }

  ngOnInit(): void {
    this.getYearListDict();
    this.getDataList();
    console.log(this.APP_PERMISSIONS.IUCCE_PROJECT_REPORT_ADD);
  }

  getYearListDict(){
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
    this.dictService.query({ 'dictNo.equals':'batchList' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals':res.body[0].id ,sort:'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.batchResultList = res2.body;
          }
        });
      }
    });
    this.dictService.query({ 'dictNo.equals':'prjTypes' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals':res.body[0].id ,sort:'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.prjTypeResultList = res2.body;
          }
        });
      }
    });
  }

  getDataList(isReset?: boolean){
    const copyParams = {};
    const q = this.queryParams;
    this.dtLoading = true;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      this.batchList = [];
      this.prjTypeList = [];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    let sortArray = [];
    for(const sortKey in this.sortList){
      let sortValue = this.sortList[sortKey].replace('end','');
      sortArray.push(sortKey+','+sortValue);
    }
    if(sortArray && sortArray.length > 0){
      copyParams['sort'] = sortArray;
    }else{
      copyParams['sort'] = ['year,desc','createdDate,desc','batch,desc','prjType,desc'];
    }
   

    this.yearGoalsReportService.query(copyParams)
      .subscribe((res: any) => {
        this.dtLoading = false;
        this.dataList = res.body;
        //this.total = res.headers.get('X-Total-Count');

        const totalStr = res.headers.get('X-Total-Count');//X-Total-Count 格式为{ totalCount:'总记录数',totalDataList:'合计汇总集合JSON格式' }
        const jsonObj = JSON.parse(totalStr);
        if(jsonObj != null && typeof jsonObj == 'object'){
          this.total = jsonObj['totalCount'];

          //合计
          let totalData = jsonObj['totalDataList'];
          if(totalData && totalData.length > 0){
            totalData = JSON.parse(totalData);
            this.dataListTotal = totalData ? totalData : [];
          }
        }else{
          this.total = totalStr;
        }
        
    });
  }

  /**
   * 
   * @param event 排序
   */
  sort(event: { key: string, value: string }){
    const sortD = this.sortList[event.key];
    if(event.value){
      this.sortList[event.key] = event.value;
    }else{
      delete this.sortList[event.key];
    }
    this.getDataList();
  }

  fnChangeYear(item){
    
    this.batchList = [];
    this.prjTypeList = [];
    this.queryParams['batch.in'] = "";
    this.queryParams['prjType.in'] = "";

    if(this.batchResultList){
      this.batchList = this.batchResultList.filter(w=>w.parentId && w.itemValue2 === item);
    }
    if(this.prjTypeResultList){
      this.prjTypeList = this.prjTypeResultList.filter(w=>w.parentId && w.itemValue2 === item);
    }
   
  }

  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(YearGoalsReportEditComponent, { record: { id: null },isAdd:true },520)
      //      .pipe(filter(w => w === true))
      .subscribe((result) => {
        if(result){
          this.getDataList();
        }
      });
  }


  // /**
  // * 编辑页面
  // * @returns {NzMessageDataFilled}
  // */
  // edit() {
  //   if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
  //   if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
  //   const tmp = this.formatIsOk(this.selectedRows[0]);
  //   this.modal
  //     .static(EduCourseEditComponent, { record: tmp })
  //     //     .pipe(filter(w => w === true))
  //     .subscribe(() => this.getDataList());
  // }

  /**
  * 编辑页面
  */
  edit(item){
    this.modal
      .static(YearGoalsReportEditComponent, { record: item ,isAdd:false},520)
      .subscribe((result) => {
        if(result){
          this.getDataList();
        }
    });
  }

  /**
   * 查看
   * @param item 
   */
  view(item){
    this.modal
      .static(YearGoalsReportEditComponent, { record: item,isAdd:false,isView:true},520)
      .subscribe((result) => {
        
    });
  }

  /**
   * 删除数据
   * @param item 
   */
  delete(item){
    this.modalService.confirm({
      nzTitle     : '确定删除数据吗？',
      nzContent   : '',
      nzOkText    : '确定',
      nzOkType    : 'primary',
      nzOnOk      : () => {
        this.yearGoalsReportService.delete(item.id)
          .subscribe(res=>{
            this.msg.success("删除成功");
            this.getDataList();
          },errorRes=>{
            this.msg.success("删除失败");
        });
      },
      nzCancelText: '取消',
      nzOnCancel  : () => {}
    });
  }

  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  /**
  * 页码数量变动事件
  * @param
  */
   paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  // currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
  //   this.dataList = $event;
  //   this.refreshStatus();
  // }

  // refreshStatus(): void {
  //   const allChecked = this.dataList.filter(value => !value.disabled).every(value => value.checked === true);
  //   const allUnChecked = this.dataList.filter(value => !value.disabled).every(value => !value.checked);
  //   this.allChecked = allChecked;
  //   this.indeterminate = (!allChecked) && (!allUnChecked);
  // }

  // checkAll(value: boolean): void {
  //   this.dataList.forEach(data => {
  //     if (!data.disabled) {
  //       data.checked = value;
  //     }
  //   });
  //   this.refreshStatus();
  // }

}
