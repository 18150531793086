import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { YearGoalsReportComponent } from './yearGoalsReport/yearGoalsReport.component';
import { ProjectReportComponent } from './projectReport/projectReport.component';
import { ProjectReportAuditComponent } from './projectReportAudit/projectReportAudit.component';
import { ApproveComponent } from './projectReportAudit/approve/approve';
import { ApproveFlowComponent } from './projectReportAudit/approveFlow/approveFlow';
import { ProjectReportAuditViewComponent } from './projectReportAudit/view/view.component';
import { ProjectFundPayComponent } from './projectFundPay/projectFundPay.component';
import { ProjectFundPayEditComponent } from './projectFundPay/edit/edit.component';
import { ProjectFundPayViewComponent } from './projectFundPay/view/view.component';
import { ProjectNicheComponent } from './projectNiche/projectNiche.component';
import { ProjectNicheEditComponent } from './projectNiche/niche/niche.component';
import { ProjectNicheViewComponent } from './projectNiche/view/view.component';
import { ProjectClosingComponent } from './projectClosing/projectClosing.component';
import { ProjectClosingEditComponent } from './projectClosing/closing/closing.component';
import { ProjectClosingViewComponent } from './projectClosing/view/view.component';
import { ProjectClosingAuditComponent } from './projectClosingAudit/projectClosingAudit.component';
import { ProjectClosingApproveFlowComponent } from "./projectClosingAudit/approveFlow/approveFlow.component";
import { ProjectClosingAuditApproveComponent } from './projectClosingAudit/approve/approve';
import { ProjectClosingAuditViewComponent } from './projectClosingAudit/view/view.component';
import { ProjectQueryComponent } from './projectQuery/projectQuery.component';
import { ProjectQueryViewComponent } from './projectQuery/view/view.component';
import { ProjectCockpitComponent } from './projectCockpit/projectCockpit.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'yeargoalsReport', component: YearGoalsReportComponent,
        data: { title: '年度目标申报', titleI18n: '年度目标申报' } 
      },
      { path: 'projectReport', component: ProjectReportComponent,
        data: { title: '项目立项申报', titleI18n: '项目立项申报' }  
      },
      { path: 'projectReportAudit', component: ProjectReportAuditComponent,
        data: { title: '项目立项审批', titleI18n: '项目立项审批' } 
      },
      { path: 'projectReportAudit/approve', component: ApproveComponent,data: { title: '立项审批详情', titleI18n: '立项审批详情' } },
      { path: 'projectReportAudit/approveFlow', component: ApproveFlowComponent,data: { title: '立项审批详情', titleI18n: '立项审批详情' } },
      { path: 'projectReportAudit/view', component: ProjectReportAuditViewComponent,data: { title: '立项审批查看', titleI18n: '立项审批查看' } },
      { path: 'projectFundPay', component: ProjectFundPayComponent,
        data: { title: '资金支付', titleI18n: '资金支付' }
      },
      {
        path: 'projectFundPay/edit',
        component: ProjectFundPayEditComponent,
        data: { title: '资金支付详情', titleI18n: '资金支付详情' },
      },
      {
        path: 'projectFundPay/view',
        component: ProjectFundPayViewComponent,
        data: { title: '资金支付查看', titleI18n: '资金支付查看' },
      },

      { path: 'projectNiche', component: ProjectNicheComponent,
        data: { title: '商机跟进', titleI18n: '商机跟进' } 
      },
      {
        path: 'projectNiche/edit',
        component: ProjectNicheEditComponent,
        data: { title: '商机跟进详情', titleI18n: '商机跟进详情' }
      },
      {
        path: 'projectNiche/view',
        component: ProjectNicheViewComponent,
        data: { title: '商机跟进查看', titleI18n: '商机跟进查看' }
      },

      { path: 'projectClosing', component: ProjectClosingComponent,
        data: { title: '项目结项提交', titleI18n: '项目结项提交' },
      },
      {
        path: 'projectClosing/edit',
        component: ProjectClosingEditComponent,
        data: { title: '结项提交详情', titleI18n: '结项提交详情' },
      },
      {
        path: 'projectClosing/view',
        component: ProjectClosingViewComponent,
        data: { title: '结项提交查看', titleI18n: '结项提交查看' },
      },

      { path: 'projectClosingAudit', component: ProjectClosingAuditComponent,
        data: { title: '项目结项审批', titleI18n: '项目结项审批' },
      },
      {
        path: 'projectClosingAudit/approve',
        component: ProjectClosingAuditApproveComponent,
        data: { title: '结项审批详情', titleI18n: '结项审批详情' },
      },
      { path: 'projectClosingAudit/approveFlow', component: ProjectClosingApproveFlowComponent,data: { title: '结项审批详情', titleI18n: '结项审批详情' } },
      {
        path: 'projectClosingAudit/view',
        component: ProjectClosingAuditViewComponent,
        data: { title: '结项审批查看', titleI18n: '结项审批查看' },
      },
      { path: 'projectQuery', component: ProjectQueryComponent,
        data: { title: '项目查询', titleI18n: '项目查询' }
      },
      { path: 'projectQuery/view', component: ProjectQueryViewComponent, data: { title: '查看项目', titleI18n: '查看项目' } },
      {
        path: 'projectCockpit',
        component: ProjectCockpitComponent,
        data: { title: '产学合作驾驶舱', titleI18n: '产学合作驾驶舱' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IucceRoutingModule {}
