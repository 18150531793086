import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Subject, QuestionOption } from './subject.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubjectService extends ThsBaseService<Subject> {
  private couseurl = '';
  questionOptionUrl = '';
  courseUrl = '';
  catalogUrl = '';
  courseCatalogsUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-subjects`);
    this.couseurl = httpAddressService.EduServe + `/edu-courses`;
    this.questionOptionUrl = httpAddressService.EduServe + '/edu-subject-options';
    this.courseUrl = httpAddressService.EduServe + '/edu-courses';
    this.catalogUrl = httpAddressService.EduServe + '/edu-course-catalogs';
    this.courseCatalogsUrl = httpAddressService.EduServe + '/edu-course-catalogs';
  }
  getcourseName(queryParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}`;
    return this.http.get<any>(url, { params: queryParams });
  }
  querySubjectCourseCatalog(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllSubjectCourseCatalog`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
  findAllByDictNo(dictNo: string): Observable<HttpResponse<any[]>> {
    const dictUrl = '/thsadmin/api/sys-dicts';
    const url = dictUrl + '/findAllByDictNo';
    return this.http.get<any>(`${url}/${dictNo}`, { observe: 'response' });
  }
  queryQuestionOption(queryParams?: any): Observable<HttpResponse<QuestionOption[]>> {
    return this.http.get<QuestionOption[]>(this.questionOptionUrl, { params: queryParams, observe: 'response' });
  }
  queryCourseAdmin(isDelete: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/getCourseByAdmin?isDelete=' + isDelete;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }

  queryCourse(teacherId: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/getCourseByTeacherId?teacherId=' + teacherId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryCatalog(courseId: string): Observable<HttpResponse<any[]>> {
    const url = this.catalogUrl + '/getCatalogByCourseId?courseId=' + courseId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  modifyQuestionInfo(param: any) {
    const url = `${this.resourceUrl}-update`;
    const copy = this.convertDateFromClient(param);
    return this.http.put<any>(url, copy, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
  saveQuestionInfo(param: any) {
    const url = `${this.resourceUrl}-save`;
    const copy = this.convertDateFromClient(param);
    return this.http.post<any>(url, copy, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
  queryCourseCatalogs(queryParams ?: any): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(this.courseCatalogsUrl, {params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }
   // 删除
   deleteQuestionInfo(ids: string) {
    const url = `${this.resourceUrl}-batch/` + ids;
    return this.http.delete<any>(url, {observe: 'response' });
  }
}
