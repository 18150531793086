import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimTeamTearchers } from '../bim-team-teacher.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimTeamTearchersService } from '../bim-team-teacher.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';

@Component({
  selector: 'app-bim-team-teacher-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimTeamTeacherEditComponent implements OnInit {

  public sexList = {'0': '男',  '1' : '女'};
  record: any = {};
  teamId:string;
   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';


  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  schoolName:string;
  teamName:string;

  serialNumber = null;

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimTeamTearchersService: BimTeamTearchersService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      mobile: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
      faculty: [null, []],
      profession:[null, []],
      duties: [null, []],
      email:[null, []],
      sex:['0', []],
      serialNumber:[null, []],
      type:[null, []],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
     this.accountName = tokenService.get().account_name;
 
  }

  ngOnInit() {
    if (!this.isAdd) {
      this.loadData();
    } else {
      this.form.controls['serialNumber'].setValue(this.serialNumber);
    }
  }




  loadData(): void {
       this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查指导老师信息是否完整');
      return;
    }
    
    param["teamId"]  = this.teamId;
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    this.bimTeamTearchersService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('修改成功');
        this.nzModalRef.destroy();
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimTeamTearchersService.create(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('保存成功');
        this.nzModalRef.destroy();
      } else {
        this.msg.error('保存失败');
      }
    });
  }


  Cancel(){
    this.nzModalRef.destroy();
  }
}
