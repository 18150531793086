import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectReportService } from "./../../projectReport/projectReport.service";
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { environment } from '@env/environment';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { Project } from '../../projectReport/projectReport.model';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.html',
  styleUrls: ['./approve.less']
})
export class ApproveComponent implements OnInit {

  validateForm: FormGroup;
  record:any;
  id;
  dataSet = [];
  prjFlowPersonList = [];//项目跟进人
  prjNicheList = [];//项目商机
  fileReportList = [];//申报附件
  downLoadFileAddress;//预览文件地址
  validateForm2: FormGroup;
  
  showUploadListSwitch1 = true;
  showUploadListSwitch2 = true;
  showUploadListSwitch3 = true;

  constructor
    (private fb: FormBuilder, 
      private router: ActivatedRoute,
      private projectReportService:ProjectReportService,
      private thsadminFileService:ThsadminFileService,
      private message: NzMessageService) { 

    this.router.queryParams.subscribe((params) => {
      this.id = params.id;
    });
    this.record = new Project();
    this.downLoadFileAddress = this.projectReportService.downLoadFileAddress;
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({});
    this.validateForm2 = this.fb.group({
      email: [null, [Validators.required]],
      email2: [null, [Validators.required]],
      email3: [null, [Validators.required]],
      password: [null, [Validators.required]],
      nickname: [null, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      website: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
      agree: [false]
    });
    for(const key in this.record){
      this.validateForm.addControl(key,new FormControl(null));
    }
    this.initFormValue();
    this.getProjectFlowPersons();
    this.getProjectNiches();
    this.getFileList();
  }

  initFormValue(){
    this.projectReportService.find(this.id).subscribe(res=>{
      const result = res.body;
      if(result != null){
        this.record = result;
        for(const key in this.record){
          this.validateForm.get(key).setValue(this.record[key]);
        }
      }
    })
  }

  /**
   * 获取项目跟进人
   */
  getProjectFlowPersons(){
    this.projectReportService.findProjectFlowinfos(this.id).subscribe(res => {
      const result = res.body;
      if(result != null && result.length > 0){
        this.prjFlowPersonList = result;
        this.prjFlowPersonList.forEach(item => {
          this.addFormControl(item);
        })
      }else{
        this.addFormControl(null);
      }
    },(errorRes: any)=>{
              
    });
  }
  /**
   * 获取项目商机信息
   */
  getProjectNiches(){
    this.projectReportService.findProjectNicheinfos(this.id).subscribe(res => {
      const result = res.body;
      if(result && result.length > 0){
        this.prjNicheList = result;
        this.prjNicheList.forEach(item => {
          this.addOpportunity(item);
        })
      }
    },(errorRes: any)=>{
              
    });
  }


  /**
   * 添加项目跟进人表单控件
   * @param i 
   */
  addFormControl(item) {
    if(item == null){
      item = {
        id: 1,
        followPersonName:null,
        followPersonTel:null,
        remark:null
      };
    }
    let flowPersonObj = item;

    this.validateForm.addControl('followinfo_'+flowPersonObj.id+'_id', new FormControl(flowPersonObj.id));
    this.validateForm.addControl('followinfo_' + flowPersonObj.id+'_followPersonName', new FormControl(flowPersonObj.followPersonName));
    this.validateForm.addControl('followinfo_'+ flowPersonObj.id+'_followPersonTel', new FormControl(flowPersonObj.followPersonTel));
    this.validateForm.addControl('followinfo_'+flowPersonObj.id+'_remark', new FormControl(flowPersonObj.remark));
    this.validateForm.addControl('followinfo_' + flowPersonObj.id+'_orderNum', new FormControl(flowPersonObj.orderNum));
  }

  addOpportunity(item) {
    if(item == null){
      item = {
        id: 1,
        estimateOrderDate:null,
        estimateOrderAmount:null,
        nicheDetails:null,
        remark:null,
        orderNum:null
      };
    }
    let obj = item;
    this.validateForm.addControl('nicheInfo_' + obj.id+'_id', new FormControl(obj.id, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderDate', new FormControl(obj.estimateOrderDate, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderAmount', new FormControl(obj.estimateOrderAmount,Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_nicheDetails', new FormControl(obj.nicheDetails, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_remark', new FormControl(obj.remark));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_orderNum', new FormControl(obj.orderNum));
 }

 getFileList(){
  let params = {
    "fileFk.equals" : this.id,
    "extField2.equals" : 'IucceProject'
  };
  this.thsadminFileService.queryFiles(params).subscribe(res=>{
    this.fileReportList = res.body;
  })
 }

 
 downFile(file) {
  const a = document.createElement('a');
  const url =  environment.SERVER_URL + this.downLoadFileAddress + file.id;
  const filename = file.fileName;
  a.href = url;
  a.download = filename;
  a.click();
}
  

}
