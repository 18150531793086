import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimSchoolDetails } from './bim-school-details.model';
import { Observable } from 'rxjs-compat/Observable';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class  BimSchoolDetailsService extends ThsBaseService<BimSchoolDetails>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-school-details');
  }

  /**
   * 保存参赛学校信息
   * @param record 
   * @returns 
   */
  saveOrUpdateBimSchoolDetails(record):Observable<HttpResponse<any>> {
    const copy = this.convertDateFromClient(record);
    return this.http.post<any>(this.resourceUrl + '/saveOrUpdateBimSchoolDetails', copy, { observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
}
