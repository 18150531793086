import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent } from '@delon/form';
import { Resource } from "../resource.model";
import { ResourceService } from "../resource.service";
import { map } from 'rxjs/operators';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { PersonnelService } from 'app/routes/admin/organization/personnel/personnel.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';

@Component({
  selector: 'app-edu-resource-edit',
  templateUrl: './edit.component.html',
})
export class EduResourceEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any;
  record: any = {};
  submitting = false;
  formData: any;
  imgUrl = '';
  originName = '';
  type = '';
  resourceType = '';
  // 字典类型 - 树形下拉
  viewType = '1';
  // 字典编码
  dictNo = 'ResourceType';
  schema: SFSchema = {
    properties: {
      name: {
        type: 'string', title: '名称', maxLength: 50
      },
      downloadUrl: {
        type: 'string', title: '下载地址', maxLength: 50
      },
      extractionCode: {
        type: 'string', title: '提取码', maxLength: 50
      },

      remark: {
        type: 'string', title: '备注', maxLength: 100
      },
      content: {
        type: 'string', title: '内容介绍',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      isOk: {
        type: 'boolean', title: '是否有效', default: true
      }
    },
    required: ['name', 'isOk', 'extractionCode', 'downloadUrl', 'content']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };


  queryParams2 = {
    fileFk: '',
  };
  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private resourceService: ResourceService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService
  ) { }

  ngOnInit(): void {
    const _this_ = this;
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.resourceService.find(this.record.id)
        .subscribe(res => {
          this.formData = this.formatRecord(res.body);
          this.type = this.formData.type;
        });
    }
  }
  save(resource?: Resource) {
    console.log(resource);
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
    if (!this.sf.valid) {
      // 手动触发所有检验项的提示信息 无效
      // this.ui['*'].firstVisual = true;
      // this.sf.refreshSchema();
      this.msgSrv.error('表单校验失败，请检查');
      return;
    }
    if (this.resourceType === '') {
      this.msgSrv.error('表单校验失败，请检查');
      return;
    }
    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));
  }

  orgChange(value) {
    console.log(value);
  }

  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }
  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }

  uploadFinish(file: any) {
    if (file && file.success && file.success === true) {
      this.sf.value.imgurl = file.file.id;
    }
  }

  saveResource() {
    let _this_ = this;
    _this_.sf.value.type = this.resourceType;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.resourceService.create(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存资源信息成功');
          });
      } else {
        // if (_this_.imgUrl !== ''){
        //   _this_.sf.value.imgUrl = _this_.imgUrl;
        // }
        // _this_.sf.value.type = this.this.resourceType;
        _this_.resourceService.update(_this_.sf.value)
          .subscribe(res => {
            resolve('更新资源信息成功');
            _this_.imgUrl = ''
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }

  typeChange(obj) {
    this.resourceType = obj;
  }
}
