import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient } from '@angular/common/http';
import { BimInfoCompany } from './bim-info-company.model';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class  BimInfoCompanyService extends ThsBaseService<BimInfoCompany>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-info-companies');
  }
}
