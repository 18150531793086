import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { BimRoutingModule } from './bim-routing.module';
import {BimInfoListComponent} from './bim-info/bim-info-list.component';
import {BimInfoEditComponent} from './bim-info/edit/edit.component';

import {BimItemsListComponent} from './bim-items/bim-items-list.component';
import {BimItemsEditComponent} from './bim-items/edit/edit.component';

import {BimInfoEventsListComponent} from './bim-info/bim-info-events/bim-info-events-list.component';
import {BimInfoEventsEditComponent} from './bim-info/bim-info-events/edit/edit.component';



import {BimInfoQqListComponent} from './bim-info/bim-info-qq/bim-info-qq-list.component';
import {BimInfoQqEditComponent} from './bim-info/bim-info-qq/edit/edit.component';


import {BimInfoAdvertisementListComponent} from './bim-info/bim-info-advertisement/bim-info-advertisement-list.component';
import {BimInfoAdvertisementEditComponent} from './bim-info/bim-info-advertisement/edit/edit.component';
import {BimSchoolsListComponent} from './school/bim-schools-list.component';
import {BimSchoolsEditComponent} from './school/edit/edit.component';

import {BimInfoSchoolsListComponent} from './bim-info/bim-info-schools/bim-info-schools-list.component';
import {BimInfoSchoolsEditComponent} from './bim-info/bim-info-schools/edit/edit.component';
import { BimInfoSchoolsAddProvinceComponent } from './bim-info/bim-info-schools/add-province/add.component';

import {BimTeamsListComponent} from './bim-info/bim-teams/bim-teams-list.component';
import {BimTeamsEditComponent} from './bim-info/bim-teams/edit/edit.component';


import { UtilsModule } from '@shared/utils/utils.module';
import { BimScoringRuleListComponent } from './bim-scoring-rule/bim-scoring-rule-list.component';
import { BimScoringRuleEditComponent } from './bim-scoring-rule/edit/edit.component';
import { BimScoringRuleItemComponent } from './bim-scoring-rule/detail/bim-scoring-rule-item.component';
import { ThsNewsListComponent } from './ths-news/ths-news-list.component';
import { ThsNewsEditComponent } from './ths-news/edit/edit.component';
import { BimCoopCollegeComponent } from './bim-coop-college/bim-coop-college.component';
import { BimCoopCollegeEditComponent } from './bim-coop-college/edit/edit.component';

import {BimTeamTeacherListComponent} from './bim-info/bim-teams/bim-team-teacher/bim-team-teacher-list.component';
import {BimTeamTeacherEditComponent} from './bim-info/bim-teams/bim-team-teacher/edit/edit.component';

import {BimTeamStudentListComponent} from './bim-info/bim-teams/bim-team-student/bim-team-student-list.component';
import {BimTeamStudentEditComponent} from './bim-info/bim-teams/bim-team-student/edit/edit.component';

import { WorkManageComponent } from './work-manage/work-manage.component';
import { ShowTeamUsersComponent } from './work-manage/show-team-users/show-team-users.component';
import { ScoreDetailsComponent } from './work-manage/score-details/score-details.component';
import { SchoolWorkManagerComponent } from './school-work-manager/school-work-manager.component';


import {BimPersonListComponent} from './bim-info/bim-person/bim-person-list.component';
import {BimPersonEditComponent} from './bim-info/bim-person/edit/edit.component';
import {BimPersonProgressComponent} from './bim-info/bim-person/progress/progress.component';
import {BimPersonTeacherListComponent} from './bim-info/bim-person/bim-person-teacher/bim-person-teacher-list.component';
import {BimPersonTeacherEditComponent} from './bim-info/bim-person/bim-person-teacher/edit/edit.component';
import {BimPersonResultListComponent} from './bim-info/bim-person/bim-person-result/bim-person-result.component';
import {BimPersonResultEditComponent} from './bim-info/bim-person/bim-person-result/edit/edit.component';
import {BimPersonResultEditShowInfoComponent} from './bim-info/bim-person/bim-person-result/edit/show-info/show-info.component';
import { SupplementaryWorksComponent } from './work-manage/supplementary-works/supplementary-works.component';
import { ProjectUploadComponent } from './work-manage/supplementary-works/project-upload/project-upload.component';
import { BimAdvisorEditComponent } from './bim-advisor/edit/edit.component';
import { BimAdvisorComponent } from './bim-advisor/bim-advisor.component';

import {BimAdvisorWorkComponent} from './bim-advisor/bim-advisor-work/bim-advisor-work.component';
import {BimAdvisorWorkEditComponent} from './bim-advisor/bim-advisor-work/edit/edit.component';


import {BimWorkAdvisorComponent} from './work-manage/work-advisor/bim-work-advisor.component';
import {BimWorkAdvisorEditComponent} from './work-manage/work-advisor/edit/edit.component';


import { BimWorkCoresComponent } from './bim-work-cores/bim-work-cores.component';

import {BimLearningClassRoomComponent} from './bim-learning-classroom/bim-learning-classroom.component';
import {BimLearningClassRoomEditComponent} from './bim-learning-classroom/edit/edit.component';


import {BimReceiptInfoListComponent} from './bim-info/bim-receipt-info/bim-receipt-info-list.component';
import {ReceiptUploadComponent} from './bim-info/bim-receipt-info/edit/receipt-upload.component';
import { LeaderEditComponent } from './bim-info/bim-receipt-info/leader-edit/leader-edit.component';
import {StudentEditComponent} from './bim-info/bim-receipt-info/student-edit/student-edit.component';
import {TeacherEditComponent} from './bim-info/bim-receipt-info/teacher-edit/teacher-edit.component';
import { ReceiptUploadFileComponent } from './bim-info/bim-receipt-info/edit/receipt-upload-file/receipt-upload-file.component';
import { QRCodeModule } from 'angular2-qrcode';
import { BimInfoEventsMutexComponent } from './bim-info/bim-info-events/bim-info-events-mutex/bim-info-events-mutex.component';

import { EduLiveBroadcastsListComponent } from './edu-live-broadcasts/edu-live-broadcasts.component';

import { EduLiveBroadcastsEditComponent } from './edu-live-broadcasts/edit/edit.component';

import {BimPersonResultSetPassRateComponent} from './bim-info/bim-person/bim-person-result/set-pass-rate/set-pass-rate.component';
import {BimPersonResultSendMessageComponent} from './bim-info/bim-person/bim-person-result/send-message/send-message.component';
import { BimInfoProcessListComponent } from './bim-info/bim-info-process/bim-info-process-list.component';
import { BimInfoProcessEditComponent } from './bim-info/bim-info-process/edit/edit.component';
import { BimInfoProcessNodeComponent } from './bim-info/bim-info-process/bim-info-process-node/bim-info-process-node.component';
import { WorkReplyManageComponent } from './work-reply-manage/work-reply-manage.component';
import { WorkReplyDistributeComponent } from './work-reply-manage/distribute/distribute.component';
import { WorkReplyAddComponent } from './work-reply-manage/add/add.component';
import { BimAdvisorReplyWorkComponent } from './bim-advisor-reply/bim-advisor-work/bim-advisor-reply-work.component';
import { BimAdvisorWorkReplyEditComponent } from './bim-advisor-reply/bim-advisor-work/edit/edit.component';
import { BimAdvisoReplyEditComponent } from './bim-advisor-reply/edit/edit.component';
import { BimAdvisorReplyComponent } from './bim-advisor-reply/bim-advisor-reply.component';
import { BimTeamResultListComponent } from './bim-info/bim-teams/bim-team-result/bim-team-result.component';
import { BimTeamResultEditComponent } from './bim-info/bim-teams/bim-team-result/edit/edit.component';
import { BimTeamResultEditShowInfoComponent } from './bim-info/bim-teams/bim-team-result/edit/show-info/show-info.component';
import { BimInfoInstitutionalComponent } from './bim-info/bim-info-institutional/bim-info-institutional.component';
import { BimInfoInstitutionalEditComponent } from './bim-info/bim-info-institutional/edit/edit.component';
import { BimWorkShowComponent } from './bim-work-show/bim-work-show.component';
import { BimWorkAddComponent } from './bim-work-show/bim-work-add/bim-work-add.component';
import { WorkUploadComponent } from './bim-work-show/bim-work-add/work-upload/work-upload.component';
import { BimCompetitionMienComponent } from './bim-competition-mien/bim-competition-mien.component';
import { BimCompetitionMienEditComponent } from './bim-competition-mien/edit/edit.component';
import { BimInfoContactComponent } from './bim-info/bim-info-contact/bim-info-contact.component';
import { BimInfoContactEditComponent } from './bim-info/bim-info-contact/edit/edit.component';
import { WorkReplyFileManageComponent } from './work-reply-file-manage/work-reply-file-manage.component';
import { WorkReplyFileAddComponent } from './work-reply-file-manage/add/add.component';
import { BimInfoLinkEditComponent } from './bim-info/bim-info-contact/edit-link/edit.component';
import { GroupNumListComponent } from './work-reply-file-manage/group-num-list/group-num-list.component';
import { AddGroupComponent } from './work-reply-file-manage/group-num-list/add-group/add-group.component';
import { MIN_LENGTH_VALIDATOR } from '@angular/forms/src/directives/validators';
import { ReplyFileSuppleComponent } from './work-reply-file-manage/reply-file-supple/reply-file-supple.component';
import { ReplyUploadComponent } from './work-reply-file-manage/reply-file-supple/reply-upload/reply-upload.component';
import { ExportProgressComponent } from './components/export-progress/export-progress.component';
import { PersonResultEditComponent } from './bim-info/bim-person/bim-person-result/edit-result/edit-result.component';
import { BimInfoSoftComponent } from './bim-info/bim-info-soft/bim-info-soft.component';
import { BimInfoSoftTypeComponent } from './bim-info/bim-info-soft/soft-type/bim-soft-type.component';
import { BimSoftTypeEditComponent } from './bim-info/bim-info-soft/soft-type/edit/edit.component';
import { SoftTypeNodeComponent } from './bim-info/bim-info-soft/soft-type/soft-type-node/soft-type-node.component';
import { BimInfoSoftEditComponent } from './bim-info/bim-info-soft/edit/edit.component';
import { BimInfoSoftDownComponent } from './bim-info/bim-info-soft/soft-download/edit.component';
import { SyncSoftDataComponent } from './bim-info/bim-info-soft/sync-data/sync-soft-data.component';
const COMPONENTS = [
  BimInfoListComponent,
  BimItemsListComponent,
  BimInfoEventsListComponent,
  BimInfoQqListComponent,
  BimInfoAdvertisementListComponent,
  BimScoringRuleListComponent,
  BimSchoolsListComponent,
  ThsNewsListComponent,
  BimCoopCollegeComponent,
  BimInfoSchoolsListComponent,
  BimTeamsListComponent,
  BimTeamTeacherListComponent,
  BimTeamStudentListComponent,
  WorkManageComponent,
  SchoolWorkManagerComponent,
  BimPersonListComponent,
  BimPersonTeacherListComponent,
  BimPersonResultListComponent,
  BimPersonResultEditShowInfoComponent,
  ProjectUploadComponent,
  BimAdvisorComponent,
  BimWorkCoresComponent,
  BimLearningClassRoomComponent,
  BimReceiptInfoListComponent,
  EduLiveBroadcastsListComponent,
  BimInfoProcessListComponent,
  WorkReplyManageComponent,
  BimAdvisorReplyComponent,
  BimTeamResultListComponent,
  BimTeamResultEditComponent,
  BimInfoInstitutionalComponent,
  BimInfoContactComponent,
  BimWorkShowComponent,
  BimCompetitionMienComponent,
  WorkReplyFileManageComponent,
  ExportProgressComponent,
  BimInfoSoftComponent,
];
const COMPONENTS_NOROUNT = [
    BimInfoEditComponent,
    BimItemsEditComponent,
    BimInfoEventsEditComponent,
    BimInfoQqEditComponent,
    BimInfoAdvertisementEditComponent,
    BimScoringRuleEditComponent,
    BimSchoolsEditComponent,
    BimScoringRuleItemComponent,
    ThsNewsEditComponent,
    BimCoopCollegeEditComponent,
    BimInfoSchoolsEditComponent,
    BimInfoSchoolsAddProvinceComponent,
    BimTeamsEditComponent,
    BimTeamTeacherEditComponent,
    BimTeamStudentEditComponent,
    ShowTeamUsersComponent,
    ScoreDetailsComponent,
    BimPersonEditComponent,
    BimPersonTeacherEditComponent,
    BimPersonResultEditComponent,
    SupplementaryWorksComponent,
    BimAdvisorEditComponent,
    BimAdvisorWorkComponent,
    BimAdvisorWorkEditComponent,
    BimWorkAdvisorComponent,
    BimWorkAdvisorEditComponent,
    BimLearningClassRoomEditComponent,
    ReceiptUploadComponent,
    StudentEditComponent,
    LeaderEditComponent,
    TeacherEditComponent,
    ReceiptUploadFileComponent,
    BimInfoEventsMutexComponent,
    BimPersonProgressComponent,
    EduLiveBroadcastsEditComponent,
    BimPersonResultSetPassRateComponent,
    BimPersonResultSendMessageComponent,
    BimInfoProcessEditComponent,
    BimInfoProcessNodeComponent,
    WorkReplyDistributeComponent,
    WorkReplyAddComponent,
    BimAdvisoReplyEditComponent,
    BimAdvisorReplyWorkComponent,
    BimAdvisorWorkReplyEditComponent,
    BimTeamResultEditShowInfoComponent,
    BimInfoInstitutionalEditComponent,
    BimInfoContactEditComponent,
    BimInfoLinkEditComponent,
    BimWorkAddComponent,
    WorkUploadComponent,
    BimCompetitionMienEditComponent,
    WorkReplyFileAddComponent,
    GroupNumListComponent,
    AddGroupComponent,
    ReplyFileSuppleComponent,
    ReplyUploadComponent,
    ExportProgressComponent,
    PersonResultEditComponent,
    BimInfoSoftTypeComponent,
    BimSoftTypeEditComponent,
    SoftTypeNodeComponent,
    BimInfoSoftEditComponent,
    BimInfoSoftDownComponent,
    SyncSoftDataComponent,
];


@NgModule({
  imports: [
    SharedModule,
    UtilsModule,
    BimRoutingModule,
    QRCodeModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  providers: [

  ],

  entryComponents: COMPONENTS_NOROUNT

})
export class BimModule { }
