import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndustryUniversityComponent } from './industry-university/industry-university';
import { DeclareComponent } from './declare/declare';
import { ApproveComponent } from './approve/approve';
// import { ViewItemComponent } from './view-item/view-item';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'IndustryUniversity', component: IndustryUniversityComponent },
      /** 申报 */
      { path: 'declare', component: DeclareComponent },
      /** 审批 */
      { path: 'approve', component: ApproveComponent },
      /** 查看项目 */
      // { path: 'viewItem', component: ViewItemComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndustryRoutingModule { }
