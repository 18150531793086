import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-bim-bimTeam-result-edit-showInfo',
  templateUrl: './show-info.component.html',

})
export class BimTeamResultEditShowInfoComponent implements OnInit {

  message:string;
  messageList:any;
 constructor(
        private nzModalRef: NzModalRef,
    ) {
   
  }

  ngOnInit(): void {
    // this.getMessage();
  }

  getMessage(){
    this.message = "";
    if(this.messageList === null || this.messageList === undefined || this.messageList.length<=0)
    {
        return false;
    }

    this.messageList.forEach(item=>{
        this.message = this.message + " 第 "+ item.rowNum + "行、" + item.msg + "<br/>" ;
    });

  }

  close() {
    this.nzModalRef.destroy();
  }

  

}
