import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Project } from './../projectReport/projectReport.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectNicheService extends ThsBaseService<Project> {
    projectResouceUrl = "";
    projectNicheFollowResouceUrl = "";//项目跟进详情url
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.IucceServe + `/iucce-project-niches`);
        this.projectResouceUrl = httpAddressService.IucceServe + `/iucce-projects`;
        this.projectNicheFollowResouceUrl = httpAddressService.IucceServe + `/iucce-project-niche-follows`;
    }
    

    /** GET: 获取商机跟进中的项目信息 */
    getAllIucceProjectNicheByPage (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.projectResouceUrl+`/getAllIucceProjectNicheByPage`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 获取商机跟进详情数据 */
    queryProjectNicheFollowDatas(params:any):Observable<HttpResponse<any[]>> {
        params.sort = ['year,desc','month,desc'];
        return this.http.get<any[]>(this.projectNicheFollowResouceUrl, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }
    
    /** GET: 获取商机跟进详情数据 */
    getAllIucceProjectNicheFollowDatas(params:any):Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(this.projectNicheFollowResouceUrl+`/getAllIucceProjectNicheFollowDatas`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }
    
    /** DELETE: 删除商机信息 */
    deleteByNicheId(record:  string | string[],projectId): Observable<HttpResponse<any>> {
        let id;
        if(record instanceof Array){
            id =  record.join(",") ;
        }else{
            id = record;
        }
        const url = `${this.resourceUrl}/deleteNicheById/${id}`;

        return this.http.delete<HttpResponse<any>>(url, { params:{ projectId:projectId },observe: 'response'});
    }

    
    /** DELETE: 删除商机跟进信息 */
    deleteNicheFollowById(record:  string | string[],nicheId,projectId): Observable<HttpResponse<any>> {
        let id;
        if(record instanceof Array){
            id =  record.join(",") ;
        }else{
            id = record;
        }
        const url = `${this.resourceUrl}/deleteNicheFollowById/${nicheId}/${id}`;

        return this.http.delete<HttpResponse<any>>(url, { params:{ projectId:projectId },observe: 'response'});
    }
}

