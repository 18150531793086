import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NzMessageService, NzModalRef } from "ng-zorro-antd";
import { BimSoftDownloadTypeService } from "../bim-soft-type.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-bim-soft-type-edit',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimSoftTypeEditComponent implements OnInit {

  form: FormGroup;

  record: any = {};

  // 是否新增
  isAdd: boolean;

  public isSaving = false;

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimSoftDownloadTypeService: BimSoftDownloadTypeService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [null, []],
      name: [null, [Validators.required, Validators.maxLength(20)]],
      parentId: [null, []],
      isAble: ['1', []],
      orderNum: [1, []],
    });
  }
  ngOnInit(): void {
    this.form.patchValue(this.record);
  }

  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    this.checkData(subParam);
  }

  checkData(param) {
    this.isSaving = true;
    this.bimSoftDownloadTypeService.query({
      'bimInfoId.equals': param['bimInfoId'],
      'name.equals': param['name'],
      'parentId.specified': false,
    }).subscribe(res => {
      const data = res.body;
      let isRepeat = false; 
      if (data.length > 0) {
        if (!this.isAdd) {
          if (param['id'] != data[0].id) {
            isRepeat = true;
          }
        } else {
          isRepeat = true;
        }
      }
      if (isRepeat) {
        this.isSaving = false;
        this.msg.remove();
        this.msg.warning('该软件分类已存在，请修改名称！');
      } else {
        this.saveData(param);
      }
    });
  }

  saveData(subParam) {
    if (this.isAdd) {
      this.isSaving = true;
      this.bimSoftDownloadTypeService.create(subParam).subscribe(res => {
        this.msg.info('保存成功');
        this.isSaving = false;
        this.close(true);
      }, () => this.isSaving = false);
    } else {
      this.isSaving = true;
      this.bimSoftDownloadTypeService.update(subParam).subscribe(res => {
        this.msg.info('保存成功');
        this.isSaving = false;
        this.close(true);
      }, () => this.isSaving = false);
    }
  }

  close(type?) {
    this.nzModalRef.destroy(type);
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

}