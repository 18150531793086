export const WFAuditResult: {[key: string]: {key: string, value: string}} = {
  wfAllow: {
    key: '同意',
    value: '1'
  }, // 同意
  wfReject: {
    key: '不同意',
    value: '2'
  }, // 驳回
  wfTransmit: {
    key: '转办',
    value: '3'
  }, // 转办
  wfAddSign: {
    key: '加签',
    value: '4'
  }, // 加签
  wfRevoke: {
    key: '撤回',
    value: '5'
  }, // 撤回
  wfJump: {
    key: '跳转',
    value: '6'
  }, // 跳转
  wfEndProcess: {
    key: '终止',
    value: '7'
  }, // 终止
};