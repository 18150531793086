import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import {ThsBaseService } from  '../../../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../../../shared/session/http-address.service';
import { BimPersonResult } from './bim-person-result.model';
import { Identifiers } from '@angular/compiler';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimPersonResultService extends ThsBaseService<BimPersonResult> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-person-results`);
    }



    // exportExcel(queryParams ?: any) {
    //     const url = `${this.resourceUrl}/exportPersonCert`;
    
    //     //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    //     this.http.get(url ,  {
    //       params: queryParams ,
    //       headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }),
    //       observe: 'response',
    //       responseType: 'blob',
    //       withCredentials: true,
    //     }).subscribe(
    //       data => {
    //         let blob = new Blob([data.body]);
    //         let objectUrl = URL.createObjectURL(blob);
    //         let a = document.createElement('a');
    //         document.body.appendChild(a);
    //         a.setAttribute('style', 'display:none');
    //         a.setAttribute('href', objectUrl);
    //         a.setAttribute('download',  '个人获奖证书' + '.pdf');
    //         a.click();
    //         URL.revokeObjectURL(objectUrl);
    //       },
    //       error => {
    //         this.handleError('下载失败,请稍后重试!');
    //         console.error('个人获奖证书下载失败 ->', error);
    //       },
    //     );
    
    //   }


      exportCertToPdf(queryParams: any, fileName: string) {
        const url = `${this.resourceUrl}/exportPersonCert`;
    
        //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
        this.http.get(url ,  {
          params: queryParams ,
          headers: new HttpHeaders({ 'Content-Type': 'application/pdf' }),
          observe: 'response',
          responseType: 'blob',
          withCredentials: true,
        }).subscribe(
          data => {
            let blob = new Blob([data.body]);
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', objectUrl);
            a.setAttribute('download', fileName + '-个人获奖证书' + '.pdf');
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error => {
            this.handleError('下载失败,请稍后重试!');
            console.error('个人获奖证书下载失败 ->', error);
          },
        );
    
      }


    getPersonResultDataList(param: any) {
        return this.http.get<any[]>(`${this.resourceUrl}/getPersonResultDataList`, {params: param ,  observe: 'response'})
            .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
      }



      sendMessageByPersonId(personId:String, personResultId:String) {
        let url = `${this.resourceUrl}/sendMessageByPersonId/${personId}/${personResultId}`;
        return this.http.get<any>(url, { observe: 'response'});
      }
  

      sendMessage(bimInfoEventsId:String, personResultId:any[]) {
        return this.http.post<any>(this.resourceUrl + '/sendMessage/' + bimInfoEventsId, personResultId, { observe: 'response'});
      }


      /**自动计算合格者名单 */
      getPassResult(eventId:String){
        let url = `${this.resourceUrl}/getPassResult/${eventId}`;
        return this.http.get<any>(url, {observe: 'response'});
      }  



          /** DELETE: 根据eventId来删除记录 */
          deleteAllByEventId (eventId:  string): Observable<HttpResponse<any>> {
             const url = `${this.resourceUrl}/deleteAllByEventId/${eventId}`;
              return this.http.delete<HttpResponse<any>>(url, { observe: 'response'});
    }


        /** DELETE: 根据eventId、itemId来删除记录 */
        deleteAllByEventIdAndItemId (eventId:  string, itemId: string): Observable<HttpResponse<any>> {
            const url = `${this.resourceUrl}/deleteAllByEventIdAndItemId/${eventId}/${itemId}`;
   
            return this.http.delete<HttpResponse<any>>(url, { observe: 'response'});
        }


        /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }


}

