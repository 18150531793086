/*
 * @Author: yuanmh ;
 * @Date: 2018-08-19 09:36:19 ;
 * @Last Modified by: yuanmh
 * @Last Modified time: 2018-09-27 16:26:00
 */
import swal from 'sweetalert';

export class  MessageHelper {
  /**
   * 成功弹窗提示
   * @param content  内容
   * @param title 标题
   */
  static success(content: string, title?: string): any {
    swal({
      title: title,
      text: content,
      icon: 'success',
      button: '确定',
    });
  }

  /**
   * 信息弹出提示
   * @param content 内容
   * @param title 标题
   */
  static info(content: string, title: string): any {
    swal({
      title: title,
      text: content,
      icon: 'info',
      button: '确定',
    });
  }

  /**
   * 警告弹窗提示
   * @param content 内容
   * @param title 标题
   */
  static warn(content: string, title?: string): any {
    swal({
      title: title,
      text: content,
      icon: 'warning',
      button: '确定',
    });
  }

  /**
   * 错误弹窗提示
   * @param content 内容
   * @param title 标题
   */
  static error(content: string, title?: string): any {
    swal({
      title: title,
      text: content,
      icon: 'error',
      dangerMode: true,
      button: '确定',
    });
  }

  /**
   * 确认框
   * @param content 内容
   * @param title 标题
   * @param confirm 回调函数 确认返回：true  失败返回：false
   */
  static confirm(content: string, title?: string, confirm?: ( isConfirmed: boolean) => void): any {
    swal({
      title: title,
      text: content,
      icon: 'warning',
      dangerMode: true,
      buttons: [
      {
        text: '取消',
        value: false,
        visible: true,
        className: '',
        closeModal: true,
      },
      {
        text: '确定',
        value: true,
        visible: true,
        className: '',
        closeModal: true
      }
      ],
    }).then( (result) => {
      if (confirm) {
        confirm(result);
      }
    });
  }
}
