import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import {  _HttpClient } from '@delon/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpParams, HttpResponse, HttpRequest } from '@angular/common/http';
import { CertRegedistManageService } from '../certRegedistManage.service';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { formatDate } from '@angular/common';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-certRegedistMan-edit',
  templateUrl: './edit.component.html',
})
export class EduCertRegedistManageEditComponent implements OnInit {
  form: FormGroup;
  record: any = {};
  submitting = false;
  resourceType = '';

  // 字典类型 - 树形下拉
  viewType = '1';
  // 字典编码
  dictNo = 'BIMAutodeskAuth';

  authbool = false;
  examineSubject = '';
  authProduct = '';
  authGrade = '';
  channelId = '';
  examineTimeStr = null;
  type = '';
  certChannelType = [];
  examineSubjectArr = [];
  authProductArr = [];
  authGradeArr = [];
  constructor(
    private modal: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private certRegedistManageService: CertRegedistManageService,
    private dictDetailService: DictDetailService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      eduCertChannelId: [null, []],
      examBatch: [null, []],
      eduUserId: [null, []],
      name: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      birthday: [null, []],
      nameSpell: [null, [Validators.required]],
      idCard: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      profession: [null, [Validators.required]],
      email: [null, [Validators.required]],
      education: [null, [Validators.required]],
      examineSubject: [null, []],
      authProduct: [null, []],
      authGrade: [null, []],
      address: [null, [Validators.required]],
      examineTime: [null, []],
      examineType: [null, []],
      chargeType: [null, []],
      chargeAmount: [null, []],
      isCharge: [null, []],
      isPass: [null, []],
      isOk: [null, []],
    });
    this.getCertChannelType();
    if (this.record.id != null && this.record.id !== '') {
      this.certRegedistManageService.find(this.record.id).subscribe(res => {
        this.record = res.body;
        this.authGrade = res.body.authGrade;
        const examineTime = res.body.examineTime;
        this.authProduct = res.body.authProduct;
        this.examineSubject = res.body.examineSubject ;
        /* if (!!examineTime) {
          this.examineTimeStr = formatDate(examineTime, 'yyyy-MM', 'zh-Hans');
        }
        this.channelId = res.body.eduCertChannelId;
        if (!!this.channelId && !!this.examineSubject) {
         this.findSubjectByChannelId( this.examineSubject );
        }
        if (!!examineTime) {
         this.findProductByChannelIdAndSubject(this.examineSubject , this.authProduct);
        }
       if (!!this.channelId && !!this.authProduct) {
          this.findGradeByChannelIdAndSubjectAndProduct(this.examineSubject, this.authProduct , this.authGrade);
        } */
        this.authbool = true;
        this.form = this.createGroup();
      });
    }
  }

  createGroup() {
    const group = this.fb.group({});
    // tslint:disable-next-line:forin
    for (const key in this.record) {
      group.addControl(key, this.fb.control(this.record[key]));
    }
    return group;
  }

  getCertChannelType() {
    this.certRegedistManageService.findAllChannel().subscribe(res => {
      const list =  res.body;
          if (list && list.length) {
            list.forEach(element => {
              this.certChannelType.push({ label: element.title, value: element.id });
            });
      }
    });
    return this.certChannelType;
  }

  findSubjectByChannelId( examineSubject: string) {
    this.certRegedistManageService.findByChannelIdAndIsOk(this.channelId,  this.examineTimeStr,
          examineSubject, '', '').subscribe(res => {
      const list =  res.body;
          if (list && list.length) {
            list.forEach(element => {
              console.log('element' + element);
              this.examineSubjectArr.push({ label: element, value: element });
            });
      }
    });
    return  this.examineSubjectArr;
  }

  findProductByChannelIdAndSubject( subject: string, product: string) {
    this.certRegedistManageService.findByChannelIdAndIsOk(this.channelId,  this.examineTimeStr,
          subject, product, '').subscribe(res => {
      const list =  res.body;
      console.log(res.body);
          if (list && list.length) {
            list.forEach(element => {
              this.authProductArr.push({ label: element, value: element});
            });
      }
    });
    return  this.authProductArr;
  }
  findGradeByChannelIdAndSubjectAndProduct( subject: string, authProduct: string, authGrade: string) {
    this.certRegedistManageService.findByChannelIdAndIsOk(this.channelId,  this.examineTimeStr,
          subject, authProduct, authGrade).subscribe(res => {
      const list =  res.body;
          if (list && list.length) {
            list.forEach(element => {
              this.authGradeArr.push({ label: element, value: element });
            });
      }
    });
    return  this.authGradeArr;
  }

  submit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    /* const authProduct = this.form.value.authProduct;
    if (!authProduct) {
      this.msg.error('认证科目/产品未选择，请检查');
      return ;
    }
    const authGrade = this.form.value.authGrade;
    if (!authGrade) {
      this.msg.error('认证等级未选择，请检查');
      return ;
    }

    if (!this.authbool) {
      this.msg.error('考试类型未选择至等级，请检查');
      return ;
    }
    this.form.value.examineSubject = this.examineSubject;
    this.form.value.authProduct = this.authProduct;
    this.form.value.authGrade = this.authGrade;
    this.form.value.authId = this.resourceType ; */
    this.record = Object.assign(this.record, this.form.value);

    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));

  }
  saveResource() {
    const _this_ = this;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
       if (_this_.form.value.id == null) {
        _this_.certRegedistManageService.create(_this_.form.value)
          .subscribe(
            (response) => _this_.onSaveSuccess(),
            (res: HttpResponse<any>) => _this_.onError(res.body)
            );
      } else {
        _this_.certRegedistManageService.update(_this_.form.value)
          .subscribe((response) => _this_.onSaveSuccess(),
          (res: HttpResponse<any>) => _this_.onError(res.body)
          );
      }
    });
  }

  formatIsOk() {
    if (this.form.value.isOk === true) {
      this.form.value.isOk = '1';
    } else if (this.form.value.isOk === false) {
      this.form.value.isOk = '0';
    }
  }
   /**
   * 保存成功
   * @param response
   */
   onSaveSuccess() {
    this.msg.success('保存成功');
    this.submitting = false;
    this.close(true);
  }
 /**
   * 请求异常
   * @param error
   */
   onError(error) {
    this.msg.create('error', error.message);
    this.submitting = false;
  }

  /**
   * 关闭窗口
   * @param result 传递参数
   */
  close(result: any) {
    this.modal.destroy(result);
  }


  subjectChange(value: string) {
    this.examineSubject = value;
    this.authProductArr = [];
    this.findProductByChannelIdAndSubject(this.examineSubject, this.authProduct);
  }


}
