import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn } from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { EduCourseCatalogAuditEditComponent } from "./edit/courseCatalogAudit.component";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { CourseAuditService } from "./courseAudit.service";
import { CourseCatalogService } from "../courseCatalog/courseCatalog.service";
import { Course } from "../course/course.model";
import { APP_PERMISSIONS } from "@shared/app.permissions";
@Component({
  selector: 'app-course-audit',
  templateUrl: './courseAudit.component.html',
})
export class EduCourseAuditComponent implements OnInit {
  public pageNoAudit: number = 0;
  public sizeNoAudit: number = 10;
  public totalNoAudit: number;
  public pageAudit: number = 0;
  public sizeAudit: number = 10;
  public totalAudit: number;

  public APP_PERMISSIONS = APP_PERMISSIONS;
  public listNoAudit: Course[] = [];
  dataListNoAudit: any[] = [];

  public listAudit: Course[] = [];
  dataListAudit: any[] = [];

  public totalCallNo: number = 0;
  public selectedRows: SimpleTableData[] = [];

  //HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;
  @ViewChild('st1') st1: SimpleTableComponent;
  //未审核
  queryParamsNoAudit = {
    //查询搜索框的字段要预置
    'courseName': '',
    'name': '',
    //sort固定
    sort: ['upload_time,desc']
  };

  //已审核
  queryParamsAudit = {
    //查询搜索框的字段要预置
    'courseName': '',
    'name': '',

    //sort固定
    sort: ['eca.audit_time,desc', 'upload_time,desc']
  };



  columns: SimpleTableColumn[] = [
    { title: '', index: "id", type: 'checkbox' },
    { title: '课程名称', index: 'courseName', width: '20%' },
    { title: '目录名称', index: 'name', width: '20%' },
    // { title: '截图', index: 'imgurl' },
    {
      title: '是否试看', index: 'isTrial', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.isTrial === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否发布', index: 'isPublish', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.isPublish === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '上传时间', index: 'uploadTime', width: '12%' },
    { title: '备注', index: 'remark', width: '13%' },
    {
      title: '操作', className: 'text-center',
      buttons: [
        // { acl: true, text: '查看', type: 'modal', component: AdminUsersEditComponent, paramName: 'record', click: () => this.getDataList() },
        { text: '审核', type: 'modal', component: EduCourseCatalogAuditEditComponent, paramName: 'record', click: () => this.getNoAuditDataList(), }

        /*  { text: '课件查看', type: 'modal', component: CourseFileComponent, paramName: 'record', click: () => this.getNoAuditDataList(), },  */
      ],
    }
  ];

  columnsNoAudit: SimpleTableColumn[] = [
    { title: '', index: "id", type: 'checkbox' },
    { title: '课程名称', index: 'courseName', width: '20%' },
    { title: '目录名称', index: 'name', width: '20%' },
    // { title: '截图', index: 'imgurl' },
    {
      title: '是否试看', index: 'isTrial', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.isTrial === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否发布', index: 'isPublish', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.isPublish === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '上传时间', index: 'uploadTime', width: '8%' },
    {
      title: '审核结果', index: 'auditResult', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.auditResult === '1') {
          return '通过';
        } else {
          return '不通过';
        }
      },
      filters: [
        { text: '通过', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '不通过', value: 0 }  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '审核时间', index: 'auditTime', width: '10%' },
    /* { title: '审核次数', index: 'auditNumber', width: '5%' }, */

    {
      title: '操作', className: 'text-center',
      buttons: [
        { text: '查看', type: 'modal', component: EduCourseCatalogAuditEditComponent, paramName: 'record', click: () => this.getNoAuditDataList(), }
        // { acl: true, text: '查看', type: 'modal', component: AdminUsersEditComponent, paramName: 'record', click: () => this.getDataList() },
        /*   { text: '编辑', type: 'modal', component: EduCourseCatalogEditComponent, paramName: 'record', click: () => this.getDataList(), },
          { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item) } },
          { text: '课件查看', type: 'modal', component: CourseFileComponent, paramName: 'record', click: () => this.getDataList(), }, */
      ],
    }
  ];


  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public courseAuditService: CourseAuditService,
    public courseCatalogService: CourseCatalogService
  ) { }

  ngOnInit() {
    this.getNoAuditDataList();
    this.getAuditDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getNoAuditDataList(isReset?: boolean) {
    let copyParams = {};
    const q = this.queryParamsNoAudit;
    if (isReset === true) {
      this.st.reset();
      this.pageNoAudit = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    };
    copyParams['page'] = this.pageNoAudit;
    copyParams['size'] = this.sizeNoAudit;
    copyParams['isAudit'] = '0';
    console.log(copyParams);
    this.courseAuditService.queryCourseCatalog(copyParams)
      .subscribe((res: any) => {
        this.dataListNoAudit = res.body.data;
        this.totalNoAudit = res.headers.get('X-Total-Count');
      });
  }

  public getAuditDataList(isReset?: boolean) {
    let copyParams = {};
    const q = this.queryParamsAudit;
    if (isReset === true) {
      this.st1.reset();
      this.pageAudit = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    };
    copyParams['page'] = this.pageAudit;
    copyParams['size'] = this.sizeAudit;
    copyParams['isAudit'] = '1';
    this.courseAuditService.queryCourseCatalog(copyParams)
      .subscribe((res: any) => {
        this.dataListAudit = res.body.data;
        this.totalAudit = res.headers.get('X-Total-Count');
      });
  }




  /**
  * 页码数量变动事件
  * @param 
  */
  paginationChange(event) {
    this.pageAudit = event.pi - 1;
    this.sizeAudit = event.ps;
    this.getAuditDataList();
  }

  /**
   * 未审核页码数量变动事件
   * @param 
   */
  paginationChangeNoAudit(event) {
    this.pageNoAudit = event.pi - 1;
    this.sizeNoAudit = event.ps;
    this.getNoAuditDataList();
  }


  /**
  * 过滤器变动事件 支持多选过滤
  * @param 
  */
  filterChange(event) {
    let i = 0;
    let _value = [];
    let _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParamsNoAudit[event.indexKey + '.' + _type] = _value;
    this.getNoAuditDataList();
  }

  /**
  * 排序变动事件
  * @param 
  */
  sortChange(event) {
    let array = this.queryParamsNoAudit['sort'];
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value == 'descend') {
      value = 'desc';
    } else if (event.value == 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + "," + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + "," + value);
    }
    //排序改变时，simpleTable会重置页码
    this.pageNoAudit = 0;
    this.getNoAuditDataList();
  }




  formatIsOk(item: any) {
    debugger
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }


  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



  /**
  * 删除
  */
  remove(res: any) {
    if (res.userName == 'admin') {
      this.msg.info("管理员账号不能删除");
      return;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.courseCatalogService.delete(res.id)
          .subscribe(() => {
            this.getNoAuditDataList();
            this.getAuditDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

}
