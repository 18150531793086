import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ACLGuard } from '@delon/acl';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { EduCoursePayComponent } from './course-pay/course-pay.component';
import { EduCertPayComponent } from './cert-pay/cert-pay.component';



const routes: Routes = [
  {
    path: '',
    children: [
       { path: 'order/course', component: EduCoursePayComponent },
       { path: 'order/cert',  component: EduCertPayComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EduOrderRoutingModule { }
