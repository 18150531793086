import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent } from '@delon/form';
import { User } from '../user.model';
import { UserService } from '../user.service';
import { map } from 'rxjs/operators';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { PersonnelService } from 'app/routes/admin/organization/personnel/personnel.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { deepCopy } from '@delon/util';
import {Md5} from 'ts-md5';


@Component({
  selector: 'app-edu-user-edit',
  templateUrl: './edit.component.html',
})
export class EduUserEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any;
  record: any = {};
  oldPassword = '';
  oldIsOk = '';
  submitting = false;
  formData: any;
  // allRoles:any[] = [];
  // selectedRoles =['1'];
  originName = '';
  schema: SFSchema = {
    properties: {
      userAccount: {
        type: 'string', title: '账号', maxLength: 50,
        ui: {
          /*  validator: (value: any) => value === null || value === '' || value === ' '
             || value === this.originName ? [] : this.userService.hasUserAccount(value).pipe(
               map(res => !res ? [{ keyword: 'required', message: '账号名已存在' }] : [])
             ) */
        }
      },
      userPassword: {
        type: 'string', title: '密码', maxLength: 50
        , ui: {
         type: 'password'
        }
      },
      personName: {
        type: 'string', title: '姓名', maxLength: 50
      },
      email: {
        type: 'string', title: '邮箱', maxLength: 100, format: 'email'
      },
      mobile: {
        type: 'string', title: '手机', maxLength: 100, format: 'mobile'
      },
      college: {
        type: 'string', title: '学校', maxLength: 50
      },
      department: {
        type: 'string', title: '院系', maxLength: 50
      },
      isOk: {
        type: 'string', title: '是否有效', default: '1',
         enum: [{label : '有效', value : '1'}, {label : '无效', value : '0'}, {label : '锁定', value : '2'}]
      },
      remark: {
        type: 'string', title: '备注', maxLength: 400
        , ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      // isOk: {
      //   type: 'boolean', title: '是否有效', default: true
      // },
  
      
    },
    required: ['userAccount', 'userPassword', 'personName', 'mobile', 'isOk']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };

  roleList: any[] = [];
  userRole: any[] = [];
  userPermission: any[] = [];
  permissionTreeNodes;
  tokenData: any;
  queryParams2 = {
    fileFk: '',
  };

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private userService: UserService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService
  ) { }

  ngOnInit(): void {
    // this.formatRecord();
    // console.log(this.record);
    // debugger
    if (this.record.id == null) {
      this.formData = this.record;
      // this.schema.properties.userAccount.readOnly = false;
    } else {
      this.userService.find(this.record.id)
        .subscribe(res => {
          // this.formData = this.formatRecord(res.body);
          this.oldPassword =  deepCopy(res.body.userPassword);
          this.oldIsOk = deepCopy(res.body.isOk);
          this.formData = res.body;
          this.schema.properties.userAccount.readOnly = true;
        });
    }
  }
  save(user?: User) {
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
    if (!this.sf.valid) {
      // 手动触发所有检验项的提示信息 无效
      // this.ui['*'].firstVisual = true;
      // this.sf.refreshSchema();
      this.msgSrv.error('必填项未填写，请检查');
      return;
    }
    const account = this.sf.value.userAccount;
    if (account && this.record.id === null) {
      this.userService.hasUserAccount(account).subscribe((res: any) => {
        if (!res) {
          this.msgSrv.error('账号名已存在');
          return;
        } else {
          this.submitting = true;
          this.saveUser().then(function () {
            this.msgSrv.success('保存成功');
            this.submitting = false;
            this.modal.close(true);
          }.bind(this));
        }
      });
    } else {
      this.submitting = true;
      this.saveUser().then(function () {
        this.msgSrv.success('保存成功');
        this.submitting = false;
        this.modal.close(true);
      }.bind(this));
    }
  }

  orgChange(value) {
    console.log(value);
    // this.personalService.find(value).subscribe((res) => {
    //   this.formData['entityName'] = res.body.perName;
    //   this.formData['entityId'] = res.body.id;
    //   this.sf.reset();
    // });
  }

  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }

    if (this.oldPassword !== this.sf.value.userPassword) {
      this.sf.value.userPassword = Md5.hashStr(this.sf.value.userPassword);
    }

    if (this.oldIsOk === '2' && this.sf.value.isOk !== this.oldIsOk) {
      this.sf.value.failedPwdAttemptCount = 0;
    }

  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }

  saveUser() {
    const _this_ = this;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.userService.create(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存用户信息成功');
          });
      } else {
        _this_.userService.update(_this_.sf.value)
          .subscribe(res => {
            resolve('更新用户信息成功');
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }

  hasUserAccount(value: any) {
    alert(value);
    this.userService.hasUserAccount(value).pipe(
      map(res => {
        this.msgSrv.error('账号名已存在');
      })
    );
  }

}
