import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimItemsService } from '../../bim-items/bim-items.service';
import { BimAdvisorsService } from '../../bim-advisor/bim-advisor.service';
import { WorkManageService } from '../../work-manage/work-manage.service';

@Component({
  selector: 'app-reply-work-distribute',
  templateUrl: './distribute.component.html',
  styleUrls: []
})
export class WorkReplyDistributeComponent implements OnInit {

  buttonIsVisible = true;

  record: any = {};

  form: FormGroup;
  public loading = false;

  public bimInfoEventsId = null;

  public items = [];
  public selBimItemId = null;

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimItemsService: BimItemsService,
    private workManageService: WorkManageService,
    private bimAdvisorsService: BimAdvisorsService,
  ) {
    this.form = this.fb.group({
      bimInfoEventsId: [null, []],
    });
  }

  ngOnInit(): void {
    this.initData();
    this.loadItems();
    // if (this.record !== null && !isNullOrUndefined(this.record.id)) {
    //   this.loadData();
    // }
  }

  initData() {
  }

  loadData() {
    this.selBimItemId = this.record.itemId;
    this.form.patchValue(this.record);
  }

  // 提交
  submit() {
    const distributeRule = [];
    this.items.forEach(e =>  distributeRule.push({
      id: e.id,
      percent: e.percent
    }));
    const param = {
      bimInfoEventsId: this.bimInfoEventsId,
      distributeRule: distributeRule
    };
    this.loading = true;
    this.workManageService.filterReplyWork(param).subscribe(res => {
      this.loading = false;
      this.msg.success('分配成功');
      this.nzModalRef.destroy('success');
    });
  }

  loadItems() {
    this.items = [];
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimInfoEventsId, {
      'isDelete.equals': 0,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      const data = res.body;
      data.forEach(e => e['percent'] = 0);
      this.items = data;
    });
  }

  close() {
    this.nzModalRef.destroy();
  }

  private onError(error: { message: string; }) {
    this.loading = false;
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.loading = false;
    this.msg.remove();
    this.msg.create('warning', error.message);
  }

  formatterPercent = value => `${value} %`;
  parserPercent = value => value.replace(' %', '');
}
