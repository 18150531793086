import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HomeworkTopic } from './homeworktopic.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeworktopicService extends ThsBaseService< HomeworkTopic> {

  constructor(
    http: HttpClient,
    httpAddressService: HttpAddressService,
  ) {
    super(http, httpAddressService.EduServe + `/edu-homework-topics`);
  }
  queryHomeworktopicCourseCatalog(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllHomeworkTopicCourseCatalog` ;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
}
