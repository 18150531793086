import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ThsNews } from './ths-news.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class  ThsNewsService extends ThsBaseService<ThsNews>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/ths-news');
  }

  
  /**
   * 保存或修改比赛风采
   * @param record 风采类型
   * @param dataList 多个类型名称 
   * @param fileList 每个类型对应的附件列表
   * @returns 
   */
  saveOrUpdateBimCompetitionMien(record:any): Observable<any> {
    const copy = this.convertDateFromClient(record);
    return this.http.post(`${this.resourceUrl}/saveOrUpdateBimCompetitionMien`, copy, { observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

  /** DELETE: 根据id、id数组来删除比赛风采记录 */
  deleteBimCompetitionMien(record:  string | string[]): Observable<HttpResponse<any>> {
    let id;
    if(record instanceof Array){
      id =  record.join(",") ;
    }else{
      id = record;
    }
    const url = `${this.resourceUrl}/deleteBimCompetitionMien/${id}`;

    return this.http.delete<HttpResponse<any>>(url, { observe: 'response'});  
  }

  /** DELETE: 根据类型id数组来删除比赛风采记录 */
  deleteBimCompetitionMienByTypeId(bimInfoId:  string | string[],typeId:string | string[],contentType:string): Observable<HttpResponse<any>> {
    let typeIds;
    if(typeId instanceof Array){
      typeIds =  typeId.join(",") ;
    }else{
      typeIds = typeId;
    }
    const url = `${this.resourceUrl}/delThsNewsByCompetitionMienType/${bimInfoId}`;

    return this.http.delete<HttpResponse<any>>(url,{ params: { typeId:typeId,contenttype:contentType }, observe: 'response' });  
  }


}
