import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { BimItemsService } from '../bim-items.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { BimItemsFileService } from './bim-items-file.service';

@Component({
  selector: 'app-bim-bimItems-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimItemsEditComponent implements OnInit {

  record: any = {};
  buttonIsVisible=true;

   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';


  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  fileParam = ['模型文件', '视频上传', 'PPT上传', '文档上传', '图片上传'];
  fileParamCode = ['model_upload', 'video_upload', 'ppt_upload', 'word_upload', 'picture_upload'];
  fileFormList = [];
  isSpinning = false;

  constructor(
    private fb: FormBuilder,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimItemsService: BimItemsService,
    private bimItemsFileService: BimItemsFileService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required]],
      per: [null, [Validators.required]],
      type: [null, [Validators.required]],
      orderSn: [null, [Validators.required]],
      isDelete: [0, []]
    });
    for (let i = 0; i < this.fileParam.length; i++) {
      const code = this.fileParamCode[i];
      this.fileFormList.push(
        this.fb.group({
          id: [null, []],
          itemId: [null, []],
          name: [this.fileParam[i], []],
          type: [code, []],
          isAble: ['1', []],
          isUpload: ['1', []],
          fileNum: [1, []],
          fileSize: [(code === 'model_upload' || code === 'video_upload') ? 100 : 10, []]
        })
      );
    }
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
   // this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
   // this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';

  }

  ngOnInit() {
    if (!this.isAdd) {
      this.loadData();
    }
  }




  loadData(): void {
    this.form.patchValue(this.record);
    this.isSpinning = true;
    this.bimItemsFileService.query({
      'itemId.equals': this.record.id
    }).subscribe(res => {
      if (res && res.body.length > 0) {
        const data = res.body;
        this.fileFormList.forEach(e => {
          const list = data.filter(d => d.type === e.controls['type'].value);
          if (list && list.length > 0) {
            e.patchValue(list[0]);
          }
        });
        this.isSpinning = false;
      }
      this.isSpinning = false;
    });
  }

  // 保存
  submit() {
    this.buttonIsVisible=false;
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查专项信息是否完整');
      this.buttonIsVisible=true;
      return;
    }

    console.log(this.fileFormList);
    

    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    let queryParamTmp ={};
    queryParamTmp['name.equals'] = param["name"];
    queryParamTmp['type.equals'] = param["type"];
    queryParamTmp['isDelete.equals'] = 0;
    this.bimItemsService.query(queryParamTmp).subscribe((res: any) => {
      let listTmp = res.body;
      let totaltmp = res.headers.get('X-Total-Count');
         if(totaltmp >1 || (totaltmp == 1 && listTmp[0].id !== param["id"] ))
         {
          this.msg.error('该专项分类下已经存在重名的专项，不能保存，请检查！');
          this.buttonIsVisible=true;
         }else{
            this.bimItemsService.update(param).subscribe(res => {
              const record = res.body;
              if (record  ) {
                this.saveItemFileList(record);
                this.msg.info('修改成功');
                this.buttonIsVisible=true;
                this.nzModalRef.destroy();
              } else {
                this.msg.error('修改失败');
                this.buttonIsVisible=true;
              }
            });
         }
    });
  }


  addDataInfo(param: any) {
  
    let queryParamTmp ={};
    queryParamTmp['name.equals'] = param["name"];
    queryParamTmp['type.equals'] = param["type"];
    queryParamTmp['isDelete.equals'] = 0;
    this.bimItemsService.query(queryParamTmp).subscribe((res: any) => {
      let totaltmp = res.headers.get('X-Total-Count');
         if(totaltmp >0)
         {
          this.msg.error('该专项分类下已经存在重名的专项，不能保存，请检查！');
          this.buttonIsVisible=true;
         }else{
              this.bimItemsService.create(param).subscribe(res => {
                const record = res.body;
                if (record  ) {
                  this.saveItemFileList(record);
                  this.msg.info('保存成功');
                  this.buttonIsVisible=true;
                  this.nzModalRef.destroy();
                } else {
                  this.msg.error('保存失败');
                  this.buttonIsVisible=true;
                }
              });
          }
        });
  }

  saveItemFileList(item) {
    if (!isNullOrUndefined(item.id)) {
      this.bimItemsFileService.deleteByItemId(item.id).subscribe(() => {
        this.fileFormList.forEach(form => {
          const param = {};
          for (const key in form.controls) {
            param[key] = form.controls[key].value;
          }
          if (isNullOrUndefined(param['itemId'])) {
            param['itemId'] = item.id;
            this.bimItemsFileService.create(param).subscribe();
          } else {
            this.bimItemsFileService.update(param).subscribe();
          }
        });
      });
    }
  }


  Cancel(){
    this.nzModalRef.destroy();
  }
}
