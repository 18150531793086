import { Component, ElementRef, OnInit,AfterContentChecked, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalRef,NzModalService,UploadFile } from 'ng-zorro-antd';
import { HttpResponse } from '@angular/common/http';
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";

import { environment } from '@env/environment';
import { ProjectReportService } from "./../projectReport.service";
import { APP_PERMISSIONS_IUCCE } from '@shared/app.permissions';
import { ACLService } from '@delon/acl';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { resolve } from 'url';
import { reject } from 'lodash';

@Component({
  selector: 'app-iucce-project-contract',
  templateUrl: './constract.component.html',
  styleUrls: ['./constract.component.less']
})
export class ProjectReportContractEditComponent implements OnInit,AfterContentChecked {
  
    validateForm: FormGroup;
    isAdd = true;
    isView = false;//查看
    isUploadContractFile = false;//是否允许上传合同协议
    record: any;
    yearList = [];//年份
    batchList = [];//批次
    prjTypeList = [];//项目类别
    saveving = false;
    submitting = false;
    showUploadListSwitch2 = false;
    fileList = [];
    uploadFileAddress = '';
    APP_PERMISSIONS = APP_PERMISSIONS_IUCCE;
    isCanEdit = false;
    isCanSpecialEdit = false;

  constructor(public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public nzModalRef: NzModalRef,
    private modalService: NzModalService,
    private projectReportService: ProjectReportService,
    private thsadminFileService: ThsadminFileService,
    private aclService: ACLService,
    private fb: FormBuilder) { 
        this.uploadFileAddress = projectReportService.uploadFileAddress;
    }

  ngOnInit(): void {

    this.isCanEdit = this.record.prjStatus > 20 && this.record.prjStatus < 30 && this.aclService.canAbility(this.APP_PERMISSIONS.IUCCE_PROJECT_REPORT_EDIT);
    this.isCanSpecialEdit = this.aclService.canAbility(this.APP_PERMISSIONS.IUCCE_PROJECT_SPECIAL_MODIFY);
    this.isView = (!this.isCanEdit && !this.isCanSpecialEdit);

    this.validateForm = this.fb.group({
        region: [this.record.region,null],
        province: [this.record.province,null],
        schoolName: [this.record.schoolName,null],
        projectName: [this.record.projectName,null],
        contractStatus: [this.record.contractStatus, [Validators.required]]
    });

    this.isView = this.record.prjStatus >= 50;
    this.isUploadContractFile = !this.isView && this.record.establishStatus == '50';

    this.getFileList();
  }
  ngAfterContentChecked():void{
    let control = $('.uploadClass');
    console.log(control+'====='+!control.hasClass('upload-list-view'))
    if(!this.isUploadContractFile && control && control.length > 0 && !control.hasClass('upload-list-view')){
      control.addClass('upload-list-view');
    }
  }

  
  /**
   * 获取文件列表
   */
   getFileList(){
    let queryParams = {
      "fileFk.equals" : this.record.id,
      "extField1.equals":'IucceProjectConstract'
    };
    this.thsadminFileService.queryFiles(queryParams).subscribe(res=>{
      let result = res.body;
      this.showUploadListSwitch2 = false;
      result.forEach(item=>{
        item.uid = item.id;
        item.name = item.fileName;
        item.url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + item.id;
      });
      this.fileList = result;
      this.showUploadListSwitch2 = true;
    });
  }
  
  // 上传之前
  beforeUploadProjectReport = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('doc') || fileName.endsWith('docx') || fileName.endsWith('pdf');
    if(!isAllowUpload){
      this.msg.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.msg.error('文件不能大于100M');
      return false;
    }

    return true;
  }

  
  // 上传之前
  beforeUploadProjectContratct = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('doc') || fileName.endsWith('docx') || fileName.endsWith('pdf');
    if(!isAllowUpload){
      this.msg.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.msg.error('文件不能大于100M');
      return false;
    }

    return true;
  }

  
  // 上传文件参数
  fileParamProjectContract = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id,
      fileDesc: file.name,
      extField1 : 'IucceProjectConstract',
      extField2 : 'IucceProject',
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }

  removeContractFile = (file: UploadFile) => {
    const this_ = this;
    new Promise((resolve,reject) => {
      this_.thsadminFileService.deleteFileById(file.uid).subscribe(res => {
        this_.fileList = this_.fileList.filter(w=>w.uid != file.uid);
        resolve(true);
      });
    }).then(res => {
      this_.msg.info('删除文件成功');
    })
    .catch(error => {
      this_.msg.error('删除文件失败');
    })
    return false;
  }

  validData(type){
    return new Promise((resolve,reject) => {
      debugger;
      if (this.validateForm.invalid) {
        this.msg.error('请检查信息是否完整!');
        return reject(false);
      }

      if(type == 1){//提交
        if(!this.fileList || this.fileList.length <= 0){
          this.msg.error('请上传合同协议!');
          return reject(false);
        }
        
        this.modalService.confirm({
          nzTitle     : '提交后合同协议将不能修改，确定提交吗？',
          nzContent   : '',
          nzOkText    : '确定',
          nzOkType    : 'primary',
          nzOnOk      : () => {
            resolve(true);
          },
          nzCancelText: '取消',
          nzOnCancel  : () => {
            reject(false);
          }
        });
      }else{
        return resolve(true);
      }
    });
  }

  /**
   * 保存
   * @returns 
   */
  submitForm(type): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    this.validData(type).then(res => {
      
      if(type == 1){
        this.submitting = true;
      }else{
        this.saveving = true;
      }

      debugger;
      let recordInfo = Object.assign({},this.record);
      recordInfo = Object.assign(recordInfo, this.validateForm.value);
      if(type == 1 && recordInfo.establishStatus == '50'){//待提交协议书
        recordInfo.establishStatus = '5';//协议书审核中
        recordInfo.prjAgreementAuditstate = '1';//已上传合同协议书
      }

      this.projectReportService.update(recordInfo)
      .subscribe(
          (response) => {
              this.msg.success(this.submitting ? '提交成功' : '保存成功');
              this.submitting = false;
              this.saveving = false;
              this.nzModalRef.close(true);
          },
          (resError) => {
              this.msg.success(this.submitting ? '提交失败' : '保存失败');
              this.submitting = false;
              this.saveving = false;
          }
      );
    }).catch(error =>{
      debugger;
      this.submitting = false;
      this.saveving = false;
    });
    // if (this.validateForm.invalid) {
    //   this.msg.error('请检查信息是否完整!');
    //   return;
    // }
    // if(type == 1){//提交
    //   if(!this.fileList || this.fileList.length <= 0){
    //     this.msg.error('请上传合同协议!');
    //     return;
    //   }

    //   this.submitting = true;
    // }else{
    //   this.saveving = true;
    // }

    // let recordInfo = Object.assign({},this.record);
    // recordInfo = Object.assign(recordInfo, this.validateForm.value);
    // if(type == 1 && recordInfo.establishStatus == '50'){//待提交协议书
    //   recordInfo.establishStatus = '5';//协议书审核中
    //   recordInfo.prjAgreementAuditstate = '1';//已上传合同协议书
    // }

    // this.projectReportService.update(recordInfo)
    //   .subscribe(
    //       (response) => {
    //           this.msg.success('保存成功');
    //           this.submitting = false;
    //           this.saveving = false;
    //           this.nzModalRef.close(true);
    //       },
    //       (resError) => {
    //           this.msg.success('保存失败');
    //           this.submitting = false;
    //           this.saveving = false;
    //       }
    //   );
  }

  /**
   * 关闭
   * @param result 
   */
  close(result: any): void {
    this.nzModalRef.destroy(result);
  }

}
