import { Component, OnInit,Inject,Output,EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import {GuidUtil} from "@shared/utils/guid.util";
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { UserService } from "app/routes/admin/system/users/users.service";
import { DictService } from 'app/shared/components/dict/dict.service';
import { COMMON_DATA } from '@shared/app.commonData';
import { ProjectNicheService } from "app/routes/business/iucce/projectNiche/projectNiche.service";
import { ProjectNicheFollowService } from "app/routes/business/iucce/projectNiche/projectNicheFollow.service";

@Component({
  selector: 'app-niche-edit',
  templateUrl: './edit.component.html',
  styles: [`
  :host ::ng-deep .ant-calendar-picker {
    display:block;
  }`]
})
export class NicheEditComponent implements OnInit {

  COMMON_DATA = COMMON_DATA;
  pageTitle: any;
  pageType: any;
  record:any;
  submitting = false;
  validateForm: FormGroup;
  isView = false;
  yearList:any;
  monthList:any;
  /**
  * 保存回调
  */
  @Output() onSubmit: EventEmitter<{}> = new EventEmitter<{}>();

  constructor
    (private fb: FormBuilder, 
      private modal: NzModalRef,
      public msg: NzMessageService,
      private dictService: DictService,
      private projectNicheService: ProjectNicheService,
      private projectNicheFollowService: ProjectNicheFollowService,
      @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
    private userService: UserService,
    ) {
        
    }

  ngOnInit(): void {
    this.monthList = this.COMMON_DATA.month;
    this.validateForm = this.fb.group({
      id: [null],
      iucceProjectId: [this.record.iucceProjectId, [Validators.required]],
      iucceProjectNicheId: [this.record.iucceProjectNicheId, [Validators.required]],
      year: [null, [Validators.required]],
      month: [null, [Validators.required]],
      prjCompleteRate: [null, [Validators.required]],
      prjCompleteDetails:[null, [Validators.required]],
      nicheFollowDetails:[null, [Validators.required]],
      isOrder:[null],
      orderDate:[null],
      orderAmount:[null],
      orderNumber:[null],
      remark:[null]
    });

    this.getYearListDict();

    if (this.pageType=='add') {
      this.pageTitle = '新增'
    } else {
      if (this.pageType == 'view') {
        this.isView = true;
        this.pageTitle = '查看';
      }else if(this.pageType == 'edit') {
        this.pageTitle = '编辑';
      }
      
      if(this.record && this.record.id){
          this.validateForm.patchValue(this.record);
      }
    }

  }

  getYearListDict(){
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
          debugger;
          if(this.record && this.record.id && this.record.year){
            this.validateForm.get('year').setValue(this.record.year.toString());
        }
      }
    });
  }

  submit(){
    for (const i in this.validateForm.controls) {
      const control = this.validateForm.controls[i];
      control.markAsDirty();
      control.updateValueAndValidity();
    }
      
    if(this.validateForm.invalid){
      this.msg.error("请检查信息是否完整!");
      return;
    }
    this.submitting = true;
    if(this.validateForm.get('orderDate').value != null || this.validateForm.get('orderAmount').value != null){
      this.validateForm.get('isOrder').setValue('1');//已成单
    }else{
      this.validateForm.get('isOrder').setValue('0');//已成单
    }
    let recordInfo = Object.assign({},this.record);
    recordInfo = Object.assign(recordInfo, this.validateForm.value);
    debugger;
    let queryParams = {
        'year.in':recordInfo.year,
        'month.in':recordInfo.month,
        'iucceProjectId.equals':recordInfo.iucceProjectId,
        'iucceProjectNicheId.equals':recordInfo.iucceProjectNicheId,
        size : 1000
    };
    
    this.projectNicheService.queryProjectNicheFollowDatas(queryParams)
        .subscribe(res=>{
            let result = res.body;

            if(this.record.id == null){
                if(result && result.length > 0){
                    this.msg.error('该商机已添加过跟进详情，无需重复添加！');
                    this.submitting = false;
                }else{
                    this.projectNicheFollowService.createIucceProjectNicheFollow(recordInfo)
                        .subscribe((response) => {
                            setTimeout(() => {
                                this.msg.success('保存成功');
                                this.record = response.body;
                                this.submitting = false;
                                this.onSubmit.emit(this.validateForm);
                                this.close(true);
                            }, 1000);
                        },
                        (res: HttpResponse<any>) => {
                            this.submitting = false;
                            this.msg.success('保存失败');
                            this.close(false);
                        }
                    );
                }
            }else{
                if(result && result.length > 0){
                    let queryResult = result.filter(w=>w.id != this.record.id);
                    if(queryResult && queryResult.length > 0){
                        this.msg.error('该商机已添加过跟进详情，无需重复添加！');
                        this.submitting = false;
                        return;
                    }
                }

                this.projectNicheFollowService.updateIucceProjectNicheFollow(recordInfo).subscribe((response) => {
                    setTimeout(() => {
                        this.msg.success('更新成功');
                        this.record = response.body;
                        this.submitting = false;
                        this.onSubmit.emit(this.record);
                        this.close(true);
                    }, 1000);
                  }, (res: HttpResponse<any>) => {
                    setTimeout(() => {
                        this.msg.success('更新失败');
                        this.submitting = false;
                        this.close(false);
                    }, 1000);
                  });
            }
        }
    );
  }

  close(isRefresh = false){
    if(isRefresh){
      this.modal.close(this.record);
    }else{
      this.modal.close();
    }
  }

}
