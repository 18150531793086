import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExportProgressService extends ThsBaseService<any> {
    
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.FileServer + '/sys-files');
    }
    

    /**
     * 分片下载
     * @param params
     * @returns 
     */
     downloadChuckByPath (fileFullName,fileName,index,chuckSize): Observable<any> {
        const param = {
            filePath: fileFullName,
            fileName: fileName,
            index: index,
            chuckSize: chuckSize
        };
        return this.http.get(this.resourceUrl+`/downloadChuckByPath` ,  {
            params: param,
            // headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
            observe: 'response',
            responseType: 'blob',
            withCredentials: true,
          });

    }

}

