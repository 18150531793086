import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BimWorks} from "../../work-manage.model";
import {NzMessageService} from "ng-zorro-antd";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import {HttpAddressService} from "@shared/session/http-address.service";
import {WorkManageService} from "../../work-manage.service";
import * as OSS from 'ali-oss';

@Component({
  selector: 'app-project-upload',
  templateUrl: './project-upload.component.html',
  styles: []
})
export class ProjectUploadComponent implements OnInit,OnChanges {

  @ViewChild('fUpload1') fUpload1: any;
  @ViewChild('fUpload2') fUpload2: any;
  @ViewChild('fUpload3') fUpload3: any;
  @ViewChild('fUpload4') fUpload4: any;
  @ViewChild('fUpload5') fUpload5: any;

  @Input() title: string = '';
  @Input() fileFk: string = '';
  @Input() schoolId: string = '';
  @Input() teamId: string = '';
  @Input() itemName: string = '';
  @Input() itemId: string = '';
  @Input() isView: boolean = false;

  type: number = 1;
  worksId: string = '';
  validateForm: FormGroup;

  modelFileList = [];
  videoFileList = [];
  pptFileList = [];
  wordFileList = [];
  imageList = [];

  works: BimWorks = new BimWorks();
  check = false;
  
  public ossClient;

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private thsadminFileService: ThsadminFileService,
    private httpAddressService: HttpAddressService,
    private workManageService: WorkManageService,
  ) { 
    this.ossClient = new OSS(this.httpAddressService.ossServe);
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      name: [{value: null, disabled: this.isView}, [Validators.required,Validators.maxLength(50)]],
      nickname: [{value: null, disabled: this.isView},[Validators.maxLength(200)]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.type && !changes.schoolId) {
    //   this.getWorks();
    //   this.modelFileList = [];
    //   this.videoFileList = [];
    //   this.pptFileList = [];
    //   this.wordFileList = [];
    //   this.imageList = [];
    // }
    if (changes.schoolId && changes.schoolId.currentValue && changes.itemId && changes.itemId.currentValue) {
      this.getWorks();
    }
  }

  getWorks() {
    const params = {
      'teamId.equals': this.teamId,
      'schoolId.equals': this.schoolId,
      'itemId.equals': this.itemId,
      'workType.equals': this.type,
      page: 0,
      size: 1
    };
    this.workManageService.query(params).subscribe(res => {
      if (res && res.body && res.body.length === 1) {
        this.validateForm.setValue({
          name: res.body[0].name,
          nickname: res.body[0].introduction
        });
        this.worksId = res.body[0].id;
        this.works = res.body[0];
        this.fileFk = res.body[0].id;
        if (this.worksId && this.worksId.length > 0) {
          this.getFiles();
        }
      } else {
        this.validateForm.setValue({
          name: null,
          nickname: null
        });
        this.worksId = '';
        this.works = new BimWorks();
      }
    })
  }

  getFiles() {
    const params = {
      'fileFk.equals': this.worksId,
    };
    this.thsadminFileService.queryFiles(params).subscribe(res => {
      if (res && res.body && res.body.length > 0) {
        this.modelFileList = [];
        this.videoFileList = [];
        this.pptFileList = [];
        this.wordFileList = [];
        this.imageList = [];
        res.body.forEach(item => {
          let downUrl;
          if (item.extField2 === 'AliOss') {
            if (item.extField4 === '1') {
              // 检查文件是否存在
              this.ossClient.head(item.fileSavePath).catch((err) => {
                if (err.code === 'NoSuchKey') {
                  console.log('文件不存在');
                  this.thsadminFileService.deleteFileById(item.id).subscribe();
                  return;
                }
              }).then((result) => {
                console.log(result);
                console.log(item.fileSavePath);
                if (result !== undefined) {
                  const response = {
                    'content-disposition': `attachment; filename=${encodeURIComponent(item.fileName)}`
                  };
                  downUrl = this.ossClient.signatureUrl(item.fileSavePath, {response});
                  this.initWorkFile(item, downUrl);
                }
              });
            }
          } else {
            downUrl = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id;
            this.initWorkFile(item, downUrl);
          }
        })
      }
    })
  }

  
  initWorkFile(item, downUrl) {
    let file = {
      uid: item.id,
      name: item.fileName,
      size: item.fileSize,
      // url: this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id,
      url: downUrl,
      path: item.fileSavePath,
      extField2: item.extField2,
      showPercent: false
    };
    if (item.extField1 === 'team_works_model') {
      this.modelFileList.push(file);
    } else if (item.extField1 === 'team_works_video') {
      this.videoFileList.push(file);
    } else if (item.extField1 === 'team_works_ppt') {
      this.pptFileList.push(file);
    } else if (item.extField1 === 'team_works_word') {
      this.wordFileList.push(file);
    } else if (item.extField1 === 'team_works_image') {
      this.imageList.push(file);
    }
  }

  submitForm() {
    this.check = false;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    console.log('validateForm:',this.validateForm);
    if (this.validateForm.status === "VALID") {
      this.modelFileList = this.fUpload1.fileList;
      this.videoFileList = this.fUpload2.fileList;
      this.pptFileList = this.fUpload3.fileList;
      this.wordFileList = this.fUpload4.fileList;
      this.imageList = this.fUpload5.fileList;
      if (!this.modelFileList || this.modelFileList.length === 0) {
        this.msg.warning('请上传' + this.itemName + '模型文件！');
        return;
      }
      if (!this.videoFileList || this.videoFileList.length === 0) {
        this.msg.warning('请上传' + this.itemName + '视频文件！');
        return;
      }
      if (!this.pptFileList || this.pptFileList.length === 0) {
        this.msg.warning('请上传' + this.itemName + 'PPT！');
        return;
      }
      if (!this.wordFileList || this.wordFileList.length === 0) {
        this.msg.warning('请上传' + this.itemName + '文档！');
        return;
      }
      if (!this.imageList || this.imageList.length === 0) {
        this.msg.warning('请上传' + this.itemName + '图片！');
        return;
      }
      this.check = true;
      this.works.name = this.validateForm.get('name').value;
      if (this.validateForm.get('nickname')) {
        this.works.introduction = this.validateForm.get('nickname').value || '';
      }
      if (!this.worksId || this.worksId.length === 0) {
        this.works.itemId = this.itemId;
        this.works.schoolId = this.schoolId;
        this.works.teamId = this.teamId;
        this.works.workType = this.type;
        this.works.workSubmitStatus = 1;
      }
    }
  }
}
