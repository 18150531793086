import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { SettingsService } from '@delon/theme';

@Component({
  selector   : 'layout-sidebar',
  templateUrl: './sidebar.component.html',
  styles  : [
    `
      :host ::ng-depp .ad-nav__depth1{
        margin-left: 10px;
      }
    `
  ]
}
)

export class SidebarComponent {
  constructor(
    public settings: SettingsService,
    public msgSrv: NzMessageService,
  ) {
  }

}

