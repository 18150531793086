import { Component,Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimReceiptInfoService } from './bim-receipt-info.service';
import { BimReceiptInfo } from './bim-receipt-info.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {ReceiptUploadComponent} from './edit/receipt-upload.component';
import { UserService } from "../../../../admin/system/users/users.service";
import {ProvinceService} from '../../provinces/provinces.service';
import {UcommonService} from '../../ucommon//ucommon.server';
import {BimInfoEventsService} from '../bim-info-events/bim-info-events.service';

@Component({
  selector: 'app-bim-receipt-info-list',
  templateUrl: './bim-receipt-info-list.component.html',
  styleUrls: ['./bim-receipt-info-list.component.less']
})
export class BimReceiptInfoListComponent implements OnInit {
  BimItems = {};
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科', '2': '中职'};

  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimReceiptInfo[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];
  bimEventsList=[];

  userLocationList = [];
  userProvinceList=[];
 
  entityId:string;
  entityName:String;
  accountName:String;

  public bimInfoStatus = 1;//-1 全部;
  
  public currentBimInfo = null;
  curBimEventId:string;

  isloadding = false;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'keyword.contains': '',
    'schoolType.contains': '',
    'regionId.contains': '',
    'province.contains': '',
     'belongType.contains': '',
    'bimInfoEventsId.contains':'',
    // sort固定
     sort: ['creatorTime,desc']
  };


  columns: SimpleTableColumn[] = [
     { title: '', index: 'id', type: 'checkbox' },
     { title: '团队名称', index: 'teamName' , width: '15%'},
     { title: '学校', index: 'schoolName' , width: '15%'},
     { title: '邮箱', index: 'email' , width: '15%'},

     { title: '学制', index: 'schoolType' , width: '5%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else{
          return item.schoolType;
        }
      }
    },
     { title: '区域', index: 'regionId' , width: '5%',
      format: (item: any) => {
         return this.findLocationName(item.regionId);
      }
    },
    { title: '省份', index: 'province' , width: '5%',
      format: (item: any) => {
        return this.findProvinceName(item.province); 
      }
    },
   
    { title: '最新提交时间', index: 'modifiedTime' , width: '20%'
    },

    {
      title: '操作', className: 'text-center', width: '20%',
      buttons: [
          { text: '编辑',  click: (item:any) => this.edit(item), },
       ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimReceiptInfoService: BimReceiptInfoService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    public bimInfoEventsService: BimInfoEventsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;


  }

  ngOnInit() {
    if(this.accountName === 'admin')
    {
      this.loadLocationList();
      this.loadprovinceList();

      this.getDataList();
    }else{
      this.getUserProvinces();
    }
  }




/**
 * 获取用户地区信息
 */
  getUserProvinces(){
     this.userProvinceList = [];
     this.userLocationList = [];
    this.provinceService.getUserProvinces()
    .subscribe((res: any) => {
      
        res.body.forEach(element => {
            this.userProvinceList.push(element.id);
            this.userLocationList.push(element.fkregionId);

            
        });
        this.loadLocationList();
        this.loadprovinceList();
        this.getDataList();

        //this.userProvinceList = res.body;
        console.log(res.body);
    });
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     if(this.bimEventsList != null && this.bimEventsList.length >0)
     {
       
     }else{
      this.list = [];
      this.total = 0;
      this.isloadding = false;
       return false;
     }

     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      this.queryParams["bimInfoEventsId.contains"] = this.bimEventsList[0].id;
      copyParams["bimInfoEventsId.contains"] = this.bimEventsList[0].id;
    } else {
      Object.keys(q).forEach(function (key) {
        if(key ==='keyword.contains' && q[key] !== '' &&　q[key] !== null )
        {
          copyParams['teamName.contains'] = q[key];
          // copyParams['schoolName.contains'] = q[key];
          // copyParams['email.contains'] = q[key];
        }else if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;


    this.curBimEventId =  copyParams["bimInfoEventsId.contains"];

    let tmplocation = copyParams['regionId.contains'];

    if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
    {   
      //delete copyParams['location.contains'];
      copyParams['regionId.in'] = this.userLocationList;
    }

    let tmpprovince = copyParams['province.contains'];

    if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
    {
      //delete copyParams['province.contains'];
      copyParams['province.in'] = this.userProvinceList;
    }

   // copyParams['isDelete.equals'] = 0;
    this.bimReceiptInfoService.queryNew(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {

    
    if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
    {
       this.msg.info('当前赛事没有团队赛项，不能添加参赛团队回执数据!');
       return false;
    }

    this.modal
      .static(ReceiptUploadComponent, { record: { id: null,bimInfoEventsId:this.curBimEventId },isAdd: true, bimInfoEventsId: this.curBimEventId })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  edit(record){
    const events = this.bimEventsList.filter(e => e.id === this.curBimEventId);
    let curBimEventName = '';
    if (events && events.length > 0) {
      curBimEventName = events[0].name
    }
    this.modal
    .static(ReceiptUploadComponent, {bimInfo:this.currentBimInfo, curTeamEvent:this.curBimEventId, curBimEventName: curBimEventName, 
             teamId: record.teamId,   schoolId:record.schoolId},1100)

    .subscribe((res) => {

        this.getDataList();
      
      
    });
  }

 

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



    /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      this.userService.queryRegionList(dataParams).subscribe(res => {
        
        if (res && res.body) {
          this.locationList = res.body;

          console.log(this.locationList);
        }
      });
  }


  
  /**
   * 加载省份列表
   */
  loadprovinceList(){
    this.provinceList = [];
    
    this.userService.queryProvinceList({
      'id.in':this.userProvinceList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.provinceList = data;
      }
    });
  }

/**
 * 查询区域名
 * @param location 
 */
  findLocationName(location){
     let tmpList = this.locationList.filter(d=>d.id===location);
     if(tmpList.length >0)
     {
        return tmpList[0].regionName;
     }else{
       return '';
     }
  }

  /**
   * 查询省份名称
   * @param location 
   */

  findProvinceName(location){
    let tmpList = this.provinceList.filter(d=>d.id===location);
    if(tmpList.length >0)
    {
       return tmpList[0].provinceName;
    }else{
      return '';
    }
 }

 /**
  * 导出excel
  */

 exportExcel(){

      if(this.bimEventsList != null && this.bimEventsList.length >0)
      {
        
      }else{
        return false;
      }

      let copyParams = {};
      const q = this.queryParams;

      Object.keys(q).forEach(function (key) {
        if(key ==='keyword.contains' && q[key] !== '' &&　q[key] !== null )
        {
          copyParams['teamName.contains'] = q[key];
        }else if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });


      this.curBimEventId =  copyParams["bimInfoEventsId.contains"];

      let tmplocation = copyParams['regionId.contains'];

      if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0)))
      {   
        copyParams['regionId.in'] = this.userLocationList;
      }

      let tmpprovince = copyParams['province.contains'];

      if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)))
      {
        copyParams['province.in'] = this.userProvinceList;
      }

      this.bimReceiptInfoService.exportExcel(copyParams);
 }


/**
 * 选择赛事事件
 * @param bimInfo 
 */
  clickContest(bimInfo) {
      this.currentBimInfo = bimInfo;
      //this.queryParams["bimInfoId"] = this.currentBimInfo.id;
      this.getEventsDataList();
  }

/**
 * 获取赛事赛项
 * @param isReset 
 */
  public getEventsDataList(isReset?: boolean) {
      let copyParams = {sort: ['createTime,desc']};
      const q = this.queryParams;
    
      copyParams['page'] = 0;
      copyParams['size'] = 1000;
      copyParams['bimInfoId.contains'] = this.currentBimInfo.id;
      copyParams['eventsType.equals'] ='0';
      copyParams['isDelete.equals'] = 0;
      

      this.bimInfoEventsService.query(copyParams)
        .subscribe((res: any) => {
            if(res.ok && res.body.length >0)
            {
              this.bimEventsList = res.body;
              this.curBimEventId = this.bimEventsList[0].id;
              this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
              this.getDataList();
            }else{
              this.bimEventsList = [];
              this.curBimEventId = "-1";
              this.queryParams["bimInfoEventsId.contains"] = "-1";
              this.getDataList();
            }
          });   
   }

/**
 * 赛项选择框改变
 * @param $event 
 */
   bimEventsChange($event){
     this.curBimEventId = $event;
     this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
     this.getDataList();
   }

   /**
    * 其他选项改变
    * @param $event 
    */
   schoolTypeChange($event){
    this.page = 0;
    this.getDataList();
  }


  searche(){
    this.page = 0;
    this.getDataList();
  }







}
