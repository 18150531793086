import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import {ThsBaseService } from  '../../../../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../../../../shared/session/http-address.service';
import { BimInfoEventsItemsPass } from './BimInfoEventsItemsPass.model';
import { Identifiers } from '@angular/compiler';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimInfoEventsItemsPassService extends ThsBaseService<BimInfoEventsItemsPass> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-info-events-items-passes`);
    }








        saveItemPass(itemPass: BimInfoEventsItemsPass[]): Observable<HttpResponse<BimInfoEventsItemsPass>> {
            return this.http.put<BimInfoEventsItemsPass>(`${this.resourceUrl}/saveItemPass`, itemPass, { observe: 'response' });
        }

        /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }


}

