export class BimWorks {
  id?: string;
  name?: string;
  introduction?: string;
  teamId?: string;
  schoolId?: string;
  itemId?: string;
  workSubmitStatus?: number;
  smallImage?: string;
  bigImage?: string;
  videos?: string;
  uploadFile?: string;
  uploadDate?: Date;
  hit?: number;
  core?: number;
  remark?: string;
  netCore?: number;
  teacherCore?: number;
  bim?: number;
  avgCore?: number;
  workType?: number;
  secTeacherCore?: number;
  secNetCore?: number;
  secAvgCore?: number;
  secCore?: number;
  thsTeacherCore?: number;
  thsNetCore?: number;
  thsAvgCore?: number;
  thsCore?: number;
  workTop?: number;
  teamName?: string;
  schoolName?: string;
  schoolType?: string;
  itemName?: string;
  bimInfoId?: string;
  bimInfoName?: string;
  eventsId?: string;
  eventsName?: string;
  secRaterList?: BimAdvisorsDTO[]; //全国赛评分评委
  checked?: boolean;
  groupName?: string;
  groupNum?: string;
}

export class BimWorkVm {
  teamName?: string; //团队名称
  teamId?: string; //团队ID
  schoolId?: string; //学校ID
  schoolName?: string; //学校名称
  schoolType?: string; //学校类型  0：专科  1：本科
  bimInfoId?: string; //赛事ID
  bimInfoName?: string; //赛事名称
  bimInfoEventId?: string; //赛项ID
  bimInfoEventName?: string; //赛项名称
  firstWorkStatus?: number; //校内赛作品提交状态 0:未提交  1:已提交
  firstScore?: number; //校内赛得分
  firstWorkTop?: number; //校内赛晋级
  secWorkStatus?: number; //全国赛作品提交状态
  secScore?: number; //全国赛得分
  secWorkId?: string; //全国赛作品ID
  secRaterList?: BimAdvisorsDTO[]; //全国赛评分评委
  checked?: boolean;
}

export class BimAdvisorsDTO {
  id?: string;
  schoolId?: string;
  name?: string;
  email?: string;
  tel?: string;
  regCode?: string;
  sendMail?: string;
  advisorType?: string;
  description?: string;
}

export class BimTeamTeacherDTO {
  id?: string;
  name?: string;
  profession?: string;
  email?: string;
  remark?: string;
  sex?: string;
  teamId?: string;
  mobile?: string;
  photoPath?: string;
  createTime?: Date;
  creator?: string;
  updateTime?: Date;
  updator?: string;
  userDefine1?: string;
  userDefine2?: string;
  userDefine3?: string;
  userDefine4?: string;
  userDefine5?: string;
  userDefine6?: string;
  serialNumber?: number;
  type?: number;
}

export class BimTeamStudentDTO {
  id?: string;
  email?: string;
  name?: string;
  profession?: string;
  remark?: string;
  sex?: string;
  teamId?: string;
  tel?: string;
  photoPath?: string;
  createTime?: Date;
  creator?: string;
  updateTime?: Date;
  updator?: string;
  userDefine1?: string;
  userDefine2?: string;
  userDefine3?: string;
  userDefine4?: string;
  userDefine5?: string;
  userDefine6?: string;
  type?: number;
  serialNumber?: number;
}

export class BimWorkCoresDTO {
  id?: string;
  workId?: string;
  advisorId?: string;
  core?: number;
  submit?: number;
  advisorName?: string;
}

export class BimWorkScoresVM {
  detailId?: string;
  workId?: string;
  advisorId?: string;
  bimScoringRuleItemId?: string;
  core?: number;
  createTime?: Date;
  creator?: string;
  updateTime?: Date;
  updator?: string;
  bimScoringRuleId?: string;
  itemSoft?: string;
  itemName1?: string;
  itemName2?: string;
  itemScore?: number;
  itemRule?: string;
  orderNum?: number;
  remark?: string;
  itemName?: string;
  rows?: number;
  cols?: number;
  rowBool?: boolean;
  colBool?: boolean;
}
