import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { User } from './users.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService extends ThsBaseService<User> {

  public regionUrl;
  public provinceUrl;
  constructor(
    http: HttpClient,
    httpAddressService: HttpAddressService) {
      super(http, `/thsuaa/api/sys-users`);
      this.regionUrl = httpAddressService.BimServe + '/region-infos';
      this.provinceUrl = httpAddressService.BimServe +  '/provinces';
  }

  hasUserName(username: string): Observable<any> {
    const url = `${this.resourceUrl}/hasUserName/${username}`;
    return this.http.get(url);
  }

  updateMultiTenancyIdByEntityId(entityId: string, multiTenancyId: string): Observable<any> {
    const url = `${this.resourceUrl}/updateMultiTenancyId/${entityId}/${multiTenancyId}`;
    return this.http.get(url);
  }

  /** GET: 分页、多条件查询记录列表 */
  queryRegionList(queryParams?: any): Observable<any> {
      return this.http.get(this.regionUrl, { params: queryParams, observe: 'response' })
          .pipe(map((res: any) => this.convertDateArrayFromServer(res)));
  }

  queryProvinceList(queryParams?: any): Observable<any> {
      return this.http.get(this.provinceUrl, { params: queryParams, observe: 'response' })
          .pipe(map((res: any) => this.convertDateArrayFromServer(res)));
  }

}

