import { Component, OnInit, ViewChild } from "@angular/core";
import { SimpleTableColumn, SimpleTableComponent, SimpleTableData } from "@delon/abc";
import { ModalHelper } from "@delon/theme";
import { isNullOrUndefined } from "util";
import { BimWorkAddComponent } from "./bim-work-add/bim-work-add.component";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { WorkManageService } from "../work-manage/work-manage.service";
import { MessageHelper } from "@shared/helpers/MessageHelper";
import { BimWorkCoresService } from "../bim-work-cores/bim-work-cores.service";
import { BimInfoEventsService } from "../bim-info/bim-info-events/bim-info-events.service";
import * as moment from 'moment';

@Component({
  selector: 'app-work-show',
  templateUrl: './bim-work-show.component.html',
  styleUrls: []
})
export class BimWorkShowComponent implements OnInit {
  
  public bimInfoStatus = -1;
  public bimInfoType = 'province';
  
  public page = 0;
  public size = 10;
  public total = 0;
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];
  
  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public currentBimInfo = null;

  columns: SimpleTableColumn[] = [
    { title: '作品名称', index: 'name', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '团队名称', index: 'teamName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '学校', index: 'schoolName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '赛项', index: 'eventsName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '专项', index: 'itemName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '上传时间', index: 'uploadDate', width: '12%', format: (item: any, col: any) => this.formatDate(item.uploadDate)  },
    {
      title: '操作', width: '8%',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.remove(item) }
      ],
    }
  ];

  loading = false;
  searchTeamName = '';
  searchSchoolName = '';
  searchWorkName = '';
  searchSchoolType = '-1';
  searchRegion = '-1'; //大区
  regionList: any = [{id:'',regionName:''}];
  searchProvinces = '-1'; //省份
  provincesList: any = [{id: '', provinceName: ''}];
  searchBimInfo = '-1'; //赛事
  bimInfoList: any = [{id: '', name: ''}];
  searchBimInfoEvents = ''; //赛项
  bimInfoEventsList: any = [{id: '', name: ''}];
  
  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private workManageService: WorkManageService,
    private bimInfoEventsService: BimInfoEventsService,
  ) {
  }

  ngOnInit(): void {
  }
  
  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    if (isReset) {
      this.page = 0;
    }
    const params = {
      page: this.page,
      size: this.size,
      'workType.equals': 0,
      sort: ['uploadDate,desc'],
    };
    console.log(this.searchTeamName)
    if (isReset) {
      this.searchTeamName = null;
      this.searchSchoolName = null;
      this.searchWorkName = null;
      this.searchSchoolType = '-1';
      this.searchBimInfoEvents = '-1';
    }
    if (this.searchTeamName && this.searchTeamName.length > 0) {
      params['teamName.contains'] = this.searchTeamName.trim();
    }
    if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      params['schoolName.contains'] = this.searchSchoolName.trim();
    }
    if (this.searchWorkName && this.searchWorkName.length > 0) {
      params['name.contains'] = this.searchWorkName.trim();
    }
    if (this.searchSchoolType && this.searchSchoolType !== '-1') {
      params['schoolType.equals'] = this.searchSchoolType;
    }
    if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
      params['eventsId.equals'] = this.searchBimInfoEvents;
    } else {
      params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
    }
    this.loading = true;
    this.workManageService.getBimWork(params).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        this.list = res.body;
        this.total = parseInt(res.headers.get('X-Total-Count'));
        this.list.forEach(item => {
          item.checked = false;
        })
      }
    },error1 => {
      this.loading = false;
      console.error(error1.message);
      MessageHelper.error('获取数据错误',error1.status);
    })
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }
  
  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    this.getBimInfoEvents(bimInfo.id);
  }

  /**
   * 根据赛事ID获取赛项
   */
  getBimInfoEvents(value) {
    if (value === '-1') {
      this.searchBimInfoEvents = '';
      this.bimInfoEventsList = [];
      return;
    }

    const params = {
      'bimInfoId.equals': value,
      'isDelete.equals': 0,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.bimInfoEventsService.query(params).subscribe(res => {
      if (res && res.body) {
        this.bimInfoEventsList = [{id: '-1', name: '全部'}];
        this.bimInfoEventsList.push(...res.body);
        this.searchBimInfoEvents = '-1';
        this.getDataList();
      }
    })
  }
  
  addBimWork() {
    if (this.currentBimInfo == null) {
      this.msg.warning('请先选择一场大赛');
      return;
    }
    this.modal.static(BimWorkAddComponent, {bimInfo: this.currentBimInfo}, 1200, {}).subscribe(res => {
      this.getDataList();
    })
  }

  formatDate(time) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * 编辑页面
   */
  edit(item) {
    this.modal.static(BimWorkAddComponent, {
      bimInfo: this.currentBimInfo,
      workInfo: item,
      isEdit: true,
    }, 1200, {}).subscribe(res => {
      this.getDataList();
    })
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.loading = true;
        this.workManageService.deleteBimWork(item.id).subscribe(res => {
          const data = res.body
          this.loading = false;
          if (data.success) {
            this.msg.success(data.data);
            this.getDataList();
          } else {
            this.msg.success(data.message);
          }
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  
}