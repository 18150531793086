import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatProjectNicheStatus' })
/**
 * 格式化项目商机成单状态管道
 */
export class formatProjectNicheStatusPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        // 0 未成单 1 部分成单 3 全部成单
        const valueNew = typeof value == 'number' ? value.toString() : value;
        switch (valueNew) {
            case '0':
                return "跟进中";
            case '1':
                return "已关闭";
            default:
                return '跟进中';
        }
    }
}
