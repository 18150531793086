import { Pipe, PipeTransform } from '@angular/core';
import { PipeManager } from '../pipeManager';


@Pipe({ name: 'formatProjectStatus' })
/**
 * 格式化项目立项状态管道
 * args : args[0] 为立项状态  args[1]为结项状态
 */
export class formatProjectStatusPipe implements PipeTransform {
 
    transform(value: any, ...args: any[]): any {
    
        // 0 立项待提交 
        // 20 立项待提交  21 立项已提交    22 立项审核中     24  立项审核不通过
        // 50 商机跟进中（立项审核通过后结项前）、
        // 60 结项申请待提交 61 结项已提交 62 结项审核中 64 结项审核不通过
        // 80 已结项（数据统计：通过验收/未通过验收或者教育部审核通过都算已结算）
        
        const valueNew = typeof value == 'number' ? value.toString() : value;
        const establishStatus = args && args.length > 0 ? args[0] : '';
        const closingStatus = args && args.length > 1 ? args[1] : '';
        
        switch (valueNew) {
            case '0':
                return "立项待提交";
            case '9':
                return "立项退回修改";
            case '20':
                return '立项待提交';
            case '21':
                return '立项已提交';
            case '22':
                return PipeManager.formatEstablishStatus().transform(establishStatus);
                // return '立项审核中';
            case '23':
                return '立项不通过';
            case '50':
                return '商机跟进中';
            case '59':
                return '结项退回修改';
            case '60':
                return '结项申请待提交';
            case '61':
                return '结项已提交';
            case '62':
                return PipeManager.formatClosingStatus().transform(closingStatus);
                // return '结项审核中';
            case '63':
                return '结项不通过';
            case '80':
                return '已结项';
            default:
                return '立项待提交';
        }
    }
}
