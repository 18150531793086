import {Injectable} from '@angular/core';
import * as $ from 'jquery';
/**
 *
 * 服务接口地址
 * @export
 * @class HttpAddressService
 */
@Injectable({providedIn: 'root'})
export class  HttpAddressService {

    /**
     *
     * 网关
     * @private
     * @type {string}
     * @memberof HttpAddressService
     */
    private _apiGateway: string;
    /**
     *
     * 认证
     * @private
     * @type {string}
     * @memberof HttpAddressService
     */
    private _authServe: string;
    
    /**
     * 系统管理配置
     */
    private _systemServe:string;

    /**
     * 教育服务配置
     */
    private _eduServe:string;

    /**
     * BIM大赛服务配置
     */
    private _bimServe:string;

    /**
     * 产学合作协同育人配置
     */
    private _iucceServe:string;

    /**
     * 文件服务配置
     */
    private _fileServer: string;

    /**
     * 工作流配置
     */
    private _workflowServe:string;

    /**
     * 传输公钥
     */
    private _transferPublicKey:string;

    /**
     * 作品预览页面
     */
    private _previewUrl: string;

    /**
     * 课程学习页面
     */
    private _courseUrl: string;

    private _OssServe: any;

    constructor() {

    }

    /**
     *
     * 下载配置文件进行请求地址初始化
     * @returns
     * @memberof HttpAddressService
     */
    getApplicationConfig() {
        const thisClone = this;
        return new Promise((_resolve, _reject) => {
            $.ajax({
                url: '/assets/appconfig.json',
                method: 'GET'
            }).done(result => {
                thisClone._authServe = result.AuthServe;
                thisClone._apiGateway = result.ApiGateway;              
                thisClone._systemServe = result.SystemServe;
                thisClone._eduServe = result.EduServe;
                thisClone._bimServe = result.BimServe;
                thisClone._iucceServe = result.IucceServe;
                thisClone._fileServer = result.FileServer;
                thisClone._workflowServe = result.WorkflowServe;
                thisClone._transferPublicKey = result.TransferPublicKey;
                thisClone._previewUrl = result.PreviewUrl;
                thisClone._courseUrl = result.LearnUrl;
                thisClone._OssServe = result.OssServe;
                _resolve();
            });
        });
    }
    /**
     *
     * 网关服务地址
     * @readonly
     * @type {string}
     * @memberof HttpAddressService
     */
    get  apiGateway(): string {
        return this._apiGateway;
    }
    /**
     *
     * 认证服务地址
     * @readonly
     * @type {string}
     * @memberof HttpAddressService
     */
    get  authServe(): string {
        return this._authServe;
    }
   
    /**
     * 获取系统服务信息
     * @returns {any}
     */
    get systemServe():any{
      return this._systemServe;
    }

    /**
     * 获取教育服务信息
     */
    get EduServe():any{
        return this._eduServe;
    }

    /**
     * 获取BIM大赛服务信息
     */
    get BimServe():any{
      return this._bimServe;
    }

    /**
     * 获取产学合作协同育人服务信息
     */
    get IucceServe():any{
      return this._iucceServe;
    }


      /**
     * 获取文件服务信息
     */
    get FileServer(): any {
      return this._fileServer;
  }
 
  /**
   *
   *获取传输公钥
   * @readonly
   * @type {*}
   * @memberof HttpAddressService
   */
  get TransferPublicKey():any{
    return this._transferPublicKey;
  }

    /**
   *
   *获取传输公钥
   * @readonly
   * @type {*}
   * @memberof HttpAddressService
   */
  get workflowServe():any{
    return this._workflowServe;
  }

  /**
   *
   *获取作品预览页面
   * @readonly
   * @type {*}
   * @memberof HttpAddressService
  */
  get PreviewUrl(): any {
    return this._previewUrl;
  }

  /**
   *
   *获取课程学习页面
   * @readonly
   * @type {*}
   * @memberof HttpAddressService
  */
  get CourseUrl(): any {
    return this._courseUrl;
  }

  /**
   *
   * 获取OSS服务地址
   * @readonly
   * @type {*}
   * @memberof HttpAddressService
   */
  get ossServe(): any {
      return this._OssServe;
  }
}
