import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimInfo } from '../bim-info.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimInfoService } from '../bim-info.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { DictService } from '@shared/components/dict/dict.service';
import { isNullOrUndefined } from 'util';
import { SchoolService } from '@shared/components/provincesColleges/school.service';
import { CacheService } from '@delon/cache';
import { GuidUtil } from '@shared/utils/guid.util';
import { BimInfoCompanyService } from '../bim-info-company/bim-info-company.service';
import * as moment from 'moment';

@Component({
  selector: 'app-bim-bimInfo-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoEditComponent implements OnInit {

  public BIM_STATUS = {'0': '草稿',  '1' : '发布', '2' : '关闭'};

  public BIM_TYPE = {'bim': '全国赛事',  'province' : '省级赛事',  'enterprise' : '行业赛'};
  record: any = {};

  BimTitle="上传BIM大赛banner图片";
  buttonIsVisible=true;
  // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';


      // 要更新附件外键的集合
      hasToUpdate = false;
      toUpdateFkBannerFileList = [];
      toUpdateFkPageImgFileList = [];

  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  extField1Banner={'extField1.equals': "BIM_BANNER"};
  extField1BannerMobile={'extField1.equals': "BIM_BANNER_MOBILE"};
  extField1PageImg={'extField1.equals': "BIM_PAGEIMG"};

  public registeFileName = null;
  public registeFileId = null;
  contestCityList = [];//比赛城市
  dateFormat = 'yyyy/MM/dd';
  previewAddress: string;

  public proviceCitys: any;
  userProvinceList=[];

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimInfoService: BimInfoService,
    private dictService: DictService,
    public schoolService: SchoolService,
    private cacheService: CacheService,
    private bimInfoCompanyService: BimInfoCompanyService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, []],
      status: [null, [Validators.required]],
      type: ['bim', [Validators.required]],
      contestCityId: [null,[]],
      isDelete: [0, []],
      userDefine4: [null, []],//大赛移动端图片
      userDefine5: [null, []],//大赛缩略图图片
      userDefine6: [null, []],//大赛banner图片
      registeFileName: [null, []],

      proviceCity: [null, []],
      province: [null, []],
      city: [null, []],
      isLoginRequire: ['1', []],
      isSchoolRequire: ['1', []],
      teamCount: [6, []],
      teamStuCount: [5, []],
      teacherCount: [5, []],
      instructorCount: [5, []],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
    this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';
    this.previewAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/preview/';

  }
  
  mainCompanyList: Array<{
    id: string,
    bimInfoId: null,
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    companyLogoUrl: null,
    type: null,
    orderNum: number,
  }> = [];
  
  secdCompanyList: Array<{
    id: string,
    bimInfoId: null,
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    companyLogoUrl: null,
    type: null,
    orderNum: null,
  }> = [];
  
  thrdCompanyList: Array<{
    id: string,
    bimInfoId: null,
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    companyLogoUrl: null,
    type: null,
    orderNum: null,
  }> = [];
  
  tagCompanyList: Array<{
    id: string,
    bimInfoId: null,
    companyName: null,
    companyUrl: null,
    companyLogo: null,
    companyLogoUrl: null,
    type: null,
    orderNum: number,
  }> = [];
  
  imgList = [];

  ngOnInit() {
    if (this.userProvinceList && this.userProvinceList.length > 0) {
      delete this.BIM_TYPE['bim'];
    }
    if (!this.isAdd) {
      this.fileId = this.record.id;
      this.loadData();
    }else{
      this.fileId = new GuidUtil().toString();
    }

    this.initContestCityData();
    this.loadOptions();
  }

  loadData(): void {
      this.record = JSON.parse(JSON.stringify(this.record));
      if (!isNullOrUndefined(this.record.startDate) && !isNullOrUndefined(this.record.endDate)) {
        this.record.startDate = [this.record.startDate, this.record.endDate];
      } else if (!isNullOrUndefined(this.record.startDate)) {
        this.record.startDate = [this.record.startDate, null];
      } else {
        this.record.startDate = null;
      }
      
      if (this.record.province !== null || this.record.city !== null) {
        this.record.proviceCity = [this.record.province, this.record.city];
      }
      this.record.status = this.record.status.toString();
      this.form.patchValue(this.record);

      this.fileUploadService.query({
        'fileFk.equals': this.record.id,
        'extField1.equals': 'studentRegisteTemplate'
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          this.registeFileName = res.body[0].fileName;
          this.registeFileId = res.body[0].id;
        }
      });
      // if (this.record.type === 'province' || this.record.type === 'bim') {
        this.bimInfoCompanyService.query({
          'bimInfoId.equals': this.record.id,
          page: 0,
          pageSize: 1000,
        }).subscribe(res => {
          if (res && res.body.length > 0) {
            const fileData = res.body;
            this.mainCompanyList = this.filterCompanyData(fileData, '1');
            this.secdCompanyList = this.filterCompanyData(fileData, '2');
            this.thrdCompanyList = this.filterCompanyData(fileData, '3');
            this.tagCompanyList = this.filterCompanyData(fileData, '4');
          }
        });
      // }
    // this.msg.loading('加载中');
    // this.bimInfoService.find(this.bimId).subscribe((res: any) => {
    //   const record = res.body;

    //    this.form.controls.id.setValue(this.record.id);
    //    this.form.controls.name.setValue(this.record.name);
    //    this.form.controls.startDate.setValue(this.record.startDate);
    //   this.form.controls.status.setValue(this.record.status.toString);
    // });
  }

  filterCompanyData(fileData, type) {
    const list = fileData.filter(e => e.type === type).sort((a, b) => a.orderNum - b.orderNum);
    list.forEach(e => {
      if (!isNullOrUndefined(e.companyLogo)) {
        e['companyLogoUrl'] = this.previewAddress + e.companyLogo;
      }
    });
    return list;
  }

  /**
   * 加载比赛城市数据
   */
  initContestCityData(){
    console.log(this.userProvinceList);
    this.dictService.findAllByDictNo('IndustryContest').subscribe(res => {
      // this.contestCityList = res.body;
      const citys = res.body;
      if (this.userProvinceList && this.userProvinceList.length > 0) {
        for (let i = 0; i < citys.length; i++) {
          const city = citys[i];
          if (this.userProvinceList.includes(city.itemText)) {
            this.contestCityList.push(city);
          }
        }
      } else {
        this.contestCityList = res.body;
      }
    });
  }

  fnChangeType(itemSelect){
    console.log(itemSelect);
    this.form.get('contestCityId').clearValidators();
    this.form.get('proviceCity').clearValidators();
    this.form.get('province').clearValidators();
    this.form.get('city').clearValidators();
    this.form.get('isLoginRequire').clearValidators();
    this.form.get('isSchoolRequire').clearValidators();
    this.form.get('teamCount').clearValidators();
    this.form.get('teacherCount').clearValidators();
    if (itemSelect === 'enterprise') {
       //清除校验
       this.form.get('contestCityId').setValidators(Validators.required);
      //  this.form.get('contestCityId').clearValidators();
    }
    if (itemSelect === 'province') {
      if (!this.record) {
        this.form.get('isLoginRequire').setValue('1');
        this.form.get('isSchoolRequire').setValue('1');
        this.form.get('teamCount').setValue(1);
        this.form.get('teacherCount').setValue(1);
      }
      this.form.get('proviceCity').setValidators(Validators.required);
      this.form.get('province').setValidators(Validators.required);
      this.form.get('city').setValidators(Validators.required);
      this.form.get('isLoginRequire').setValidators(Validators.required);
      this.form.get('isSchoolRequire').setValidators(Validators.required);
      this.form.get('teamCount').setValidators(Validators.required);
      this.form.get('teamStuCount').setValidators(Validators.required);
      this.form.get('teacherCount').setValidators(Validators.required);
      this.form.get('instructorCount').setValidators(Validators.required);
       //清除校验
      //  this.form.get('province').clearValidators();
      //  this.form.get('city').clearValidators();
      //  this.form.get('isLoginRequire').clearValidators();
      //  this.form.get('isSchoolRequire').clearValidators();
      //  this.form.get('teamCount').clearValidators();
      //  this.form.get('teacherCount').clearValidators();
    }
  }

  // 保存
  submit() {
    //  this.buttonIsVisible=false;
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查大赛信息是否完整');
      this.buttonIsVisible=true;
      return;
    }

    const time = param['startDate'];
    param['startDate'] = moment(time[0]).add(8, 'h');
    param['endDate'] = moment(time[1]).add(8, 'h');

    if (param['type'] === 'province' || param['type'] === 'bim') {
      delete param['contestCityId'];
      param['mainCompanyList'] = this.mainCompanyList;
      param['mainCompanyList'].forEach(e => {
        e.companyLogoUrl = null;
      });
      param['secdCompanyList'] = this.secdCompanyList;
      param['secdCompanyList'].forEach(e => {
        e.companyLogoUrl = null;
      });
      param['thrdCompanyList'] = this.thrdCompanyList;
      param['thrdCompanyList'].forEach(e => {
        e.companyLogoUrl = null;
      });
    }
    param['tagCompanyList'] = this.tagCompanyList;
    if (param['type'] !== 'province') {
      delete param['province'];
      delete param['city'];
      delete param['isLoginRequire'];
      delete param['isSchoolRequire'];
      delete param['teamCount'];
      delete param['teacherCount'];
    }

    if (this.isAdd) {
      if(( this.toUpdateFkBannerFileList===null ||  this.toUpdateFkBannerFileList == undefined || this.toUpdateFkBannerFileList.length <=0))
      {
        this.msg.error('大赛banner图片没有上传，不能保存！');
        this.buttonIsVisible=true;
        return;
      } else {
        if (this.toUpdateFkBannerFileList.length < 2) {
          const data = this.toUpdateFkBannerFileList[0];
          if (isNullOrUndefined(data['userDefine4'])) {
            this.msg.error('大赛移动端banner图片没有上传，不能保存！');
          } else {
            this.msg.error('大赛banner图片没有上传，不能保存！');
          }
          this.buttonIsVisible=true;
          return;
        }
      }
      
      if(( this.toUpdateFkPageImgFileList===null ||  this.toUpdateFkPageImgFileList == undefined || this.toUpdateFkPageImgFileList.length <=0))
      {
        this.msg.error('大赛缩略图片没有上传，不能保存！');
        this.buttonIsVisible=true;
        return;
      }
    } else {
      if (param['userDefine6'] === null) {
        this.msg.error('大赛banner图片没有上传，不能保存！');
        this.buttonIsVisible=true;
        return;
      }
      if (param['userDefine4'] === null) {
        this.msg.error('大赛移动端banner图片没有上传，不能保存！');
        this.buttonIsVisible=true;
        return;
      }
      if (param['userDefine5'] === null) {
        this.msg.error('大赛缩略图片没有上传，不能保存！');
        this.buttonIsVisible=true;
        return;
      }
    }
    
    this.bimInfoService.registerBimInfo(param).subscribe(res => {
      this.imgList = [];
      this.record = res.body.data;
      if (this.record) {
        this.hasToUpdate = true;
        this.msg.info(this.isAdd === true ? '保存成功' : '修改成功');
        this.buttonIsVisible=true;
        this.close(true);
      } else {
        this.msg.error(this.isAdd === true ? '保存失败' : '修改失败');
        this.buttonIsVisible=true;
      }
    });
    // if (this.isAdd === true) {
    //   this.addQuestionInfo(param);
    // } else {

    //   this.modifyQuestionInfo(param);
    // }

  }


  modifyQuestionInfo(param: any) {
    this.bimInfoService.update(param).subscribe(res => {
      this.imgList = [];
      const record = res.body;
      if (record  ) {
        this.hasToUpdate = true;
        this.msg.info('修改成功');
        this.buttonIsVisible=true;
        this.close(true);
        //this.nzModalRef.destroy();
      } else {
        this.msg.error('修改失败');
        this.buttonIsVisible=true;
      }
    });
  }


  addQuestionInfo(param: any) {
    this.bimInfoService.create(param).subscribe(res => {
      this.imgList = [];
      this.record = res.body;
      if (this.record) {
        this.fileId = this.record.id;
        this.hasToUpdate = true;
        this.msg.info('保存成功');
        this.buttonIsVisible=true;
        this.close(true);
        //this.nzModalRef.destroy();
      } else {
        this.msg.error('保存失败');
        this.buttonIsVisible=true;
      }
    });
  }


  // Cancel(){
  //   this.nzModalRef.destroy();
  // }

 

  prefixChange(i: string, p: boolean) {
    const flag = this.form.controls['prefix' + i].value;
    this.form.controls['prefix' + i].setValue(flag);
  }

  // 上传文件参数
  fileParam = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.fileId,
      fileDesc: file.name,
      uploader: this.entityName,
      fileType: file.name.substring(file.name.indexOf('.') + 1),
      extField1: 'bimCompanyLogo'
    };
    return tmp;
  }


  beforeUpload = (file: UploadFile): boolean => {
    const format = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf', 'bmp', 'jpg', 'png', 'gif', 'svg', 'jpeg'];
    const type = file.name.substring(file.name.indexOf('.') + 1).toLowerCase();
    if (format.indexOf(type) < 0) {
      this.msg.error('格式错误,仅支持' + format + '格式');
      return false;
    }
    this.fileList.push(file);
    return true;
  }


  handleUploadChange(item: any) {
    if (item.type === 'success') {
      const res = item.file.response;
      this.form.controls['fileId'].setValue(res.id);
    }
  }


  statusChange(event) {

  }



  
     /**
      * 上传成功回调
      * @param file
      */
     onSuccessfulBanner(file: any) {

        const thisSuccessFile = file.successFile;
        if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
          thisSuccessFile.forEach(item => {
            this.toUpdateFkBannerFileList.push(item);
            this.form.controls['userDefine6'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
          });
        }
      }

      /**
      * 上传成功回调
      * @param file
      */
     onSuccessfulBannerMobile(file: any) {

      const thisSuccessFile = file.successFile;
      if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
        thisSuccessFile.forEach(item => {
          this.toUpdateFkBannerFileList.push(item);
          this.form.controls['userDefine4'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
        });
      }
    }

      /**
       * 删除成功回调
       * @param file
       */
      onDeleteFileBanner(file: any) {

        if (file.file) {
          this.toUpdateFkBannerFileList = this.toUpdateFkBannerFileList.filter(d => d.uid !== file.file.uid);
        }
        this.form.controls['userDefine6'].setValue(null);
      }

      /**
       * 删除成功回调
       * @param file
       */
      onDeleteFileBannerMobile(file: any) {

        if (file.file) {
          this.toUpdateFkBannerFileList = this.toUpdateFkBannerFileList.filter(d => d.uid !== file.file.uid);
        }
        this.form.controls['userDefine4'].setValue(null);
      }


           /**
      * 上传成功回调
      * @param file
      */
     onSuccessfulPageImg(file: any) {

      const thisSuccessFile = file.successFile;
      if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
        thisSuccessFile.forEach(item => {
          this.toUpdateFkPageImgFileList.push(item);
          this.form.controls['userDefine5'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
        });
      }
    }

    /**
     * 删除成功回调
     * @param file
     */
    onDeleteFilePageImg(file: any) {

      if (file.file) {
        this.toUpdateFkPageImgFileList = this.toUpdateFkPageImgFileList.filter(d => d.uid !== file.file.uid);
      }
      this.form.controls['userDefine5'].setValue(null);
    }



      // 关闭事件
  close(type?) {
    if (this.hasToUpdate && '' !== this.record.id) { // need
      //  this.closeUpdateFile().then(function () {
      //     this.nzModalRef.destroy(type);
      // }.bind(this));

    //  const _this_ = this;
      let isChangeData = false;
      if ( this.toUpdateFkBannerFileList.length > 0)
      {
        isChangeData = true;
          for (const i of Object.keys(this.toUpdateFkBannerFileList)) {
            this.fileUploadService.updateFkBatch(this.toUpdateFkBannerFileList[i].response.fileFk, this.record.id)
              .subscribe((res: any) => {  this.nzModalRef.destroy(type);}, (res: HttpResponse<any>) => this.msg.create('error', 'Banner图片上传失败'));
          }

          
      }

      if ( this.toUpdateFkPageImgFileList.length > 0)
      {
          isChangeData = true;
          for (const i of Object.keys(this.toUpdateFkPageImgFileList)) {
            this.fileUploadService.updateFkBatch(this.toUpdateFkPageImgFileList[i].response.fileFk, this.record.id)
              .subscribe((res: any) => { this.nzModalRef.destroy(type);}, (res: HttpResponse<any>) => this.msg.create('error', '缩略图片上传失败'));
          }
      }

      if(!isChangeData){
        this.nzModalRef.destroy(type);
      } else {
        console.log('报名模板上传');
        this.fileUploadService.updateFkBatch(this.fileId, this.record.id)
          .subscribe(() => { this.nzModalRef.destroy(type); });
      }

    } else {
      this.nzModalRef.destroy(type);
    }
  }

  
  /**
 * 销毁时删除附件，防止文件表过多
 */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    if (null !== this.toUpdateFkBannerFileList && this.toUpdateFkBannerFileList.length > 0 && !this.hasToUpdate) {
          const idArray = new Array();
          this.toUpdateFkBannerFileList.forEach((d) => {
            if (!isNullOrUndefined(d.id)) {
              idArray.push(d.id);
            } else {
              idArray.push(d.response.id);
            }
          });
          this.fileUploadService.delete(idArray).subscribe((res: any) => {
            console.log(idArray);
          });
    }



    if (null !== this.toUpdateFkPageImgFileList && this.toUpdateFkPageImgFileList.length > 0 && !this.hasToUpdate) {
      const idArray1 = new Array();
      this.toUpdateFkPageImgFileList.forEach((d) => {
        if (!isNullOrUndefined(d.id)) {
          idArray1.push(d.id);
        } else {
          idArray1.push(d.response.id);
        }
      });
      this.fileUploadService.delete(idArray1).subscribe((res: any) => {
        console.log(idArray1);
      });
    }

    if (this.isAdd && !isNullOrUndefined(this.registeFileId) && !this.hasToUpdate) {
      this.fileUploadService.delete(this.registeFileId).subscribe();
    }

    if (this.imgList.length > 0) {
      this.imgList.forEach(e => {
        this.fileUploadService.delete(e).subscribe();
      });
    }
  }

  beforeRegisteUpload = (file: UploadFile): boolean => {
    const imgFormat = ['doc', 'docx', 'DOC', 'DOCX'];
    const type = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (imgFormat.indexOf(type) < 0) {
      this.msg.remove();
      this.msg.error('格式错误,仅支持' + imgFormat + '格式');
      return false;
    }
    if (file.size > 1024 * 1024 * 20) {
      this.msg.remove();
      this.msg.error('支持2M以下文件上传');
      return false;
    }
    return true;
  }

  registeFileParam = (file: UploadFile) => {
    const tmp = {
      fileFk: this.fileId,
      name: file.name,
      fileDesc: file.name,
      uploader: this.entityName,
      fileType: file.name.substring(file.name.indexOf('.') + 1),
      extField1: 'studentRegisteTemplate'
    };
    return tmp;
  }

  registeUploadChange(item: any) {
    if (item.type === 'success') {
      const res = item.file.response;
      this.registeFileName = res.fileName;
      if (!isNullOrUndefined(this.registeFileId)) {
        this.fileUploadService.delete(this.registeFileId).subscribe();
      }
      this.registeFileId = res.id;
    }
  }

  removeRegisteFile() {
    if (!isNullOrUndefined(this.registeFileId)) {
      this.fileUploadService.delete(this.registeFileId).subscribe();
      this.registeFileId = null;
      this.registeFileName = null;
    }
  }

  loadOptions() {
    // this.proviceCitys = this.cacheService.getNone('provinceTree');
    // if (!this.proviceCitys || this.proviceCitys === null) {
    //  this.schoolService.queryProvinceTree().subscribe(res => {
    //      this.proviceCitys = res;
    //      this.cacheService.set('provinceTree', this.proviceCitys);
    //  });
    // }
    let proviceCitys: any = this.cacheService.getNone('provinceTree');
    if (!proviceCitys || proviceCitys === null) {
     this.schoolService.queryProvinceTree().subscribe(res => {
         this.proviceCitys = res;
         proviceCitys = this.proviceCitys;
         this.cacheService.set('provinceTree', this.proviceCitys);
     });
    }
    if (this.userProvinceList && this.userProvinceList.length > 0) {
      const citys = [];
      for (let i = 0; i < proviceCitys.length; i++) {
        const city = proviceCitys[i];
        if (this.userProvinceList.includes(city.label)) {
          citys.push(city);
        }
      }
      this.proviceCitys = citys;
    } else {
      this.proviceCitys = proviceCitys;
    }
  }

  onSelect(item: any) {
    if (!isNullOrUndefined(item) && item.index === 1) {
      this.form.controls['province'].setValue(item.option.parent.label);
      this.form.controls['city'].setValue(item.option.label);
    }
  }

  addCom(type): void {
    switch (type) {
      case '1':
        this.mainCompanyList = [...this.mainCompanyList, {
          id: new GuidUtil().toString(),
          bimInfoId: null,
          companyName: null,
          companyUrl: null,
          companyLogo: null,
          companyLogoUrl: null,
          type: type,
          orderNum: this.mainCompanyList.length + 1,
        }];
        break;
      case '2':
        this.secdCompanyList = [...this.secdCompanyList, {
          id: new GuidUtil().toString(),
          bimInfoId: null,
          companyName: null,
          companyUrl: null,
          companyLogo: null,
          companyLogoUrl: null,
          type: type,
          orderNum: null,
        }];
        break;
      case '3':
        this.thrdCompanyList = [...this.thrdCompanyList, {
          id: new GuidUtil().toString(),
          bimInfoId: null,
          companyName: null,
          companyUrl: null,
          companyLogo: null,
          companyLogoUrl: null,
          type: type,
          orderNum: null,
        }];
        break;
      case '4':
        this.tagCompanyList = [...this.tagCompanyList, {
          id: new GuidUtil().toString(),
          bimInfoId: null,
          companyName: null,
          companyUrl: null,
          companyLogo: null,
          companyLogoUrl: null,
          type: type,
          orderNum: this.tagCompanyList.length + 1,
        }];
        break;
      default:
        break;
    }
  }

  removeCom(id: string, type, imgId): void {
    switch (type) {
      case '1':
        // if (this.mainCompanyList.length > 1) {
          this.mainCompanyList = this.mainCompanyList.filter(d => d.id !== id);
        // }
        break;
      case '2':
        // if (this.secdCompanyList.length > 1) {
          this.secdCompanyList = this.secdCompanyList.filter(d => d.id !== id);
        // }
        break;
      case '3':
        // if (this.thrdCompanyList.length > 1) {
          this.thrdCompanyList = this.thrdCompanyList.filter(d => d.id !== id);
        // }
        break;
      case '4':
        // if (this.tagCompanyList.length > 1) {
          this.tagCompanyList = this.tagCompanyList.filter(d => d.id !== id);
        // }
        break;
      default:
        break;
    }
    if (!isNullOrUndefined(imgId)) {
      this.fileUploadService.delete(imgId).subscribe();
    }
  }

  beforeUploadLogo = (file: UploadFile): boolean => {
    const imgFormat = ['jpg', 'png', 'jpeg', 'bmp', 'ico'];
    const type = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (imgFormat.indexOf(type) < 0) {
      this.msg.remove();
      this.msg.error('格式错误,仅支持' + imgFormat + '格式');
      return false;
    }

    if (file.size > 100 * 1024) {
      this.msg.remove();
      this.msg.error('支持100KB以下图片上传');
      return false;
    }
    return true;
  }

  // 上传成功后把旧文件删除
  handleChange(event: any, id, type) {
    if (event.type === 'success') {
      console.log(event);
      this.imgList.push(event.file.response.id);

      switch (type) {
        case '1':
          this.mainCompanyList.forEach(e => {
            if (e.id === id) {
              // 删除之前上传的文件
              if (!isNullOrUndefined(e.companyLogo)) {
                this.fileUploadService.delete(e.companyLogo).subscribe();
              }
              e.companyLogo = event.file.response.id;
              e.companyLogoUrl = event.file.thumbUrl;
            }
          });
          break;
        case '2':
          this.secdCompanyList.forEach(e => {
            if (e.id === id) {
              // 删除之前上传的文件
              if (!isNullOrUndefined(e.companyLogo)) {
                this.fileUploadService.delete(e.companyLogo).subscribe();
              }
              e.companyLogo = event.file.response.id;
              e.companyLogoUrl = event.file.thumbUrl;
            }
          });
          break;
        case '3':
          this.thrdCompanyList.forEach(e => {
            if (e.id === id) {
              // 删除之前上传的文件
              if (!isNullOrUndefined(e.companyLogo)) {
                this.fileUploadService.delete(e.companyLogo).subscribe();
              }
              e.companyLogo = event.file.response.id;
              e.companyLogoUrl = event.file.thumbUrl;
            }
          });
          break;
        default:
          break;
      }
    }
  }
}
