import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { Homework } from './homework.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeworkService extends ThsBaseService<Homework> {
  courseUrl = '';
  catalogUrl = '';
  topicUrl = '';
  homeworkTypeUrl = '';
  subjectUrl = '';
  constructor(
    http: HttpClient,
    httpAddressService: HttpAddressService,
  ) {
    super(http, httpAddressService.EduServe + `/edu-homeworks`);
    this.courseUrl = httpAddressService.EduServe + '/edu-courses';
    this.catalogUrl = httpAddressService.EduServe + '/edu-course-catalogs';
    this.topicUrl = httpAddressService.EduServe + '/edu-homework-topics';
    this.subjectUrl = httpAddressService.EduServe + '/edu-subjects';
    this.homeworkTypeUrl = httpAddressService.EduServe + '/edu-homework-types';
  }
  queryHomeworkCourseCatalog(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllHomeworkCourseCatalog`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
  queryCourseAdmin(isDelete: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/getCourseByAdmin?isDelete=' + isDelete;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }

  queryCourse(teacherId: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/getCourseByTeacherId?teacherId=' + teacherId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryCourseById(courseId: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/' + courseId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }

  querySubject(queryParams?: any): Observable<HttpResponse<any[]>> {
    const url = this.subjectUrl + '/getSubjectByCatalogIdAndType';
    return this.http.get<any[]>(url , { params: queryParams , observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryCatalog(courseId: string): Observable<HttpResponse<any[]>> {
    const url = this.catalogUrl + '/getCatalogByCourseId?courseId=' + courseId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  updateHomeworkInfo(param: any) {
    const url = `${this.resourceUrl}/saveHomeworkInfo`;
    const copy = this.convertDateFromClient(param);
    return this.http.post<any>(url, copy, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
  deleteHomeworkInfo(examId: string) {
    const url = `${this.resourceUrl}/deleteHomeworkInfo?homeworkId=` + examId;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  queryHomeworkTypeAndTopic(homeworkId: string): Observable<HttpResponse<any[]>> {
    const url = this.topicUrl + '/getTopicAndTypeByHomeworkId?homeworkId=' + homeworkId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryHomeworkType(homeworkId: string): Observable<HttpResponse<any[]>> {
    const url = this.homeworkTypeUrl + '/getHomeworkTypeByHomeworkId?homeworkId=' + homeworkId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  querySubjectToAutoSelect(queryParams?: any): Observable<HttpResponse<any[]>> {
    const url = this.subjectUrl + '/getSubjectIdToAutoSelect';
    return this.http.get<any[]>(url , { params: queryParams , observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryTopicsByHomeworkId(homeworkId: string): Observable<HttpResponse<any[]>> {
    const url =  this.topicUrl + '/getSelectTopicByHomeworkId?homeworkId=' + homeworkId;
    return this.http.get<any[]>(url , { observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
}
