// 权限点
export const APP_PERMISSIONS = {
    CG_VIEW : '52cff40e-0d00-408f-b4cb-29968c177979',
    CONTRACT_ALL : '1a47e8bd-18ba-4430-b220-fe5df8957e43',
    CONTRACT_COMPANY : '5c1ce0a7-5f2c-48bc-8ac7-626bccdcc875',
    CONTRACT_CREATE : 'b76805c8-df7d-436c-a48e-3dec7ac4714d',
    CONTRACT_DELETE : '0d52331d-c34d-448a-8fce-f73b95233dd6',
    CONTRACT_DEPT : 'ead847bc-6c2e-4298-a229-957dc2d034bc',
    CONTRACT_EXPORT : '7de7ba6d-645a-4750-86e5-b988ae4c9709',
    CONTRACT_INCOMING_VIEW : '4952c0e6-0e26-4701-b51c-00b54ec8e96e',
    CONTRACT_OUTCOMING_VIEW : '641943dc-f0ca-4cac-93ff-a17b9ada5947',
    CONTRACT_PERSON : 'f0e5c9d9-e370-4a3a-8b6a-330e445438de',
    CONTRACT_UPDATE : '9b7076ec-8992-4e54-b43d-7e3fe4309f71',
    CONTRACT_VIEW : '4df6d799-6946-4faa-81ef-86c6b3c4a215',
    CONTRACT_VIEW_ALL : '6cf18b32-eda6-4b13-bd2c-b78857a40759',
    CONTRACT_VIEW_COMPANY : '1db6c38b-3d1c-4e81-a191-78830f428a13',
    CONTRACT_VIEW_DEPT : '69b2f049-06b1-4e67-be52-67d1417af004',
    CONTRACT_VIEW_PERSON : 'b3cf5ce1-ba07-4044-a34a-8e026dde2071',
    CUSTOMER_CREATE : '0da74076-8268-43e0-9f56-d783b264f335',
    CUSTOMER_DELETE : 'd728efc2-b0b3-4189-ac2a-e413483cc0fa',
    CUSTOMER_EXPORT : 'ce4e1915-a351-43e1-b283-c60dfee8e921',
    CUSTOMER_UPDATE : 'd297940a-b97f-4ff5-9eb6-59a86ccb2280',
    CUSTOMER_VIEW : 'a10ef1ee-7381-4c6b-b98d-1309a1f36316',
    CUSTOMER_VIEW_ALL : '7105dc2e-c00f-49ac-81d8-8e7e31353645',
    CUSTOMER_VIEW_COMPANY : '0ac2abdb-d32a-4f85-a2de-787631ce1877',
    CUSTOMER_VIEW_DEPT : 'ef7d1c9e-b67b-42de-ad71-6363ef0c8230',
    CUSTOMER_VIEW_PERSON : '3cd5a94f-87bd-4ad5-bf36-04e04eeaab5f',
    DB_VIEW : '30d2d8c6-787a-4262-840f-7c57bce8d631',
    GCHTLB_CONTRACT_CREATE : 'f626b77d-f8ea-4c12-aafd-929300fdb1c4',
    GCHTLB_CONTRACT_DELETE : '9ec44e2d-a20e-4859-9979-3521db879a53',
    GCHTLB_CONTRACT_UPDATE : '2bc2772e-7240-42bb-bcfa-a54a2eb691a6',
    GCHTLB_CONTRACT_VIEW : 'bbdaa839-7d63-48d8-a1e0-521ed48ca46f',
    GCHTLB_CONTRACT_VIEW_ALL : 'd243dc1b-7135-43bc-9b2b-91621f315f03',
    GCHTLB_CONTRACT_VIEW_COMPANY : '9705694e-da6a-4857-99b3-8faca5086a1f',
    GCHTLB_CONTRACT_VIEW_DEPT : '9562b74c-aa3a-4986-a492-12c50cd5a4df',
    GCHTLB_CONTRACT_VIEW_PERSON : 'b885370c-73bc-4fcb-bfed-a82446963986',
    GCHTTZ_CONTRACT_EXPORT : '8e6e6f63-362c-4c2c-b282-6165cf0749b6',
    GCHTTZ_CONTRACT_VIEW_ALL : '033f3e0a-488f-4688-ba8b-7660505af84a',
    GCHTTZ_CONTRACT_VIEW_COMPANY : '6dba13c6-5bd9-45bf-b2a9-69607c38e928',
    GCHTTZ_CONTRACT_VIEW_DEPT : '1852a260-a3b3-4f89-860a-c9933fa69554',
    GCHTTZ_CONTRACT_VIEW_PERSON : '1806c464-d860-427f-8cc8-f2dfff5d279a',
    GC_CB_VIEW : 'c4f527df-f68b-4d20-98f8-702ad8ed7ddc',
    GC_EJSH_VIEW : 'c241dd0c-c7e7-41fe-bb86-02ed64b25f1a',
    GC_QF_VIEW : 'f143ead1-c5fe-4e92-846b-8c9771a3e4b9',
    GC_SJSH_VIEW : '555f2921-c858-44ae-b01a-b36a5b7d5d19',
    GC_WDXM_VIEW : '1f484d83-8d99-4b0f-92ad-99bda8254bea',
    GC_XGBB_EXPORT : '222cb691-82f9-459b-9ee3-e80b8367346e',
    GC_XGBB_VIEW_ALL : '1a56e638-713f-4927-aea9-f5073fec3852',
    GC_XGBB_VIEW_COMPANY : 'a22adcfa-84d4-43dc-8f24-7be4ce562842',
    GC_XGBB_VIEW_DEPT : '4710a6eb-c677-4371-8da3-83efe519e9f9',
    GC_XGBB_VIEW_PERSON : 'ea7a4e57-a7a8-4b79-b77a-fd4f052e0c94',
    GC_XMCH_VIEW : '67143aef-1ebb-4f84-99ee-07fd18050fd8',
    GC_XMDJ_ADDRESSEE : '237320b5-8007-4ea8-b405-d7f9baf2ab24',
    GC_XMDJ_CREATE : 'd2014f7a-f357-4424-b3cc-81267aedd9e8',
    GC_XMDJ_DELETE : '0d26fdb1-6814-4704-8411-e95e1df7290f',
    GC_XMDJ_EXPORT : '1b64e93f-d479-44c8-bf9b-32ba453eb5fc',
    GC_XMDJ_UPDATE : '1bcebc65-c1b5-44dd-b328-1d550b7fccd6',
    GC_XMDJ_VIEW : '64732aba-3112-40b5-8c57-93998c88611e',
    GC_XMDJ_VIEW_ALL : '520e8d30-56c7-423a-bf89-558bf75415db',
    GC_XMDJ_VIEW_COMPANY : '2ff0ae16-adaf-478f-859e-4a856c5200cd',
    GC_XMDJ_VIEW_DEPT : '263dbd93-739b-4108-8c15-c0c297a0b54d',
    GC_XMDJ_VIEW_PERSON : 'f47feefc-3cee-4bb7-aa39-535535ef83fc',
    GC_XMGD_ARCHIVE : 'b8fa5d91-9b40-42ee-83a0-02822305c164',
    GC_XMGD_VIEW_ALL : 'caa45b4c-6220-40bf-9490-7a30921f203a',
    GC_XMGD_VIEW_COMPANY : 'dc7cb93b-3b13-4af9-ba7d-34cad85bee03',
    GC_XMGD_VIEW_DEPT : '40ac00be-d18a-43a8-b64c-4e1be307115c',
    GC_XMGD_VIEW_PERSON : '6ac7f482-303b-4c83-b888-41dbec074914',
    GC_XMGZ_EXPORT : '912258bd-2d80-4094-a488-ace8e540e26d',
    GC_XMGZ_VIEW_ALL : '6f228251-e13f-4a3b-9d0f-f8a51b89b334',
    GC_XMGZ_VIEW_COMPANY : '904ed334-a026-4d33-befd-4c52b744c2e7',
    GC_XMGZ_VIEW_DEPT : '9c01d199-a960-4433-a11f-48fa8f038219',
    GC_XMGZ_VIEW_DETAIL : 'c97d27c2-7476-498b-982e-9a1f86eb65a2',
    GC_XMGZ_VIEW_PERSON : 'b37b0baf-979c-4416-aae9-8c6fa353614c',
    GC_XMSH_VIEW : '024f90cb-dfb7-4063-8dda-ded07abb33d0',
    GC_XMSSHZ_VIEW : '857eca06-d326-49cd-92f6-c4fc19a7c01c',
    GC_XMSS_VIEW : '018118b1-d3fb-475f-9bb6-35e38957e81a',
    GC_XMTD_VIEW_PERSON : '3411a8c4-45a5-40b1-82f4-42dac089f7b5',
    GC_YJSH_VIEW : '4f1089ee-cb43-4d5d-ae4a-7867a4f5f872',
    GZMB_VIEW : 'd6cb25b7-435a-4093-a518-9e8dd3ef7c71',
    ORG_DEPT_CREATE : '3041473c-a7fb-4f4c-8dc4-1b47986821e0',
    ORG_DEPT_DELETE : 'e4a31ac3-bcd6-4407-8ecf-c5db4d95b090',
    ORG_DEPT_UPDATE : '34bb90a4-253a-484f-86e1-55cbb14b6787',
    ORG_DEPT_VIEW_ALL : 'deaa3d3e-839c-42d7-b787-f3dc1c573f91',
    ORG_DEPT_VIEW_COMPANY : '98118331-45de-4f77-8b41-0876a445c265',
    ORG_DUTY_CREATE : 'ab901d96-57b9-4642-9efe-a22739d5b875',
    ORG_DUTY_DELETE : '7a73fcf7-d086-4433-b2ba-6eb2bb1f47e2',
    ORG_DUTY_UPDATE : '9e960203-0bde-4eea-8a04-521502fe5794',
    ORG_DUTY_VIEW_ALL : 'a500282a-53a4-425a-a5e6-4371273ef4c6',
    ORG_DUTY_VIEW_COMPANY : 'c59e2607-d659-4bed-8f3e-340e1ba4a17e',
    ORG_DUTY_VIEW_DEPT : '61302b9d-b08d-490b-8f21-ba67179451aa',
    ORG_ORGANIZATIONS_CREATE : '724f0079-8acc-4272-99bb-04427360a71a',
    ORG_ORGANIZATIONS_DELETE : '965672fb-8321-4d4a-b8cb-14b723f53046',
    ORG_ORGANIZATIONS_UPDATE : 'a37af4c8-c2db-44e7-ba92-d0d0534328f9',
    ORG_ORGANIZATIONS_VIEW_ALL : 'f9168eb7-f1b0-4cf0-bcf7-db4c96bc367b',
    ORG_PERSON_CREATE : 'cbfde36c-3442-4562-a021-3e1cac35f1f7',
    ORG_PERSON_DELETE : '4a336dd1-9885-4567-8180-91e83fcc01eb',
    ORG_PERSON_UPDATE : '20b56aec-ffd4-44c1-92e6-7d0113f2026e',
    ORG_PERSON_VIEW_ALL : '2e771ab3-4c5a-430f-ba3b-ee8244cdcd88',
    ORG_PERSON_VIEW_COMPANY : '7941edec-a80c-4d84-a241-5805cf0c4812',
    ORG_PERSON_VIEW_DEPT : '56ffb14b-0fb1-4c47-9950-e26e5297eedd',
    PERSONAL_SETTINGS : 'a17a1e0c-7fe0-4554-a623-5961a47d6c59',
    SYS_CONTENT_CREATE : '62db7792-ef7e-4868-97fb-7f5e74d474f7',
    SYS_CONTENT_DELETE : '36607f6d-71f8-4e0d-bf1b-21c54b3d011d',
    SYS_CONTENT_UPDATE : '3e6e60b0-c9a2-446a-9285-a994c1a6db52',
    SYS_CONTENT_VIEW : '935c58c8-79e5-46f9-831c-6cd397df5d39',
    SYS_CONTENT_VIEW_ALL : '6896c23e-eac5-489f-9e9b-2e32b29cc8ba',
    SYS_CONTENT_VIEW_COMPANY : '47eb3238-021c-4a10-bd95-7bab3b053c1b',
    SYS_CONTENT_VIEW_DEPT : 'a4e359a0-a827-4b14-8349-7496a6b9107b',
    SYS_DICTS_CREATE : '51f74ac5-e845-4109-bad2-ab1a56b394b7',
    SYS_DICTS_DELETE : 'e39315e9-40e8-43d2-b240-da6a3b6c3d57',
    SYS_DICTS_UPDATE : '4c843c68-911e-4642-aff1-1076db27a047',
    SYS_DICTS_VIEW : '4c0b10e9-858d-46c2-bd22-f8e72d041893',
    SYS_FILECATALOGS_CREATE : '8fc41931-717a-4920-a2c5-8a7c54d8bd8d',
    SYS_FILECATALOGS_DELETE : '1020b145-3e20-415e-aed5-ca1a9ba28867',
    SYS_FILECATALOGS_UPDATE : '20057cab-9617-4199-8751-75befd91ad20',
    SYS_FILECATALOGS_VIEW : '9b51afef-3735-4f4f-a23e-7e53e963f8f1',
    SYS_MODULES_CREATE : 'b3fc4b51-5519-46ba-b952-687f6ae15dad',
    SYS_MODULES_DELETE : '97d031b7-d876-4a57-bdbb-1dcb5ad0d3d9',
    SYS_MODULES_UPDATE : '54d5128b-acd7-4da0-9bf9-6e168d450fc2',
    SYS_MODULES_VIEW : '371c4549-5700-4ae7-8310-016880836ac3',
    SYS_NOTIC_VIEW : '9504fc54-e561-4073-8a96-8b8a1be18402',
    SYS_ROLE_CREATE : '4801de20-7862-48e6-a2b9-34f2ae9b683d',
    SYS_ROLE_DELETE : 'c72c5026-7999-467a-8073-e6d2dcf3e398',
    SYS_ROLE_UPDATE : '86063001-73f0-4c45-b5aa-d80deb9e6255',
    SYS_ROLE_VIEW_ALL : '2378f407-76d9-4068-b6fd-c6a6bf57596f',
    SYS_ROLE_VIEW_COMPANY : '396a04de-0b28-44a0-848e-4cb6801d056e',
    SYS_ROLE_VIEW_DEPT : '3161dea6-8d8f-40b0-a224-099e85b8cea2',
    SYS_ROLE_VIEW_PERSON : '843a1624-eb2e-435d-9e1f-357f97297722',
    SYS_SETTINGS_UPDATE : 'f3046895-fca2-4415-93c7-d0ebc4a63882',
    SYS_USER_CREATE : 'eb51417b-6426-4913-bad8-f5b4ad09ca72',
    SYS_USER_DELETE : 'e5abd1a7-fe88-4164-a6c5-855b125b338f',
    SYS_USER_UPDATE : 'ff9f565a-1326-4ed6-8206-f693e16b25d7',
    SYS_USER_VIEW_ALL : 'ac66e67c-0ac3-4bdf-b786-b79f772d88ff',
    SYS_USER_VIEW_COMPANY : '2ec1671a-3c34-48d0-bd99-0c48e8857529',
    SYS_USER_VIEW_DEPT : 'cfb8365b-8847-4ad1-a3ec-0016213d3eea',
    WF_FLOWASSISTANT_CREATE : '36715df0-2ecc-41d1-906c-9c0339ebea4b',
    WF_FLOWASSISTANT_DELETE : '0698d498-0d9f-4bf4-9c25-f5fc9c2d09a3',
    WF_FLOWASSISTANT_UPDATE : '57f29584-8a3c-4794-a199-3081b066ba1e',
    WF_FLOWASSISTANT_VIEWALL : 'eafe2b05-1ee4-4745-a6cb-679c2c5462c9',
    WF_FLOWINFO_CREATE : '21c9b8b2-746e-4812-a737-9d8052822a59',
    WF_FLOWINFO_DELETE : '09f09555-3213-4001-846c-1123aa835a5b',
    WF_FLOWINFO_UPDATE : '0d4f3e64-bbbe-43d3-b85d-d0f0c3d0f449',
    WF_FLOWINFO_VIEW_ALL : '5a386fc2-9c9c-4c22-a034-dce06f616fff',
    ZJHTLB_CONTRACT_CREATE : '598fc4a7-5619-43c6-8d8c-d2118da081f0',
    ZJHTLB_CONTRACT_DELETE : '0c46e31c-76d1-470d-88c2-e8e5603ae4bb',
    ZJHTLB_CONTRACT_UPDATE : 'a6a2f6a7-1764-458e-b68d-8acfa4e5d6d7',
    ZJHTLB_CONTRACT_VIEW : 'b8eb8f4f-b623-40e7-a7a5-5ff48881446f',
    ZJHTLB_CONTRACT_VIEW_ALL : '8455ab7e-eb0f-4481-9950-f20cdbe65444',
    ZJHTLB_CONTRACT_VIEW_COMPANY : '58db1c0c-d1b9-44c2-91eb-fd3ace76a1fb',
    ZJHTLB_CONTRACT_VIEW_DEPT : 'a1ba8061-f4a4-4849-9261-fef461a3d1b2',
    ZJHTLB_CONTRACT_VIEW_PERSON : '1ce7d5e9-9036-45cd-9d5d-e385e4832e53',
    ZJHTTZ_CONTRACT_EXPORT : 'a34f87c0-cde4-414a-8ed9-0ff5a5a9ec52',
    ZJHTTZ_CONTRACT_VIEW_ALL : 'e55dd134-8347-4108-be9d-92f4964e2e5f',
    ZJHTTZ_CONTRACT_VIEW_COMPANY : 'e7c7cfd2-1eaa-47b9-aaf7-215bdf3e775f',
    ZJHTTZ_CONTRACT_VIEW_DEPT : '10e33bda-a07d-4fab-aa8a-792a98e9014b',
    ZJHTTZ_CONTRACT_VIEW_PERSON : '051db83b-171c-4589-9bfd-557b64a41231',
    ZJ_CB_VIEW : '15fa88fb-a5ec-4873-93ec-3422c8736996',
    ZJ_CHSH_VIEW : '465d0609-2c3c-4d3b-be50-a397e9353d11',
    ZJ_EJSH_VIEW : 'a77bc15a-e578-4a78-b9a5-cf92673d3ef8',
    ZJ_QF_VIEW : 'd71c0336-3473-4362-8cb8-5110575bcdf3',
    ZJ_SJSH_VIEW : '64ce9288-4edd-4df7-a799-8814ba68b828',
    ZJ_XGBB_EXPORT : '6d67f373-6398-410f-af51-501dcfe5d7c4',
    ZJ_XGBB_VIEW_ALL : '1b6ec246-44cb-4341-a141-a942eeeebd1f',
    ZJ_XGBB_VIEW_COMPANY : 'ccb59d5c-caff-4d8e-9259-716af01c071b',
    ZJ_XGBB_VIEW_DEPT : '653c3043-bb40-4d95-b036-497238191b07',
    ZJ_XGBB_VIEW_PERSON : '08a0b44a-08c2-4791-92d5-9aa92923d2b1',
    ZJ_XMCH_VIEW : '08c64901-9925-4b2b-afdc-d268591201a4',
    ZJ_XMDJ_ADDRESSEE : '2a06fe2d-8a82-4afb-9ed5-af803c9aee7a',
    ZJ_XMDJ_CREATE : '04fc2a08-8eac-4600-95e0-2eed0724b4c9',
    ZJ_XMDJ_DELETE : '1b7e8197-082e-4ea4-8207-0b271878d79c',
    ZJ_XMDJ_EXPORT : 'bc40f6e3-a1d4-4e06-91fc-2a6c7b8a949c',
    ZJ_XMDJ_UPDATE : '6dcde29c-329a-4301-b03b-dba29d6b6c40',
    ZJ_XMDJ_VIEW : 'ce8b34f1-1cf2-45b5-8f17-652e10667d42',
    ZJ_XMDJ_VIEW_ALL : '743f8d3a-35fe-4bce-be73-8eab75c1a5c1',
    ZJ_XMDJ_VIEW_COMPANY : '380598db-c1e3-4509-87e6-47e2b7f60da7',
    ZJ_XMDJ_VIEW_DEPT : '5a18cd25-0c83-4993-be1d-956591976561',
    ZJ_XMDJ_VIEW_PERSON : '03e22e65-31a5-466b-baed-02175cd3956b',
    ZJ_XMGD_ARCHIVE : '0c3d4e6e-c113-4dca-8638-b4e64967e919',
    ZJ_XMGD_VIEW_ALL : '0b45736d-5ed4-4e4a-8798-704a2d901c85',
    ZJ_XMGD_VIEW_COMPANY : '7883e0aa-ef9c-47b2-ae1b-fd610f1b1887',
    ZJ_XMGD_VIEW_DEPT : '118b1c02-df9f-44db-8a7e-0d5ac6eb2a01',
    ZJ_XMGD_VIEW_PERSON : '07519934-9ee2-41ce-9a1d-efccd217ddda',
    ZJ_XMGZ_EXPORT : 'fe678cbc-7378-44ca-a60e-b2ebcab2509d',
    ZJ_XMGZ_VIEW_ALL : 'ce1051df-a2ee-4fbe-ba0c-e291c341400c',
    ZJ_XMGZ_VIEW_COMPANY : '4485e389-2ee4-43e1-b74d-a051f065e149',
    ZJ_XMGZ_VIEW_DEPT : '799e2451-a611-411b-851a-9ecb126d9d4c',
    ZJ_XMGZ_VIEW_DETAIL : 'e39251f5-53dc-4191-93ab-259166f80beb',
    ZJ_XMGZ_VIEW_PERSON : '40bfad81-51e9-4afa-b738-7f4998224cb5',
    ZJ_XMHZ_VIEW : '1b5acadd-2196-41d3-9331-61704cae50ff',
    ZJ_XMTZ_VIEW : '36d6f221-f030-4550-8d00-065ea96c8112',
    ZJ_ZJHZ_VIEW : '7f04f1d9-8eb8-4438-ab55-1365fab4764a',
    ZJ_ZJ_VIEW : '8c159712-851e-4919-8378-4e4e9f843c98',

    // 校内初评作品管理-作品导出
    bimSchoolWorkExport : 'c5d72044-5236-4580-834d-87dafdc4a404',


    // 免考认证订单权限  测试环境
    // CERT_PRODUCT : 'a56efd46-c165-47c8-afec-6aba97c059fa',
    // CERT_REGEDIST : 'bdd3ac4b-c9ca-4919-a3ad-b7ad40b46812',
    // CERT_REGEDIST_Del : '66554cdc-00b8-49cb-bb4d-972ecb7ac5b0',
    // CERT_REGEDIST_Edit : 'a05c375a-ecb3-45e6-b881-3888984090ed',
    // CERT_REGEDIST_Export : '55b327b2-2a19-4123-ac3b-d7f782786dfc',
    // CERT_REGEDIST_Export_Finance : '8d62f169-67d9-4ba3-8965-c81b230df98d',
    // CERT_REGEDIST_Import : '87e8933b-1df6-4747-a0f1-b8bf065fa8b7',
    // CERT_REGEDIST_ORDER : '893537ac-3023-4375-be0e-b505deb721eb',
    // CERT_REGEDIST_ORDER_DELETE : '54edda4e-3ae8-4dbd-946e-b9aea8daf802',
    // CERT_REGEDIST_ORDER_Status : '0503876a-e7b8-4862-ae35-80414cf53241',



    // 免考认证订单权限  正式环境
    CERT_PRODUCT : 'd52761bb-0104-4392-8708-0a13384d14d9',
    CERT_REGEDIST : 'ffd345cc-a7c4-4861-9ab8-204582525fc6',
    CERT_REGEDIST_Del : 'dd436c7d-e76e-43fe-9641-7d337112ae84',
    CERT_REGEDIST_Edit : 'ae5860c6-7a6b-42db-a22f-f69c6c2d7573',
    CERT_REGEDIST_Export : 'cd5811e1-82a1-4efc-acc4-2fb651ef221a',
    CERT_REGEDIST_Export_Finance : '6dcae341-a996-4db2-be44-9cd4dfcea062',
    CERT_REGEDIST_Import : '5ec7c1b6-762a-4e15-b6db-40ac415f2bfc',
    CERT_REGEDIST_ORDER : '1063f807-2b6f-4449-a1ae-1b8d1407f9db',
    CERT_REGEDIST_ORDER_DELETE : '77a25a79-1c16-4a68-95ed-373dda8208ec',
    CERT_REGEDIST_ORDER_Status : 'ef5d66c6-c919-438e-9430-0ec7ff62b5c5',
    CERT_REGEDIST_ORDER_CRMORDERNO : '839da48f-c068-4cb8-90e1-7e924d048a21',
    
};

/**
 * /产学合作协同育人-权限
 */
export const APP_PERMISSIONS_IUCCE = {
    //年度目标申报
    IUCCE_YEARGOALS_REPORT_QUERY : 'dfd697f6-90ad-4d63-a025-53426c6aaba7',
    IUCCE_YEARGOALS_REPORT_ADD : 'a721c2af-59d3-4c89-b206-9be06053af3f',
    IUCCE_YEARGOALS_REPORT_EDIT : '7e61cdbe-7b91-424a-bbae-e3109efe51ff',
    IUCCE_YEARGOALS_REPORT_DEL : '3507961e-194f-4bfa-ad58-b3bbf57da3d7',
    IUCCE_PROJECT_REPORT_QUERY : '7cf92770-32a2-42c4-8561-36bfca7c89e2', //项目立项申报
    IUCCE_PROJECT_REPORT_ADD : '6b38c8ec-fd3f-4791-8db4-6421c80be0e3',
    IUCCE_PROJECT_REPORT_EDIT : 'b3d60adf-421c-403f-89f6-4cbdea063edf',
    IUCCE_PROJECT_REPORT_DELETE : '18174620-1dec-4a61-a18d-9cd25143f650',
    IUCCE_PROJECT_SPECIAL_MODIFY : '319f385e-0846-457c-be97-972b5c4c136a', //项目立项申报特权编辑
    
}