
import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient } from '@angular/common/http';
import { BimScoringRule } from './bim-scoring-rule.model';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class BimScoringRuleService extends ThsBaseService<BimScoringRule>  {

  public bimScoringRuleItemUrl = '';
  public bimWorkCoreUrl = '';
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-scoring-rules');
    this.bimScoringRuleItemUrl = httpAddressService.BimServe + '/bim-scoring-rule-items';
    this.bimWorkCoreUrl = httpAddressService.BimServe + '/bim-work-cores';
  }

  deleteByScoringRuleId(id: string) {
    const url =  `${this.bimScoringRuleItemUrl}/deleteByScoringRuleId/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  getRuleItemByWorkId(param: any) {
    const url =  `${this.bimScoringRuleItemUrl}/getRuleItemByWorkId`;
    return this.http.get<any>(url, {params: param ,  observe: 'response'});
  }

  saveWorkCores(param: any) {
    const url =  `${this.bimWorkCoreUrl}/saveWorkCores`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }
}
