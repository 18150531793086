export class BimSoftDownloadType {
    constructor(
      public  id?: string,
      public  bimInfoId?: string,
      public  name?: string,
      public  parentId?: string,
      public  orderNum?: number,
      public  isAble?: string,
      public  createTime?: string,
      public  creator?: string,
      public  updateTime?: string,
      public  updator?: string,
    ) {}
  }