import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { HttpAddressService } from '@shared/session/http-address.service';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { NzMessageService, NzModalService, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BimAdvisorsService } from '../../bim-advisor/bim-advisor.service';
import {BimWorkCoresService} from '../../bim-work-cores/bim-work-cores.service';

import {BimWorkAdvisorEditComponent} from './edit/edit.component';

  import { from } from 'rxjs';
@Component({
  selector: 'app-bim-work-advisor',
  templateUrl: './bim-work-advisor.component.html',
  styleUrls: []
})
export class BimWorkAdvisorComponent implements OnInit {

  public bimInfoName = "";
  public bimInfoEventName= "";
  public workName= "";
  public workId= "";
  public schoolName= "";
  public teamName= "";
  public itemName= "";
  public bimEventId = "";
  public isReply = '';
  
  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  
  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  

  public queryParams = {
    // sort固定
    sort: ['tel,asc']
  };

  columns: SimpleTableColumn[] = [
    { title: '评委姓名', index: 'name', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '单位名称', index: 'orgName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '联系电话', index: 'tel', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '邮箱', index: 'email', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    
    { title: '大区', index: 'regionName' , width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },

    { title: '省份', index: 'provinceName', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    // { title: '评审专项', index: 'itemName', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },

    {
      title: '操作', width: '10%',
      buttons: [
        { text: '删除', click: (item: any) => this.remove(item) },
      ],
    }
  ];

 

  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private userService: UserService,
    private modalService: NzModalService, 
    private httpAddressService: HttpAddressService,
    private bimAdvisorsService: BimAdvisorsService,
    private bimWorkCoresService:BimWorkCoresService,
  ) {
   }

  ngOnInit(): void {
     this.getDataList();

  }



  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimWorkCoresService.getWorkAdvisorDataList(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key !== 'bimInfoEventsId') {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });

    copyParams['workId'] = this.workId;
    copyParams['isReply'] = this.isReply;

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  

  /**
  * 新增页面
  */
  add() {
      this.modal.static(BimWorkAdvisorEditComponent, {
        bimInfoName: this.bimInfoName,
        bimInfoEventName: this.bimInfoEventName,
        workName:this.workName,
        workId:this.workId,
        schoolName: this.schoolName,
        teamName:this.teamName,
        itemName: this.itemName,
        bimEventId:this.bimEventId,
        isReply: this.isReply
      }, 1003).subscribe((res) => {
        
          if(res !== null && res.length > 0)
          {
              let advisorIds ="";
              res.forEach(element => {
                   if(advisorIds.length <= 0)
                   {
                    advisorIds = element.id
                   }else{
                    advisorIds = advisorIds + "," + element.id;
                   }
              });

              this.bimWorkCoresService.saveWorkAdvisor(this.workId, advisorIds).subscribe(res => {
                  this.msg.success('添加成功');
                  this.getDataList();
        
              });
          }

         
      });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isloadding = true;
        this.bimWorkCoresService.delete(item.id).subscribe(res => {
          this.isloadding = false;
          this.msg.success('删除成功！');
          this.getDataList();
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

}
