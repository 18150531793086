import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BimSoftDownloadDetailsService } from '../bim-info-soft.service';

@Component({
  selector: 'app-bim-info-soft-download',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimInfoSoftDownComponent implements OnInit, OnDestroy {

  record: any = {};

  form: FormGroup;
  public isSaving = false;


  constructor(
    private fb: FormBuilder,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimSoftDownloadDetailsService: BimSoftDownloadDetailsService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [null, []],
      softTypeId: [null, []],
      name: [null, []],
      isAble: [null, []],
      orderNum: [null, []],
      downloadUrl: [null, [Validators.required, Validators.maxLength(150)]],
      downloadTitle: [null, [Validators.required, Validators.maxLength(150)]],

      softDetail: [null, []],
    });
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      param[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      return;
    }
    this.modifyDataInfo(param);
  }


  modifyDataInfo(param: any) {
    this.isSaving = true;
    this.bimSoftDownloadDetailsService.updateSoftDownloadUrl(param).subscribe(res => {
      this.isSaving = false;
      const record = res.body;
      if (record  ) {
        this.msg.info('保存成功');
        this.close(true);
      } else {
        this.msg.error('保存失败');
      }
    });
  }

   // 关闭事件
   close(type?) {
    this.nzModalRef.destroy(type);
   }

   ngOnDestroy(): void {
   }
}
