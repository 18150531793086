import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { StudentService } from "../student.service";
import { Course } from "../../course/course.model";
import { APP_PERMISSIONS } from "@shared/app.permissions";

@Component({
  selector: 'app-edu-courseNum',
  templateUrl: './courseNum.component.html',
})
export class EduStudentCourseNumComponent implements OnInit{
  public page: number = 0;
  public size: number = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public dataList: any[] = [];
  
  record: any = {};
  public totalCallNo: number = 0;
  public selectedRows: SimpleTableData[] = [];

  //HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    //查询搜索框的字段要预置
    'name': '',
    'studentId.contains': '',
    //sort固定
    'sort': ['order_num,asc']
  };


 


  
  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public studentService: StudentService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    }else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    };
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    copyParams['studentId'] = this.record.id;
    this.studentService.queryCourse(copyParams)
      .subscribe((res: any) => {
        debugger
      this.dataList=res.data;
      this.total = res.headers.get('X-Total-Count');
     // this.total = 200;
       // this.list = res.body;

      });
  }

  /**
  * 页码数量变动事件
  * @param 
  */
  paginationChange(event) {
    this.page = 0;
    this.size = event;
    this.getDataList();
  }

 /*  pageIndexChange(){
    this.getDataList();
  } */

  /**
  * 过滤器变动事件 支持多选过滤
  * @param 
  */
  filterChange(event) {
    let i = 0;
    let _value = [];
    let _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey +'.'+ _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param 
  */
  sortChange(event) {
    let array = this.queryParams['sort'];
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value == 'descend') {
      value = 'desc';
    } else if (event.value == 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + "," + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + "," + value);
    }
    //排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }

 
 




}
