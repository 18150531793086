import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimScoringRuleItem } from './bim-scoring-rule-item.model';

@Injectable({ providedIn: 'root' })
export class  BimScoringRuleItemService extends ThsBaseService<BimScoringRuleItem>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-scoring-rule-items');
  }

  getRuleItemData(id: string) {
    const url =  `${this.resourceUrl}/getRuleItemData/${id}`;
    return this.http.get<any>(url, { observe: 'response' });
  }
}
