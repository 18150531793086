import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { EduExamResult } from './examdetails.model';

@Injectable({
  providedIn: 'root'
})
export class ExamdetailsService extends ThsBaseService<EduExamResult> {
  examUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-exam-results`);
    this.examUrl = httpAddressService.EduServe + '/edu-exams';
  }
  queryExamByStuAndCourseAndUser(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getExamByStuAndCourseAndUser`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
  queryExamToView(examId: string, studentId: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.examUrl + '/getStuedntExamResult?examId=' + examId +
      '&studentId=' + studentId, { observe: 'response' });
  }
}
