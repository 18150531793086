import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BimInfoListComponent} from './bim-info/bim-info-list.component';
import {BimItemsListComponent} from './bim-items/bim-items-list.component';
import {BimInfoEventsListComponent} from './bim-info/bim-info-events/bim-info-events-list.component';

import {BimInfoQqListComponent} from './bim-info/bim-info-qq/bim-info-qq-list.component';
import {BimInfoAdvertisementListComponent} from './bim-info/bim-info-advertisement/bim-info-advertisement-list.component';
import { BimScoringRuleListComponent } from './bim-scoring-rule/bim-scoring-rule-list.component';

import {BimSchoolsListComponent} from './school/bim-schools-list.component';
import { ThsNewsListComponent } from './ths-news/ths-news-list.component';
import { BimCoopCollegeComponent } from './bim-coop-college/bim-coop-college.component';
import {BimInfoSchoolsListComponent} from './bim-info/bim-info-schools/bim-info-schools-list.component';
import {BimTeamsListComponent} from './bim-info/bim-teams/bim-teams-list.component';

import {BimTeamTeacherListComponent} from './bim-info/bim-teams/bim-team-teacher/bim-team-teacher-list.component';
import {BimTeamStudentListComponent} from './bim-info/bim-teams/bim-team-student/bim-team-student-list.component';

import { WorkManageComponent } from './work-manage/work-manage.component';
import {SchoolWorkManagerComponent} from './school-work-manager/school-work-manager.component';


import {BimPersonListComponent} from './bim-info/bim-person/bim-person-list.component';
import {BimPersonTeacherListComponent} from './bim-info/bim-person/bim-person-teacher/bim-person-teacher-list.component';
import {BimPersonResultListComponent} from './bim-info/bim-person/bim-person-result/bim-person-result.component';
import {BimPersonResultEditShowInfoComponent} from './bim-info/bim-person/bim-person-result/edit/show-info/show-info.component';
import { BimAdvisorComponent } from './bim-advisor/bim-advisor.component';
import { BimWorkCoresComponent } from './bim-work-cores/bim-work-cores.component';
import {BimLearningClassRoomComponent} from './bim-learning-classroom/bim-learning-classroom.component';
import {BimReceiptInfoListComponent} from './bim-info/bim-receipt-info/bim-receipt-info-list.component';
import { EduLiveBroadcastsListComponent } from './edu-live-broadcasts/edu-live-broadcasts.component';
import { BimInfoProcessListComponent } from './bim-info/bim-info-process/bim-info-process-list.component';
import { WorkReplyManageComponent } from './work-reply-manage/work-reply-manage.component';
import { BimAdvisorReplyComponent } from './bim-advisor-reply/bim-advisor-reply.component';
import { BimTeamResultListComponent } from './bim-info/bim-teams/bim-team-result/bim-team-result.component';
import { BimInfoInstitutionalComponent } from './bim-info/bim-info-institutional/bim-info-institutional.component';
import { BimWorkShowComponent } from './bim-work-show/bim-work-show.component';
import { BimCompetitionMienComponent } from './bim-competition-mien/bim-competition-mien.component';
import { BimInfoContactComponent } from './bim-info/bim-info-contact/bim-info-contact.component';
import { WorkReplyFileManageComponent } from './work-reply-file-manage/work-reply-file-manage.component';
import { BimInfoSoftComponent } from './bim-info/bim-info-soft/bim-info-soft.component';


const routes: Routes =  [
  {
    path: '',
    children: [
      { path: 'bim-info-list', component: BimInfoListComponent, data: {title: '历届赛事管理'} },
      { path: 'bim-items-list', component: BimItemsListComponent, data: {title: '比赛专项管理'} },
      { path: 'bim-scoring-rule-list', component: BimScoringRuleListComponent, data: {title: '评分模板管理'} },
      { path: 'bim-info-events-list', component: BimInfoEventsListComponent, data: {title: '比赛赛项管理'}},
      { path: 'bim-info-qq-list', component: BimInfoQqListComponent, data: {title: '官方沟通渠道'}},
      { path: 'bim-info-advertisement-list', component: BimInfoAdvertisementListComponent, data: {title: '比赛广告位管理'}},
      { path: 'bim-info-process-list', component: BimInfoProcessListComponent, data: {title: '比赛流程管理'}},
      { path: 'bim-schools-list', component: BimSchoolsListComponent, data: {title: '学校信息管理'}},
      { path: 'bim-info-schools-list', component: BimInfoSchoolsListComponent, data: {title: '参赛学校管理'}},
      { path: 'bim-teams-list', component: BimTeamsListComponent, data: {title: '参赛团队管理'}},
      { path: 'bim-teams-teacher-list', component: BimTeamTeacherListComponent, data: {title: '参赛团队指导老师管理'}},
      { path: 'bim-teams-student-list', component: BimTeamStudentListComponent, data: {title: '参赛团队队员管理'}},
      { path: 'bim-info-institutional', component: BimInfoInstitutionalComponent, data: {title: '制度文件管理'}},
      { path: 'bim-info-contact', component: BimInfoContactComponent, data: {title: '联系方式管理'}},
      { path: 'bim-info-soft', component: BimInfoSoftComponent, data: {title: '软件下载管理'}},

      { path: 'bim-school-work-manage', component: SchoolWorkManagerComponent, data: { title: '校内初评作品管理'}},
      { path: 'bim-work-manage', component: WorkManageComponent, data: { title: '全国评比作品管理'}},
      { path: 'bim-reply-file-manage', component: WorkReplyFileManageComponent, data: { title: '全国答辩材料管理'}},
      { path: 'bim-reply-work-manage', component: WorkReplyManageComponent, data: { title: '全国答辩作品管理'}},

      { path: 'bim-person-list', component: BimPersonListComponent, data: {title: '参赛个人管理'}},
      { path: 'bim-person-teacher-list', component: BimPersonTeacherListComponent, data: {title: '个人参赛指导老师管理'}},
      { path: 'bim-person-result-list', component: BimPersonResultListComponent, data: {title: '个人赛成绩管理'}},
      { path: 'bim-team-result-list', component: BimTeamResultListComponent, data: {title: '团队赛成绩管理'}},
      { path: 'bim-person-result-import-show-info', component: BimPersonResultEditShowInfoComponent, data: {title: '个人参赛成绩导入记录'}},

      { path: 'bim-document-list/:contentType', component: ThsNewsListComponent},
      { path: 'bim-coop-colleges', component: BimCoopCollegeComponent, data: {title: '合作院校'}},
      { path: 'bim-learning-classroom', component: BimLearningClassRoomComponent, data: {title: '学习课堂'}},
      { path: 'bim-work-show', component: BimWorkShowComponent, data: {title: '作品展示'}},

      { path: 'bim-country-advisor', component: BimAdvisorComponent, data: {title: '全国评委配置'}},
      { path: 'bim-country-reply-advisor', component: BimAdvisorReplyComponent, data: {title: '全国答辩评委配置'}},
      { path: 'bim-school-work-cores', component: BimWorkCoresComponent, data: {title: '校内初评排名'}},
      { path: 'bim-country-work-cores', component: BimWorkCoresComponent, data: {title: '全国评比排名'}},
      { path: 'bim-receipt-info-list', component: BimReceiptInfoListComponent, data: {title: '团队回执管理'}},
      { path: 'bim-receipt-info-list', component: BimReceiptInfoListComponent, data: {title: '团队回执管理'}},
      { path: 'bim-certification', loadChildren: './bim-certification/bim-certification.module#BimCertificationModule' },

      { path: 'edu-live-broadcasts-list', component: EduLiveBroadcastsListComponent, data: {title: '直播管理'}},
      { path: 'bim-competition-mien', component: BimCompetitionMienComponent, data: {title: '比赛风采'}},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BimRoutingModule { }
