import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { TopicPush } from './TopicPush.model';
import {ThsBaseService } from "@shared/components/thsBaseService";
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class EduTopicPushService extends ThsBaseService<TopicPush> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-push-topics`);
  }
 
  // getTypeFromDict(typeId :string){
  //   this.http.get('/thsadmin/api/sys-dicts/' + typeId, {observe: 'response'}).subscribe((res: HttpResponse<any[]>) => {
  //     this.count = parseInt(res.headers.get('x-total-count'));
  //   });
  // }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}

findTopic(id: string|number): Observable<HttpResponse<TopicPush>> {
  const url = `${this.resourceUrl}/getEduPushTopicById?id=`+id;         
  return this.http.get<TopicPush>(url, { observe: 'response' }).pipe(map((res: HttpResponse<TopicPush>) => this.convertDateFromServer(res)));
}
  // hasUserAccount(userAccount : string): Observable<any>{
  //   const url = `${this.resourceUrl}/hasUserAccount/${userAccount}`;
  //   return this.http.get(url);
  // }

  // updateMultiTenancyIdByEntityId(entityId: string, multiTenancyId: string): Observable<any>{
  //   const url = `${this.resourceUrl}/updateMultiTenancyId/${entityId}/${multiTenancyId}`;
  //   return this.http.get(url);
  // }

}

