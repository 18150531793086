import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ThsNewsService } from '../../ths-news/ths-news.service';

@Component({
  selector: 'app-bim-bim-learning-classroom-edit',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimLearningClassRoomEditComponent implements OnInit {

  isAdd = false;
  record: any = {};
  contentType = null;

  form: FormGroup;
  public loading = false;

  public dataId = null;
  public bimInfoList = [];

  loadExtParams = {'extField1.equals': 'LearningClassRoom'};

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private modalHelp: ModalHelper,
    private bimInfoService: BimInfoService,
    private thsNewsService: ThsNewsService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [null, [Validators.required]],
      title: [null, [Validators.required, Validators.maxLength(51)]],
      remark: [null, []],
      istop: [true, []],
      author: [null, []],
      content: [null, []],
      createTime: [null, []],
      isDelete: [0, []],
      hit: [1, []],
      contenttype: [null, []],
    });
  }

  ngOnInit(): void {
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.dataId = this.record.id;
      this.loadData();
    }

    if (this.bimInfoList.length === 0) {
      this.initBimInfoList();
    }
  }

  loadData() {
    this.form.patchValue(this.record);
  }

  initBimInfoList() {
    this.bimInfoService.query({
      'size' : '10000',
      'isDelete.equals': 0,
      'status.in': ['0', '1'],
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
        this.bimInfoList = res.body;
    });
  }
  // 提交
  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    console.log(subParam);
    this.checkSaveData(subParam);
  }

  checkSaveData(subParam: any) {
    this.loading = true;
    subParam['istop'] = subParam['istop'] ? 1 : 0;
    subParam['isreport'] = subParam['isreport'] ? 1 : 0;
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.updateData(subParam);
    } else {
      this.saveData(subParam);
    }
  }

  saveData(subParam: any) {
    subParam['id'] = this.dataId;
    if (!isNullOrUndefined(this.contentType)) {
      subParam['contenttype'] = this.contentType;
    }
    subParam['createTime'] = new Date();
    this.thsNewsService.create(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  updateData(subParam: any) {
    subParam['updateTime'] = new Date();
    this.thsNewsService.update(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  uploadFile(isView: boolean) {
    this.modalHelp.static(FileUploadComponent, {
      fileFk: this.dataId,
      uploadable: !isView,
      deleteable: !isView,
      uploadExtParams: {
        'extField1': this.contentType
      },
      loadExtParams: {
        'extField1.equals': this.contentType
      }
    }, 1200, { nzTitle: '附件上传' })
      .subscribe(res => {
        // this.updateFileCount(item);
      });
  }
  /**
    * 上传成功回调
    * @param file
    */
   onSuccessfulSchoolLogo(file: any) {
    const thisSuccessFile = file.successFile;
    if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
      thisSuccessFile.forEach(item => {
        this.form.controls['content'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
      });
    }
  }

  /**
   * 删除成功回调
   * @param file
   */
  onDeleteFileSchoolLogo(file: any) {
    if (file.file) {
    }
    this.form.controls['userDefine6'].setValue(null);
  }

  close() {
    this.nzModalRef.destroy(this.dataId);
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }
}
