import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { APP_PERMISSIONS_IUCCE } from '@shared/app.permissions';
import { zip } from 'rxjs';
import { Router } from '@angular/router';
import { ProjectReportService } from '../projectReport/projectReport.service';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

@Component({
  selector: 'app-project-fund-pay',
  templateUrl: './projectQuery.component.html',
  styles: []
})
export class ProjectQueryComponent implements OnInit {
  dtLoading = false;
  queryParams = {
    regionId: null,
    provinceId: null,
    year: null,
    schoolName: null,
    projectName: null,
    batchId: null,
    projectTypeId: null,
    projectStatus: null,
    orderStatus: null,
    orderDateBegin: null,
    orderDateEnd: null,
    auditDateBegin: null,
    auditDateEnd: null,
    closeDateBegin: null,
    closeDateEnd: null,
  };
  isCollapse = true;
  regionList: any; // 大区
  provinceList: any; // 省份（根据大区选择进行筛选）
  provinceResultList: any; // 省份（所有数据）
  yearList: any; // 年份
  batchList: any; // 批次
  batchResultList: any; // 批次全部数据
  prjTypeList: any; // 项目类别
  prjTypeResultList: any; // 项目类别全部数据
  exportingPrjDetail = false;

  allChecked = false;
  indeterminate = false;
  dataList: any;
  total = 0;
  page = 0;
  size = 10;
  pageSizeOptions = [10, 20, 50, 100];
  APP_PERMISSIONS = APP_PERMISSIONS_IUCCE;

  public establishStatus = [
    {
      label: '待立项',
      value: '0'
    },
    {
      label: '立项退回修改',
      value: '9'
    },
    {
      label: '立项待提交',
      value: '20'
    },
    {
      label: '立项已提交',
      value: '21'
    },
    {
      label: '立项审核中',
      value: '22'
    },
    {
      label: '立项审核不通过',
      value: '24'
    },
    {
      label: '商机跟进中',
      value: '50'
    },
    {
      label: '结项退回修改',
      value: '59'
    },
    {
      label: '结项申请待提交',
      value: '60'
    },
    {
      label: '结项已提交',
      value: '61'
    },
    {
      label: '结项审核中',
      value: '62'
    },
    {
      label: '结项审核不通过',
      value: '633.'
    },
    {
      label: '已结项',
      value: '80'
    },
  ];

  constructor(
    private router: Router,
    private dictService: DictService,
    private userService: UserService,
    private dictDetailService: DictDetailService,
    private projectReportService: ProjectReportService) { }

  ngOnInit(): void {
    this.getDataList();
    this.initSearchValue();
  }
  /**
   * 初始化搜索条件默认值
   */
  initSearchValue() {
    this.getYearListDict();
    this.getRegionProvinceInfo();
  }

  /**
   * 获取大区、省份数据
   */
  getRegionProvinceInfo() {
    // 加载区域与省份数据
    const dataParams = {
      'size' : '10000',
      'sort': 'orderNum,asc'
    };
    zip(
      this.userService.queryRegionList(dataParams),
      this.userService.queryProvinceList(dataParams),
      this.projectReportService.getUserRegionAndProvince()
    ).subscribe(([regionRes, provinceRes, userDetails]) => {
      let regionDataList = regionRes.body;
      let provinceDataList = provinceRes.body;
      const regionProviceResult = userDetails.body; // 当前登录用户的大区、省份数据
      if (regionProviceResult) {
        let regionInfos = regionProviceResult['regionInfos']; // 大区
        let provinces = regionProviceResult['provinces']; // 省份
        // 过滤大区
        if (regionInfos && regionInfos.length > 0) {
          regionInfos = regionInfos.map(w => w.id);
          regionDataList = regionDataList.filter(w => regionInfos.includes(w.id));
        }
        // 过滤省份
        if (provinces && provinces.length > 0) {
          provinces = provinces.map(w => w.id);
          provinceDataList = provinceDataList.filter(w => provinces.includes(w.id));
        }
      }

      this.regionList = regionDataList;
      this.provinceResultList = provinceDataList;
    });
  }

  /**
   * 大区改变事件
   */
  fnChangeRegion(itemSelect) {
    this.provinceList = [];
    if (this.provinceResultList && itemSelect) {
      const items = typeof itemSelect === 'object' ? itemSelect : [itemSelect];
      items.forEach(item => {
        const regionQueryList = this.regionList.filter(w => w.id === item);
        const region = regionQueryList[0];
        const queryProvinces = this.provinceResultList.filter(w => w.fkregionId === region.id);
        queryProvinces.forEach(element => {
          this.provinceList.push(element);
        });
      });
    }
  }

   /**
   * 省份改变事件
   */
  fnChangeProvince(itemSelect) {
    this.queryParams['province.in'] = [];
    if (this.provinceList && itemSelect) {
      const items = typeof itemSelect === 'object' ? itemSelect : [itemSelect];
      let regionIds = []; // 将现在用的值和原来用过的值拼接一起查询
      items.forEach(item => {
        const regionQueryList = this.provinceList.filter(w => w.itemValue1 === item);
        const region = regionQueryList[0];
        if (region.itemValue2) {// 原来用过的值
          regionIds = region.itemValue2.split(',');
        }
        regionIds.push(item);
      });
      this.queryParams['province.in'] = regionIds;
    }
  }

  fnChangeYear(item) {

    this.batchList = [];
    this.prjTypeList = [];
    this.queryParams['batch.in'] = '';
    this.queryParams['prjType.in'] = '';

    if (this.batchResultList) {
      this.batchList = this.batchResultList.filter(w => w.parentId && w.itemValue2 === item);
    }
    if (this.prjTypeResultList) {
      this.prjTypeList = this.prjTypeResultList.filter(w => w.parentId && w.itemValue2 === item);
    }

  }

  /**
   * 获取年份
   */
  getYearListDict() {
    this.dictService.findAllByDictNo('yearList').subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
    this.dictService.query({ 'dictNo.equals': 'batchList' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals': res.body[0].id , sort: 'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.batchResultList = res2.body;
          }
        });
      }
    });
    this.dictService.query({ 'dictNo.equals': 'prjTypes' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals': res.body[0].id , sort: 'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.prjTypeResultList = res2.body;
          }
        });
      }
    });
  }

  getQueryParams(isReset){
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = null;
      });
      this.batchList = [];
      this.prjTypeList = [];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          if (key.indexOf('DateBegin') > -1) {
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 00:00:00';
          } else if (key.indexOf('DateEnd') > -1) {
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 23:59:59';
          } else {
            if (typeof q[key] === 'object' && q[key]) {
              copyParams[key] = q[key].join(',');
            } else {
              copyParams[key] = q[key];
            }
          }
        }
      });
    }
    return copyParams;
  }

  getDataList(isReset?: boolean) {
    this.dtLoading = true;
    const copyParams = this.getQueryParams(isReset);
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.projectReportService.getAllIucceProjectsByPage(copyParams)
      .subscribe((res: any) => {
        this.dtLoading = false;
        this.dataList = res.body;
        if (this.dataList) {
            this.dataList.forEach(item => {
              let endDateStr = item.endDate;
              if(item.isDelay == '1' && item.delayEndDate){
                endDateStr = item.delayEndDate;
              }
              const endDateMoment = moment(endDateStr,'YYYY-MM-DD 00:00:00');
              if(endDateMoment.isValid()){
                if(endDateMoment.isBefore(moment(new Date()))){
                  endDateStr = endDateMoment.format('YYYY-MM-DD') + '(已到期)';
                }else{
                  endDateStr = endDateMoment.format('YYYY-MM-DD') + '(未到期)';
                }
              }else{
                endDateStr = '';
              }
              item['endDateValue'] = endDateStr;

              //软件支持类项目只有软件投入价值，无资金支付
              

              
                // const projectTypeId = item.projectTypeId;
                // let prjType = null;
                // if (!isNullOrUndefined(projectTypeId) && this.prjTypeResultList !== null) {
                //   const typeList = this.prjTypeResultList.filter(e => e.id === projectTypeId);
                //   if (typeList && typeList.length > 0) {
                //     prjType = typeList[0].itemValue3;
                //   }
                // }
                // item['prjType'] = prjType === 'soft' ? '软件支付类' : '非软件支付类';
            });
        }
        this.total = res.headers.get('X-Total-Count');
    });
  }

  /**
   * 导出
   */
  exportProjectDetailExcel(){
    this.exportingPrjDetail = true;
    const this_ = this;
    let queryParams = this.getQueryParams(false);
    queryParams['fileName'] = "项目明细表";
    this.projectReportService.exportProjectQueryDetailExcel(queryParams,this_);
  }


  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  /**
  * 页码数量变动事件
  * @param
  */
   paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  view(item: any) {
    const projectTypeId = item.projectTypeId;
    let prjType = null;
    if (!isNullOrUndefined(projectTypeId) && this.prjTypeResultList !== null) {
      const typeList = this.prjTypeResultList.filter(e => e.id === projectTypeId);
      if (typeList && typeList.length > 0) {
        prjType = typeList[0].itemValue3;
      }
    }
    this.router.navigate(['/business/iucce/projectQuery/view'], {
      queryParams: {
        id: item.id,
        prjType: prjType
      }
    });
  }

  formatOrderStatus(status) {
    if(status === '1'){
      return '部分成单'
    }else if(status === '3'){
      return '全部成单'
    }else{
      return '未成单';
    }
  }

}
