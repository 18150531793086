import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { YearGoalsReport } from './yearGoalsReport.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class YearGoalsReportService extends ThsBaseService<YearGoalsReport> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.IucceServe + `/iucce-year-goals-reports`);
    }

    // findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    //     return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
    // }
    // findAllByDictNo(dictNo: string): Observable<HttpResponse<any[]>> {
    //     const dictUrl = '/thsadmin/api/sys-dicts';
    //     const url = dictUrl + '/findAllByDictNo';
    //     return this.http.get<any>(`${url}/${dictNo}`, { observe: 'response' });
    // }

}

