import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {RegionInfoService} from "../region-info/region-service.model";
import {ProvinceService} from "../provinces/provinces.service";
import {BimInfoService} from "../bim-info/bim-info.service";
import {BimInfoEventsService} from "../bim-info/bim-info-events/bim-info-events.service";
import {WorkManageService} from "./work-manage.service";
import {MessageHelper} from "@shared/helpers/MessageHelper";
import {BimWorks, BimWorkVm} from "./work-manage.model";
import {ModalHelper} from "@delon/theme";
import {ShowTeamUsersComponent} from "./show-team-users/show-team-users.component";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HttpAddressService} from "@shared/session/http-address.service";
import {ScoreDetailsComponent} from "./score-details/score-details.component";
import {SupplementaryWorksComponent} from "./supplementary-works/supplementary-works.component";

import {BimWorkAdvisorComponent} from './work-advisor/bim-work-advisor.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { GuidUtil } from '@shared/utils/guid.util';

@Component({
  selector: 'app-work-manage',
  templateUrl: './work-manage.component.html',
  styles: []
})
export class WorkManageComponent implements OnInit {

  searchTeamName = '';
  searchSchoolName = '';
  searchSchoolType = '-1';
  searchRegion = '-1'; //大区
  regionList: any = [{id:'',regionName:''}];
  searchProvinces = '-1'; //省份
  provincesList: any = [{id: '', provinceName: ''}];
  searchBimInfo = '-1'; //赛事
  bimInfoList: any = [{id: '', name: ''}];
  searchBimInfoEvents = ''; //赛项
  bimInfoEventsList: any = [{id: '', name: ''}];
  searchWorkTop = -1; //校内是否晋级
  
  isQueryEvent = false;

  curBimEventId = "";
  curBimInfoName = "";

  page = 1;
  size = 20;
  total = 0;
  list: BimWorks[] = [];
  loading = false;
  allChecked = false;
  indeterminate = false;

  teamUrl = '';
  exportLoading = false;
  downloadLoading = false;
  startDownload = false;

  @ViewChild('workQrCode')
  workQrCode: TemplateRef<any>;
  previewUrl = null;
  previewWorkUrl = null;

  
  public interval: any;

  constructor(
    private msg: NzMessageService,
    private regionInfoService: RegionInfoService,
    private provinceService: ProvinceService,
    private bimInfoService: BimInfoService,
    private bimInfoEventsService: BimInfoEventsService,
    private workManageService: WorkManageService,
    public modal: ModalHelper,
    private http: HttpClient,
    private httpAddressService: HttpAddressService,
    private fileUploadService: FileUploadService,
    private modalService: NzModalService,
  ) {
    this.teamUrl = this.httpAddressService.BimServe + '/bim-works';
    this.previewUrl = this.httpAddressService.PreviewUrl;
  }

  ngOnInit() {
    this.getAllRegion();
    this.getProvinces('-1');
    this.getBimInfo();
    this.getList();
  }

  /**
   * 获取所有大区
   */
  getAllRegion() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'orderNum',
    };
    this.regionInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.regionList = [{id:'-1',regionName:'全部'}];
        this.regionList.push(...res.body);
      }
    })
  }

  /**
   * 根据大区获取省份
   */
  getProvinces(value) {
    const params = {
      'fkregionId.equals': value,
      page: 0,
      size: 1000,
      sort: 'orderNum'
    };
    console.log('searchRegion:',this.searchRegion);
    console.log('value:',value);
    if (value === '-1') {
      delete params['fkregionId.equals'];
    }
    this.provinceService.query(params).subscribe(res => {
      if (res && res.body) {
        this.provincesList = [{id: '-1', provinceName: '全部'}];
        this.provincesList.push(...res.body);
      }
    })
  }

  /**
   * 获取所有大赛
   */
  getBimInfo() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
      'isDelete.equals': 0
    };
    this.bimInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.bimInfoList = [{id: '-1', name: '全部赛事'}];
        this.bimInfoList.push(...res.body);
      }
    })
  }

  /**
   * 根据赛事ID获取赛项
   */
  getBimInfoEvents(value) {
    if (value === '-1') {
      this.searchBimInfoEvents = '';
      this.bimInfoEventsList = [];
      return;
    }

   let tmpList =  this.bimInfoList.filter(d=>d.id===value);
    this.curBimInfoName = tmpList[0].name
    const params = {
      'bimInfoId.equals': value,
      'isDelete.equals': 0,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.isQueryEvent = true;
    this.bimInfoEventsService.query(params).subscribe(res => {
      this.isQueryEvent = false;
      if (res && res.body) {
        this.bimInfoEventsList = [{id: '-1', name: '全部'}];
        this.bimInfoEventsList.push(...res.body);
        this.searchBimInfoEvents = '-1';
      }
    })
  }

  rest() {
    this.searchTeamName = '';
    this.searchSchoolName = '';
    this.searchRegion = '-1';
    this.searchProvinces = '-1';
    this.searchBimInfo = '-1';
    this.searchBimInfoEvents = '';
    this.searchWorkTop = -1;
    this.getProvinces('-1');
    this.bimInfoEventsList = [];
    this.page = 1;
    this.getList();
  }

  getList(pageRest?: boolean) {
    if (pageRest) {
      this.page = 1;
    }
    const params = {
      page: this.page - 1,
      size: this.size,
      'workType.equals': 1,
      sort: ['itemId','core,desc'],
    };
    if (this.searchTeamName && this.searchTeamName.length > 0) {
      params['teamName.contains'] = this.searchTeamName;
    }
    if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      params['schoolName.contains'] = this.searchSchoolName;
    }
    if (this.searchSchoolType && this.searchSchoolType !== '-1') {
      params['schoolType.equals'] = this.searchSchoolType;
    }
    if (this.searchRegion && this.searchRegion !== '-1') {
      params['location.equals'] = this.searchRegion;
    }
    if (this.searchProvinces && this.searchProvinces !== '-1') {
      params['province.equals'] = this.searchProvinces;
    }
    if (this.searchBimInfo && this.searchBimInfo !== '-1') {
      if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
        params['eventsId.equals'] = this.searchBimInfoEvents;
      } else {
        params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
      }
    }
    // if (this.searchWorkTop && this.searchWorkTop !== -1) {
    //   params['schoolTop.equals'] = this.searchWorkTop;
    // }
    if (this.isQueryEvent) {
      this.msg.warning('请等待参赛项目查询完成');
      return;
    }
    this.loading = true;
    this.workManageService.getBimWork(params).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        this.list = res.body;
        this.total = parseInt(res.headers.get('X-Total-Count'));
        this.list.forEach(item => {
          item.checked = false;
        })
      }
    },error1 => {
      this.loading = false;
      console.error(error1.message);
      MessageHelper.error('获取数据错误',error1.status);
    })
  }

  advisors(data) {
    let teacher = 0;
    if (data && data.length > 0) {
      teacher = data.length;
    }
    // if (data && data.length > 0) {
    //   data.forEach(item => {
    //     teacher += ',' + item.name;
    //   });
    //   if (teacher && teacher.length > 0) {
    //     teacher.substring(1);
    //   }
    // }
    return teacher;
  }

  refreshStatus(): void {
    const allChecked = this.list.every(value => value.checked === true);
    const allUnChecked = this.list.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.list.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  viewTeamUsers(data: BimWorks) {
    console.log('data:',data);
    this.modal.static(ShowTeamUsersComponent,{teamId: data.teamId},800,{}).subscribe(res => {})
  }

  teamWorkExportExcel() {
    const params = {
      'workType.equals': '1',
      sort: ['itemId','core,desc'],
    };
    if (this.searchTeamName && this.searchTeamName.length > 0) {
      params['teamName.contains'] = this.searchTeamName;
    }
    if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      params['schoolName.contains'] = this.searchSchoolName;
    }
    if (this.searchSchoolType && this.searchSchoolType !== '-1') {
      params['schoolType.equals'] = this.searchSchoolType;
    }
    if (this.searchRegion && this.searchRegion !== '-1') {
      params['location.equals'] = this.searchRegion;
    }
    if (this.searchProvinces && this.searchProvinces !== '-1') {
      params['province.equals'] = this.searchProvinces;
    }
    if (this.searchBimInfo && this.searchBimInfo !== '-1') {
      if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
        params['eventsId.equals'] = this.searchBimInfoEvents;
      } else {
        params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
      }
    }
    // if (this.searchWorkTop && this.searchWorkTop !== -1) {
    //   params['schoolTop.equals'] = this.searchWorkTop;
    // }
    if (this.isQueryEvent) {
      this.msg.warning('请等待参赛项目查询完成');
      return;
    }
    this.exportLoading = true;
    const url = `${this.teamUrl}/workExportExcel`;
    //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    this.http.get(url ,  {
      params: params ,
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).subscribe(
      data => {
        let blob = new Blob([data.body]);
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download',  '全国赛团队作品及得分' + '.xlsx');
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.exportLoading = false;
      },
      error => {
        this.exportLoading = false;
        MessageHelper.error('下载失败，请稍后重试');
        console.error('全国赛团队作品及得分下载失败 ->', error);
      },
    );

  }

  viewScores(data: BimWorks) {
    this.bimInfoEventsService.find(data.eventsId).subscribe(res => {
      if (res && res.body) {
        const event = res.body;
        this.modal.static(ScoreDetailsComponent, { workId: data.id, bimInfoId: event.bimInfoId }, 800,{}).subscribe(res => {
          this.getList();
        })
      }
    });
  }

  openSupplementare() {
    this.modal.static(SupplementaryWorksComponent, {}, 1200, {}).subscribe(res => {
    })
  }

  downloadWorks() {
    const select = this.list.filter(item => item.checked);
    const params = {
      'workType.equals': '1',
      sort: ['itemId','core,desc'],
    };
    if (select && select.length > 0) {
      if (select.length > 1) {
        MessageHelper.warn('仅支持单个作品导出');
        return;
      } else {
        params['id.in'] = select.map(item => item.id);
        MessageHelper.success('正在打包导出，请勿刷新页面');
      }
    } else {
      MessageHelper.warn('请选择一个作品进行导出');
      return;
    }
    // if (select && select.length > 0) {
    //   params['id.in'] = select.map(item => item.id);
    // } else {
    //   if (this.searchTeamName && this.searchTeamName.length > 0) {
    //     params['teamName.contains'] = this.searchTeamName;
    //   }
    //   if (this.searchSchoolName && this.searchSchoolName.length > 0) {
    //     params['schoolName.contains'] = this.searchSchoolName;
    //   }
    //   if (this.searchSchoolType && this.searchSchoolType !== '-1') {
    //     params['schoolType.equals'] = this.searchSchoolType;
    //   }
    //   if (this.searchRegion && this.searchRegion !== '-1') {
    //     params['location.equals'] = this.searchRegion;
    //   }
    //   if (this.searchProvinces && this.searchProvinces !== '-1') {
    //     params['province.equals'] = this.searchProvinces;
    //   }
    //   if (this.searchBimInfo && this.searchBimInfo !== '-1') {
    //     if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
    //       params['eventsId.equals'] = this.searchBimInfoEvents;
    //     } else {
    //       params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
    //     }
    //   }
    // }
    // if (this.searchWorkTop && this.searchWorkTop !== -1) {
    //   params['schoolTop.equals'] = this.searchWorkTop;
    // }
    const fileData = select[0];
    this.downloadLoading = true;
    params['key'] = new GuidUtil().toString();
    this.checkDownloadWorks(params, fileData);
  }

  checkDownloadWorks(params, fileData) {
    this.workManageService.checkDownloadWorks(params).subscribe(res => {
      if (res && res.body === 1) {
        this.startDownload = true;
        this.downloadZipWorks(params, fileData);
      } else {
        setTimeout(() => {
          this.checkDownloadWorks(params, fileData);
        }, 5000);
      }
    });
  }

  downloadZipWorks(params, fileData) {
    const url = `${this.teamUrl}/downloadWorks`;
    // 需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    this.http.get(url ,  {
      params: params ,
      headers: new HttpHeaders({ 'Content-Type': 'application/zip' }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).subscribe(
      data => {
        let blob = new Blob([data.body]);
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download',  fileData.schoolName + '-' + fileData.teamName + '-' + fileData.itemName + '.zip');
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadLoading = false;
      },
      error => {
        this.downloadLoading = false;
        MessageHelper.error('下载失败，请稍后重试');
        console.error('全国赛团队作品下载失败 ->', error);
      },
    );
  }


  workAdvisor(data){
    this.modal.static(BimWorkAdvisorComponent, {
      bimInfoName: data.bimInfoName,
      bimInfoEventName: data.eventsName,
      schoolName: data.schoolName,
      teamName: data.teamName,
      itemName: data.itemName,
      workName: data.name,
      bimEventId: data.eventsId,
      workId:data.id,
      isReply: '0'
    }, 1200).subscribe((res) => {
       
        this.getList();
    });
  }

  generateQrCode() {
    const selList = this.list.filter(e => e.checked === true);
    if (selList && selList.length > 0) {
      if (selList.length > 1) {
        return this.msg.warning('只能选择一条数据生成！');
      } else {
        const selWork = selList[0];
        const fileName =  selWork.name + '-作品二维码';

        this.previewWorkUrl = this.previewUrl + selWork.id;
        this.modalService.info({
          nzTitle: fileName,
          nzContent: this.workQrCode,
          nzWidth: '50vh'
        });
        // const extField1 = 'workQrCode';
        // this.fileUploadService.query({
        //   'fileFk.equals': selWork.id,
        //   'extField1.equals': extField1
        // }).subscribe(dRes => {
        //   // 已生成二维码直接下载
        //   if (dRes && dRes.body.length > 0) {
        //     this.download(dRes.body[0].id);
        //   } else {
        //     // 生成二维码
        //     this.workManageService.generateWorkQrCode({
        //       workId: selWork.id,
        //       workName: selWork.name
        //     }).subscribe(res => {
        //       const result = res.body;
        //       if (result.success) {
        //         const fileData = result.data;
        //         if (!isNullOrUndefined(fileData)) {
        //           fileData.extField1 = extField1;
        //           this.fileUploadService.create(fileData).subscribe(fRes => {
        //             if (fRes && fRes.body) {
        //               const file = fRes.body;
        //               this.download(file.id);
        //             }
        //           });
        //         }
        //       } else {
        //         this.msg.warning(result.message);
        //       }
        //     });
        //   }
        // });
      }
    } else {
      return this.msg.warning('请选择一条数据！');
    }
  }

  download(fileId) {
    const a = document.createElement('a');
    const url = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    a.click();
  }
}
