import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimInfoEvents } from '../bim-info-events.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimInfoEventsService } from '../bim-info-events.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimItemsService } from '../../../bim-items/bim-items.service';
import {BimInfoEventsItemsService} from '../bim-info-events-items/bim-info-events-items.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-bim-bimInfo-events-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoEventsEditComponent implements OnInit {


  public BIM_EVENTS_STATUS = {'0': '未开始',  '1' : '开始', '2' : '结束'};
  public BIM_EVENTS_TYPE = {'0': '团队赛',  '1' : '个人赛'};
  public BIM_EVENTS_IS = {'0': '否',  '1' : '是'};
  public BIM_SEL_TYPE = {'0': '多选',  '1' : '单选'};

  record: any = {};
  bimInfoId:string;
   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';


  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  ItemTypes=[];
  Items =[];
  BimInfoEventsItems=[];

  dateFormat = 'yyyy/MM/dd';
  public submitting = false;

  public type = null;

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimInfoEventsService: BimInfoEventsService,
    private bimItemsService:BimItemsService,
    private bimInfoEventsItemsService:BimInfoEventsItemsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId:[this.bimInfoId, []],
      name: [null, [Validators.required,Validators.maxLength(50)]],
      eventsType: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, []],
      isUplaodWork: [null, [Validators.required]],
      isUploadReceipt: [null, [Validators.required]],
      schoolWorkUploadStatus: [null, [Validators.required]],
      schoolWorkScoreStatus: [null, [Validators.required]],
      advisorsWorkUploadStatus: [null, [Validators.required]],
      advisorsWorkScoreStatus: [null, [Validators.required]],
      replyWorkUploadStatus: [null, [Validators.required]],
      receiptStatus: [null, [Validators.required]],
      maxTopCount: [null, []],
      isDelete: [0, []],
      isUpdatePrizeInfo: [null, [Validators.required]],
      isSingle: ['0', [Validators.required]],
      isOnly: ['0', [Validators.required]],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
   // this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
   // this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';

  }

  ngOnInit() {
    this.loadItems();
    
    if (!this.isAdd) {     
      this.loadData();
      this.loadBimInfoEventsItems();
    }


  }




  loadData(): void {
       this.record.eventsType = this.record.eventsType.toString();
       this.record.isUplaodWork = this.record.isUplaodWork.toString();
       this.record.isUploadReceipt = this.record.isUploadReceipt.toString();
       this.record.schoolWorkUploadStatus = this.record.schoolWorkUploadStatus.toString();
       this.record.schoolWorkScoreStatus = this.record.schoolWorkScoreStatus.toString();
       this.record.advisorsWorkUploadStatus = this.record.advisorsWorkUploadStatus.toString();
       this.record.advisorsWorkScoreStatus = this.record.advisorsWorkScoreStatus.toString();
       this.record.receiptStatus = this.record.receiptStatus.toString();

       if(this.record.isUpdatePrizeInfo != null && this.record.isUpdatePrizeInfo !=undefined)
       {
        this.record.isUpdatePrizeInfo = this.record.isUpdatePrizeInfo.toString();
       }

       if (!isNullOrUndefined(this.record.replyWorkUploadStatus)) {
        this.record.replyWorkUploadStatus = this.record.replyWorkUploadStatus.toString();
       }
       

       this.bimInfoId  = this.record.bimInfoId;

       this.record = JSON.parse(JSON.stringify(this.record));
       if (!isNullOrUndefined(this.record.startTime) && !isNullOrUndefined(this.record.endTime)) {
        this.record.startTime = [this.record.startTime, this.record.endTime];
       }
       this.form.patchValue(this.record);
  }


  loadItems(){
    this.Items=[];
    this.ItemTypes=[];
    let copyParams = {};
    copyParams['isDelete.equals'] = 0;
    copyParams['size'] = 1000;
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    this.bimItemsService.query(copyParams).subscribe((res: any) => {
      
          this.Items = res.body;
          let tmpType="";
          this.Items.forEach(element => {
               let tmpHasItem = this.ItemTypes.filter(d => d.type === element.type);
               if(element.type != tmpType && tmpHasItem.length <= 0)
               {
                   tmpType = element.type;
                   let tmpTypeObj = {};
                   tmpTypeObj["type"] = tmpType;
                   this.ItemTypes.push(tmpTypeObj);
               }
          });
    });
  }

  loadBimInfoEventsItems(){
    this.BimInfoEventsItems = [];
    let copyParams = {};
    copyParams['bimInfoEventsId.contains'] = this.record.id;
    this.bimInfoEventsItemsService.query(copyParams).subscribe((res: any) => {
        this.BimInfoEventsItems = res.body;
    });
  }

  // 保存
  submit() {
    
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查BIM大赛赛项信息是否完整');
      return;
    }
    if (this.BimInfoEventsItems.length === 0) {
      this.msg.error('请选择比赛专项');
      return;
    }
    
    param["bimInfoId"]  = this.bimInfoId;
    const time = param['startTime'];
    param['startTime'] = time[0];
    param['endTime'] = time[1];
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    let queryParamTmp ={};
    queryParamTmp['name.equals'] = param["name"];
    queryParamTmp['bimInfoId.equals'] = param["bimInfoId"];
    queryParamTmp['isDelete.equals'] = '0';
    this.submitting = true;
    this.bimInfoEventsService.query(queryParamTmp).subscribe((res: any) => {
      let listTmp = res.body;
      let totaltmp = res.headers.get('X-Total-Count');
         if(totaltmp >1 || (totaltmp == 1 && listTmp[0].id !== param["id"] ))
         {
          this.msg.error('该专项分类下已经存在重名的赛项，不能保存，请检查！');
          this.submitting = false;
         }else{
                this.bimInfoEventsService.update(param).subscribe(res => {
                  this.submitting = false;
                  const record = res.body;
                  if (record  ) {
                    this.saveItems(record.id, '修改成功');
                  } else {
                    this.submitting = false;
                    this.msg.error('修改失败');
                  }
                });
          }
        });

  }


  addDataInfo(param: any) {
    let queryParamTmp ={};
    queryParamTmp['name.equals'] = param["name"];
    queryParamTmp['bimInfoId.equals'] = param["bimInfoId"];
    queryParamTmp['isDelete.equals'] = '0';

    this.submitting = true;
    this.bimInfoEventsService.query(queryParamTmp).subscribe((res: any) => {
      let totaltmp = res.headers.get('X-Total-Count');
         if(totaltmp >0)
         {
          this.msg.error('该专项分类下已经存在重名的赛项，不能保存，请检查！');
          this.submitting = false;
         }else{

          this.bimInfoEventsService.create(param).subscribe(res => {
            const record = res.body;
            if (record  ) {
              this.saveItems(record.id,'保存成功！');
              
            } else {
              this.submitting = false;
              this.msg.error('保存失败');
            }
          });

        }
      });
  }

  saveItems(eventId:string, msg:string){
      let ids=[];
      this.BimInfoEventsItems.forEach(item=>{
           ids.push(item.itemId);
      })
      if (ids.length > 0) {
        this.bimInfoEventsItemsService.saveItemByEvents({'eventid': eventId, 'ItemsIdList': ids.toString()
      }).subscribe(res => {
          this.msg.info(msg);
          this.nzModalRef.destroy();
          this.submitting = false;
      });
      } else {
        this.msg.info(msg);
        this.nzModalRef.destroy();
        this.submitting = false;
      }
  }


  Cancel(){
    this.nzModalRef.destroy();
  }


  getItemByType(type){
    return this.Items.filter(d => d.type === type).sort(d=>d.orderSn);
  }


  /**去掉空对象 */
  delNullObj(List: any): any {
    const zhangstr = [];
    for (let k = 0; k < List.length; k++) {
      const obj = List[k];
      if (obj != null)
        zhangstr.push(obj);
    }

    return zhangstr;
  }


    /**
   *
   * @param $event 复选按钮触发事件
   * @param optValue
   */
  checkboxClick($event, itemId: string) {
    
    const target = $event.target;
    if(target.checked === true){
      let tmpItems =  this.BimInfoEventsItems.filter(d=>d.itemId === itemId);   
      if(tmpItems.length <=0)
      {
         let tmpBimItem ={};
         tmpBimItem["itemId"] = itemId;
         tmpBimItem["bimInfoEventsId"] = this.record.id;
         this.BimInfoEventsItems.push(tmpBimItem);
      }
    }else{
      let nindex;
      this.BimInfoEventsItems.forEach((element, index) => {

        if (element.itemId === itemId) {
          nindex = index;
        }
      });

      //删除没有选择项的，并清空空记录
       delete this.BimInfoEventsItems[nindex];
       this.BimInfoEventsItems = this.delNullObj(this.BimInfoEventsItems);
    }
  }

  getCheckBoxValue(itemId: string){
      let tmpItems =  this.BimInfoEventsItems.filter(d=>d.itemId === itemId);  
      if (tmpItems.length > 0)
      {
        return 'true';
      }else{
        return '';
      }

  }
}
