import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EduOrderRoutingModule } from './order-routing.module';
import { EduCoursePayComponent } from './course-pay/course-pay.component';
import { EduCertPayComponent } from './cert-pay/cert-pay.component';
import { CoursePayService } from './course-pay/course-pay.service';
import { CertPayService } from './cert-pay/cert-pay.service';
import { EduCoursePayDetailsComponent } from './course-pay/details/details.component';
import { CoursePayDetailsService } from './course-pay/details/details.service';

const COMPONENTS = [
  EduCoursePayComponent,
  EduCertPayComponent
];
const COMPONENTS_NOROUNT = [
  EduCoursePayDetailsComponent
];



@NgModule({
  imports: [
    SharedModule,
    EduOrderRoutingModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  providers: [
    CoursePayService,
    CertPayService,
    CoursePayDetailsService
  ],
  entryComponents: COMPONENTS_NOROUNT

})
export class EduOrderModule { }
