import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { Dept } from './department.model';
import { createRequestOption } from '@shared/utils/request-util';
import { ThsBaseService } from '@shared/components/thsBaseService';

@Injectable({ providedIn: 'root' })
export class DeptService extends ThsBaseService<Dept>{
    protected resourceUrl = '/thsadmin/api/org-depts';

    constructor(protected http: HttpClient) {
        super(http, `/thsadmin/api/org-depts`);
    }

    queryForDuty(options?: any): Observable<HttpResponse<Dept[]>> {
        return this.http.get<Dept[]>(this.resourceUrl+ "/queryForDuty", { params: options, observe: 'response' });
    }

}
