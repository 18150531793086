import { Component, OnInit } from "@angular/core";
import { SimpleTableColumn } from "@delon/abc";
import { BimSoftTypeEditComponent } from "./edit/edit.component";
import { ModalHelper } from "@delon/theme";
import { BimSoftDownloadTypeService } from "./bim-soft-type.service";
import { isNullOrUndefined } from "util";
import { SoftTypeNodeComponent } from "./soft-type-node/soft-type-node.component";
import { NzMessageService, NzModalService } from "ng-zorro-antd";

@Component({
  selector: 'app-bim-info-soft-type',
  templateUrl: './bim-soft-type.component.html',
  styleUrls: []
})
export class BimInfoSoftTypeComponent implements OnInit {

  public softTypeList = [];
  public isloadding = false;

  public softTypePage = 0;
  public softTypeSize = 10;
  public softTypeTotal = '0';

  public bimInfoId = null;

  softTypeColumns: SimpleTableColumn[] = [
    // { title: '', index: 'id', type: 'checkbox', width: '1%' },
    { title: '软件分类名称', index: 'name', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '是否启用', index: 'isAble', width: '12%', format: (item: any) => {
        if (isNullOrUndefined(item.isAble)) {
          return '';
        }
        if (item.isAble == '1') {
          return '启用';
        } else {
          return '不启用';
        }
      }
    },
    { title: '排序', index: 'orderNum', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '操作',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item), },
        { text: '配置软件', type: 'modal', component: SoftTypeNodeComponent, paramName: 'record', 
          modal: {
            modalOptions: {
              nzMaskClosable: false
            }
          },
          size: 850, click: (res) => {
            if (res) {
              this.getSoftTypeDataList();
            }
          } },

        { text: '删除', click: (item: any) => this.remove(item), },
      ], width: '20%'
    }
  ];


  constructor(
    public modal: ModalHelper,
    public msg: NzMessageService,
    public modalService: NzModalService,
    public bimSoftDownloadTypeService: BimSoftDownloadTypeService,
  ) {

  }

  ngOnInit(): void {
    this.getSoftTypeDataList();
  }

  getSoftTypeDataList() {
    this.isloadding = true;
    this.bimSoftDownloadTypeService.query({
      'bimInfoId.equals': this.bimInfoId,
      'parentId.specified': false,
      page: this.softTypePage,
      size: this.softTypeSize,
      sort: ['orderNum']
    }).subscribe((res) => {
      this.isloadding = false;
      this.softTypeList = res.body;
      if (this.softTypeList.length === 0 && this.softTypePage > 0) {
        this.softTypePage = 0;
        this.getSoftTypeDataList();
      }
      this.softTypeTotal = res.headers.get('X-Total-Count');
    });
  }

  add() {
    this.modal.static(BimSoftTypeEditComponent, 
      { 
        record: {
          bimInfoId: this.bimInfoId, 
          orderNum: Number(this.softTypeTotal) + 1
        },
        isAdd: true }, 500)
      .subscribe((res) => {
        if (res) {
          this.getSoftTypeDataList();
        }
      });
  }

  edit(item) {
    this.modal.static(BimSoftTypeEditComponent, { record: item, isAdd: false }, 500)
      .subscribe((res) => {
        if (res) {
          this.getSoftTypeDataList();
        }
      });
  }

  remove(res: any) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isloadding = true;
        this.bimSoftDownloadTypeService.delete(res.id)
          .subscribe(() => {
            this.msg.remove();
            this.msg.success('删除成功！');
            this.softTypePage = 0;
            this.getSoftTypeDataList();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  softTypePageChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.softTypePage = event.pi - 1;
    this.softTypeSize = event.ps;
    this.getSoftTypeDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  softTypePageChangePage(event) {
    this.softTypePage = event - 1;
    this.getSoftTypeDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  softTypePageChangeSize(event) {
    this.softTypeSize = event;
    this.getSoftTypeDataList();
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => item[col.indexKey] === null ? '' : item[col.indexKey];

}