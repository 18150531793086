// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  SERVER_URL: `https://api.educloud.thsware.com`,
 FILE_URL: `https://file.educloud.thsware.com`,
  // SERVER_URL: `http://172.17.0.138:9084`,
  //  SERVER_URL: `http://202.105.146.197:11480`,
  //  SERVER_URL: `http://192.168.0.50:11480`,
    // FILE_URL: `http://202.105.146.197:11480`,
    // FILE_URL: `http://192.168.0.50:11480`,
  // SERVER_URL: `http://127.0.0.1:15034`,
  // FILE_URL: `http://127.0.0.1:15034`,
  production: false,
  useHash: true,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

