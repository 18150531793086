import { NzMessageService } from 'ng-zorro-antd';
import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { Router } from '@angular/router';
import { WorkflowService } from '@shared/components/workflow/workflow.service';
import { SimpleTableColumn, SimpleTableComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-v1',
  styleUrls:['./v1.component.less'],
  templateUrl: './v1.component.html',
})
export class DashboardV1Component implements OnInit {
 
  status:string='unFinish';
  loading = false;
  unFinishList: any[] = [];
  finishList: any[] = [];
  unFinishTotal: number = 0;
  finishTotal: number = 0;
  total: number = 0;
  params: any = {};
  thingTitle:string='';

  searchSchema: SFSchema = {
    properties: {
      thingTitle: {
        type: 'string',
        title: '标题',
        $comment: 'contains'
      }
    }
  };

  @ViewChild('st') st: SimpleTableComponent;
  unFinishTaskColumns: SimpleTableColumn[] = [
    { title: '序号', index: 'no' },
    { title: '标题', index: 'thingTitle' },
    { title: '发送人', index: 'sender' },
    { title: '发送时间', type:'date',index: 'sendTime' },
    { title: '阅读时间',type:'date', index: 'readTime' },
    { title: '办理类型',  index: 'thingType' },
    {
      title: '操作',
      buttons: [
        {
          text: `审核`,
          click: (record: any) =>{
            if(record&&record.thingUrl){
              this.router.navigateByUrl(record.thingUrl);
            }
          }        
        },
      ]
    }
  ];
  finishedTask: SimpleTableColumn[] = [
    { title: '序号', index: 'no' },
    { title: '标题',  index: 'thingTitle' },
    { title: '发送人',  index: 'sender' },
    { title: '发送时间', type: 'date', index: 'sendTime' },
    { title: '阅读时间', type: 'date', index: 'readTime' },
    { title: '办理类型', index: 'thingType' },
  ];
  constructor( public workflowService:WorkflowService,   
     public message: NzMessageService,
     private router: Router,
    ) { }
    
  ngOnInit() {
    //this.loadUnFinishThings();
   // this.getTotal();

  }
  getTotal(){
    this.workflowService.getThingsList().pipe(
      tap(() => (this.loading = false))
    ).subscribe((res) => {
      this.total = res.headers.get('X-Total-Count');
      res.body.map((item: any, idx: number) => {
        if(item.isFinish=="1") {
          this.finishTotal+=1;
        }
        else{
          this.unFinishTotal+=1;
        }    
      });
    }, (res: HttpResponse<any>) => this.onError(res.body));
  }

  
  loadUnFinishThings(){
    this.loading = true;
    this.workflowService.getThingsList(
      Object.assign({
        'isFinish.equals': '0'
      }, this.getParams())
    ).pipe(
      tap(() => (this.loading = false))
    ).subscribe((res) => {
      this.unFinishList =  res.body.map((item: any, idx: number) => {
        item=Object.assign(item,{"no":idx+1});
        return item;
      });
     
    }, (res: HttpResponse<any>) => this.onError(res.body));
   }
   
   loadFinishThings(){
    // this.loading = true;
    // this.workflowService.getThingsList(
    //   Object.assign({
    //     'isFinish.equals': '1'
    //   }, this.getParams())
    // ).pipe(
    //   tap(() => (this.loading = false))
    // ).subscribe((res) => {
    //   this.finishList = res.body.map((item: any, idx: number) => {
    //     item=Object.assign(item,{"no":idx+1});
    //     return item;
    //   });
    // }, (res: HttpResponse<any>) => this.onError(res.body));
   }

  private onError(error) {
    this.message.create('error', error.message);
  }
  
  getParams() {
    let result = {};
    for (let p in this.params) {
      if (this.params[p].value != null && this.params[p].value != '' && typeof (this.params[p].value) != undefined) {
        result[p + '.' + this.params[p].type] = this.params[p].value;
      }
    }
    return result;
  }

  onChange($event) {
    switch (this.status) {
      case 'unFinish':
        // this.loadUnFinishThings();
        break;
      case 'finish':
        // this.loadFinishThings();
        break;
    }
  }
  rowClick($event){
    if($event.item&&$event.item.thingUrl){
      this.router.navigateByUrl($event.item.thingUrl);
    }
  }
  submit(value: any): void {
    this.params['thingTitle']={value:this.thingTitle,type:'contains'};
    // this.reload();
  }

  reload(): void {
    switch (this.status) {
      case 'unFinish':
        this.loadUnFinishThings();
        break;
      case 'finish':
        this.loadFinishThings();
        break;
    }
  }
}
