import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CertRegedistPayManageService } from './certRegedistPayManage.service';
import { CertRegedistPay } from './certRegedistPay.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-certRegedistPay',
  templateUrl: './certRegedistPayManage.component.html',
})
export class EduCertRegedistPayComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: CertRegedistPay[] = [];

  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'edu_cert_channel_id': '',
    'name': '',
    'id_card': '',
    'edu_cert_exam_name': '',
    'status': '',
    'pay_type': '',
    // sort固定
     sort: ['crp.created_date,desc']
  };

  certChannel = [];

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '学员姓名', index: 'name' , width: '10%'},
    { title: '身份证号码', index: 'id_card' , width: '13%'},
    { title: '认证类型', index: 'auth_type' , width: '15%'},
    { title: '考试科目', index: 'edu_cert_exam_name' , width: '20%'},
    {
      title: '支付方式', index: 'pay_type', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.pay_type === 'wx') {
          return '微信';
        } else if (item.pay_type === 'zfb') {
          return '支付宝';
        }
      },
      filters: [
        { text: '微信', value: 'wx' }, // 此处值对应数据库的值，即domain对象的值
        { text: '支付宝', value: 'zfb' }  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '支付金额', index: 'price' , width: '10%'},
    {
      title: '支付状态', index: 'status', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.status === '1') {
          return '已支付';
        } else if (item.status === '0') {
          return '未支付';
        }
      },
      filters: [
        { text: '已支付', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '未支付', value: 0 }  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '支付时间', index: 'pay_time' , width: '15%'},
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public certRegedistPayManageService: CertRegedistPayManageService
  ) {}

  ngOnInit() {
    this.getCertChannel();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    console.log(this.queryParams);
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
        copyParams[key] = q[key];
      });
    } else {
      Object.keys(q).forEach(function (key) {
          copyParams[key] = q[key];
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    console.log(copyParams);

     this.certRegedistPayManageService.queryData(copyParams)
      .subscribe((res: any) => {
      this.list = res.body.data;
      this.total = res.headers.get('X-Total-Count');
      });
  }

   getCertChannel() {
    this.certRegedistPayManageService.findAllChannel().subscribe(res => {
      if (res.body && res.body.length) {
          this.certChannel = res.body;
         // console.log(this.dictDetail);
      }
      this.getDataList();
    });
  }



  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    console.log(JSON.stringify(event));
    let _value = '';
    event.filters.forEach(element => {
      if (element.checked) {
        _value = element.value;
      }
    });
    this.queryParams[event.indexKey] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }



  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


  searchData() {
    this.page = 0;
    this.size = 10;
    this.getDataList();
  }


}
