import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CertExamineResult} from './certExamineResult.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { filter} from 'rxjs/operators';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CertExamineResultManService extends ThsBaseService<CertExamineResult> {
  examineResultUrl = '';
  channelUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-cert-examine-results`);
      this.channelUrl = httpAddressService.EduServe + '/edu-certification-channels';
      this.examineResultUrl = httpAddressService.EduServe + '/edu-cert-examine-results';
  }

  findAllChannel(): Observable<HttpResponse<any[]>>  {
    const url = this.channelUrl + '/findAllChannel';
    return this.http.get<any>(`${url}`, { observe: 'response' });
  }
saveImportExaminResult(formData: FormData) {
  const url = this.examineResultUrl + '/saveImportExaminResult' ;
  const req = new HttpRequest('POST', url, formData, {
  });
 return  this.http.request(req).pipe(filter(e => e instanceof HttpResponse));
}
saveImportExaminResult2 (formData: FormData): Observable<HttpResponse<any>> {
  return this.http.post<any>(this.examineResultUrl + '/saveImportExaminResult', formData,
   {headers: {'Content-Type': null},  observe: 'response'})
  .pipe(map((res: HttpResponse<any>) => res));
}


queryExamineResult (queryParams ?: any): Observable<HttpResponse<CertExamineResult[]>> {
  return this.http.get<CertExamineResult[]>(this.examineResultUrl + '/getCertRegedistResultByPage',
  {params: queryParams ,  observe: 'response'}).pipe(map((res: HttpResponse<CertExamineResult[]>) => res));
 }

 findExamineResult(id: string, resultId: string): Observable<HttpResponse<CertExamineResult>> {
  return this.http.get<CertExamineResult>(this.examineResultUrl + '/getCertRegedistResult?resultId=' + resultId + '&regedistId=' + id ,
  { observe: 'response' }).pipe(map((res: HttpResponse<CertExamineResult>) => this.convertDateFromServer(res)));
}


saveOrUpdate (record: CertExamineResult): Observable<HttpResponse<CertExamineResult>> {
  const copy = this.convertDateFromClient(record);
  return this.http.post<CertExamineResult>(this.examineResultUrl + '/saveOrUpdateResult', copy, { observe: 'response'})
  .pipe(map((res: HttpResponse<any>) => res));
}


}

