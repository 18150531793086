import { Component } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimPersonTearchersService } from './bim-person-teacher.service';
import { BimPersonTearchers } from './bim-person-teacher.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimPersonTeacherEditComponent} from './edit/edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PARAMETERS } from '@angular/core/src/util/decorators';
@Component({
  selector: 'app-bim-person-teacher',
  templateUrl: './bim-person-teacher-list.component.html',
  styleUrls: ['./bim-person-teacher-list.component.less']
})
export class BimPersonTeacherListComponent implements OnInit {
 
  public personId:String;
  public schoolName:String;
  public personName:String;
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimPersonTearchers[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];

  isloadding = false;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
     sort: ['name,asc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '姓名', index: 'name' , width: '10%'},
    { title: '性别', index: 'sex' , width: '10%',
      format: (item: any) => {
        if (item.sex === '1') {
          return '女';
        } else if (item.sex === '0') {
          return '男';
        } else{
          return '';
        }
      }
    },
   
    { title: '联系电话', index: 'mobile' , width: '20%'},
    { title: '邮箱', index: 'email' , width: '20%'},  
    { title: '专业', index: 'profession' , width: '20%'},
  
    {
      title: '操作', className: 'text-center',   width: '20%',
      buttons: [
         { text: '编辑',   click: (item:any) => this.edit(item) },
         { text: '删除', click: (item: any) => { this.remove(item); } },
      ],
    }
  ];

  constructor(
    private routeInfo: ActivatedRoute,
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimPersonTearchersService: BimPersonTearchersService,
  ) {
    this.routeInfo.queryParams.subscribe(parms => {
      if (parms.bimInfoId) {
         this.personId = parms.personId;
      }

      if(parms.schoolName){
        this.schoolName = parms.schoolName;
      }

      if(parms.personName){
        this.personName = parms.personName;
      }
    });
  }

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['personId.contains'] = this.personId;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.bimPersonTearchersService.query(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(BimPersonTeacherEditComponent, { record: { id: null},isAdd: true ,personId:this.personId, schoolName:this.schoolName, personName:this.personName })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  edit(record){
    this.modal
    .static(BimPersonTeacherEditComponent, { record: record,isAdd: false,personId:this.personId, schoolName:this.schoolName, personName:this.personName })
    .subscribe((res) => {
      //if(res === true)
      {
        this.getDataList();
      }
    });
  }



  /**
  * 删除
  */
  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimPersonTearchersService.delete(res.id)
          .subscribe(() => {
            this.page = 0;
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimPersonTearchersService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }

  

}
