import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimSchools,SchoolPerson } from '../bim-schools.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimSchoolsService } from '../bim-schools.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { UserService } from "../../../../admin/system/users/users.service";
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-bim-bimSchools-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimSchoolsEditComponent implements OnInit {


  public schoolTypeList = {'0': '本科',  '1' : '专科',  '2' : '中职'};
  public belongTypeList = {'0': '985',  '1' : '211', '2' : '其他'};

  record: any = {};

   // 是否增加
  isAdd: boolean;
  userLocationList = [];
  userProvinceList=[];
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';

  locationList=[];
  provinceList=[];
  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  collegeContactList = [];
  freqContactList = [];
  recommendSchoolList = [];
  recommendContryList = [];

  
  // 要更新附件外键的集合
  hasToUpdate = false;
  toUpdateFkSchoolLogoList = [];
  toUpdateFkSchoolFileList = [];
  extField1SchoolLogo={'extField1.equals': "SCHOOL_LOGO"};
  extField1SchoolFile={'extField1.equals': "SCHOOL_FILE"};

  bimInfoType = 'bim';

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimSchoolsService: BimSchoolsService,
    private userService: UserService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required]],
      college: [null, []],
      professional: [null, []],
      schoolType: [null, [Validators.required]],
      location: [null, [Validators.required]],
      province: [null, [Validators.required]],
      email: [null, [Validators.required]],
      address: [null, []],
      belongType: [null, [Validators.required]],
      collegeContact:[null, []], //院系负责人
      freqContact:[null, []],  //大赛日常联系人
      recommendSchool:[null, []], //推荐校内评委名单
      recommendContry:[null, []], //推荐全国评委名单
      userDefine1: [null, []],
      userDefine4: [null, []],
      userDefine6: [null, []],
      regCode: [null, []],
      isDelete: [0, []]
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
   // this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
   // this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';

  }

  ngOnInit() {
    if (!isNullOrUndefined(this.record.userDefine4)) {
      this.bimInfoType = this.record.userDefine4;//大赛类型
    }

    if (this.bimInfoType === 'province') {
      const str = ['location', 'province', 'email', 'belongType'];
      for (let i = 0; i < str.length; i++) {
        this.form.controls[str[i]].clearValidators();
      }
      const valStr = ['college', 'address'];
      for (let i = 0; i < valStr.length; i++) {
        this.form.controls[valStr[i]].setValidators([Validators.required, Validators.maxLength(100)]);
      }
    }
    let person1 = new SchoolPerson();
    person1.id = new GuidUtil().toString();

    let person2 = new SchoolPerson();
    person2.id = new GuidUtil().toString();

    let person3 = new SchoolPerson();
    person3.id = new GuidUtil().toString();

    let person4 = new SchoolPerson();
    person4.id = new GuidUtil().toString();

    this.loadLocationList();

    if (!this.isAdd) {
      this.loadprovinceList(this.record.location);
      this.fileId = this.record.id;
      this.loadData();
      
      if(this.record.collegeContact!= null && this.record.collegeContact.length > 0)
      {
        this.collegeContactList =  JSON.parse( this.ReplaceHtml(this.record.collegeContact));
      }else{
        this.collegeContactList.push(person1);
      }

debugger;
      if(this.record.freqContact!= null && this.record.freqContact.length > 0)
      {
        this.freqContactList =  JSON.parse( this.ReplaceHtml(this.record.freqContact));
      }else{
        this.freqContactList.push(person2);
      }


      if(this.record.recommendSchool!= null && this.record.recommendSchool.length > 0)
      {
        this.recommendSchoolList =  JSON.parse( this.ReplaceHtml(this.record.recommendSchool));
      }else{
        this.recommendSchoolList.push(person3);
      }

      if(this.record.recommendContry!= null && this.record.recommendContry.length > 0)
      {
        this.recommendContryList =  JSON.parse( this.ReplaceHtml(this.record.recommendContry));
      }else{
        this.recommendContryList.push(person4);
      }

    }else{
      this.loadData();
      this.fileId =  this.entityId;
      
      this.collegeContactList.push(person1);
      this.freqContactList.push(person2);
      this.recommendSchoolList.push(person3);
      this.recommendContryList.push(person4);
    }

   
  }



/**
 * 加载页面data
 */
  loadData(): void {
       this.form.patchValue(this.record);

  }

  /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      this.userService.queryRegionList(dataParams).subscribe(res => {
        
        if (res && res.body) {
          this.locationList = res.body;
        }
      });
  }


  setLocationData(event){
       this.loadprovinceList(event);
       this.form.controls['province'].setValue("") ;
  }

  /**
   * 加载省份列表
   */
  loadprovinceList(regionId){
    this.provinceList = [];
    
    this.userService.queryProvinceList({
      'id.in':this.userProvinceList,
      'fkregionId.equals': regionId,
      'size' : '10000',
      'sort': 'orderNum,asc'
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.provinceList = data;
      }
    });
  }

  // 保存
  submit() {
     
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查学校信息是否完整');
      return;
    }

    param['collegeContact'] =this.ReplaceStr(JSON.stringify(this.collegeContactList));
    param['freqContact'] =this.ReplaceStr(JSON.stringify(this.freqContactList));
    param['recommendSchool'] =this.ReplaceStr(JSON.stringify(this.recommendSchoolList));
    param['recommendContry'] =this.ReplaceStr(JSON.stringify(this.recommendContryList));


    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    this.bimSchoolsService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.hasToUpdate = true;
        this.msg.info('修改成功');
        //this.nzModalRef.destroy();
        this.Cancel(true);
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimSchoolsService.create(param).subscribe(res => {
      this.record = res.body;
      if (this.record  ) {
        // this.msg.info('保存成功');
        // this.nzModalRef.destroy();
        this.fileId = this.record;
        this.hasToUpdate = true;
        this.msg.info('保存成功');
        this.Cancel(true);
        //this.nzModalRef.destroy();
      } else {
        this.msg.error('保存失败');
      }
    });
  }


  Cancel(type?){
    
    if (this.hasToUpdate  && '' !== this.record.id) { // need
      let isChangeData = false;
      if ( this.toUpdateFkSchoolLogoList.length > 0)
      {
        isChangeData = true;
          for (const i of Object.keys(this.toUpdateFkSchoolLogoList)) {
            this.fileUploadService.updateFkBatch(this.toUpdateFkSchoolLogoList[i].response.fileFk, this.record.id)
              .subscribe((res: any) => {  this.nzModalRef.destroy(type);}, (res: HttpResponse<any>) => this.msg.create('error', '学校LOGO图片上传失败'));
          }

          
      }

      if ( this.toUpdateFkSchoolFileList.length > 0)
      {
          isChangeData = true;
          for (const i of Object.keys(this.toUpdateFkSchoolFileList)) {
            this.fileUploadService.updateFkBatch(this.toUpdateFkSchoolFileList[i].response.fileFk, this.record.id)
              .subscribe((res: any) => { this.nzModalRef.destroy(type);}, (res: HttpResponse<any>) => this.msg.create('error', '报名附件图片上传失败'));
          }
      }

      if(!isChangeData){
        this.nzModalRef.destroy(type);
      }

    } else {
      this.nzModalRef.destroy(type);
    }
  }


    /**
      * 上传成功回调
      * @param file
      */
     onSuccessfulSchoolLogo(file: any) {

      const thisSuccessFile = file.successFile;
      if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
        thisSuccessFile.forEach(item => {
          this.toUpdateFkSchoolLogoList.push(item);
          this.form.controls['userDefine6'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
        });
      }
    }

    /**
     * 删除成功回调
     * @param file
     */
    onDeleteFileSchoolLogo(file: any) {

      if (file.file) {
        this.toUpdateFkSchoolLogoList = this.toUpdateFkSchoolLogoList.filter(d => d.uid !== file.file.uid);
      }
      this.form.controls['userDefine6'].setValue(null);
    }


         /**
    * 上传成功回调
    * @param file
    */
   onSuccessfulSchoolFile(file: any) {

    const thisSuccessFile = file.successFile;
    if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
      thisSuccessFile.forEach(item => {
        this.toUpdateFkSchoolFileList.push(item);
      });
    }
  }

  /**
   * 删除成功回调
   * @param file
   */
  onDeleteFileSchoolFile(file: any) {

    if (file.file) {
      this.toUpdateFkSchoolFileList = this.toUpdateFkSchoolFileList.filter(d => d.uid !== file.file.uid);
    }
  }





    
      
      /**
     * 销毁时删除附件，防止文件表过多
     */
      // tslint:disable-next-line:use-life-cycle-interface
      ngOnDestroy(): void {

        if (null !== this.toUpdateFkSchoolLogoList && this.toUpdateFkSchoolLogoList.length > 0 && !this.hasToUpdate) {
              const idArray = new Array();
              this.toUpdateFkSchoolLogoList.forEach((d) => {
                idArray.push(d.response.id);
              });
              this.fileUploadService.delete(idArray).subscribe((res: any) => {
                console.log(idArray);
              });
        }
    
    
    
        if (null !== this.toUpdateFkSchoolFileList && this.toUpdateFkSchoolFileList.length > 0 && !this.hasToUpdate) {
          const idArray1 = new Array();
          this.toUpdateFkSchoolFileList.forEach((d) => {
            idArray1.push(d.response.id);
          });
          this.fileUploadService.delete(idArray1).subscribe((res: any) => {
            console.log(idArray1);
          });
        }
      }


      changeCollegeContactText($event, personType, personContentType, id:string){
       
          const target = $event.target;
          let tmpList = [];
          switch (personType) {
            case 1:
              tmpList = this.collegeContactList;
              break;
            case 2:
              tmpList = this.freqContactList;
                break;
            case 3:
              tmpList = this.recommendSchoolList;
              break;
            case 4:
              tmpList = this.recommendContryList;
                break;  
            default:
              break;
          }

          if (tmpList != null && tmpList.length > 0) {
            tmpList.forEach((element, index) => {
              if (element.id === id) {
               // element.optText = target.value;

                switch (personContentType) {
                  case 1:
                    element.name = target.value;
                    break;
                  case 2:
                    element.zw = target.value;
                      break;
                  case 3:
                    element.tel = target.value;
                    break;
                  case 4:
                    element.mobile = target.value;
                      break;  
                  case 5:
                    element.email = target.value;
                      break;  
                  default:
                    break;
                }
              }
            });
          }
      }

  /**去掉空对象 */
  delNullObj(List: any): any {
    const zhangstr = [];
    for (let k = 0; k < List.length; k++) {
      const obj = List[k];
      if (obj != null)
        zhangstr.push(obj);
    }

    return zhangstr;
  }
      
    delCollegeContactItem(personType,id:string){

      let tmpList = [];
      switch (personType) {
        case 1:
          tmpList = this.collegeContactList;
          break;
        case 2:
          tmpList = this.freqContactList;
            break;
        case 3:
          tmpList = this.recommendSchoolList;
          break;
        case 4:
          tmpList = this.recommendContryList;
            break;  
        default:
          break;
      }

      if (tmpList != null && tmpList.length > 0) {
        let nindex;
        tmpList.forEach((element, index) => {
  
          if (element.id === id) {
            nindex = index;
          }
        });
  

        delete tmpList[nindex];
  
        switch (personType) {
          case 1:
            this.collegeContactList =this.delNullObj(tmpList);
            break;
          case 2:
            this.freqContactList =this.delNullObj(tmpList);
              break;
          case 3:
            this.recommendSchoolList = this.delNullObj(tmpList);
            break;
          case 4:
            this.recommendContryList = this.delNullObj(tmpList);
              break;  
          default:
            break;
        }
      }
    }


    addCollegeContactItemItem(personType){
          let tmpPerson = new SchoolPerson();
          tmpPerson.id = new GuidUtil().toString();
          switch (personType) {
            case 1:
              this.collegeContactList.push(tmpPerson);
              break;
            case 2:
              this.freqContactList.push(tmpPerson);
                break;
            case 3:
              this.recommendSchoolList.push(tmpPerson);
              break;
            case 4:
              this.recommendContryList.push(tmpPerson);
                break;  
            default:
              break;
          }
    }



     /**
   * 特殊字符转换位html标签处理
   * @param obj
   */
  ReplaceStr(obj: string) {
    if (obj != null) {
      obj = obj.replace(/\n/g, '\<br\/\>');
      obj = obj.replace(/\"/g, '&quot;');
      obj = obj.replace(/\\/g, '&#92;');


      obj = obj.replace(/\//g, '&#47;');
      obj = obj.replace(/\^/g, '&#94;');
      obj = obj.replace(/\{/g, '&#123;');
      obj = obj.replace(/\}/g, '&#125;');
      obj = obj.replace(/\+/g, '&#43;');
      obj = obj.replace(/\(/g, '&#40;');
      obj = obj.replace(/\)/g, '&#41;');
    }
    return obj;
  }

  /**
   * html标签转换位转义字符处理
   * @param obj
   */
  ReplaceHtml(obj: string) {
    // if (obj === undefined) {
    //   return;
    // }
    obj = obj.replace(/\<br\/\>/g, '\n');
    obj = obj.replace(/&quot;/g, '"');
    obj = obj.replace(/&#92;/g, '\\');

    obj = obj.replace(/&#47;/g, '/');
    obj = obj.replace(/&#94;/g, '^');
    obj = obj.replace(/&#123;/g, '{');
    obj = obj.replace(/&#125;/g, '}');
    obj = obj.replace(/&#43;/g, '+');
    obj = obj.replace(/&#40;/g, '(');
    obj = obj.replace(/&#41;/g, ')');

    return obj;
  }

}
