import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { environment } from '@env/environment';
import { zip } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ReuseTabService } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { NicheEditComponent } from 'app/routes/business/iucce/component/niche-edit/edit.component';
import { ProjectNicheInfoComponent } from "app/routes/business/iucce/projectNiche/edit/edit.component";
import { NzMessageService,NzModalService } from 'ng-zorro-antd';
import { ProjectReportService } from '../../projectReport/projectReport.service';
import { ProjectNicheService } from '../projectNiche.service';
import { ProjectNicheFollowService } from '../projectNicheFollow.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { COMMON_DATA } from '@shared/app.commonData';
import { isTemplateRef } from 'ng-zorro-antd/src/core/util/check';
import * as moment from 'moment';

@Component({
  selector: 'app-project-niche-edit',
  templateUrl: './niche.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less','./niche.component.less']
})
export class ProjectNicheEditComponent implements OnInit {

  // validateForm: FormGroup;
  validateCloseNicheForm:FormGroup
  projectId;
  fileList;
  dtNicheLoading = false;
  dtNicheFollowLoading = false;
  closeNicheLoading = false;
  closeNicheModalVisible = false;//关闭商机弹框，默认隐藏
  
  total = '0';
  page = 0;
  size = 10;
  pageSizeOptions = [10, 20, 50, 100];
  
  
  isCollapse = true;
  queryParams = {
    'iucceProjectId':'',
    'iucceProjectNicheId':'',
    'year':[],
    'month':[],
    'orderDateBegin':'',
    'orderDateEnd':'',
    'orderAmountMin':'',
    'orderAmountMax':'',
    'searchValue':'',
  };
  orderStatusList = [
    { value: 11, label: '结项审核中',color: '#18BBFF'},
    { value: 21, label: '结项通过',color: '#46C39E' },
    { value: 31, label: '结项不通过',color: '#FF6D46' },
    { value: null, label: '待成单',color: '#666666' },
    { value: 0, label: '待成单',color: '#666666' },
    { value: 1, label: '部分成单',color: '#FFAE46' },
    { value: 3, label: '全部成单',color: '#46C39E' }
  ]
  //项目商机情况
  prjNicheList = [];
  prjNicheFollowAllList = {};//商机跟进详情
  yearList: any;//年份
  monthList = [];
  
  countRemark = 0;
  opportunitys = [];
  countRemark2 = 0;

  record:any;
  isView = false;
  closeNicheData:any;//关闭商机数据
  prjNicheMaxOrderNum = 0;//项目商机最大的排序号
  prjNicheDataKeys = [];//项目商机情况id key集合
  prjNicheDatas = [];//项目商机情况数据集合
  curMonth = moment().month() + 1;//当前月份
  curYear= moment().year();//当前年份
  COMMON_DATA = COMMON_DATA;
  prjOrderStatusList = [];

  constructor(
    private fb: FormBuilder,
    public modalService: NzModalService,
    private router: Router,
    private routerActived: ActivatedRoute,
    private message: NzMessageService,
    private projectReportService: ProjectReportService,
    private projectNicheService: ProjectNicheService,
    private projectNicheFollowService: ProjectNicheFollowService,
    private dictService: DictService,
    private reuseTabService: ReuseTabService,
    private thsadminFileService: ThsadminFileService,
    private modal: ModalHelper,
  ) { 
    this.projectId = this.routerActived.snapshot.queryParams['id'];
    this.isView = this.routerActived.snapshot.queryParams['isView'];
    this.prjOrderStatusList = this.COMMON_DATA.iucceProjectOrderStatus;

  }
  
  ngOnInit(): void {
    this.validateCloseNicheForm = this.fb.group({
      nicheStatus : [3, [Validators.required]],//关闭商机
      closedReason : [null, [Validators.required]],
      orderStatus : [null,[Validators.required]],
      orderAmount : [{ value : null, disabled : true }]
    });
    this.monthList = this.COMMON_DATA.month;
    this.getFileList();
    this.getProjectNiches();
    this.getYearListDict();
  }

  getYearListDict(){
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
  }

  /**
   * 获取项目商机信息
   */
  getProjectNiches(){
    if(!this.projectId){
      return;
    }
    
    const nicheParams = {
      'iucceProjectId.equals' : this.projectId,
      sort : ['estimateOrderDate,asc','createdDate,asc'],
      page:this.page,
      size:this.size
    };
    const nicheFollowParams = {
      'iucceProjectId.equals' : this.projectId,
      size:10000
    };

    zip(
      //this.projectReportService.findProjectNicheinfos(this.projectId),
      this.projectNicheService.query(nicheParams),
      this.projectNicheService.queryProjectNicheFollowDatas(nicheFollowParams)
    ).subscribe(([nicheRes, nicheFollowRes]) => {
      this.prjNicheList = nicheRes.body;
      this.total = nicheRes.headers.get('X-Total-Count');
      const nicheFollowAllList = nicheFollowRes.body;
      if(this.prjNicheList && this.prjNicheList.length > 0){
        let index = 0;
        this.prjNicheList.forEach(item => {
          item.expand = false;
          if(index == 0){
            item.expand = true;
          }
          const nicheFollowAllListSel = nicheFollowAllList.filter(w=>w.iucceProjectNicheId == item.id);
          this.prjNicheFollowAllList[item.id] = nicheFollowAllListSel;
          index ++;
        })
      }
      
      
    })
  }

  returnProjectInfo(event) {
    this.record = event;
   
    //结项申请提交后不允许修改
    if(this.record.prjStatus > 60){
      this.isView = true;
    }
  }

  /**
   * 获取文件列表
   */
  getFileList(){
    let queryParams = {
      "fileFk.equals" : this.projectId,
      "extField2.equals" : 'IucceProject',
      page : 0,
      size : 10000,
      ort : ['extField1,asc','createdDate,asc','fileName,asc']
    };
    this.thsadminFileService.queryFiles(queryParams).subscribe(res=>{ 
      let result = res.body;
      result = result.filter(w=>w.extField1 != 'IucceProjectReportAudit' && w.extField1 != 'IucceProjectClosingAudit' && w.extField3 != 'IucceProjectClosing');//排除立项申报审核、结项提交附件、结项审核附件
      result.forEach(item => {
        item.uid = item.id;
        item.name = item.fileName;
        item.url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + item.id;
        
        if (item.extField1 === 'IucceProjectReport') {
          item.fileDesc = '项目申报书';
        } else if (item.extField1 === 'IucceProjectConstract') {
          item.fileDesc = '合同协议';
        } else {
          item.fileDesc = '其他附件';
        }
      });
      this.fileList = result;
    });
  }
  
  downFile(file) {
    const a = document.createElement('a');
    const filename = file.fileName;
    a.href = file.url;
    a.download = filename;
    a.click();
  }
  
  //商机跟进情况-成单状态
  orderStatusLabel(orderStatus) {
    const label =  this.orderStatusList.filter(item => item.value == orderStatus);
    if(label == null || label.length <= 0){
      return "";
    }
    return label[0].label;
  }
  orderStatusColor(orderStatus) {
    const label =  this.orderStatusList.filter(item => item.value == orderStatus);
    if(label == null || label.length <= 0){
      return "";
    }
    return label[0].color;
  }
  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }


  //新增
  addNiche() {
    this.modal.static(ProjectNicheInfoComponent, {
      pageType:'add',
      record:{
        iucceProjectId : this.projectId,
        orderNum : this.total
      }
    }, 800).subscribe((result) => {
      if(result != null){
        this.getProjectNiches();
      }
    });
  }
  //编辑
  editNiche(data) {
    this.modal.static(ProjectNicheInfoComponent, {
      pageType: 'edit',
      record:data
    }, 800).subscribe((result) => {
      if(result != null){
        this.getProjectNiches();
      }
    });
  }
  //删除商机信息
  deleteNiche(data){
    this.modalService.confirm({
      nzTitle: '确定要删除吗?',
      nzContent: '',
      nzOkText: '确定',
      nzOkType: 'danger',
      nzOnOk: () => {
        debugger;
        this.projectNicheService.deleteByNicheId(data.id,data.iucceProjectId).subscribe(res=>{
          if(res.status == 200){
            this.message.info("删除成功");
            this.getProjectNiches();
          }else{
            this.message.info("删除失败");
          }
        },(errorRes)=>{
          this.message.info("删除失败");
        });
      },
      nzCancelText: '取消',
     
    });
  }
  //查看
  viewNiche(data) {
    this.modal.static(ProjectNicheInfoComponent, {
      pageType: 'view',
      record:data
    }, 800).subscribe();
  }

  //增加商机跟进详情
  addNicheFollow(data){
    this.modal.static(NicheEditComponent, {
      pageType: 'add',
      record:{ 
        iucceProjectId : data.iucceProjectId,
        iucceProjectNicheId : data.id,
        id:null 
      }
    }, 1080)
    .subscribe((result) => {
      if(result){
        this.getProjectNiches();
        //this.getNicheFollowDataList(data,true);
      }
    });
  }

  //编辑商机跟进详情
  editNicheFollow(niche,data){
    this.modal.static(NicheEditComponent, {
      pageType: 'edit',
      record:data
    }, 1080)
    .subscribe((result) => {
      if(result){
        this.getProjectNiches();
        //this.getNicheFollowDataList(niche,true);

        // //更新商机
        // let resultSel = this.prjNicheFollowAllList[result.iucceProjectNicheId];
        // if(!resultSel || resultSel.length <= 0){
        //   resultSel = [];
        //   resultSel.push(result);
        // }else{
        //   for(let i = 0; i < resultSel.length;i++){
        //     if(resultSel[i].id == result.id){
        //       resultSel[i] = result;
        //       break;
        //     }
        //   }
        // }
      }
    });
  }

  //删除商机跟进详情
  deleteNicheFollow(niche,data){
    this.modalService.confirm({
      nzTitle: '确定要删除吗?',
      nzContent: '',
      nzOkText: '确定',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.projectNicheService.deleteNicheFollowById(data.id,niche.id,data.projectId).subscribe(res=>{
          if(res.status == 200){
            this.message.info("删除成功");
            this.getNicheFollowDataList(niche,true);
          }else{
            this.message.info("删除失败");
          }
        },(errorRes)=>{
          this.message.info("删除失败");
        });
      },
      nzCancelText: '取消',
      // nzOnCancel: () => console.log('Cancel')
    });
  }

  //查看商机跟进详情
  viewNicheFollow(data){
    this.modal.static(NicheEditComponent, {
      pageType: 'view',
      record:data
    }, 1080).subscribe();
  }

  getNicheFollowDataList(data,isReset?: boolean){
    const copyParams = {};
    
    const q = this.queryParams;
    this.dtNicheFollowLoading = true;
    if (isReset === true) {
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          debugger;
          if(key==="orderDateBegin"){
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 00:00:00';
          }else if(key==="orderDateEnd"){
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 23:59:59';
          }
          else if(typeof q[key] === 'object' && q[key]){
            copyParams[key] = q[key].join(",");
          }else{
            copyParams[key] = q[key];
          }
        }
      });
    }
    this.queryParams['iucceProjectId'] = this.projectId;
    this.queryParams['iucceProjectNicheId'] = data.id;
    copyParams['iucceProjectId'] = this.projectId;
    copyParams['iucceProjectNicheId'] = data.id;

    copyParams['page'] = 0;
    copyParams['size'] = 10000;
    //copyParams['sort'] = ['year,desc','month,desc'];

    this.projectNicheService.getAllIucceProjectNicheFollowDatas(copyParams)
      .subscribe((res: any) => {
        
        this.dtNicheFollowLoading = false;
        const nicheFollowAllList = res.body;
        this.prjNicheFollowAllList[data.id] = nicheFollowAllList;
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getProjectNiches();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getProjectNiches();
  }


  // submitForm() {
  //   for (const i in this.validateForm.controls) {
  //     this.validateForm.controls[i].markAsDirty();
  //     this.validateForm.controls[i].updateValueAndValidity();
  //   }
  //   console.log(this.validateForm.value);
  // }

  return(isRefresh: boolean) {
   // this.reuseTabService.replace('/business/iucce/projectNiche');
    if(!isRefresh){
      this.router.navigate(['/business/iucce/projectNiche']);
    }else{
      this.router.navigate(['/business/iucce/projectNiche'], { queryParams : { time : Date.now() } });
    }
  }

  
  showModal(nicheData): void {
    this.closeNicheData = nicheData;
    // this.validateCloseNicheForm.get('closedReason').setValue(nicheData.closedReason);
    const nicheStatus = this.validateCloseNicheForm.get('nicheStatus').value;
    this.validateCloseNicheForm.patchValue(nicheData);
    this.validateCloseNicheForm.get('nicheStatus').setValue(nicheStatus);
    
    this.closeNicheModalVisible = true;
  }

  //保存商机关闭原因
  handleNicheClose(){
    if(this.closeNicheData == null){
      this.message.warning("请先选择要关闭的项目商机数据");
      return;
    }
    for (const i in this.validateCloseNicheForm.controls) {
      const control = this.validateCloseNicheForm.controls[i];
      control.markAsDirty();
      control.updateValueAndValidity();
    }
     debugger;
    if(this.validateCloseNicheForm.invalid){
      this.message.error("请检查信息是否完整!");
      return;
    }


    this.closeNicheLoading = true;

    let recordInfo = Object.assign({},this.closeNicheData);
    recordInfo = Object.assign(recordInfo, this.validateCloseNicheForm.value);
    
    this.projectNicheService.update(recordInfo).subscribe(res=>{
      setTimeout(() => {
        this.message.success('关闭商机成功');
        this.closeNicheLoading = false;
        
        //更新列表商机数据
        this.getProjectNiches();
        this.handleNicheCancel();
    }, 1000);
    });
  }

  handleNicheCancel(): void {
     this.closeNicheData = null;
    this.validateCloseNicheForm.reset();
    this.closeNicheModalVisible = false;
  }

}
