import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ProjectReportAuditService } from "./projectReportAudit.service";
import { ProjectReportService } from "./../projectReport/projectReport.service";
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { APP_PERMISSIONS_IUCCE } from '@shared/app.permissions';
import { zip } from 'rxjs';
import { ActivatedRoute,Router } from '@angular/router';
import { WorkflowService } from "@shared/components/workflow/workflow.service";
import { UserService } from "app/routes/admin/system/users/users.service";
import { workflowModules } from "@shared/app.constants";
import { COMMON_DATA } from "@shared/app.commonData";

@Component({
  selector: 'app-iucce-project-report-audit',
  templateUrl: './projectReportAudit.component.html',
  styles: [`
  :host ::ng-deep .ant-tabs-bar {
    margin:0;
  }
  :host ::ng-deep .ant-tabs-nav {
      padding-left:20px !important;
    }
  .industy-outer {
    min-height: calc(100vh - 138px - 45px);
  }
  `]
})
export class ProjectReportAuditComponent implements OnInit {
  dtLoading = false;
  dtLoading_done = false;

  queryParams = {
    regionId:[],
    provinceId:[],
    year:'',
    schoolName:'',
    projectName:'',
    batchId:'',
    projectTypeId:'',
  };
  queryParams_done = {
    regionId:[],
    provinceId:[],
    year:'',
    schoolName:'',
    projectName:'',
    batchId:'',
    projectTypeId:'',
    establishStatus : ''
  };
  isCollapse = true;
  isCollapse_done = true;
  regionList: any;//大区
  provinceList: any;//省份（根据大区选择进行筛选）
  provinceList_done:any;//省份（根据大区选择进行筛选）--已审批
  provinceResultList: any;//省份（所有数据）
  yearList: any;//年份
  batchList:any;//批次
  batchList_done:any;//批次-已审批
  batchResultList: any;//批次全部数据
  prjTypeList:any;//项目类别
  prjTypeList_done:any;//项目类别-已审批
  prjTypeResultList: any;//项目类别全部数据

  allChecked = false;
  indeterminate = false;
  dataList = [];
  dataList_done = [];
  total = '0';
  total_done = '0';
  page = 0;
  page_done = 0;
  size = 10;
  size_done = 10;
  pageSizeOptions = [10, 20, 50, 100];
  prjEstablishStatusList = [];//审核状态
  APP_PERMISSIONS = APP_PERMISSIONS_IUCCE;
  workflowModules = workflowModules;
  COMMON_DATA = COMMON_DATA;

  constructor(
    private routerActived: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    private dictService: DictService,
    private dictDetailService: DictDetailService,
    private workflowService: WorkflowService,
    private projectReportAuditService: ProjectReportAuditService,
    private userService: UserService,
    private projectReportService : ProjectReportService) { 
      this.routerActived.queryParams.subscribe((params) => {
        if(params.time != null && params.time != undefined && params.time != ''){
          this.getDataList(0);
          this.getDataList(1);
        }
      });

      this.prjEstablishStatusList = this.COMMON_DATA.iucceProjectEstablishStatus;
    }

  ngOnInit(): void {
    this.getDataList();
    this.initSearchValue();
  }
  /**
   * 初始化搜索条件默认值
   */
  initSearchValue(){
    this.getYearListDict();
    this.getRegionProvinceInfo();
  }

  /**
   * 获取大区、省份数据
   */
  getRegionProvinceInfo(){
    // 加载区域与省份数据
    const dataParams = {
      'size' : '10000',
      'sort': 'orderNum,asc'
    };
    zip(
      this.userService.queryRegionList(dataParams),
      this.userService.queryProvinceList(dataParams)
    ).subscribe(([regionRes,provinceRes]) => {
      let regionDataList = regionRes.body;
      let provinceDataList = provinceRes.body;
      this.regionList = regionDataList;
      this.provinceResultList = provinceDataList;
    })
  }

  /**
   * 大区改变事件
   */
  fnChangeRegion(dataType = 0,itemSelect){
    if(dataType == 0){
      this.provinceList = [];
    }else if(dataType == 1){
      this.provinceList_done = [];
    }
    

    if(this.provinceResultList && itemSelect){
      let items = typeof itemSelect == 'object' ? itemSelect : [itemSelect];
      items.forEach(item => {
        let regionQueryList = this.regionList.filter(w=>w.id == item);
        let region = regionQueryList[0];
        let queryProvinces = this.provinceResultList.filter(w=>w.fkregionId == region.id);
        queryProvinces.forEach(element => {
          if(dataType == 0){
            this.provinceList.push(element);
          }else if(dataType == 1){
            this.provinceList_done.push(element);
          }
        });
      });
    }
  }
  
  fnChangeYear(dataType,item){
    if(dataType == 0){
      this.batchList = [];
      this.prjTypeList = [];
      this.queryParams['batch.in'] = "";
      this.queryParams['prjType.in'] = "";
      
      if(this.batchResultList){
        this.batchList = this.batchResultList.filter(w=>w.parentId && w.itemValue2 === item);
      }
      if(this.prjTypeResultList){
        this.prjTypeList = this.prjTypeResultList.filter(w=>w.parentId && w.itemValue2 === item);
      }
    }else if(dataType == 1){
      this.batchList_done = [];
      this.prjTypeList_done = [];
      this.queryParams_done['batch.in'] = "";
      this.queryParams_done['prjType.in'] = "";
      
      if(this.batchResultList){
        this.batchList_done = this.batchResultList.filter(w=>w.parentId && w.itemValue2 === item);
      }
      if(this.prjTypeResultList){
        this.prjTypeList_done = this.prjTypeResultList.filter(w=>w.parentId && w.itemValue2 === item);
      }
    }

  }

  /**
   * 获取年份
   */
  getYearListDict(){
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
    this.dictService.query({ 'dictNo.equals':'batchList' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals':res.body[0].id ,sort:'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.batchResultList = res2.body;
          }
        });
      }
    });
    this.dictService.query({ 'dictNo.equals':'prjTypes' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals':res.body[0].id ,sort:'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.prjTypeResultList = res2.body;
          }
        });
      }
    });
  }

  getDataList(dataType = 0,isReset?: boolean){
    this.dataList = [];

    const copyParams = {};
    let q = this.queryParams;
    if(dataType == 0){
      this.dtLoading = true;
    }else if(dataType == 1){
      q = this.queryParams_done;
      this.dtLoading_done = true;
    }
    
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      this.batchList = [];
      this.prjTypeList = [];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          if(typeof q[key] === 'object' && q[key]){
            copyParams[key] = q[key].join(",");
          }else{
            copyParams[key] = q[key];
          }
        }
      });
    }
    copyParams['isFinish.equals'] = "0";
    if(dataType == 1){
      copyParams['isFinish.equals'] = "1";//已审核
    }

    copyParams['isVisible.equals'] = "1";
    copyParams['businessCode'] = "10";//项目立项审批
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    
    this.projectReportAuditService.getNotictingProjectByPage(copyParams).subscribe((res)=>{
      if(dataType == 0){
        this.dataList = res.body;
        this.dtLoading = false;
        this.total = res.headers.get('X-Total-Count');
      }else if(dataType == 1){
        this.dataList_done = res.body;
        this.dtLoading_done = false;
        this.total_done = res.headers.get('X-Total-Count');
      }
    })
  }


  fnSelectTabs(tabs){
    if(tabs.index == 1){
      this.getDataList(1);
    }else{
      this.getDataList(0);
    }
  }

  
  toggleCollapse(dataType) {
    if(dataType == 0){
      this.isCollapse = !this.isCollapse;
    }else{
      this.isCollapse_done = !this.isCollapse_done;
    }
    
  }

  /**
  * 页码数量变动事件
  * @param
  */
   paginationChange(dataType = 0,event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    if(dataType == 0){
      this.page = event.pi - 1;
      this.size = event.ps;
      this.getDataList();
    }else if(dataType == 1){
      this.page_done = event.pi - 1;
      this.size_done = event.ps;
      this.getDataList(dataType);
    }
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(dataType = 0,event) {
    if(dataType == 0){
      this.page = event - 1;
      this.getDataList();
    }else if(dataType == 1){
      this.page_done = event - 1;
      this.getDataList(dataType);
    }
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(dataType = 0,event) {
    if(dataType == 0){
      this.size = event;
      this.getDataList();
    }else if(dataType == 1){
      this.size_done = event;
      this.getDataList(dataType);
    }
  }

  audit(item: any) {
    this.router.navigateByUrl(item.thingUrl);
    // this.router.navigate(['/business/iucce/projectReportAudit/approve'],{queryParams: {id:item.id}});
    //this.router.navigateByUrl(`/business/iucce/projectReportAudit/approve/${item.id}`);
  }
  auditFlow(item:any){
    this.router.navigateByUrl(item.thingUrl);
    //this.router.navigate(['/business/iucce/projectReportAudit/approveFlow'],{queryParams: {id:item.id}});
  }
  view(item: any) {
    //this.router.navigateByUrl(`/business/iucce/projectReportAudit/approve/${item.id}/view`);
    this.router.navigateByUrl(item.thingUrl);
  }

}
