import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { TopicManager } from './TopicManager.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class EduTopicManagerService extends ThsBaseService<TopicManager> {
  catalogUrl = '';
  courseUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-topics`);
    this.catalogUrl = httpAddressService.EduServe + '/edu-course-catalogs';
    this.courseUrl = httpAddressService.EduServe + '/edu-courses';
  }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}

queryTopic(copyParams?: any): Observable<HttpResponse<any>> {
  const url = `${this.resourceUrl}/getTopicByPage`;
  return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
}

queryCourse(courseName: string): Observable<HttpResponse<any[]>> {
  const url = this.courseUrl + '/getCourseByCourseName?courseName=' + courseName;
  return this.http.get<any[]>(url, { observe: 'response' })
    .pipe(map((res: HttpResponse<any[]>) => (res)));
}
queryCatalog(courseId: string): Observable<HttpResponse<any[]>> {
  const url = this.catalogUrl + '/getCatalogByCourseId?courseId=' + courseId;
  return this.http.get<any[]>(url, { observe: 'response' })
    .pipe(map((res: HttpResponse<any[]>) => (res)));
}

queryTopicType(dictNo: string): Observable<HttpResponse<any[]>> {
  const url =  `${this.resourceUrl}/getDictDetailByDictNo?dictNo=` + dictNo;
  return this.http.get<any[]>(url, { observe: 'response' })
    .pipe(map((res: HttpResponse<any[]>) => (res)));
 }

 saveTopicInfo(param: any) {
  const url = `${this.resourceUrl}/saveTopicInfo`;
  // const copy = this.convertDateFromClient(param);
  return this.http.post<any>(url, param, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
}

}

