import { Component, ElementRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn, XlsxService} from '@delon/abc';
import { NzMessageService, NzModalService, UploadFile, NzModalRef } from 'ng-zorro-antd';
import { CertExamineResultManService } from '../certExamineResultMan.service';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { HttpClient, HttpParams, HttpResponse, HttpRequest } from '@angular/common/http';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-certResultImport',
  templateUrl: './certResultImport.component.html',
})
export class CertResultImportComponent implements OnInit {
  public APP_PERMISSIONS = APP_PERMISSIONS;
  excelFile: UploadFile[] = [];
  examineResult = [];

  uploading = false;
  fileList: UploadFile[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;



  ngOnInit() {
  }

  constructor(
    private xlsx: XlsxService,
    private certExamineResultManService: CertExamineResultManService,
    private http: HttpClient,
    public msgSrv: NzMessageService,
    private elementRef: ElementRef,
    private nzModalRef: NzModalRef ,
    ) {}
  // tslint:disable-next-line:member-ordering
  data: any;

  change(e: Event) {
    const node = e.target as HTMLInputElement;
    // tslint:disable-next-line:no-non-null-assertion
     this.xlsx.import(node.files![0]).then(res => {
      this.data = res;
      this.examineResult = ( this.data || JSON);
      console.log(e);
     });
    node.value = '';
  }
  submitResult() {
    const r = JSON.stringify(this.examineResult);
   const  fileList: any[] = [];
   const formData = new FormData();
   formData.append('files', r);
   this.certExamineResultManService.saveImportExaminResult(formData)
           .subscribe(res => {
             const result = JSON.parse(JSON.stringify(res)).body;
             console.log('res' + result['success']);
              if (result['success']) {
               this.msgSrv.success(result['mes']);
               this.nzModalRef.destroy(true);
             } else {
               const msg = result['mes'];
               this.msgSrv.error(msg);
             }
           });
 }




 // 上传之前
 beforeUpload = (file: UploadFile): boolean => {
  this.fileList = this.fileList.concat(file);
  return false;
}

// 导入题目逻辑
handleUpload(): void {
    const formData = new FormData();
    console.log(formData);
    this.fileList.forEach((file: any) => {
      formData.append('file', file);
    });
    this.uploading = true;
    this.certExamineResultManService.saveImportExaminResult(formData)
         .subscribe(res => {
           const result = JSON.parse(JSON.stringify(res)).body;
           console.log('res' + result['success']);
            if (result['success']) {
             this.msgSrv.success(result['mes']);
           } else {
             const msg = result['mes'];
             this.msgSrv.error(msg);
           }
         });
}



}
