import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { StudyHistoryService } from "./studyHistory.service";
import { StudyHistory } from "./studyHistory.model";
import { APP_PERMISSIONS } from "@shared/app.permissions";

@Component({
  selector: 'app-edu-history',
  templateUrl: './studyHistory.component.html',
})
export class EduStudyHistoryComponent implements OnInit{
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: StudyHistory[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'courseName': '',
    'catalogName': '',
    'studentName': '',
    // sort固定
    // sort: ['orderNum,asc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: "id", type: 'checkbox' },
    { title: '姓名', index: 'studentName' , width: '12%'},
      // { title: '截图', index: 'imgurl' },
    { title: '课程', index: 'courseName', width: '15%' },
    { title: '章节', index: 'catalogName', width: '15%'},
    { title: '学习时间', index: 'studyTime', width: '10%'},
    { title: '学习时长', index: 'studyHours', width: '10%'},
    { title: '备注', index: 'remark', width: '17%'},
       {
      title: '操作', className: 'text-center',
      buttons: [
        // { acl: true, text: '查看', type: 'modal', component: AdminUsersEditComponent, paramName: 'record', click: () => this.getDataList() },
        //{ text: '编辑', type: 'modal', component: EduTeacherEditComponent, paramName: 'record', click: () => this.getDataList(), },
        { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item) } }
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public studyHistoryService: StudyHistoryService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    }else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    };
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.studyHistoryService.queryStudyHistory(copyParams)
      .subscribe((res: any) => {
        // if (res.body && res.body !== null && res.body.length > 0) {
        //   for (let i = 0; i < res.body.length ; i++) {
        //     // this.resourceService.findTypeFromDict(res.body[i].type)
        //     // .subscribe((res1) => {
        //     //   res.body[i].type =  res1.body.itemText;
        //     //   if (i === res.body.length - 1) {
        //     //     this.list = res.body;
        //     //   }
        //     // });
        //   }
        // }
      //  this.getTypeFromDict();
      this.list = res.body.data;
      this.total = res.headers.get('X-Total-Count');
       // this.list = res.body;

      });
  }

  /**
  * 页码数量变动事件
  * @param 
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param 
  */
  filterChange(event) {
    let i = 0;
    let _value = [];
    let _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey +'.'+ _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param 
  */
  sortChange(event) {
    let array = this.queryParams['sort'];
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value == 'descend') {
      value = 'desc';
    } else if (event.value == 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + "," + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + "," + value);
    }
    //排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }




  

  
  // /**
  // * 查看页面
  // * @returns {NzMessageDataFilled}
  // */
  // view() {
  //   if (this.selectedRows.length > 1) return this.msg.warning("只能选择一条数据编辑！");
  //   if (this.selectedRows.length < 1) return this.msg.warning("请先选择一条数据再编辑！");
  //   this.modal
  //     .static(AdminUsersViewComponent, { record: this.selectedRows[0] })
  //     .subscribe(() => this.st.reload());
  // }
  /**
  * 删除
  */
  remove(res: any) {
    if(res.userName == 'admin'){
      this.msg.info("管理员账号不能删除");
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.studyHistoryService.delete(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning("请先选择您要删除的信息！");
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if(ele.userName == "admin"){
        this.msg.info("管理员账号不能删除");
        flag = true;
      }
    });
    if(flag){
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.studyHistoryService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

 


}
