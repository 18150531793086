import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CertRegedist} from './certRegedist.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class CertRegedistManageService extends ThsBaseService<CertRegedist> {
  channelUrl = '';
  examineTimesUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-cert-regedists`);
      this.channelUrl = httpAddressService.EduServe + '/edu-certification-channels';
      this.examineTimesUrl = httpAddressService.EduServe + '/edu-cert-examine-times';
  }
  findAllChannel(): Observable<HttpResponse<any[]>>  {
  const url = this.channelUrl + '/findAllChannel';
  return this.http.get<any>(`${url}`, { observe: 'response' });
}

findByChannelIdAndIsOk(channelId: string, examTime: string, subject: string, authProduct: string, authGrade: string):
 Observable<HttpResponse<any[]>>  {
  const url = this.examineTimesUrl + '/findByChannelIdAndIsOk?channelId= ' + channelId +
  '&examTime=' + examTime + '&examineSubject=' + subject + '&authProduct=' + authProduct + '&authGrade=' + authGrade;
  return this.http.get<any>(`${url}`, { observe: 'response' });
}


}

