import { Injectable } from '@angular/core';
import { HttpAddressService } from '@shared/session/http-address.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HomeworkType } from './homeworktype.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeworktypeService extends ThsBaseService< HomeworkType> {

  constructor(
    http: HttpClient,
    httpAddressService: HttpAddressService,
  ) {
    super(http, httpAddressService.EduServe + `/edu-homework-types`);
  }
  queryHomeworktypeCourseCatalog(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllHomeworktypeCourseCatalog` ;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
  findAllByDictNo(dictNo: string): Observable<HttpResponse<any[]>>  {
    const dictUrl = '/thsadmin/api/sys-dicts';
    const url = dictUrl + '/findAllByDictNo';
    return this.http.get<any>(`${url}/${dictNo}`, { observe: 'response' });
  }
  
}
