import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of, zip } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimTeamStudents } from '../bim-team-student.model';
import { NzMessageService, NzModalRef, NzModalService, UploadFile } from 'ng-zorro-antd';
import { BimTeamStudentsService } from '../bim-team-student.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

@Component({
  selector: 'app-bim-team-student-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimTeamStudentEditComponent implements OnInit {

  public sexList = {'0': '男',  '1' : '女'};
  record: any = {};
  teamId:string;
   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';


  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  schoolName:string;
  teamName:string;
  Option1:string;
  bimEventId:String;

  serialNumber = null;

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    public modalService: NzModalService,
    private bimTeamStudentsService: BimTeamStudentsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      tel: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
      faculty:[null, []],
      profession:[null, []],
      grade:[null, []],
      email:[null, []],
      sex:['0', []],
      serialNumber:[null, []],
      type:[null, []],
      remark:['false', [Validators.required]],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
     this.accountName = tokenService.get().account_name;
 
  }

  ngOnInit() {
    if (!this.isAdd) {
      this.loadData();
    } else {
      this.form.controls['serialNumber'].setValue(this.serialNumber);
    }
  }




  loadData(): void {
       this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查团队成员信息是否完整');
      return;
    }

    if (!isNullOrUndefined(param['grade'])) {
      param['grade'] = moment(param['grade']).format('YYYY');
    }
    param["teamId"]  = this.teamId;
    
    zip(
      this.bimTeamStudentsService.getTeamStudentByTelAndEventId({
        'mobile': param['tel'].trim(),
        'eventId': this.bimEventId,
      }),
      this.bimTeamStudentsService.query({
        'teamId.equals': param['teamId'],
        'remark.equals': 'true'
      })
    ).subscribe(([res1, res2]) => {
      if (res1 && res1.body) {
        const data1 = res1.body;
        const data2 = res2.body;
        if (data1.length > 0) {
          if (this.isAdd === true) {
            this.msg.error('该联系电话已注册团队，请勿重复添加');
            return;
          } else {
            const team = data1[0];
            if (team.id === param['id']) {
              // 查询是否已有队长
              if (param['remark'] == 'true' && data2.length > 0) {
                const teamCaptain = data2[0];
                this.checkTeamCaptain(param, teamCaptain);
              } else {
                this.saveTeamStudent(param);
              }
            } else {
              this.msg.error('该联系电话已注册团队，请勿重复添加');
              return;
            }
          }
        } else {
          // 查询是否已有队长
          if (param['remark'] == 'true' && data2.length > 0) {
            const teamCaptain = data2[0];
            this.checkTeamCaptain(param, teamCaptain);
          } else {
            this.saveTeamStudent(param);
          }
        }
      }
    })
  }

  checkTeamCaptain(param, teamCaptain) {
    if (teamCaptain.id === param['id']) {
      this.saveTeamStudent(param);
    } else {
      this.modalService.confirm({
        nzTitle: '该团队已存在队长，是否将队长更新为本队员?',
        nzContent: '',
        nzOkText: '是',
        nzOkType: 'danger',
        nzOnOk: () => {
          teamCaptain['remark'] = 'false';
          this.bimTeamStudentsService.update(teamCaptain).subscribe();
          this.saveTeamStudent(param);
        },
        nzCancelText: '否',
        nzOnCancel: () => console.log('Cancel')
      });
    }
  }

  saveTeamStudent(param) {
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {
      this.modifyDataInfo(param);
    }
  }


  modifyDataInfo(param: any) {
    this.bimTeamStudentsService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('修改成功');
        this.nzModalRef.destroy();
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimTeamStudentsService.create(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('保存成功');
        this.nzModalRef.destroy();
      } else {
        this.msg.error('保存失败');
      }
    });
  }


  Cancel(){
    this.nzModalRef.destroy();
  }
}
