import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';
import { BimInfoProcessService } from '../bim-info-process.service';

@Component({
  selector: 'app-bim-info-process-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoProcessEditComponent implements OnInit, OnDestroy {

  record: any = {};
  bimInfoId: string;
   // 是否增加
  isAdd: boolean;

  form: FormGroup;

  entityId: string;
  entityName: String;
  accountName: String;


  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimInfoProcessService: BimInfoProcessService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [this.bimInfoId, []],
      name: [null, [Validators.required]],
      isAble: [null, [Validators.required]],
      orderNum: [null, [Validators.required]],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.record.isAble = this.record.isAble + '';
    this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查BIM大赛赛项信息是否完整');
      return;
    }
    if (!isNullOrUndefined(this.bimInfoId)) {
      param['bimInfoId']  = this.bimInfoId;
    }
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    this.bimInfoProcessService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('修改成功');
        this.close(true);
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimInfoProcessService.create(param).subscribe(res => {
      this.record = res.body;
      if (this.record) {
        this.msg.info('保存成功');
        this.close(true);
      } else {
        this.msg.error('保存失败');
      }
    });
  }

   // 关闭事件
   close(type?) {
    this.nzModalRef.destroy(type);
   }

   ngOnDestroy(): void {
   }
}
