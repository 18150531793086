import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimInfoProcess } from './bim-info-process.model';
@Injectable({ providedIn: 'root' })
export class BimInfoProcessService extends ThsBaseService<BimInfoProcess> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-info-processes`);
    }


}

