export const workflowModules = {
    'ZJWDXM':{
        moduleId: `aa2f9872-97a5-4416-b84c-74cd2e1c0f7c`,
        busiUrl:`/business/zj/project/projectEdit`
    },
    'GCWDXM':{
        moduleId: `0e9fbc35-6b65-4fb3-8688-cae7f812455f`,
        busiUrl:`/business/gc/project/projectEdit`
    },
    'IucceProjectReport':{
        moduleId: `3b00b6f6-ab5e-4831-a6a6-dea7a16efa58`,
        businessCode:'10',
        businessName:'项目立项审批',
        busiUrl:`/business/iucce/projectReportAudit/approveFlow`
    },
    'IucceProjectClosing':{
        moduleId: `f4aaa249-6a5a-4cbb-8c72-446fd22a86de`,
        businessCode:'20',
        businessName:'项目结项审批',
        busiUrl:`/business/iucce/projectClosingAudit/approveFlow`
    }
};
