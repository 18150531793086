import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { BimPersonService } from 'app/routes/business/bim/bim-info/bim-person/bim-person.service';
import { ProvinceService } from 'app/routes/business/bim/provinces/provinces.service';
import { RegionInfoService } from 'app/routes/business/bim/region-info/region-service.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-bim-cert-pass',
  templateUrl: './bim-cert-pass.component.html',
  styleUrls: []
})
export class BimCertPassComponent implements OnInit {

  /**
   * contentType
  */
  @Input() contentType = '';
  selectedRows: SimpleTableData[] = [];

  public bimInfoId = null;
  public bimInfoList = [];

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public queryParams = {
    // 查询搜索框的字段要预置
    'regionId': '-1',
    'province': '-1'
  };

  columns: SimpleTableColumn[] = [
    { title: '大区', index: 'regionName', width: '30%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '省份', index: 'provinceName', width: '30%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '人数', index: 'num', width: '30%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '操作', width: '10%',
      buttons: [
        { text: '详情', click: (item: any) => { this.detail(item); } },
      ],
    }
  ];

  public locationList = [];
  public provinceList = [];

  constructor(
    private router: Router,
    private routeInfo: ActivatedRoute,
    private bimInfoService: BimInfoService,
    private provinceService: ProvinceService,
    private bimPersonService: BimPersonService,
    private regionInfoService: RegionInfoService,
  ) { }

  ngOnInit(): void {
    this.bimInfoId = this.routeInfo.snapshot.queryParams['bimInfoId'];
    this.getAllRegion();
    this.getProvinces(-1);
    if (!isNullOrUndefined(this.bimInfoId)) {
      this.getDataList();
    }
  }

  /**
   * 获取所有大区
   */
  getAllRegion() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'orderNum',
    };
    this.regionInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.locationList = [{ id: '-1', regionName: '全部' }];
        this.locationList.push(...res.body);
      }
    });
  }

  /**
   * 根据大区获取省份
   */
  getProvinces(value) {
    const params = {
      'fkregionId.equals': value,
      page: 0,
      size: 1000,
      sort: 'orderNum'
    };
    if (value === '-1') {
      delete params['fkregionId.equals'];
    }
    this.provinceService.query(params).subscribe(res => {
      if (res && res.body) {
        this.provinceList = [{ id: '-1', provinceName: '全部' }];
        this.provinceList.push(...res.body);
      }
    });
  }

  initBimInfoList() {
    this.isloadding = true;
    this.bimInfoService.query({
      'size': '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
      this.bimInfoList = res.body;
      this.getDataList();
    }, () => this.isloadding = false);
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimPersonService.getBimCertPassData(copyParams).subscribe((res: any) => {
      this.isloadding = false;
      this.list = res.body;
      this.selectedRows = [];
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        q[key] = null;
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null && q[key] !== '-1') {
        copyParams[key] = q[key];
      }
    });
    copyParams['bimInfoId'] = this.bimInfoId;
    return copyParams;
  }

  detail(res: any) {
    this.router.navigate(['/dashboard/bimCertPass/detail'],
          {queryParams: { bimInfoId: this.bimInfoId, province: res.provinceId, type: 'bimCertPass' }});
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];
}
