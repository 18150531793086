import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReuseTabService } from '@delon/abc';
import { environment } from '@env/environment';
import { ThsadminFileService } from '@shared/components/f-upload/thsadmin-file.service';
import { NzMessageService } from 'ng-zorro-antd';
import * as moment from 'moment';
import { GuidUtil } from '@shared/utils/guid.util';
import { IucceProjectFundPayService } from '../projectFundPay.service';
import { IucceProjectSoftfundPayService } from './projectSoftFundPay.service';
import { zip } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ProjectReportService } from '../../projectReport/projectReport.service';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';

@Component({
  selector: 'app-project-fund-pay-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less', './edit.component.less']
})
export class ProjectFundPayEditComponent implements OnInit {

  validateForm: FormGroup;

  public projectInfo = null;
  public fileList = [];
  public fundPayList = [];

  public softFundList = [];
  dateFormat = 'yyyy/MM/dd';

  @Input() public projectId = null;
  @Input() public prjType = null;
  @Input() public isView = false;
  public isLoading = false;

  public delFundPay = [];
  public delSoftfundPay = [];

  constructor(
    private fb: FormBuilder,
    private modalHelper: ModalHelper,
    private message: NzMessageService,
    private routeInfo: ActivatedRoute,
    private reuseTabService: ReuseTabService,
    private thsadminFileService: ThsadminFileService,
    private projectReportService: ProjectReportService,
    private iucceProjectFundPayService: IucceProjectFundPayService,
    private iucceProjectSoftfundPayService: IucceProjectSoftfundPayService,
  ) {
    this.validateForm = this.fb.group({
      payment: ['1', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.isView) {
      this.validateForm.disable();
    }
    this.projectId = this.routeInfo.snapshot.queryParams['id'];
    this.prjType = this.routeInfo.snapshot.queryParams['prjType'];
    this.getFileList();
    this.initData();

  }

  initData() {
    this.iucceProjectFundPayService.query({
      'iucceProjectId.equals': this.projectId,
      sort: ['payDate,asc']
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
        for (let i = 0; i < data.length; i++) {
          this.fundPayList = [
            ...this.fundPayList,
            {
              id: data[i].id,
              iucceProjectId: this.projectId,
              payDate: data[i].payDate,
              payAmount: data[i].payAmount,
              remark: data[i].remark,
              isAdd: false
            }
          ];
        }
        if (this.fundPayList.length === 0) {
          this.addFundPay();
        }
      }
    });
    this.iucceProjectSoftfundPayService.query({
      'iucceProjectId.equals': this.projectId,
      sort: ['orderNum,desc']
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
        for (let i = 0; i < data.length; i++) {
          let authDate = null;
          if (!isNullOrUndefined(data[i].authStartDate) && !isNullOrUndefined(data[i].authEndDate)) {
            authDate = [data[i].authStartDate, data[i].authEndDate];
          }
          this.softFundList = [
            ...this.softFundList,
            {
              id: data[i].id,
              iucceProjectId: this.projectId,
              softwareAmount: data[i].softwareAmount,
              softwareOrderNum: data[i].softwareOrderNum,
              authDate: authDate,
              softwareDetails: data[i].softwareDetails,
              isAdd: false
            }
          ];
        }
        if (this.softFundList.length === 0) {
          this.addSoftFund();
        }
      }
    });
  }

  addFundPay() {
    this.fundPayList = [
      ...this.fundPayList,
      {
        id: new GuidUtil().toString(),
        iucceProjectId: this.projectId,
        payDate: null,
        payAmount: null,
        remark: null,
        isAdd: true
      }
    ];
  }

  removeFundPay(item) {
    if (this.fundPayList.length === 1) {
      this.message.warning('至少要有一条资金支付情况');
      return;
    }
    this.fundPayList = this.fundPayList.filter(e => e.id !== item.id);
    if (!item.isAdd) {
      this.iucceProjectFundPayService.delete(item.id).subscribe();
    }
  }

  addSoftFund() {
    this.softFundList = [
      ...this.softFundList,
      {
        id: new GuidUtil().toString(),
        iucceProjectId: this.projectId,
        softwareAmount: null,
        softwareOrderNum: null,
        authDate: null,
        softwareDetails: null,
        isAdd: true
      }
    ];
  }

  removeSoftFund(item) {
    if (this.softFundList.length === 1) {
      this.message.warning('至少要有一条软件投入明细');
      return;
    }
    this.softFundList = this.softFundList.filter(e => e.id !== item.id);
    if (!item.isAdd) {
      this.iucceProjectSoftfundPayService.delete(item.id).subscribe();
    }
  }

  returnProjectInfo(event) {
    this.projectInfo = event;

    const isHaveSoftwarePay = this.projectInfo.isHaveSoftwarePay;
    this.validateForm.controls['payment'].setValue(isHaveSoftwarePay === null ? '1' : isHaveSoftwarePay);
  }

  submitForm() {
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    const payment = this.validateForm.value.payment;
    const saveSubArray = [];
    let funPayAmount = null;
    if (this.prjType === null || this.prjType !== 'soft') {
      funPayAmount = 0;
      // tslint:disable-next-line:forin
      for (let i = 0; i < this.fundPayList.length; i++) {
        const saveData = this.fundPayList[i];
        if (isNullOrUndefined(saveData.payDate) || isNullOrUndefined(saveData.payAmount) || saveData.payAmount === '') {
          this.message.error('资金支付时间-请检查表单,填写带 * 的信息');
          return;
        }
        funPayAmount = funPayAmount + saveData.payAmount;
        if (saveData.isAdd) {
          saveSubArray.push(this.iucceProjectFundPayService.create(saveData));
        } else {
          saveSubArray.push(this.iucceProjectFundPayService.update(saveData));
        }
      }
    }
    let softwareAmount = null;
    if (payment === '1') {
      softwareAmount = 0;
      // tslint:disable-next-line:forin
      for (let i = 0; i < this.softFundList.length; i++) {
        const saveData = this.softFundList[i];
        if (isNullOrUndefined(saveData.softwareOrderNum) || isNullOrUndefined(saveData.softwareAmount)
           || isNullOrUndefined(saveData.authDate)) {
          this.message.error('软件投入明细-请检查表单,填写带 * 的信息');
          return;
        }
        const authDate = saveData.authDate;
        saveData['authStartDate'] = authDate[0];
        saveData['authEndDate'] = authDate[1];
        saveData['orderNum'] = i + 1;
        softwareAmount = softwareAmount + saveData.softwareAmount;
        if (saveData.isAdd) {
          saveSubArray.push(this.iucceProjectSoftfundPayService.create(saveData));
        } else {
          saveSubArray.push(this.iucceProjectSoftfundPayService.update(saveData));
        }
      }
    } else {
      for (let i = 0; i < this.softFundList.length; i++) {
        const item = this.softFundList[i];
        if (!item.isAdd) {
          this.iucceProjectSoftfundPayService.delete(item.id).subscribe();
        }
      }
    }
    this.isLoading = true;
    this.projectInfo.isHaveSoftwarePay = payment;
    this.projectInfo.payAmount = funPayAmount;
    this.projectInfo.sortwareAmount = softwareAmount;
    if (saveSubArray.length > 0) {
      zip(...saveSubArray).subscribe(() => {
        this.updateProject();
      });
    } else {
      this.updateProject();
    }
  }

  updateProject() {
    this.projectReportService.update(this.projectInfo).subscribe(res => {
      this.isLoading = false;
      this.message.success('保存成功');
    });
  }

  return() {
    this.reuseTabService.replace('/business/iucce/projectFundPay');
  }

  /**
   * 获取文件列表
   */
  getFileList() {
    const queryParams = {
      'fileFk.equals' : this.projectId
    };
    this.thsadminFileService.queryFiles(queryParams).subscribe(res => {
      this.fileList = res.body;
      this.fileList.forEach(item => {
        item.uid = item.id;
        item.name = item.fileName;
        item.url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + item.id;

        if (item.extField1 === 'IucceProjectReport') {
          item.fileDesc = '项目申报书';
        } else if (item.extField1 === 'IucceProjectConstract') {
          item.fileDesc = '合同协议';
        } else if (item.extField1 === 'IucceProjectElse') {
          item.fileDesc = '其他附件';
        }
      });
    });
  }

  formatDate(time) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  downFile(file) {
    const a = document.createElement('a');
    const filename = file.fileName;
    a.href = file.url;
    a.download = filename;
    a.click();
  }

  uploadFile(item, uploadable) {
    this.modalHelper.static(FileUploadComponent, {
      display: 'list',
      fileFk: item.id,
      uploadable: uploadable
    }, 'md').subscribe();
  }

}
