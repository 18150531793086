import { Component, OnInit } from '@angular/core';
import {NzModalRef} from "ng-zorro-antd";
import {WorkManageService} from "../work-manage.service";
import { zip } from 'rxjs';

@Component({
  selector: 'app-show-team-users',
  templateUrl: './show-team-users.component.html',
  styles: []
})
export class ShowTeamUsersComponent implements OnInit {

  teamId = '';
  list = [];

  constructor(
    private nzModalRef: NzModalRef,
    private workManageService: WorkManageService,
  ) { }

  ngOnInit() {
    console.log(1);
    this.getList();
  }

  onClose() {
    this.nzModalRef.destroy();
  }

  getList() {
    const params = {
      'teamId.equals': this.teamId,
      page: 0,
      size: 10000,
      sort: 'serialNumber'
    };
    zip(
      this.workManageService.queryTeamTeacher(params),
      this.workManageService.queryTeamStudent(params)
    ).subscribe(([res1,res2]) => {
      this.list = [];
      if (res1 && res1.body) {
        res1.body.forEach(item => {
          const value = {
            id: item.id,
            name: item.name,
            type: '指导老师',
            sex: item.sex,
            tel: item.mobile,
            profession: item.profession,
            email: item.email
          };
          this.list.push(value);
        })
      }
      if (res2 && res2.body) {
        res2.body.forEach(item => {
          const value = {
            id: item.id,
            name: item.name,
            type: '队员',
            sex: item.sex,
            tel: item.tel,
            profession: item.profession,
            email: item.email
          };
          this.list.push(value);
        })
      }
    })
  }

}
