import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { CoursePay } from './course-pay.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class CoursePayService extends ThsBaseService<CoursePay> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-course-pays`);
  }


  queryNew (queryParams ?: any): Observable<HttpResponse<CoursePay[]>> {
    let url = this.resourceUrl + '/getAllEduCoursePaysNew';
    return this.http.get<CoursePay[]>(url, {params: queryParams ,  observe: 'response'})
    .pipe(map((res: HttpResponse<CoursePay[]>) => this.convertDateArrayFromServer(res)));
}



exportExcel(queryParams ?: any) {
  const url = `${this.resourceUrl}/exportExcel`;

  //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
  this.http.get(url ,  {
    params: queryParams ,
    headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
    observe: 'response',
    responseType: 'blob',
    withCredentials: true,
  }).subscribe(
    data => {
      let blob = new Blob([data.body]);
      let objectUrl = URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', objectUrl);
      a.setAttribute('download',  '课程订单表' + '.xlsx');
      a.click();
      URL.revokeObjectURL(objectUrl);
    },
    error => {
      this.handleError('下载失败,请稍后重试!');
      console.error('课程订单表下载失败 ->', error);
    },
  );

}


/**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('CoursePayService: ' + message);
  }

}