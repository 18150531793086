import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StudyHistory } from './studyHistory.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class StudyHistoryService extends ThsBaseService<StudyHistory> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-study-histories`);
  }

  queryStudyHistory(queryParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllStudyHistory` ;
    return this.http.get<HttpResponse<any>>(url, { params: queryParams, observe: 'response' });
  }

}

