import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption } from '@shared/utils/request-util';
import { IWfFlowinfo } from './wf-flowinfo.model';
import { HttpAddressService } from '@shared/session/http-address.service';
type EntityResponseType = HttpResponse<IWfFlowinfo>;
type EntityArrayResponseType = HttpResponse<IWfFlowinfo[]>;

@Injectable({ providedIn: 'root' })
export class WfFlowinfoService {
    
    resourceUrl:string;
    constructor(private http: HttpClient
        , private httpAddressService:HttpAddressService
        ) {
        this.resourceUrl = this.httpAddressService.workflowServe+'/wf-flowinfos';
    }

    create(wfFlowinfo: IWfFlowinfo): Observable<EntityResponseType> {
        return this.http.post<IWfFlowinfo>(this.resourceUrl, wfFlowinfo, { observe: 'response' });
    }

    update(wfFlowinfo: IWfFlowinfo): Observable<EntityResponseType> {
        return this.http.put<IWfFlowinfo>(this.resourceUrl, wfFlowinfo, { observe: 'response' });
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http.get<IWfFlowinfo>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<IWfFlowinfo[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    isExists(wfFlowinfo: IWfFlowinfo):  Observable<any> {       
        return this.http.post(`${this.resourceUrl}/isExists`, wfFlowinfo, { observe: 'response' });
    }

    deploy(wfFlowinfo: IWfFlowinfo):  Observable<any> {       
        return this.http.post(`${this.resourceUrl}/deploy`, wfFlowinfo, { observe: 'response' });
    }

}
