import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { MegagameCockpitComponent } from './megagame-cockpit/megagame-cockpit';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'edu', loadChildren: './edu/edu.module#EduModule' },
      { path: 'bim', loadChildren: './bim/bim.module#BimModule' },
      { path: 'industry', loadChildren: './industry/industry.module#IndustryModule' },
      { path: 'iucce', loadChildren: './iucce/iucce.module#IucceModule' },
      // {
      //   path: 'megagameCockpit',
      //   component: MegagameCockpitComponent,
      //   data: { title: 'bim大赛驾驶舱', titleI18n: 'bim大赛驾驶舱' },
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessRoutingModule { }
