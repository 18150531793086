import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ReuseTabService } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { NicheEditComponent } from 'app/routes/business/iucce/component/niche-edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd';
import { ProjectReportService } from '../../projectReport/projectReport.service';

@Component({
  selector: 'app-project-view-edit',
  templateUrl: './view.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less','./view.component.less']
})
export class ProjectNicheViewComponent implements OnInit {

  validateForm: FormGroup;
  dataSet = [{}];
  dataSet2 = [{
    id:1,
    year: '2023',
    month: '3',
    nicheInfo: '商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况',
    completionRate: '100%',
    completionInfo: 1,
    time: '2023-01-20',
    amount: 9600000,
    orderNumber: 'Y12345678910112',
  }];
  countRemark = 0;
  opportunitys = [];
  countRemark2 = 0;

  record:any;
  prjNicheList = [];//项目商机情况
  prjNicheMaxOrderNum = 0;//项目商机最大的排序号
  prjNicheDataKeys = [];//项目商机情况id key集合
  prjNicheDatas = [];//项目商机情况数据集合

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private projectReportService: ProjectReportService,
    private reuseTabService: ReuseTabService,
    private modal: ModalHelper,
  ) { }
  
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      
    });
    this.addOpportunity();
  }

  /**
   * 获取项目商机信息
   */
  getProjectNiches(){
    if(!this.record.id){
      return;
    }
    this.projectReportService.findProjectNicheinfos(this.record.id).subscribe(res => {
      this.prjNicheList = res.body;
      if(this.prjNicheList && this.prjNicheList.length > 0){
        this.prjNicheList.forEach(item => {
          
          this.prjNicheMaxOrderNum = parseFloat(item.orderNum);
          if(isNaN(this.prjNicheMaxOrderNum)){
            this.prjNicheMaxOrderNum = 0;
          }
          this.addOpportunity(item);
        })
      }else{
        this.addOpportunity();
      }
    },(errorRes: any)=>{
              
    });
    
  }


  addOpportunity(item = null) {
    this.prjNicheMaxOrderNum++;
    let addId = this.prjNicheMaxOrderNum;
    let obj = {
      id: addId,
      estimateOrderDate:null,
      estimateOrderAmount:null,
      nicheDetails:null,
      remark:null,
      orderNum:null
    };
    if(item){
      obj = item;
    }else{
      this.prjNicheList = [
        ...this.prjNicheList,
        obj
      ];
    }

    this.prjNicheDataKeys.push(obj.id);

    this.validateForm.addControl('nicheInfo_' + obj.id+'_id', new FormControl(obj.id, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderDate', new FormControl(obj.estimateOrderDate, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderAmount', new FormControl(obj.estimateOrderAmount,Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_nicheDetails', new FormControl(obj.nicheDetails, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_remark', new FormControl(obj.remark));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_orderNum', new FormControl(this.prjNicheMaxOrderNum));
  }

  removeOpportunity(rid) {
    this.prjNicheList = this.prjNicheList.filter(item => {
      return item.id !== rid
    })
    this.prjNicheDataKeys = this.prjNicheDataKeys.filter(item => {
      return item !== rid
    })
    
    this.validateForm.removeControl('nicheInfo_'+rid+'_id');
    this.validateForm.removeControl('nicheInfo_'+rid+'_estimateOrderDate');
    this.validateForm.removeControl('nicheInfo_'+rid+'_estimateOrderAmount');
    this.validateForm.removeControl('nicheInfo_'+rid+'_nicheDetails');
    this.validateForm.removeControl('nicheInfo_'+rid+'_remark');
    this.validateForm.removeControl('nicheInfo_'+rid+'_orderNum');
  }

  //查看
  viewNiche(data) {
    this.modal.static(NicheEditComponent, {
      pageType: 'view',
      record:data
    }, 1080).subscribe();
  }

  submitForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    console.log(this.validateForm.value);
  }

  return() {
    this.reuseTabService.replace('/business/iucce/projectNiche');
  }

}

