import { Component,Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimPersonService } from '../bim-person.service';
import { BimPerson } from '../bim-person.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimPersonResultEditComponent} from './edit/edit.component';
import { UserService } from "../../../../../admin/system/users/users.service";
import {ProvinceService} from '../../../provinces/provinces.service';
import {UcommonService} from '../../../ucommon//ucommon.server';
import {BimInfoEventsService} from '../../bim-info-events/bim-info-events.service';
import {BimInfoEventsItemsService} from '../../bim-info-events/bim-info-events-items/bim-info-events-items.service';

import {BimPersonTeacherListComponent} from '.././bim-person-teacher/bim-person-teacher-list.component';
import {BimPersonItemsService} from '.././bim-person-items/bim-person-items.service';

import {BimPersonResult} from './bim-person-result.model';
import {BimPersonResultService} from './bim-person-result.service';
import {BimPersonResultSetPassRateComponent} from './set-pass-rate/set-pass-rate.component';
import {BimPersonResultSendMessageComponent} from './send-message/send-message.component';
import { PersonResultEditComponent } from './edit-result/edit-result.component';

@Component({
  selector: 'app-bim-bimPerson-result',
  templateUrl: './bim-person-result.component.html',
  styleUrls: ['./bim-person-result.component.less']
})
export class BimPersonResultListComponent implements OnInit {
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科', '2': '中职'};

  public schoolAuditeList = {'':'全部','0': '未通过',  '1' : '通过'};
  public page  = 0;
  public size  = 10;
  public total ;
  public pageSizeOptions = [10, 20, 50, 100, 200, 300];
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimPerson[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];
  bimEventsList=[];
  bimItemsList = [];
  userLocationList = [];
  userProvinceList=[];
 
  entityId:string;
  entityName:string;
  accountName:string;

  isloadding=false;

  public bimInfoStatus = 1;//-1 全部;
  
  public currentBimInfo = null;
  curBimEventId:string;
  curBimItemId:string;


  isMsgVisible=false;
  showMsg="";

  isAdmin = false;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'searchValue': '',
    'schoolType': '',
    'location': '',
    'province': '',
    'itemId': '',
    'bimInfoEventsId':'',

    // sort固定
     sort: ['bpr.score,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', width: '1%', type: 'checkbox' },
    { title: '姓名', index: 'personName' , width: '4%'},
    { title: '学校', index: 'schoolName' , width: '10%'},
    { title: '类别', index: 'schoolType' , width: '3%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else{
          return item.schoolType;
        }
      }
    },

    { title: '邮箱', index: 'personEmail' , width: '8%'},

    { title: '联系电话', index: 'personTel' , width: '6%'},


    { title: '参赛专项', index: 'itemName', width: '6%'   },
    { title: '区域', index: 'regionName' , width: '4%'   },
    { title: '省份', index: 'provinceName' , width: '3%',    },
   
    { title: '成绩类型', index: 'scoreType' , width: '5%', },
    { title: '成绩', index: 'score' , width: '4%', },
    { title: '成绩结论', index: 'scoreRemark' , width: '4%', },
    { title: '排名', index: 'sortNo' , width: '3%', },
    
    { title: '所获奖项', index: 'prizeType' , width: '5%', },
    { title: '获奖等级', index: 'prizeLevel' , width: '5%', },

    { title: '使用时长', index: 'usedHms' , width: '6%', },
    { title: '考试时间', index: 'examDate' , width: '5%', },
    { title: '证书日期', index: 'certDate' , width: '6%', },
    {
      title: '短信状态', index: 'sendMessageStatus', className: 'text-center', width: '5%',
      format: (item: any) => {
        if (item.sendMessageStatus === 1) {
          return '发送成功';
        } 
        else if (item.sendMessageStatus === 0) {
          return '发送失败';
        }
        else{
          return '';
        }
      },
      filters: [
        { text: '发送失败', value: 0 } ,
        { text: '发送成功', value: 1 }, // 此处值对应数据库的值，即domain对象的值     
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    
    {
      title: '操作', className: 'text-center', width: '7%',
      buttons: [
        { text: '编辑',  click: (item:any) => this.editPersonResult(item), },
        { text: '发送短信',  click: (item:any) => this.sendMessageByPersonId(item), },
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimPersonService: BimPersonService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    public bimInfoEventsService: BimInfoEventsService,
    private bimPersonItemsService:BimPersonItemsService,
    private bimPersonResultService:BimPersonResultService,
    private bimInfoEventsItemsService:BimInfoEventsItemsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;


  }

  ngOnInit() {
    if(this.accountName === 'admin')
    {
      this.loadLocationList();
      this.loadprovinceList();

      this.isAdmin = true;

    //  this.getDataList();
    }else{
      this.getUserProvinces();
    }
  }


/**
 * 获取用户地区信息
 */
  getUserProvinces(){
     this.userProvinceList = [];
     this.userLocationList = [];
    this.provinceService.getUserProvinces()
    .subscribe((res: any) => {
      
        res.body.forEach(element => {
            this.userProvinceList.push(element.id);
            this.userLocationList.push(element.fkregionId);

            
        });
        this.loadLocationList();
        this.loadprovinceList();
        this.getDataList();

        //this.userProvinceList = res.body;
        console.log(res.body);
    });
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding=true;
     if(this.bimEventsList != null && this.bimEventsList.length >0 && this.curBimEventId != null && this.curBimEventId != undefined && this.curBimEventId.length > 0 )
     {
       
     }else{

      this.list = [];
      this.total = 0;

      this.isloadding=false;
       return false;
     }

     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
     // this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
      
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
        copyParams[key] = q[key];
      });
      this.queryParams["bimInfoEventsId"] = this.bimEventsList[0].id;
      copyParams["bimInfoEventsId"] = this.bimEventsList[0].id;
      this.queryParams.sort= ['bpr.score,desc'];
      copyParams['sort'] = 'bpr.score,desc';
    } else {
      Object.keys(q).forEach(function (key) {
        copyParams[key] = q[key];
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;


    this.curBimEventId =  copyParams["bimInfoEventsId"];

    let tmplocation = copyParams['location'];

    if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
    {   
      //delete copyParams['location.contains'];
    //  copyParams['location'] = this.userLocationList.join(",");
    }

    let tmpprovince = copyParams['province'];

    if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
    {
      //delete copyParams['province.contains'];
      copyParams['province'] = this.userProvinceList.join(",");
    }

    copyParams['type'] = this.currentBimInfo.type;//大赛类型
    
    this.bimPersonResultService.getPersonResultDataList(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding=false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event - 1;
    console.log(event);
    // this.page = event.pi - 1;
    // this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  *清除赛项成绩
  */
 deleteAllResult() {
    
      this.modalService.confirm({
        nzTitle: '清除之后，个人赛项成绩为空，需要重新导入，是否清除赛项下面的所有成绩?',
        nzContent: '',
        nzOkText: '是',
        nzOkType: 'danger',
        nzOnOk: () => {
          this.bimPersonResultService.deleteAllByEventId(this.curBimEventId)
            .subscribe(() => {
              this.msg.info("清除赛项成绩成功！");
              this.page = 0;
              this.getDataList();
              this.st.clearCheck();
            });
        },
        nzCancelText: '否',
        nzOnCancel: () => console.log('Cancel')
      });
  }




  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



    /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      this.userService.queryRegionList(dataParams).subscribe(res => {
        
        if (res && res.body) {
          this.locationList = res.body;
        }
      });
  }


  
  /**
   * 加载省份列表
   */
  loadprovinceList(){
    this.provinceList = [];
    
    this.userService.queryProvinceList({
      'id.in':this.userProvinceList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.provinceList = data;
      }
    });
  }


    /**
   * 加载赛项的专项列表
   */
  loadEventItemsList(){
    this.curBimItemId = "";
    this.bimItemsList = [];
    
    if(this.bimEventsList != null && this.bimEventsList.length >0 && this.curBimEventId != null && this.curBimEventId != undefined && this.curBimEventId.length > 0 )
     {
      
    }else{
      return false;
    }


    this.bimInfoEventsItemsService.getBimItemInfoByEventId({
      'bimInfoEventsId':this.curBimEventId,
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.bimItemsList = data;
      }
    });
  }

/**
 * 查询区域名
 * @param location 
 */
  findLocationName(location){
     let tmpList = this.locationList.filter(d=>d.id===location);
     if(tmpList.length >0)
     {
        return tmpList[0].regionName;
     }else{
       return '';
     }
  }

  /**
   * 查询省份名称
   * @param location 
   */

  findProvinceName(location){
    let tmpList = this.provinceList.filter(d=>d.id===location);
    if(tmpList.length >0)
    {
       return tmpList[0].provinceName;
    }else{
      return '';
    }
 }

 /**
  * 导出excel
  */

 importExcel(){

  if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
  {
     this.msg.info('当前赛事没有个人赛项，不能导入个人成绩!');
     return false;
  }


  
  this.modalService.confirm({
    nzTitle: '全量导入会清除所有已经导入的成绩数据，请谨慎使用该功能，是否继续?',
    nzContent: '',
    nzOkText: '是',
    nzOkType: 'danger',
    nzOnOk: () => {
       
        this.modal
        .static(BimPersonResultEditComponent, {  bimInfoEventsId: this.curBimEventId,isAdd:false }, 500)
        .subscribe(() => {
          this.getDataList();
        });
    },
    nzCancelText: '否',
    nzOnCancel: () => console.log('Cancel')
  });





     
 }

 
 /**
  * 导出excel
  */

 importExcelAdd(){

  if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
  {
     this.msg.info('当前赛事没有个人赛项，不能导入个人成绩!');
     return false;
  }

  this.modal
    .static(BimPersonResultEditComponent, {  bimInfoEventsId: this.curBimEventId, isAdd:true }, 500)
    .subscribe(() => {
      this.getDataList();
    });
     
 }



/**
 * 选择赛事事件
 * @param bimInfo 
 */
  clickContest(bimInfo) {
      this.currentBimInfo = bimInfo;
      //this.queryParams["bimInfoId"] = this.currentBimInfo.id;
      this.getEventsDataList();
  }

/**
 * 获取赛事赛项
 * @param isReset 
 */
  public getEventsDataList(isReset?: boolean) {
      let copyParams = {sort: ['createTime,desc']};
      const q = this.queryParams;
    
      copyParams['page'] = 0;
      copyParams['size'] = 1000;
      copyParams['bimInfoId.contains'] = this.currentBimInfo.id;
      copyParams['eventsType.equals'] ='1';
      copyParams['isDelete.equals'] = 0;
      

      this.bimInfoEventsService.query(copyParams)
        .subscribe((res: any) => {
            if(res.ok && res.body.length >0)
            {
              this.bimEventsList = res.body;
              this.curBimEventId = this.bimEventsList[0].id;
              this.queryParams["bimInfoEventsId"] = this.curBimEventId;
              this.loadEventItemsList();
              this.getDataList();
            }else{
              this.bimEventsList = [];
              this.curBimEventId = "-1";
              this.queryParams["bimInfoEventsId"] = "-1";
              this.loadEventItemsList();
              this.getDataList();
            }
          });   
   }

/**
 * 赛项选择框改变
 * @param $event 
 */
   bimEventsChange($event){
     this.curBimEventId = $event;
     this.queryParams["bimInfoEventsId"] = this.curBimEventId;

     this.loadEventItemsList();
     this.getDataList();
   }



   /**
    * 其他选项改变
    * @param $event 
    */
   schoolTypeChange($event){
    this.page = 0;
    this.getDataList();
  }

  searche(){
    this.page = 0;
    this.getDataList();
  }
  


  downLoadCert(){

    let tmpBimEventId = this.curBimEventId;

    if(tmpBimEventId != null && tmpBimEventId != undefined && tmpBimEventId.length >0)
    {
      
    }else{
      this.msg.info('请先选择赛事，再选择个人!');
      return false;
    }

    if(this.selectedRows != null  &&  this.selectedRows != undefined &&  this.selectedRows.length >0)
    {
      
    }else{
      this.msg.info('请先个人成绩，再导出!');
      return false;
    }



    this.selectedRows.forEach(item=>{

        if(item.prizeLevel == '一等奖' || item.prizeLevel == '二等奖' || item.prizeLevel == '三等奖')
        {
            let copyParams = {};
            copyParams['eventId'] = tmpBimEventId;
            copyParams['personId'] = item.personId;
            copyParams['itemId'] = item.itemId;
            this.bimPersonResultService.exportCertToPdf(copyParams,
              item.schoolName + '-' + item.itemType + '-' + item.itemName + '-' + item.personName);
        }
    });


    
  }


  //设置合格参数值
  setPassResult(){

    if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
    {
       this.msg.info('当前赛事没有个人赛项，不能设置合格率参数!');
       return false;
    }
  
    this.modal
      .static(BimPersonResultSetPassRateComponent, {  bimInfoEventsId: this.curBimEventId,isAdd:false }, 900)
      .subscribe(() => {
       // this.getDataList();
      });
       
  }


//自动计算成绩合格
  getPassResult(){


    if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
    {
       this.msg.info('当前赛事没有个人赛项，不能自动计算成绩合格!');
       return false;
    }


    this.modalService.confirm({
      nzTitle: '计算个人成绩合格，会将之前的合格数据清理重新计算，为避免错误，请谨慎使用该功能，是否继续?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
            this.bimPersonResultService.getPassResult( this.curBimEventId)
            .subscribe((res: any) => {
                console.log(res);


                if(res.ok)
                {
                  let body = res.body;
                  if(body.success)
                  {
                    // this.msg.info("自动计算成功，详细日志：" + body.message);

                    this.showMsg = "自动计算成功，详细日志：<br/>" + body.message
                      
                  }else{
                    //this.msg.info("自动计算失败，详细日志：" + body.message);

                    this.showMsg = "自动计算失败，详细日志：<br/>" + body.message
                  }

                  this.isMsgVisible = true;
                }else{
                  this.msg.info('自动计算失败，请联系管理员!');
                }
              }); 
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });


 
   
  }



  sendMessage(){
      if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
      {
        this.msg.info('当前赛事没有个人赛项，不能设置合格率参数!');
        return false;
      }
    
      this.modal
        .static(BimPersonResultSendMessageComponent, {  bimInfoEventsId: this.curBimEventId }, 900)
        .subscribe(() => {
           this.getDataList();
        });
  }


  sendMessageByPersonId(item){

    if(this.isAdmin != true)
    {
      this.msg.info('您不是超级管理员，不能使用该功能!');
        return false;
    }

       let personId = item.personId;
       let personResultId = item.id;
       let personScoreMark = item.scoreRemark;
       if(personId === null || personId === undefined || personId.length <=0)
       {
        this.msg.info('没有参数个人信息，不能发送短信提醒!');
        return false;
       }

       if(personResultId === null || personResultId === undefined || personResultId.length <=0)
       {
        this.msg.info('没有参数个人成绩，不能发送短信提醒!');
        return false;
       }

       if(personScoreMark === null || personScoreMark === undefined || personScoreMark.length <=0 || personScoreMark != '合格')
       {
        this.msg.info('参数个人成绩不合格，不能发送短信提醒!');
        return false;
       }

       this.bimPersonResultService.sendMessageByPersonId(personId, personResultId).subscribe(res => {
        let resultVo = res.body;
          this.msg.info(resultVo.message);
          this.getDataList();
      });
  }


  handleOkMsg(): void {
    this.isMsgVisible = false;

  }

  handleCancelMsg(): void {
    this.isMsgVisible = false;
  }

  editPersonResult(item) {
    this.modal
      .static(PersonResultEditComponent, {  record: item, isAdd: false }, 900)
      .subscribe(() => {
         this.getDataList();
      });
  }

}
