import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { SFSchema, SFUISchema, SFComponent} from '@delon/form';
import { TopicManager } from '../TopicManager.model';
import { EduTopicManagerService } from '../topicManager.service';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SelectCourseComponent } from '../selectCourse/selectCourse.component';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-topicPush-edit',
  templateUrl: './edit.component.html',
})
export class EduTopicManagerEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  id: string;
  orgPerson: any ;
  record: any = {};
  submitting = false;
  formData: any;
  form: FormGroup;
  courseList = [];
  catalogList = [];
  topicTypeList = [];
  courseId = '';
  eduCourseName = '';
  // 字典类型 - 树形下拉
  viewType = '1';
  // 字典编码
  dictNo = 'TopicType';
  type = '';
  tokenData: any;
  queryParams2 = {
    fileFk : '',
  };
    constructor(
    private modal: NzModalRef,
    private fb: FormBuilder,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalHelper: ModalHelper,
    private eduTopicManagerService: EduTopicManagerService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService
  ) {
    this.form = this.fb.group({
      eduCourseName: [null, [Validators.required]],
     // eduCourseId: [null, []],
    //  courseCatalogName: [null, []],
      courseCatalogId: [null, [Validators.required]],
      description: [null, [Validators.required]],
      type: ['1', [Validators.required]],
      isSolved: ['0', [Validators.required]],
      isTop: ['0', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getTopicType();
    if (this.record.id == null) {
      this.type = '1';
    } else {
      this.eduTopicManagerService.find(this.record.id).subscribe((res: any) => {
        const record = res.body;
        this.msgSrv.remove();
        if (record && record !== null) {
          const val = record;
          this.getCatalogMsg(val.eduCourseId);
          this.courseId = val.eduCourseId;
         // this.form.controls.eduCourseId.setValue(val.eduCourseId);
          this.form.controls.eduCourseName.setValue(val.eduCourseName);
          this.form.controls.courseCatalogId.setValue(val.courseCatalogId);
          this.form.controls.type.setValue(val.type);
          this.form.controls.description.setValue(val.description);
          this.form.controls.isSolved.setValue(val.isSolved  + '');
          this.form.controls.isTop.setValue(val.isTop + '');
          this.courseId = val.eduCourseId;
          this.id = val.id;
        }
      });
    }
  }
  save(resource ?: TopicManager) {
    const param = {};
    let isSave = true;
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (!this.form.controls[key].value) {
        isSave =  false;
        return ;
      }
      param[key] = this.form.controls[key].value;
    }
    param['id'] = this.id;
    param['eduCourseId'] = this.courseId;
    if (isSave) {
      this.submitting = true;
      this.eduTopicManagerService.saveTopicInfo(param).subscribe(res => {
        const record = res.ok;
        if (record ) {
          this.msgSrv.info('保存成功');
          this.modal.destroy(true);
        } else {
          this.msgSrv.error('保存失败');
        }
      });
    } else {
      this.msgSrv.error('请将信息填写完整');
    }
  }

  close() {
    this.modal.destroy();
  }


 // 获取主题类型
 getTopicType() {
  this.eduTopicManagerService.queryTopicType('TopicType').subscribe(res => {
    const reconds = res.body;
    if (reconds && reconds !== null && reconds.length > 0) {
      this.topicTypeList = reconds;
    }
  });
}

 // 获取课程数据
 getCourseMsg(courseName: string) {
  this.eduTopicManagerService.queryCourse(courseName).subscribe(res => {
    const reconds = res.body;
    if (reconds && reconds !== null && reconds.length > 0) {
      this.courseList = reconds;
    }
  });
}



  // 获取课程章节数据
  getCatalogMsg(courseId: string) {
    this.eduTopicManagerService.queryCatalog(courseId).subscribe(res => {
      const reconds = res.body;
      if (reconds && reconds !== null && reconds.length > 0) {
        this.catalogList = reconds;
      }
    });
  }

 catalogChange(value: any) {
  console.log(value );
 }

  selectCourse() {
  // 选择课程
  this.modalHelper.open(SelectCourseComponent, {
    isAdd: true}, 'xl', { nzClassName: 'select-modal' }).subscribe(res => {
      this.form.controls.courseCatalogId.setValue(null);
      this.catalogList = [];
      this.getCatalogMsg(res.courseId);
      this.form.controls.eduCourseName.setValue(res.courseName);
    //  this.form.controls.eduCourseId.setValue(res.courseId);
    this.courseId = res.courseId;
  });

  }


}
