export const COMMON_DATA = {
    'menu' :  { 'data': []},
    'sysNewsContentType': [
        { value:'coopCollege',itemText: '全国赛事',type:'bim'},
        { value: 'coopCollegeProvince', itemText: '省级赛事',type:'province' },
        { value: 'coopCollegeEnterprise', itemText: '行业赛',type:'enterprise' },
    ],
    'month' : [
        { text : '1月',value:1 },
        { text : '2月',value:2 },
        { text : '3月',value:3 },
        { text : '4月',value:4 },
        { text : '5月',value:5 },
        { text : '6月',value:6 },
        { text : '7月',value:7 },
        { text : '8月',value:8 },
        { text : '9月',value:9 },
        { text : '10月',value:10 },
        { text : '11月',value:11 },
        { text : '12月',value:12 }
    ],
    'iucceProjectEstablishStatus' : [
        { text : '待立项', value : '0' },
        // { text : '申报书已提交', value : '1' },
        { text : '申报书审核中', value : '2' },
        { text : '申报书审核通过', value : '3' },
        { text : '申报书审核不通过', value : '4' },
        { text : '待企业提交协议', value : '-5' },
        { text : '协议书审核中', value : '5' },
        { text : '企业审核通过', value : '6' },
        { text : '协议书审核不通过', value : '7' },
        { text : '退回修改', value : '9' },
        { text : '立项通过', value : '8' },
        { text : '立项不通过', value : '44' }
    ],
    'iucceProjectOrderStatus' : [
        { text : '待成单', value : '0', color: '#666666' },
        { text : '部分成单', value : '1', color: '#FFAE46' },
        { text : '全部成单', value : '3', color: '#46C39E' }
    ],
    'iucceProjectClosingStatus' : [
        { text : '待结项', value : '0' },
        { text : '已提交', value : '1' },
        { text : '审批中', value : '2' },
        { text : '企业验收通过', value : '3' },
        { text : '企业验收不通过', value : '4' },
        { text : '结项通过', value : '6' },
        { text : '结项不通过', value : '7' },
        { text : '退回修改', value : '9' }
    ]
}