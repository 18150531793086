import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { HttpAddressService } from '@shared/session/http-address.service';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { NzMessageService, NzModalService, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BimInfoEventsService } from '../bim-info/bim-info-events/bim-info-events.service';
import { BimSchoolsService } from '../school/bim-schools.service';
import {BimWorkCoresService} from '../bim-work-cores/bim-work-cores.service';
import { BimAdvisorsService } from '../bim-advisor/bim-advisor.service';
import { BimAdvisorEditComponent } from '../bim-advisor/edit/edit.component';


@Component({
  selector: 'app-work-cores',
  templateUrl: './bim-work-cores.component.html',
  styleUrls: []
})
export class BimWorkCoresComponent implements OnInit {

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public currentBimInfo = null;
  public bimInfoStatus = -1;

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  bimEventsList = [];
  curBimEvent = null;
  curBimEventId = null;
  public queryParams = {
    // 查询搜索框的字段要预置
    'location': null,
    'province': null,
    'workName': null
  };

  flushLoading = false;
  exportLoading = false;
  // 校内评分 全国评分
  public isSchoolScore = true;

  columns: SimpleTableColumn[] = [
    { title: '作品名称', index: 'workName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '团队名称', index: 'teamName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '学校', index: 'schoolName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '专项', index: 'itemName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '大区', index: 'regionName', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '省份', index: 'provinceName', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '得分', index: 'secCore', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '提交时间', index: 'uploadDate', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col)  },
  ];

  public regionList = [];
  public initProvinceList = [];
  public provinceList = [];

  constructor(
    private router: Router,
    private msg: NzMessageService,
    private userService: UserService,
    private bimInfoEventsService: BimInfoEventsService,
    private bimWorkCoresService: BimWorkCoresService,
  ) {
  }

  ngOnInit(): void {
    // this.getDataList();
    this.isSchoolScore = this.router.url.indexOf('school') > -1;
    this.initData();
  }

  initData() {
    // 加载区域与省份数据
    const dataParams = {
      'size': '10000',
      'sort': 'orderNum,asc'
    };
    this.userService.queryRegionList(dataParams).subscribe(res => {
      if (res && res.body) {
        this.regionList = res.body;
      }
    });
    this.userService.queryProvinceList(dataParams).subscribe(res => {
      if (res && res.body) {
        this.initProvinceList = res.body;
      }
    });
  }

  locationChange(regionId) {
    this.queryParams['province'] = null;
    if (!isNullOrUndefined(regionId)) {
      this.provinceList = this.initProvinceList.filter(e => e.fkregionId === regionId);
    } else {
      this.provinceList = [];
    }
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimWorkCoresService.getBimWorkDataByBimInfo(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key !== 'bimInfoEventsId') {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key].trim();
      }
    });
    if (copyParams['bimInfoEventsId'] === null) {
      copyParams['bimInfoEventsId'] = -1;
    }
    copyParams['workType'] = this.isSchoolScore ? 0 : 1;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
   * 获取赛事赛项
   * @param isReset
   */
  public getEventsDataList() {
    const copyParams = {
      'page': 0,
      'size': 1000,
      'bimInfoId.contains': this.currentBimInfo.id,
      'eventsType.equals': '0',
      'isUplaodWork.equals': '1',
      'isDelete.equals': 0,
      sort: ['createTime,desc']
    };

    this.isloadding = true;
    this.bimInfoEventsService.query(copyParams).subscribe((res: any) => {
      if (res.ok && res.body.length > 0) {
        this.bimEventsList = res.body;
        this.curBimEvent = this.bimEventsList[0];
        this.curBimEventId = this.bimEventsList[0].id;
        this.queryParams['bimInfoEventsId'] = this.curBimEventId;
        this.getDataList();
      } else {
        this.isloadding = false;
        this.bimEventsList = [];
        this.curBimEvent = null;
        this.curBimEventId = '-1';
        this.queryParams['bimInfoEventsId'] = '-1';
        this.list = [];
        this.page = 0;
        this.size = 10;
        this.total = '0';
        // this.getDataList();
      }
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    // this.getDataList();
    this.getEventsDataList();
  }

  /**
   * 赛项选择框改变
   * @param $event
   */
  bimEventsChange($event) {
    this.curBimEventId = $event;
    this.queryParams['bimInfoEventsId'] = this.curBimEventId;
    this.getDataList();
  }

  flushWorkScore() {
    if (isNullOrUndefined(this.curBimEventId) || this.curBimEventId === '-1') {
      this.msg.info('当前赛事没有团队赛项!');
      return;
    }
    this.flushLoading = true;
    this.bimWorkCoresService.flushCountryWorkScore(this.curBimEventId).subscribe(res => {
      this.flushLoading = false;
      this.msg.success('更新完成');
      this.getDataList();
    });
  }

  exportWorkData() {
    if (isNullOrUndefined(this.curBimEventId) || this.curBimEventId === '-1') {
      this.msg.info('当前赛事没有团队赛项!');
      return;
    }
    this.exportLoading = true;
    this.bimWorkCoresService.exportWorkData(this.curBimEventId, this.isSchoolScore ? 0 : 1,
          `${this.currentBimInfo.name}-${this.curBimEvent.name}-${this.isSchoolScore ? '校内作品排名' : '决赛作品排名'}(${this.formatDate()})`);
    this.exportLoading = false;
  }

  formatDate(str?: string) {
    const date = isNullOrUndefined(str) ? new Date() : new Date(str);
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }
}
