import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAddressService } from '@shared/session/http-address.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
  resourceUrl: string;
  auditResourceUrl:string;
  constructor(public http: HttpClient,
    private httpAddressService: HttpAddressService
  ) {
    this.resourceUrl = this.httpAddressService.workflowServe + '/ccbworkflow';
    this.auditResourceUrl = this.httpAddressService.workflowServe + '/wf-auditideas';
  }

  getBtnState(businessId: string): Observable<any> {
    const url = `${this.resourceUrl}/getBtnState/${businessId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getAuditIdeaList(businessId: string): Observable<any> {
    const url = `${this.resourceUrl}/getAuditIdeaList/${businessId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getCommonIdeaList(): Observable<any> {
    const url = `${this.httpAddressService.workflowServe}/wf-common-ideas`;
    return this.http.get(url, { observe: 'response' });
  }

  // getFlowList(moduleId: string, tenantId: string, businessId: string): Observable<any> {
  //   const url = `${this.resourceUrl}/getFlowList?moduleId=${moduleId}&businessId=${businessId}&tenantId=${tenantId}`;
  //   return this.http.get(url, { observe: 'response' });
  // }
  getFlowList(moduleId: string, tenantId: string, businessId: string): Observable<any> {
    const url = `${this.resourceUrl}/getFlowList?moduleId=${moduleId}&businessId=${businessId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getAddSignFlowList(moduleId: string, businessId: string): Observable<any> {
    const url = `${this.resourceUrl}/getAddSignFlowList?moduleId=${moduleId}&businessId=${businessId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getStepList(flowId: string, curTaskId: string): Observable<any> {
    const url = `${this.resourceUrl}/getStepList?flowId=${flowId}&curTaskId=${curTaskId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getRejectStepList(curTaskId: string): Observable<any> {
    const url = `${this.resourceUrl}/getRejectStepList/${curTaskId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getAddSignStepList(flowId: string, curTaskId: string): Observable<any> {
    const url = `${this.resourceUrl}/getAddSignStepList?flowId=${flowId}&curTaskId=${curTaskId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getStepFormProperty(flowId: string, stepId: string): Observable<any> {
    const url = `${this.resourceUrl}/getStepFormProperty/${flowId}/${stepId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getStepFormProperty2(flowId: string, stepId: string): Observable<any> {
    const url = `${this.resourceUrl}/getStepFormProperty?flowId=${flowId}&stepId=${stepId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getAuditerList(flowId: string, stepId: string): Observable<any> {
    const url = `${this.resourceUrl}/getAuditerList/${flowId}/${stepId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getAuditerRoleList(flowId: string, stepId: string): Observable<any> {
    const url = `${this.resourceUrl}/getAuditerRoleList/${flowId}/${stepId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getRejectAuditerList(flowId: string, stepId: string, businessId: string, taskId: string): Observable<any> {
    const url = `${this.resourceUrl}/getRejectAuditerList/${flowId}/${stepId}/${businessId}/${taskId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getProcessInstanceStarter(taskId: string): Observable<any> {
    const url = `${this.resourceUrl}/getProcessInstanceStarter/${taskId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getReaderList(flowId: string, stepId: string): Observable<any> {
    const url = `${this.resourceUrl}/getReaderList/${flowId}/${stepId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getWfState(businessId: string, curTaskId: string): Observable<any> {
    const url = `${this.resourceUrl}/getWfState?businessId=${businessId}&taskId=${curTaskId}`;
    return this.http.get(url, { observe: 'response' });
  }
  getThingsList(queryParams?: any): Observable<any> {
    const url = `${this.httpAddressService.workflowServe}/wf-notice-things`;
    let params = Object.assign({}, queryParams, { "sort": "sendTime,desc" });
    return this.http.get(url, { params: params, observe: 'response' });
  }
  setThingsReaded(id: String) {
    const url = `${this.httpAddressService.workflowServe}/setThingReaded/${id}`;
    return this.http.get(url, { observe: 'response' });
  }

  getAuditIdeas(params: any): Observable<any> {
    const url = `${this.httpAddressService.workflowServe}/wf-auditideas`;
    return this.http.get(url, { params: params, observe: 'response' });
  }

  getProcessDiagramUrl(processInstanceId: string) {
    let t = moment();
    let url = `${this.resourceUrl}/createProcessDiagram/${processInstanceId}/${t}`;
    return url;
  }

  getThingsListForAuditList(businessId: string): Observable<any> {
    const url = `${this.httpAddressService.workflowServe}/getThingsListForAuditList/${businessId}`;
    return this.http.get(url, { observe: 'response' });
  }

  send(wfParam: any) {
    const url = `${this.resourceUrl}/send`;
    return this.http.post(url, wfParam, { observe: 'response' });
  }
  submit(wfParam: any) {
    const url = `${this.resourceUrl}/submit`;
    return this.http.post(url, wfParam, { observe: 'response' });
  }
  saveAuditIdea(record:any){
    const url = `${this.auditResourceUrl}`;
    if(record.id){
      return this.http.put(url, record, { observe: 'response' });
    }
    return this.http.post(url, record, { observe: 'response' });
  }

  revokeByThingId(thingId: string) {
    const url = `${this.resourceUrl}/revokeByThingId/${thingId}`;
    return this.http.get(url, { observe: 'response' });
  }
  revoke(wfParam: any) {
    const url = `${this.resourceUrl}/revoke`;
    return this.http.post(url, wfParam, { observe: 'response' });
  }

  reject(wfParam: any) {
    const url = `${this.resourceUrl}/reject`;
    return this.http.post(url, wfParam, { observe: 'response' });
  }

  endProcess(wfParam: any): Observable<any> {
    const url = `${this.resourceUrl}/endProcess`;
    return this.http.post(url, wfParam, { observe: 'response' });
  }

  getDistinctThingTitleByReceiver(receiver: string): Observable<any> {
    const url = this.httpAddressService.workflowServe + `/wf-notice-things/getDistinctThingTitleByReceiver/${receiver}`;
    return this.http.get(url, { observe: 'response' });
  }

  getDistinctFlowInfoByReceiver(receiver: string): Observable<any> {
    const url = this.httpAddressService.workflowServe + `/wf-notice-things/getDistinctFlowInfoByReceiver/${receiver}`;
    return this.http.get(url, { observe: 'response' });
  }

  getLatestHistoricProcess(param: any): Observable<HttpResponse<any[]>> {
    const postUrl = `${this.resourceUrl}/getLatestHistoricProcess`;
    return this.http.post<any>(postUrl, param, { observe: 'response' });
  }

  getThingsListAll(queryParams?: any): Observable<any> {
    const url = `${this.httpAddressService.workflowServe}/wf-notice-things/all`;
    const params = Object.assign({}, queryParams, { 'sort': 'sendTime,desc' });
    return this.http.get(url, { params: params, observe: 'response' });
  }
}
