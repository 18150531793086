import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimSchools,SchoolPerson } from '../../../school/bim-schools.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimPersonService } from '../bim-person.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { UserService } from "../../../../../admin/system/users/users.service";
import { isNullOrUndefined } from 'util';

import {BimInfoSchoolsEditComponent} from '../../bim-info-schools/edit/edit.component';
import { BimItemsService } from '../../../bim-items/bim-items.service';
import {BimPersonItemsService} from '../bim-person-items/bim-person-items.service';
import * as moment from 'moment';

@Component({
  selector: 'app-bim-bimPerson-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimPersonEditComponent implements OnInit {

  record:any;

   // 是否增加
  isAdd: boolean;
 
  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;
  schoolId:string;
  bimInfoEventsId:string;
  bimInfoType: string;


  ItemTypes=[];
  Items =[];
  personItems=[];

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimPersonService: BimPersonService,
    private userService: UserService,
    public modal: ModalHelper,
    private bimItemsService:BimItemsService,
    private bimPersonItemsService:BimPersonItemsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      schoolName:[null, [Validators.required]],
      personName: [null, [Validators.required]],
      personEmail: [null, [Validators.required]],
      personTel: [null, [Validators.required]],
      grade: [null, [Validators.required]],
      profession: [null, [Validators.required]],
      isDelete: [0, []]
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
     this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {
    this.loadItems();
   

    if (!this.isAdd) {
    
      this.loadData();
      this.loadPersonItems();
    }

   
  }



/**
 * 加载页面data
 */
  loadData(): void {
       this.form.patchValue(this.record);
        this.schoolId = this.record.schoolId;
  }


  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    param["schoolId"] = this.schoolId;
    param["bimInfoEventsId"] = this.bimInfoEventsId;
    

    if (this.form.invalid) {
      this.msg.error('请检查个人信息是否完整');
      return;
    }

    if (this.isMatchName(param['personName'])) {
      this.msg.error('参赛人姓名不可包含字母、数字、符号');
      return;
    }

    if(this.personItems.length<=0)
    {
      this.msg.error('请选择参数专项');
      return;
    }

    param['grade'] = moment(param['grade']).format('YYYY');
    if (this.isAdd === true) {
        param["userDefine1"] ="0";
        this.addDataInfo(param);
    } else {
      param["userDefine1"] =this.record.userDefine1;
       this.modifyDataInfo(param);
    }

  }

  isMatchName(str: string) {
    const regex = /([a-z0-9][a-z0-9]*)/;
    return str.match(regex);
  }


  modifyDataInfo(param: any) {
    this.bimPersonService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        // this.msg.info('修改成功');
        // this.Cancel(true);
        this.saveItems(record.id, '修改成功');
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {

    const tmpparam = {};
    tmpparam["bimInfoEventsId.equals"] = this.bimInfoEventsId;
    tmpparam["personTel.equals"] = param["personTel"];

    this.bimPersonService.query(tmpparam).subscribe((res: any) => {

         let tmplist = res.body;
         if(tmplist != null && tmplist.length > 0)
         {
            this.msg.error('该学生的手机号码已经报名，不能重复添加！');
            return false;
         }else{
            const tmpparam1 = {};
            tmpparam1["bimInfoEventsId.equals"] = this.bimInfoEventsId;
            tmpparam1["personName.equals"] = param["personName"];
            tmpparam1["schoolId.equals"] = param["schoolId"];


            this.bimPersonService.query(tmpparam1).subscribe((res: any) => {

              let tmplist1 = res.body;
              if(tmplist1 != null && tmplist1.length > 0)
              {
                 this.msg.error('该学校已经存在该学生同名的报名同学，不能重复添加！');
                 return false;
              }else{
                  this.bimPersonService.create(param).subscribe(res => {
                    this.record = res.body;
                    if (this.record  ) {
                      // this.msg.info('保存成功');
                      // this.Cancel(true);
                      this.saveItems(this.record.id,'保存成功！');
                    } else {
                      this.msg.error('保存失败');
                    }
                  });
     
              }
           });
     

         }
      });



  }


  Cancel(type?){
     this.nzModalRef.destroy(type);
  }




    
      
      /**
     * 销毁时删除附件，防止文件表过多
     */
      // tslint:disable-next-line:use-life-cycle-interface
      ngOnDestroy(): void {
        
      }



  selectSchool(){
    this.modal
    .static(BimInfoSchoolsEditComponent, {
      bimInfoType: this.bimInfoType
    })
    .subscribe((res) => {
      if(res !=null && res.length >1)
      {
          this.msg.error('只能选择一个学校，请重新选择！');
          return false;
      }else if(res !=null && res.length === 1)
      { 
          this.record.schoolId = res[0].id;
          this.schoolId  = res[0].id;
          this.record.schoolName =  res[0].name;
          this.form.patchValue(this.record);
      }
     
    });
  }


  loadItems(){
    this.Items=[];
    this.ItemTypes=[];
    let copyParams = {};
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    copyParams['isDelete.equals'] = 0;
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimInfoEventsId, copyParams).subscribe((res: any) => {
      
          this.Items = res.body;
          let tmpType="";
          this.Items.forEach(element => {
               let tmpHasItem = this.ItemTypes.filter(d => d.type === element.type);
               if(element.type != tmpType && tmpHasItem.length <= 0)
               {
                   tmpType = element.type;
                   let tmpTypeObj = {};
                   tmpTypeObj["type"] = tmpType;
                   this.ItemTypes.push(tmpTypeObj);
               }
          });
    });
  }


  loadPersonItems(){
    this.personItems = [];
    let copyParams = {};
    copyParams['personId.contains'] = this.record.id;
    this.bimPersonItemsService.query(copyParams).subscribe((res: any) => {
        this.personItems = res.body;
    });
  }


  saveItems(personId:string, msg:string){
      let ids=[];
      this.personItems.forEach(item=>{
          ids.push(item.itemId);
      })

      this.bimPersonItemsService.saveItemByTeam({'personId': personId, 'ItemsIdList': ids.toString()}).subscribe(res => {
        this.msg.info(msg);
        this.nzModalRef.destroy();

      });
  }

  getItemByType(type){
    return this.Items.filter(d => d.type === type).sort(d=>d.orderSn);
  }


  /**去掉空对象 */
  delNullObj(List: any): any {
    const zhangstr = [];
    for (let k = 0; k < List.length; k++) {
      const obj = List[k];
      if (obj != null)
        zhangstr.push(obj);
    }

    return zhangstr;
  }


    /**
   *
   * @param $event 复选按钮触发事件
   * @param optValue
   */
  checkboxClick($event, itemId: string) {
    
    const target = $event.target;
    if(target.checked === true){
      let tmpItems =  this.personItems.filter(d=>d.itemId === itemId);   
      if(tmpItems.length <=0)
      {
         let tmpBimItem ={};
         tmpBimItem["itemId"] = itemId;
         tmpBimItem["bimInfoEventsId"] = this.record.id;
         this.personItems.push(tmpBimItem);
      }
    }else{
      let nindex;
      this.personItems.forEach((element, index) => {

        if (element.itemId === itemId) {
          nindex = index;
        }
      });

      //删除没有选择项的，并清空空记录
       delete this.personItems[nindex];
       this.personItems = this.delNullObj(this.personItems);
    }
  }

  getCheckBoxValue(itemId: string){
      let tmpItems =  this.personItems.filter(d=>d.itemId === itemId);  
      if (tmpItems.length > 0)
      {
        return 'true';
      }else{
        return '';
      }

  }
}
