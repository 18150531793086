import { Component, OnInit, Inject } from '@angular/core';
import { CertificationService } from './certification.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ModalHelper } from '@delon/theme';
import { Certification } from './certification.model';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.less']
})
export class CertificationComponent implements OnInit {

  // 数据集合
  dataList = [];
  // 图片前缀
  imgPrefix = '';
  // 当前用户实体id
  entityId = '';
 // 当前用户id
  userId = '';

  thsBimList = [
    { name: '职业能力证书（封面）', src: '/assets/images/certification/certificate_cover.png' },
    { name: '职业能力证书（内页）', src: '/assets/images/certification/certificate_inner.png' }
  ];

  atcList = [
    { name: '欧特克ATC（Revit）认证', src: '/assets/images/certification/ATC1.png' },
    { name: '欧特克ATC（CAD）认证）', src: '/assets/images/certification/ATC2.png' },
    { name: 'Autodesk授权培训中心', src: '/assets/images/certification/ATC3.png' },
    { name: '2017获奖”最佳BIM教育实践奖“）', src: '/assets/images/certification/ATC4.png' },
    { name: '2018  Autodesk  ATC  优秀机构', src: '/assets/images/certification/ATC5.png' },
    { name: '2016获奖”十佳ATC“', src: '/assets/images/certification/ATC6.png' }
  ];

  // 是否登录
  isLogin = false;

  constructor(
    private certificationService: CertificationService,
    private modalHelper: ModalHelper,
    private httpAddressService: HttpAddressService,
    private modalService: NzModalService,
    public msg: NzMessageService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.imgPrefix = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';
  }

  ngOnInit() {
    this.loadData();
  }


  loadData() {
    const param = {
      'isOk.equals': '1',
      'sort': ['orderNum,asc']
    };
    this.certificationService.query(param).subscribe(res => {
      const reconds = res.body;
      console.log(reconds);
      if (reconds && reconds !== null && reconds.length > 0) {
        this.dataList = reconds;
        this.dataList.forEach(val => {
          if (val.linkUrl !== null && val.linkUrl !== '') {
            val.linkUrl = 'http://' + val.linkUrl;
          }
        });
      }
    });
  }

}
