import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BimInfoEventsItemsService } from '../../bim-info/bim-info-events/bim-info-events-items/bim-info-events-items.service';
import { BimInfoEventsService } from '../../bim-info/bim-info-events/bim-info-events.service';
import { BimInfoService } from '../../bim-info/bim-info.service';
import { BimItemsService } from '../../bim-items/bim-items.service';
import { BimScoringRuleService } from '../bim-scoring-rule.service';
import { BimScoringRuleItemComponent } from '../detail/bim-scoring-rule-item.component';

@Component({
  selector: 'app-bim-scoring-rule-edit',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimScoringRuleEditComponent implements OnInit {

  record: any = {};

  public isAdd = true;

  form: FormGroup;
  public loading = false;
  public uploadIsVisible = false;
  // 导入工作量 excel
  public excelFile: UploadFile[] = [];
  // 是否上传中
  public excelUploading = false;
  // 导入接口
  public importUrl = '';

  public dataId = null;

  public bimInfoList = [];
  public initItemList = [];
  public bimItemList = [];
  public bimItemType = [];
  public bimInfoEventList = [];
  public bimEventItemList = [];
  public initEventItemList = [];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private modalHelp: ModalHelper,
    private nzModalRef: NzModalRef,
    private bimInfoService: BimInfoService,
    private bimItemsService: BimItemsService,
    private fileUploadService: FileUploadService,
    private bimInfoEventsService: BimInfoEventsService,
    private bimScoringRuleService: BimScoringRuleService,
    private bimInfoEventsItemsService: BimInfoEventsItemsService,
    httpAddressService: HttpAddressService,
  ) {
    this.importUrl = httpAddressService.BimServe + '/bim-scoring-rules/import';
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [null, [Validators.required]],
      bimInfoEvent: [null, [Validators.required]],
      bimItemType: [null, []],
      itemName: [null, []],
      bimItemsId: [null, [Validators.required]],
      eventsName: [null, []],
      eventsItemId: [null, []],
      isOk: [null, [Validators.required]],
      userDefine1: [null, [Validators.required]]//总分
    });
  }

  ngOnInit(): void {
    if (this.bimInfoList.length === 0) {
      this.initBimInfoList();
    }
    this.initBimItemList();
  }

  initBimInfoList() {
    this.bimInfoService.query({
      'size' : '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
        this.bimInfoList = res.body;
    });
  }

  initBimItemList() {
    this.loading = true;
    this.bimItemsService.query({
      'size' : '10000',
      'isDelete.equals': 0,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      const data = res.body;
      this.initItemList = data;
      if (this.record !== null && !isNullOrUndefined(this.record.id)) {
        this.dataId = this.record.id;
        this.loadData();
      } else {
        this.loading = false;
      }
    }, () => this.loading = false);
  }

  loadData() {
    this.bimInfoEventsService.query({
      'bimInfoId.equals': this.record.bimInfoId,
      'eventsType.equals': 0,
      'isDelete.equals': 0,
      'size' : '10000',
      sort: ['createTime,desc']
    }).subscribe(res => {
      const eventList = res.body;
      this.bimInfoEventList = res.body;
      this.bimInfoEventsItemsService.query({
        'itemId.equals': this.record.eventsItemId
      }).subscribe(itRes => {
        const eventItemsList = itRes.body;
        let selEvent = null;
        eventItemsList.forEach(e => {
          if (selEvent === null) {
            eventList.forEach(d => {
              if (selEvent === null && e.bimInfoEventsId === d.id) {
                selEvent = d;
              }
            });
          }
        });
        const item = this.initItemList.filter(e => e.id === this.record.eventsItemId);
        if (item && item.length > 0) {
          this.record.bimItemType = item[0].type;
        }
        this.form.patchValue(this.record);
        if (selEvent !== null) {
          this.form.controls['bimInfoEvent'].setValue(selEvent.id);
          this.form.controls['bimItemsId'].setValue(this.record.eventsItemId);
        }
        this.form.controls['isOk'].setValue(this.record.isOk + '');
        this.loading = false;
      });
    });
  }

  selBimInfoChange(id) {
    if (isNullOrUndefined(id)) return;
    if (this.bimInfoEventList.length > 0 && this.bimInfoEventList[0].bimInfoId === id) return;
    this.bimInfoEventsService.query({
      'bimInfoId.equals': id,
      'eventsType.equals': 0,
      'isDelete.equals': 0,
      'size' : '10000',
      sort: ['createTime,desc']
    }).subscribe(res => {
      this.bimInfoEventList = res.body;
    });
  }

  selEventChange(id) {
    if (isNullOrUndefined(id)) return;
    this.initItemList.forEach(e => {
      if (this.bimItemType.indexOf(e.type) < 0) {
        this.bimItemType.push(e.type);
      }
    });
    this.bimInfoEventsItemsService.query({
      'bimInfoEventsId.equals': id,
      'size' : '10000',
    }).subscribe(res => {
      this.bimEventItemList = res.body;
      this.initEventItemList = this.initItemList.filter(e => (this.bimEventItemList.filter(d => d.itemId === e.id).length > 0));
      this.bimItemList = this.initEventItemList;
      this.loading = false;
    });
  }

  selItemChange(id) {
  }

  selTypeChange(type) {
    this.form.controls['eventsItemId'].setValue(null);
    this.form.controls['bimItemsId'].setValue(null);
    this.bimItemList = this.initEventItemList.filter(e => e.type === type);
  }

  // 生成考试提交
  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    const event = this.bimInfoEventList.filter(e => e.id === subParam['bimInfoEvent']);
    if (event && event.length > 0) {
      subParam['eventsName'] = event[0].name;
    }

    const data = this.bimItemList.filter(e => e.id === subParam['bimItemsId']);
    if (data && data.length > 0) {
      subParam['itemName'] = data[0].name;
    }

    const eventItem = this.bimEventItemList.filter(e => e.itemId === subParam['bimItemsId']);
    if (eventItem && eventItem.length > 0) {
      subParam['eventsItemId'] = eventItem[0].itemId;
    }
    this.checkSaveData(subParam);
  }

  checkSaveData(subParam: any) {
    this.loading = true;
    this.checkRepeatData(subParam);
  }

  checkRepeatData(subParam: any) {
    if (Number(subParam['isOk']) === 1) {
      this.bimScoringRuleService.query({
        'bimInfoId.equals': subParam['bimInfoId'],
        'eventsItemId.equals': subParam['eventsItemId'],
        'isOk.equals': 1
      }).subscribe(qRes => {
        if (qRes && qRes.body) {
          if (qRes.body.length > 0) {
            let data = qRes.body;
            if (subParam['id'] !== null) {
              data = data.filter(e => e.id !== subParam['id']);
            }
            if (data.length > 0) {
              this.onWarning({ 'message': '已存在该赛事事项数据，请勿重复添加！' });
              this.loading = false;
              return;
            }
          }
        }
        if (subParam['id'] === null) {
          this.saveData(subParam);
        } else {
          this.updateData(subParam);
        }
      }, () => this.loading = false);
    } else {
      if (subParam['id'] === null) {
        this.saveData(subParam);
      } else {
        this.updateData(subParam);
      }
    }
  }

  saveData(subParam: any) {
    subParam['id'] = this.dataId;
    this.bimScoringRuleService.create(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  updateData(subParam: any) {
    this.bimScoringRuleService.update(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  close() {
    this.nzModalRef.destroy(this.dataId);
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }

  // 打开上传弹框
  openUploadnModal() {
    this.uploadIsVisible = true;
  }

  // 关闭上传弹框
  handleCancel() {
    this.excelFile = [];
    this.uploadIsVisible = false;
    this.excelUploading = false;
  }

  // 上传之前
  beforeUpload = (file: UploadFile): boolean => {
    const isXlsx = file.name.endsWith('xlsx') || file.name.endsWith('xls');
    if (!isXlsx) {
      this.msg.error('仅允许上传xlsx或xls格式文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      this.msg.error('文件不能大于50M');
    }
    if (isXlsx && isLt2M) {
      this.excelFile.push(file);
    }
    return false;
  }

  // 导入参赛团队信息
  handleUpload(): void {
    const formData = new FormData();
    this.excelFile.forEach((file: any) => {
      formData.append('file', file);
    });
    if (this.excelFile.length === 0) {
      this.msg.remove();
      this.msg.info('请选择导入文件！');
      return;
    }
    formData.append('bimScoringRuleId', this.dataId);
    this.excelUploading = true;
    const req = new HttpRequest('POST', this.importUrl, formData, {});
    this.http
      .request(req)
      .pipe(filter(e => e instanceof HttpResponse))
      .subscribe(
        (event: any) => {
          const result = event.body;
          this.excelUploading = false;
          const msg = result.message;
          if (result.message !== 'success') {
            if (msg === 'errorLogin') {
              this.msg.warning('用户登录失效，请重新登陆');
            }
            if (msg === 'errorTem') {
              this.msg.warning('导入文件与模板不符合，请检查文件');
            }
          } else {
            this.msg.success('导入成功');
            const fileData = result.fileData;
            if (!isNullOrUndefined(fileData)) {
              this.fileUploadService.deleteAllByFk(this.dataId).subscribe(() => {
                this.fileUploadService.create(fileData).subscribe();
              });
            }
          }
          this.handleCancel();
          this.excelFile = [];
        },
        err => {
          this.excelUploading = false;
          this.msg.error('upload failed.');
        }
      );
  }

  detail() {
    this.modalHelp.static(BimScoringRuleItemComponent, {
      record: {
        id: this.dataId,
        itemName: this.form.controls['itemName'].value
      },
    }, 'xl').subscribe((res) => {
    });
  }
}
