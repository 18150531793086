import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { isNullOrUndefined } from 'util';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';


import { APP_PERMISSIONS } from '@shared/app.permissions';
import {BimWorkCoresService} from "../../../bim-work-cores/bim-work-cores.service";

@Component({
  selector: 'app-bim-advisor-work-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimWorkAdvisorEditComponent implements OnInit {

  public bimInfoName = "";
  public bimInfoEventName= "";
  public workName= "";
  public workId= "";
  public schoolName= "";
  public teamName= "";
  public itemName= "";
  public bimEventId = "";
  public isReply = '';

  submitting = false;
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: any[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  
  entityId:string;
  entityName:String;
  accountName:String;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    // sort固定
     sort: ['tel,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '评委姓名', index: 'name', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '单位名称', index: 'orgName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '联系电话', index: 'tel', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '邮箱', index: 'email', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    
    { title: '大区', index: 'regionName' , width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },

    { title: '省份', index: 'provinceName', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    // { title: '评审专项', index: 'itemName', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
  ];


  constructor(
    private fb: FormBuilder,
     private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimWorkCoresService:BimWorkCoresService,
     @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;



  }

  ngOnInit() {

    if(this.accountName === 'admin')
    {
    //  this.loadLocationList();
      //this.loadprovinceList();

      this.getDataList();
    }else{
    //  this.getUserProvinces();
    }
   
  }




/**
* 获取数据列表
* @param {string} url
*/
public getDataList(isReset?: boolean) {
  let copyParams = {};
 const q = this.queryParams;
 if (isReset === true) {
   this.st.reset();
   this.page = 0;
   Object.keys(q).forEach(function (key) {
     q[key] = '';
   });
 } else {
   Object.keys(q).forEach(function (key) {
     if (q[key] !== '' &&　q[key] !== null) {
       copyParams[key] = q[key];
     }
   });
 }

 copyParams['eventId'] = this.bimEventId;
 copyParams['workId'] = this.workId;
 copyParams['isReply'] = this.isReply;

 copyParams['page'] = this.page;
 copyParams['size'] = this.size;


 this.bimWorkCoresService.getAdvisorNotWorkDataList(copyParams)
   .subscribe((res: any) => {

   this.list = res.body;
   this.total = res.headers.get('X-Total-Count');

   });
}

/**
* 页码数量变动事件
* @param
*/
paginationChange(event) {
 console.log(event);
 this.page = event.pi - 1;
 this.size = event.ps;
 this.getDataList();
}

/**
* 过滤器变动事件 支持多选过滤
* @param
*/
filterChange(event) {
 let i = 0;
 const _value = [];
 const _type = event.filterMultiple ? 'in' : 'equals';
 event.filters.forEach(element => {
   if (element.checked) {
     _value[i++] = element.value;
   }
 });
 this.queryParams[event.indexKey + '.' + _type] = _value;
 this.getDataList();
}

/**
* 排序变动事件
* @param
*/
sortChange(event) {
 const array = this.queryParams['sort'];
 const length = array.length;
 let isInArray = false;
 let value = null;
 if (event.value === 'descend') {
   value = 'desc';
 } else if (event.value === 'ascend') {
   value = 'asc';
 }
 for (let i = 0; i < length; i++) {
   if (array[i].startsWith(event.column.indexKey)) {
     if (value == null) {
       array.splice(i, 1);
       isInArray = true;
       break;
     } else {
       array[i] = event.column.indexKey + ',' + value;
       isInArray = true;
       break;
     }
   }
 }
 if (value != null && !isInArray) {
   array.push(event.column.indexKey + ',' + value);
 }
 // 排序改变时，simpleTable会重置页码
 this.page = 0;
 this.getDataList();
}


 /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
 checkboxChange(list: SimpleTableData[]) {
  this.selectedRows = list;
  this.totalCallNo = this.selectedRows.reduce(
    (total, cv) => total + cv.callNo,
    0,
  );
}





  // 保存
  submit() {
    this.nzModalRef.destroy(this.selectedRows);
   
  }


  Cancel(){
    this.nzModalRef.destroy(null);
  }



    /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

}


