import { Component, OnInit,Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  HttpResponse,
} from '@angular/common/http';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { UserService } from "app/routes/admin/system/users/users.service";
import { DictService } from 'app/shared/components/dict/dict.service';
import { COMMON_DATA } from '@shared/app.commonData';
import { ProjectNicheService } from "app/routes/business/iucce/projectNiche/projectNiche.service";

@Component({
  selector: 'app-iucce-project-niche-info',
  templateUrl: './edit.component.html',
  styles: [`
  :host ::ng-deep .ant-calendar-picker {
    display:block;
  }`]
})
export class ProjectNicheInfoComponent implements OnInit {

  COMMON_DATA = COMMON_DATA;
  pageTitle: any;
  pageType: any;
  record:any;
  submitting = false;
  validateForm: FormGroup;
  isView = false;

  constructor
    (private fb: FormBuilder, 
      private modal: NzModalRef,
      public msg: NzMessageService,
      private dictService: DictService,
      private projectNicheService: ProjectNicheService,
      @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
    private userService: UserService,
    ) {
        
    }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      id: [null],
      iucceProjectId: [this.record.iucceProjectId, [Validators.required]],
      estimateOrderDate: [null, [Validators.required]],
      estimateOrderAmount:[null, [Validators.required]],
      nicheDetails:[null, [Validators.required]],
      nicheStatus:[null],
      isAdd:['1'],
      orderNum:[0],
      remark:[null]
    });

    if (this.pageType=='add') {
      this.pageTitle = '新增'
    } else {
      if (this.pageType == 'view') {
        this.isView = true;
        this.pageTitle = '查看';
      }else if(this.pageType == 'edit') {
        this.pageTitle = '编辑';
      }
      
      this.record.orderNum = parseFloat(this.record.orderNum);
      if(isNaN(this.record.orderNum)){
        this.record.orderNum = 0;
      }

      if(this.record && this.record.id){
          this.validateForm.patchValue(this.record);
      }
    }

  }

  submit(){
    for (const i in this.validateForm.controls) {
      const control = this.validateForm.controls[i];
      control.markAsDirty();
      control.updateValueAndValidity();
    }
      
    if(this.validateForm.invalid){
      this.msg.error("请检查信息是否完整!");
      return;
    }
    
    this.record.orderNum++;
    this.validateForm.get('orderNum').setValue(this.record.orderNum);
    this.submitting = true;
    let recordInfo = Object.assign({},this.record);
    recordInfo = Object.assign(recordInfo, this.validateForm.value);
    
    if(!this.record.id){
        this.projectNicheService.create(recordInfo)
        .subscribe((response) => {
            setTimeout(() => {
                this.msg.success('保存成功');
                this.record = response.body;
                this.submitting = false;
                this.close(true);
            }, 1000);
        },
        (res: HttpResponse<any>) => {
            this.submitting = false;
            this.msg.success('保存失败');
            this.close(false);
        }
    );
    }else{
        this.projectNicheService.update(recordInfo).subscribe((response) => {
            setTimeout(() => {
                this.msg.success('更新成功');
                this.record = response.body;
                this.submitting = false;
                this.close(true);
            }, 1000);
          }, (res: HttpResponse<any>) => {
            setTimeout(() => {
                this.msg.success('更新失败');
                this.submitting = false;
                this.close(false);
            }, 1000);
        });
    }
  }

  close(isRefresh = false){
    if(isRefresh){
      this.modal.close(this.record);
    }else{
      this.modal.close();
    }
  }

}
