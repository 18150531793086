import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CacheService } from '@delon/cache';
import { MenuService, ModalHelper } from '@delon/theme';
import { WorkflowService } from '@shared/components/workflow/workflow.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

/**
 *工作流通用按钮组件
 *
 * @export
 * @class WorkflowComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ths-workflow-form-cus',
  templateUrl: './workflowform_cus.component.html'
})

export class WorkflowFormCusComponent implements OnInit, AfterViewInit, OnChanges {


  flowList: any[] = [];    // 流程列表
  stepList: any[] = [];   // 步骤列表
  auditerList: any[] = [];   // 主办人列表
  wfForm: FormGroup; // 流程表单信息
  taskId: string = ''; // 当前任务ID
  processInstanceId: string = ''; // 流程实例ID
  curLoginPersonId: string = ''; // 当前登录人
  canAudit: boolean = true; // 是否可审批

  auditerCount = 0;

  @Input() busiUrl: string = ''; // 待办事项URL

  @Input() moduleId: string = '';   // 模块ID
  @Input() businessId: string = ''; // 业务ID
  @Input() busiForm: FormGroup;  // 业务表单信息
  @Input() curTaskId: string = ''; // 当前待办任务id
  @Input() customAuditers: any[] = []; // 自定义主办人 [{name:'',id:''}]
  @Input() auditerId: any[] = []; // 办理人
  @Input() canSelectAuditer: boolean = true; // 是否可以选择人员
  @Input() canSelectPass: boolean = true; // 可选审批通过
  @Input() canSelectReject: boolean = true; // 可选审批驳回
  @Input() canSelectTransmit: boolean = true; // 可选转办
  @Input() canSelectAddSign: boolean = true; // 可选加签

  @Input() isTransmitVisible: any; // 转办是否可见
  @Input() isAddSignVisible: any; // 加签是否可见
  @Input() isReturnNextStepDisabled: boolean = false; // 退回至是否禁用

  @Input() auditResult: string = '1'; // 办理结果
  @Input() auditResultLabel = {
    pass: '同意',
    reject: '不同意'
  };


  @Input() maxMultipleCount:number = 1;//办理人选择个数
  @Input() defaultFlowId: string = ''; // 默认选中的流程id
  @Input() stepFormProperty = {};
  @Output() stepFormPropertyChange = new EventEmitter<any>();
  @Output() afterWorklistInit = new EventEmitter<any>();

  // @Input() onGetAuditerList: (value:any) => []=function(value) { return []; };

  @Output() onResultSelected: EventEmitter<any> = new EventEmitter(); // 选择处理结果事件回调
  @Output() onSave: EventEmitter<any> = new EventEmitter(); // 保存事件回调
  @Output() onSubmit: EventEmitter<any> = new EventEmitter(); // 提交事件回调
  @Output() onRevoke: EventEmitter<any> = new EventEmitter(); // 撤回事件回调
  @Output() onEndProcess: EventEmitter<any> = new EventEmitter(); // 终止流程回调
  @Output() onInitWfState: EventEmitter<any> = new EventEmitter(); // 初始化状态成功

  /**
   *
   * 按扭操作loading状态
   * @type {*}
   * @memberof WorkflowFormComponent
   */
  loadingStatus: any = {
    saving: false,
    submitting: false
  };

  /**
   *
   * 按钮显示状态
   * @type {*}
   * @memberof WorkflowFormComponent
   */
  wfBtnVisible: any = {
    wfSave: false, // 保存
    wfSubmit: false, // 提交
    wfTransmit: false, // 转办
    wfReject: false, // 驳回
    wfRevoke: false, // 撤回
    wfJump: false, // 跳转
    wfEndProcess: false, // 终止
    wfAddSign: false // 加签
  };

  constructor(
    public modalService: NzModalService,
    public modalHelper: ModalHelper,
    public message: NzMessageService,
    public http: HttpClient,
    public workflowService: WorkflowService,
    private fb: FormBuilder,
    private cacheService: CacheService,
    private menuService: MenuService,
  ) {
  }

  ngOnInit(): void {
    this.createGroup();
    this.taskId = this.curTaskId;
  }

  ngAfterViewInit(): void {
    if (!this.businessId) {
      this.viewInit();
    }
  }

  /**
   * 监听属性变化
   *
   * @param {SimpleChanges} changes
   * @memberof WorkflowFormComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const changedProp = changes[propName];
        const value = changedProp.currentValue;
        if (propName === 'businessId') {
          if (value) this.viewInit();
        } else if (propName === 'auditResult') {
          if (value) {
            setTimeout(() => {
              this.auditResult = changedProp.currentValue + '';
              if (this.wfForm) {
                this.wfForm.get('auditResult').setValue(this.auditResult);
                this.getAuditerList(this.wfForm.get('nextStepId').value);
              }
            });
          }
        } else if (propName === 'customAuditers') {
          this.customAuditers = [];
          if (value && value.length > 0) {
            setTimeout(() => {
              this.customAuditers = changedProp.currentValue;
              if (this.wfForm) {
                this.getAuditerList(this.wfForm.get('nextStepId').value);
              }
            });
          }
        } else if (propName === 'busiUrl') {
          const menu = this.menuService.getPathByUrl(value);
          if (menu && menu.length > 0) {
            const item = menu.find(x => value === x.moduleUrl);
            if (item) this.moduleId = item.id;
          }
        }
      }
    }
  }
  /**
   * 初始化界面控件
   *
   * @memberof WorkflowFormComponent
   */
  createGroup() {
    this.wfForm = this.fb.group({
      nextStepId: [null],
      nextStepName: [null],
      auditerId: [this.auditerId || null],
      flowName: ['', [Validators.required]],
      flowId: ['', [Validators.required]],
      auditResult: [this.auditResult + '' || '1', [Validators.required]],
      auditIdea: ['同意', [Validators.required, Validators.maxLength(256)]],
    });
  }

  /**
   * 设置转办人员
   *
   * @memberof WorkflowFormComponent
   */
  setTransmitPerson(value: any) {
    this.wfForm.get('auditerId').setValue([value[0].id]);
  }

  setAddSignPerson(value: any) {
    this.wfForm.get('auditerId').setValue([value[0].id]);
  }

  /**
   * 初始化按钮状态 加载流程列表
   *
   * @memberof WorkflowFormComponent
   */

  viewInit(): void {
    this.getFlowList();
  }

  /**
   *
   * 获取流程列表
   * @memberof WorkflowFormComponent
   */
  getFlowList() {
    // const projectId = this.cacheService.getNone<string>('projectId');
    const companyId = this.cacheService.getNone<string>('companyId');
    // const projectId = this.appSessionService.appProjectId;
    // this.workflowService.getFlowList(this.moduleId, projectId, this.businessId || '').subscribe(
    this.workflowService.getFlowList(this.moduleId, companyId, this.businessId || '').subscribe((res) => {
      if (res.body.success) {
        this.flowList = res.body.result;
      }

      this.afterWorklistInit.emit(this.flowList);
      this.getWfState();
    });
  }

  getAddSignFlowList() {
    this.flowList = [];
    this.wfForm.get('flowId').setValue('');
    this.workflowService.getAddSignFlowList(this.moduleId, this.businessId || '').subscribe(
      (res) => {
        this.flowList = res.body.result;
        if (this.flowList.length > 0) {
          this.wfForm.get('flowName').setValue(this.flowList[0].name);
          this.wfForm.get('flowId').setValue(this.flowList[0].id);
        }
      }
    );
  }
  /**
   *根据业务ID获取流程状态
   *
   * @returns
   * @memberof WorkflowFormComponent
   */
  getWfState() {
    this.workflowService.getWfState(this.businessId || '', this.taskId || '').subscribe((res) => {
      if (res.body.success) {
        this.initWfState(res.body.result);
      }
    });
  }


  /**
   * 
   * 初始化流程状态
   * @param {*} state
   * @memberof WorkflowFormComponent
   */
  initWfState(state: any) {
    this.taskId = state.taskId;
    this.processInstanceId = state.processInstanceId;
    this.curLoginPersonId = state.curLoginPersonId;
    if (state.flowId != '') {
      this.wfForm.get('flowId').setValue(state.flowId);
      this.wfForm.get('flowName').setValue(state.flowName);
    }
    else {
      if (this.defaultFlowId) {
        this.wfForm.get('flowId').setValue(this.defaultFlowId);
      }
      else {
        if (this.wfForm.get('flowId').value == '' && this.flowList.length > 0) {
          this.wfForm.get('flowName').setValue(this.flowList[0].name);
          this.wfForm.get('flowId').setValue(this.flowList[0].id);
        }
      }
    }
    
    this.wfBtnVisible = state.wfBtnVisible;

    if (this.isTransmitVisible != undefined) {
      this.wfBtnVisible = Object.assign({}, this.wfBtnVisible, { wfTransmit: this.isTransmitVisible });
    }
    if (this.isAddSignVisible != undefined) {
      this.wfBtnVisible = Object.assign({}, this.wfBtnVisible, { wfAddSign: this.isAddSignVisible });
    }
    this.canAudit = this.checkAuditState();
    this.wfBtnVisible.wfEndProcess = true;
    this.onInitWfState.emit();
  }
  /**
   *
   * 判断意见输入框是否可显示 
   * @memberof WorkflowFormComponent
   */
  checkAuditState(): any {
    return this.wfBtnVisible.wfSend ||
      this.wfBtnVisible.wfSubmit ||
      this.wfBtnVisible.wfTransmit ||
      this.wfBtnVisible.wfReject ||
      //  this.wfBtnVisible.wfRevoke||
      this.wfBtnVisible.wfJump ||
      this.wfBtnVisible.wfEndProcess ||
      this.wfBtnVisible.wfAddSign;
  }
  /**
*
* 处理结果切换时触发事件
* @memberof WorkflowFormComponent
*/
  onResultChanged($event) {
    this.onResultSelected.emit($event);
    this.auditResult = this.wfForm.get('auditResult').value;
    if (this.auditResult === '1') {
      // 同意时默认设置意见为同意
      this.wfForm.get('auditIdea').setValue('同意');
    } else {
      this.wfForm.get('auditIdea').setValue('');
    }
    // if(this.auditResult == '2'){
    //   this.getStepList();
    // }
    if (this.auditResult === '4') {
      // 加签时重新获取加签流程列表
      this.auditerId = [];
      this.getAddSignFlowList();
    } else {
      this.getFlowList();
      // this.getStepList();
    }
  }

  /**
 *
 * 获取步骤列表
 * @memberof WorkflowFormComponent
 */
  getStepList() {
    debugger;
    this.flowList.forEach((ele) => {
      if (ele.id === this.wfForm.get('flowId').value) {
        this.wfForm.get('flowName').setValue(ele.name);
      }
    });
    this.wfForm.get('nextStepId').setValue(null);
    this.wfForm.get('nextStepName').setValue(null);
    switch (this.auditResult) {
      case '1': // 提交
        this.workflowService.getStepList(this.wfForm.get('flowId').value, this.taskId).subscribe(
          (res) => {
            if (res.body.success) {
              this.stepList = res.body.result || [];
              if (this.stepList.length > 0) {
                this.wfForm.get('nextStepId').setValue(this.stepList[0].id);
                this.wfForm.get('nextStepName').setValue(this.stepList[0].name);
                this.getStepFormProperty();
              }
            } else {
              this.stepList = [];
            }

          }
        );
        break;
      case '2': // 驳回
        this.getRejectStepList(this.taskId);
        break;
      case '4': // 加签
        this.workflowService.getAddSignStepList(this.wfForm.get('flowId').value, '').subscribe(
          (res) => {
            if (res.body.success) {
              this.stepList = res.body.result || [];
              if (this.stepList.length > 0) {
                this.wfForm.get('nextStepId').setValue(this.stepList[0].id);
                this.getStepFormProperty();
              }
            } else {
              this.stepList = [];
            }
          }
        );
        break;
      default:
        break;
    }
  }
  getStepFormProperty(flowId?: string, stepId?: string) {
    if (!flowId) {
      flowId = this.wfForm.get('flowId').value;
    }
    if (!stepId) {
      stepId = this.wfForm.get('nextStepId').value;
    }
    this.workflowService.getStepFormProperty2(flowId, stepId).subscribe(
      // this.workflowService.getStepFormProperty(this.wfForm.get('flowId').value, this.wfForm.get('nextStepId').value).subscribe(
      (res) => {
        if (res.body.success) {
          this.stepFormProperty = res.body.result;
          this.stepFormPropertyChange.emit(this.stepFormProperty);
        }
      }
    );
  }

  /**
   * 获取驳回步骤列表
   *
   * @memberof WorkflowButtonsComponent
   */
  getRejectStepList(curTaskId: string) {
    this.workflowService.getRejectStepList(curTaskId).subscribe(
      (res) => {
        if (res.body.success) {
          this.stepList = res.body.result || [];
          if (this.stepList.length > 0) {

            this.wfForm.get('nextStepId').setValue(this.stepList[0].id);
            this.getStepFormProperty();
          }
        } else {
          this.stepList = [];
        }
      }
    );
  }

  /**
    * 获取步骤办理人员列表
    * 提交按步骤ID查人员
    * 驳回时如果是发起步骤 取流程发起人 其他情况按步骤ID查人员
    *
    * @memberof WorkflowFormComponent
    */
  getAuditerList(stepId: string) {
    this.auditerId = [];
    if (stepId === '' || stepId == null) return;
    if (this.wfForm.get('nextStepName').value !== this.getStepName(stepId)) {
      this.getStepFormProperty();
      this.wfForm.get('nextStepName').setValue(this.getStepName(stepId));
    }
    if (this.auditResult === '1') {
      if (this.customAuditers.length > 0) {
        this.auditerList = this.customAuditers;
      } else {
        this.workflowService.getAuditerList(this.wfForm.get('flowId').value, stepId).subscribe((res) => {
          if (res.body.success) {
            // 避免异步响应慢覆盖更新的值customAuditers
            if (this.customAuditers.length > 0) {
              this.auditerList = this.customAuditers;
            } else {
              this.auditerList = res.body.result || [];
            }
            if(this.auditerList != null && this.auditerList.length == 1){
              this.wfForm.get('auditerId').setValue([this.auditerList[0].id]);
            }
          } else {
            this.auditerList = [];
          }
        }
        );
      }
    } else {
      if (this.customAuditers.length > 0) {
        this.auditerList = this.customAuditers;
      } else {
        if (this.getStepType(stepId) === 'StartUserTask') {
          this.workflowService.getProcessInstanceStarter(this.taskId).subscribe(
            (res) => {
              if (res.body.success) {
                // 避免异步响应慢覆盖更新的值customAuditers
                if (this.customAuditers.length > 0) {
                  this.auditerList = this.customAuditers;
                } else {
                  this.auditerList = res.body.result || [];
                }
                
                if(this.auditerList != null && this.auditerList.length == 1){
                  this.wfForm.get('auditerId').setValue([this.auditerList[0].id]);
                }
              } else {
                this.auditerList = [];
              }
            }
          );
        } else {
          this.workflowService.getRejectAuditerList(this.wfForm.get('flowId').value, stepId, this.businessId, this.taskId).subscribe(
            (res) => {
              if (res.body.success) {
                // 避免异步响应慢覆盖更新的值customAuditers
                if (this.customAuditers.length > 0) {
                  this.auditerList = this.customAuditers;
                } else {
                  this.auditerList = res.body.result || [];
                }
                if(this.auditerList != null && this.auditerList.length == 1){
                  this.wfForm.get('auditerId').setValue([this.auditerList[0].id]);
                }
              } else {
                this.auditerList = [];
              }
            }
          );
        }
      }
    }

    if (this.auditerId) {
      this.wfForm.get('auditerId').setValue(this.auditerId);
    } else {
      this.wfForm.get('auditerId').setValue(null);
    }
  }

  /**
   * 表单验证前设置控件状态
   *
   * @memberof WorkflowFormComponent
   */
  prepareForCheck() {
    for (const i in this.busiForm.controls) {
      this.busiForm.controls[i].markAsDirty();
      this.busiForm.controls[i].updateValueAndValidity();
    }
    for (const i in this.wfForm.controls) {
      this.wfForm.controls[i].markAsDirty();
      this.wfForm.controls[i].updateValueAndValidity();
    }
  }

  /**
   * 获取步骤类型
   *
   * @memberof WorkflowFormComponent
   */
  getStepType(stepId: string) {
    let stepType = '';
    if (!stepId) return stepType;
    this.stepList.forEach(element => {
      if (element.id === stepId) {
        stepType = element.stepType;
      }
    });
    return stepType;
  }

  /**
   * 获取流程类型
   *
   * @memberof WorkflowFormComponent
   */
  getProcessType(stepId: string) {
    let processType = '';
    if (!stepId) return processType;
    this.stepList.forEach(element => {
      if (element.id === stepId) {
        processType = element.processType;
      }
    });
    return processType;
  }

  /**
    * 获取步骤名称
    *
    * @memberof WorkflowFormComponent
    */
  getStepName(stepId: string) {
    let stepName = '';
    if (!stepId) return stepName;
    this.stepList.forEach(element => {
      if (element.id === stepId) {
        stepName = element.name;
      }
    });
    return stepName;
  }

  /**
   * 判断是否是多实例步骤
   *
   * @memberof WorkflowFormComponent
   */
  isMultiInstanceStep(stepId: string) {
    let isMultiInstanceStep = false;
    if (!stepId) return isMultiInstanceStep;
    this.stepList.forEach(element => {
      if (element.id === stepId) {
        isMultiInstanceStep = element.multiInstanceStep;
      }
    });
    return isMultiInstanceStep;
  }

  /**
  * 保存事件
  *
  * @memberof WorkflowFormComponent
  */
  save() {
    this.onSave.emit(this.wfForm);
  }

  /**
  * 提交事件
  *
  * @memberof WorkflowFormComponent
  */
  submit() {
    this.prepareForCheck();
    if (this.busiForm.invalid) return;
    if (this.wfForm.invalid) return;
    if (this.wfForm.controls.auditResult.value !== '3') {
      if (!this.wfForm.get('nextStepId').value || this.wfForm.get('nextStepId').value.length == 0) {
        this.modalService.error({
          nzTitle: '请选择下一处理环节！'
        });
        return;
      }
    }

    if ((this.getStepType(this.wfForm.controls.nextStepId.value) !== 'End'
      && this.getProcessType(this.wfForm.controls.nextStepId.value) === 'MainProcess') ||
      this.getProcessType(this.wfForm.controls.nextStepId.value) === 'SubProcess'
    ) {
      if (!this.wfForm.get('auditerId').value || this.wfForm.get('auditerId').value.length == 0) {
        this.modalService.error({
          nzTitle: '请填写下一环节处理人！'
        });
        return;
      }

      // tslint:disable-next-line:max-line-length
      ////20230422注释
      // if ((!(this.wfForm.get('auditerId').value.constructor === String && typeof this.wfForm.get('auditerId').value === 'string')) && this.wfForm.get('auditerId').value.length > 0) {
      //   let  auditerId0 = [];
      //   auditerId0 = this.wfForm.get('auditerId').value;
      //   this.wfForm.get('auditerId').setValue([auditerId0[0]]);
      // }

    }
 

    if (!this.isMultiInstanceStep(this.wfForm.controls.nextStepId.value)) {
      // tslint:disable-next-line:max-line-length
      if ((this.wfForm.get('auditerId').value != null) && (this.wfForm.get('auditerId').value.constructor === String) && (typeof this.wfForm.get('auditerId').value === 'string')) {
        const auditerId = this.wfForm.get('auditerId').value;
        this.wfForm.get('auditerId').setValue([auditerId]);
      }else if((this.wfForm.get('auditerId').value != null) && (this.wfForm.get('auditerId').value.constructor === Array) && (typeof this.wfForm.get('auditerId').value === 'object')) {
        const auditerId = this.wfForm.get('auditerId').value;
        this.wfForm.get('auditerId').setValue([auditerId[0]]);
      }
      if(this.getStepType(this.wfForm.controls.nextStepId.value) === 'End') {
        this.wfForm.get('auditerId').setValue([]);
      }
      if (this.wfForm.get('auditerId').value && this.wfForm.get('auditerId').value.length > 1) {
        this.modalService.error({
          nzTitle: '只能选择1个主办人员！'
        });
        return;
      }
    }else{
     
      if ((this.wfForm.get('auditerId').value != null) && (this.wfForm.get('auditerId').value.constructor === String) && (typeof this.wfForm.get('auditerId').value === 'string')) {
        const auditerId = this.wfForm.get('auditerId').value;
        this.wfForm.get('auditerId').setValue([auditerId]);
      }else if((this.wfForm.get('auditerId').value != null) && (this.wfForm.get('auditerId').value.constructor === Array) && (typeof this.wfForm.get('auditerId').value === 'object')) {
        const auditerId = this.wfForm.get('auditerId').value;
        this.wfForm.get('auditerId').setValue(auditerId);
      }
      if(this.getStepType(this.wfForm.controls.nextStepId.value) === 'End') {
        this.wfForm.get('auditerId').setValue([]);
      }
    }

    var busiFormValues = {};////// 为解决this.busiForm.value FormControl 设置为disabled后无法获取到该值
    for(const key in this.busiForm.controls){
      busiFormValues[key] = this.busiForm.controls[key].value;
    }

    const wfParam = Object.assign({}, this.wfForm.value,
      //{ 'busiFormData': JSON.stringify(this.busiForm.value) },
      { 'busiFormData': JSON.stringify(busiFormValues) },
      {
        'taskId': this.taskId,
        'processInstanceId': this.processInstanceId,
        'busiUrl': this.busiUrl,
        'moduleId': this.moduleId,
        curLoginPersonId: this.curLoginPersonId,
        'auditType': 'Audit'
      }
    );
    
    //const entity = Object.assign({}, this.busiForm.value, wfParam);
    const entity = Object.assign({}, busiFormValues, wfParam);

    this.onSubmit.emit(entity);
  }

  /**
   * 撤回
   */
  revoke() {
    var busiFormValues = {};////// 为解决this.busiForm.value FormControl 设置为disabled后无法获取到该值
    for(const key in this.busiForm.controls){
      busiFormValues[key] = this.busiForm.controls[key].value;
    }

    const wfParam = Object.assign({}, this.wfForm.value,
      { 'busiFormData': JSON.stringify(busiFormValues) },
      {
        'taskId': this.taskId,
        'processInstanceId': this.processInstanceId,
        'busiUrl': this.busiUrl,
        'moduleId': this.moduleId,
        curLoginPersonId: this.curLoginPersonId,
        'auditType': 'Audit'
      }
    );

    const entity = Object.assign({}, busiFormValues, wfParam);
    this.modalService.confirm({
      nzTitle: '确定要撤回吗?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.onRevoke.emit(entity);
      },
      nzCancelText: '否',
    });
  }


  /**
   * 终止流程
   * @returns 
   */
  endProcess() {
    if (!this.wfForm.get('auditIdea').value) {
      this.modalService.error({
        nzTitle: '请输入处理意见'
      });
      return;
    }

    const wfParam = {
      ...this.wfForm.value,
      busiFormData: JSON.stringify(this.busiForm.value),
      taskId: this.taskId,
      processInstanceId: this.processInstanceId,
      busiUrl: this.busiUrl,
      moduleId: this.moduleId,
      curLoginPersonId: this.curLoginPersonId,
      auditType: 'Audit'
    };
    const entity = {
      ...this.busiForm.value,
      ...wfParam
    };
    this.modalService.confirm({
      nzTitle: '确定需要终止流程吗?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.onEndProcess.emit(entity);
      },
      nzCancelText: '否',
    });
  }

  /**
    * 清空下一步骤可选人员列表
    *
    * @memberof WorkflowFormComponent
    */
  clearAuditerList() {
    this.auditerList = [];
    this.wfForm.get('auditerId').setValue(null);
    this.auditerCount = 0;
  }

  // 去重
  distinct(a, b) {
    const arr = a.concat(b);
    const result = [];
    const obj = {};
    for (const i of arr) {
      if (!obj[i]) {
        result.push(i);
        obj[i] = 1;
      }
    }
    return result;
  }
}
