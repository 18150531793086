import { Component, OnInit, Input, Output, forwardRef, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TeacherService } from '../../../routes/business/edu/teacher/teacher.service';
import { Teacher } from '../../../routes/business/edu/teacher/teacher.model';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'ths-teacher-select',
    templateUrl: './teacherSelector.component.html',
    providers: [
        TeacherService
    ],
})
// tslint:disable-next-line: component-class-suffix
export class ThsTeacherSelect implements OnInit {
    /**
     * 是否显示搜索框
     */
    @Input() nzShowSearch = true;

    /**
     * 编辑时显示的教师的id
     */
    @Input() id = '';

    /**
     * 是否必选 -- 显示红色标识
     */
    @Input() isRequired = false;

    /**
     * title
    */
    @Input() nzTitle = '选择老师';

    /**
     * 提示文本
     */
    @Input() nzPlaceHolder = '请选择老师';

     /**
     * label 宽度
     */
    @Input() nzLabelSpan = 4;


     /**
     *  input宽度
     */
    @Input() nzInputSpan = 8;

    /**
     * 完成选中回调事件
     */
    @Output() selectFinish: EventEmitter<{}> = new EventEmitter<{}>();


    /**
     * 使用  <ths-teacher-select  [isRequired]='true'  (selectFinish) = "selectFinish($event)" > </ths-teacher-select>
     */


    public page = 0;
    public size = 10;
    public total: number;
    public list: Teacher[] = [];
    public totalCallNo = 0;
    public selectedRows: SimpleTableData[] = [];
    // HTML模板里面<simple-table>的选择器必须为st

     @ViewChild('st') st: SimpleTableComponent;

    queryParams = {
        // 查询搜索框的字段要预置
        'personName.contains': '',
        'mobile.contains': '',
        'college.contains': '',
        // sort固定
     //   'sort': ['orderNum,asc']
      };

    // 模态框显示
    modalIsVisible = false;

    columns: SimpleTableColumn[] = [
        { title: '', index: 'id', type: 'checkbox' },
        { title: '姓名', index: 'name' , width: '12%'},
        { title: '身份证号', index: 'idCard', width: '15%' },
        { title: '学校', index: 'college', width: '15%'},
        { title: '院系', index: 'department', width: '8%'},
        { title: '工号', index: 'jobNum', width: '7%'},
        { title: '所授专业', index: 'profession', width: '7%'},
        { title: '学生数量', index: 'studentNum', width: '7%'},
        { title: '点赞数量', index: 'likePoints', width: '7%'},
        { title: '参与讨论数', index: 'discussion', width: '7%'},
        {
          title: '操作', className: 'text-center',
          buttons: [
            { text: '选择', click: (item: any) => this.selection(item), },
          ],
        }
      ];


    validateForm: FormGroup;

    constructor(
        private teacherService: TeacherService,
        public msg: NzMessageService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.validateForm = this.fb.group({
          teacherName: [null],
        });
        this.getInitData();
        this.getDataList();
      }


      public getInitData() {
         if (this.id !== null && this.id !== '' ) {
            this.teacherService.find(this.id).subscribe(res => {
              this.validateForm = this.fb.group({
                teacherName: [res.body.name],
            });
          });
        }
      }

      /**
      * 获取数据列表
      * @param {string} url
      */
      public getDataList(isReset?: boolean) {
        const copyParams = {};
        const q = this.queryParams;
        if (isReset === true) {
          this.st.reset();
          this.page = 0;
          Object.keys(q).forEach(function (key) {
            q[key] = '';
          });
          // q['sort'] = ['orderNum,asc'];
        } else {
          Object.keys(q).forEach(function (key) {
            if (q[key] !== '' &&　q[key] !== null) {
              copyParams[key] = q[key];
            }
          });
        }
        copyParams['page'] = this.page;
        copyParams['size'] = this.size;
        this.teacherService.query(copyParams)
          .subscribe((res: any) => {
          this.list = res.body;
          this.total = res.headers.get('X-Total-Count');
          });
      }

    // 选中事件
    selection(item: any) {
        this.validateForm = this.fb.group({
            teacherName: item.name,
          });
        this.selectFinish.emit(item);
        this.handleCancel();
    }

    openSelectModal() {
         this.modalIsVisible = true;
    }

    handleCancel() {
        this.modalIsVisible = false;
    }

    handleOk() {
        if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据！');
        if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据！');
        this.selection(this.selectedRows[0]);
        this.modalIsVisible = false;
    }

      /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }

}
