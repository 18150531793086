import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn, XlsxService} from '@delon/abc';
//  import { AdminUsersViewComponent } from './view/view.component';
// import { EduCourseEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { SoftwareDownload } from './download.model';
import { SoftwareDownloadService } from './download.service';
import { deepCopy } from '@delon/util';

@Component({
  selector: 'app-software-download',
  templateUrl: './download.component.html',
})
export class EduSoftwareDownloadComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: SoftwareDownload[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  // 查询参数
  queryParams = {
    'softwareTypeName': '',
    'softwareSubTypeName': '',
    'softwareName': '',
    'personName': '',
    'mobile': '',
    'email': '',
  };

  exportColumns = [['软件类型', '软件类别', '软件名称', '用户类型', '用户姓名' ,'学校', '邮箱', '手机', '下载时间']];

  pageSizes= [10,20,30,40,50,100,250,500,750,1000]

  columns: SimpleTableColumn[] = [
    { title: '软件类型', index: 'softwareTypeName' , width: '8%'},
    { title: '软件类别', index: 'softwareSubTypeName',  width: '7%' },
    { title: '软件名称', index: 'softwareName',  width: '7%',},
    { title: '用户类型', index: 'entityType', className: 'text-center', width: '7%',
    format: (item: any) => {
      if (item.entityType === '0') {
        return '学生';
      } else if (item.entityType === '1') {
        return '老师';
      } else {
        return '其他';
      }
    }},
    { title: '学校', index: 'college',width: '7%' },
    { title: '用户姓名', index: 'personName',width: '7%' },
    { title: '邮箱', index: 'email',width: '7%' },
    { title: '手机', index: 'mobile',width: '7%' },
    { title: '下载时间', index: 'downloadTime',className: 'text-center', width: '10%'},
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public xlsx: XlsxService,
    public softwareDownloadService: SoftwareDownloadService
  ) {}

  ngOnInit() {
    this.getDataList();
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.msg.remove();
    this.msg.loading('加载中');
    const copyParams = this.getParams(isReset);
    if (Object.keys(copyParams).length > 3 && this.page > 0) {
      this.page = 0;
    } 
    copyParams['size'] = this.size;
    copyParams['page'] = this.page;
    this.doGetDataList(copyParams);
  }

  private getParams(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['size'] = this.size;
    copyParams['page'] = this.page;
    return copyParams;
  }

  private doGetDataList(param: any) {
    this.softwareDownloadService.queryList(param)
      .subscribe((res: any) => {
      this.msg.remove();
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      });
  }


  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.msg.remove();
    this.msg.loading('加载中');
    this.page = event.pi - 1;
    this.size = event.ps;
    const copyParams = this.getParams();
    this.doGetDataList(copyParams);
  }

  public export() {
    const data = deepCopy(this.exportColumns);
    this.list.forEach(i =>
      data.push([
      i.softwareTypeName,
      i.softwareSubTypeName,
      i.softwareName,
      i.entityType === '0' ? '学生' : (i.entityType === '1' ? '老师' : '其他'),
      i.personName,
      i.college,
      i.email,
      i.mobile,
      i.downloadTime,
    ]));
    this.xlsx.export({
      sheets: [
        {
          data,
          name: 'sheet name',
        },
      ],
      filename: '下载导出.xlsx'
    });
  }

  // /**
  // * 过滤器变动事件 支持多选过滤
  // * @param
  // */
  // filterChange(event) {
  //   let i = 0;
  //   const _value = [];
  //   const _type = event.filterMultiple ? 'in' : 'equals';
  //   event.filters.forEach(element => {
  //     if (element.checked) {
  //       _value[i++] = element.value;
  //     }
  //   });
  //   this.queryParams[event.indexKey + '.' + _type] = _value;
  //   this.getDataList();
  // }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  // */
  // edit() {
  //   if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
  //   if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
  //   const tmp = this.formatIsOk(this.selectedRows[0]);
  //   this.modal
  //     .static(EduCourseEditComponent, { record: tmp })
  //     //      .pipe(filter(w => w === true))
  //     .subscribe(() => this.getDataList());
  // }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

}
