import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimInfoAdvertisement } from '../bim-info-advertisement.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimInfoAdvertisementService } from '../bim-info-advertisement.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-bim-bimInfo-advertisement-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoAdvertisementEditComponent implements OnInit, OnDestroy {


  BimTitle = '上传广告图片';
  // 要更新附件外键的集合
  hasToUpdate = false;
  toUpdateFkFileList = [];
  extField1 = {'extField1.equals': 'BIM_ADVERTISE'};
  public addressArr = {'中部': 'center', '底部': 'bottom'};

  record: any = {};
  bimInfoId:string;
   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';

  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;


  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimInfoAdvertisementService: BimInfoAdvertisementService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId:[this.bimInfoId, []],
      userDefine1: [null, []],
      name: [null, [Validators.required]],
      address: [null, [Validators.required]],
      url: [null, [Validators.required]],
      userDefine2: [null, []],
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
   this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
   this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';

  }

  ngOnInit() {
    if (!this.isAdd) {
      this.fileId = this.record.id;
      this.loadData();
    } else {
      this.fileId =  this.entityId;
    }
  }




  loadData(): void {
 
       this.form.patchValue(this.record);
  }

  // 保存
  submit() {
    debugger; 
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查BIM大赛赛项信息是否完整');
      return;
    }
    if (!isNullOrUndefined(this.bimInfoId)) {
      param['bimInfoId']  = this.bimInfoId;
    }
    if (this.isAdd === true) {
      this.addDataInfo(param);
    } else {

      this.modifyDataInfo(param);
    }

  }


  modifyDataInfo(param: any) {
    this.bimInfoAdvertisementService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.hasToUpdate = true;
        this.msg.info('修改成功');
        this.close(true);
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimInfoAdvertisementService.create(param).subscribe(res => {
      this.record = res.body;
      if (this.record) {
        this.hasToUpdate = true;
        this.msg.info('保存成功');
        this.close(true);
      } else {
        this.msg.error('保存失败');
      }
    });
  }

  /**
   * 上传成功回调
   * @param file
   */
  onSuccessful(file: any) {
    const thisSuccessFile = file.successFile;
    if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
      thisSuccessFile.forEach(item => {
        this.toUpdateFkFileList.push(item);
        this.form.controls['userDefine2'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
      });
    }
  }

   /**
    * 删除成功回调
    * @param file
    */
   onDeleteFile(file: any) {
     if (file.file) {
       this.toUpdateFkFileList = this.toUpdateFkFileList.filter(d => d.uid !== file.file.uid);
     }
     this.form.controls['userDefine2'].setValue(null);
   }

   // 关闭事件
   close(type?) {
     if (this.hasToUpdate  && '' !== this.record.id) {
       let isChangeData = false;
       if (this.toUpdateFkFileList.length > 0) {
        isChangeData = true;
        for (const i of Object.keys(this.toUpdateFkFileList)) {
          this.fileUploadService.updateFkBatch(this.toUpdateFkFileList[i].response.fileFk, this.record.id)
            .subscribe((res: any) => {
               this.nzModalRef.destroy(type);
             }, (res: HttpResponse<any>) => this.msg.create('error', 'Banner图片上传失败'));
        }
       }
       if (!isChangeData) {
         this.nzModalRef.destroy(type);
       }
     } else {
       this.nzModalRef.destroy(type);
     }
   }

   ngOnDestroy(): void {
    if (null !== this.toUpdateFkFileList && this.toUpdateFkFileList.length > 0 && !this.hasToUpdate) {
      const idArray = new Array();
      this.toUpdateFkFileList.forEach((d) => {
        idArray.push(isNullOrUndefined(d.id) ?  d.response.id : d.id);
      });
      this.fileUploadService.delete(idArray).subscribe((res: any) => {
        console.log(idArray);
      });
    }
   }
}
