import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { EduCourseEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CourseService } from './course.service';
import { Course } from './course.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import {EduCourseSummerManComponent  } from './courseSummer/courseSummerMan.component';
import { CourseAppointStuManComponent } from './appointStudent/courseAppointStuMan.component';
@Component({
  selector: 'app-edu-course',
  templateUrl: './course.component.html',
})
export class EduCourseComponent implements OnInit {
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: Course[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'courseName.contains': '',
    'teacherName.contains': '',
    // sort固定
     sort: ['createdDate,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '课程名称', index: 'courseName' , width: '12%'},
      // { title: '截图', index: 'imgurl' },
    { title: '课程分类', index: 'classifyName', width: '8%' },
    { title: '课程类别', index: 'courseType', width: '8%'},
    { title: '教师', index: 'teacherName', width: '5%'},
    { title: '总学时', index: 'totalHours', width: '5%'},
    { title: '开始时间', index: 'startDate', width: '7%'},
    { title: '结束时间', index: 'endDate', width: '7%'},
    {
      title: '课程状态', index: 'courseStatus', className: 'text-center', width: '7%',
      format: (item: any) => {
        if (item.courseStatus === '1') {
          return '正在进行';
        } else if (item.courseStatus === '2') {
          return '即将结束';
        } else {
          return '已结束';
        }
      },
      filters: [
        { text: '正在进行', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        // { text: '即将结束', value: 2} , //此处值对应数据库的值，即domain对象的值
        { text: '已结束', value: 3}  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否收费', index: 'isCharge', className: 'text-center', width: '7%',
      format: (item: any) => {
        if (item.isCharge === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否发布', index: 'isPublish', className: 'text-center', width: '7%',
      format: (item: any) => {
        if (item.isPublish === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否指定学生', index: 'isLimit', className: 'text-center', width: '7%',
      format: (item: any) => {
        if (item.isLimit === '1') {
          return '是';
        } else {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '操作', className: 'text-center',
      buttons: [
        // { acl: true, text: '查看', type: 'modal', component: AdminUsersEditComponent,
        // paramName: 'record', click: () => this.getDataList() },
        { text: '编辑', type: 'modal', component: EduCourseEditComponent, paramName: 'record', click: () => this.getDataList(), },
        { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item); } },
        { text: '课程概述', type: 'modal', component: EduCourseSummerManComponent, paramName: 'record', click: () => this.getDataList(), },
        { text: '指定学生', type: 'modal', component: CourseAppointStuManComponent,
        size: 1000 , paramName: 'record', click: () => this.getDataList(), },
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public courseService: CourseService,
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    // tslint:disable-next-line:prefer-const
    let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    /* if(i>0){
      this.page=0;
    } */
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.courseService.query(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');

      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(EduCourseEditComponent, { record: { id: null } })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
    const tmp = this.formatIsOk(this.selectedRows[0]);
    this.modal
      .static(EduCourseEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  /**
  * 删除
  */
  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.courseService.delete(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.courseService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


}
