import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Project } from './../projectReport/projectReport.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectCockpitService extends ThsBaseService<Project> {
    
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.IucceServe + `/iucce-project-cockpits`);
    }
    
    /** GET: 获取申报指南汇总数据 */
    getYearGoalsTotal (params:any): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.resourceUrl+`/getYearGoalsTotal`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }
    
    /** GET: 获取项目汇总数据（已供资金、软硬件价值、成单商机数量、成单金额、立项/结项/未结项数据统计） */
    getProjectInfoTotal (params:any): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.resourceUrl+`/getProjectInfoTotal`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

     
    /** GET: 按批次获取项目汇总数据（已供资金、软硬件价值、成单商机数量、成单金额、立项/结项/未结项数据统计） */
    getProjectInfoTotalByBatch (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any>(this.resourceUrl+`/getProjectInfoTotalByBatch`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 按区域获取项目汇总数据（已供资金、软硬件价值、成单商机数量、成单金额、立项/结项/未结项数据统计） */
    getProjectInfoTotalByRegion (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any>(this.resourceUrl+`/getProjectInfoTotalByRegion`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 按项目类别获取项目立项数据统计） */
    getProjectInfoTotalByProjectType (params:any): Observable<HttpResponse<any[]>> {
        return this.http.get<any>(this.resourceUrl+`/getProjectInfoTotalByProjectType`, {params: params ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

    /** GET: 导出项目明细表 */
    exportProjectDetailExcel(params:any,obj) {
        this.http.get(this.resourceUrl+`/exportProjectDetailExcel`, {
            params: params,
            headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
            observe: 'response',
            responseType: 'blob',
            withCredentials: true,
          }).subscribe(
            data => {
              let blob = new Blob([data.body]);
              let objectUrl = URL.createObjectURL(blob);
              let a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display:none');
              a.setAttribute('href', objectUrl);
              a.setAttribute('download', (params.fileName ? params.fileName : '数据导出') + '.xlsx');
              a.click();
              URL.revokeObjectURL(objectUrl);
              obj.exportingPrjDetail = false;
            },
            error => {
              console.error('导出项目明细表失败：', error);
              obj.exportingPrjDetail = false;
            },
          );
    }
    
   /** GET: 导出项目明细表 */
    exportProjectTotalExcel(params:any,obj) {
        this.http.get(this.resourceUrl+`/exportProjectTotalExcel`, {
            params: params,
            headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
            observe: 'response',
            responseType: 'blob',
            withCredentials: true,
          }).subscribe(
            data => {
              let blob = new Blob([data.body]);
              let objectUrl = URL.createObjectURL(blob);
              let a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display:none');
              a.setAttribute('href', objectUrl);
              a.setAttribute('download', (params.fileName ? params.fileName : '数据导出') + '.xlsx');
              a.click();
              URL.revokeObjectURL(objectUrl);
              obj.exportingPrjTotal = false;
            },
            error => {
              console.error('导出项目汇总表失败：', error);
              obj.exportingPrjTotal = false;
            },
          );
    }
}

