import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CourseCatalog } from './courseCatalog.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Course } from '../course/course.model';

@Injectable({ providedIn: 'root' })
export class CourseCatalogService extends ThsBaseService<CourseCatalog> {
  private courseUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-course-catalogs`);
      this.courseUrl = httpAddressService.EduServe + '/edu-courses';
  }

  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}

  queryCourseCatalog(queryParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllCourseCatalogs` ;
    return this.http.get<HttpResponse<any>>(url, { params: queryParams, observe: 'response' });
  }

  getcourseClassify(queryParams?: any) {
     const courseClassify = [];
     this.http.get<Course[]>(this.courseUrl, { params: queryParams, observe: 'response' }) .subscribe(res => {
       const list =  res.body;
       if (list && list.length) {
        list.forEach(element => {
          courseClassify.push({ label: element.courseName, value: element.id });
        });
      }
       console.log(courseClassify);
     });
     return courseClassify;
   }

}

