import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { TextValue } from './ucommon.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class UcommonService {


  /**去掉空对象 */
  delNullObj(List: any): any {
    const zhangstr = [];
    for (let k = 0; k < List.length; k++) {
      const obj = List[k];
      if (obj != null)
        zhangstr.push(obj);
    }

    return zhangstr;
  }

  /**
   * 判断字符串是否json对象字符串
   * @param str
   */
  isStrJSON(str): boolean {
    if (typeof str === 'string') {
      try {
        const obj = JSON.parse(str);
        if (typeof obj === 'object' && obj) {
          return true;
        } else {
          return false;
        }

      } catch (e) {
        return false;
      }
    } else {
      return false;
    }

  }

  /**
   * 判断是否json对象
   * @param str
   */
  isJSON(obj): boolean {
    if (typeof obj === 'object' && obj) {
      return true;
    } else {
      return false;
    }

  }


  /**
   * 特殊字符转换位html标签处理
   * @param obj
   */
  ReplaceStr(obj: string) {
    if (obj != null) {
      obj = obj.replace(/\n/g, '\<br\/\>');
      obj = obj.replace(/\"/g, '&quot;');
      obj = obj.replace(/\\/g, '&#92;');


      obj = obj.replace(/\//g, '&#47;');
      obj = obj.replace(/\^/g, '&#94;');
      obj = obj.replace(/\{/g, '&#123;');
      obj = obj.replace(/\}/g, '&#125;');
      obj = obj.replace(/\+/g, '&#43;');
      obj = obj.replace(/\(/g, '&#40;');
      obj = obj.replace(/\)/g, '&#41;');
    }
    return obj;
  }

  /**
   * html标签转换位转义字符处理
   * @param obj
   */
  ReplaceHtml(obj: string) {
    // if (obj === undefined) {
    //   return;
    // }
    obj = obj.replace(/\<br\/\>/g, '\n');
    obj = obj.replace(/&quot;/g, '"');
    obj = obj.replace(/&#92;/g, '\\');

    obj = obj.replace(/&#47;/g, '/');
    obj = obj.replace(/&#94;/g, '^');
    obj = obj.replace(/&#123;/g, '{');
    obj = obj.replace(/&#125;/g, '}');
    obj = obj.replace(/&#43;/g, '+');
    obj = obj.replace(/&#40;/g, '(');
    obj = obj.replace(/&#41;/g, ')');

    return obj;
  }


  /**
   * 验证手机号码
   */

  ValidatorsMobile(mobile){
    
    if (!(/^1\d{10}$/.test(mobile))) {
      return false;
    }else{
      return true;
    }
  }

/**
 * email验证
 */

ValidatorsEmail(email){
    
  if (!(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email))) {
    return false;
  }else{
    return true;
  }
}

  // 小数相加的精度问题
  addNum(arg1, arg2) {
    let r1, r2, m, c;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
      const cm = Math.pow(10, c);
      if (r1 > r2) {
        arg1 = Number(arg1.toString().replace('.', ''));
        arg2 = Number(arg2.toString().replace('.', '')) * cm;
      } else {
        arg1 = Number(arg1.toString().replace('.', '')) * cm;
        arg2 = Number(arg2.toString().replace('.', ''));
      }
    } else {
      arg1 = Number(arg1.toString().replace('.', ''));
      arg2 = Number(arg2.toString().replace('.', ''));
    }
    return (arg1 + arg2) / m;
  }

}
