import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-industry-university',
  templateUrl: './industry-university.html',
  styleUrls: ['./Iidustry-university.less']
})
export class IndustryUniversityComponent implements OnInit {

  search = {
    region: '',
    province: '',
    school: '',
    followUp: '',
    projectName: '',
    projectCategory: '',
    applicant: '',
    year: '',
    batch: '',
  };
  isCollapse = true;

  allChecked = false;
  indeterminate = false;
  displayData = [
    {
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      checked: false,
      disabled: false
    },
    {
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      checked: false,
      disabled: false
    },
    {
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      checked: false,
      disabled: false
    },
    {
      name: 'Disabled User',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      checked: false,
      disabled: true
    }
  ];

  /** 新增 */
  isVisible = false;
  isConfirmLoading = false;

  validateForm: FormGroup;

  constructor
    (private fb: FormBuilder) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email]],
      password: [null, [Validators.required]],
      nickname: [null, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      website: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
      agree: [false]
    });
  }

  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    // this.isConfirmLoading = true;
    // setTimeout(() => {
    //   this.isVisible = false;
    //   this.isConfirmLoading = false;
    // }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
