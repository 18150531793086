import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'formatEstablishStatus' })
/**
 * 格式化项目立项状态管道
 */
export class formatEstablishStatusPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (value) {
            case '0':
                return "待立项";
            case '1':
                return '申报书已提交';
            case '2':
                return '申报书审核中';
            case '3':
                return '申报书审核通过';
            case '4':
                return '申报书审核不通过';
            case '-5':
                return '待企业提交协议';
            case '50':
                return '协议书待提交';
            case '5':
                return '协议书审核中';
            case '6':
                //return '协议书审核通过';
                //return '立项通过';
                return '企业审核通过';
            case '7':
                return '协议书审核不通过';
            case '8':
                return '立项通过';
            case '9':
                return '退回修改';
            case '44':
                return '立项不通过';
            default:
                return '待立项';
        }
    }
}