import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { isNullOrUndefined } from 'util';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn } from '@delon/abc';


import { APP_PERMISSIONS } from '@shared/app.permissions';
import { BimWorkCoresService } from '../../bim-work-cores/bim-work-cores.service';
import { WorkManageService } from '../../work-manage/work-manage.service';
import { BimItemsService } from '../../bim-items/bim-items.service';

@Component({
  selector: 'app-work-reply-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less']
})
export class WorkReplyAddComponent implements OnInit {

  public bimEventId = '';
  public bimInfoName = '';
  public bimInfoEventName = '';
  public schoolName = '';

  submitting = false;
  public page = 0;
  public size = 10;
  public total;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: any[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  entityId: string;
  entityName: String;
  accountName: String;
  public items = [];
  public loading = false;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'itemId.equals': null,
    'name.contains': null
    // sort固定
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '作品名称', index: 'name', width: '20%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '团队名称', index: 'teamName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '所属学校', index: 'schoolName', width: '20%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '学校类型', index: 'schoolType', width: '10%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else {
          return item.schoolType;
        }
      }
    },

    { title: '参赛专项', index: 'itemName', width: '20%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '评分', index: 'secCore', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },

  ];


  constructor(
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimItemsService: BimItemsService,
    private workManageService: WorkManageService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;
  }

  ngOnInit() {
    this.loadItems();
    this.getDataList();
  }

  loadItems() {
    this.items = [];
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimEventId, {
      'isDelete.equals': 0,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      const data = res.body;
      data.forEach(e => e['percent'] = 0);
      this.items = data;
    });
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {
      'isReply.equals': '0',
      page: this.page - 1,
      size: this.size,
      'workType.equals': 1,
      sort: ['itemId', 'secCore,desc']
    };
    const q = this.queryParams;
    if (isReset === true) {
      // this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = null;
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['eventId'] = this.bimEventId;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    this.loading = true;
    this.workManageService.getBimWork(copyParams).subscribe((res: any) => {
        this.loading = false;
        this.list = res.body;
        if (this.list.length === 0 && this.page > 0) {
          this.page --;
          this.getDataList();
        }
        this.selectedRows = [];
        this.total = res.headers.get('X-Total-Count');
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
   * 复选框事件
   * @param {SimpleTableData[]} list
   */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }

  // 保存
  submit() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要保存的作品！');
    this.nzModalRef.destroy(this.selectedRows.map(e => e.id));
  }

  Cancel() {
    this.nzModalRef.destroy(null);
  }

  /**
 * ie列表显示null格式化
 */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

}


