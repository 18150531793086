import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'formatContractStatus' })
/**
 * 格式化合同状态管道
 */
export class formatContractStatusPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (value) {
            case '3':
                return "完成";
            case '9':
                return "归档";
        }
    }
}