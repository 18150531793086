import { Component, OnInit,ViewChild, Input,Output ,EventEmitter} from '@angular/core';
import { NzModalService, NzMessageService,NzModalRef} from 'ng-zorro-antd';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { WorkflowService } from '@shared/components/workflow/workflow.service';
import {FormBuilder, FormGroup, Validators,FormControl} from "@angular/forms";
import { OperateTypeEnum } from '@shared/enums/service.enum';

/**
 *工作流流程提交选人组件
 *
 * @export
 * @class WorkflowSubmitComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'ths-workflow-submit',
    templateUrl: './workflowsubmit.component.html'
  })

  export class WorkflowSubmitComponent implements OnInit {
  
    businessId:string="";
    wfEntity:any={}; 
    form:FormGroup;
    stepList:any[]=[];
    auditerList:any[]=[];
    readerList:any[]=[];
    canJumpStep:boolean=false;


    constructor(
      private modal: NzModalRef,
      public modalService:NzModalService,
      public message: NzMessageService,
      public http: HttpClient,
      public workflowService:WorkflowService,
      private fb: FormBuilder
    ) {
     
    }
  
  
    ngOnInit(): void {
      //console.log("flowId:" + this.wfEntity.flowId);
      switch (this.wfEntity.operateType) {
        case OperateTypeEnum.Reject:
          this.getRejectStepList(this.wfEntity.taskId);
          break;
        case OperateTypeEnum.Submit:
          this.getStepList(this.wfEntity.flowId, this.wfEntity.taskId);
          break;
        case OperateTypeEnum.Send:
          this.wfEntity.taskId='';
          this.getStepList(this.wfEntity.flowId, '');
          break;
        default:
          break;
      }
      //todo 控制发送及流程结束前一步骤 不能从步骤列表选择下一步骤
      this.canJumpStep = this.wfEntity.taskId != ''; 
      this.createGroup();
    }
    ngAfterViewInit():void{ 
      
    }
    createGroup() {  
      this.form = this.fb.group({
        nextStepId: [null, [Validators.required]],
        auditerId: [null],
        readerId: [null],
      });
    }

 
    getStepType(stepId:string){
      let stepType='';
      this.stepList.forEach(element => {
        if(element.id==stepId){
          stepType=element.stepType;
        }
      });
      return stepType;
    }
    isMultiInstanceStep(stepId:string){
      let isMultiInstanceStep=false;
      this.stepList.forEach(element => {
        if(element.id==stepId){
          isMultiInstanceStep=element.isMultiInstanceStep;
        }
      });
      return isMultiInstanceStep;
    }
    getStepList(flowId:string,curTaskId:string){
      this.workflowService.getStepList(flowId,curTaskId).subscribe( 
        (res) =>  {
          this.stepList=res.body.result;  
          if(this.stepList.length>0){
            this.form.get("nextStepId").setValue(this.stepList[0].id);
          }
          
        }
      );
    }
    getRejectStepList(curTaskId:string){
      this.workflowService.getRejectStepList(curTaskId).subscribe( 
        (res) =>  {
          this.stepList=res.body.result;  
          if(this.stepList.length>0){
            this.form.get("nextStepId").setValue(this.stepList[0].id);
          }
        }
      );
    }
    getAuditerAndReaderList(stepId:string){
      if (stepId == "") return;
      this.workflowService.getAuditerList(this.wfEntity.flowId,stepId).subscribe(
        (res) => {
          this.auditerList = res.body.result||[];  
        }
      );
      this.workflowService.getReaderList(this.wfEntity.flowId,stepId).subscribe(
        (res) => {
          this.readerList = res.body.result||[];  
        }
      );
    }
    
    
    close(result: any) {
      this.modal.destroy(result);
    }
    confirm(){
      this.prepareForCheck();
      if (this.form.invalid) return;
      if(this.wfEntity.operateType==OperateTypeEnum.Send||this.wfEntity.operateType==OperateTypeEnum.Submit){
        if(this.getStepType(this.form.controls.nextStepId.value)!="EndStep" ){
          if(!this.form.get("auditerId").value||this.form.get("auditerId").value.length==0){
            this.modalService.error({
              nzTitle: '请选择主办人员！'
            });
            return ;
          }
        }
        if(!this.isMultiInstanceStep(this.form.controls.nextStepId.value)){
          if(this.form.get("auditerId").value&&this.form.get("auditerId").value.length>1){
            this.modalService.error({
              nzTitle: '只能选择1个主办人员！'
            });
            return ;
          }
        }
      } 
      this.close(this.form); //返回步骤及主办人;
    }
  
    prepareForCheck(){
      for (const i in this.form.controls) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].updateValueAndValidity();
      }
    }
    
 

}
  