import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
//  import { AdminUsersViewComponent } from './view/view.component';
// import { EduCourseEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { SoftwareAuthService } from './auth.service';
import { SoftwareAuth } from './auth.model';

@Component({
  selector: 'app-software-auth',
  templateUrl: './auth.component.html',
})
export class EduSoftwareAuthComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: SoftwareAuth[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  // 查询参数
  queryParams = {
    'productName': '',
    'productCodeTry': '',
    'serialNumber': '',
    'entityName': '',
    'mobile': '',
    'email': '',
    'sort': 'created_date,desc'
  };


  columns: SimpleTableColumn[] = [
    { title: '产品名称', index: 'productName' , width: '12%'},
    { title: '产品标识码', index: 'productCodeTry', className: 'text-center', width: '7%' },
    { title: '用户姓名', index: 'entityName',  width: '7%',},
    { title: '用户类型', index: 'entityType', className: 'text-center', width: '7%',
    format: (item: any) => {
      if (item.entityType === '0') {
        return '学生';
      } else if (item.entityType === '1') {
        return '老师';
      } else {
        return '其他';
      }
    }},
    { title: '授权码', index: 'serialNumber', className: 'text-center', width: '7%' },
    { title: '手机号', index: 'mobile', className: 'text-center', width: '7%' },
    { title: '邮箱', index: 'email', className: 'text-center', width: '7%' },
    { title: '授权时间（天）', index: 'expirationDays',width: '6%'},
    { title: '申请时间', index: 'createdDate', className: 'text-center', width: '10%'},
    { title: '是否发送邮件', index: 'isSendMail', className: 'text-center', width: '7%',
    format: (item: any) => {
      if (item.isSendMail === '0') {
        return '未发送';
      } else if (item.isSendMail === '1') {
        return '已发送';
      } 
    }},
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public softwareAuthService: SoftwareAuthService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.msg.remove();
    this.msg.loading('加载中');
    const copyParams = this.getParams(isReset);
    this.doGetDataList(copyParams);

  }

  private doGetDataList(copyParams?: any) {
    this.softwareAuthService.queryList(copyParams)
      .subscribe((res: any) => {
      this.msg.remove();
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      });
  }

  private getParams(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['size'] = this.size;
    copyParams['page'] = this.page;
    copyParams['sort'] = 'created_date,desc';
    return copyParams;
  }


  // showDetail(item: any) {
  //   this.modal
  //   .static( EduCoursePayDetailsComponent, { record: item })
  //   .subscribe(() => {
  //     // this.getDataList();
  //   });
  // }


  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.msg.remove();
    this.msg.loading('加载中');
    this.page = event.pi - 1;
    this.size = event.ps;
    const copyParams = this.getParams();
    this.doGetDataList(copyParams);
  }

  // /**
  // * 过滤器变动事件 支持多选过滤
  // * @param
  // */
  // filterChange(event) {
  //   let i = 0;
  //   const _value = [];
  //   const _type = event.filterMultiple ? 'in' : 'equals';
  //   event.filters.forEach(element => {
  //     if (element.checked) {
  //       _value[i++] = element.value;
  //     }
  //   });
  //   this.queryParams[event.indexKey + '.' + _type] = _value;
  //   this.getDataList();
  // }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  // */
  // edit() {
  //   if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
  //   if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
  //   const tmp = this.formatIsOk(this.selectedRows[0]);
  //   this.modal
  //     .static(EduCourseEditComponent, { record: tmp })
  //     //      .pipe(filter(w => w === true))
  //     .subscribe(() => this.getDataList());
  // }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

}
