import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleTableColumn, SimpleTableComponent, SimpleTableData } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { environment } from '@env/environment';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoService } from '../bim-info/bim-info.service';
import { BimScoringRuleService } from './bim-scoring-rule.service';
import { BimScoringRuleItemComponent } from './detail/bim-scoring-rule-item.component';
import { BimScoringRuleEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-bim-scoring-rule',
  templateUrl: './bim-scoring-rule-list.component.html',
  styleUrls: ['./bim-scoring-rule-list.component.less']
})
export class BimScoringRuleListComponent implements OnInit {

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public bimInfoList = [];

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public queryParams = {
    // 查询搜索框的字段要预置
    'itemName.contains': null,
    'bimInfoId.equals': null,
    'isOk.equals': '',
    // sort固定
    sort: ['createTime,desc']
  };

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '大赛赛项', index: 'eventsName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '专项名称', index: 'itemName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '所属赛事', index: 'bimInfoId',
      format: (item: any) => {
        if (isNullOrUndefined(item.bimInfoId)) {
          return '';
        } else {
          const data = this.bimInfoList.filter(e => e.id === item.bimInfoId);
          if (data && data.length > 0) {
            return data[0].name;
          }
          return '';
        }
      }
    },
    {
      title: '是否生效', index: 'isOk', width: '10%',
      format: (item: any) => {
        if (item.isOk === 0) {
          return '否';
        } else if (item.isOk === 1) {
          return '是';
        } else {
          return '';
        }
      }
    },
    {
      title: '评分模板', width: '15%',
      buttons: [
        { text: '查看', click: (item: any) => this.detail(item) },
        { text: '下载', click: (item: any) => this.download(item) }
      ],
    },
    {
      title: '操作', width: '15%',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.remove(item) }
      ],
    }
  ];

  ngOnInit(): void {
    this.initBimInfoList();
  }

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private bimInfoService: BimInfoService,
    private fileUploadService: FileUploadService,
    private bimScoringRuleService: BimScoringRuleService,
  ) { }

  initBimInfoList() {
    this.isloadding = true;
    this.bimInfoService.query({
      'size' : '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
      this.bimInfoList = res.body;
      this.getDataList();
    }, () => this.isloadding = false);
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimScoringRuleService.query(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
  * 新增页面
  */
  add() {
    const dataId = new GuidUtil().toString();
    this.modal.static(BimScoringRuleEditComponent, {
      record: { id: null },
      dataId: dataId,
      bimInfoList: this.bimInfoList
    }).subscribe((res) => {
      console.log(res);
      if (res === 'success') {
        this.getDataList();
      } else {
        this.bimScoringRuleService.deleteByScoringRuleId(dataId).subscribe(() => {
          this.fileUploadService.deleteAllByFk(dataId).subscribe();
        });
      }
    });
  }

  /**
   * 编辑页面
   */
  edit(item) {
    this.modal.static(BimScoringRuleEditComponent, {
      isAdd: false,
      record: item,
      bimInfoList: this.bimInfoList
    }).subscribe((res) => {
      if (res === 'success') {
        this.getDataList();
      }
    });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteData(item.id);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteData(dataId) {
    this.isloadding = true;
    this.bimScoringRuleService.delete(dataId).subscribe(() => {
      this.isloadding = false;
      this.fileUploadService.deleteAllByFk(dataId).subscribe();
      this.msg.remove();
      this.msg.info('删除成功！');
      this.getDataList();
    });
  }

  detail(item) {
    this.modal.static(BimScoringRuleItemComponent, {
      record: item,
    }, 1400).subscribe((res) => {
    });
  }

  download(item) {
    const fileFk = item.id;
    this.fileUploadService.query({
      'fileFk.equals': fileFk
    }).subscribe(res => {
      const data = res.body;
      if (data && data.length > 0) {
        const a = document.createElement('a');
        const url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + data[0].id;
        a.href = url;
        a.click();
      } else {
        this.msg.info('未上传评分细项模板');
      }
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];
}
