import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimInfoSchools } from '../bim-info-schools.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimInfoSchoolsService } from '../bim-info-schools.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { UserService } from "../../../../../admin/system/users/users.service";
import { isNullOrUndefined } from 'util';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { BimSchoolsService } from '../../../school/bim-schools.service';
import { BimSchools,SchoolPerson } from '../../../school/bim-schools.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';


import {ProvinceService} from '../../../provinces/provinces.service';
import {UcommonService} from '../../../ucommon//ucommon.server';


@Component({
  selector: 'app-bim-bimInfoSchools-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimInfoSchoolsEditComponent implements OnInit {
  submitting = false;
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科', '2' : '中职'};

  public schoolAuditeList = {'':'全部','0': '未通过',  '1' : '通过'};
  public belongTypeList = {'':'全部','0': '985',  '1' : '211', '2' : '其他'};
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimSchools[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];

  userLocationList = [];
  userProvinceList=[];
 
  entityId:string;
  entityName:String;
  accountName:String;
  
  bimInfoType = null;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    'schoolType.contains': '',
    'location.contains': '',
    'province.contains': '',
    'audit.equals': '',
    'belongType.contains': '',

    // sort固定
     sort: ['createTime,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '学校名称', index: 'name' , width: '25%'},
    { title: '学制', index: 'schoolType' , width: '8%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else{
          return item.schoolType;
        }
      }
    },
    { title: '类型', index: 'belongType' , width: '8%',
      format: (item: any) => {
        if (item.belongType === '1') {
          return '211';
        } else if (item.belongType === '0') {
          return '985';
        } else if (item.belongType === '2') {
          return '其他';
        }else{
          return item.belongType;
        }
      }
    },
    { title: '区域', index: 'location' , width: '10%',
      format: (item: any) => {
         return this.findLocationName(item.location);
      }
    },
    { title: '省份', index: 'province' , width: '10%',
      format: (item: any) => {
        return this.findProvinceName(item.province); 
      }
    },
    { title: '邮箱', index: 'email' , width: '20%'},
    { title: '注册码', index: 'regCode' , width: '8%'},
    { title: '注册码发送状态', index: 'sendMail' , width: '10%'},
    
    { title: '状态', index: 'audit' , width: '8%',
      format: (item: any) => {
        if (item.audit === 1) {
          return '通过';
        } else if (item.audit === 0) {
          return '未通过';
        } else{
          return '未审核';
        }
      }
    },

  ];


  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimInfoSchoolsService: BimInfoSchoolsService,

    public bimSchoolsService: BimSchoolsService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;



  }

  ngOnInit() {

    if(this.accountName === 'admin')
    {
      this.loadLocationList();
      this.loadprovinceList();

      this.getDataList();
    }else{
      this.getUserProvinces();
    }
   
  }


/**
 * 获取用户地区信息
 */
getUserProvinces(){
  this.userProvinceList = [];
  this.userLocationList = [];
 this.provinceService.getUserProvinces()
 .subscribe((res: any) => {
   
     res.body.forEach(element => {
         this.userProvinceList.push(element.id);
         this.userLocationList.push(element.fkregionId);

         
     });
     this.loadLocationList();
     this.loadprovinceList();
     this.getDataList();

     //this.userProvinceList = res.body;
     console.log(res.body);
 });
}


/**
* 获取数据列表
* @param {string} url
*/
public getDataList(isReset?: boolean) {
  let copyParams = {};
 const q = this.queryParams;
 if (isReset === true) {
   this.st.reset();
   this.page = 0;
   Object.keys(q).forEach(function (key) {
     q[key] = '';
   });
 } else {
   Object.keys(q).forEach(function (key) {
     if (q[key] !== '' &&　q[key] !== null) {
       copyParams[key] = q[key];
     }
   });
 }

 if (!isNullOrUndefined(this.bimInfoType)) {
  if (this.bimInfoType === 'bim') {
    copyParams['userDefine4.specified'] = false;
  } else {
    copyParams['userDefine4.equals'] = this.bimInfoType;
  }
 } else {
  // copyParams['userDefine4.specified'] = false;
 }
 
 copyParams['page'] = this.page;
 copyParams['size'] = this.size;

 let tmplocation = copyParams['location.contains'];
 copyParams['isDelete.equals'] = 0;
 if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
 {   
   //delete copyParams['location.contains'];
   copyParams['location.in'] = this.userLocationList;
 }

 let tmpprovince = copyParams['province.contains'];

 if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
 {
   //delete copyParams['province.contains'];
   copyParams['province.in'] = this.userProvinceList;
 }

 this.bimSchoolsService.query(copyParams)
   .subscribe((res: any) => {

   this.list = res.body;
   this.total = res.headers.get('X-Total-Count');

   });
}

/**
* 页码数量变动事件
* @param
*/
paginationChange(event) {
 console.log(event);
 this.page = event.pi - 1;
 this.size = event.ps;
 this.getDataList();
}

/**
* 过滤器变动事件 支持多选过滤
* @param
*/
filterChange(event) {
 let i = 0;
 const _value = [];
 const _type = event.filterMultiple ? 'in' : 'equals';
 event.filters.forEach(element => {
   if (element.checked) {
     _value[i++] = element.value;
   }
 });
 this.queryParams[event.indexKey + '.' + _type] = _value;
 this.getDataList();
}

/**
* 排序变动事件
* @param
*/
sortChange(event) {
 const array = this.queryParams['sort'];
 const length = array.length;
 let isInArray = false;
 let value = null;
 if (event.value === 'descend') {
   value = 'desc';
 } else if (event.value === 'ascend') {
   value = 'asc';
 }
 for (let i = 0; i < length; i++) {
   if (array[i].startsWith(event.column.indexKey)) {
     if (value == null) {
       array.splice(i, 1);
       isInArray = true;
       break;
     } else {
       array[i] = event.column.indexKey + ',' + value;
       isInArray = true;
       break;
     }
   }
 }
 if (value != null && !isInArray) {
   array.push(event.column.indexKey + ',' + value);
 }
 // 排序改变时，simpleTable会重置页码
 this.page = 0;
 this.getDataList();
}


 /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
 checkboxChange(list: SimpleTableData[]) {
  this.selectedRows = list;
  this.totalCallNo = this.selectedRows.reduce(
    (total, cv) => total + cv.callNo,
    0,
  );
}



  /**
 * 加载区域列表
 */
loadLocationList(){
  this.locationList = [];
  // 加载区域与省份数据
  const dataParams = {
    'id.in':this.userLocationList,
    'size' : '10000',
    'sort': 'orderNum,asc'
  };

    this.userService.queryRegionList(dataParams).subscribe(res => {
      
      if (res && res.body) {
        this.locationList = res.body;
      }
    });
}



/**
 * 加载省份列表
 */
loadprovinceList(){
  this.provinceList = [];
  
  this.userService.queryProvinceList({
    'id.in':this.userProvinceList,
    'size' : '10000',
    'sort': 'orderNum,asc'
  }).subscribe(res => {
    if (res && res.body) {
      const data = res.body;
       this.provinceList = data;
    }
  });
}


findLocationName(location){
   let tmpList = this.locationList.filter(d=>d.id===location);
   if(tmpList.length >0)
   {
      return tmpList[0].regionName;
   }else{
     return '';
   }
}

findProvinceName(location){
  let tmpList = this.provinceList.filter(d=>d.id===location);
  if(tmpList.length >0)
  {
     return tmpList[0].provinceName;
  }else{
    return '';
  }
}



  // 保存
  submit() {
    this.nzModalRef.destroy(this.selectedRows);
   
  }


  Cancel(){
    this.nzModalRef.destroy(null);
  }

}


