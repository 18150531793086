import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ThsNewsService } from '../../../ths-news/ths-news.service';

@Component({
  selector: 'app-biminfo-institutional-edit',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimInfoInstitutionalEditComponent implements OnInit {

  isAdd = false;
  record: any = {};
  contentType = 'institutional';

  form: FormGroup;
  public loading = false;

  public dataId = null;
  public bimInfoList = [];

  public bimInfoId:string;

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private modalHelp: ModalHelper,
    private bimInfoService: BimInfoService,
    private thsNewsService: ThsNewsService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [this.bimInfoId, []],
      title: [null, [Validators.required, Validators.maxLength(50)]],
      author: [null, []],
      edit: [null, []],
      istop: [true, []],
      isreport: [1, []],
      createTime: [null, []],
      remark: [null, []],
      content: [null, []],
      isDelete: [0, []],
      hit: [0, []],
      contenttype: [null, []],
    });
  }

  ngOnInit(): void {
    // if (this.bimInfoList.length === 0) {
    //   this.initBimInfoList();
    // }
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.dataId = this.record.id;
      this.loadData();
    }
  }

  initBimInfoList() {
    this.bimInfoService.query({
      'size' : '10000',
      'status.in': ['0', '1'],
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
        this.bimInfoList = res.body;
    });
  }

  loadData() {
    this.form.patchValue(this.record);
  }

  // 提交
  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    if (!isNullOrUndefined(this.bimInfoId)) {
      subParam['bimInfoId']  = this.bimInfoId;
    }
    console.log(subParam);

    this.loading = true;
    const isUpdate = this.record !== null && !isNullOrUndefined(this.record.id);
    this.thsNewsService.query({
      'bimInfoId.equals': this.bimInfoId,
      'title.equals': subParam['title']
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
        if (data.length > 0) {
          if (isUpdate) {
            if (this.record.id === data[0].id) {
              this.checkSaveData(subParam);
            } else {
              this.loading = false;
              this.onError({ 'message': '该类型已存在，请修改！' });
              return;
            }
          } else {
            this.loading = false;
            this.onError({ 'message': '该类型已存在，请修改！' });
            return;
          }
        } else {
          this.checkSaveData(subParam);
        }
      }
    });
  }

  checkSaveData(subParam: any) {
    subParam['istop'] = subParam['istop'] ? 1 : 0;
    subParam['title'] = subParam['title'].trim();
    const content = subParam['content'];
    if (content && content.length > 0) {
      subParam['content'] = content.replace(new RegExp('align="center"', 'g'), 'style="margin: auto;"');
    }
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.updateData(subParam);
    } else {
      this.saveData(subParam);
    }
  }

  saveData(subParam: any) {
    subParam['id'] = this.dataId;
    if (!isNullOrUndefined(this.contentType)) {
      subParam['contenttype'] = this.contentType;
    }
    this.thsNewsService.create(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  updateData(subParam: any) {
    subParam['updateTime'] = new Date();
    this.thsNewsService.update(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  uploadFile(isView: boolean) {
    this.modalHelp.static(FileUploadComponent, {
      fileFk: this.dataId,
      uploadable: !isView,
      deleteable: !isView,
      uploadExtParams: {
        'extField1': this.contentType
      },
      loadExtParams: {
        'extField1.equals': this.contentType
      }
    }, 1200, { nzTitle: '附件上传' })
      .subscribe(res => {
        // this.updateFileCount(item);
      });
  }

  close() {
    this.nzModalRef.destroy(this.dataId);
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }
}
