import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'formatProjectClosingStatus' })
/**
 * 格式化项目结项状态管道
 */
export class formatProjectClosingStatusPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        
        switch (value) {
            case '0':
                return "待结项";
            case '1':
                return '结项已提交';
            case '2':
                return '结项审核中';
            case '3':
                return '企业验收通过';
            case '4':
                return '企业验收不通过';
            case '6':
                return '结项通过';
            case '7':
                return '结项不通过';
            case '9':
                return '退回修改';
            default:
                return '待结项';
        }
    }
}