import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimItemsFile } from './bim-items-file.model';

@Injectable({ providedIn: 'root' })
export class  BimItemsFileService extends ThsBaseService<BimItemsFile>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-items-files');
  }

  deleteByItemId(itemId) {
    return this.http.get<any>(this.resourceUrl + `/deleteByItemId/${itemId}`, { observe: 'response' });
  }
}
