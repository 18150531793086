import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { GuidUtil } from "@shared/utils/guid.util";
import { NzMessageService, NzModalRef } from "ng-zorro-antd";
import { isNullOrUndefined } from "util";
import { BimInfoEventsGroupService } from "./event-gourp.service";

@Component({
    selector: 'app-add-group',
    templateUrl: './add-group.component.html',
    styleUrls: ['./add-group.component.less']
  })
  export class AddGroupComponent implements OnInit {

    bimInfoId = null;
    bimEventId = null;
		itemId = null;
		itemName = null;

    dataList = [];
    
    form: FormGroup;

    loading = false;
    
    ngOnInit(): void {
			this.loadData();
    }
    
    constructor(
      private fb: FormBuilder,
      private msg: NzMessageService,
      private nzModalRef: NzModalRef,
      private bimInfoEventsGroupService: BimInfoEventsGroupService,
    ) {
      this.form = this.fb.group({
      });
    }

    /**
      * 加载页面data
      */
		loadData(): void {
      this.bimInfoEventsGroupService.query({
        'bimInfoId.equals': this.bimInfoId,
        'bimInfoEventsId.equals': this.bimEventId,
        'itemId.equals': this.itemId,
        sort: ['orderNum']
      }).subscribe(res => {
        if (res && res.body.length > 0) {
          const datas = res.body;
          datas.forEach(item => {
            this.addFormControl(item);
          })
          this.dataList = datas;
        } else {
          this.addRow();
        }
      });
		}

    addRow(){
      const item = {
        id: new GuidUtil().toString(),
        groupName: null,
        startNum: null,
        endNum: null,
      }
      this.dataList = [ ...this.dataList,item ];
     
      this.addFormControl(item);
    }

    addFormControl(item){
      this.form.addControl(item.id + '_groupName' , new FormControl(item.groupName, [Validators.required, Validators.maxLength(10)]));
      this.form.addControl(item.id + '_startNum', new FormControl(item.startNum, [Validators.required]));
      this.form.addControl(item.id + '_endNum', new FormControl(item.endNum, [Validators.required]));
    }

		removeRow(item) {
			this.dataList = this.dataList.filter(e => {
				return e.id !== item.id
			})
			this.form.removeControl(item.id + '_groupName');
			this.form.removeControl(item.id + '_startNum');
			this.form.removeControl(item.id + '_endNum');
		}

    
    // 保存
    submit() {
      const res = this.saveBefore();
      console.log(typeof(res));
      if(typeof(res) === 'object' && !isNullOrUndefined(res)) {
        this.loading = true;
        let isCheck = true;
        if (this.dataList && this.dataList.length > 0) {
          const groupNameList = [];
          let index = 1;
          this.dataList.forEach(item => {
            const groupNameKey = item.id +'_groupName' ;
            let control = this.form.get(groupNameKey);
            item['groupName'] = control ? control.value : null;
            if (!groupNameList.includes(item['groupName'])) {
              groupNameList.push(item['groupName']);
            }
    
            const startNumKey = item.id +'_startNum';
            control = this.form.get(startNumKey);
            item['startNum'] = control ? control.value : null;
    
            const endNumKey = item.id +'_endNum';
            control = this.form.get(endNumKey);
            item['endNum'] = control ? control.value : null;
            
            if (item['startNum'] > item['endNum']) {
              this.msg.warning(`组别${index}序号开始不可大于序号结束`);
              this.loading = false;
              isCheck = false;
              return;
            }
            index++;
          });
          console.log(this.dataList);

          for (let i = 0; i < groupNameList.length; i++) {
            const groupName = groupNameList[i];
            const list = this.dataList.filter(e => e.groupName === groupName);
            // 检查组别内序号是否有交集
            for (let j = 0; j < list.length; j ++) {
              const first = list[j];
              for (let k = 0; k < list.length; k ++) {
                if (j !== k) {
                  const second = list[k];
                  if (this.isHaveNumRange(first, second)) {
                    this.loading = false;
                    isCheck = false;
                    this.msg.warning(`组别${j + 1}【${first.groupName}】序号范围存在重复，请检查！`);
                    return;
                  }
                }
              }
            }
          }

          if (!isCheck) {
            return;
          }

          const param = {
            bimInfoId: this.bimInfoId,
            bimEventId: this.bimEventId,
            itemId: this.itemId,
            dataList: this.dataList
          };
          this.bimInfoEventsGroupService.createItemEventGroup(param).subscribe(res => {
            this.loading = false;
            if (res && res.body) {
              const data = res.body;
              if (data.success) {
                this.msg.success('保存成功');
                this.close(true);
              } else {
                this.msg.warning(data.message);
              }
            }
          })
        }
      }
    }

    isHaveNumRange(f, s) {
      if (f.endNum < s.startNum || s.endNum < f.startNum) {
        return false;
      } else {
        return true;
      }
    }

    saveBefore(){
      let param = this.setValue();

      if (this.form.invalid) {
        this.msg.error('请检查信息是否完整');
        return false;
      }
      
      return param;
    }

    setValue(){
      const param = {};
    
      for (const key in this.form.controls) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
        param[key] = this.form.controls[key].value;
      }

      return param;
    }

    close(flag?){
      this.nzModalRef.destroy(flag);
    }
    
}