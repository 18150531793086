import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { EduTopicReplyService } from '../topicReply/topicReply.service';
import { TopicReply } from '../topicReply/topicReply.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-topicReply',
  templateUrl: './topicReply.component.html',
})
export class EduTopicReplyComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: TopicReply[] = [];
  record: any = {};
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'description.contains': '',
    'createdBy.contains': '',
    // sort固定
    sort: ['replyTime,asc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
        // { title: '截图', index: 'imgurl' },
    { title: '回复描述', index: 'description', width: '20%' },
    { title: '回复人', index: 'createdBy', width: '10%'},
    { title: '回复时间', index: 'replyTime', width: '15%'},
    {
      title: '是否已采纳', index: 'isGet', className: 'text-center', width: '15%',
      format: (item: any) => {
        if (item.isGet === '0') {
          return '未采纳';
        } else if (item.isGet === '1') {
          return '已采纳';
        }
      },
      filters: [
        { text: '未采纳', value: 0 }, // 此处值对应数据库的值，即domain对象的值
        { text: '已采纳', value: 1}  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否置顶', index: 'isTop', className: 'text-center', width: '15%',
      format: (item: any) => {
        if (item.isTop === '0') {
          return '否';
        } else if (item.isTop === '1') {
          return '是';
        }
      },
      filters: [
        { text: '否', value: 0 },
        { text: '是', value: 1}
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '点赞数量', index: 'likePoints', width: '10%'},
    {
      title: '操作', className: 'text-center',
      buttons: [
        { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item); } }
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public eduTopicReplyService: EduTopicReplyService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    copyParams['eduTopicId.contains'] = this.record.id;
    console.log(copyParams);
    this.eduTopicReplyService.query(copyParams)
      .subscribe((res: any) => {
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
       // this.list = res.body;

      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }

 /**
  * 删除
  */
 remove(res: any) {
  this.modalService.confirm({
    nzTitle: '确认删除?',
    nzContent: '',
    nzOkText: '是',
    nzOkType: 'danger',
    nzOnOk: () => {
      this.eduTopicReplyService.delete(res.id)
        .subscribe(() => {
          this.getDataList();
          this.st.clearCheck();
        });
    },
    nzCancelText: '否',
    nzOnCancel: () => console.log('Cancel')
  });
}


 /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
 checkboxChange(list: SimpleTableData[]) {
  this.selectedRows = list;
  this.totalCallNo = this.selectedRows.reduce(
    (total, cv) => total + cv.callNo,
    0,
  );
}

}
