import {Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import {HttpAddressService} from "@shared/session/http-address.service";
import * as OSS from 'ali-oss';
import { CacheService } from '@delon/cache';
import { isNullOrUndefined } from 'util';
import { BimItemsFileService } from './bim-items-file/bim-items-file.service';
import { BimWorks } from '../../../work-manage/work-manage.model';
import { WorkManageService } from '../../../work-manage/work-manage.service';
import { BimInfoEventsGroupService } from '../../group-num-list/add-group/event-gourp.service';

@Component({
  selector: 'app-reply-upload',
  templateUrl: './reply-upload.component.html',
  styleUrls: ['./reply-upload.component.less'],
})
export class ReplyUploadComponent implements OnInit,OnChanges {
  @ViewChild('fUpload1') fUpload1: any;
  @ViewChild('fUpload2') fUpload2: any;
  @ViewChild('fUpload3') fUpload3: any;
  @ViewChild('fUpload4') fUpload4: any;
  @ViewChild('fUpload5') fUpload5: any;

  @Input() title: string = '';
  @Input() fileFk: string = '';
  @Input() schoolId: string = '';
  @Input() teamId: string = '';
  @Input() itemName: string = '';
  @Input() itemId: string = '';
  @Input() type: number = 3;
  @Input() isView: boolean = false;
  @Input() teamSchoolTop = null;
  @Input() bimEventId = null;

  worksId: string = '';
  validateForm: FormGroup;

  modelIsAble = 1;
  videoIsAble = 1;
  pptIsAble = 1;
  wordIsAble = 1;
  pictureIsAble = 1;

  modelFileList = [];
  videoFileList = [];
  pptFileList = [];
  wordFileList = [];
  imageList = [];

  works: BimWorks = new BimWorks();
  check = false;


  modelNumLimit = 1;
  videoNumLimit = 1;
  pptNumLimit = 1;
  wordNumLimit = 1;
  pictureNumLimit = 1;

  modelFileSize = 100;
  videoFileSize = 100;
  pptFileSize = 10;
  wordFileSize = 10;
  pictureFileSize = 10;

  modelIsUpload = 1;
  videoIsUpload = 1;
  pptIsUpload = 1;
  wordIsUpload = 1;
  pictureIsUpload = 1;

  isDataInit = false;

  groupNameList = [];
  groupNumList = [];
  groupNumMap = {};

  public ossClient;
  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private cacheService: CacheService,
    private workManageService: WorkManageService,
    private httpAddressService: HttpAddressService,
    private bimItemsFileService: BimItemsFileService,
    private thsadminFileService: ThsadminFileService,
    private bimInfoEventsGroupService: BimInfoEventsGroupService,
  ) {
    this.ossClient = new OSS(this.httpAddressService.ossServe);
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      name: [{value: null, disabled: this.isView}, [Validators.required,Validators.maxLength(50)]],
      nickname: [{value: null, disabled: this.isView},[Validators.maxLength(200)]],
      groupName: [{value: null, disabled: this.isView},[Validators.required]],
      groupNum: [{value: null, disabled: this.isView},[Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type && !changes.schoolId) {
      this.getEventGroup();
      this.modelFileList = [];
      this.videoFileList = [];
      this.pptFileList = [];
      this.wordFileList = [];
      this.imageList = [];
    }
    if (changes.schoolId && changes.schoolId.currentValue && changes.itemId && changes.itemId.currentValue) {
      this.getItemFile();
    }
  }

  getEventGroup() {
    const param = {
      'bimInfoEventsId.equals': this.bimEventId,
      'itemId.equals': this.itemId,
      sort: ['orderNum']
    };
    this.bimInfoEventsGroupService.query(param).subscribe(res => {
      this.groupNameList = [];
      this.groupNumMap = {};
      if (res && res.body.length > 0) {
        const data = res.body;
        data.forEach(e => {
          let groupNum = [];
          if (!this.groupNameList.includes(e.groupName)) {
            this.groupNameList.push(e.groupName);
          } else {
            groupNum = this.groupNumMap[e.groupName];
          }
          for (let i = e.startNum; i <= e.endNum; i++) {
            groupNum.push(i);
          }
          this.groupNumMap[e.groupName] = groupNum;
        });

        const groupName = this.validateForm.controls['groupName'].value;
        const groupNum = this.validateForm.controls['groupNum'].value;
        if (!this.groupNameList.includes(groupName)) {
          this.groupNameList.push(groupName);
          this.groupNumMap[groupName] = [groupNum];
          this.groupNumList = this.groupNumMap[groupName];
        } else {
          const groupNumList = this.groupNumMap[groupName];
          if (!groupNumList.includes(groupNum)) {
            groupNumList.push(groupNum);
            this.groupNumMap[groupName] = groupNumList;
            this.groupNumList = groupNumList;
          }
        }
        this.getWorks();
      }
    })
  }

  getItemFile() {
    this.bimItemsFileService.query({
      'itemId.equals': this.itemId
    }).subscribe(res => {
      if (res && res.body.length > 0) {
        const data = res.body;
        for (let i = 0; i < data.length; i++) {
          const itemFile = data[i];
          switch (itemFile.type) {
            case 'model_upload':
              this.modelIsAble = this.formatNum(itemFile.isAble, this.modelIsAble);
              this.modelNumLimit = this.formatNum(itemFile.fileNum, this.modelNumLimit);
              // this.modelFileSize = this.getMaxNum(this.formatNum(itemFile.fileSize, this.modelFileSize), this.modelFileSize) * 1024 * 1024;
              this.modelFileSize = this.formatNum(itemFile.fileSize, this.modelFileSize) * 1024 * 1024;
              this.modelIsUpload = this.formatNum(itemFile.isUpload, this.modelIsUpload);
              break;
            case 'video_upload':
              this.videoIsAble = this.formatNum(itemFile.isAble, this.videoIsAble);
              this.videoNumLimit = this.formatNum(itemFile.fileNum, this.videoNumLimit);
              // this.videoFileSize = this.getMaxNum(this.formatNum(itemFile.fileSize, this.videoFileSize), this.videoFileSize) * 1024 * 1024;
              this.videoFileSize = this.formatNum(itemFile.fileSize, this.videoFileSize) * 1024 * 1024;
              this.videoIsUpload = this.formatNum(itemFile.isUpload, this.videoIsUpload);
              break;
            case 'ppt_upload':
              this.pptIsAble = this.formatNum(itemFile.isAble, this.pptIsAble);
              this.pptNumLimit = this.formatNum(itemFile.fileNum, this.pptNumLimit);
              // this.pptFileSize = this.getMaxNum(this.formatNum(itemFile.fileSize, this.pptFileSize), this.pptFileSize) * 1024 * 1024;
              this.pptFileSize = this.formatNum(itemFile.fileSize, this.pptFileSize) * 1024 * 1024;
              this.pptIsUpload = this.formatNum(itemFile.isUpload, this.pptIsUpload);
              break;
            case 'word_upload':
              this.wordIsAble = this.formatNum(itemFile.isAble, this.wordIsAble);
              this.wordNumLimit = this.formatNum(itemFile.fileNum, this.wordNumLimit);
              this.wordFileSize = this.formatNum(itemFile.fileSize, this.wordFileSize) * 1024 * 1024;
              this.wordIsUpload = this.formatNum(itemFile.isUpload, this.wordIsUpload);
              break;
            case 'picture_upload':
              this.pictureIsAble = this.formatNum(itemFile.isAble, this.pictureIsAble);
              this.pictureNumLimit = this.formatNum(itemFile.fileNum, this.pictureNumLimit);
              this.pictureFileSize = this.formatNum(itemFile.fileSize, this.pictureFileSize) * 1024 * 1024;
              this.pictureIsUpload = this.formatNum(itemFile.isUpload, this.pictureIsUpload);
              break;
            default:
              break;
          }
        }
      }
      this.getEventGroup();
    });
  }

  getWorks(workType?) {
    const params = {
      'teamId.equals': this.teamId,
      'schoolId.equals': this.schoolId,
      'itemId.equals': this.itemId,
      'workType.equals': isNullOrUndefined(workType) ? this.type : workType,
      page: 0,
      size: 1
    };
    this.workManageService.query(params).subscribe(res => {
      if (!isNullOrUndefined(workType)) {
        this.isDataInit = true;
        if (res && res.body && res.body.length === 1) {
          this.validateForm.setValue({
            name: res.body[0].name,
            nickname: res.body[0].introduction,
            groupName: res.body[0].groupName,
            groupNum: res.body[0].groupNum,
          });
          this.worksId = '';
          this.works = new BimWorks();
          if (this.teamSchoolTop == 1) {
            this.validateForm.controls['name'].disable();
            this.validateForm.controls['nickname'].disable();
          }
        }
      } else {
        if (res && res.body && res.body.length === 1) {
          this.isDataInit = true;
          this.validateForm.setValue({
            name: res.body[0].name,
            nickname: res.body[0].introduction,
            groupName: res.body[0].groupName,
            groupNum: res.body[0].groupNum,
          });
          this.worksId = res.body[0].id;
          this.works = res.body[0];
          this.fileFk = res.body[0].id;
          if (this.worksId && this.worksId.length > 0) {
            this.getFiles();
          }
          if (this.teamSchoolTop == 1) {
            this.validateForm.controls['name'].disable();
            this.validateForm.controls['nickname'].disable();
          }
        } else {
          if (this.teamSchoolTop == 1) {
            if (isNullOrUndefined(workType)) {
              this.getWorks(1);
            } else {
              this.isDataInit = true;
            }
          } else {
            this.isDataInit = true;
          }
        }
      }
    });
  }

  getFiles() {
    const params = {
      'fileFk.equals': this.worksId,
      sort: ['createdDate']
    };
    this.thsadminFileService.queryFiles(params).subscribe(res => {
      if (res && res.body && res.body.length > 0) {
        this.modelFileList = [];
        this.videoFileList = [];
        this.pptFileList = [];
        this.wordFileList = [];
        this.imageList = [];
        res.body.forEach(item => {
          let downUrl;
          if (item.extField2 === 'AliOss') {
            if (item.extField4 === '1') {
              // 检查文件是否存在
              this.ossClient.head(item.fileSavePath).catch((err) => {
                if (err.code === 'NoSuchKey') {
                  console.log('文件不存在');
                  this.thsadminFileService.deleteFileById(item.id).subscribe();
                  return;
                }
              }).then((result) => {
                if (result !== undefined) {
                  const response = {
                    'content-disposition': `attachment; filename=${encodeURIComponent(item.fileName)}; Content-Type:application/octet-stream`
                  };
                  downUrl = this.ossClient.signatureUrl(item.fileSavePath, {response});
                  this.initWorkFile(item, downUrl);
                }
              });
            }
          } else {
            downUrl = this.httpAddressService.apiGateway + '/thsadmin/api/sys-files/download/' + item.id;
            this.initWorkFile(item, downUrl);
          }
        });
      }
    });
  }

  initWorkFile(item, downUrl) {
    const file = {
      uid: item.id,
      name: item.fileName,
      size: item.fileSize,
      // tslint:disable-next-line:max-line-length
      url: downUrl,
      path: item.fileSavePath,
      extField2: item.extField2,
      showPercent: false
    };
    if (item.extField1 === 'team_reply_works_model') {
      if ((item.extField2 === null) || item.extField2 === 'AliOss' && item.extField4 === '1') {
        this.modelFileList.push(file);
      }
    } else if (item.extField1 === 'team_reply_works_video') {
      if ((item.extField2 === null) || item.extField2 === 'AliOss' && item.extField4 === '1') {
        this.videoFileList.push(file);
      }
    } else if (item.extField1 === 'team_reply_works_ppt') {
      this.pptFileList.push(file);
    } else if (item.extField1 === 'team_reply_works_word') {
      this.wordFileList.push(file);
    } else if (item.extField1 === 'team_reply_works_image') {
      this.imageList.push(file);
    }
  }

  submitForm() {
    this.check = false;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
    if (this.validateForm.status === "VALID") {
      if (this.modelIsAble === 1) {
        this.modelFileList = this.fUpload1.fileList;
        if (this.modelIsUpload == 1 && (!this.modelFileList || this.modelFileList.length === 0)) {
          this.msg.warning('请上传' + this.itemName + '模型文件！');
          return;
        }
      }
      if (this.videoIsAble === 1) {
        this.videoFileList = this.fUpload2.fileList;
        if (this.videoIsUpload == 1 && (!this.videoFileList || this.videoFileList.length === 0)) {
          this.msg.warning('请上传' + this.itemName + '视频文件！');
          return;
        }
      }
      if (this.pptIsAble === 1) {
        this.pptFileList = this.fUpload3.fileList;
        if (this.pptIsUpload == 1 && (!this.pptFileList || this.pptFileList.length === 0)) {
          this.msg.warning('请上传' + this.itemName + 'PPT！');
          return;
        }
      }
      if (this.wordIsAble === 1) {
        this.wordFileList = this.fUpload4.fileList;
        if (this.wordIsUpload == 1 && (!this.wordFileList || this.wordFileList.length === 0)) {
          this.msg.warning('请上传' + this.itemName + '文档！');
          return;
        }
      }
      if (this.pictureIsAble === 1) {
        this.imageList = this.fUpload5.fileList;
        if (this.pictureIsUpload == 1 && (!this.imageList || this.imageList.length === 0)) {
          this.msg.warning('请上传' + this.itemName + '图片！');
          return;
        }
      }
      this.check = true;
      this.works.name = this.validateForm.get('name').value;
      if (this.validateForm.get('nickname')) {
        this.works.introduction = this.validateForm.get('nickname').value || '';
      }
      this.works.groupName = this.validateForm.get('groupName').value;
      this.works.groupNum = this.validateForm.get('groupNum').value;
      this.works.workSubmitStatus = 1;
      if (!this.worksId || this.worksId.length === 0) {
        this.works.itemId = this.itemId;
        this.works.schoolId = this.schoolId;
        this.works.teamId = this.teamId;
        this.works.workType = this.type;
      }
    }
  }

  groupNameChange(groupName) {
    this.groupNumList = this.groupNumMap[groupName];
    if (!isNullOrUndefined(this.validateForm.controls['groupNum'].value)) {
      this.validateForm.controls['groupNum'].setValue(null);
    }
  }

  formatNum(str: any, defaultNum: any) {
    return str === undefined || str === null || str === ''
      ? defaultNum
      : Number(str);
  }

  getMaxNum(num1, num2) {
    return num1 > num2 ? num1 : num2;
  }

}
