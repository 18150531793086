import { Injectable } from '@angular/core';
import {ThsBaseService} from "@shared/components/thsBaseService";
import {
  BimTeamStudentDTO,
  BimTeamTeacherDTO,
  BimWorkCoresDTO,
  BimWorks,
  BimWorkScoresVM,
  BimWorkVm
} from "./work-manage.model";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {HttpAddressService} from "@shared/session/http-address.service";
import {Observable} from "rxjs/Observable";
import {MessageHelper} from "@shared/helpers/MessageHelper";
import { timeout } from 'rxjs/internal/operators/timeout';

@Injectable({
  providedIn: 'root'
})
export class WorkManageService extends ThsBaseService<BimWorks> {

  worksUrl = '';
  teamUrl = '';
  teamTeacherUrl = '';
  teamStudentUrl = '';
  workScoresUrl = '';
  workScoreDetailsUrl = '';

  constructor(
    http: HttpClient,
    httpAddressService: HttpAddressService
  ) {
    super(http, httpAddressService.BimServe + `/bim-works`);
    this.worksUrl = httpAddressService.BimServe + '/bim-works';
    this.teamUrl = httpAddressService.BimServe + '/bim-teams';
    this.teamTeacherUrl = httpAddressService.BimServe + '/bim-team-tearchers';
    this.teamStudentUrl = httpAddressService.BimServe + '/bim-team-students';
    this.workScoresUrl = httpAddressService.BimServe + '/bim-work-cores';
    this.workScoreDetailsUrl = httpAddressService.BimServe + '/bim-work-cores-details';
  }

  queryAllTeamWork(params: any): Observable<HttpResponse<BimWorkVm[]>> {
    return this.http.get<BimWorkVm[]>(this.teamUrl + '/works', { params: params,observe: 'response' });
  }

  queryTeamTeacher(params: any): Observable<HttpResponse<BimTeamTeacherDTO[]>> {
    return this.http.get<BimTeamTeacherDTO[]>(this.teamTeacherUrl, { params: params, observe: 'response' });
  }

  queryTeamStudent(params: any): Observable<HttpResponse<BimTeamStudentDTO[]>> {
    return this.http.get<BimTeamStudentDTO[]>(this.teamStudentUrl, { params: params, observe: 'response' })
  }

  queryWorkScores(params: any): Observable<HttpResponse<BimWorkCoresDTO[]>> {
    return this.http.get<BimWorkCoresDTO[]>(this.workScoresUrl, { params: params, observe: 'response'});
  }

  queryBimWorkCoresDetails(params: any): Observable<HttpResponse<BimWorkScoresVM[]>> {
    return this.http.get<BimWorkScoresVM[]>(this.workScoreDetailsUrl + '/work-cores', { params: params, observe: 'response'});
  }

  getBimWork(params: any): Observable<HttpResponse<BimWorks[]>> {
    return this.http.get<BimWorks[]>(this.worksUrl + '/getBimWork', { params: params, observe: 'response'});
  }

  generateWorkQrCode(params: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.worksUrl + '/generateWorkQrCode', { params: params, observe: 'response'});
  }

  downWorkFileLocal(param: any) {
      return this.http.get<any[]>(`${this.resourceUrl}/downWorkFileLocal`, { params: param, observe: 'response' });
  }

  checkDownloadWorks(param: any) {
      return this.http.get<any>(`${this.resourceUrl}/checkDownloadWorks`, { params: param, observe: 'response' });
  }

  updateUploadDate(id): Observable<HttpResponse<BimWorks>> {
    return this.http.get<BimWorks>(`${this.resourceUrl}/updateUploadDate/${id}`, { observe: 'response'});
  }

  // 答辩作品
  filterReplyWork(param: any) {
    const url =  `${this.resourceUrl}/filterReplyWork`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  // 答辩作品
  updateWorkReplyType(param: any) {
    const url =  `${this.resourceUrl}/updateWorkReplyType`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  exportReplyWorkData(queryParams) {
    const url = `${this.resourceUrl}/exportReplyWorks`;
    // 需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    return this.http.get(url ,  {
      params: queryParams,
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  // 答辩作品
  deleteBimWork(id) {
    const url =  `${this.resourceUrl}/deleteBimWork/${id}`;
    return this.http.delete<any>(url, { observe: 'response' });
  }

  /**
   * 导出全国答辩材料管理
   * @param queryParams 
   * @returns 
   */
  exportReplyWorkFileData(queryParams) {
    const url = `${this.resourceUrl}/exportReplyWorkFileExcel`;
   
    return this.http.get(url ,  {
      params: queryParams,
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel',timeout:`${600000}` }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).pipe(timeout(600000));
  }

  /**
   * 导出全国答辩材料文件压缩包
   * @param queryParams 
   * @returns 
   */
  exportReplyWorkFileRar(queryParams) {
    const url = `${this.resourceUrl}/exportReplyWorkFileRar`;
   
    return this.http.post(url ,queryParams, {
      headers: new HttpHeaders({ timeout:`${900000}`,observe: 'response'}),
      withCredentials: true,
    }).pipe(timeout(900000));
  }
  
  createReplyWork(param: any) {
    const url =  `${this.resourceUrl}/createReplyWork`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  /**
   * 下载文件
   * @param filePath 文件路径（含文件名）
   * @param fileName (文件名)
   */
  downLoadFile(filePath: string,fileName:string,callback:(flag)=>void) {
    const url = `${this.resourceUrl}/downLoadFile`;

    //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    this.http.get(url ,  {
      params:{ filePath: filePath,fileName: fileName },
      headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream',timeout:`${600000}` }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).subscribe(
      data => {
        
        let blob = new Blob([data.body]);
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', fileName);
        a.click();
        URL.revokeObjectURL(objectUrl);
        if(callback && typeof(callback)  == 'function'){
          callback(true);
        }
      },
      error => {
        if(callback && typeof(callback)  == 'function'){
          callback(false);
        }
        console.error('下载文件失败', error);
      },
    );

  }

}
