import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { EduCourseSummerManEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CourseSummerManService } from './courseSummerMan.service';
import { CourseSummarize } from './courseSummerMan.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-courseSummer',
  templateUrl: './courseSummerMan.component.html',
})
export class EduCourseSummerManComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: CourseSummarize[] = [];
  record: any = {};
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];
  courseId = this.record.id;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    // sort固定
    // sort: ['orderNum,asc']
  };

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '课程概述', index: 'content', width: '60%' },
   /*  { title: '排序号', index: 'jhiOrder', width: '10%'}, */
    {
      title: '操作', className: 'text-center',
      buttons: [
        // tslint:disable-next-line:max-line-length
        // { acl: true, text: '查看', type: 'modal', component: AdminUsersEditComponent, paramName: 'record', click: () => this.getDataList() },
        { text: '编辑', type: 'modal', component: EduCourseSummerManEditComponent, paramName: 'record', click: () => this.getDataList(), },
        { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item); } }
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public courseSummerService: CourseSummerManService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    copyParams['courseId.contains'] = this.record.id;
    this.courseSummerService.query(copyParams)
      .subscribe((res: any) => {
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');

      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }
  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    // tslint:disable-next-line:prefer-const
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(EduCourseSummerManEditComponent, { record: { id: null, courseId: this.record.id } })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
    const tmp = this.formatIsOk(this.selectedRows[0]);
    this.modal
      .static(EduCourseSummerManEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  /**
  * 删除
  */
  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.courseSummerService.delete(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.courseSummerService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


}
