import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { APP_PERMISSIONS_IUCCE } from '@shared/app.permissions';
import { zip } from 'rxjs';
import { Router } from '@angular/router';
import { ProjectReportService } from '../projectReport/projectReport.service';
import { isNullOrUndefined } from 'util';
import { UserService } from 'app/routes/admin/system/users/users.service';

@Component({
  selector: 'app-project-fund-pay',
  templateUrl: './projectFundPay.component.html',
  styles: []
})
export class ProjectFundPayComponent implements OnInit {
  dtLoading = false;
  queryParams = {
    regionId: null,
    provinceId: null,
    year: null,
    schoolName: null,
    projectName: null,
    batchId: null,
    projectTypeId: null,
    payAmountStatus: null,
    amountGreater: null,
    amountLess: null,
    payAmountGreater: null,
    payAmountLess: null,
    softwareAmountGreater: null,
    softwareAmountLess: null,
    projectStage : 'fundpay'//项目阶段(资金支付)
  };
  isCollapse = true;
  regionList: any; // 大区
  provinceList: any; // 省份（根据大区选择进行筛选）
  provinceResultList: any; // 省份（所有数据）
  yearList: any; // 年份
  batchList: any; // 批次
  batchResultList: any; // 批次全部数据
  prjTypeList: any; // 项目类别
  prjTypeResultList: any; // 项目类别全部数据

  allChecked = false;
  indeterminate = false;
  dataList: any;
  total = 0;
  page = 0;
  size = 10;
  pageSizeOptions = [10, 20, 50, 100];
  APP_PERMISSIONS = APP_PERMISSIONS_IUCCE;

  constructor(
    private router: Router,
    public http: _HttpClient,
    public modal: ModalHelper,
    public msg: NzMessageService,
    private dictService: DictService,
    private userService: UserService,
    public modalService: NzModalService,
    private dictDetailService: DictDetailService,
    private projectReportService: ProjectReportService) { }

  ngOnInit(): void {
    this.initSearchValue();
  }
  /**
   * 初始化搜索条件默认值
   */
  initSearchValue() {
    this.getYearListDict();
  }

  /**
   * 获取大区、省份数据
   */
  getRegionProvinceInfo() {
    // 加载区域与省份数据
    const dataParams = {
      'size' : '10000',
      'sort': 'orderNum,asc'
    };
    zip(
      this.userService.queryRegionList(dataParams),
      this.userService.queryProvinceList(dataParams),
      this.projectReportService.getUserRegionAndProvince()
    ).subscribe(([regionRes, provinceRes, userDetails]) => {
      let regionDataList = regionRes.body;
      let provinceDataList = provinceRes.body;
      const regionProviceResult = userDetails.body; // 当前登录用户的大区、省份数据
      if (regionProviceResult) {
        let regionInfos = regionProviceResult['regionInfos']; // 大区
        let provinces = regionProviceResult['provinces']; // 省份
        // 过滤大区
        if (regionInfos && regionInfos.length > 0) {
          regionInfos = regionInfos.map(w => w.id);
          regionDataList = regionDataList.filter(w => regionInfos.includes(w.id));
        }
        // 过滤省份
        if (provinces && provinces.length > 0) {
          provinces = provinces.map(w => w.id);
          provinceDataList = provinceDataList.filter(w => provinces.includes(w.id));
        }
      }

      this.regionList = regionDataList;
      this.provinceResultList = provinceDataList;
    });
  }

  /**
   * 大区改变事件
   */
  fnChangeRegion(itemSelect) {
    this.provinceList = [];
    if (this.provinceResultList && itemSelect) {
      const items = typeof itemSelect === 'object' ? itemSelect : [itemSelect];
      const regionIds = []; // 将现在用的值和原来用过的值拼接一起查询
      items.forEach(item => {
        const regionQueryList = this.regionList.filter(w => w.id === item);
        const region = regionQueryList[0];
        const queryProvinces = this.provinceResultList.filter(w => w.fkregionId === region.id);
        queryProvinces.forEach(element => {
          this.provinceList.push(element);
        });
      });
    }
  }

  fnChangeYear(item) {
    this.batchList = [];
    this.prjTypeList = [];
    this.queryParams['batch.in'] = '';
    this.queryParams['prjType.in'] = '';

    if (this.batchResultList) {
      this.batchList = this.batchResultList.filter(w => w.parentId && w.itemValue2 === item);
    }
    if (this.prjTypeResultList) {
      this.prjTypeList = this.prjTypeResultList.filter(w => w.parentId && w.itemValue2 === item);
    }

  }

  /**
   * 获取年份
   */
  getYearListDict() {
    this.dictService.findAllByDictNo('yearList').subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
    this.dictService.query({ 'dictNo.equals': 'batchList' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals': res.body[0].id , sort: 'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.batchResultList = res2.body;
          }
        });
      }
    });
    this.dtLoading = true;
    this.dictService.query({ 'dictNo.equals': 'prjTypes' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals': res.body[0].id , sort: 'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.prjTypeResultList = res2.body;
          }
          this.getRegionProvinceInfo();
          this.getDataList();
        });
      }
    });
  }

  getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    this.dtLoading = true;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        if(key === 'projectStage'){
          copyParams[key] = q[key];
        }else{
          q[key] = null;
        }
      });
      this.batchList = [];
      this.prjTypeList = [];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          if (typeof q[key] === 'object' && q[key]) {
            copyParams[key] = q[key].join(',');
          } else {
            copyParams[key] = q[key];
          }
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.projectReportService.getAllIucceProjectsByPage(copyParams)
      .subscribe((res: any) => {
        this.dtLoading = false;
        this.dataList = res.body;
        if (this.dataList) {
            this.dataList.forEach(item => {
                const projectTypeId = item.projectTypeId;
                let prjType = null;
                if (!isNullOrUndefined(projectTypeId) && this.prjTypeResultList !== null) {
                  const typeList = this.prjTypeResultList.filter(e => e.id === projectTypeId);
                  if (typeList && typeList.length > 0) {
                    prjType = typeList[0].itemValue3;
                  }
                }
                item['prjType'] = prjType === 'soft' ? '软件支付类' : '非软件支付类';
            });
        }
        this.total = res.headers.get('X-Total-Count');
    });
  }


  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  /**
  * 页码数量变动事件
  * @param
  */
   paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  pay(_item: any, isView) {
    const projectTypeId = _item.projectTypeId;
    let prjType = null;
    if (!isNullOrUndefined(projectTypeId) && this.prjTypeResultList !== null) {
      const typeList = this.prjTypeResultList.filter(e => e.id === projectTypeId);
      if (typeList && typeList.length > 0) {
        prjType = typeList[0].itemValue3;
      }
    }
    if (isView) {
      this.router.navigate(['/business/iucce/projectFundPay/view'], {
        queryParams: {
          id: _item.id,
          prjType: prjType
        }
      });
    } else {
      this.router.navigate(['/business/iucce/projectFundPay/edit'], {
        queryParams: {
          id: _item.id,
          prjType: prjType
        }
      });
    }
  }

  formatAmount(amount) {
    return isNullOrUndefined(amount) ? 0 : amount;
  }

}
