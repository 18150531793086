import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ThsNewsService } from '../../ths-news/ths-news.service';
import { COMMON_DATA } from '@shared/app.commonData';

@Component({
  selector: 'app-bim-coop-college',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimCoopCollegeEditComponent implements OnInit {

  isAdd = false;
  record: any = {};
  // contentType = null;

  form: FormGroup;
  public loading = false;

  public dataId = null;
  public bimInfoList = [];

  loadExtParams = {'extField1.equals': 'coopCollege'};
  
  CONTENT_TYPE = COMMON_DATA.sysNewsContentType;

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private modalHelp: ModalHelper,
    private bimInfoService: BimInfoService,
    private thsNewsService: ThsNewsService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [null,[]],
      title: [null, [Validators.required, Validators.maxLength(51)]],
      contenttype: [null, [Validators.required]],
      remark: [null, []],
      istop: [true, []],
      author: [1, []],
      content: [null, []],
      createTime: [null, []],
      isDelete: [0, []],
      hit: [0, []],
    });
  }

  ngOnInit(): void {
    // if(this.contentType){
    //   this.loadExtParams['extField1.equals'] = this.contentType;
    // }

    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.dataId = this.record.id;
      this.loadData();
    }
  }

  loadData() {
    this.form.patchValue(this.record);

    this.logContentType(this.record.contenttype);
  }

  getBimInfoList(type) {
    this.bimInfoService.query({
      'size' : '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'type.in': [type],
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
      this.bimInfoList = res.body;
    });
  }

  // 提交
  submit() {
    const subParam = {};

    if(this.form.get('contenttype').value != 'coopCollege'){
      this.form.get('bimInfoId').setValidators([Validators.required]);
    }else{
      this.form.get('bimInfoId').clearValidators();
    }
    

    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    console.log(subParam);
    this.checkSaveData(subParam);
  }

  checkSaveData(subParam: any) {
    // 判断学校是否存在
    const params = {
      'contenttype.equals':  subParam['contenttype'],
      'title.equals':  subParam['title'].trim(),
      'isDelete.equals':  0,
    };
    if(subParam['bimInfoId']){
      params['bimInfoId.equals'] = subParam['bimInfoId'];
    }
    this.thsNewsService.query(params).subscribe(res => {
      let datas = res.body;
      if(datas){
        datas = datas.filter(w=>w.id != subParam['id']);
      }
      if(datas && datas.length > 0){
        this.onError({ 'message': '学校已存在，不能重复添加' });
        return;
      }

      this.loading = true;
      subParam['istop'] = subParam['istop'] ? 1 : 0;
      subParam['isreport'] = subParam['isreport'] ? 1 : 0;
      if (this.record !== null && !isNullOrUndefined(this.record.id)) {
        this.updateData(subParam);
      } else {
        this.saveData(subParam);
      }
    })


    
  }

  saveData(subParam: any) {
    subParam['id'] = this.dataId;
    // if (!isNullOrUndefined(this.contentType)) {
    //   subParam['contenttype'] = this.contentType;
    // }
    subParam['createTime'] = new Date();
    this.thsNewsService.create(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  updateData(subParam: any) {
    subParam['updateTime'] = new Date();
    this.thsNewsService.update(subParam).subscribe(res => {
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close('success');
    }, () => this.loading = false);
  }

  // uploadFile(isView: boolean) {
  //   this.modalHelp.static(FileUploadComponent, {
  //     fileFk: this.dataId,
  //     uploadable: !isView,
  //     deleteable: !isView,
  //     uploadExtParams: {
  //       'extField1': this.contentType
  //     },
  //     loadExtParams: {
  //       'extField1.equals': this.contentType
  //     }
  //   }, 1200, { nzTitle: '附件上传' })
  //     .subscribe(res => {
  //       // this.updateFileCount(item);
  //     });
  // }
  /**
    * 上传成功回调
    * @param file
    */
   onSuccessfulSchoolLogo(file: any) {
    const thisSuccessFile = file.successFile;
    if (null !== thisSuccessFile && thisSuccessFile.length > 0) {
      thisSuccessFile.forEach(item => {
        this.form.controls['content'].setValue(isNullOrUndefined(item.id) ?  item.response.id : item.id);
      });
    }
  }

  /**
   * 删除成功回调
   * @param file
   */
  onDeleteFileSchoolLogo(file: any) {
    if (file.file) {
    }
    this.form.controls['userDefine6'].setValue(null);
  }

  logContentType(event){
    const datas = this.CONTENT_TYPE.filter(w=>w.value === event);
    this.loadExtParams['extField1.equals'] = event ;
    console.log(this.loadExtParams['extField1.equals']);
    if(datas && datas.length > 0){
      const type = datas[0].type;
      if(type != 'bim'){
        this.getBimInfoList(type);
      }else{
        this.form.get('bimInfoId').setValue(null);
      }
    }
  }

  close() {
    this.nzModalRef.destroy(this.dataId);
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }
}
