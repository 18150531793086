import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EduLiveBroadcasts } from './edu-live-broadcasts.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class EduLiveBroadcastsService extends ThsBaseService<EduLiveBroadcasts> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/edu-live-broadcasts`);
        console.log(this.resourceUrl);
    }


}

