import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { formatStatePipe } from './formatState.pipe';

import { formatValuePipe } from "./formatValue.pipe";

import { formatBooleanPipe } from "./formatBoolean.pipe";

import { formatSexPipe } from "./formatSex.pipe";

import { formatMoneyUnitConvertPipe } from "./moneyUnitConvert.pipe";

import { ArrayService } from "./array.service";
import { KeysPipe } from './keysPipe.pipe';
import { htmlPipe } from './innerHtml.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        ArrayService
    ],
    declarations: [
        formatStatePipe,
        formatValuePipe,
        formatBooleanPipe,
        formatSexPipe,
        formatMoneyUnitConvertPipe,
        KeysPipe,
        htmlPipe
    ],
    exports: [
        formatStatePipe,
        formatValuePipe,
        formatBooleanPipe,
        formatSexPipe,
        formatMoneyUnitConvertPipe,
        KeysPipe,
        htmlPipe
    ]
})
export class UtilsModule { }
