import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CertExamineTime } from './certExamineTime.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class CertExamineManageService extends ThsBaseService<CertExamineTime> {
  channelUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-cert-examine-times`);
      this.channelUrl = httpAddressService.EduServe + '/edu-certification-channels';
  }
  findAllChannel(): Observable<HttpResponse<any[]>>  {
  const url = this.channelUrl + '/findAllChannel';
  return this.http.get<any>(`${url}`, { observe: 'response' });
}


}

