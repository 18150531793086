import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BimInfo } from './bim-info.model';
import {ThsBaseService } from '../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class BimInfoService extends ThsBaseService<BimInfo> {

    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-infos`);
    }


  // 新增大赛
  registerBimInfo(param: any) {
    const url =  `${this.resourceUrl}/registerBimInfo`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  /**
   * 获取导出进度
   * @param queryParams 
   * @returns 
   */
  getExportProcess(queryParams ?: any){
    return this.http.get<any>(`${this.resourceUrl}/getExportProcess`,{ params: queryParams, observe: 'response'} );
  }

  /**
   * 删除导出进度数据
   * @param queryParams 
   * @returns 
   */
  removeExportProcess(queryParams ?: any){
    return this.http.get<any>(`${this.resourceUrl}/removeExportProcess`,{ params: queryParams, observe: 'response'} );
  }
}

