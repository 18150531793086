import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { Subject } from '../subject.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { SubjectService } from '../subject.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { CourseService } from '../../course/course.service';
import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CourseCatalogService } from '../../courseCatalog/courseCatalog.service';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';

@Component({
  selector: 'app-subjectaddedit',
  templateUrl: './subjectaddedit.component.html',
  styleUrls: ['./subjectaddedit.component.less']
})
export class SubjectaddeditComponent implements OnInit {


  // // 选项control
  // controlArray: Array<{ id: number, prefix: string, instance: string }> = [];
  // 单选答案
  singeValue = 'A';
  // 判断答案
  judgeValue = '对';
  // 选项组
  options = [
    { optionPrefixContent: 'A', optionContent: '', checked: false }, { optionPrefixContent: 'B', optionContent: '', checked: false },
    { optionPrefixContent: 'C', optionContent: '', checked: false }, { optionPrefixContent: 'D', optionContent: '', checked: false }
  ];

  // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';

  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';

  // 题目类别字典No
  SUBJECT_TYPE = 'SubjectType';
  // 题目难度字典No
  SUBJECT_DIFFICULTY = 'SubjectDifficulty';
  subjectType = [];
  subjectDifficulty = [];

  // 是否需要选项
  isNeedOpt = false;
  // 动态加载禁用List
  disabledList = [true];
  // 选中 题目类型
  selectSubjectType = '2';

  fileList = [];

  form: FormGroup;
  // 题目id
  @Input() questionId = '';
  manualTopic = [];
  autoTopic = [];
  // 实体id
  entityId = '';
  entityName = '';
  courseName = '';
  courseList = [];
  catalogList = [];
  accountName: string;
  prefix = ['A', 'B', 'C', 'D', 'E', 'F', 'G',
    'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U',
    'V', 'W', 'X', 'Y', 'Z'];


  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private subjectService: SubjectService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      isAllowExtract: [false, []],
      subjectType: [null, [Validators.required]],
      eduCourseId: [null, [Validators.required]],
      eduCatalogId: [null, [Validators.required]],
      subjectDifficulty: [null, [Validators.required]],
      subjectSupplyer: [null, [Validators.required]],
      fileId: [null],
      subjectTitle: [null, [Validators.required]],
      subjectDescription: [null],
      optionDTOs: [null]
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.accountName = tokenService.get().account_name;
    this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';
  }

  ngOnInit() {
    // this.addField();
    this.getCourseMsg();
    this.getDictDetials();
    if (!this.isAdd) {
      this.loadData();
      this.getCourseMsg();
    }
  }




  loadData(): void {
    this.msg.loading('加载中');
    this.subjectService.find(this.questionId).subscribe((res: any) => {
      const record = res.body;
      this.msg.remove();
      if (record && record !== null) {
        const param = {
          'eduSubjectId.equals': record.id,
          'sort': ['orderNum,asc']
        };
        this.subjectService.queryQuestionOption(param).subscribe(( res: any) => {
          this.msg.remove();
          const option = res.body;
          this.fileId = record.fileId;
          this.downloadAddress += this.fileId;
          this.form.controls.id.setValue(record.id);
          this.form.controls.isAllowExtract.setValue(record.isAllowExtract === '1');
          this.form.controls.subjectType.setValue(record.subjectType);
          this.form.controls.eduCourseId.setValue(record.eduCourseId);
          this.form.controls.eduCatalogId.setValue(record.eduCatalogId);
          this.form.controls.subjectDifficulty.setValue(record.subjectDifficulty);
          this.form.controls.subjectSupplyer.setValue(record.subjectSupplyer);
          this.form.controls.fileId.setValue(record.fileId);
          this.form.controls.subjectTitle.setValue(record.subjectTitle);
          this.form.controls.subjectDescription.setValue(record.subjectDescription);
          if (option && option !== null && option.length > 0) {
            if (record.subjectType === '2') {
              option.forEach(e => {
                if (e.isRightAnswer === '1') {
                  this.judgeValue = e.optionContent;
                }
              });
            } else if (record.subjectType === '0') {
              option.forEach(e => {
                if (e.isRightAnswer === '1') {
                  this.singeValue = e.optionPrefixContent;
                }
              });
              this.options = option;
            } else {
              option.forEach(e => {
                if (e.isRightAnswer === '1') {
                  e.checked = true;
                } else {
                  e.checked = false;
                }
              });
              this.options = option;
            }
          }
        });
      }
    });
  }

  // 生成题目提交
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    if (this.form.invalid) {
      this.msg.error('请检查题目信息是否完整');
      return;
    }
    param['id'] = this.questionId;
    this.addParam(param);

    if (this.isAdd === true) {
      this.addQuestionInfo(param);
    } else {
      this.modifyQuestionInfo(param);
    }

  }


  modifyQuestionInfo(param: any) {
    this.subjectService.modifyQuestionInfo(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('修改成功');
        this.nzModalRef.destroy();
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addQuestionInfo(param: any) {
    this.subjectService.saveQuestionInfo(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        this.msg.info('保存成功');
        this.nzModalRef.destroy();
      } else {
        this.msg.error('保存失败');
      }
    });
  }

  // 增加选项参数
  addParam(param: any) {
    if (this.selectSubjectType === '2') {
      // 判断题选项
      const options = [{ 'optionPrefixContent': '对', 'isRightAnswer': '0' }, { 'optionPrefixContent': '错', 'isRightAnswer': '0' }];
      if (this.judgeValue === '对') {
        options[0].isRightAnswer = '1';
      } else {
        options[1].isRightAnswer = '1';
      }
      param.optionDTOs = options;
      return;
    }
    if (this.selectSubjectType === '0') {
      // 单选
      this.options.forEach(e => {
        if (e.optionPrefixContent === this.singeValue) {
          e['isRightAnswer'] = '1';
        } else {
          e['isRightAnswer'] = '0';
        }
      });
      param.optionDTOs = this.options;
      return;
    }
    if (this.selectSubjectType === '1') {
      this.options.forEach(e => {
        if (e.checked === true) {
          e['isRightAnswer'] = '1';
        } else {
          e['isRightAnswer'] = '0';
        }
      });
      param.optionDTOs = this.options;
      return;
    }


  }

  // 获取字典详情
  getDictDetials() {
    this.subjectService.findAllByDictNo(this.SUBJECT_TYPE).subscribe(res => {
      if (res.body && res.body != null && res.body.length > 0) {
        this.subjectType = res.body;
      }
    });
    this.subjectService.findAllByDictNo(this.SUBJECT_DIFFICULTY).subscribe(res => {
      if (res.body && res.body != null && res.body.length > 0) {
        this.subjectDifficulty = res.body;
        console.log(this.subjectDifficulty, 'this.subjectDifficulty ');
      }
    });
  }

  // 选中题目类型
  subjectTypeChange(value: string) {
    this.selectSubjectType = value;
    if (value === '2') {
      this.isNeedOpt = false;
    } else {
      this.isNeedOpt = true;
    }
  }
  // 获取课程数据
  getCourseMsg() {
    if (this.accountName === 'admin') {
      // 当登录为admin时查询所有的课程信息
      const isDelete = '0';
      this.subjectService.queryCourseAdmin(isDelete)
        .subscribe((res: any) => {
          if (res.body && res.body.length) {
            this.courseList = res.body;
            console.log(this.courseList);
          }
        });
    } else {
      this.subjectService.queryCourse(this.entityId).subscribe(res => {
        const reconds = res.body;
        if (reconds && reconds !== null && reconds.length > 0) {
          this.courseList = reconds;
        }
      });
    }
  }
  courseChange(courseId: string) {
    console.log(courseId);
    this.catalogList = [];
    this.form.controls.eduCatalogId.setValue(null);
    this.getCatalogMsg(courseId);
  }


  // 获取课程章节数据
  getCatalogMsg(courseId: string) {
    this.subjectService.queryCatalog(courseId).subscribe(res => {
      const reconds = res.body;
      if (reconds && reconds !== null && reconds.length > 0) {
        this.catalogList = reconds;
      }
    });
  }
  // // 选择课程后联动课时
  // provinceChange(courseId: string): void {
  //   this.form.controls.eduCatalogId.setValue('');
  //   const param = {
  //     'eduCourseId.equals': courseId,
  //     'isOk.equals': '1',
  //     'isAudit.equals': '1',
  //     'sort': ['orderNum,asc']
  //   };
  //   this.subjectService.queryCourseCatalogs(param).subscribe((res: any) => {
  //     if (res && res.body && res.body !== null) {
  //       this.catalogList =  res.body;
  //       this.disabledList[0] = false;
  //     }
  //   });
  // }

  // 增加选项
  addField(): void {
    const tmp = { optionPrefixContent: 'A', optionContent: '', checked: false };
    tmp.optionPrefixContent = this.prefix[this.options.length];
    this.options.push(tmp);
  }

  // 移除选项
  removeField(i): void {
    if (this.options.length > 1) {
      const index = this.options.indexOf(i);
      this.options.splice(index, 1);
    }
  }

  prefixChange(i: string, p: boolean) {
    const flag = this.form.controls['prefix' + i].value;
    this.form.controls['prefix' + i].setValue(flag);
  }

  // 上传文件参数
  fileParam = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.questionId,
      fileDesc: file.name,
      uploader: this.entityName,
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }


  beforeUpload = (file: UploadFile): boolean => {
    const format = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf', 'bmp', 'jpg', 'png', 'gif', 'svg', 'jpeg'];
    const type = file.name.substring(file.name.indexOf('.') + 1).toLowerCase();
    if (format.indexOf(type) < 0) {
      this.msg.error('格式错误,仅支持' + format + '格式');
      return false;
    }
    this.fileList.push(file);
    return true;
  }


  handleUploadChange(item: any) {
    if (item.type === 'success') {
      const res = item.file.response;
      this.form.controls['fileId'].setValue(res.id);
    }
  }






















  // @ViewChild('sf') sf: SFComponent;
  // isAdd: boolean;
  // type = '';
  // imgUrl = '';
  // formData: any;
  // record: any = {};
  // submitting = false;
  // resourceType = '';
  // // 实体id
  // entityId = '';
  // // 用户id
  // userId = '';
  // // 实体名
  // personName = '';
  // values: any[];
  // // 字典编码
  // dictNo = 'ResourceType';
  // copyParams: any = {};
  // copyParam: any = {};
  // courselist: any[] = [];
  // form: FormGroup;
  // cataloglist: any[] = [];
  // catalogList = [];
  // // 题目类别字典No
  // SUBJECT_TYPE = 'SubjectType';
  // // 题目难度字典No
  // SUBJECT_DIFFICULTY = 'SubjectDifficulty';
  // subjectTypes = [];
  // subjectDifficultys = [];
  // courseList = [];
  // accountName: string;
  // modulesMap: Map<string, string> = new Map<string, string>();
  // /**
  //   * 选项改变事件
  //   */
  // @Output() ngModelChange = new EventEmitter();
  // constructor(
  //   private fb: FormBuilder,
  //   private modal: NzModalRef,
  //   public msgSrv: NzMessageService,
  //   public subjectService: SubjectService,
  //   public courseService: CourseService,
  //   public courseCatalogService: CourseCatalogService,
  //   @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  // ) {
  //   this.form = this.fb.group({
  //     eduCourseId: [null],
  //     eduCatalogId: [null],
  //     subjectNo: [null],
  //     subjectType: [null],
  //     subjectDifficulty: [null],
  //     subjectSupplyer: [null],
  //     subjectTitle: [null],
  //     subjectSource: [null],
  //     subjectDescription: [null],
  //     isPublic: ['1', []],
  //     isOk: ['1', []],
  //     isAllowExtract: ['1', []],
  //     orderNum: [null],
  //     remark: [null],
  //     rightOptions: [null],
  //   });
  // }

  // ui: SFUISchema = {
  //   '*': {
  //     spanLabelFixed: 140,
  //     spanLabel: 8,
  //     spanControl: 16,
  //     grid: { span: 12 },
  //   }
  // };
  // ngOnInit() {
  //   this.getCourseMsg();
  //   if (!this.isAdd) {
  //     this.getCourseMsg();
  //   } else {
  //     this.subjectService.find(this.record.id)
  //       .subscribe(res => {
  //         // this.formData = this.formatRecord(res.body);
  //         // this.type = this.formData.type;
  //         this.record = res.body;
  //         this.form = this.fb.group({
  //           eduCourseId: [this.record.eduCourseId],
  //           eduCatalogId: [this.record.eduCatalogId],
  //           subjectNo: [this.record.subjectNo],
  //           subjectType: [this.record.subjectType],
  //           subjectDifficulty: [this.record.subjectDifficulty],
  //           subjectSupplyer: [this.record.subjectSupplyer],
  //           subjectTitle: [this.record.subjectTitle],
  //           subjectSource: [this.record.subjectSource],
  //           subjectDescription: [this.record.subjectDescription],
  //           isPublic: [this.record.isPublic === '1' ? '是' : '否'],
  //           isOk: [this.record.isOk === '1' ? '是' : '否'],
  //           isAllowExtract: [this.record.isAllowExtract === '1' ? '是' : '否'],
  //           orderNum: [this.record.orderNum],
  //           remark: [this.record.remark],
  //           rightOptions: [this.record.rightOptions],
  //         });
  //         // this.setModuleName(this.record.eduCatalogId);
  //       });
  //   }
  //   this.personName = this.tokenService.get().entity_name; // 登录人
  //   this.userId = this.tokenService.get().account_id; // 登录id
  //   this.entityId = this.tokenService.get().entity_id;
  //   this.accountName = this.tokenService.get().account_name;
  //   this.getDictDetials();
  // }
  // save() {
  //   // tslint:disable-next-line: forin
  //   for (const i in this.form.controls) {
  //     this.form.controls[i].markAsDirty();
  //     this.form.controls[i].updateValueAndValidity();
  //   }
  //   if (this.form.invalid) return;
  //   this.submitting = true;
  //   this.record = Object.assign(this.record, this.form.value);
  //   if (this.record.id == null) {
  //     this.subjectService.create(this.record).subscribe((response) =>
  //       this.onSaveSuccess(response), (res: HttpResponse<any>) => this.onError(res.body));
  //     this.submitting = false;
  //   } else {
  //     this.subjectService.update(this.record).subscribe((response) =>
  //       this.onUpdateSuccess(response), (res: HttpResponse<any>) => this.onError(res.body));
  //     this.submitting = false;
  //   }
  // }
  // private onSaveSuccess(response) {
  //   this.submitting = true;
  //   setTimeout(() => {
  //     this.msgSrv.success('保存成功');
  //     this.submitting = false;
  //     this.close(true);
  //   }, 1000);
  // }

  // private onUpdateSuccess(response) {
  //   this.submitting = true;
  //   setTimeout(() => {
  //     this.msgSrv.success('更新成功');
  //     this.submitting = false;
  //     this.close(true);
  //   }, 1000);
  // }
  // onError(error: any): void {
  //   this.msgSrv.create('error', error.message);
  // }
  // close(result: any) {
  //   this.modal.destroy(result);
  // }

  // // getcourseval() {
  // //   if (this.tokenData.account_name === 'admin') {
  // //     // 当登录为admin时查询所有的课程信息
  // //     this.copyParams['isPublish.equals'] = '1';
  // //     this.copyParams['isDelete.equals'] = '0';
  // //     this.courseService.query(this.copyParams)
  // //       .subscribe((res: any) => {
  // //         if (res.body && res.body.length) {
  // //           this.courselist = res.body;
  // //         }
  // //       });
  // //   } else {
  // //     if (this.record.id == null) {
  // //       this.copyParams['teacherId.equals'] = this.userId;
  // //       this.copyParams['isPublish.equals'] = '1';
  // //       this.copyParams['isDelete.equals'] = '0';
  // //       this.courseService.query(this.copyParams)
  // //         .subscribe((res: any) => {
  // //           if (res.body && res.body.length) {
  // //             this.courselist = res.body;
  // //           }
  // //         });
  // //     } else {
  // //       this.copyParams['id.equals'] = this.record.eduCourseId;
  // //       this.copyParams['isPublish.equals'] = '1';
  // //       this.copyParams['isDelete.equals'] = '0';
  // //       this.courseService.query(this.copyParams)
  // //         .subscribe((res: any) => {
  // //           if (res.body && res.body.length) {
  // //             this.courselist = res.body;
  // //           }
  // //         });
  // //     }
  // //   }
  // // }
  // // setModuleName(moduleId: string) {
  // //   console.log(moduleId);
  // //   if (moduleId == null) {
  // //     this.msgSrv.error('请选择课程');
  // //   } else if (this.record.id !== null) {
  // //     this.copyParam['eduCourseId.equals'] = this.record.eduCatalogId;
  // //     // this.copyParam['isPublish.equals'] = '1';
  // //     this.courseCatalogService.query(this.copyParam)
  // //       .subscribe((res: any) => {
  // //         this.cataloglist = res.body;
  // //       });
  // //   } else {
  // //     this.copyParam['eduCourseId.equals'] = moduleId;
  // //     this.copyParam['isPublish.equals'] = '1';
  // //     this.courseCatalogService.query(this.copyParam)
  // //       .subscribe((res: any) => {
  // //         this.cataloglist = res.body;
  // //       });
  // //   }
  // // }
  //  // 获取课程数据
  //  getCourseMsg() {

  //   // this.homeworkService.queryCourse(this.entityId).subscribe(res => {
  //   //   const reconds = res.body;
  //   //   if (reconds && reconds !== null && reconds.length > 0) {
  //   //     this.courseList = reconds;
  //   //   }
  //   // });
  //   if (this.accountName === 'admin') {
  //     // 当登录为admin时查询所有的课程信息
  //     const isDelete = '0';
  //     this.subjectService.queryCourseAdmin(isDelete)
  //       .subscribe((res: any) => {
  //         if (res.body && res.body.length) {
  //           this.courseList = res.body;
  //           console.log(this.courseList);
  //         }
  //       });
  //   } else {
  //     this.subjectService.queryCourse(this.entityId).subscribe(res => {
  //       const reconds = res.body;
  //       if (reconds && reconds !== null && reconds.length > 0) {
  //         this.courseList = reconds;
  //       }
  //     });
  //   }
  // }
  // courseChange(courseId: string) {
  //   console.log(courseId);
  //   this.catalogList = [];
  //   this.form.controls.eduCatalogId.setValue(null);
  //   this.getCatalogMsg(courseId);
  // }


  // // 获取课程章节数据
  // getCatalogMsg(courseId: string) {
  //   this.subjectService.queryCatalog(courseId).subscribe(res => {
  //     const reconds = res.body;
  //     if (reconds && reconds !== null && reconds.length > 0) {
  //       this.catalogList = reconds;
  //     }
  //   });
  // }
  // // 获取字典详情
  // getDictDetials() {
  //   this.subjectService.findAllByDictNo(this.SUBJECT_TYPE).subscribe(res => {
  //     if (res.body && res.body != null && res.body.length > 0) {
  //       this.subjectTypes = res.body;
  //     }
  //   });
  //   this.subjectService.findAllByDictNo(this.SUBJECT_DIFFICULTY).subscribe(res => {
  //     if (res.body && res.body != null && res.body.length > 0) {
  //       this.subjectDifficultys = res.body;
  //     }
  //   });
  // }
}
