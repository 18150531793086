import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { FileUploadPreview } from './fileUploadPreview.model';
import {ThsBaseService } from "@shared/components/thsBaseService";
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class FileUploadPreviewService extends ThsBaseService<FileUploadPreview> {
  private filesUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, `/thsadmin/api/sys-files`);
      this.filesUrl = httpAddressService.FileServer + '/sys-file-details';
  }

  /** GET: 根据ID获取某一记录 */
  downloadById(id: string | number): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/download/${id}`;
    return this.http.request('get', url, { observe: 'response' , responseType: 'blob'});
  }

  readablizeBytes(bytes) {
    if (!bytes || bytes === null || bytes === '' || bytes === 0) {
      bytes = 1024;
    }
    var s = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var e = Math.floor(Math.log(bytes)/Math.log(1024));
    return (bytes/Math.pow(1024, Math.floor(e))).toFixed(2)+" "+s[e];
  }

  deleteAllByFk(fk: string): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/fileFk/${fk}`;
    return this.http.delete<HttpResponse<any>>(url, { observe: 'response'});
  }

  updateFkBatch(oldFk: string, newFk: string): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/updateFkBatch?oldFk=${oldFk}&newFk=${newFk}`;
    return this.http.post<HttpResponse<any>>(url,  { observe: 'response'});
  }


  uploadVideo(item: any) {
    const url = `${this.filesUrl}/upload/video`;
    debugger
    return this.http.post<HttpResponse<any>>(url, item, { observe: 'response'});
  }

  
}

