import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService , CascaderOption } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent, CascaderWidget } from '@delon/form';
import { Teacher } from '../teacher.model';
import { TeacherService } from '../teacher.service';
import { map } from 'rxjs/operators';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { PersonnelService } from 'app/routes/admin/organization/personnel/personnel.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';



@Component({
  selector: 'app-edu-resource-edit',
  templateUrl: './edit.component.html',
})
export class EduTeacherEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any ;
  record: any = {};
  submitting = false;
  formData: any;
  imgUrl = '';
  originName = '';
  type = '';
  resourceType = '';
  dictNo = 'CourseType';
  schema: SFSchema = {
    properties: {
      name: {
        type: 'string', title: '名称', maxLength: 50
      },
      birthday: {
        type: 'string', title: '出生日期', ui: { widget: 'date', end: 'end' } , default: new Date()
      },
      idCard: {
        type: 'string', title: '身份证号码', maxLength: 20 ,
        // tslint:disable-next-line:max-line-length
        pattern: '(^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$)|(^[1-9]\\d{5}\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}$)' ,
      },
      signature: {
        type: 'string', title: '个人签名', maxLength: 100
      },
     /*  college: {
        type: 'string', title: '学校', maxLength: 100,
         ui: {
          widget: 'cascader',
          asyncData: () => of(this.teacherService.getSchoolTree()).pipe(delay(1200))
       },
      default: [110000, 110100, 110105]
      },
      department: {
        type: 'string', title: '院系', maxLength: 100
      },  */
      profession: {
        type: 'string', title: '所授专业', maxLength: 20
      },
      certificateCode: {
        type: 'string', title: '资格证号码', maxLength: 50
      },
      jobNum: {
        type: 'string', title: '工号', maxLength: 100
      },
      subjectResult: {
        type: 'string', title: '学科成果',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      honor: {
        type: 'string', title: '所获荣誉',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      courseNum: {
        type: 'number', title: '课程数量', default: 0
      },
      studentNum: {
        type: 'number', title: '学生数量', default: 0
      },
      likePoints: {
        type: 'number', title: '点赞数量', default: 0
      },
      discussion: {
        type: 'number', title: '参与讨论数', default: 0
      }
    },
    required: ['name', 'idCard' , 'birthday']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };


  tokenData: any;
  queryParams2 = {
    fileFk : '',
  };
  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private teacherService: TeacherService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService
  ) {}

  ngOnInit(): void {
    // this.formatRecord();
    // console.log(this.record);
// debugger
   this.teacherService.getSchoolTree();
    const _this_ = this;
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.teacherService.find(this.record.id)
        .subscribe(res => {
          this.formData = this.formatRecord(res.body);
          this.type =  this.formData.type;
        });
    }
  }
  save(resource ?: Teacher) {
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
    if (!this.sf.valid) {
      // 手动触发所有检验项的提示信息 无效
      // this.ui['*'].firstVisual = true;
      // this.sf.refreshSchema();
      this.msgSrv.error('表单校验失败，请检查');
      return ;
    }
    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));
  }

  orgChange(value) {
    console.log(value);
    // this.personalService.find(value).subscribe((res) => {
    //   this.formData['entityName'] = res.body.perName;
    //   this.formData['entityId'] = res.body.id;
    //   this.sf.reset();
    // });
  }

  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }
  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }

  uploadFinish(file: any) {
    if (file && file.success && file.success === true) {
      this.sf.value.imgurl = file.file.id;
    }
  }

  saveResource() {
    const _this_ = this;
    _this_.sf.value.type = this.resourceType;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.teacherService.create(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存资源信息成功');
          });
      } else {
        // if (_this_.imgUrl !== ''){
        //   _this_.sf.value.imgUrl = _this_.imgUrl;
        // }
        // _this_.sf.value.type = this.this.resourceType;
        _this_.teacherService.update(_this_.sf.value)
          .subscribe(res => {
            resolve('更新资源信息成功');
            _this_.imgUrl = '';
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }

  typeChange (obj) {
    this.resourceType = obj;
  }



  selectFinish(item: any) {
    console.log(item);

  }
}
