import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {GuidUtil} from "@shared/utils/guid.util";
import {DA_SERVICE_TOKEN, ITokenService} from "@delon/auth";
import {CacheService} from "@delon/cache";
import {BimSchoolsService} from "../../school/bim-schools.service";
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import {NzMessageService, NzModalRef} from "ng-zorro-antd";
import {BimInfoService} from "../../bim-info/bim-info.service";
import {BimInfoEventsService} from "../../bim-info/bim-info-events/bim-info-events.service";
import {BimTeamsService} from "../../bim-info/bim-teams/bim-teams.service";
import { WorkManageService } from '../../work-manage/work-manage.service';

@Component({
  selector: 'app-reply-file-supple',
  templateUrl: './reply-file-supple.component.html',
  styles: []
})
export class ReplyFileSuppleComponent implements OnInit {

  @ViewChild('projectUpload') projectUpload: any;
  @ViewChild('projectUpload2') projectUpload2: any;
  fileFk1 = GuidUtil.getNewGUIDString();
  fileFk2 = GuidUtil.getNewGUIDString();

  teamInfo: any = {name: '',schoolTop: '',schoolId: ''};
  items = [];
  loading = false;
  isView1 = false;
  isView2 = false;
  // type = 0;
  teamSchoolTop = null;

  bimInfoId = '';
  bimInfoList : any = [];
  infoEventsId = '';
  infoEventsList: any = [];
  schoolId = '';
  schoolList: any = [];
  teamId = '';
  teamList: any = [];
  
  type = 3;

  bimInfo: any;
  isEdit = false;
  workInfo: any;

  isFinishInit = false;

  constructor(
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
    private bimSchoolsService: BimSchoolsService,
    private thsadminFileService: ThsadminFileService,
    private msg: NzMessageService,
    private workManageService: WorkManageService,
    private nzModalRef: NzModalRef,
    private bimInfoService: BimInfoService,
    private bimInfoEventsService: BimInfoEventsService,
    private bimTeamsService: BimTeamsService,
  ) { }

  ngOnInit() {
    this.getAllBimInfo();
  }

  /**
   * 获取所有大赛
   */
  getAllBimInfo() {
    const params = {
      'status.equals': 1,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.bimInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.bimInfoList.push(...res.body);
        if (this.isEdit) {
          if (this.bimInfoList && this.bimInfoList.length > 0) {
            this.bimInfoId = this.bimInfo.id;
            this.getBimInfoEvents(this.bimInfoId);
          }
        }
      }
    })
  }

  /**
   * 根据赛事ID获取赛项
   */
  getBimInfoEvents(value) {
    this.infoEventsId = '';
    this.infoEventsList = [];
    this.items = [];
    this.schoolId = '';
    this.schoolList = [];
    this.teamId = '';
    this.teamList = [];
    const params = {
      'bimInfoId.equals': value,
      'eventsType.equals': 0,
      'isUplaodWork.equals': 1,
      'isDelete.equals': 0,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.isFinishInit = false;
    this.bimInfoEventsService.query(params).subscribe(res => {
      this.getAllSchool(value);
      if (res && res.body) {
        this.infoEventsList.push(...res.body);
        if (this.infoEventsList && this.infoEventsList.length > 0) {
          if (this.isEdit) {
            this.infoEventsId = this.workInfo.eventsId;
          } else {
            this.infoEventsId = this.infoEventsList[0].id;
          }
        }
      }
    })
  }

  getAllSchool(value) {
    if (value && value.length > 0) {
      this.schoolId = '';
      this.schoolList = [];
      this.teamId = '';
      this.teamList = [];
      const params = {
        'bimInfoId.equals': value,
        'name.contains': '',
      };
      this.isFinishInit = false;
      this.bimSchoolsService.queryParticipatingSchools(params).subscribe(res => {
        if (res && res.body) {
          this.schoolList = res.body;
          if (this.schoolList && this.schoolList.length > 0) {
            if (this.isEdit) {
              this.schoolId = this.workInfo.schoolId;
            } else {
              this.schoolId = this.schoolList[0].id;
            }
            this.getTeam(this.infoEventsId,this.schoolId);
          }
        }
      })
    }
  }

  getTeam(eventsId,schoolId) {
    if (eventsId && eventsId.length > 0 && schoolId && schoolId.length > 0) {
      this.schoolId = schoolId;
      this.items = [];
      this.teamId = '';
      this.teamList = [];
      const params = {
        'bimInfoEventsId.equals': eventsId,
        'schoolId.equals': schoolId,
        // 'schoolTop.equals': 1,
        page: 0,
        size: 1000
      };
      this.isFinishInit = false;
      this.bimTeamsService.query(params).subscribe(res => {
        if (res && res.body) {
          this.teamList = res.body;
          if (this.teamList && this.teamList.length > 0) {
            if (this.isEdit) {
              this.teamId = this.workInfo.teamId;
            } else {
              this.teamId = this.teamList[0].id;
            }
            this.getBimInfoByTeamId(this.teamId);
          }
        }
      });
    }
  }


  onClose() {
    this.nzModalRef.close();
  }

  getBimInfoByTeamId(value) {
    if (value && value.length > 0) {
      this.items = [];
      this.bimSchoolsService.getBimTeamById(value).subscribe(res => {
        if (res && res.body) {
          this.teamInfo = res.body;
          this.teamId = this.teamInfo.id;
          this.items = [];
          this.teamSchoolTop = this.teamInfo.schoolTop;
          if (this.teamInfo.schoolTop === 1) {
            res.body.teamItems.forEach(item => {
              if (this.isEdit) {
                if (item.itemId === this.workInfo.itemId) {
                  this.items.push(item);
                }
              } else {
                this.items.push(item);
              }
            });
            this.isFinishInit = true;
          } else {
            this.workManageService.query({
              'teamId.equals': this.teamId,
              'workType.equals': this.type
            }).subscribe(wRes => {
              if (wRes && wRes.body.length > 0) {
                const data = wRes.body;
                const items = [];
                if (this.isEdit) {
                  const rItems = res.body.teamItems.filter(j => j.itemId === this.workInfo.itemId);
                  if (rItems.length > 0) {
                    items.push(rItems[0]);
                  }
                } else {
                  data.forEach(e => {
                    const rItems = res.body.teamItems.filter(j => j.itemId === e.itemId);
                    if (rItems.length > 0) {
                      items.push(rItems[0]);
                    }
                  });
                }
                this.items = items;
              }
              this.isFinishInit = true;
            });
          }
        }
      })
    }
  }

  submitWork1() {
    if (this.projectUpload) {
      this.projectUpload.submitForm();
      if (this.projectUpload.check) {
        const works1 = this.projectUpload.works;
        this.loading = true;
        if (works1 && works1.id) {
          this.workManageService.update(works1).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works1 && !works1.id) {
          works1.workTop = 0;
          this.workManageService.create(works1).subscribe(res => {
            if (res && res.body) {
              this.projectUpload.works.id = res.body.id;
              const data = {
                oldFk: this.fileFk1,
                newFk: res.body.id,
              };
              this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                this.loading = false;
                this.msg.success('提交成功！');
              });
            }
          });
        }
      }
    }
  }

  submitWork2() {
    if (this.projectUpload2) {
      this.projectUpload2.submitForm();
      if (this.projectUpload2.check) {
        const works2 = this.projectUpload2.works;
        this.loading = true;
        if (works2 && works2.id) {
          this.workManageService.update(works2).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works2 && !works2.id) {
          works2.workTop = 0;
          this.workManageService.create(works2).subscribe(res => {
            if (res && res.body) {
              this.projectUpload2.works.id = res.body.id;
              const data = {
                oldFk: this.fileFk2,
                newFk: res.body.id,
              };
              this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                this.loading = false;
                this.msg.success('提交成功！');
              });
            }
          });
        }
      }
    }
  }

}
