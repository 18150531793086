import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimSchools,SchoolPerson } from '../../../school/bim-schools.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimTeamsService } from '../bim-teams.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { UserService } from "../../../../../admin/system/users/users.service";
import { isNullOrUndefined } from 'util';

import {BimInfoSchoolsEditComponent} from '../../bim-info-schools/edit/edit.component';
import { BimItemsService } from '../../../bim-items/bim-items.service';
import {BimTeamItemsService} from '../bim-team-items/bim-team-items.service';
@Component({
  selector: 'app-bim-bimTeams-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimTeamsEditComponent implements OnInit {

  record:any;

   // 是否增加
  isAdd: boolean;
 
  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;
  schoolId:string;
  bimInfoEventsId:string;


  ItemTypes=[];
  Items =[];
  teamItems=[];

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimTeamsService: BimTeamsService,
    private userService: UserService,
    public modal: ModalHelper,
    private bimItemsService:BimItemsService,
    private bimTeamItemsService:BimTeamItemsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required]],
      schoolName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      isDelete: [0, []]
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
     this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {
    this.loadItems();
   

    if (!this.isAdd) {
    
      this.loadData();
      this.loadTeamItems();
    }

   
  }



/**
 * 加载页面data
 */
  loadData(): void {
       this.form.patchValue(this.record);
        this.schoolId = this.record.schoolId;
  }


  // 保存
  submit() {
     debugger;
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }
    param["schoolId"] = this.schoolId;
    param["bimInfoEventsId"] = this.bimInfoEventsId;
    

    if (this.form.invalid) {
      this.msg.error('请检查团队信息是否完整');
      return;
    }

    if (this.teamItems.length === 0) {
      this.msg.error('请选择参赛项目');
      return;
    }


    if (this.teamItems.length > 2) {
      this.msg.error('参赛项目最多可选两项，请进行修改');
      return;
    }

    // 检查团队在大赛是否存在
    this.bimTeamsService.query({
      'schoolId.equals': param['schoolId'],
      'name.equals': param['name'].trim(),
      'email.equals': param['email'].trim(),
      'bimInfoEventsId.equals': param['bimInfoEventsId'],
      'isDelete.equals': 0,
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
        if (data.length > 0) {
          if (this.isAdd === true) {
            this.msg.error('参赛团队在本大赛已存在，请勿重复添加');
            return;
          } else {
            const team = data[0];
            if (team.id === param['id']) {
              this.saveTeamData(param);
            } else {
              this.msg.error('参赛团队在本大赛已存在，请勿重复添加');
              return;
            }
          }
        } else {
          this.saveTeamData(param);
        }
      }
    });
  }

  saveTeamData(param) {
    if (this.isAdd === true) {
      param["regDate"] = new Date();
      param["schoolTop"] = 0;
      param["userDefine1"] ="0";  
      this.addDataInfo(param);
    } else {
      param["schoolTop"] = this.record.schoolTop;
      param["userDefine1"] =this.record.userDefine1;
      param["regDate"] = this.record.regDate;
      this.modifyDataInfo(param);
    }
  }


  modifyDataInfo(param: any) {
    this.bimTeamsService.update(param).subscribe(res => {
      const record = res.body;
      if (record  ) {
        // this.msg.info('修改成功');
        // this.Cancel(true);
        this.saveItems(record.id, '修改成功');
      } else {
        this.msg.error('修改失败');
      }
    });
  }


  addDataInfo(param: any) {
    this.bimTeamsService.create(param).subscribe(res => {
      this.record = res.body;
      if (this.record  ) {
        // this.msg.info('保存成功');
        // this.Cancel(true);
        this.saveItems(this.record.id,'保存成功！');
      } else {
        this.msg.error('保存失败');
      }
    });
  }


  Cancel(type?){
     this.nzModalRef.destroy(type);
  }




    
      
      /**
     * 销毁时删除附件，防止文件表过多
     */
      // tslint:disable-next-line:use-life-cycle-interface
      ngOnDestroy(): void {
        
      }



  selectSchool(){
    this.modal
    .static(BimInfoSchoolsEditComponent)
    .subscribe((res) => {
      debugger;
      if(res !=null && res.length >1)
      {
          this.msg.error('只能选择一个学校，请重新选择！');
          return false;
      }else if(res !=null && res.length === 1)
      { 
          this.record.schoolId = res[0].id;
          this.schoolId  = res[0].id;
          this.record.schoolName =  res[0].name;
          this.form.patchValue(this.record);
      }
     
    });
  }


  loadItems(){
    this.Items=[];
    this.ItemTypes=[];
    let copyParams = {};
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    copyParams['isDelete.equals'] = 0;
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimInfoEventsId, copyParams).subscribe((res: any) => {
      
          this.Items = res.body;
          let tmpType="";
          this.Items.forEach(element => {
               let tmpHasItem = this.ItemTypes.filter(d => d.type === element.type);
               if(element.type != tmpType && tmpHasItem.length <= 0)
               {
                   tmpType = element.type;
                   let tmpTypeObj = {};
                   tmpTypeObj["type"] = tmpType;
                   this.ItemTypes.push(tmpTypeObj);
               }
          });
    });
  }


  loadTeamItems(){
    this.teamItems = [];
    let copyParams = {};
    copyParams['teamId.contains'] = this.record.id;
    this.bimTeamItemsService.query(copyParams).subscribe((res: any) => {
        this.teamItems = res.body;
    });
  }


  saveItems(teamId:string, msg:string){
      let ids=[];
      this.teamItems.forEach(item=>{
          ids.push(item.itemId);
      })

      this.bimTeamItemsService.saveItemByTeam({
        'eventId': this.bimInfoEventsId,
        'teamId': teamId,
        'ItemsIdList': ids.toString()}).subscribe(res => {
          const data = res.body;
          if (data.success) {
            this.msg.info(msg);
            this.nzModalRef.destroy();
          } else {
            this.msg.warning(data.message);
          }

      });
  }

  getItemByType(type){
    return this.Items.filter(d => d.type === type).sort(d=>d.orderSn);
  }


  /**去掉空对象 */
  delNullObj(List: any): any {
    const zhangstr = [];
    for (let k = 0; k < List.length; k++) {
      const obj = List[k];
      if (obj != null)
        zhangstr.push(obj);
    }

    return zhangstr;
  }


    /**
   *
   * @param $event 复选按钮触发事件
   * @param optValue
   */
  checkboxClick($event, itemId: string) {
    
    const target = $event.target;
    if(target.checked === true){
      let tmpItems =  this.teamItems.filter(d=>d.itemId === itemId);   
      if(tmpItems.length <=0)
      {
         let tmpBimItem ={};
         tmpBimItem["itemId"] = itemId;
         tmpBimItem["bimInfoEventsId"] = this.record.id;
         this.teamItems.push(tmpBimItem);
      }
    }else{
      let nindex;
      this.teamItems.forEach((element, index) => {

        if (element.itemId === itemId) {
          nindex = index;
        }
      });

      //删除没有选择项的，并清空空记录
       delete this.teamItems[nindex];
       this.teamItems = this.delNullObj(this.teamItems);
    }
  }

  getCheckBoxValue(itemId: string){
      let tmpItems =  this.teamItems.filter(d=>d.itemId === itemId);  
      if (tmpItems.length > 0)
      {
        return 'true';
      }else{
        return '';
      }

  }
}
