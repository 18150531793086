import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { formatEstablishStatusPipe } from "./formatEstablishStatus.pipe";
import { formatProjectStatusPipe } from "./formatProjectStatusPipe.pipe";
import { formatContractStatusPipe } from "./formatContractStatus.pipe";
import { formatProjectOrderStatusPipe } from "./formatProjectOrderStatus.pipe";
import { formatProjectNicheStatusPipe } from "./formatProjectNicheStatus.pipe";
import { formatProjectClosingStatusPipe } from "./formatProjectClosingStatus.pipe";

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        
    ],
    declarations: [
        formatEstablishStatusPipe,
        formatProjectStatusPipe,
        formatProjectOrderStatusPipe,
        formatContractStatusPipe,
        formatProjectNicheStatusPipe,
        formatProjectClosingStatusPipe
    ],
    exports: [
        formatEstablishStatusPipe,
        formatProjectStatusPipe,
        formatProjectOrderStatusPipe,
        formatContractStatusPipe,
        formatProjectNicheStatusPipe,
        formatProjectClosingStatusPipe
    ]
})
export class UtilsIucceModule { }
