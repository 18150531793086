import { Component, OnInit,OnDestroy, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { BimSchools,SchoolPerson } from '../../../school/bim-schools.model';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { BimPersonService } from '../bim-person.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
@Component({
  selector: 'app-bim-bimPerson-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.less']
})
export class BimPersonProgressComponent implements OnInit {
  entityId:string;
  entityName:String;
  accountName:String;
  exportExcelKeyName:String;
  timer = null;
  public progress= 0;
  public progressTotal=0;

  constructor(
    private fb: FormBuilder,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimPersonService: BimPersonService,
     public modal: ModalHelper,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
     this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {

    if(this.exportExcelKeyName !=null && this.exportExcelKeyName != undefined && this.exportExcelKeyName.length > 0)
    {
        this.timer = setInterval(() => {
          this.getData();
        },1000);
        
    }


   
  }

  getData(){
    let queryParams={};
    queryParams["exportExcelKeyName"] = this.exportExcelKeyName;
      this.bimPersonService.getExportExcelCase(queryParams).subscribe(res => {
        console.log(res);
        console.log(res.body);
            let resultVo = res.body;
            if(resultVo.success){
                this.progress = 100;
                this.progresscComplete();
            }else{
                this.progressTotal =parseInt(resultVo.data) ;
                let progressNum = parseInt(resultVo.message) ;
                if(this.progressTotal === 0)
                {
                  this.progress = 0;
                }else{
                  this.progress =parseFloat( (progressNum/ this.progressTotal * 100).toFixed(0));
                }
                 
                console.log(this.progress);
            }
      });
  }

  progresscComplete(){
         
    if(this.timer)
    {
      clearInterval(this.timer);
      this.timer = null;
    }
    setTimeout(() => {
       this.nzModalRef.destroy(true);
    },3000);

  }

  ngOnDestroy(){
    let queryParams={};
    queryParams["exportExcelKeyName"] = this.exportExcelKeyName;
    this.bimPersonService.removeExportExcelCase(queryParams);
  }
}
