import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { EduBannerEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BannerService } from './banner.service';
import { Resource } from './banner.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  selector: 'app-edu-banner',
  templateUrl: './banner.component.html',
})
export class EduBannerComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: Resource[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'title.contains': '',
    'cotent.contains': '',
    'type.contains': '',
    // sort固定
    // sort: ['orderNum,asc']
  };

   // 字典类型
   viewType = '0';
   // 字典编码
   dictNo = 'BannerType';
  dictDetail = [];

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '标题', index: 'title' , width: '17%'},
    { title: '类型', index: 'type', width: '8%' },
    { title: '图片内容', index: 'cotent', width: '15%' },
    { title: '链接地址', index: 'linkUrl', width: '15%'},
    { title: '图片标识', index: 'code', width: '8%' },
    {
      title: '是否有效', index: 'isOk', className: 'text-center', width: '10%',
      format: (item: any) => {
        if (item.isOk === '1') {
          return '有效';
        } else {
          return '无效';
        }
      },
      filters: [
        { text: '有效', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '无效', value: 0 }  // 此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    { title: '排序号', index: 'orderNum', width: '8%'},
    {
      title: '操作', className: 'text-center',
      buttons: [
        // { acl: true, text: '查看', type: 'modal', component: AdminUsersEditComponent,
        // paramName: 'record', click: () => this.getDataList() },
        { text: '编辑', type: 'modal', component: EduBannerEditComponent, paramName: 'record', click: () => this.getDataList(), },
        { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item); } }
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public resourceService: BannerService
  ) {}

  ngOnInit() {
    this.getDiceDetail();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

     this.resourceService.query(copyParams)
      .subscribe((res: any) => {
         if (res.body && res.body !== null && res.body.length > 0) {
           for (let i = 0; i < res.body.length ; i++) {
            const type = res.body[i].type;
            for (let j = 0; j < this.dictDetail.length ; j++) {
                  if (type === this.dictDetail[j].itemValue1) {
                    res.body[i].type =  this.dictDetail[j].itemText;
                    continue;
                  }
            }
          }
        }
      //  this.getTypeFromDict();
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
       // this.list = res.body;

      });
  }

   getDiceDetail() {
    this.resourceService.findAllByDictNo('BannerType').subscribe(res => {
      if (res.body && res.body.length) {
          this.dictDetail = res.body;
          console.log(this.dictDetail);
      }
      this.getDataList();
    });
  }



  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(EduBannerEditComponent, { record: { id: null } })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
    const tmp = this.formatIsOk(this.selectedRows[0]);
    this.modal
      .static(EduBannerEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }
  // /**
  // * 查看页面
  // * @returns {NzMessageDataFilled}
  // */
  // view() {
  //   if (this.selectedRows.length > 1) return this.msg.warning("只能选择一条数据编辑！");
  //   if (this.selectedRows.length < 1) return this.msg.warning("请先选择一条数据再编辑！");
  //   this.modal
  //     .static(AdminUsersViewComponent, { record: this.selectedRows[0] })
  //     .subscribe(() => this.st.reload());
  // }
  /**
  * 删除
  */
  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.resourceService.delete(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.resourceService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


}
