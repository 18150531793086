import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup} from '@angular/forms';
import { ProjectReportService } from 'app/routes/business/iucce/projectReport/projectReport.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Project } from '../../../projectReport/projectReport.model';

@Component({
  selector: 'app-project-info-view',
  templateUrl: './project-info-view.component.html',
  styleUrls: ['./project-info-view.component.less']
})
export class ProjectInfoViewComponent implements OnInit {

  validateForm: FormGroup;

  @Input() projectId = null;
  @Input() isShowPrjFollowPerson = true;//是否显示项目跟进人信息
  public projectInfo = null;
  public prjFlowPersonList = [];

  @Output() returnProjectInfo = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private projectReportService: ProjectReportService,
  ) {
    this.validateForm = this.fb.group({
      // id: [null, []],
      // region: [{value: null, disabled: true}],
      // province: [{value: null, disabled: true}],
      // schoolName: [{value: null, disabled: true}],
      // projectName: [{value: null, disabled: true}],
      // year: [{value: null, disabled: true}],
      // projectType: [{value: null, disabled: true}],
      // batch: [{value: null, disabled: true}],
      // endDate: [{value: null, disabled: true}],
      // applicant: [{value: null, disabled: true}],
      // applicantTel: [{value: null, disabled: true}],
      // contractStatus: [{value: null, disabled: true}],
      // remark: [{value: null, disabled: true}],
    });

    this.projectInfo = new Project();
    for(const key in this.projectInfo){
       this.validateForm.addControl(key,new FormControl({ value : null,disabled : true }));
    }
    
  }

  ngOnInit(): void {
    this.initProjectData();
  }

  initProjectData() {
    console.log(this.projectId);
    if (this.projectId != null) {
      this.projectReportService.find(this.projectId).subscribe(res => {
        console.log(res);
        if (res && res.ok) {
          this.projectInfo = res.body;
          // for(const key in this.projectInfo){
          //   this.validateForm.addControl(key,new FormControl({ value : this.projectInfo[key],disabled : true }));
          // }
          this.validateForm.patchValue(this.projectInfo);
          if(this.isShowPrjFollowPerson){
            this.getProjectFlowPersons();
          }
          
          this.returnProjectInfo.emit(this.projectInfo);
        }
      });
    }
  }

  /**
   * 获取项目跟进人
   */
  getProjectFlowPersons() {
    this.projectReportService.findProjectFlowinfos(this.projectId).subscribe(res => {
      this.prjFlowPersonList = res.body;
      if (this.prjFlowPersonList && this.prjFlowPersonList.length > 0) {
        this.prjFlowPersonList.forEach(item => {
          this.addFormControl(item);
        });
      }
    });
  }

  addFormControl(item) {
    this.validateForm.addControl('followPerson' + item.id, new FormControl(item.followPersonName));
    this.validateForm.addControl('followNumber' + item.id, new FormControl(item.followPersonTel));
    this.validateForm.addControl('followRemark' + item.id, new FormControl(item.remark));
  }
}
