import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { CourseService } from "../../course/course.service";
import { Course } from "../../course/course.model";
import { APP_PERMISSIONS } from "@shared/app.permissions";

@Component({
  selector: 'app-edu-courseNum',
  templateUrl: './courseNum.component.html',
})
export class EduCourseNumComponent implements OnInit{
  public page: number = 0;
  public size: number = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: Course[] = [];
  record: any = {};
  public totalCallNo: number = 0;
  public selectedRows: SimpleTableData[] = [];

  //HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    //查询搜索框的字段要预置
    'courseName.contains': '',
    'teacherId.contains': ''
    //sort固定
    // sort: ['orderNum,asc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: "id", type: 'checkbox' },
        // { title: '截图', index: 'imgurl' },
    { title: '课程名称', index: 'courseName', width: '20%' },
    { title: '总学时', index: 'totalHours', width: '15%'},
    { title: '开始时间', index: 'startDate', width: '15%'},
    { title: '结束时间', index: 'endDate', width: '15%'},
    {
      title: '课程状态', index: 'courseStatus', className: 'text-center', width: '15%',
      format: (item: any) => {
        if (item.courseStatus === '1'){
          return '正在进行';
        }else if (item.courseStatus === '2'){
          return '即将结束';
        } else{
          return '已结束';
        } 
      },
      filters: [
        { text: '正在进行', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '即将结束', value: 2} , //此处值对应数据库的值，即domain对象的值
        { text: '已结束', value: 3}  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    },
    {
      title: '是否收费', index: 'isCharge', className: 'text-center', width: '15%',
      format: (item: any) => {
        if (item.isCharge === '1'){
          return '是';
        }else{
          return '否';
        } 
      },
      filters: [
        { text: '是', value: 1 }, //此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  //此处值对应数据库的值，即domain对象的值
      ],
      filtered: true,
      filterMultiple: false,
      filter: () => true
    }
  ];


  
  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public courseService: CourseService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    }else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    };
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    copyParams['teacherId.contains'] = this.record.id;
    debugger;
    this.courseService.query(copyParams)
      .subscribe((res: any) => {
        // if (res.body && res.body !== null && res.body.length > 0) {
        //   for (let i = 0; i < res.body.length ; i++) {
        //     // this.resourceService.findTypeFromDict(res.body[i].type)
        //     // .subscribe((res1) => {
        //     //   res.body[i].type =  res1.body.itemText;
        //     //   if (i === res.body.length - 1) {
        //     //     this.list = res.body;
        //     //   }
        //     // });
        //   }
        // }
      //  this.getTypeFromDict();
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
       // this.list = res.body;

      });
  }

  /**
  * 页码数量变动事件
  * @param 
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param 
  */
  filterChange(event) {
    let i = 0;
    let _value = [];
    let _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey +'.'+ _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param 
  */
  sortChange(event) {
    let array = this.queryParams['sort'];
    let length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value == 'descend') {
      value = 'desc';
    } else if (event.value == 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + "," + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + "," + value);
    }
    //排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }

  
 

  

  
  

  
 


}
