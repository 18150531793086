import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { ExamService } from './exam.service';
import { ExamaddteditComponent } from './examaddtedit/examaddtedit.component';
import { ExampriviewComponent } from './exampriview/exampriview.component';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styles: []
})
export class ExamComponent implements OnInit {
  public total;
  public page = 0;
  public size = 10;
  @Input() examId = '';
  allChecked = false;
  indeterminate = false;
  dataList: any[] = [];
  public totalCallNo = 0;
  setOfCheckedId = new Set<string>();
  public APP_PERMISSIONS = APP_PERMISSIONS;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;
  public selectedRows: SimpleTableData[] = [];
  queryParams = {
    // 查询搜索框的字段要预置
    'courseName': '',
    'examTitle': '',
  };
  columns: SimpleTableColumn[] = [];
  constructor(
    private modalHelper: ModalHelper,
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public examService: ExamService,
  ) { }
  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    this.examService.queryExamCourse(copyParams)
      .subscribe((res: any) => {
        this.dataList = res.body.data;
        this.total = res.headers.get('X-Total-Count');

      });
  }
  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = 0;
    this.size = event.ps;
    this.getDataList();
  }
  /**
   * 页码变动事件
   * @param
   */
  changePageIndex(pageIndex) {
    this.page = pageIndex - 1;
    this.getDataList();
  }


  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  /**
  * 新增页面
  */
  add() {
    this.modalHelper.open(ExamaddteditComponent,
      { isAdd: true },
      680,
      { nzClassName: 'exam-modal' })
      .subscribe(() => {
      this.getDataList();
    });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    let examId = '';
    if (this.setOfCheckedId.size === 1) {
      this.setOfCheckedId.forEach(function (element) {
      examId = element;
      });
      this.modalHelper.open(ExamaddteditComponent,
        { isAdd: false , examId: examId},
         680, { nzClassName: 'exam-modal' })
         .subscribe(() => {});
    } else {
      this.msg.info('请选择一条数据');
    }
  }

  edittype(data: any) {
    const tmp = data;
    const examId = tmp.id;
    this.modal
      .static(ExamaddteditComponent, { examId: examId , isAdd: false , })
      .subscribe(() => this.getDataList());
  }


  formatIsOk(item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.examService.deleteExamInfo(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    const validData = this.dataList.filter(value => value.checked === true);
    if (validData.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    validData.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.examService.delete(validData.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  checkAll(value: boolean): void {
    this.dataList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.dataList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  searchTeacherData() {
    this.page = 0;
    this.size = 10;
    this.getDataList();
  }
  priview() {
    let examId = '';
    if (this.setOfCheckedId.size === 1) {
      this.setOfCheckedId.forEach(function (element) {
        examId = element;
      });

      this.modalHelper.open(ExampriviewComponent, {  examId: examId},
        680, { nzClassName: 'exam-modal' }).subscribe(res => {
          if (res) {
          }
        });
    } else {
      this.msg.info('请选择一条数据');
    }
  }
}
