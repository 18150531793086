import { Component, ElementRef, OnInit, ViewChild,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReuseTabService } from '@delon/abc';
import { ProjectReportService } from "../../projectReport/projectReport.service";
import { ProjectReportAuditService } from "../../projectReportAudit/projectReportAudit.service";
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { environment } from '@env/environment';
import { workflowModules } from "@shared/app.constants";
import { WorkflowFormComponent } from '@shared/components/workflow/workflowform.component';
import { ProjectInfoViewComponent } from "./../../component/project-info/view/project-info-view.component";
import { WorkflowService } from '@shared/components/workflow/workflow.service';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '../../../../../shared/components/fileUpload/fileUpload.component';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NzMessageService, NzModalRef,UploadFile } from 'ng-zorro-antd';
import { Project } from '../../projectReport/projectReport.model';

@Component({
  selector: 'app-closing-approve-flow',
  templateUrl: './approveFlow.component.html',
  styleUrls: ['./approveFlow.component.less']
})
export class ProjectClosingApproveFlowComponent implements OnInit {
  @ViewChild('wf') wf: WorkflowFormComponent;
  @ViewChild('projectInfoView') projectInfoView:ProjectInfoViewComponent;
  // validateForm: FormGroup;
  validateWFForm:FormGroup;//工作流表单
  record:any;
  isView = false;
  moduleId = '';
  busiUrl = '';
  customAuditers = [{ name: '', id: '' }];
  taskId = '';
  auditerId = '';
  businessId = '';
  businessCode = '';
  curStepId = '';//当前办理环节id
  isCanEditEsDate = false;
  entityId = '';
  entityName = '';
  id;
  dataSet = [];
  prjFlowPersonList = [];//项目跟进人
  prjNicheList = [];//项目商机
  fileReportList = [];//申报附件
  fileClosingList = [];//结项附件
  downLoadFileAddress;//预览文件地址
  validateForm2: FormGroup;
  submitting = false;
  revokeing = false;
  saveing = false;
  uploadFileAddress = '';//附件上传地址
  
  showUploadListSwitch1 = true;
  showUploadListSwitch2 = true;
  showUploadListSwitch3 = true;

  workflowModules = workflowModules;
  
  btnRevoke = true; // 撤回
  btnSave = true;
  btnSubmit = true;
  canSelectAuditer = true;//
  canInputEstablishDate = false;//是否允许输入立项时间
  canUploadFile = false;//是否允许上传附件

  constructor
    (private fb: FormBuilder, 
      private router: Router,
      private routerActived: ActivatedRoute,
      private projectReportService:ProjectReportService,
      private projectReportAuditService:ProjectReportAuditService,
      private thsadminFileService:ThsadminFileService,
      private workflowService: WorkflowService,
      @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
      private reuseTabService: ReuseTabService,
      private modalHelper: ModalHelper,
      private message: NzMessageService) { 

    this.routerActived.queryParams.subscribe((params) => {
      this.id = params.id;
      this.businessId = params.businessId;
      this.taskId = params.taskId;
      this.isView = params.isView === "true" ? true : false;
    });
    
    this.record = new Project();
    this.moduleId = workflowModules.IucceProjectClosing.moduleId;
    this.businessCode = workflowModules.IucceProjectClosing.businessCode;
    this.busiUrl = workflowModules.IucceProjectClosing.busiUrl;
    this.busiUrl += "?id="+this.id + "&businessId="+this.businessId;
    this.downLoadFileAddress = this.projectReportService.downLoadFileAddress;
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    
    this.uploadFileAddress = projectReportService.uploadFileAddress;
   
  }

  ngOnInit(): void {
    // this.validateForm = this.fb.group({
    //   businessId: [this.businessId, [Validators.required]],
    //   businessCode : [this.businessCode, [Validators.required]]
    // });
    this.projectInfoView.validateForm.addControl('businessId',new FormControl({ value : this.businessId,disabled : true }));
    this.projectInfoView.validateForm.addControl('businessCode',new FormControl({ value : this.businessCode,disabled : true }));
   
    this.validateWFForm = this.fb.group({
      closingDate: [null],
    });
    
    
    // for(const key in this.record){
    //   this.validateForm.addControl(key,new FormControl({ value : null,disabled : true }));
    // }

    this.initFormValue();
    this.getTempAuditIdea();
    this.getProjectFlowPersons();
    this.getProjectNiches();
    this.getFileList();
  }

  returnProjectInfo(event) {
    this.record = event;
    this.validateWFForm.get('closingDate').setValue(this.record['closingDate']);
  }

  initFormValue(){
    // this.projectReportService.find(this.id).subscribe(res=>{
    //   const result = res.body;
    //   if(result != null){
    //     this.record = result;
    //     for(const key in this.record){
    //       const control = this.validateForm.get(key);
    //       if(!control){
    //         continue;
    //       }
    //       control.setValue(this.record[key]);
    //     }
    //     this.validateWFForm.get('closingDate').setValue(this.record['closingDate']);
    //   }
    // })

    let params = {
      'businessId.equals' : this.id,
      "businessCode.equals" : this.workflowModules.IucceProjectClosing.businessCode,
      page : 0,
      size : 1000
    }
    this.projectReportService.getAllBusiformApprovalList(params).subscribe(res=>{
      let result = res.body;
      debugger;
      if(result && result.length > 0){
        this.curStepId = result[0].step;

        // if(this.curStepId == 'closingHOAudit' || this.curStepId == 'closingEduAudit'){
        if(this.curStepId == 'closingEduAudit' || this.wf.wfForm.controls.nextStepName.value === '结束'){//教育部审核，教育部审核为最后一个节点
          //当前节点为教育部审核 需填写结项时间
          this.validateWFForm.controls.closingDate.setValidators([Validators.required]);
          this.isCanEditEsDate = true;
        }
      }
    })
  }

  /**
   * 获取暂存意见
   */
  getTempAuditIdea(){
    let params = {
      'auditType.equals' : 'TempSave',
      'auditerId.equals' : this.entityId,
      'extField1.equals' : this.taskId,
      'businessId.equals' : this.businessId
    };

    this.workflowService.getAuditIdeas(params).subscribe(res=>{
   
      const result = res.body;
      if(result && result.length > 0){
        const auditIdea = result[0].auditIdea;
        const auditResult = result[0].auditResult;
        this.wf.wfForm.get('auditIdea').setValue(auditIdea);
        // this.wf.wfForm.get('auditResult').setValue(auditResult);
      }
    })
  }

  /**
   * 流程状态初始化回调函数
   */
  initWfState(){
    this.canInputEstablishDate = !this.isView || this.wf.wfBtnVisible.wfSubmit;
    this.canUploadFile = !this.isView || this.wf.wfBtnVisible.wfSubmit;
  }

  uploadWfFile(){
    const tmp = {
      fileFk: this.id,
      uploadable:this.canUploadFile,
      deleteable:this.canUploadFile,
      editable:false,
      uploadFileType:['doc','docx','xls','xlsx','pdf','rar','zip','DOC','DOCX','XLS','XLSX','PDF','ZIP','RAR'],
      uploadExtParams:{
        'extField1' : 'IucceProjectClosingAudit',//流程审批附件
        'extField2' : 'IucceProject',
        'extField3' : this.businessId,//业务id
        'extField4' : this.taskId//任务id
      },
      loadExtParams:{
        'extField1.equals' : 'IucceProjectClosingAudit',//流程审批附件
        'extField2.equals' : 'IucceProject'
      }
    };
    this.modalHelper.static(FileUploadComponent, tmp,800, 'md').subscribe((res) => {
        
    });
  }

  /**
   * 获取项目跟进人
   */
  getProjectFlowPersons(){
    this.projectReportService.findProjectFlowinfos(this.id).subscribe(res => {
      const result = res.body;
      if(result != null && result.length > 0){
        this.prjFlowPersonList = result;
        this.prjFlowPersonList.forEach(item => {
          this.addFormControl(item);
        })
      }else{
        this.addFormControl(null);
      }
    },(errorRes: any)=>{
              
    });
  }
  /**
   * 获取项目商机信息
   */
  getProjectNiches(){
    this.projectReportService.findProjectNicheinfos(this.id).subscribe(res => {
      const result = res.body;
      if(result && result.length > 0){
        this.prjNicheList = result;
        this.prjNicheList.forEach(item => {
          this.addOpportunity(item);
        })
      }
    },(errorRes: any)=>{
              
    });
  }


  /**
   * 添加项目跟进人表单控件
   * @param i 
   */
  addFormControl(item) {
    if(item == null){
      item = {
        id: 1,
        followPersonName:null,
        followPersonTel:null,
        remark:null
      };
    }
    let flowPersonObj = item;

    this.projectInfoView.validateForm.addControl('followinfo_'+flowPersonObj.id+'_id', new FormControl(flowPersonObj.id));
    this.projectInfoView.validateForm.addControl('followinfo_' + flowPersonObj.id+'_followPersonName', new FormControl(flowPersonObj.followPersonName));
    this.projectInfoView.validateForm.addControl('followinfo_'+ flowPersonObj.id+'_followPersonTel', new FormControl(flowPersonObj.followPersonTel));
    this.projectInfoView.validateForm.addControl('followinfo_'+flowPersonObj.id+'_remark', new FormControl(flowPersonObj.remark));
    this.projectInfoView.validateForm.addControl('followinfo_' + flowPersonObj.id+'_orderNum', new FormControl(flowPersonObj.orderNum));
  }

  addOpportunity(item) {
    if(item == null){
      item = {
        id: 1,
        estimateOrderDate:null,
        estimateOrderAmount:null,
        nicheDetails:null,
        remark:null,
        orderNum:null
      };
    }
    let obj = item;
    this.projectInfoView.validateForm.addControl('nicheInfo_' + obj.id+'_id', new FormControl(obj.id, Validators.required));
    this.projectInfoView.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderDate', new FormControl(obj.estimateOrderDate, Validators.required));
    this.projectInfoView.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderAmount', new FormControl(obj.estimateOrderAmount,Validators.required));
    this.projectInfoView.validateForm.addControl('nicheInfo_' + obj.id+'_nicheDetails', new FormControl(obj.nicheDetails, Validators.required));
    this.projectInfoView.validateForm.addControl('nicheInfo_' + obj.id+'_remark', new FormControl(obj.remark));
    this.projectInfoView.validateForm.addControl('nicheInfo_' + obj.id+'_orderNum', new FormControl(obj.orderNum));
 }

 getFileList(){
  let params = {
    "fileFk.equals" : this.id,
    "extField2.equals" : 'IucceProject'
    ,"extField3.equals" : 'IucceProjectClosing'
    ,page : 0
    ,size : 10000
    ,sort : ['extField1,asc','createdDate,asc','fileName,asc']
  };
  this.thsadminFileService.queryFiles(params).subscribe(res=>{
    const fileList = res.body;
    let fileReports = [];
    fileList.forEach(item => {
      if (item.extField1 === 'IucceProjectClosingReport') {
        item.fileDesc = '结题报告';
      } else if (item.extField1 === 'IucceProjectClosingOutCome') {
        item.fileDesc = '成果文件';
      } else {
        item.fileDesc = '其他附件';
      }
      fileReports.push(item);
    });
    this.fileReportList = fileReports;
  })
 }

 
 downFile(file) {
  const a = document.createElement('a');
  const url =  environment.SERVER_URL + this.downLoadFileAddress + file.id;
  const filename = file.fileName;
  a.href = url;
  a.download = filename;
  a.click();
}

/**
 * 暂存
 */
save(){
 debugger;
  this.saveing = true;
  // 暂存立项时间和办理意见
  const closingDate = this.validateWFForm.get('closingDate').value;
  let projectInfo = Object.assign({},this.record);
  projectInfo = Object.assign(projectInfo,{ closingDate : closingDate,businessId:this.businessId,businessCode : this.businessCode});
  
  let auditIdeaRecord = {
    businessId : this.businessId,
    auditerId : this.entityId,
    auditerName : this.entityName,
    auditType : 'TempSave',//暂存
    auditResult : this.wf.wfForm.get('auditResult').value,
    auditIdea : this.wf.wfForm.get('auditIdea').value,
    extField1 : this.taskId,
    taskId : this.taskId
  };

  this.projectReportAuditService.saveTempData(projectInfo,auditIdeaRecord).subscribe((res)=>{
    this.saveing = false;
    this.message.success('暂存成功');
  },errorRes=>{
    this.message.success('暂存失败');
    this.saveing = false;
  });
}

submit(entity){
  if (this.wf.wfForm.controls.auditResult.value === '1') {//正常办理
    if (this.validateWFForm.controls.closingDate.invalid) {//总部审核、教育部审核
      this.wf.modalService.error({
        nzTitle: '请选择结项时间！'
      });
      return;
    }
  }

  entity['closingDate'] = this.validateWFForm.get('closingDate').value;
  entity['businessCode'] = this.businessCode;
  entity['businessId'] = this.businessId;

  //流程提交
  this.submitting = true;
  this.projectReportAuditService.submit(entity).subscribe(res=>{
    this.message.success('提交成功');
    this.returnList(true);
    this.submitting = false;
  },(errorRes)=>{
    this.message.success(errorRes.message ? errorRes.message : '提交失败');
    this.submitting = false;
  })
}

/**
 * 撤回
 * @param entity 
 */
revokeForm(entity){
  this.revokeing = true;
  entity.taskId = this.taskId;
  this.projectReportAuditService.revoke(entity).subscribe(res=>{
    this.message.success('撤回成功');
    this.returnList(true);
    this.revokeing = false;
  },(errorRes)=>{
    this.message.success(errorRes.message ? errorRes.message : '撤回失败');
    this.revokeing = false;
  })
}

stepFormPropertyChange(property){
  //加载大区管理员用户作为流程下一环节班里人
  const nextStepId = this.wf.wfForm.get('nextStepId').value;
  const nextStepName = this.wf.wfForm.get('nextStepName').value;
  if(nextStepId == "regionContractAudit"){//大区协议书审核
    this.projectReportService.getProjectRegionUserIds(this.record.regionId,this.record.provinceId).subscribe(res=>{
      this.wf.auditerList = res.body || [];
      if(this.wf.auditerList != null && this.wf.auditerList.length == 1){
        this.wf.wfForm.get('auditerId').setValue(this.wf.auditerList[0].id);
      }
    })
  }
  else if(nextStepName === '结束' || (nextStepId === 'End' || nextStepId === 'end')){
     //当前节点为教育部审核 需填写结项时间
     this.validateWFForm.controls.closingDate.setValidators([Validators.required]);
     this.isCanEditEsDate = true;
  }
}


returnList(isRefresh : boolean) {
  //this.reuseTabService.replace('/business/iucce/projectClosingAudit');
  //this.router.navigateByUrl(`/business/iucce/projectClosingAudit`);
  //this.router.navigate(['/business/iucce/projectClosingAudit'], { queryParams : { timespan : Date.now() },skipLocationChange : true });
  debugger;
  if(!isRefresh){
    this.router.navigate(['/business/iucce/projectClosingAudit']);
  }else{
    this.router.navigate(['/business/iucce/projectClosingAudit'], { queryParams : { time : Date.now() } });
  }

}
  

}
