export class BimInfoProcessNode {
  constructor(
    public  id?: string,
    public  bimInfoProcessId?: string,
    public  processName?: string,
    public  startTime?: string,
    public  endTime?: string,
    public  orderNum?: Number,
    public  createTime?: string,
    public  creator?: string,
    public  updateTime?: string,
    public  updator?: string,
  ) {}
}
