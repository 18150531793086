import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BimTeamStudents } from './bim-team-student.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimTeamStudentsService extends ThsBaseService<BimTeamStudents> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-team-students`);
        console.log(this.resourceUrl);
    }
    
    // 查询团队信息 参数-电话、赛项
    getTeamStudentByTelAndEventId(queryParams ?: any): Observable<any> {
        return this.http.get<any>(`${this.resourceUrl}/getTeamStudentByTelAndEventId`, {params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateArrayFromServer(res)));
    }


}

