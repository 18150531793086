import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimSoftDownloadType } from './bim-soft-type.model';

@Injectable({ providedIn: 'root' })
export class BimSoftDownloadTypeService extends ThsBaseService<BimSoftDownloadType> {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + '/bim-soft-download-types');
  }

  getSyncSoftTypeBimInfo() {
    const url =  `${this.resourceUrl}/getSyncSoftTypeBimInfo`;
    return this.http.get<any>(url, {observe: 'response'});
  }

  syncSoftTypeData(param: any) {
    const url =  `${this.resourceUrl}/syncSoftTypeData`;
    return this.http.get<any>(url, {params: param ,  observe: 'response'});
  }
}
