import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import {  _HttpClient } from '@delon/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpParams, HttpResponse, HttpRequest } from '@angular/common/http';
import { CertExamineResultManService } from '../certExamineResultMan.service';
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { formatDate } from '@angular/common';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-examineResultMan-edit',
  templateUrl: './edit.component.html',
})
export class EduCertExamineResultManEditComponent implements OnInit {
  form: FormGroup;
  record: any = {};
  submitting = false;
  resourceType = '';

  // 字典类型 - 树形下拉
  viewType = '1';
  // 字典编码
  dictNo = 'BIMAutodeskAuth';

  authbool = false;
  examineSubject = '';
  channelId = '';
  examineTimeStr = null;
  type = '';
  certChannelType = [];
  constructor(
    private modal: NzModalRef,
    public msg: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private certExamineResultManageService: CertExamineResultManService,
    private dictDetailService: DictDetailService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      eduCertChannelId: [null, []],
      edu_cert_channel_name: [null, []],
      name: [null, []],
      id_card: [null, []],
      examine_subject: [null, []],
      auth_product: [null, []],
      auth_grade: [null, []],
      examine_type: [null, []],
      charge_type: [null, []],
      examine_time: [null, []],
      is_pass: [null, []],
      result_id: [null, []],
      is_ok: [null, []],
    });
    if (this.record.id != null && this.record.id !== '') {
      this.certExamineResultManageService.findExamineResult(this.record.id, this.record.result_id).subscribe((res: any) => {
        this.record = res.body.data[0];
        this.form = this.createGroup();
      });
    }
  }

  createGroup() {
    const group = this.fb.group({});
    // tslint:disable-next-line:forin
    for (const key in this.record) {
      group.addControl(key, this.fb.control(this.record[key]));
    }
    return group;
  }


  submit() {
    // tslint:disable-next-line:forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    const is_pass = this.form.value.is_pass;
    if (!is_pass) {
      this.msg.error('是否通过考试未选择，请检查');
      return ;
    }

    this.record = Object.assign(this.record, this.form.value);

    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));

  }
  saveResource() {
    const _this_ = this;
    console.log(_this_.form.value);
    return new Promise(function (resolve) {
      _this_.formatIsOk();
        _this_.certExamineResultManageService.saveOrUpdate(_this_.form.value)
          .subscribe((res: any) => {
          const isTrue  = res.body.success;
          if (isTrue) {
            _this_.onSaveSuccess();
            } else {
              _this_.onError();
            }
          });
    });
  }

  formatIsOk() {
    if (this.form.value.isOk === true) {
      this.form.value.isOk = '1';
    } else if (this.form.value.isOk === false) {
      this.form.value.isOk = '0';
    }
  }
   /**
   * 保存成功
   * @param response
   */
   onSaveSuccess() {
    this.msg.success('保存成功');
    this.submitting = false;
    this.close(true);
  }
 /**
   * 请求异常
   * @param error
   */
   onError() {
    this.msg.create('error', '保存失败');
    this.submitting = false;
  }

  /**
   * 关闭窗口
   * @param result 传递参数
   */
  close(result: any) {
    this.modal.destroy(result);
  }


}
