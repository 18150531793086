import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EduSoftwareAuthComponent } from './auth/auth.component';
import { EduSoftwareDownloadComponent } from './download/download.component';
import { EduSoftwareRoutingModule } from './software-download-routing.module';


const COMPONENTS = [
  EduSoftwareAuthComponent,
  EduSoftwareDownloadComponent
];
const COMPONENTS_NOROUNT = [
  
];

@NgModule({
  imports: [
    SharedModule,
    EduSoftwareRoutingModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  providers: [
    // CoursePayDetailsService
  ],
  entryComponents: COMPONENTS_NOROUNT

})
export class EduSoftwareModule { }
