import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class  BimAdvisorsService extends ThsBaseService<any>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.BimServe + '/bim-advisors');
  }

  getBimAdvisorDataList(param: any) {
    return this.http.get<any[]>(`${this.resourceUrl}/getBimAdvisorDataList`, {params: param ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  // 保存评委
  saveAdvisorData(param: any) {
    const url =  `${this.resourceUrl}/saveAdvisorData`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }
}
