import { Component, OnInit,OnDestroy, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';

import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { BimInfoService } from '../../bim-info/bim-info.service';
import { WorkManageService } from '../../work-manage/work-manage.service';
import { MessageHelper } from "@shared/helpers/MessageHelper";
import { ExportProgressService } from './export-progress.service';
import { chunk } from 'lodash';

@Component({
  selector: 'app-bim-export-progress',
  templateUrl: './export-progress.component.html',
  styleUrls: ['./export-progress.component.less']
})
export class ExportProgressComponent implements OnInit {
  entityId:string;
  entityName:String;
  accountName:String;
  @Input() exportKeyName:string;
  isClose:boolean;//是否关闭
  @Input() isShowProgress: boolean;
  @Input() isShowDownLoad: boolean;
  downLoadTitle;//下载文字
  title: String;
  timer = null;
  public progress= 0;
  public progressTotal=0;
  @Input()isShowTitle : boolean;
  @Input() relativePath:string;//相对路径
  @Input() fileName:string;//文件名称
  @Input() btnCloseVisible:boolean;//关闭按钮是否可见
  downLoadBlobList = [];//下载流数组

  constructor(
    private fb: FormBuilder,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
     public modal: ModalHelper,
    private bimInfoService: BimInfoService,
    private workManageService: WorkManageService,
    private exportProgressService: ExportProgressService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
     this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {
    debugger;
    this.isShowProgress = true;
    if(this.exportKeyName && this.exportKeyName.length > 0){
        this.timer = setInterval(() => {
          this.getData();
        },1000);
        
    }


   
  }
  
  

  getData(){
    let queryParams={};
    queryParams["exportKeyName"] = this.exportKeyName;
      this.bimInfoService.getExportProcess(queryParams).subscribe(res => {
        console.log(res);
        console.log(res.body);
            let resultVo = res.body;
            if(resultVo.success){
                this.progress = 100;
                this.progresscComplete();
            }else{
                this.progressTotal =parseInt(resultVo.data) ;
                let progressNum = parseInt(resultVo.message) ;
                if(this.progressTotal === 0)
                {
                  this.progress = 0;
                }else{
                  this.progress =parseFloat( (progressNum/ this.progressTotal * 100).toFixed(0));
                }
                 
                console.log(this.progress);
            }
      });
  }

  progresscComplete(){
    this.isShowProgress = false;
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
    if(this.isClose){
      setTimeout(() => {
        this.nzModalRef.destroy(true);
     },3000);
    }
  }

  downFileOld(){
    // this.relativePath = 'exportWordSubjectResultRar\\网上考核试卷A_07ab0d9b-d2f8-463e-ae08-541c9b3ba244.zip';
    // this.fileName = '网上考核试卷A.zip';
    debugger;
    if(!this.relativePath || !this.fileName){
      MessageHelper.warn('下载失败','提示');
      return;
    }
    this.downLoadTitle = '正在下载，请稍等。。。';
    this.workManageService.downLoadFile(this.relativePath,this.fileName,this.changeSuccessTitle);
  }

  //点击下载文件分片
  downloadChunk(index) {
    var chunkSize = 1024 * 1024 * 5;//5M 为一个分片
    console.log('下载：',index);
    this.exportProgressService.downloadChuckByPath(this.relativePath,this.fileName,index,chunkSize).subscribe(res => {
      console.log(res);
      if(res != null && res.body != null && res.body.size > 0){
        this.downLoadBlobList.push(res.body);

        this.downloadChunk(index + 1);
      }else{
        debugger;
        if(!this.downLoadBlobList || this.downLoadBlobList.length <= 0){
          MessageHelper.warn('没有要下载的文件','提示');
          this.downLoadTitle = '没有要下载的文件';
          this.isShowDownLoad = true;
          return;
        }
        var blob = new Blob(this.downLoadBlobList, {
          type: "application/octet-stream",
        });

        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', this.fileName);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.changeSuccessTitle(true);
      }
    },error => {
      console.log('下载失败：',error);
      MessageHelper.warn('下载失败','提示');
    })
  }

  downFile(){
    debugger;
    if(!this.relativePath || !this.fileName){
      MessageHelper.warn('下载失败','提示');
      return;
    }
    this.downLoadTitle = '正在下载，请稍等。。。';
    this.downloadChunk(1);
  }

  changeSuccessTitle(flag){
    if(flag){
      $('#dvDownLoiadTitle').html('<span style="color:blue">下载成功</span>');
    }else{
      $('#dvDownLoiadTitle').html('<span style="color:red">下载失败</span>');
    }
    
  }

  cancel(){
    this.nzModalRef.destroy(true);
  }

  ngOnDestroy(){
    let queryParams={};
    queryParams["exporKeyName"] = this.exportKeyName;

    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }

    this.bimInfoService.removeExportProcess(queryParams);
  }
}
