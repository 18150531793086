import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { Personnel } from './personnel.model';
import { createRequestOption } from '@shared/utils/request-util';
import { ThsBaseService } from '@shared/components/thsBaseService';

@Injectable({ providedIn: 'root' })
export class PersonnelService  extends ThsBaseService<Personnel>{
    resourceUrl = '/thsadmin/api/org-personnels';

    constructor(http: HttpClient) { 
        super(http, `/thsadmin/api/org-personnels`);
    }

}
