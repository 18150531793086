import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimInfoCompanyService } from '../../bim-info-company/bim-info-company.service'; 

@Component({
  selector: 'app-biminfo-contact-edit',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimInfoContactEditComponent implements OnInit {

  isAdd = false;
  total: any;//总数，用于序号
  record: any = {};
  contentType = 'bimInfoContact';//联系人

  form: FormGroup;
  public loading = false;

  public dataId = null;
  public bimInfoList = [];

  public bimInfoId:string;

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private modalHelp: ModalHelper,
    private bimInfoCompanyService: BimInfoCompanyService,
  ) {
   
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [this.bimInfoId, []],
      contactName: [null, [Validators.required, Validators.maxLength(50)]],
      contactPhone: [null, [Validators.required,Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
      contactEmail: [null, [Validators.required,Validators.email]],
      contactTel:[null, [Validators.pattern(/^(0\d{3}-\d{8}|0\d{3}-\d{7}|0\d{2}-\d{8})$/)]],
      type: [5,[]],
      orderNum: [(this.total + 1), []],
    });
  }

  ngOnInit(): void {
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.dataId = this.record.id;
      this.loadData();
    }
    if(this.isAdd){
      const totalN = parseInt(this.total);
      if(isNaN(totalN)){
        this.total = 0;
      }
      this.form.get('orderNum').setValue((this.total + 1))
    }
  }


  loadData() {
    this.form.patchValue(this.record);
  }

  // 提交
  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    if (!isNullOrUndefined(this.bimInfoId)) {
      subParam['bimInfoId']  = this.bimInfoId;
    }
    console.log(subParam);

    this.loading = true;
    const isUpdate = this.record !== null && !isNullOrUndefined(this.record.id);
    this.bimInfoCompanyService.query({
      'bimInfoId.equals': this.bimInfoId,
      'contactName.equals': subParam['contactName'],
      'contactPhone.equals': subParam['contactPhone'],
      'type.equals': this.form.get('type').value
    }).subscribe(res => {
      if (res && res.body) {
        let data = res.body;
        data = data.filter(w=>w.id != this.record.id);
        if (data.length > 0) {
          this.loading = false;
          this.onError({ 'message': '该联系人和联系人手机号码已存在，请修改！' });
          return;
        } else {
          this.checkSaveData(subParam);
        }
      }
    });
  }

  checkSaveData(subParam: any) {
    subParam['contactName'] = subParam['contactName'].trim();
    subParam['contactEmail'] = subParam['contactEmail'].trim();
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.updateData(subParam);
    } else {
      this.saveData(subParam);
    }
  }

  saveData(subParam: any) {
    subParam['id'] = this.dataId;
    this.bimInfoCompanyService.create(subParam).subscribe(res => {
      this.loading = false;
      this.dataId = res.body.id;
      this.msg.info('保存成功');
      this.modal.close(true);
    }, () => this.loading = false);
  }

  updateData(subParam: any) {
    subParam['updateTime'] = new Date();
    this.bimInfoCompanyService.update(subParam).subscribe(res => {
      this.dataId = res.body.id;
      this.loading = false;
      this.msg.info('保存成功');
      this.modal.close(true);
    }, () => this.loading = false);
  }

  uploadFile(isView: boolean) {
    this.modalHelp.static(FileUploadComponent, {
      fileFk: this.dataId,
      uploadable: !isView,
      deleteable: !isView,
      uploadExtParams: {
        'extField1': this.contentType
      },
      loadExtParams: {
        'extField1.equals': this.contentType
      }
    }, 1200, { nzTitle: '附件上传' })
      .subscribe(res => {
        // this.updateFileCount(item);
      });
  }

  close() {
    this.nzModalRef.destroy();
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }
}
