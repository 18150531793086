import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {Student } from './student.model';
import {ThsBaseService } from "@shared/components/thsBaseService";
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';

@Injectable({ providedIn: 'root' })
export class StudentService extends ThsBaseService<Student> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-students`);
  }
 
  // getTypeFromDict(typeId :string){
  //   this.http.get('/thsadmin/api/sys-dicts/' + typeId, {observe: 'response'}).subscribe((res: HttpResponse<any[]>) => {
  //     this.count = parseInt(res.headers.get('x-total-count'));
  //   });
  // }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
}

queryCourse (queryParams ?: any): Observable<HttpResponse<any>> {
  
  const url = `${this.resourceUrl}/getCourse/`;
  return this.http.get<any>(url, {params: queryParams});
  
}
  // hasUserAccount(userAccount : string): Observable<any>{
  //   const url = `${this.resourceUrl}/hasUserAccount/${userAccount}`;
  //   return this.http.get(url);
  // }

  // updateMultiTenancyIdByEntityId(entityId: string, multiTenancyId: string): Observable<any>{
  //   const url = `${this.resourceUrl}/updateMultiTenancyId/${entityId}/${multiTenancyId}`;
  //   return this.http.get(url);
  // }

}

