import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { ExamModel } from './exam.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExamService extends ThsBaseService<ExamModel> {
  private courseUrl = '';
  private examTypeUrl = '';
  private topicUrl = '';
  private subjectUrl = '';
  constructor(
    http: HttpClient,
    httpAddressService: HttpAddressService,
  ) {
    super(http, httpAddressService.EduServe + `/edu-exams`);
    this.courseUrl = httpAddressService.EduServe + '/edu-courses';
    this.examTypeUrl = httpAddressService.EduServe + '/edu-exam-types';
    this.topicUrl = httpAddressService.EduServe + '/edu-exam-topics';
    this.subjectUrl = httpAddressService.EduServe + '/edu-subjects';
  }
  queryExamCourse(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getExamCourse`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
  queryCourse(teacherId: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/getCourseByTeacherId?teacherId=' + teacherId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryExamType(examId: string): Observable<HttpResponse<any[]>> {
    const url = this.examTypeUrl + '/getExamTypeByExamId?examId=' + examId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  updateExamInfo(param: any) {
    const url = `${this.resourceUrl}/saveExamInfo`;
    const copy = this.convertDateFromClient(param);
    return this.http.post<any>(url, copy, { observe: 'response' }).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
  deleteExamInfo(examId: string) {
    const url = `${this.resourceUrl}/deleteExams?examId=` + examId;
    return this.http.delete<any>(url, { observe: 'response' });
  }
  queryTypeAndTopic(examId: string): Observable<HttpResponse<any[]>> {
    const url = this.topicUrl + '/getTopicAndTypeByExamId?examId=' + examId;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  querySubject(queryParams?: any): Observable<HttpResponse<any[]>> {
    const url = this.subjectUrl + '/getSubjectByCatalogIdAndType';
    return this.http.get<any[]>(url, { params: queryParams, observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryCourseAdmin(isDelete: string): Observable<HttpResponse<any[]>> {
    const url = this.courseUrl + '/getCourseByAdmin?isDelete=' + isDelete;
    return this.http.get<any[]>(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  querySubjectToAuto(queryParams?: any): Observable<HttpResponse<any[]>> {
    const url = this.subjectUrl + '/getSubjectIdToAutoSelect';
    return this.http.get<any[]>(url , { params: queryParams , observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
  queryTopicsByExamId(examId: string): Observable<HttpResponse<any[]>> {
    const url =  this.topicUrl + '/getSelectTopicByExamId?examId=' + examId;
    return this.http.get<any[]>(url , { observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => (res)));
  }
}
