import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent} from '@delon/form';
import { CertIntroduce } from '../certIntroduce.model';
import { CertIntroduceService } from '../certIntroduce.service';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';
import { CertExamineManageService } from '../../certExamineTimeManage/certExamineTime.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-certIntroduce-edit',
  templateUrl: './edit.component.html',
})
export class EduCertIntroduceEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any ;
  record: any = {};
  submitting = false;
  formData: any;
  imgUrl = '';
  originName = '';
  certChannel =  [];
  schema: SFSchema = {
    properties: {

      eduCertChannelId: {
        type: 'string', title: '类型',
        ui: {
          widget: 'select',
        asyncData: () => of(this.getCertChannelType()).pipe(delay(1200)),
      }
      },
      title: {
        type: 'string', title: '图片标题', maxLength: 200
      },
      linkUrl: {
        type: 'string', title: '链接地址', maxLength: 200
      },
      width: {
        type: 'number', title: '图片宽度', default: 10, ui: { unit: '%' }
      },
      remark: {
        type: 'string', title: '备注',
        ui: {
          widget: 'textarea',
          autosize: { minRows: 3, maxRows: 5 },
          spanLabel: 4,
          spanControl: 20,
          grid: { span: 24 }
        }
      },
      orderNum: {
        type: 'integer', title: '排序号', maxLength: 500
      },
      isOk: {
        type: 'boolean', title: '是否有效', default: true
      }
    },
    required: ['title', 'isOk', 'code' ,  'cotent', 'type']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    }
  };


  tokenData: any;
  queryParams2 = {
    fileFk : '',
  };
  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private certIntroduceService: CertIntroduceService,
    private certExamineManageService: CertExamineManageService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService,

  ) {}

  ngOnInit(): void {
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.certIntroduceService.find(this.record.id)
        .subscribe(res => {
          this.formData = this.formatRecord(res.body);
        });
    }
  }
  save(resource ?: CertIntroduce) {
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
    if (!this.sf.valid) {
      // 手动触发所有检验项的提示信息 无效
      // this.ui['*'].firstVisual = true;
      // this.sf.refreshSchema();
      this.msgSrv.error('表单校验失败，请检查');
      return ;
    }

    this.submitting = true;
    this.saveResource().then(function () {
      this.msgSrv.success('保存成功');
      this.submitting = false;
      this.modal.close(true);
    }.bind(this));
  }


  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }
  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }

  uploadFinish(file: any) {
    if (file && file.success && file.success === true) {
      this.sf.value.imgurl = file.file.id;
    }
  }

  saveResource() {
    const _this_ = this;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.certIntroduceService.create(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存成功');
          });
      } else {
        _this_.certIntroduceService.update(_this_.sf.value)
          .subscribe(res => {
            resolve('更新成功');
            _this_.imgUrl = '';
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }


  getCertChannelType(queryParams?: any) {
    const certChannelType = [];
    this.certExamineManageService.findAllChannel().subscribe(res => {
      const list =  res.body;
          this.certChannel = res.body;
          if (list && list.length) {
            list.forEach(element => {
              certChannelType.push({ label: element.title, value: element.id });
            });
      }
    } );
    return certChannelType;
  }


}
