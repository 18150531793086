import { Injectable } from '@angular/core';
import { EduStudyHmResult } from './workdetails.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkdetailsService extends ThsBaseService<any> {
  homeworkurl='';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-study-hm-results`);
    this.homeworkurl = httpAddressService.EduServe + '/edu-homeworks';
  }
  queryHmResultByStuAndCourseAndUser(copyParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getHmResultByStuAndCourseAndUser`;
    return this.http.get<HttpResponse<any>>(url, { params: copyParams, observe: 'response' });
  }
  queryHomeworkToView(homeworkId: string, studentId: string) {
    return this.http.get<any[]>(this.homeworkurl + '/findStudentHomework?homeworkId=' + homeworkId +
    '&studentId=' + studentId , {observe: 'response'})
    .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }
}
