import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EduUserComponent } from './user/user.component';
import { EduResourceComponent } from './resource/resource.component';
import { EduBannerComponent } from './bannerManager/banner.component';
import { EduCourseClassifyComponent } from './courseClassify/courseClassify.component';
import { EduTeacherComponent } from './teacher/teacher.component';
import { EduCourseComponent } from './course/course.component';
import { EduCourseCatalogComponent } from './courseCatalog/courseCatalog.component';
import { EduStudentComponent } from './student/student.component';
import { EduStudyHistoryComponent } from './studyHistory/studyHistory.component';
import { EduCourseAuditComponent } from './courseAudit/courseAudit.component';
import { EduTopicPushComponent } from './topicPush/topicPush.component';
import { EduTopicManagerComponent } from './topicManager/topicManager.component';
import { EduCertChannelComponent } from './cert/certChannelManage/certChannel.component';
import { EduCertExamineTimeComponent } from './cert/certExamineTimeManage/certExamineTime.component';
import { EduCertIntroduceComponent } from './cert/certIntroduceManage/certIntroduce.component';
import { EduCertRegedistComponent } from './cert/certRegedistManage/certRegedistManage.component';
import { EertExamineResultManComponent } from './cert/certExamineResultMan/certExamineResultMan.component';
import { EduCertRegedistPayComponent } from './cert/certRegedistPayManage/certRegedistPayManage.component';
import { SubjectComponent } from './subject/subject.component';
import { HomeworkComponent } from './homework/homework.component';
import { ExamComponent } from './exam/exam.component';

import { ACLGuard } from '@delon/acl';
import { APP_PERMISSIONS } from '@shared/app.permissions';



const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'user', component: EduUserComponent },
      { path: 'resource', component: EduResourceComponent },
      { path: 'banner', component: EduBannerComponent },
      { path: 'courseClassify', component: EduCourseClassifyComponent },
      { path: 'teacher', component: EduTeacherComponent },
      { path: 'course', component: EduCourseComponent },
      { path: 'courseCatalog', component: EduCourseCatalogComponent },
      { path: 'student', component: EduStudentComponent },
      { path: 'studyHistory', component: EduStudyHistoryComponent },
      { path: 'courseAudit', component: EduCourseAuditComponent },
      { path: 'topicPush', component: EduTopicPushComponent },
      { path: 'topicManager', component: EduTopicManagerComponent },
      { path: 'subject', component: SubjectComponent },
      { path: 'homework', component: HomeworkComponent },
      { path: 'certChannel', component: EduCertChannelComponent },
      { path: 'certExamineTime', component: EduCertExamineTimeComponent },
      { path: 'certIntroduce', component: EduCertIntroduceComponent },
      { path: 'certRegedist', component: EduCertRegedistComponent },
      { path: 'certExamineResult', component: EertExamineResultManComponent },
      { path: 'certRegedistPay', component: EduCertRegedistPayComponent },
      { path: 'exam', component: ExamComponent },
      { path: 'software-download', loadChildren: './software-download/software-download.module#EduSoftwareModule' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EduRoutingModule { }
