import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SimpleTableData, SimpleTableComponent } from '@delon/abc';
import { HomeworkService } from './homework.service';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { HomeworkaddeditComponent } from './homeworkaddedit/homeworkaddedit.component';
import { PriviewComponent } from './priview/priview.component';

@Component({
  selector: 'app-homework',
  templateUrl: './homework.component.html',
  styles: []
})
export class HomeworkComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  queryParams = {
    // 查询搜索框的字段要预置
    'courseName': '',
    'catalogName': '',
    'homeworkName': '',
    // sort: ['examStartTime,asc']
  };
  homeworkList: any[] = [];
  selectedRows: SimpleTableData[] = [];
  allChecked = false;
  indeterminate = false;
  setOfCheckedId = new Set<string>();
  public APP_PERMISSIONS = APP_PERMISSIONS;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;
  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    private modalHelper: ModalHelper,
    public modalService: NzModalService,
    public homeworkService: HomeworkService,
  ) { }

  ngOnInit() {
    this.getDataList();
  }
  /**
   * 新增页面
   */
  add() {
    // this.modal
    //   .static(HomeworkaddeditComponent, { record: { id: null } })
    //   .subscribe(() => {
    //     this.getDataList();
    //   });
    this.modalHelper.open(HomeworkaddeditComponent, { isAdd: true }, 680, { nzClassName: 'homework-modal' }).subscribe(() => {
      this.getDataList();
    });
  }
  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    let homeworkId = '';
    if (this.setOfCheckedId.size === 1) {
      this.setOfCheckedId.forEach(function (element) {
        homeworkId = element;
      });

      this.modalHelper.open(HomeworkaddeditComponent, { isAdd: false, homeworkId: homeworkId },
        680, { nzClassName: 'exam-modal' }).subscribe(res => {
          if (res) {
            this.getDataList();
          }
        });
    } else {
      this.msg.info('请选择一条数据');
    }
  }


  edithomework(data: any) {
    const tmp = data;
    this.modal
      .static(HomeworkaddeditComponent, { homeworkId: tmp.id })
      .subscribe(() => this.getDataList());
  }
  formatIsOk(item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }
  /**
 * 获取数据列表
 * @param {string} url
 */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    this.homeworkService.queryHomeworkCourseCatalog(copyParams)
      .subscribe((res: any) => {
        this.homeworkList = res.body.data;
        this.total = res.headers.get('X-Total-Count');
      });
  }

  /**
 * 页码变动事件
 * @param
 */
  changePageIndex(pageIndex) {
    this.page = pageIndex - 1;
    this.getDataList();
  }
  /**
* 删除
*/
  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.homeworkService.deleteHomeworkInfo(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }
  checkAll(value: boolean): void {
    this.homeworkList.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.homeworkList.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  searchTeacherData() {
    this.page = 0;
    this.size = 10;
    this.getDataList();
  }

  // 预览
  priview() {
    let homeworkId = '';
    if (this.setOfCheckedId.size === 1) {
      this.setOfCheckedId.forEach(function (element) {
        homeworkId = element;
      });
      this.modalHelper.open(PriviewComponent, { homeworkId: homeworkId },
        680, { nzClassName: 'exam-modal' }).subscribe(res => {
          if (res) {
            // this.getDataList();
          }
        });
    } else {
      this.msg.info('请选择一条数据');
    }
  }
}
