import { Component, ElementRef, OnInit, ViewChild,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { zip } from 'rxjs';
import { environment } from '@env/environment';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ReuseTabService } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { NicheEditComponent } from 'app/routes/business/iucce/component/niche-edit/edit.component';
import { ProjectNicheInfoComponent } from "app/routes/business/iucce/projectNiche/edit/edit.component";
import { NzMessageService,UploadFile } from 'ng-zorro-antd';
import { COMMON_DATA } from '@shared/app.commonData';
import { DictService } from 'app/shared/components/dict/dict.service';
import { ProjectReportService } from "./../../projectReport/projectReport.service";
import { ProjectNicheService } from './../../projectNiche/projectNiche.service';
import { ProjectReportAuditService } from "./../../projectReportAudit/projectReportAudit.service";
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import * as moment from 'moment';
import { WorkFlowHelper } from "@shared/helpers/WorkFlowHelper";

@Component({
  selector: 'app-project-closing-edit',
  templateUrl: './closing.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less','./closing.component.less']
})
export class ProjectClosingEditComponent implements OnInit {

  validateFormSend: FormGroup;
  projectId;
  fileList;
  dtNicheLoading = false;
  dtNicheFollowLoading = false;
  closeNicheLoading = false;

  total = '0';
  page = 0;
  size = 2;
  pageSizeOptions = [10, 20, 50, 100];
  isCollapse = true;
  submitting = false;
  isCanSubmit = false;
  
  yearList: any;//年份
  monthList = [];
  queryParams = {
    'iucceProjectId':'',
    'iucceProjectNicheId':'',
    'year':[],
    'month':[],
    'orderDateBegin':'',
    'orderDateEnd':'',
    'orderAmountMin':'',
    'orderAmountMax':'',
    'searchValue':'',
  };
  orderStatusList = [
    { value: 11, label: '结项审核中',color: '#18BBFF'},
    { value: 21, label: '结项通过',color: '#46C39E' },
    { value: 31, label: '结项不通过',color: '#FF6D46' },
    { value: null, label: '待成单',color: '#666666' },
    { value: 0, label: '待成单',color: '#666666' },
    { value: 1, label: '部分成单',color: '#FFAE46' },
    { value: 3, label: '全部成单',color: '#46C39E' }
  ]
  //项目商机情况
  prjNicheList = [];
  prjNicheFollowAllList = {};//商机跟进详情

  
  record:any;
  isView = false;
  closeNicheData:any;//关闭商机数据
  prjNicheDataKeys = [];//项目商机情况id key集合
  prjNicheDatas = [];//项目商机情况数据集合
  COMMON_DATA = COMMON_DATA;
  fileListClosing = [];//结项所有附件
  fileListClosingOutCome = [];//成果文件
  fileListClosingReport = [];//结题报告
  fileListClosingElse = [];//其他
  showUploadListSwitch1 = false;
  showUploadListSwitch2 = false;
  showUploadListSwitch3 = false;
  uploadFileAddress;
  entityId ='';
  entityName = '';

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private reuseTabService: ReuseTabService,
    private router: Router,
    private routerActived: ActivatedRoute,
    private dictService: DictService,
    private projectReportService: ProjectReportService,
    private projectNicheService: ProjectNicheService,
    private projectReportAuditService: ProjectReportAuditService,
    private thsadminFileService: ThsadminFileService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
    private modal: ModalHelper,
  ) {
    this.projectId = this.routerActived.snapshot.queryParams['id'];
    this.isView = this.routerActived.snapshot.queryParams['isView'];
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.uploadFileAddress = projectReportService.uploadFileAddress;
   }

  ngOnInit(): void {
    this.validateFormSend = this.fb.group({
      // accepter: [null, [Validators.required]],
      // projectExpirationTime: [null, [Validators.required]],
      auditIdea : [{value : null, disabled : this.isView}, [Validators.required]],
      auditIdeaReturnModify : [{value : null, disabled : true}]
    });

    this.monthList = this.COMMON_DATA.month;
    
    this.getFileList();
    this.getProjectNiches();
    this.getYearListDict();
    this.getAuditIdea();
    
  }

  getYearListDict(){
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
  }

  /**
   * 获取项目商机信息
   */
  getProjectNiches(){
    if(!this.projectId){
      return;
    }
    
    const nicheParams = {
      'iucceProjectId.equals' : this.projectId,
      sort : ['estimateOrderDate,asc','createdDate,asc'],
      page:this.page,
      size:this.size
    };
    const nicheFollowParams = {
      'iucceProjectId.equals' : this.projectId,
      size:10000
    };

    zip(
      //this.projectReportService.findProjectNicheinfos(this.projectId),
      this.projectNicheService.query(nicheParams),
      this.projectNicheService.queryProjectNicheFollowDatas(nicheFollowParams)
    ).subscribe(([nicheRes, nicheFollowRes]) => {
      this.prjNicheList = nicheRes.body;
      this.total = nicheRes.headers.get('X-Total-Count');
      const nicheFollowAllList = nicheFollowRes.body;
      if(this.prjNicheList && this.prjNicheList.length > 0){
        this.prjNicheList.forEach(item => {
          item.expand = false;
          const nicheFollowAllListSel = nicheFollowAllList.filter(w=>w.iucceProjectNicheId == item.id);
          this.prjNicheFollowAllList[item.id] = nicheFollowAllListSel;
        })
      }
      
      
    })
  }

  returnProjectInfo(event) {
    this.record = event;
    //结项申请提交后不允许修改
    if(this.record.prjStatus > 60){
      this.isView = true;
    }
    //商机跟进待提交结项或者退回修改
    debugger;
    if(this.record.prjStatus == 50 || this.record.prjStatus == 59 || this.record.closingStatus == 9){
      this.isCanSubmit = true;
    }
  }

  /**
   * 获取结项意见
   */
  getAuditIdea(){
    debugger;
    const wfParams = WorkFlowHelper.getWorkFlowParams('IucceProjectClosing',{});
    const pageable = { 
      page : 0,
      size : 10000
    };
            
    this.projectReportAuditService.getAuditIdeaByProjectId(this.projectId,wfParams.businessCode,pageable).subscribe(res=>{
      let resultAll = res.body;
      let result = [];
      if(result != null){
        result = resultAll.filter(w=>w.auditStep == '流程发起');
        if(result.length > 0){
          this.validateFormSend.get('auditIdea').setValue(result[0].auditIdea);
        }

        result = resultAll.filter(w=>w.auditResult == '2');//退回修改意见
        if(result.length > 0){
          this.validateFormSend.get('auditIdeaReturnModify').setValue(result[0].auditIdea);
        }
      }
    });
  }

  /**
   * 获取文件列表
   */
  getFileList(){
    let queryParams = {
      "fileFk.equals" : this.projectId,
      "extField2.equals" : 'IucceProject',
      sort:['createdDate,asc']
    };
    this.thsadminFileService.queryFiles(queryParams).subscribe(res=>{ 
      let result = res.body;
      this.showUploadListSwitch1 = false;
      this.showUploadListSwitch2 = false;
      this.showUploadListSwitch3 = false;
      // result = result.filter(w=>w.extField1 == 'IucceProjectReport' || w.extField1 == 'IucceProjectConstract' || w.extField1 == 'IucceProjectElse');//排除立项申报审核和结项审核附件
      let fileReportList = [];
      result.forEach(item => {
        item.uid = item.id;
        item.name = item.fileName;
        item.url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + item.id;

        /**查找申报附件 */
        if (item.extField1 === 'IucceProjectReport') {
          item.fileDesc = '项目申报书';
          fileReportList.push(item);
        } else if (item.extField1 === 'IucceProjectConstract') {
          item.fileDesc = '合同协议';
          fileReportList.push(item);
        } else if (item.extField1 === 'IucceProjectElse') {
          item.fileDesc = '其他附件';
          fileReportList.push(item);
        }else if (item.extField1 === 'IucceProjectReportAudit') {
          item.fileDesc = '项目立项审批附件';
          fileReportList.push(item);
        }

        if (item.extField1 === 'IucceProjectClosingOutCome') {
          item.fileDesc = '成果文件';
          this.fileListClosingOutCome.push(item);
        } else if (item.extField1 === 'IucceProjectClosingReport') {
          item.fileDesc = '项目结题报告';
          this.fileListClosingReport.push(item);
        } else if (item.extField1 === 'IucceProjectClosingElse') {
          item.fileDesc = '其他附件';
          this.fileListClosingElse.push(item);
        }
      });
      
      this.fileList = fileReportList;
      this.showUploadListSwitch1 = true;
      this.showUploadListSwitch2 = true;
      this.showUploadListSwitch3 = true;
    });

    
  }

  
  // 上传文件参数
  fileParamClosingOutCome = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id.replace("add_",""),
      fileDesc: file.name,
      uploader: this.entityName,
      extField1 : 'IucceProjectClosingOutCome',
      extField2 : 'IucceProject',
      extField3 : 'IucceProjectClosing',//代表结项
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }

  // 上传文件参数
  fileParamClosingReport = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id.replace("add_",""),
      fileDesc: file.name,
      uploader: this.entityName,
      extField1 : 'IucceProjectClosingReport',
      extField2 : 'IucceProject',
      extField3 : 'IucceProjectClosing',//代表结项
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }

  // 上传文件参数
  fileParamClosingElse = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id.replace("add_",""),
      fileDesc: file.name,
      uploader: this.entityName,
      extField1 : 'IucceProjectClosingElse',
      extField2 : 'IucceProject',
      extField3 : 'IucceProjectClosing',//代表立项申报
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }

  // 上传之前
  beforeUploadClosingOutCome = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('doc') || fileName.endsWith('docx') || 
      fileName.endsWith('pdf') || fileName.endsWith('rar') || fileName.endsWith('zip');
    if(!isAllowUpload){
      this.message.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.message.error('文件不能大于100M');
      return false;
    }

    return true;
  }

  
  // 上传之前
  beforeUploadClosingReport = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('doc') || fileName.endsWith('docx') || fileName.endsWith('pdf');
    if(!isAllowUpload){
      this.message.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.message.error('文件不能大于100M');
      return false;
    }

    return true;
  }

  // 上传之前
  beforeUploadClosingElse = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || 
                          fileName.endsWith('doc') || fileName.endsWith('docx') || 
                          fileName.endsWith('pdf')  || fileName.endsWith('rar') || fileName.endsWith('zip');
    if(!isAllowUpload){
      this.message.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.message.error('文件不能大于100M');
      return false;
    }
    
    return true;
  }

  handleUploadChange(item: any,param:any) {
    if(!this.isView){
      if (item.type === 'success') {
        debugger;
        const res = item.file.response;
        item.file.id = res.id;
        item.file.fileName = res.name;
      }else if(item.type === "removed" && 
        (item.file.id || (item.file.response != null && item.file.response.id != null && item.file.response.id != ''))){
        //删除文件
        const fileId = item.file.id ? item.file.id : item.file.response.id;
        this.projectReportService.deleteProjectFile(fileId).subscribe(res=>{
          if(res.status == 200){
          }else{
            this.message.error("删除文件失败！");
          }
        });
      }
    }
  }

  downFile(file) {
    const a = document.createElement('a');
    const filename = file.fileName;
    a.href = file.url;
    a.download = filename;
    a.click();
  }
  
  //商机跟进情况-成单状态
  orderStatusLabel(orderStatus) {
    const label =  this.orderStatusList.filter(item => item.value == orderStatus);
    if(label == null || label.length <= 0){
      return "";
    }
    return label[0].label;
  }
  orderStatusColor(orderStatus) {
    const label =  this.orderStatusList.filter(item => item.value == orderStatus);
    if(label == null || label.length <= 0){
      return "";
    }
    return label[0].color;
  }
  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  //查看
  viewNiche(data) {
    this.modal.static(ProjectNicheInfoComponent, {
      pageType: 'view',
      record:data
    }, 800).subscribe();
  }

  //查看商机跟进详情
  viewNicheFollow(data){
    this.modal.static(NicheEditComponent, {
      pageType: 'view',
      record:data
    }, 1080).subscribe();
  }

  getNicheFollowDataList(data,isReset?: boolean){
    const copyParams = {};
    
    const q = this.queryParams;
    this.dtNicheFollowLoading = true;
    if (isReset === true) {
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          debugger;
          if(key==="orderDateBegin"){
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 00:00:00';
          }else if(key==="orderDateEnd"){
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 23:59:59';
          }
          else if(typeof q[key] === 'object' && q[key]){
            copyParams[key] = q[key].join(",");
          }else{
            copyParams[key] = q[key];
          }
        }
      });
    }
    this.queryParams['iucceProjectId'] = this.projectId;
    this.queryParams['iucceProjectNicheId'] = data.id;
    copyParams['iucceProjectId'] = this.projectId;
    copyParams['iucceProjectNicheId'] = data.id;

    copyParams['page'] = 0;
    copyParams['size'] = 10000;

    this.projectNicheService.getAllIucceProjectNicheFollowDatas(copyParams)
      .subscribe((res: any) => {
        
        this.dtNicheFollowLoading = false;
        const nicheFollowAllList = res.body;
        this.prjNicheFollowAllList[data.id] = nicheFollowAllList;
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getProjectNiches();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getProjectNiches();
  }


  submitForm() {
    const _this_ = this;

    for (const i in this.validateFormSend.controls) {
      this.validateFormSend.controls[i].markAsDirty();
      this.validateFormSend.controls[i].updateValueAndValidity();
    }
    
    if(this.validateFormSend.invalid){
      this.message.error("请检查信息是否完整!");
      return;
    }

    if(this.fileListClosingOutCome == null || this.fileListClosingOutCome.length <= 0){
      this.message.error("请先上传项目成果文件!");
      return;
    }
    if(this.fileListClosingReport == null || this.fileListClosingReport.length <= 0){
      this.message.error("请先上传结项结题报告!");
      return;
    }
    this.submitting = true;
    let recordInfo = Object.assign({},this.record);
    recordInfo = Object.assign(recordInfo,this.validateFormSend.value);
    

    return new Promise(function (resolve) {
      let param = WorkFlowHelper.getWorkFlowParams('IucceProjectClosing',recordInfo);
     
      _this_.projectReportAuditService.send(recordInfo,param)
        .subscribe((response) => {
          _this_.submitting = false;
          if(response){
            _this_.message.success('提交成功');
            _this_.returnList(true);
            resolve('提交成功');
          }else{
            _this_.message.success('提交失败');
            resolve('提交失败');
          }
        },
        (resError) => {
          _this_.message.success('提交失败');
          _this_.submitting = false;
          resolve('提交失败');
        }
      );
    });
  }

  returnList(isRefresh:boolean) {
    //this.reuseTabService.replace('/business/iucce/projectClosing');
    if(!isRefresh){
      this.router.navigate(['/business/iucce/projectClosing']);
    }else{
      this.router.navigate(['/business/iucce/projectClosing'], { queryParams : { time : Date.now() } });
    }
  }

}
