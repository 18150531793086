import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {CourseSummarize } from './courseSummerMan.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CourseSummerManService extends ThsBaseService<CourseSummarize> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-course-summarizes`);

  }

}

