import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ArrayService } from "@shared/utils/array.service";

import { Duty } from './duty.model';
import { createRequestOption } from '@shared/utils/request-util';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DutyService extends ThsBaseService<Duty> {
    protected resourceUrl = '/thsadmin/api/org-dept-duties';

    constructor(
        protected http: HttpClient,
        protected arrayService: ArrayService
        ) {
            super(http, `/thsadmin/api/org-dept-duties`);
         }


    //获取岗位树
  getDutyTree = Observable.create((observer) => {
    let dutyList;
    let queryParams = {
      'size' : 100000
    };
    this.query(queryParams).subscribe(res => {
        dutyList = res.body;
        let permissionTreeNodes = this.arrayService.arrToTreeNode(dutyList, {
          parentIdMapName: "parentDuty",
          titleMapName: 'dutyName'
        });
        observer.next(permissionTreeNodes);
        observer.complete();
    });
  }); 

      /** GET: 分页、多条件查询记录列表 */
    queryPersonDuty (queryParams ?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>('/thsadmin/api/org-personnel-duties',  {headers: {"ignoreAuth": "true"},params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
    }

}
