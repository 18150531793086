import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { CourseService } from '../../course/course.service';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { HomeworkService } from '../../homework/homework.service';
import { HomeworktopicService } from '../homeworktopic.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { CourseCatalogService } from '../../courseCatalog/courseCatalog.service';
import { SFComponent } from '@delon/form';

@Component({
  selector: 'app-topicaddedit',
  templateUrl: './topicaddedit.component.html',
  styles: []
})
export class TopicaddeditComponent implements OnInit {

  @ViewChild('sf') sf: SFComponent;
  type = '';
  imgUrl = '';
  formData: any;
  resourceType = '';
  // 实体id
  entityId = '';
  // 用户id
  userId = '';
  // 实体名
  personName = '';
  public page = 0;
  public size = 10;
  form: FormGroup;
  record: any = {};
  submitting = false;
  copyParam: any = {};
  copyParams: any = {};
  courselist: any[] = [];
  homworklist: any[] = [];
  cataloglist: any[] = [];
  constructor(
    private fb: FormBuilder,
    public http: _HttpClient,
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public courseService: CourseService,
    public homeworkService: HomeworkService,

    public homeworktopicService: HomeworktopicService,
    public courseCatalogService: CourseCatalogService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      eduSubjectId: [null],
      eduCourseId: [null],
      eduCatalogId: [null],
      eduHomeworkId: [null],
      eduHomeworkTypeId: [null],
      subjectNo: [null],
      subjectTitle: [null],
      orderNum: [null],
      subjectOptions: [null],
      rightAnswer: [null],
      subjectDescription: [null],
    });
  }

  ngOnInit() {
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.homeworktopicService.find(this.record.id)
      .subscribe(res => {
        this.formData = this.formatRecord(res.body);
          this.type =  this.formData.type;
        this.record = res.body;
        this.form = this.fb.group({
          eduSubjectId: [this.record.eduSubjectId],
          eduCourseId: [this.record.eduCourseId],
          eduHomeworkId: [this.record.eduHomeworkId],
          eduHomeworkTypeId: [this.record.eduHomeworkTypeId],
          subjectTitle: [this.record.subjectTitle],
          subjectNo: [this.record.subjectNo],
          orderNum: [this.record.orderNum],
          subjectOptions: [this.record.subjectOptions],
          rightAnswer: [this.record.rightAnswer],
          subjectDescription: [this.record.subjectDescription],
        });
      });
    }
    this.personName = this.tokenService.get().entity_name; // 登录人
    this.userId = this.tokenService.get().account_id; // 登录id
    this.entityId = this.tokenService.get().entity_id;
    this.getcourseval();
    this.gethomworkList();
  }
  // 获取主表id
  gethomworkList(isReset?: boolean) {
    let copyParams = {};
    // const q = this.queryParams;
    // if (isReset === true) {
    //   this.page = 0;
    //   Object.keys(q).forEach(function (key) {
    //     q[key] = '';
    //   });
    // } else {
    //   Object.keys(q).forEach(function (key) {
    //     if (q[key] !== '' && q[key] !== null) {
    //       copyParams[key] = q[key];
    //     }
    //   });
    // };
    // copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    // console.log(copyParams);
    this.homeworkService.query(copyParams)
      .subscribe((res: any) => {
        this.homworklist = res.body;
        // this.total = res.headers.get('X-Total-Count');
        console.log(this.homworklist, 'this.homworklist');
      });
  }
  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }
  // 课程，课程章节信息查询
  getcourseval() {
    // this.copyParams['teacherId.equals'] = this.userId;
    // this.copyParams['isPublish.equals'] = '1';
    // this.copyParams['isDelete.equals'] = '0';
    // this.courseService.query(this.copyParams)
    //   .subscribe((res: any) => {
    //     if (res.body && res.body.length) {
    //       this.courselist = res.body;
    //     }
    //   });
    if (this.record.id == null) {
      this.copyParams['teacherId.equals'] = this.userId;
      this.copyParams['isPublish.equals'] = '1';
      this.copyParams['isDelete.equals'] = '0';
      this.courseService.query(this.copyParams)
        .subscribe((res: any) => {
          if (res.body && res.body.length) {
            this.courselist = res.body;
          }
        });
    } else {
      this.copyParams['id.equals'] = this.record.eduCourseId;
      this.copyParams['isPublish.equals'] = '1';
      this.copyParams['isDelete.equals'] = '0';
      this.courseService.query(this.copyParams)
        .subscribe((res: any) => {
          if (res.body && res.body.length) {
            this.courselist = res.body;
          }
        });
    }

  }
  setModuleName(moduleId: string) {
    if (moduleId == null) {
      this.msgSrv.error('请选择课程');
    } else {
      this.copyParam['eduCourseId.equals'] = moduleId;
      this.copyParam['isPublish.equals'] = '1';
      this.courseCatalogService.query(this.copyParam)
        .subscribe((res: any) => {
          this.cataloglist = res.body;
        });
    }
  }
  // 关闭窗口
  close(result: any) {
    this.modal.destroy(result);
  }
  // 保存
  save() {
    this.record = Object.assign(this.record, this.form.value);
    this.submitting = true;
    if (this.record.id == null) {
      this.record['createdBy'] = this.personName;
      this.record['createdTime'] = new Date();
      this.homeworktopicService.create(this.record).subscribe((resp) => {
        if (resp.body && resp.body.id !== null) {
          this.msgSrv.info('添加成功');
          this.close(true);
        } else {
          this.msgSrv.info('添加失败');
          this.close(false);
        }
        this.submitting = false;
      });
    } else {
      this.record['lastModifiedBy'] = this.personName;
      this.record['lastModifiedDate'] = new Date();
      this.homeworktopicService.update(this.record).subscribe((resp) => {
        if (resp.body && resp.body.id !== null) {
          this.msgSrv.info('更新成功');
          this.close(true);
        } else {
          this.msgSrv.info('更新失败');
          this.close(false);
        }
        this.submitting = false;
      });
    }
  }

}
