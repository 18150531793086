import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Organization } from './organization.model';
import {ThsBaseService } from "@shared/components/thsBaseService";

@Injectable({ providedIn: 'root' })
export class OrganizationService extends ThsBaseService<Organization> {
  constructor(protected http: HttpClient) {
      super(http, `/thsadmin/api/org-organizations`);
  }

}

