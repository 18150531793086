import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { BimSoftDownloadTypeService } from '../soft-type/bim-soft-type.service';

@Component({
  selector: 'app-sync-soft-data',
  templateUrl: './sync-soft-data.component.html',
  styleUrls: [],
})
export class SyncSoftDataComponent implements OnInit {
  form: FormGroup;

  public isSaving = false;

  public bimInfoList = [];

  public bimInfoId = null;
  public bimInfoName = null;

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    public modalService: NzModalService,
    private bimSoftDownloadTypeService: BimSoftDownloadTypeService,
  ) {
    this.form = this.fb.group({
      bimInfoId: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getBimInfo();
  }

  getBimInfo() {
    this.bimSoftDownloadTypeService.getSyncSoftTypeBimInfo().subscribe(res => {
      const data = res.body;
      data.forEach(e => {
        if (e.id != this.bimInfoId) {
          this.bimInfoList.push(e);
        }
      });
    });
  }

  submit() {
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      subParam[key] = this.form.controls[key].value;
    }
    if (this.form.invalid) {
      return;
    }
    
    this.modalService.confirm({
      nzTitle: '确认同步将删除原软件下载数据，确认同步?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isSaving = true;
        subParam['syncBimInfoId'] = this.bimInfoId;
        this.bimSoftDownloadTypeService.syncSoftTypeData(subParam).subscribe(res => {
          this.msg.remove();
          this.msg.info('同步成功');
          this.isSaving = false;
          this.close(true);
        }, () => (this.isSaving = false));
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  close(type?) {
    this.nzModalRef.destroy(type);
  }
}
