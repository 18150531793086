import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { HttpAddressService } from '@shared/session/http-address.service';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { NzMessageService, NzModalService, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BimInfoEventsService } from '../bim-info/bim-info-events/bim-info-events.service';
import { BimSchoolsService } from '../school/bim-schools.service';
import {BimWorkCoresService} from '../bim-work-cores/bim-work-cores.service';


  import { from } from 'rxjs';
import { BimAdvisorsService } from '../bim-advisor/bim-advisor.service';
import { BimAdvisoReplyEditComponent } from './edit/edit.component';
import { BimAdvisorReplyWorkComponent } from './bim-advisor-work/bim-advisor-reply-work.component';
@Component({
  selector: 'app-bim-advisor-reply',
  templateUrl: './bim-advisor-reply.component.html',
  styleUrls: []
})
export class BimAdvisorReplyComponent implements OnInit {

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public currentBimInfo = null;
  public bimInfoStatus = -1;

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public audoDistLoading = false;

  bimEventsList = [];
  curBimEventId = null;
  public queryParams = {
    // 查询搜索框的字段要预置
    'location': null,
    'province': null,
    'name': null,
    // sort固定
    sort: ['bis.id,desc', 'tb.groupName,desc']
  };

  columns: SimpleTableColumn[] = [
    { title: '单位名称', index: 'orgName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '姓名', index: 'name', width: '9%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '电话', index: 'tel', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '大区', index: 'locationName', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '省份', index: 'provinceName', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '组别', index: 'groupName', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '评分专项', index: 'itemName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },

    { title: '分配作品', index: 'advisorWorkCount', width: '8%', format: (item: any, col: any) => this.formatIeCol(item, col)  },

    // { title: '类别', index: 'advisorType', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    // { title: '注册码', index: 'regCode', width: '10%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '操作', width: '10%',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '作品', click: (item: any) => this.showWorkCores(item) },
        { text: '删除', click: (item: any) => this.remove(item) },
      ],
    }
  ];

  public regionList = [];
  public initProvinceList = [];
  public provinceList = [];

  // 上传 弹框显示
  public uploadIsVisible = false;
  // 导入工作量 excel
  public excelFile: UploadFile[] = [];
  // 是否上传中
  public excelUploading = false;
  // 导入接口
  public importUrl = '';

  public nullDataList = [];
  public errLocationList = [];
  public errProvinceList = [];
  public errPhoneList = [];
  public errEmailList = [];
  public errItemList = [];

  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private userService: UserService,
    private modalService: NzModalService,
    private bimSchoolsService: BimSchoolsService,
    private httpAddressService: HttpAddressService,
    private bimAdvisorsService: BimAdvisorsService,
    private bimInfoEventsService: BimInfoEventsService,
    private bimWorkCoresService: BimWorkCoresService,
  ) {
    this.importUrl = httpAddressService.BimServe + '/bim-advisors/importReplyAdvisor';
  }

  ngOnInit(): void {
    // this.getDataList();
    this.initData();
  }

  initData() {
    // 加载区域与省份数据
    const dataParams = {
      'size': '10000',
      'sort': 'orderNum,asc'
    };
    this.userService.queryRegionList(dataParams).subscribe(res => {
      if (res && res.body) {
        this.regionList = res.body;
      }
    });
    this.userService.queryProvinceList(dataParams).subscribe(res => {
      if (res && res.body) {
        this.initProvinceList = res.body;
      }
    });
  }

  locationChange(regionId) {
    this.queryParams['province'] = null;
    if (!isNullOrUndefined(regionId)) {
      this.provinceList = this.initProvinceList.filter(e => e.fkregionId === regionId);
    } else {
      this.provinceList = [];
    }
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimAdvisorsService.getBimAdvisorDataList(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key !== 'bimInfoEventsId') {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    copyParams['isReply'] = 1;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
   * 获取赛事赛项
   * @param isReset
   */
  public getEventsDataList() {

    const copyParams = {
      'page': 0,
      'size': 1000,
      'bimInfoId.contains': this.currentBimInfo.id,
      'eventsType.equals': '0',
      'isUplaodWork.equals': '1',
      'isDelete.equals': 0,
      sort: ['createTime,desc']
    };

    this.isloadding = true;
    this.bimInfoEventsService.query(copyParams).subscribe((res: any) => {
      if (res.ok && res.body.length > 0) {
        this.bimEventsList = res.body;
        this.curBimEventId = this.bimEventsList[0].id;
        this.queryParams['bimInfoEventsId'] = this.curBimEventId;
        this.getDataList();
      } else {
        this.isloadding = false;
        this.bimEventsList = [];
        this.curBimEventId = '-1';
        this.queryParams['bimInfoEventsId'] = '-1';
        this.list = [];
        this.page = 0;
        this.size = 10;
        this.total = '0';
        // this.getDataList();
      }
    });
  }

  /**
  * 新增页面
  */
  add() {
    if (isNullOrUndefined(this.curBimEventId) || this.curBimEventId === '-1') {
      this.msg.info('当前赛事没有团队赛项，不能添加数据!');
      return false;
    }
    this.modal.static(BimAdvisoReplyEditComponent, {
      isAdd: true,
      regionList: this.regionList,
      initProvinceList: this.initProvinceList,
      bimInfoEventsId: this.curBimEventId
    }).subscribe((res) => {
      console.log(res);
      if (res === 'success') {
        this.getDataList();
      }
    });
  }

  /**
   * 编辑页面
   */
  edit(item) {
    this.modal.static(BimAdvisoReplyEditComponent, {
      isAdd: false,
      record: item,
      regionList: this.regionList,
      initProvinceList: this.initProvinceList,
      bimInfoEventsId: this.curBimEventId
    }).subscribe((res) => {
      if (res === 'success') {
        this.getDataList();
      }
    });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.isloadding = true;
        this.bimAdvisorsService.delete(item.id).subscribe(res => {
          this.isloadding = false;
          this.msg.success('删除成功！');
          this.page = 0;
          this.getDataList();
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    // this.getDataList();
    this.getEventsDataList();
  }

  /**
   * 赛项选择框改变
   * @param $event
   */
  bimEventsChange($event) {
    this.curBimEventId = $event;
    this.queryParams['bimInfoEventsId'] = this.curBimEventId;
    this.getDataList();
  }

  // 导入全国评委信息
  handleUpload(): void {
    if (isNullOrUndefined(this.curBimEventId) || this.curBimEventId === '-1') {
      this.msg.info('当前赛事没有团队赛项，不能添加数据!');
      return;
    }
    const formData = new FormData();
    this.excelFile.forEach((file: any) => {
      formData.append('file', file);
      formData.append('bimInfoEventsId', this.curBimEventId);
    });
    if (this.excelFile.length === 0) {
      this.msg.remove();
      this.msg.info('请选择导入文件！');
      return;
    }
    this.excelUploading = true;
    const req = new HttpRequest('POST', this.importUrl, formData, {});
    this.http
      .request(req)
      .pipe(filter(e => e instanceof HttpResponse))
      .subscribe(
        (event: any) => {
          const result = event.body;
          this.excelUploading = false;

          if (result.message !== 'success') {
            let title = '文件错误';
            let content = result.message;
            if (result.message === 'errorTem') {
              content = '上传模板错误，请使用正确的模板';
            } else if (result.message === 'errorLogin') {
              content = '获取登录用户失败，请重新登录';
            } else {
              this.nullDataList = result.nullDataList;
              this.errLocationList = result.errLocationList;
              this.errProvinceList = result.errProvinceList;
              this.errPhoneList = result.errPhoneList;
              this.errEmailList = result.errEmailList;
              this.errItemList = result.errItemList;

              title = '其他数据已上传成功：';
              content = this.modalWarning;
            }
            this.modalService.warning({
              nzTitle: title,
              nzContent: content,
              nzWidth: '60vh'
            });
          } else {
            this.msg.success('导入成功');
          }
          this.handleCancel();
          this.getDataList();
          this.excelFile = [];
        },
        err => {
          this.excelUploading = false;
          this.msg.error('upload failed.');
        }
      );
  }

  // 打开上传弹框
  openUploadnModal() {
    this.uploadIsVisible = true;
  }

  // 关闭上传弹框
  handleCancel() {
    this.excelFile = [];
    this.uploadIsVisible = false;
    this.excelUploading = false;
  }

  // 上传之前
  beforeUpload = (file: UploadFile): boolean => {
    const isXlsx = file.name.endsWith('xlsx') || file.name.endsWith('xls');
    if (!isXlsx) {
      this.msg.error('仅允许上传xlsx或xls格式文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      this.msg.error('文件不能大于5M');
    }
    if (isXlsx && isLt2M) {
      this.excelFile.push(file);
    }
    return false;
  }


  autoDistribution() {
     if (this.curBimEventId === null || this.curBimEventId === undefined || this.curBimEventId.length <= 0 || this.curBimEventId === '-1') {
      this.msg.info('没有赛项数据，不能执行自动分配任务！');
      return false;
     }

     const copyParams = {};
     copyParams['eventId'] = this.curBimEventId;
     copyParams['workType'] = 1;
     copyParams['advisorType'] = '答辩评委';
     copyParams['iWorkCountAdvisor'] = 1;
     this.audoDistLoading = true;
     this.bimWorkCoresService.autoDistributionReply(copyParams).subscribe((res: any) => {
        this.audoDistLoading = false;
        console.log(res);
        if (res.body.success) {
            console.log('成功！');
        }
        this.msg.success(res.body.message);
        this.getDataList();
    });
  }


  showWorkCores(item) {

    const tmpList = this.bimEventsList.filter(d => d.id === this.curBimEventId);

      this.modal.static(BimAdvisorReplyWorkComponent, {
        bimInfoName: this.currentBimInfo.name,
        bimInfoEventName: tmpList[0].name,
        schoolName: item.orgName,
        advisorName: item.name,
        advisorId: item.id,
        bimEventId: this.curBimEventId,
      }, 1200).subscribe((res) => {

          this.getDataList();
      });
  }


  searche() {
    this.page = 0;
    this.getDataList();
  }
}
