import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { NzMessageService } from 'ng-zorro-antd';
import { BimInfoEventsItemsService } from '../bim-info-events-items/bim-info-events-items.service';
import { BimInfoEventsMutexService } from './bim-info-events-mutex.service';

@Component({
  selector: 'app-bim-info-events-mutex',
  templateUrl: './bim-info-events-mutex.component.html',
  styleUrls: []
})
export class BimInfoEventsMutexComponent implements OnInit {

  record: any = {};
  public page  = 0;
  public size  = 10;
  public total = '0';
  public list = [];
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  public bimInfoEventItems = [];
  public mutexItems = [];
  isItemSetting = false;
  mainItemId = null;
  isOnly = '0';
  isloading = false;
  public type = null;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    // sort固定
  };

  columns: SimpleTableColumn[] = [];

  constructor(
    private msg: NzMessageService,
    private bimInfoEventsItemsService: BimInfoEventsItemsService,
    private bimInfoEventsMutexService: BimInfoEventsMutexService
  ) {
  }

  ngOnInit(): void {
    if (this.type === 'province') {
       this.columns = [
        { title: '赛项分类', index: 'itemType' , width: '15%'},
        { title: '赛项名称', index: 'name' , width: '25%'},
        { title: '互斥赛项', index: 'mutItemName' , width: '40%'},
        { title: '是否唯一', index: 'isOnly' , width: '10%',
        format: (item: any) => {
          if (item.isOnly === 1) {
            return '是';
          } else {
            return '否';
          }
        },},
        {
          title: '操作', className: 'text-center',
          buttons: [
             { text: '配置', click: (item: any) => {this.openModal(item); } },
          ],
        }
      ];
    } else {
      this.columns = [
       { title: '赛项分类', index: 'itemType' , width: '15%'},
       { title: '赛项名称', index: 'name' , width: '25%'},
       { title: '互斥赛项', index: 'mutItemName' , width: '40%'},
       {
         title: '操作', className: 'text-center',
         buttons: [
            { text: '配置', click: (item: any) => {this.openModal(item); } },
         ],
       }
     ];
    }
    console.log(this.type);
    console.log(this.record);
    this.getEventItemData();
  }

  getEventItemData() {
    this.bimInfoEventsItemsService.getBimItemInfoByEventId({
      'bimInfoEventsId': this.record.id
    }).subscribe(res => {
      this.bimInfoEventItems = res.body;
      this.getDataList();
    });
  }

  openModal(item) {
    this.mainItemId = item.id;
    this.mutexItems = this.bimInfoEventItems.filter(e => e.itemType === item.itemType && item.id !== e.id);
    if (this.mutexItems && this.mutexItems.length === 0) {
      this.msg.warning('单个赛项不可配置');
      return;
    }
    this.mutexItems.forEach(e => e.checked = item.mutItemId !== null && item.mutItemId.indexOf(e.id) > -1);
    this.isItemSetting = true;
  }

  // 关闭上传弹框
  handleCancel() {
    this.mutexItems = [];
    this.isItemSetting = false;
  }

  handleConfirm() {
    console.log(this.mutexItems);
    const saveData = this.mutexItems.filter(e => e.checked);
    this.isloading = true;
    this.bimInfoEventsMutexService.saveMutexItemData({
      'bimInfoEventsId': this.record.id,
      'mainItemId': this.mainItemId,
      'isOnly': this.isOnly,
      'saveData': saveData
    }).subscribe(res => {
      this.msg.info('保存成功');
      this.isloading = false;
      this.mutexItems = [];
      this.isItemSetting = false;
      this.getDataList();
    });
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
   public getDataList(isReset?: boolean) {
    const copyParams = this.getParams(isReset);
    this.bimInfoEventsMutexService.getAllDataPageList(copyParams).subscribe(res => {
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.total = res.headers.get('X-Total-Count');
    });
   }

   getParams(isReset?: boolean) {
     const copyParams = {};
     const q = this.queryParams;
     if (isReset === true) {
       this.page = 0;
       Object.keys(q).forEach(function (key) {
         if (key.indexOf('isOk') > -1) {
           q[key] = '';
         } else {
           q[key] = null;
         }
       });
     }
     Object.keys(q).forEach(function (key) {
       if (q[key] !== '' && q[key] !== null) {
         copyParams[key] = q[key];
       }
     });
     copyParams['page'] = this.page;
     copyParams['size'] = this.size;
     copyParams['bimInfoEventId'] = this.record.id;
     return copyParams;
   }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

}
