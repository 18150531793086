import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { BimItemsService } from '../../../../bim-items/bim-items.service';

import {BimInfoEventsItemsPassService} from './bimInfoEventsItemsPass.service';
import {BimInfoEventsItemsPass} from './BimInfoEventsItemsPass.model';

@Component({
  selector: 'app-bim-bimPerson-result-set-pass-rate',
  templateUrl: './set-pass-rate.component.html',
  styleUrls: ['./set-pass-rate.component.less']
})
export class BimPersonResultSetPassRateComponent implements OnInit {

  public bimInfoEventsId = '';
  

  Items =[];
  personItemsRate=[];
  public isAdd = false;
 

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private modalHelp: ModalHelper,
    private nzModalRef: NzModalRef,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    private bimItemsService:BimItemsService,
    private bimInfoEventsItemsPassService:BimInfoEventsItemsPassService,
  ) {
    
   
  }

  ngOnInit(): void {
    this.loadItems();
 
  }



  
  loadItems(){
    this.Items=[];
    this.personItemsRate = [];
    let copyParams = {};
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    copyParams['isDelete.equals'] = 0;
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimInfoEventsId, copyParams).subscribe((res: any) => {    
          this.Items = res.body;

          this.Items.forEach(item=>{
            let bimInfoEventsItemsPass = new BimInfoEventsItemsPass();
            bimInfoEventsItemsPass.id=null;
            bimInfoEventsItemsPass.bimInfoEventsId = this.bimInfoEventsId;
            bimInfoEventsItemsPass.itemId = item.id;
            bimInfoEventsItemsPass.passRate = '30';
            this.personItemsRate.push(bimInfoEventsItemsPass);
          });

          this.getPersonPassRate();
    });
  }


  getPersonPassRate(){
    let copyParams = {};
    copyParams["bimInfoEventsId.equals"] = this.bimInfoEventsId;
    this.bimInfoEventsItemsPassService.query(copyParams).subscribe((res: any) => {    
       let body = res.body;

       body.forEach(element => {
          this.personItemsRate.forEach(item=>{
              if(element.itemId  == item.itemId)
              {
                  item.passRate = element.passRate;
              }
          });
       });

     
    });
  }


  getItemName(itemId){
      let itemName = "";
      this.Items.forEach(item=>{
           if(item.id == itemId)
           {
            itemName =  item.name;          
           }
      });


      return itemName;

  }




/** 限制输入框只能输入数字 */
  onInput($event) {
    if (!$event) {
      return;
    }
    const target = $event.target;
    const regexp = /[^(0-9)]/ig;
    target.value = target.value.replace(regexp, '');
    if(target.value >100)
    {
      target.value = 100;
    }

    if(target.value<0)
    {
      target.value = 0;
    }
  }


    /**修改专项通过比例设置输入框内容 */
    passRateChange($event, i) {
       const target = $event.target;
       const item = this.personItemsRate.length >= i ? this.personItemsRate[i] : null;
       if (item != null) {
        item.passRate = target.value;
       }
    }



    submit(){
       this.bimInfoEventsItemsPassService.saveItemPass(this.personItemsRate).subscribe(res => {
          this.msg.info("保存成功！");
          this.nzModalRef.destroy();

      });

    }




  close() {
    this.nzModalRef.destroy();
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }

  

}
