import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
// import { AdminUsersViewComponent } from "./view/view.component";
import { EduTeacherEditComponent } from './edit/edit.component';
import { EduCourseNumComponent } from './courseNum/courseNum.component';
import { EduStudentNumComponent } from './studentNum/studentNum.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { TeacherService } from './teacher.service';
import { Teacher } from './teacher.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  selector: 'app-edu-teacher',
  templateUrl: './teacher.component.html',
})
export class EduTeacherComponent implements OnInit {
  public page   = 0;
  public size   = 10;
  public total  ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
 dataList: any[] = [];
  public totalCallNo   = 0;
  public selectedRows: SimpleTableData[] = [];
  allChecked = false;
  indeterminate = false;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    'idCard.contains': '',
    'college.contains': '',
    // sort固定
    // sort: ['orderNum,asc']
  };


  columns: SimpleTableColumn[] = [

  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public teacherService: TeacherService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    console.log(copyParams);
   this.teacherService.query(copyParams)
      .subscribe((res: any) => {
      this.dataList = res.body;

      this.total = res.headers.get('X-Total-Count');

      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = 0;
    this.size = event.ps;
    this.getDataList();
  }
 /**
  * 页码变动事件
  * @param
  */
  changePageIndex(pageIndex ) {
    this.page = pageIndex - 1;
    this.getDataList();
  }


  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(EduTeacherEditComponent, { record: { id: null } })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
     const validData = this.dataList.filter(value => value.checked === true);
     console.log(validData);
    if (validData.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
    if (validData.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
    const tmp = validData[0];
    this.modal
      .static(EduTeacherEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }

  editTeacher(data: any) {
    const tmp = data;
    this.modal
      .static(EduTeacherEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }


  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.teacherService.delete(res.id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    const validData = this.dataList.filter(value => value.checked === true);
    if (validData.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    validData.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.teacherService.delete(validData.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }


 viewStudentNum(data: any) {
  const tmp = data;
  this.modal
      .static(EduStudentNumComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());

 }


viewCourse(data: any) {
  const tmp = data;
  this.modal
      .static(EduCourseNumComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
}


checkAll(value: boolean): void {
  this.dataList.forEach(data => {
    if (!data.disabled) {
      data.checked = value;
    }
  });
  this.refreshStatus();
}

refreshStatus(): void {
  const validData = this.dataList.filter(value => !value.disabled);
  const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
  const allUnChecked = validData.every(value => !value.checked);
  this.allChecked = allChecked;
  this.indeterminate = (!allChecked) && (!allUnChecked);
}

searchTeacherData() {
  this.page = 0;
  this.size = 10;
  this.getDataList();
}


}
