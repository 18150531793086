import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BimInfoEventsItems } from './bim-info-events-items.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class BimInfoEventsItemsService extends ThsBaseService<BimInfoEventsItems> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-info-events-items`);
    }


    // 根据赛项bimInfoEventsId获取专项信息
    getBimItemInfoByEventId(queryParams): Observable<any> {
        const url = `${this.resourceUrl}/getBimItemInfoByEventId`;
        return this.http.get(url, { params: queryParams, observe: 'response' });
    }

    /** POST: 添加一条新记录 */
    saveItemByEvents(param: any): Observable<HttpResponse<any>> {
        const url = this.resourceUrl + '/saveItemByEvents';
        return this.http.get<any>(url, { params: param, observe: 'response' });
    }

    // 根据大赛bimInfoId获取专项信息
    getBimItemInfoByBimInfoId(queryParams): Observable<any> {
        const url = `${this.resourceUrl}/getBimItemInfoByBimInfoId`;
        return this.http.get(url, { params: queryParams, observe: 'response' });
    }
}

