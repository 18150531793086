import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { BimItemsService } from '../../../../bim-items/bim-items.service';
import {BimPersonResultService} from '../bim-person-result.service';

@Component({
  selector: 'app-bim-bimPerson-result-send_message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.less']
})
export class BimPersonResultSendMessageComponent implements OnInit {

  public bimInfoEventsId = '';
  

  Items =[];
  selectItems=[];
  

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private modalHelp: ModalHelper,
    private nzModalRef: NzModalRef,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    private bimItemsService:BimItemsService,
    private bimPersonResultService:BimPersonResultService,
  ) {
    
   
  }

  ngOnInit(): void {

    this.selectItems = [];

    this.loadItems();
 
  }



  
  loadItems(){
    this.Items=[];
    let copyParams = {};
    copyParams['sort'] =['type,asc', 'orderSn,asc'];
    copyParams['isDelete.equals'] = 0;
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimInfoEventsId, copyParams).subscribe((res: any) => {    
          this.Items = res.body;

          console.log(this.Items);
    });
  }


  


    submit(){
       

       if(this.bimInfoEventsId === null || this.bimInfoEventsId === undefined || this.bimInfoEventsId.length <= 0)
       {
           this.msg.info("没有选择赛项，不能发送");
           return false;
       }

       if(this.selectItems === null || this.selectItems === undefined || this.selectItems.length <= 0)
       {
        this.msg.info("没有选择专项，不能发送");
        return false;
       }

       let itemIdList=[];
       this.selectItems.forEach(select=>{
           itemIdList.push(select["itemId"]);
       });

       console.log(itemIdList);

       this.bimPersonResultService.sendMessage(this.bimInfoEventsId, itemIdList).subscribe(res => {
        let resultVo = res.body;
          this.msg.info(resultVo.message);
          this.nzModalRef.destroy();
      });

    }




  close() {
    this.nzModalRef.destroy();
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }



   /**
   *
   * @param $event 复选按钮触发事件
   * @param optValue
   */
  checkboxClick($event, itemId: string) {

    debugger;

    const target = $event.target;

    if(target.checked === true){
      let tmpItems =  this.selectItems.filter(d=>d.itemId === itemId);   
      if(tmpItems.length <=0)
      {
         let tmpBimItem ={};
         tmpBimItem["itemId"] = itemId;

         this.selectItems.push(tmpBimItem);
      }
    }else{
      let nindex;
      this.selectItems.forEach((element, index) => {

        if (element.itemId === itemId) {
          nindex = index;
        }
      });

      //删除没有选择项的，并清空空记录
       delete this.selectItems[nindex];
       this.selectItems = this.delNullObj(this.selectItems);
    }
  }

  
  /**去掉空对象 */
  delNullObj(List: any): any {
    const zhangstr = [];
    for (let k = 0; k < List.length; k++) {
      const obj = List[k];
      if (obj != null)
        zhangstr.push(obj);
    }

    return zhangstr;
  }
  

}
