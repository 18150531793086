import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CoursePayDetailsService } from './details.service';
import { CoursePayDetails } from './details.model';

@Component({
  selector: 'app-course-order-detail',
  templateUrl: './details.component.html',
})
export class EduCoursePayDetailsComponent implements OnInit {

  record: any;

  public list: CoursePayDetails[] = [];
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  // 查询参数
  queryParams = {
    'eduCourseName.contains': '',
    'eduStudentName.contains': '',
    'status.equals': '1',
    'remark.contains': '',
  };


  columns: SimpleTableColumn[] = [
    // { title: '', index: 'id', type: 'checkbox' },
    { title: '订单号', index: 'orderNo' , className: 'text-center', width: '12%'},
    { title: '课程名称', index: 'eduCourseName' , className: 'text-center', width: '12%'},
    { title: '课时总数', index: 'totalHours', className: 'text-center', width: '7%' },
    { title: '单价', index: 'coursePrice', className: 'text-center', width: '7%' },
    { title: '数量', index: 'courseNum', className: 'text-center', width: '7%' },
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public coursePayDetailsService: CoursePayDetailsService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.coursePayDetailsService.queryByOrderNo(this.record.orderNo)
      .subscribe((res: any) => {
        const result = res.body.data;
        if (result) {
          this.list = result;
        }
      });
  }

}
