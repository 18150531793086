import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { IndustryRoutingModule } from './industry-routing.module';

import { IndustryUniversityComponent } from './industry-university/industry-university';
import { DeclareComponent } from './declare/declare';
import { ApproveComponent } from './approve/approve';
// import { ViewItemComponent } from './view-item/view-item';

const COMPONENTS = [
];
const COMPONENTS_NOROUNT = [
  IndustryUniversityComponent,
  DeclareComponent,
  ApproveComponent,
  // ViewItemComponent
];


@NgModule({
  imports: [
    SharedModule,
    IndustryRoutingModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  providers: [
  ],

  entryComponents: COMPONENTS_NOROUNT

})
export class IndustryModule { }
