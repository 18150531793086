import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { UserService } from 'app/routes/admin/system/users/users.service';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { BimItemsService } from '../../bim-items/bim-items.service';
import { BimSchoolsService } from '../../school/bim-schools.service';
import { ThsNewsService } from '../../ths-news/ths-news.service';
import { BimAdvisorsService } from '../../bim-advisor/bim-advisor.service';

@Component({
  selector: 'app-bim-advisor-reply-edit',
  templateUrl: './edit.component.html',
  styleUrls: []
})
export class BimAdvisoReplyEditComponent implements OnInit {

  buttonIsVisible = true;

  isAdd = false;
  record: any = {};

  form: FormGroup;
  public loading = false;

  public bimInfoEventsId = null;

  public schoolList = [];

  public infoEventId = null;
  public bimInfoEventsItems = [];
  public itemTypes = [];
  public items = [];
  public selBimItemId = null;

  public regionList = [];
  public provinceList = [];
  public initProvinceList = [];

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private userService: UserService,
    private bimItemsService: BimItemsService,
    private bimAdvisorsService: BimAdvisorsService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      orgName: [null, [Validators.maxLength(50)]],
      name: [null, [Validators.required, Validators.maxLength(20)]],
      location: [null, []],
      province: [null, []],
      // tslint:disable-next-line:max-line-length
      tel: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],
      email: [null, [Validators.email]],
      bimInfoEventsId: [null, []],
      advisorType: [null, []],
      isReply: [null, []],
      groupName: [null, [Validators.required, Validators.maxLength(20)]]
    });
  }

  ngOnInit(): void {
    this.initData();
    this.loadItems();
    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.loadData();
    }
  }

  initData() {
  }

  regionChange(regionId: string, init?: string) {
    this.form.controls['province'].setValue(null);
    if (!isNullOrUndefined(regionId)) {
      this.provinceList = this.initProvinceList.filter(e => e.fkregionId === regionId);
    } else {
      this.provinceList = [];
    }
  }

  loadData() {
    this.regionChange(this.record.location);
    this.selBimItemId = this.record.itemId;
    this.form.patchValue(this.record);
  }

  // 提交
  submit() {
    this.buttonIsVisible = false;
    const subParam = {};
    // tslint:disable-next-line:forin
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    if (this.form.invalid) {
      this.onError({ 'message': '请检查信息是否完整！' });
      this.buttonIsVisible = true;
      return;
    }
    if (!isNullOrUndefined(this.selBimItemId)) {
      subParam['bimItemId'] = this.selBimItemId;
    }
    console.log(subParam);
    subParam['bimInfoEventsId'] = this.bimInfoEventsId;
    this.checkSaveData(subParam);
  }

  checkSaveData(subParam: any) {
    this.loading = true;
    this.bimAdvisorsService.query({
      'location.equals': subParam['location'],
      'province.equals': subParam['province'],
      'tel.equals': subParam['tel'],
      'bimInfoEventsId.equals': subParam['bimInfoEventsId']
    }).subscribe(res => {
      if (res && res.body.length > 0) {
        if (this.isAdd) {
          this.onWarning({message: '此评委已注册，请修改注册信息'});
          this.buttonIsVisible = true;
        } else {
          const data = res.body[0];
          if (data.id === subParam['id']) {
            this.saveData(subParam);
          } else {
            this.onWarning({message: '此评委已注册，请修改注册信息'});
            this.buttonIsVisible = true;
          }
        }
      } else {
        this.saveData(subParam);

      }
    });
  }

  saveData(subParam: any) {
    this.bimAdvisorsService.saveAdvisorData(subParam).subscribe(res => {
      this.loading = false;
      this.msg.success('保存成功');
      this.buttonIsVisible = true;
      this.modal.close('success');
    }, () => this.loading = false);
  }

  loadItems(initData?) {
    this.items = [];
    this.itemTypes = [];
    this.bimItemsService.getAllBimItemsInBimEvents(this.bimInfoEventsId, {
      'isDelete.equals': 0,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      this.items = res.body;
      const allType = [];
      this.items.forEach(e => {
        if (!allType.includes(e.type)) {
          allType.push(e.type);
          this.itemTypes.push({
            'type': e.type,
            'data': this.getItemByType(e.type, initData)
          });
        }
      });
    });
  }

  getItemByType(type, initData?) {
    const data = this.items.filter(d => d.type === type).sort(d => d.orderSn);
    if (!isNullOrUndefined(initData)) {
      data.forEach(e => e.checked = initData.filter(s => s.itemId === e.id).length > 0);
    }
    return data;
  }

  checkClick(checked, type, id) {
  }

  close() {
    this.nzModalRef.destroy();
  }

  private onError(error: { message: string; }) {
    this.loading = false;
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.loading = false;
    this.msg.remove();
    this.msg.create('warning', error.message);
  }
}
