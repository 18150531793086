import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CourseCatalogService } from '../../courseCatalog/courseCatalog.service';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { CourseService } from '../../course/course.service';
import { HomeworktypeService } from '../homeworktype.service';
import { SFComponent } from '@delon/form';
import { HomeworkService } from '../../homework/homework.service';

@Component({
  selector: 'app-typeaddedit',
  templateUrl: './typeaddedit.component.html',
  styles: []
})
export class TypeaddeditComponent implements OnInit {
  @ViewChild('sf') sf: SFComponent;
  type = '';
  imgUrl = '';
  formData: any;
  resourceType = '';
  // 实体id
  entityId = '';
  // 用户id
  userId = '';
  // 实体名
  personName = '';
  public page = 0;
  public size = 10;
  form: FormGroup;
  record: any = {};
  submitting = false;
  copyParam: any = {};
  copyParams: any = {};
  courselist: any[] = [];
  homworklist: any[] = [];
  cataloglist: any[] = [];
  // 题目类别字典No
  SUBJECT_TYPE = 'SubjectType';
  // 题目难度字典No
  SUBJECT_DIFFICULTY = 'SubjectDifficulty';
  subjectType = [];
  subjectDifficulty = [];
  constructor(
    private fb: FormBuilder,
    public http: _HttpClient,
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public courseService: CourseService,
    public homeworkService: HomeworkService,

    public homeworktypeService: HomeworktypeService,
    public courseCatalogService: CourseCatalogService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      eduCourseId: [null],
      eduCatalogId: [null],
      eduHomeworkId: [null],
      chooseType: [null],
      score: [null],
      subjectType: [null],
      subjectQuantity: [null],
      totalScore: [null],
    });
  }

  ngOnInit() {
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.homeworktypeService.find(this.record.id)
      .subscribe(res => {
        this.formData = this.formatRecord(res.body);
        this.type =  this.formData.type;
        this.record = res.body;
        this.form = this.fb.group({
          eduCourseId: [this.record.eduCourseId],
          eduCatalogId: [this.record.eduCatalogId],
          eduHomeworkId: [this.record.eduHomeworkId],
          chooseType: [this.record.chooseType],
          score: [this.record.score],
          subjectType: [this.record.subjectType],
          subjectQuantity: [this.record.subjectQuantity],
          totalScore: [this.record.totalScore],
        });
      });
    }
    this.personName = this.tokenService.get().entity_name; // 登录人
    this.userId = this.tokenService.get().account_id; // 登录id
    this.entityId = this.tokenService.get().entity_id;
    this.getcourseval();
    this.gethomworkList();
  }
  // 获取主表id
  gethomworkList(isReset?: boolean) {
    let copyParams = {};
    // const q = this.queryParams;
    // if (isReset === true) {
    //   this.page = 0;
    //   Object.keys(q).forEach(function (key) {
    //     q[key] = '';
    //   });
    // } else {
    //   Object.keys(q).forEach(function (key) {
    //     if (q[key] !== '' && q[key] !== null) {
    //       copyParams[key] = q[key];
    //     }
    //   });
    // };
    // copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    console.log(copyParams);
    this.homeworkService.query(copyParams)
      .subscribe((res: any) => {
        this.homworklist = res.body;
        // this.total = res.headers.get('X-Total-Count');
        console.log(this.homworklist, 'this.homworklist');
      });
  }
  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }
  // 课程，课程章节信息查询
  getcourseval() {
    // this.copyParams['teacherId.equals'] = this.userId;
    // this.copyParams['isPublish.equals'] = '1';
    // this.copyParams['isDelete.equals'] = '0';
    // this.courseService.query(this.copyParams)
    //   .subscribe((res: any) => {
    //     if (res.body && res.body.length) {
    //       this.courselist = res.body;
    //     }
    //   });
    if (this.record.id == null) {
      this.copyParams['teacherId.equals'] = this.userId;
      this.copyParams['isPublish.equals'] = '1';
      this.copyParams['isDelete.equals'] = '0';
      this.courseService.query(this.copyParams)
        .subscribe((res: any) => {
          if (res.body && res.body.length) {
            this.courselist = res.body;
          }
        });
    } else {
      this.copyParams['id.equals'] = this.record.eduCourseId;
      this.copyParams['isPublish.equals'] = '1';
      this.copyParams['isDelete.equals'] = '0';
      this.courseService.query(this.copyParams)
        .subscribe((res: any) => {
          if (res.body && res.body.length) {
            this.courselist = res.body;
          }
        });
    }

  }
  setModuleName(moduleId: string) {
    if (moduleId == null) {
      this.msgSrv.error('请选择课程');
    } else {
      this.copyParam['eduCourseId.equals'] = moduleId;
      this.copyParam['isPublish.equals'] = '1';
      this.courseCatalogService.query(this.copyParam)
        .subscribe((res: any) => {
          this.cataloglist = res.body;
        });
    }
  }
  // 关闭窗口
  close(result: any) {
    this.modal.destroy(result);
  }
  // 保存
  save() {
    this.record = Object.assign(this.record, this.form.value);
    this.submitting = true;
    if (this.record.id == null) {
      this.record['createdBy'] = this.personName;
      this.record['createdTime'] = new Date();
      this.homeworktypeService.create(this.record).subscribe((resp) => {
        if (resp.body && resp.body.id !== null) {
          this.msgSrv.info('添加成功');
          this.close(true);
        } else {
          this.msgSrv.info('添加失败');
          this.close(false);
        }
        this.submitting = false;
      });
    } else {
      this.record['lastModifiedBy'] = this.personName;
      this.record['lastModifiedDate'] = new Date();
      this.homeworktypeService.update(this.record).subscribe((resp) => {
        if (resp.body && resp.body.id !== null) {
          this.msgSrv.info('更新成功');
          this.close(true);
        } else {
          this.msgSrv.info('更新失败');
          this.close(false);
        }
        this.submitting = false;
      });
    }
  }
  // 获取字典详情
  getDictDetials() {
   this.homeworktypeService.findAllByDictNo(this.SUBJECT_TYPE).subscribe(res => {
     if (res.body && res.body != null && res.body.length > 0) {
       this.subjectType = res.body;
     }
   });
   this.homeworktypeService.findAllByDictNo(this.SUBJECT_DIFFICULTY).subscribe(res => {
     if (res.body && res.body != null && res.body.length > 0) {
       this.subjectDifficulty = res.body;
     }
   });
 }
}
