import { Component,Inject } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
//  import { AdminUsersViewComponent } from './view/view.component';
// import { EduCourseEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CoursePayService } from './course-pay.service';
import { CoursePay } from './course-pay.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { EduCoursePayDetailsComponent } from './details/details.component';
import { isNullOrUndefined } from 'util';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import * as moment from 'moment';

@Component({
  selector: 'app-order-course',
  templateUrl: './course-pay.component.html',
})
export class EduCoursePayComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: CoursePay[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  isVisible = false;
  isInvoiceVisible = false;
  isZFVisible = false;
  public record:CoursePay = null;
  accountName="";
  public invoiceStatus= null;
  public loading=false;
  public handType = null;
  public orderStatus=null;
  public invoiceType = null;
  public invoiceNum = null;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  // 查询参数
  queryParams = {
    'eduCourseName.contains': '',
    'eduStudentName.contains': '',
    'status.equals': '1',
    'remark.contains': '',

    'orderTime.greaterOrEqualThan': '',
    'orderTime.lessOrEqualThan': '',
    sort: ['createdDate,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '订单号', index: 'orderNo' , className: 'text-center', width: '10%'},
    // { title: '课程名称', index: 'eduCourseName' , className: 'text-center', width: '12%'},
    { title: '购买用户', index: 'eduStudentName', width: '6%' },

    { title: '课程名称', index: 'courseNames', width: '6%' },

    { title: '订单状态', index: 'status', className: 'text-center', width: '6%',
      format: (item: any) => {
        if (item.status === 0) {
          return '未支付';
        } else if (item.status === 1) {
          return '已支付';
        } else {
          return '已取消';
        }
      }
},
    { title: '订单总价', index: 'totalPrice', className: 'text-center', width: '6%' },
    { title: '支付类型', index: 'payType', className: 'text-center', width: '6%',
      format: (item: any) => {
        if (item.payType === 'wx') {
          return '微信支付';
        } else if (item.payType === 'zfb') {
          return '支付宝支付';
        } else {
          return '未支付';
        }
      }
    },
    { title: '支付结果', index: 'payResult', className: 'text-center', width: '6%',
      format: (item: any) => {
        if (item.payResult === null || item.payResult === '') {
          return '未成功';
        } else {
          return '成功';
        }
      }
    },
    { title: '下单时间', index: 'orderTime', className: 'text-center', width: '10%'},
    { title: '支付时间', index: 'payTime', className: 'text-center', width: '10%'},
    { title: '交易单号', index: 'serialNumber', className: 'text-center', width: '10%'},

    { title: '开票状态', index: 'invoiceStatus', className: 'text-center', width: '6%',
      format: (item: any) => {
        if (item.invoiceStatus === null || item.invoiceStatus === '' || item.invoiceStatus === '0' ) {
          return '未开票';
        } else if(item.invoiceStatus === '1') {
          return '已开票';
        }
      }
    },
  { title: '开票说明', index: 'invoiceNum', className: 'text-center', width: '10%'},

    {
      title: '操作', className: 'text-center', width: '14%',
      buttons: [
        { text: '查看详情', click: (item: any) => { this.showDetail(item); } },

        { text: '开票', click: (item: any) => { this.openModal(item, 2); } },
        { text: '支付状态', acl:APP_PERMISSIONS.CERT_REGEDIST_ORDER_Status,  click: (item: any) => this.openZFModal(item) },
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public coursePayService: CoursePayService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?:boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
            if(key==="orderTime.greaterOrEqualThan")
            {
             
              copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + 'T00:00:00Z';

            }else if(key ==="orderTime.lessOrEqualThan")
            {
                 copyParams[key] = moment(q[key]).format('YYYY-MM-DD') + 'T23:59:59Z';

            }
            else{
                copyParams[key] = q[key];
            }
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.coursePayService.queryNew(copyParams)
      .subscribe((res: any) => {
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      });
  }


  showDetail(item: any) {
    this.modal
    .static( EduCoursePayDetailsComponent, { record: item })
    .subscribe(() => {
      // this.getDataList();
    });
  }


  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  // /**
  // * 过滤器变动事件 支持多选过滤
  // * @param
  // */
  // filterChange(event) {
  //   let i = 0;
  //   const _value = [];
  //   const _type = event.filterMultiple ? 'in' : 'equals';
  //   event.filters.forEach(element => {
  //     if (element.checked) {
  //       _value[i++] = element.value;
  //     }
  //   });
  //   this.queryParams[event.indexKey + '.' + _type] = _value;
  //   this.getDataList();
  // }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  // */
  // edit() {
  //   if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
  //   if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
  //   const tmp = this.formatIsOk(this.selectedRows[0]);
  //   this.modal
  //     .static(EduCourseEditComponent, { record: tmp })
  //     //      .pipe(filter(w => w === true))
  //     .subscribe(() => this.getDataList());
  // }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }



    // 关闭上传弹框
    handleCancel() {
      this.isVisible = false;
      this.isInvoiceVisible = false;
    }


     // 打开上传弹框
  openModal(item, type) {

    this.handType=type;
    if (type === 2 && item.status !== 1) {
      this.msg.info('未付款不可开票');
      return;
    }
    if (type === 2 && item.invoiceStatus === '2') {
      this.msg.info('此订单不开发票');
      return;
    }
    this.coursePayService.find(item.id).subscribe(res => {
      
      if (res && res.body) {
        this.record = res.body;
        this.invoiceType = this.record.invoiceType!=null? this.record.invoiceType+ '':'';
        this.invoiceStatus =  this.record.invoiceStatus!=null? this.record.invoiceStatus+ '':'';
        this.invoiceNum = this.record.invoiceNum;
        this.isVisible = true;
      }
    });
  }


  invoiceStatusChange($event){
      if($event === '0')
      {
          this.record.invoiceNum = "";
      }
  }

  
  handleConfirm() {
    this.isVisible = false;
    this.record.invoiceStatus =  this.invoiceStatus ;
    this.record.invoiceNum = this.invoiceNum;
    this.coursePayService.update(this.record).subscribe(() => {
      this.getDataList();
      this.record.invoiceStatus = null;
      this.invoiceNum = null;
    });
  }

  
  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
 checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    let price = 0;
  }


  changeInvoice() {
    if (this.selectedRows.length < 1) {
      this.msg.remove();
      this.msg.error('请至少选择一条数据');
      return;
    }

    this.invoiceStatus = null;
    this.isInvoiceVisible = true;
  }


  
  invoiceConfirm() {
    if (this.invoiceStatus === null) {
      this.msg.remove();
      this.msg.error('请选择开票状态');
      return;
    }
    if (this.selectedRows.length > 0) {
      let index = 0;
      this.selectedRows.forEach(e => {
        this.loading = true;

        if (e.status === 1) {
          e.invoiceStatus = this.invoiceStatus;
          
          this.coursePayService.update(this.setCoursePay(e)).subscribe(() => {
            index++;
            if (this.selectedRows.length === index) {
              this.loading = false;
              this.invoiceStatus = null;
              this.handleCancel();
              this.getDataList();
            }
          });
        } else {
          index++;
            if (this.selectedRows.length === index) {
              this.loading = false;
              this.invoiceStatus = null;
              this.handleCancel();
              this.getDataList();
            }
        }
      });
    }
  }


  setCoursePay(e){
    let coursePay = new CoursePay();
    coursePay.id = e.id;
    coursePay.orderNo = e.orderNo;
    coursePay.serialNumber = e.serialNumber;
    coursePay.eduCourseId = e.eduCourseId;
    coursePay.eduStudentId = e.eduStudentId;
    coursePay.eduCourseName = e.eduCourseName;
    coursePay.eduStudentName = e.eduStudentName;
    coursePay.payType = e.payType;
    coursePay.price = e.price;
    coursePay.payNum = e.payNum;
    coursePay.totalPrice = e.totalPrice;
    coursePay.status = e.status;
    coursePay.payResult = e.payResult;
    coursePay.payTime = e.payTime;
    coursePay.orderNum = e.orderNum;
    coursePay.remark = e.remark;
    coursePay.createdBy = e.createdBy;
    coursePay.createdDate = e.createdDate;
    coursePay.lastModifiedBy = e.lastModifiedBy;
    coursePay.lastModifiedDate = e.lastModifiedDate;
    coursePay.invoiceStatus = e.invoiceStatus;
    coursePay.invoiceReceivingName = e.invoiceReceivingName;
    coursePay.invoiceReceivingTel = e.invoiceReceivingTel;
    coursePay.invoiceReceivingAddress = e.invoiceReceivingAddress;
    coursePay.invoiceType = e.invoiceType;
    coursePay.invoiceTitle = e.invoiceTitle;
    coursePay.invoiceNum = e.invoiceNum;
    coursePay.taxpayerSn = e.taxpayerSn;
    coursePay.invoiceAddress = e.invoiceAddress;
    coursePay.invoiceTel = e.invoiceTel;
    coursePay.bankAccount = e.bankAccount;
    coursePay.bankName = e.bankName;
    coursePay.province = e.province;
    coursePay.city = e.city;
    coursePay.region = e.region;

    return coursePay;

  }


  openZFModal(item){
    if(this.accountName !== "admin")
    {
      this.msg.remove();
      this.msg.error('您不是超级管理员，不能使用该功能!');
      return;
    }

    this.coursePayService.find(item.id).subscribe(res => {
      if (res && res.body) {  
          this.record = res.body;
          this.orderStatus = this.record.status!=null?this.record.status + '':'';
          this.isZFVisible = true;
      }
    });
  }


  invoiceZFConfirm(){
    if (this.record === null || this.orderStatus === null) {
      this.msg.remove();
      this.msg.error('请选择支付状态');
      return;
    }

    this.record.status = this.orderStatus;
      this.isZFVisible = false;
      this.coursePayService.update(this.record).subscribe(() => {
        this.getDataList();
        this.orderStatus = null;
      });

  }


  //关闭支付弹出框
  handleZFCancel(){
    this.isZFVisible = false;
  }



  /**
  * 导出excel
  */

 exportExcel(){
  const copyParams = {};
  const q = this.queryParams;
 
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' &&　q[key] !== null) {
          if(key==="orderTime.greaterOrEqualThan")
          {
           
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + 'T00:00:00Z';

          }else if(key ==="orderTime.lessOrEqualThan")
          {
               copyParams[key] = moment(q[key]).format('YYYY-MM-DD') + 'T23:59:59Z';

          }
          else{
              copyParams[key] = q[key];
          }
      }
    });
  


  this.coursePayService.exportExcel(copyParams);
}

}
