import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReuseTabService } from '@delon/abc';
import { environment } from '@env/environment';
import { ThsadminFileService } from '@shared/components/f-upload/thsadmin-file.service';
import { NzMessageService } from 'ng-zorro-antd';
import * as moment from 'moment';
import { GuidUtil } from '@shared/utils/guid.util';
import { zip } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ProjectReportService } from '../../projectReport/projectReport.service';
import { ModalHelper } from '@delon/theme';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
import { NicheEditComponent } from '../../component/niche-edit/edit.component';
import { IucceProjectSoftfundPayService } from '../../projectFundPay/edit/projectSoftFundPay.service';
import { IucceProjectFundPayService } from '../../projectFundPay/projectFundPay.service';
import { ProjectNicheService } from '../../projectNiche/projectNiche.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { COMMON_DATA } from '@shared/app.commonData';
import { ProjectNicheInfoComponent } from '../../projectNiche/edit/edit.component';
import { WorkflowService } from '@shared/components/workflow/workflow.service';

@Component({
  selector: 'app-project-query-view',
  templateUrl: './view.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less', './view.component.less']
})
export class ProjectQueryViewComponent implements OnInit {

  validateForm: FormGroup;

  public projectInfo = null;
  public fileList = [];
  public fileListClosing = []; // 结项所有附件
  public fundPayList = [];

  public softFundList = [];
  dateFormat = 'yyyy/MM/dd';

  @Input() public projectId = null;
  @Input() public prjType = null;
  @Input() public isView = true;
  public isLoading = false;

  // 项目商机情况
  public prjNicheList = [];
  prjNicheFollowAllList = {}; // 商机跟进详情
  dtNicheFollowLoading = false;
  yearList: any; // 年份
  monthList = [];
  COMMON_DATA = COMMON_DATA;
  curMonth = moment().month() + 1; // 当前月份
  curYear = moment().year(); // 当前年份

  total = '0';
  page = 0;
  size = 10;
  pageSizeOptions = [10, 20, 50, 100];

  orderStatusList = [
    { value: 11, label: '结项审核中', color: '#18BBFF'},
    { value: 21, label: '结项通过', color: '#46C39E' },
    { value: 31, label: '结项不通过', color: '#FF6D46' },
    { value: null, label: '待成单', color: '#666666' },
    { value: 0, label: '待成单', color: '#666666' },
    { value: 1, label: '部分成单', color: '#FFAE46' },
    { value: 3, label: '全部成单', color: '#46C39E' }
  ];

  public reportAuditList = [];
  public closeAuditList = [];

  queryParams = {
    'iucceProjectId': null,
    'iucceProjectNicheId': null,
    'year': null,
    'month': null,
    'orderDateBegin': null,
    'orderDateEnd': null,
    'orderAmountMin': null,
    'orderAmountMax': null,
    'searchValue': null,
  };

  isCollapse = true;

  constructor(
    private fb: FormBuilder,
    private dictService: DictService,
    private modalHelper: ModalHelper,
    private message: NzMessageService,
    private routeInfo: ActivatedRoute,
    private workflowService: WorkflowService,
    private reuseTabService: ReuseTabService,
    private projectNicheService: ProjectNicheService,
    private thsadminFileService: ThsadminFileService,
    private projectReportService: ProjectReportService,
    private iucceProjectFundPayService: IucceProjectFundPayService,
    private iucceProjectSoftfundPayService: IucceProjectSoftfundPayService,
  ) {
    this.validateForm = this.fb.group({
      payment: ['1', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.isView) {
      this.validateForm.disable();
    }
    this.projectId = this.routeInfo.snapshot.queryParams['id'];
    this.prjType = this.routeInfo.snapshot.queryParams['prjType'];
    this.monthList = this.COMMON_DATA.month;
    this.getFileList();
    this.initData();
    this.getProjectNiches();
    this.getYearListDict();
    this.initAuditProcess();
  }

  initData() {
    this.iucceProjectFundPayService.query({
      'iucceProjectId.equals': this.projectId,
      sort: ['payDate,asc']
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
        for (let i = 0; i < data.length; i++) {
          this.fundPayList = [
            ...this.fundPayList,
            {
              id: data[i].id,
              iucceProjectId: this.projectId,
              payDate: data[i].payDate,
              payAmount: data[i].payAmount,
              remark: data[i].remark,
              isAdd: false
            }
          ];
        }
        if (this.fundPayList.length === 0) {
          this.addFundPay();
        }
      }
    });
    this.iucceProjectSoftfundPayService.query({
      'iucceProjectId.equals': this.projectId,
      sort: ['orderNum,desc']
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
        for (let i = 0; i < data.length; i++) {
          let authDate = null;
          if (!isNullOrUndefined(data[i].authStartDate) && !isNullOrUndefined(data[i].authEndDate)) {
            authDate = [data[i].authStartDate, data[i].authEndDate];
          }
          this.softFundList = [
            ...this.softFundList,
            {
              id: data[i].id,
              iucceProjectId: this.projectId,
              softwareAmount: data[i].softwareAmount,
              softwareOrderNum: data[i].softwareOrderNum,
              authDate: authDate,
              softwareDetails: data[i].softwareDetails,
              isAdd: false
            }
          ];
        }
        if (this.softFundList.length === 0) {
          this.addSoftFund();
        }
      }
    });
  }

  addFundPay() {
    this.fundPayList = [
      ...this.fundPayList,
      {
        id: new GuidUtil().toString(),
        iucceProjectId: this.projectId,
        payDate: null,
        payAmount: null,
        remark: null,
        isAdd: true
      }
    ];
  }

  removeFundPay(item) {
    if (this.fundPayList.length === 1) {
      this.message.warning('至少要有一条资金支付情况');
      return;
    }
    this.fundPayList = this.fundPayList.filter(e => e.id !== item.id);
    if (!item.isAdd) {
      this.iucceProjectFundPayService.delete(item.id).subscribe();
    }
  }

  addSoftFund() {
    this.softFundList = [
      ...this.softFundList,
      {
        id: new GuidUtil().toString(),
        iucceProjectId: this.projectId,
        softwareAmount: null,
        softwareOrderNum: null,
        authDate: null,
        softwareDetails: null,
        isAdd: true
      }
    ];
  }

  removeSoftFund(item) {
    if (this.softFundList.length === 1) {
      this.message.warning('至少要有一条软件投入明细');
      return;
    }
    this.softFundList = this.softFundList.filter(e => e.id !== item.id);
    if (!item.isAdd) {
      this.iucceProjectSoftfundPayService.delete(item.id).subscribe();
    }
  }

  returnProjectInfo(event) {
    this.projectInfo = event;

    const isHaveSoftwarePay = this.projectInfo.isHaveSoftwarePay;
    this.validateForm.controls['payment'].setValue(isHaveSoftwarePay === null ? '1' : isHaveSoftwarePay);
  }

  submitForm() {
    // tslint:disable-next-line:forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    const payment = this.validateForm.value.payment;
    const saveSubArray = [];
    let funPayAmount = null;
    if (this.prjType === null || this.prjType !== 'soft') {
      funPayAmount = 0;
      // tslint:disable-next-line:forin
      for (let i = 0; i < this.fundPayList.length; i++) {
        const saveData = this.fundPayList[i];
        if (isNullOrUndefined(saveData.payDate) || isNullOrUndefined(saveData.payAmount)) {
          this.message.error('资金支付时间-请检查表单,填写带 * 的信息');
          return;
        }
        funPayAmount = funPayAmount + saveData.payAmount;
        if (saveData.isAdd) {
          saveSubArray.push(this.iucceProjectFundPayService.create(saveData));
        } else {
          saveSubArray.push(this.iucceProjectFundPayService.update(saveData));
        }
      }
    }
    let softwareAmount = null;
    if (payment === '1') {
      softwareAmount = 0;
      // tslint:disable-next-line:forin
      for (let i = 0; i < this.softFundList.length; i++) {
        const saveData = this.softFundList[i];
        if (isNullOrUndefined(saveData.softwareOrderNum) || isNullOrUndefined(saveData.softwareAmount)
           || isNullOrUndefined(saveData.authDate)) {
          this.message.error('软件投入明细-请检查表单,填写带 * 的信息');
          return;
        }
        const authDate = saveData.authDate;
        saveData['authStartDate'] = authDate[0];
        saveData['authEndDate'] = authDate[1];
        saveData['orderNum'] = i + 1;
        softwareAmount = softwareAmount + saveData.softwareAmount;
        if (saveData.isAdd) {
          saveSubArray.push(this.iucceProjectSoftfundPayService.create(saveData));
        } else {
          saveSubArray.push(this.iucceProjectSoftfundPayService.update(saveData));
        }
      }
    } else {
      for (let i = 0; i < this.softFundList.length; i++) {
        const item = this.softFundList[i];
        if (!item.isAdd) {
          this.iucceProjectSoftfundPayService.delete(item.id).subscribe();
        }
      }
    }
    this.isLoading = true;
    this.projectInfo.isHaveSoftwarePay = payment;
    this.projectInfo.payAmount = funPayAmount;
    this.projectInfo.sortwareAmount = softwareAmount;
    if (saveSubArray.length > 0) {
      zip(...saveSubArray).subscribe(() => {
        this.updateProject();
      });
    } else {
      this.updateProject();
    }
  }

  updateProject() {
    this.projectReportService.update(this.projectInfo).subscribe(res => {
      this.isLoading = false;
      this.message.success('保存成功');
    });
  }

  return() {
    this.reuseTabService.replace('/business/iucce/projectQuery');
  }

  /**
   * 获取文件列表
   */
  getFileList() {
    const queryParams = {
      'fileFk.equals' : this.projectId,
      sort: ['createdDate,desc']
    };
    this.thsadminFileService.queryFiles(queryParams).subscribe(res => {
      const data = res.body;
      const fileList = [];
      const fileListClosing = [];
      data.forEach(item => {
        item.uid = item.id;
        item.name = item.fileName;
        item.url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + item.id;

        if (item.extField1 === 'IucceProjectReport') {
          item.fileDesc = '项目申报书';
          fileList.push(item);
        } else if (item.extField1 === 'IucceProjectConstract') {
          item.fileDesc = '合同协议';
          fileList.push(item);
        } else if (item.extField1 === 'IucceProjectElse') {
          item.fileDesc = '其他附件';
          fileList.push(item);
        }

        if (item.extField1 === 'IucceProjectClosingOutCome') {
          item.fileDesc = '成果文件';
          fileListClosing.push(item);
        } else if (item.extField1 === 'IucceProjectClosingReport') {
          item.fileDesc = '项目结题报告';
          fileListClosing.push(item);
        } else if (item.extField1 === 'IucceProjectClosingElse') {
          item.fileDesc = '其他附件';
          fileListClosing.push(item);
        }
      });
      this.fileList = fileList;
      this.fileListClosing = fileListClosing;
    });
  }

  formatDate(time) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  downFile(file) {
    const a = document.createElement('a');
    const filename = file.fileName;
    a.href = file.url;
    a.download = filename;
    a.click();
  }

  uploadFile(item, uploadable) {
    this.modalHelper.static(FileUploadComponent, {
      display: 'list',
      fileFk: item.id,
      uploadable: uploadable
    }, 'md').subscribe();
  }

  // 商机跟进情况-成单状态
  orderStatusLabel(orderStatus) {
    const label =  this.orderStatusList.filter(item => item.value + '' === orderStatus);
    if (label == null || label.length <= 0) {
      return '';
    }
    return label[0].label;
  }

  orderStatusColor(orderStatus) {
    const label =  this.orderStatusList.filter(item => item.value + '' === orderStatus);
    if (label == null || label.length <= 0) {
      return '';
    }
    return label[0].color;
  }

  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  /**
   * 获取项目商机信息
   */
  getProjectNiches() {
    if (!this.projectId) {
      return;
    }

    const nicheParams = {
      'iucceProjectId.equals' : this.projectId,
      sort : ['estimateOrderDate,asc', 'createdDate,asc'],
      page: this.page,
      size: this.size
    };
    const nicheFollowParams = {
      'iucceProjectId.equals' : this.projectId,
      size: 10000
    };

    zip(
      this.projectNicheService.query(nicheParams),
      this.projectNicheService.queryProjectNicheFollowDatas(nicheFollowParams)
    ).subscribe(([nicheRes, nicheFollowRes]) => {
      this.prjNicheList = nicheRes.body;
      this.total = nicheRes.headers.get('X-Total-Count');
      const nicheFollowAllList = nicheFollowRes.body;
      if (this.prjNicheList && this.prjNicheList.length > 0) {
        let index = 0;
        this.prjNicheList.forEach(item => {
          item.expand = false;
          if (index === 0) {
            item.expand = true;
          }
          const nicheFollowAllListSel = nicheFollowAllList.filter(w => w.iucceProjectNicheId === item.id);
          this.prjNicheFollowAllList[item.id] = nicheFollowAllListSel;
          index ++;
        });
      }
    });
  }

  getYearListDict() {
    this.dictService.findAllByDictNo('yearList').subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
  }

  // 查看商机跟进详情
  viewNicheFollow(data) {
    this.modalHelper.static(NicheEditComponent, {
      pageType: 'view',
      record: data
    }, 1080).subscribe();
  }
  // 查看
  viewNiche(data) {
    this.modalHelper.static(ProjectNicheInfoComponent, {
      pageType: 'view',
      record: data
    }, 800).subscribe();
  }

  getNicheFollowDataList(data, isReset?: boolean) {
    const copyParams = {};

    const q = this.queryParams;
    this.dtNicheFollowLoading = true;
    if (isReset === true) {
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });

    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' && q[key] !== null) {
          if (key === 'orderDateBegin') {
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 00:00:00';
          } else if (key === 'orderDateEnd') {
            copyParams[key] =  moment(q[key]).format('YYYY-MM-DD') + ' 23:59:59';
          } else if (typeof q[key] === 'object' && q[key]) {
            copyParams[key] = q[key].join(',');
          } else {
            copyParams[key] = q[key];
          }
        }
      });
    }
    this.queryParams['iucceProjectId'] = this.projectId;
    this.queryParams['iucceProjectNicheId'] = data.id;
    copyParams['iucceProjectId'] = this.projectId;
    copyParams['iucceProjectNicheId'] = data.id;

    copyParams['page'] = 0;
    copyParams['size'] = 10000;
    copyParams['sort'] = ['year,desc', 'month,desc'];

    this.projectNicheService.getAllIucceProjectNicheFollowDatas(copyParams)
      .subscribe((res: any) => {

        this.dtNicheFollowLoading = false;
        const nicheFollowAllList = res.body;
        this.prjNicheFollowAllList[data.id] = nicheFollowAllList;
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getProjectNiches();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getProjectNiches();
  }

  initAuditProcess() {
    this.workflowService.getThingsListAll({
      'thingUrl.contains': this.projectId,
      'thingType.equals': 'Audit'
    }).subscribe(res => {
      if (res && res.body.length > 0) {
        const workFlows = res.body;
        const reportAudits = workFlows.filter(e => e.thingUrl.indexOf('projectReportAudit') > -1);
        if (reportAudits.length > 0) {
          const businessId = reportAudits[0].businessId;
          this.workflowService.getAuditIdeaList(businessId).subscribe(aRes => {
            if (aRes && aRes.body.success) {
              this.reportAuditList = aRes.body.result;
            }
          });
        }
        const closeAudits = workFlows.filter(e => e.thingUrl.indexOf('projectClosingAudit') > -1);
        if (closeAudits.length > 0) {
          const businessId = closeAudits[0].businessId;
          this.workflowService.getAuditIdeaList(businessId).subscribe(aRes => {
            if (aRes && aRes.body.success) {
              this.closeAuditList = aRes.body.result;
            }
          });
        }
      }
    });
  }

}
