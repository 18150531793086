import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { BimPersonService } from 'app/routes/business/bim/bim-info/bim-person/bim-person.service';
import { ProvinceService } from 'app/routes/business/bim/provinces/provinces.service';
import { RegionInfoService } from 'app/routes/business/bim/region-info/region-service.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-bim-cert-pass-detail',
  templateUrl: './detail.component.html',
  styleUrls: []
})
export class BimCertPassDetailComponent implements OnInit {

  /**
   * contentType
  */
  @Input() contentType = '';
  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public bimInfoList = [];

  public bimInfoId = null;
  public province = null;

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public queryParams = {
    // 查询搜索框的字段要预置
    'keyword': '',
  };

  columns: SimpleTableColumn[] = [
    { title: '姓名', index: 'name', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '学校', index: 'schoolName', width: '25%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '电话', index: 'tel', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '邮箱', index: 'email', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '类型', index: 'type', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '团队名称', index: 'teamName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
  ];

  public locationList = [];
  public provinceList = [];

  constructor(
    private router: Router,
    private routeInfo: ActivatedRoute,
    private bimInfoService: BimInfoService,
    private provinceService: ProvinceService,
    private bimPersonService: BimPersonService,
    private regionInfoService: RegionInfoService,
  ) { }

  ngOnInit(): void {
    this.bimInfoId = this.routeInfo.snapshot.queryParams['bimInfoId'];
    this.province = this.routeInfo.snapshot.queryParams['province'];
    // this.getAllRegion();
    // this.getProvinces(-1);
    if (!isNullOrUndefined(this.bimInfoId) && !isNullOrUndefined(this.province)) {
      this.getDataList();
    }
  }

  initBimInfoList() {
    this.isloadding = true;
    this.bimInfoService.query({
      'size': '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
      this.bimInfoList = res.body;
      this.getDataList();
    }, () => this.isloadding = false);
  }

  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.bimPersonService.getBimCertPassDetailPage(copyParams).subscribe((res: any) => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    });
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        q[key] = null;
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key].trim();
      }
    });
    copyParams['bimInfoId'] = this.bimInfoId;
    copyParams['province'] = this.province;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];
}
