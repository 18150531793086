import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'money' })
/**
 * 金额转换（元转万元）参数说明：args[0] 单位(万),args[1] 保留小数位数
 */
export class formatMoneyUnitConvertPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if(args[0] == null || args[0] == undefined || args[0] == ''){
            return value;
        }
        let valueNew = typeof value == 'number' ? value : parseFloat(value);
        if(isNaN(valueNew)){
            valueNew = 0;
        }
        let fixed = args[1];//保留小数位数
        if(args[1] == null || args[1] == undefined || args[1] == ''){
            fixed = 0;
        }
        let returnValue = valueNew.toString();
        switch (args[0]) {
            case '万':
                returnValue = fixed > 0 ? (valueNew / 10000).toFixed(fixed) : returnValue;
                break;
        
            default:
                break;
        }
        
        return returnValue;
    }
}
