import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
//  import { AdminUsersViewComponent } from './view/view.component';
// import { EduCourseEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CertPayService } from './cert-pay.service';
import { CertPay } from './cert-pay.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  selector: 'app-order-cert',
  templateUrl: './cert-pay.component.html',
})
export class EduCertPayComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: CertPay[] = [];
  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  // 查询参数
  queryParams = {
    'eduCourseName.contains': '',
    'eduStudentName.contains': '',
    'status.equals': '',
    'remark.contains': '',
  };


  columns: SimpleTableColumn[] = [
    // { title: '', index: 'id', type: 'checkbox' },
    { title: '订单号', index: 'id' , className: 'text-center', width: '14%'},
    { title: '报名科目', index: 'eduCertExamName' , className: 'text-center', width: '13%'},
    { title: '报名用户', index: 'eduUserAccount', width: '7%' },
    { title: '订单状态', index: 'status', className: 'text-center', width: '7%',
      format: (item: any) => {
        if (item.status === 0) {
          return '已下单';
        } else if (item.status === 1) {
          return '已支付';
        } else {
          return '其他';
        }
      }
},
    { title: '订单总价', index: 'price', className: 'text-center', width: '7%' },
    { title: '支付类型', index: 'payType', className: 'text-center', width: '7%',
      format: (item: any) => {
        if (item.payType === 'wx') {
          return '微信支付';
        } else if (item.payType === 'zfb') {
          return '支付宝支付';
        } else {
          return '未支付';
        }
      }
    },
    { title: '支付时间', index: 'payTime', className: 'text-center', width: '11%'},
    { title: '下单时间', index: 'createdDate', className: 'text-center', width: '11%'},
    { title: '交易单号', index: 'remark',className: 'text-center', width: '10%'},
    { title: '支付结果', index: 'payResult', className: 'text-center', width: '6%',
      format: (item: any) => {
        if (item.payResult === null || item.payResult === '') {
          return '未成功';
        } else {
          return '成功';
        }
      }
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public certPayService: CertPayService
  ) {}

  ngOnInit() {
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    this.certPayService.query(copyParams)
      .subscribe((res: any) => {
      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      });
  }



  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  // /**
  // * 过滤器变动事件 支持多选过滤
  // * @param
  // */
  // filterChange(event) {
  //   let i = 0;
  //   const _value = [];
  //   const _type = event.filterMultiple ? 'in' : 'equals';
  //   event.filters.forEach(element => {
  //     if (element.checked) {
  //       _value[i++] = element.value;
  //     }
  //   });
  //   this.queryParams[event.indexKey + '.' + _type] = _value;
  //   this.getDataList();
  // }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  // */
  // edit() {
  //   if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
  //   if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
  //   const tmp = this.formatIsOk(this.selectedRows[0]);
  //   this.modal
  //     .static(EduCourseEditComponent, { record: tmp })
  //     //      .pipe(filter(w => w === true))
  //     .subscribe(() => this.getDataList());
  // }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

}
