import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class EduSelectCourseService extends ThsBaseService<any> {

  courseUrl = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-topics`);
    this.courseUrl = httpAddressService.EduServe + '/edu-courses';
  }


queryTopic (queryParams ?: any): Observable<HttpResponse<any[]>> {
  return this.http.get<any[]>(`${this.resourceUrl}` + '/getTopicByPage',
  {params: queryParams ,  observe: 'response'}).pipe(map((res: HttpResponse<any[]>) => res));
 }

 queryCourse (queryParams ?: any): Observable<HttpResponse<any[]>> {
  return this.http.get<any[]>(this.courseUrl,
  {params: queryParams ,  observe: 'response'}).pipe(map((res: HttpResponse<any[]>) => res));
 }

}

