/*
 * @Author: hyman
 * @Date: 2023-03-06 08:35:19
 * @LastEditors: hyman
 * @LastEditTime: 2023-03-30 09:33:13
 * @Description: 请填写简介
 */
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { BusinessRoutingModule } from './business-routing.module';
import { EduModule } from './edu/edu.module';
import { BimModule } from './bim/bim.module';
import { IndustryModule } from './industry/industry.module';
import { IucceModule } from './iucce/iucce.module';

const COMPONENTS = [
];
const COMPONENTS_NOROUNT = [
];

@NgModule({
  imports: [
    SharedModule,
    BusinessRoutingModule,
    EduModule,
    BimModule,
    IucceModule,
    IndustryModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT
  ],
  entryComponents: COMPONENTS_NOROUNT
})
export class BusinessModule { }
