import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema, SFComponent} from '@delon/form';
import { Student } from '../student.model';
import { StudentService } from '../student.service';
import { map } from 'rxjs/operators';
import { NzFormatEmitEvent, NzTreeComponent } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ArrayService } from '@shared/utils/array.service';




@Component({
  selector: 'app-edu-student-edit',
  templateUrl: './edit.component.html',
})
export class EduStudentEditComponent implements OnInit {
  @ViewChild('nzTree') nzTree: NzTreeComponent;
  @ViewChild('sf') sf: SFComponent;
  orgPerson: any ;
  record: any = {};
  submitting = false;
  formData: any;
  imgUrl = '';
  originName = '';
  type = '';
  resourceType = '';
  schema: SFSchema = {
    properties: {
      name: {
        type: 'string', title: '名称', maxLength: 50
      },
      birthday: {
        type: 'string', title: '出生日期', ui: { widget: 'date', end: 'end' } , default: new Date()
      },
      idCard: {
        type: 'string', title: '身份证号码', maxLength: 18  ,
        // tslint:disable-next-line:max-line-length
        pattern: '(^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$)|(^[1-9]\\d{5}\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}$)' ,
      },
      signature: {
        type: 'string', title: '个人签名', maxLength: 100
      },
     /*  college: {
        type: 'string', title: '学校', maxLength: 100
      },
      department: {
        type: 'string', title: '院系', maxLength: 100
      },  */
      studentNum: {
        type: 'string', title: '学号',  maxLength: 20
      },
      education: {
        type: 'string', title: '学历', maxLength: 20
      },
      profession: {
        type: 'string', title: '学习专业', maxLength: 50
      },
      extraProfession: {
        type: 'string', title: '额外专业', maxLength: 50
      },
      career: {
        type: 'string', title: '职业',
         ui: {
          widget: 'radio',
          asyncData: () => of([{ label: '学生', value: '1' }, { label: '在职', value: '2' }, { label: '其他', value: '3' }]),
          change: console.log,
          } ,
        default: '1',
      },
      likePoints: {
        type: 'number', title: '点赞数量', default: 0
      },
      learningTime: {
        type: 'number', title: '学习时长', default: 0
      }
    },
    required: ['name', 'idCard' , 'birthday']
  };
  ui: SFUISchema = {
    '*': {
      spanLabelFixed: 140,
      spanLabel: 8,
      spanControl: 16,
      grid: { span: 12 },
    },
    $idCard: {
      widget: 'string',
      errors: { 'pattern': '请输入正确的身份证号' }
    }
  };


  tokenData: any;
  queryParams2 = {
    fileFk : '',
  };
  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private teacherService: StudentService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private arrService: ArrayService
  ) {}

  ngOnInit(): void {
    // this.formatRecord();
    // console.log(this.record);
// debugger
    const _this_ = this;
    if (this.record.id == null) {
      this.formData = this.record;
    } else {
      this.teacherService.find(this.record.id)
        .subscribe(res => {
          this.formData = this.formatRecord(res.body);
          this.type =  this.formData.type;
        });
    }
  }
  save(resource ?: Student) {
    if (!this.sf.liveValidate) {
      this.sf.validator();
    }
   if (!this.sf.valid) {
      this.msgSrv.error('必填项不能为空，请检查');
      return ;
    }
    this.submitting = true;
  }

  orgChange(value) {
    console.log(value);
  }

  formatIsOk() {
    if (this.sf.value.isOk === true) {
      this.sf.value.isOk = '1';
    } else if (this.sf.value.isOk === false) {
      this.sf.value.isOk = '0';
    }
  }

  formatRecord(record: any) {
    if (record.isOk === '1') {
      record.isOk = true;
    } else if (this.record.isOk === '0') {
      record.isOk = false;
    }
    return record;
  }

  uploadFinish(file: any) {
    if (file && file.success && file.success === true) {
      this.sf.value.imgurl = file.file.id;
    }
  }

  saveResource() {
    const _this_ = this;
    _this_.sf.value.type = this.resourceType;
    return new Promise(function (resolve) {
      _this_.formatIsOk();
      if (_this_.sf.value.id == null) {
        _this_.teacherService.create(_this_.sf.value)
          .subscribe(res => {
            _this_.formData.id = res.body.id;
            resolve('保存学生成功');
          });
      } else {
        _this_.teacherService.update(_this_.sf.value)
          .subscribe(res => {
            resolve('更新学生成功');
            _this_.imgUrl = '';
          });
      }
    });
  }
  close() {
    this.modal.destroy();
  }

  typeChange (obj: any) {
    this.resourceType = obj;
  }
}
