import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimTeamitems } from './bim-team-items.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimTeamItemsService extends ThsBaseService<BimTeamitems> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-teamitems`);
    }

    saveItemByTeam(param: any): Observable<HttpResponse<any>> {
        let url = this.resourceUrl + '/saveItemByTeam';
        return this.http.get<any>(url, {params:param, observe: 'response'});
    }
}

