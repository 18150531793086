import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CertRegedistPay} from './certRegedistPay.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CertRegedistPayManageService extends ThsBaseService<CertRegedistPay> {
  channelUrl = '';
  payUrl  = '';
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-cert-regedist-pays`);
      this.payUrl = httpAddressService.EduServe + `/edu-cert-regedist-pays`;
      this.channelUrl = httpAddressService.EduServe + '/edu-certification-channels';
  }
  findAllChannel(): Observable<HttpResponse<any[]>>  {
  const url = this.channelUrl + '/findAllChannel';
  return this.http.get<any>(`${url}`, { observe: 'response' });
}

queryData (queryParams ?: any): Observable<HttpResponse<any[]>> {
  const url =  this.payUrl + '/getEduCertRegedistPayByPage';
  return this.http.get<any[]>(url, {params: queryParams ,  observe: 'response'});
}
}

