import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ThsBaseService } from '../../../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../../../shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BimTeamResult } from './bim-team-result.model';
@Injectable({ providedIn: 'root' })
export class BimTeamResultService extends ThsBaseService<BimTeamResult> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.BimServe + `/bim-team-results`);
  }
  getTeamResultDataList(param: any) {
    return this.http
      .get<any[]>(`${this.resourceUrl}/getTeamResultDataList`, {
        params: param,
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)),
      );
  }

  sendMessageByPersonId(personId: String, personResultId: String) {
    let url = `${
      this.resourceUrl
    }/sendMessageByPersonId/${personId}/${personResultId}`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  sendMessage(bimInfoEventsId: String, personResultId: any[]) {
    return this.http.post<any>(
      this.resourceUrl + '/sendMessage/' + bimInfoEventsId,
      personResultId,
      { observe: 'response' },
    );
  }

  /** DELETE: 根据eventId来删除记录 */
  deleteAllByEventId(eventId: string): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/deleteAllByEventId/${eventId}`;
    return this.http.delete<HttpResponse<any>>(url, { observe: 'response' });
  }

  /** DELETE: 根据eventId、itemId来删除记录 */
  deleteAllByEventIdAndItemId(
    eventId: string,
    itemId: string,
  ): Observable<HttpResponse<any>> {
    const url = `${
      this.resourceUrl
    }/deleteAllByEventIdAndItemId/${eventId}/${itemId}`;

    return this.http.delete<HttpResponse<any>>(url, { observe: 'response' });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }
}
