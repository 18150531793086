import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as echarts from 'echarts';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { BimPersonService } from 'app/routes/business/bim/bim-info/bim-person/bim-person.service';

@Component({
  selector: 'app-megagame-cockpit',
  templateUrl: './megagame-cockpit.html',
  styleUrls: ['./megagame-cockpit.less'],
})
export class MegagameCockpitComponent implements OnInit, AfterViewInit {
  validateForm: FormGroup;
  @ViewChild('chart1') chart1: ElementRef;
  @ViewChild('chart2') chart2: ElementRef;
  @ViewChild('chart3') chart3: ElementRef;
  @ViewChild('chart4') chart4: ElementRef;
  @ViewChild('chart5') chart5: ElementRef;
  @ViewChild('chart6') chart6: ElementRef;
  @ViewChild('chart7') chart7: ElementRef;
  @ViewChild('chart8') chart8: ElementRef;
  @ViewChild('chart9') chart9: ElementRef;
  @ViewChild('chart10') chart10: ElementRef;
  @ViewChild('chart11') chart11: ElementRef;
  @ViewChild('chart12') chart12: ElementRef;
  selectedValue;


  public schoolJoinNum = 0;
  // 教育云数据
  public registerUser = 0;
  public todayUser = 0;
  public todayStudyUser = 0;
  public allUser = 0;
  public allStudyUser = 0;
  // 团队赛数据
  public team = null;
  public teamItem = null;
  public teamWork = null;
  public teamWorkItem = null;
  public teamTop = null;
  public teamTopItem = null;
  // 免考认证
  public certPay = null;
  public certUnPay = null;
  public certPassPerson = null;
  public certCancel = null;
  public certAmount = null;
  public certAmountTarget = 300000;

  public bimInfoId = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private bimPersonService: BimPersonService,
    ) {

  }

  ngOnInit(): void {
    this.initSearchForm();
    this.initHomeData();
  }

  initHomeData() {
    this.bimPersonService.statisBimInfoHomeData().subscribe((res: any) => {
      console.log(res);
      if (res && res.body) {
        const data = res.body;

        this.bimInfoId = data.bimInfoId;
        // 个人赛报名数据
        const person = data.person;
        const personTarget = 1200;
        const personItem = data.personItem;
        const personJoin = data.personJoin;
        const personJoinTarget = 1000;
        const personJoinItem = data.personJoinItem;

        const perItemArr = new Map<string, Array<any>>();
        if (personItem && personItem.length > 0) {
          personJoinItem.forEach(e => {
            const arr = [];
            arr.push(e.num);
            perItemArr.set(e.itemName, arr);
          });
        }
        perItemArr.forEach((v, k) => {
          if (personItem && personItem.length > 0) {
            const joinItem = personItem.filter(f => k === f.itemName);
            if (joinItem && joinItem.length > 0) {
              v.push(joinItem[0].num);
            } else {
              v.push(0);
            }
          } else {
            v.push(0);
          }
        });

        const key = [];
        const value = [];

        perItemArr.forEach((v, k) => {
          key.push(k);
          value.push(v);
        });

        this.initPersonChart(this.chart1.nativeElement, key, ['个人赛参赛', '个人赛报名'], value,
            [(personJoin.num / personJoinTarget).toFixed(2), (person.num / personTarget).toFixed(2)], [personJoin.num, person.num]);

        // 参赛院校数据
        const school = data.school;
        const schoolTarget = 500;

        this.schoolJoinNum = school.num;
        this.initPeiChart(this.chart12.nativeElement, 'rgba(43, 181, 201, 1)', ((this.schoolJoinNum / schoolTarget) * 100).toFixed(2));

        // 教育云数据
        this.registerUser = data.registerUser;
        this.todayUser = data.todayUser;
        this.todayStudyUser = data.todayStudyUser;
        this.allUser = data.todayUser;
        this.allStudyUser = data.allStudyUser;

        // 团队赛数据
        this.team = data.team;
        this.teamItem = data.teamItem;
        this.teamWork = data.teamWork;
        this.teamWorkItem = data.teamWorkItem;
        this.teamTop = data.teamTop;
        this.teamTopItem = data.teamTopItem;

        this.initDataPeichart(this.chart10.nativeElement);

        // 免考认证
        this.certPay = isNullOrUndefined(data.certPay) ? 0 : data.certPay;
        this.certUnPay = isNullOrUndefined(data.certUnPay) ? 0 : data.certUnPay;
        this.certPassPerson = isNullOrUndefined(data.certPassPerson) ? 0 : data.certPassPerson;
        this.certCancel = isNullOrUndefined(data.certCancel) ? 0 : data.certCancel;
        this.certAmount = data.certAmount;
        this.initPeiChart(this.chart11.nativeElement, 'rgba(255, 142, 108, 1)', (this.certAmount / this.certAmountTarget * 100).toFixed(2));

        // 近三年数据对比
        const bimInfoCompare = data.bimInfoCompare;
        const yearArr = [];
        const compCol = {
          person: [],
          personJoin: [],
          team: [],
          teamTop: [],
          school: [],
          certPassPerson: [],
          certPay: [],
          certAmount: [],
        };
        if (!isNullOrUndefined(bimInfoCompare)) {
          // tslint:disable-next-line:forin
          for (const k in bimInfoCompare) {
            const compareInfo = bimInfoCompare[k];
            yearArr.push(k);
            if (!isNullOrUndefined(compareInfo) && compareInfo.length > 0) {
              compareInfo.forEach(e => {
                compCol[e.type].push(e.num);
              });
            } else {
              // tslint:disable-next-line:forin
              for (const t in compCol) {
                compCol[t].push(0);
              }
            }
            console.log(compCol);
          }
        }
        this.initBarChart(this.chart2.nativeElement, [
          'rgba(24, 144, 255, 1)', 'rgba(255, 171, 24, 1)'
        ], ['报名人数', '参赛人数'], yearArr, {
          data1: compCol.person,
          data2: compCol.personJoin,
        });
        this.initBarChart(this.chart3.nativeElement, [
          'rgba(48, 196, 233, 1)', 'rgba(247, 214, 58, 1)'
        ], ['报名队伍数', '晋级队伍数'], yearArr, {
          data1: compCol.team,
          data2: compCol.teamTop,
        });
        this.initLineChart2(this.chart4.nativeElement, compCol.school, yearArr);
        this.initBarChart(this.chart5.nativeElement, [
          'rgba(97, 216, 176, 1)', 'rgba(255, 146, 113, 1)'
        ], ['免考认证及格人数', '免考认证缴费人数'], yearArr, {
          data1: compCol.certPassPerson,
          data2: compCol.certPay,
        }, 2);
      }
    });
  }

  formatAmount(amount) {
    return (amount / 10000).toFixed(2);
  }

  initSearchForm() {
    this.validateForm = this.fb.group({
      startYear: [null, [Validators.required]],
      endYear: [null],
    });
  }

  ngAfterViewInit() {
    this.initLineChart(this.chart8.nativeElement, ['参赛费（万元）', '业绩完成（%）'], ['参赛费', '业绩完成率']);
    // tslint:disable-next-line:max-line-length
    this.initLineChart(this.chart9.nativeElement, ['缴费（万元）', '业绩完成（%）'], ['免考认证缴费', '业绩完成率'], ['rgba(113, 116, 251, 1)', 'rgba(255, 140, 113, 1)']);
  }

  rebuild(data) {
    let max = data[0];
    data.forEach(item => { max = item > max ? item : max; });
    return data.map(item => {
      return item ? item : {
        value: item,
        label: { show: false }
      };
    });
  }

  bindResize(chart) {
    window.addEventListener('resize', () => {
      chart.resize();
    });
  }

  initBarChart(chartIns, color = [
    'rgba(74, 207, 133, 1)',
    'rgba(255, 178, 103, 1)',
    'rgba(66, 203, 253, 1)',
  ], names, axisData, datas, type = 1) {
    const chart = echarts.init(chartIns);
    const { data1, data2 } = datas;

    const picData1 = data2.map((item, index) => item + data1[index]);
    // let picData2 = data3.map((item, index) => item + picData1[index]);

    const option: any = {
      color,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',
          z: -1
        },
        formatter: function (v: any) {
          let str = v[0].name + '<br/>';
          v.forEach(item => {
            if (item.componentSubType !== 'pictorialBar') {
              const { marker, seriesName, value } = item;
              str += `${marker} ${seriesName}：${value}<br/>`;
            }
          });
          return str;
        },
      },
      xAxis: {
        type: type !== 1 ? 'value' : 'category',
        axisLabel: { color: 'rgba(153, 153, 153, 1)' },
        axisLine: {
          show: true,
          lineStyle: { color: 'rgba(204, 204, 204, 1)' },
        },
        axisTick: { show: false },
        splitLine: { show: type !== 1 },
      },
      yAxis: {
        type: type !== 1 ? 'category' : 'value',
        axisLabel: { color: 'rgba(153, 153, 153, 1)' },
        axisLine: { lineStyle: { color: 'rgba(204, 204, 204, 1)' } },
        axisTick: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          name: names[0],
          type: 'bar',
          barWidth: 16,
          label: { show: true, position: type === 1 ? 'top' : 'right' },
          itemStyle: {
            barBorderRadius: type === 1 ? [10, 10, 0, 0] : [0, 10, 10, 0]
          },
          data: this.rebuild(data1),
        },
        {
          name: names[1],
          type: 'bar',
          barWidth: 16,
          label: { show: true, position: type === 1 ? 'top' : 'right' },
          itemStyle: {
            barBorderRadius: type === 1 ? [10, 10, 0, 0] : [0, 10, 10, 0]
          },
          data: this.rebuild(data2),
        },
      ],
    };
    if (type === 1) {
      option.xAxis.data = axisData;
      option.legend = { top: 30, right: 10, itemWidth: 14 };
      option.grid = { left: 10, right: 10, bottom: 10, top: 65, containLabel: true };
    } else {
      option.yAxis.data = axisData;
      option.legend = { top: 30, right: 20, itemWidth: 14 };
      option.grid = { left: 10, right: 20, bottom: 10, top: 65, containLabel: true };
    }
    chart.setOption(option);
    this.bindResize(chart);
  }

  initLineChart(chartIns, yAxisName, seriesName, color = ['rgba(48, 122, 233, 1)', 'rgba(255, 113, 143, 1)']) {
    const chart = echarts.init(chartIns);
    const data1 = [320, 302, 301, 334, 390, 330, 320];
    const data2 = [30, 132, 101, 134, 90, 230, 210];
    const data3 = [30, 182, 191, 234, 290, 330, 310];

    const option = {
      color,
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',        // 默认为直线，可选为：'line' | 'shadow'
          z: -1
        },
      },
      legend: {
        top: 1,
        right: 0,
        itemWidth: 14
      },
      grid: {
        left: 30,
        right: 30,
        bottom: 10,
        top: 65,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          color: 'rgba(153, 153, 153, 1)'

        },
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(204, 204, 204, 1)'
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      },
      yAxis: [
        {
          type: 'value',
          name: yAxisName[0],
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true
          },
        },
        {
          type: 'value',
          name: yAxisName[1],
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)',


            formatter: '{value}%'
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
        }
      ],
      series: [
        {
          name: seriesName[0],
          type: 'bar',
          barWidth: 16,
          itemStyle: {
            barBorderRadius: [15, 15, 0, 0]
          },
          label: {
            show: true,
            position: 'top'
          },
          data: data1,
        },
        {
          yAxisIndex: 1,
          name: seriesName[1],
          type: 'line',
          smooth: true,
          symbol: '',
          symbolSize: 6,
          lineStyle: {
            width: 3,
            shadowBlur: 5,
            shadowColor: 'rgba(255, 113, 143, .6)',
            shadowOffsetY: 5
          },
          emphasis: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3,
            }
          },
          label: {
            show: false,
            position: 'top',
            formatter: '{c}%'
          },
          data: data2,
        },
      ]
    };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initLineChart2(chartIns, data, yearData) {
    const chart = echarts.init(chartIns);

    const option = {
      color: ['rgba(40, 195, 218, 1)'],
      grid: {
        left: 30,
        right: 30,
        bottom: 10,
        top: 65,
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',       // 默认为直线，可选为：'line' | 'shadow'

          z: -1
        },
      },
      legend: {
        top: 1,
        right: 20,
        itemWidth: 14
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: yearData
        }],
      yAxis: [
        {
          type: 'value',
          name: '',
          nameTextStyle: {
            color: 'rgba(153, 153, 153, 1)'
          },
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)'

          },
          axisLine: {
            lineStyle: {
              color: 'rgba(204, 204, 204, 1)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(230, 230, 230, 1)'
            }
          },
        },
      ],
      series: [
        {
          name: '参赛院校数量',
          type: 'line',
          smooth: true,
          symbol: '',
          symbolSize: 10,
          itemStyle: {
          },
          lineStyle: {
            width: 3,
            // shadowBlur: 5,
            // shadowColor: 'rgba(40, 195, 218, .6)',
            // shadowOffsetY: 5
          },
          emphasis: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3,
            }
          },
          label: {
            show: true,
            position: 'top',
            formatter: '{c}'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(40, 195, 218, .3)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(40, 195, 218, .05)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          data,
        },
      ]
    };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initPersonChart(chartIns, names, axisData, datas, percents = ['0', '0'], personNum = [0, 0]) {
    const chart = echarts.init(chartIns);
    const series = [];
    if (names && names.length > 0) {
      for (let i = 0; i < names.length; i++) {
        series.push({
          name: names[i],
          type: 'bar',
          stack: 'total',
          barWidth: 20,
          label: { show: true, position: 'inside' },
          data: this.rebuild(datas[i]),
        });
      }
    }

    const option: any = {
      color: [
        'rgba(24, 144, 255, 1)',
        'rgba(66, 211, 206, 1)',
        'rgba(255, 198, 24, 1)',
        'rgba(255, 138, 24, 1)',
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow',
          z: -1
        },
        formatter: function (v: any) {
          let str = v[0].name + '<br/>';
          v.forEach(item => {
            if (item.componentSubType !== 'pictorialBar') {
              const { marker, seriesName, value } = item;
              str += `${marker} ${seriesName}：${value}<br/>`;
            }
          });
          return str;
        },
      },
      xAxis:
        [{
          type: 'value',
          axisLabel: {
            color: 'rgba(153, 153, 153, 1)',

          },
          axisLine: {
            show: false,
            lineStyle: { color: 'rgba(204, 204, 204, 1)' },
          },
          axisTick: { show: false },
          splitLine: {
            show: true, lineStyle: {
              color: 'rgba(230, 230, 230, 1)'
            }
          },
        }]
      ,
      yAxis: [{
        type: 'category',
        axisLabel: {
          color: 'rgba(153, 153, 153, 1)',
          formatter: (value, index) => {
            let txt = `{a|${value}}\n`;
            txt += !index ? `{c|参赛率${percents[0]}%}` : `{b|完成率${percents[1]}%}`;
            return txt;
          },
          align: 'center',
          margin: 65,
          rich: {
            a: {
              fontSize: 14,
              lineHeight: 40,
              color: '#666666'
            },
            b: {
              fontSize: 12,
              lineHeight: 12,
              color: '#18A5FF',
              padding: [5, 10],
              backgroundColor: 'rgba(215, 239, 255, 1)',
              borderRadius: 15
            },
            c: {
              fontSize: 12,
              lineHeight: 12,
              color: 'rgba(24, 133, 255, 1)',
              padding: [5, 10],
              backgroundColor: 'rgba(215, 233, 255, 1)',
              borderRadius: 15
            }
          }
        },
        axisLine: { lineStyle: { color: 'rgba(204, 204, 204, 1)' } },
        axisTick: { show: false },
        splitLine: { show: false },
        data: axisData
      },
      {
        type: 'category',
        axisLabel: {
          color: 'rgba(51, 51, 51, 1)',
          fontSize: 16,
          fontWeight: 'bold'
        },
        axisLine: { show: false, lineStyle: { color: 'rgba(204, 204, 204, 1)' } },
        axisTick: { show: false },
        splitLine: { show: false },
        data: [personNum[0], personNum[1]]
      }
      ],
      series: series,
    };
    option.yAxis.data = axisData;
    option.legend = { top: 5, right: 20, itemWidth: 8, icon: 'circle', itemHeight: 8 };
    option.grid = { left: 115, right: 90, bottom: 50, top: 50, containLabel: false };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initDataPeichart(chartIns) {
    const chart = echarts.init(chartIns);
    const titles = [
      {
        name: '报名队伍',
        value: this.team.num
      },
      {
        name: '提交作品队伍',
        value: this.teamWork.num
      },
      {
        name: '晋级队伍',
        value: this.teamTop.num
      }
    ];
    const teamItemArr = this.teamItem.map(item => {
      return {
        name: item.itemName,
        value: item.num
      };
    });
    const teamWorkItemArr = this.teamWorkItem.map(item => {
      return {
        name: item.itemName,
        value: item.num
      };
    });
    const teamTopItemArr = this.teamTopItem.map(item => {
      return {
        name: item.itemName,
        value: item.num
      };
    });
    const option = {
      color: ['rgba(24, 144, 255, 1)', 'rgba(66, 211, 206, 1)', 'rgba(255, 198, 24, 1)', 'rgba(255, 138, 24, 1)'],
      title: [
        {
          text: titles[0].name,
          subtext: titles[0].value,
          left: '15.5%',
          top: '40%',
          textAlign: 'center',
          textStyle: {
            color: '#666',
            fontSize: 16,
            lineHeight: 20,
            fontWeight: 400
          },
          subtextStyle: {
            color: '#333',
            fontSize: 22,
            fontWeight: 'bold'
          },
        },
        {
          text: titles[1].name,
          subtext: titles[1].value,
          left: 'center',
          top: '40%',
          textStyle: {
            color: '#666',
            fontSize: 16,
            lineHeight: 20,
            fontWeight: 400
          },
          subtextStyle: {
            color: '#333',
            fontSize: 22,
            fontWeight: 'bold'
          },
        },
        {
          text: titles[2].name,
          subtext: titles[2].value,
          left: '82.5%',
          top: '40%',
          textAlign: 'center',
          textStyle: {
            color: '#666',
            fontSize: 16,
            lineHeight: 20,
            fontWeight: 400
          },
          subtextStyle: {
            color: '#333',
            fontSize: 22,
            fontWeight: 'bold'
          },
        },
      ],
      tooltip: {
        trigger: 'item',
      },
      legend: {
        left: 'center',
        bottom: 8,
        itemWidth: 12,
        itemHeight: 12
      },
      series: [
        {
          name: titles[0].name,
          type: 'pie',
          radius: ['45%', '60%'],
          center: ['16%', '50%'],
          label: {
            show: false
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: '#fff'
          },
          data: teamItemArr
        },
        {
          name: titles[1].name,
          type: 'pie',
          radius: ['45%', '60%'],
          center: ['50%', '50%'],
          label: {
            show: false
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: '#fff'
          },
          data: teamWorkItemArr
        },
        {
          name: titles[2].name,
          type: 'pie',
          radius: ['45%', '60%'],
          center: ['83%', '50%'],
          label: {
            show: false
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: '#fff'
          },
          data: teamTopItemArr
        }
      ]
    };
    chart.setOption(option);
    this.bindResize(chart);
  }

  initPeiChart(chartIns, color = 'rgba(255, 142, 108, 1)', finishRate) {
    const chart = echarts.init(chartIns);
    const option = {
      title: [
        {
          text: '完成率：',
          subtext: finishRate + '%',
          left: 'center',
          top: '35%',
          textStyle: {
            color: '#666',
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 400
          },
          subtextStyle: {
            color,
            fontSize: 18,
            fontWeight: 'bold'
          },
        },
      ],
      tooltip: {
        trigger: 'item',
      },
      polar: {
        radius: ['65%', '80%'],
        center: ['50%', '50%'],
      },
      angleAxis: {
        max: 100,
        clockwise: false,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      radiusAxis: {
        type: 'category',
        show: true,
        inverse: true,
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          name: '完成率',
          type: 'bar',
          roundCap: true,

          clockwise: false,
          showBackground: true,
          backgroundStyle: {
            color: '#d0daea',
          },
          data: [finishRate],
          coordinateSystem: 'polar',
          itemStyle: {
            normal: {
              color
            },
          },
        },
      ]
    };

    chart.setOption(option);
    this.bindResize(chart);
  }

  bimCertPassData() {
    if (!isNullOrUndefined(this.bimInfoId)) {
      this.router.navigate(['/dashboard/bimCertPass'], { queryParams: { bimInfoId: this.bimInfoId } });
    }
  }

}
