import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BimTeamTearchers } from './bim-team-teacher.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class BimTeamTearchersService extends ThsBaseService<BimTeamTearchers> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-team-tearchers`);
        console.log(this.resourceUrl);
    }


}

