import { formatEstablishStatusPipe } from "./iucce/formatEstablishStatus.pipe";
import { formatProjectClosingStatusPipe } from "./iucce/formatProjectClosingStatus.pipe";

export class PipeManager {
    private static _pipes = {
        'convertEstablishStatus': new formatEstablishStatusPipe(),
        'convertClosingStatus': new formatProjectClosingStatusPipe()
    };
  
    public static PipeForKey(key: string) {
      return PipeManager._pipes[key];
    }

    public static formatEstablishStatus() {
        return PipeManager._pipes.convertEstablishStatus;
    }

    public static formatClosingStatus() {
        return PipeManager._pipes.convertClosingStatus;
    }
}