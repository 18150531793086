import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimTeams } from './bim-teams.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class BimTeamsService extends ThsBaseService<BimTeams> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-teams`);
    }


    /** GET: 分页、多条件查询记录列表 */
    queryNew (queryParams ?: any): Observable<HttpResponse<BimTeams[]>> {
        let url = this.resourceUrl + "/getAllBimTeamsNew"
        return this.http.get<BimTeams[]>(url, {params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<BimTeams[]>) => this.convertDateArrayFromServer(res)));
    }


    exportExcel(queryParams ?: any) {
      const url = `${this.resourceUrl}/exportExcel`;
  
      //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
      this.http.get(url ,  {
        params: queryParams ,
        headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
        observe: 'response',
        responseType: 'blob',
        withCredentials: true,
      }).subscribe(
        data => {
          let blob = new Blob([data.body]);
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.setAttribute('href', objectUrl);
          a.setAttribute('download',  '团队名单' + '.xlsx');
          a.click();
          URL.revokeObjectURL(objectUrl);
        },
        error => {
          this.handleError('下载失败,请稍后重试!');
          console.error('团队名单下载失败 ->', error);
        },
      );
  
    }
    

    /**
     * 晋级或取消晋级（校内初评作品）
     * @param record 
     * @returns 
     */
    saveTeamTop(id,isTop:number):Observable<HttpResponse<any>> {
        return this.http.post<any>(this.resourceUrl + `/saveTeamTop/${id}/${isTop}`, {}, { observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }

  
      
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }

  exportTeamEnrollWord(param: any) {
    const url =  `${this.resourceUrl}/exportTeamEnrollWord`;
    return this.http.get(url, { params: param , observe: 'response' });
  }


  exportEnrollExcel(queryParams ?: any) {
    const url = `${this.resourceUrl}/exportTeamEnrollExcel`;

    //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    this.http.get(url ,  {
      params: queryParams ,
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).subscribe(
      data => {
        let blob = new Blob([data.body]);
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download',  '报名汇总表' + '.xlsx');
        a.click();
        URL.revokeObjectURL(objectUrl);
      },
      error => {
        this.handleError('下载失败,请稍后重试!');
        console.error('报名汇总表下载失败 ->', error);
      },
    );

  }

  /** GET: 分页、多条件查询记录列表 */
  getReplyWorkFilePage (queryParams ?: any): Observable<HttpResponse<any>> {
      let url = this.resourceUrl + "/getReplyWorkFilePage"
      return this.http.get<any[]>(url, {params: queryParams ,  observe: 'response'})
      .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  // 更新团队答辩
  updateWorkReplyType(param: any) {
    const url =  `${this.resourceUrl}/updateWorkReplyType`;
    return this.http.post<any>(url, param, { observe: 'response' });
  }

  /** GET: 分页、多条件查询记录列表 */
  getNoReplyTeamDataPage (queryParams ?: any): Observable<HttpResponse<any>> {
      let url = this.resourceUrl + "/getNoReplyTeamDataPage"
      return this.http.get<any[]>(url, {params: queryParams ,  observe: 'response'})
      .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }



  exportTeamEnrollFile(queryParams ?: any) {
    const url = `${this.resourceUrl}/exportTeamEnrollFile`;
    const __this = this;

    return new Promise(function (resolve, reject) {
      //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
      __this.http.get(url ,  {
        params: queryParams ,
        headers: new HttpHeaders({ 'Content-Type': 'application/zip' }),
        observe: 'response',
        responseType: 'blob',
        withCredentials: true,
      }).subscribe(
        data => {
          let blob = new Blob([data.body]);
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.setAttribute('href', objectUrl);
          a.setAttribute('download',  '团队报名附件' +  + moment(new Date()).format('YYYYMMDDHHmmss') + '.zip');
          a.click();
          URL.revokeObjectURL(objectUrl);
          resolve('success');
        },
        error => {
          __this.handleError('下载失败，请稍后重试!');
          reject('下载失败，请稍后重试');
        },
      );
    });
  }
}

