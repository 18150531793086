import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// delon
import { AlainThemeModule } from '@delon/theme';
import { DelonABCModule } from '@delon/abc';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
// i18n
import { TranslateModule } from '@ngx-translate/core';

// region: third libs
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CountdownModule } from 'ngx-countdown';
import { UEditorModule } from 'ngx-ueditor';
import { NgxTinymceModule } from 'ngx-tinymce';
import { SimplemdeModule } from 'ngx-simplemde';
import { NotificationViewComponent } from 'app/routes/admin/message/notification/view/view.component';
import { FileUploadComponent } from './components/fileUpload/fileUpload.component';
import { ThsOrgSelector } from './components/orgSelector/orgSelector.component';
import { WorkflowFormComponent } from './components/workflow/workflowform.component';
import { WorkflowFormCusComponent } from './components/workflow/workflowform_cus.component';
import { WorkflowAuditListComponent } from './components/workflow/workflowauditlist.component';
import { WorkflowAuditListCusComponent } from './components/workflow/workflowauditlist_cus.component';
import { WorkflowSubmitComponent } from './components/workflow/workflowsubmit.component';
import { ThsMonenyInputComponent } from './components/moneyInput/moneyInput.component';
import { DictComponent } from './components/dict/dict.component';
import { ThsTeacherSelect } from './components/teacherSelector/teacherSelector.component';
import { ThsCollegesSelectComponent } from './components/provincesColleges/provincesColleges.component';
import { BimContestListComponent } from './components/bim-contest-list/bim-contest-list.component';
import { FileUploadPreviewComponent } from './components/fileUpLoadPreview/fileUploadPreview.component';
import { FUploadComponent } from './components/f-upload/f-upload.component';
import { FileUploadPreviewSmallComponent } from './components/fileUpLoadPreview/fileUploadPreviewSmall.component';
import { ProjectInfoEditComponent } from './components/project-info-edit/project-info-edit.component';

const THIRDMODULES = [
  NgZorroAntdModule,
  CountdownModule,
  UEditorModule,
  NgxTinymceModule,
  SimplemdeModule
];
// endregion

// region: your componets & directives
const COMPONENTS = [
  FileUploadComponent,
  FileUploadPreviewComponent,
  FileUploadPreviewSmallComponent,
  ThsOrgSelector,
  WorkflowFormComponent,
  WorkflowFormCusComponent,
  WorkflowAuditListComponent,
  WorkflowAuditListCusComponent,
  WorkflowSubmitComponent,
  ThsMonenyInputComponent,
  DictComponent,
  ThsTeacherSelect,
  ThsCollegesSelectComponent,
  BimContestListComponent,
  FUploadComponent,
  ProjectInfoEditComponent
];

const COMPONENTS_NOROUNT = [
  NotificationViewComponent,
  DictComponent,
  FileUploadComponent,
  FileUploadPreviewComponent,
  FileUploadPreviewSmallComponent,
  ProjectInfoEditComponent
];
const DIRECTIVES = [];
// endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonABCModule,
    DelonACLModule,
    DelonFormModule,
    // third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    ...COMPONENTS_NOROUNT,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonABCModule,
    DelonACLModule,
    DelonFormModule,
    // i18n
    TranslateModule,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  entryComponents: [COMPONENTS_NOROUNT]
})
export class SharedModule {}
