import { Injectable } from '@angular/core';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { IucceProjectSoftfundPay } from './projectSoftFundPay.model';

@Injectable({ providedIn: 'root' })
export class  IucceProjectSoftfundPayService extends ThsBaseService<IucceProjectSoftfundPay>  {
  constructor(
    public http: HttpClient, httpAddressService: HttpAddressService ) {
    super(http, httpAddressService.IucceServe + '/iucce-project-softfund-pays');
  }
}
