import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Project } from './../projectReport/projectReport.model';
import { ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from 'app/shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectNicheFollowService extends ThsBaseService<Project> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.IucceServe + `/iucce-project-niche-follows`);
    }
    
    
    /** POST: 添加一条商机跟进详情新记录 */
    createIucceProjectNicheFollow (record: any): Observable<HttpResponse<any>> {
        const copy = this.convertDateFromClient(record);
        return this.http.post<any>(this.resourceUrl+`/createIucceProjectNicheFollow`, copy, { observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }
    
    /** POST: 修改一条商机跟进详情新记录 */
    updateIucceProjectNicheFollow (record: any): Observable<HttpResponse<any>> {
        const copy = this.convertDateFromClient(record);
        return this.http.put<any>(this.resourceUrl+`/updateIucceProjectNicheFollow`, copy, { observe: 'response'})
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
    }
}

