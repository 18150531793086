import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ModalHelper } from '@delon/theme';
import { MessageHelper } from '@shared/helpers/MessageHelper';
import { BimInfoEventsService } from '../bim-info/bim-info-events/bim-info-events.service';
import { BimInfoService } from '../bim-info/bim-info.service';
import { ProvinceService } from '../provinces/provinces.service';
import { RegionInfoService } from '../region-info/region-service.model';
import { ScoreDetailsComponent } from '../work-manage/score-details/score-details.component';
import { ShowTeamUsersComponent } from '../work-manage/show-team-users/show-team-users.component';
import { BimWorks } from '../work-manage/work-manage.model';
import { WorkManageService } from '../work-manage/work-manage.service';
import { SimpleTableColumn, SimpleTableData } from '@delon/abc';
import { isNullOrUndefined } from 'util';
import { WorkReplyDistributeComponent } from './distribute/distribute.component';
import { WorkReplyAddComponent } from './add/add.component';
import { BimWorkAdvisorComponent } from '../work-manage/work-advisor/bim-work-advisor.component';
import { BimItemsService } from '../bim-items/bim-items.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-work-reply-manage',
  templateUrl: './work-reply-manage.component.html',
  styles: []
})
export class WorkReplyManageComponent implements OnInit {

  searchTeamName = '';
  searchSchoolName = '';
  searchSchoolType = '-1';
  searchRegion = '-1'; // 大区
  regionList: any = [{ id: '', regionName: '' }];
  searchProvinces = '-1'; // 省份
  provincesList: any = [{ id: '', provinceName: '' }];
  searchBimInfo = '-1'; // 赛事
  bimInfoList: any = [{ id: '', name: '' }];
  searchBimInfoEvents = ''; // 赛项
  searchBimInfoItems = null; // 专项
  bimInfoEventsList: any = [{ id: '', name: '' }];
  searchWorkTop = -1; // 校内是否晋级

  public oriBimInfoEvent = [];

  isQueryEvent = false;

  curBimInfoName = '';

  page = 0;
  size = 20;
  total = 0;
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];
  list: BimWorks[] = [];
  loading = false;
  allChecked = false;
  indeterminate = false;

  curBimEventId = null;
  public currentBimInfo = null;
  public bimInfoStatus = -1;
  public exportReplyWork = false;

  public items = [];

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', width: '1%', type: 'checkbox' },
    { title: '作品名称', index: 'name', width: '18%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '团队名称', index: 'teamName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '所属学校', index: 'schoolName', width: '15%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '学校类型', index: 'schoolType', width: '6%', format: (item: any, col: any) => item[col.indexKey] === '0' ? '本科' : (item[col.indexKey] === '1' ? '专科' : '中职') },
    { title: '赛项', index: 'eventsName', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '参赛专项', index: 'itemName', width: '12%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '全国赛得分', index: 'secCore', width: '7%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '答辩评委', index: 'replyAdvisor', width: '6%',
          format: (item: any, col: any) => item['replyRaterList'] === null ? 0 : item['replyRaterList'].length },
    {
      title: '操作', width: '10%',
      buttons: [
        { text: '队员和指导老师', click: (item: any) => this.viewTeamUsers(item) },
        { text: '评委', click: (item: any) => this.workAdvisor(item) }
      ],
    }
  ];

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    public modalService: NzModalService,
    private bimInfoService: BimInfoService,
    private provinceService: ProvinceService,
    private bimItemsService: BimItemsService,
    private regionInfoService: RegionInfoService,
    private workManageService: WorkManageService,
    private bimInfoEventsService: BimInfoEventsService,
  ) {
  }

  ngOnInit() {
    this.getAllRegion();
    this.getProvinces('-1');
  }

  /**
   * 获取所有大区
   */
  getAllRegion() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'orderNum',
    };
    this.regionInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.regionList = [{ id: '-1', regionName: '全部' }];
        this.regionList.push(...res.body);
      }
    });
  }

  /**
   * 根据大区获取省份
   */
  getProvinces(value) {
    const params = {
      'fkregionId.equals': value,
      page: 0,
      size: 1000,
      sort: 'orderNum'
    };
    console.log('searchRegion:', this.searchRegion);
    console.log('value:', value);
    if (value === '-1') {
      delete params['fkregionId.equals'];
    }
    this.provinceService.query(params).subscribe(res => {
      if (res && res.body) {
        this.provincesList = [{ id: '-1', provinceName: '全部' }];
        this.provincesList.push(...res.body);
      }
    });
  }

  /**
   * 获取所有大赛
   */
  getBimInfo() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
      'isDelete.equals': 0
    };
    this.bimInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.bimInfoList = [{ id: '-1', name: '全部赛事' }];
        this.bimInfoList.push(...res.body);
      }
    });
  }

  /**
   * 根据赛事ID获取赛项
   */
  getBimInfoEvents(value) {
    if (value === '-1') {
      this.searchBimInfoEvents = '';
      this.searchBimInfoItems = null;
      this.bimInfoEventsList = [];
      this.oriBimInfoEvent = [];
      return;
    }

    // const tmpList = this.bimInfoList.filter(d => d.id === value);
    // this.curBimInfoName = tmpList[0].name;
    const params = {
      'bimInfoId.equals': value,
      'eventsType.equals': '0',
      'isDelete.equals': 0,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.loading = true;
    this.isQueryEvent = true;
    this.bimInfoEventsService.query(params).subscribe(res => {
      this.isQueryEvent = false;
      if (res.ok && res.body.length > 0) {
        this.bimInfoEventsList = res.body;
        this.curBimEventId = this.bimInfoEventsList[0].id;
        this.searchBimInfoEvents = this.curBimEventId;
        this.searchBimInfoItems = null;
        this.loadItems();
        this.getList();
      } else {
        this.loading = false;
        this.bimInfoEventsList = [];
        this.curBimEventId = '-1';
        this.searchBimInfoEvents = '-1';
        this.searchBimInfoItems = '-1';
        this.list = [];
        this.page = 0;
        this.size = 20;
        this.total = 0;
      }
    });
  }

  loadItems() {
    this.items = [];
    this.bimItemsService.getAllBimItemsInBimEvents(this.curBimEventId, {
      'isDelete.equals': 0,
      'sort': ['type,asc', 'orderSn,asc']
    }).subscribe((res: any) => {
      const data = res.body;
      data.forEach(e => e['percent'] = 0);
      this.items = data;
    });
  }

  rest() {
    this.searchTeamName = '';
    this.searchSchoolName = '';
    this.searchRegion = '-1';
    this.searchProvinces = '-1';
    this.searchBimInfo = '-1';
    this.searchBimInfoEvents = '';
    this.searchBimInfoItems = null;
    this.searchWorkTop = -1;
    this.getProvinces('-1');
    this.bimInfoEventsList = [];
    this.page = 0;
    this.getBimInfoEvents(this.currentBimInfo.id);
  }

  getList(pageRest?: boolean) {
    if (pageRest) {
      this.page = 0;
    }
    const params = {
      'isReply.equals': '1',
      page: this.page,
      size: this.size,
      'workType.equals': 1,
      sort: ['itemId', 'secCore,desc'],
    };
    if (this.searchTeamName && this.searchTeamName.length > 0) {
      params['teamName.contains'] = this.searchTeamName;
    }
    if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      params['schoolName.contains'] = this.searchSchoolName;
    }
    if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
      params['eventsId.equals'] = this.searchBimInfoEvents;
    } else {
      params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
    }
    if (this.searchBimInfoItems && this.searchBimInfoItems !== '-1') {
      params['itemId.equals'] = this.searchBimInfoItems;
    }
    if (this.isQueryEvent) {
      this.msg.warning('请等待参赛项目查询完成');
      return;
    }
    this.loading = true;
    this.workManageService.getBimWork(params).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        this.list = res.body;
        if (this.list.length === 0 && this.page > 0) {
          this.page --;
          this.getList();
        }
        // tslint:disable-next-line:radix
        this.total = parseInt(res.headers.get('X-Total-Count'));
        this.list.forEach(item => {
          item.checked = false;
        });
      }
    }, error1 => {
      this.loading = false;
      console.error(error1.message);
      MessageHelper.error('获取数据错误', error1.status);
    });
  }

  refreshStatus(): void {
    const allChecked = this.list.every(value => value.checked === true);
    const allUnChecked = this.list.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.list.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  viewTeamUsers(data: BimWorks) {
    console.log('data:', data);
    this.modal.static(ShowTeamUsersComponent, { teamId: data.teamId }, 800, {}).subscribe(() => { });
  }

  viewScores(data: BimWorks) {
    this.modal.static(ScoreDetailsComponent, { workId: data.id }, 800, {}).subscribe(() => { });
  }

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    this.getBimInfoEvents(this.currentBimInfo.id);
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getList();
  }

  remove() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.workManageService.updateWorkReplyType({
          replyType: '0',
          bimInfoEventsId: this.curBimEventId,
          uptWorkId: this.selectedRows.map(e => e.id)
        }).subscribe(res => {
          this.msg.success('删除成功');
          this.getList();
        });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

  distribute() {
    if (isNullOrUndefined(this.curBimEventId) || this.curBimEventId === '-1') {
      this.msg.info('当前赛事没有团队赛项，不能添加数据!');
      return false;
    }
    this.modal.static(WorkReplyDistributeComponent, {
      isAdd: true,
      bimInfoEventsId: this.curBimEventId
    }, 400).subscribe((res) => {
      if (res === 'success') {
        this.getList();
      }
    });
  }

  /**
  * 新增页面
  */
  add() {
    this.modal.static(WorkReplyAddComponent, {
      bimEventId: this.curBimEventId,
    }, 1003).subscribe((res) => {
      if (res !== null && res.length > 0) {
        this.workManageService.updateWorkReplyType({
          replyType: '1',
          uptWorkId: res
        }).subscribe(() => {
          this.msg.success('保存成功');
          this.getList();
        });
      }
    });
  }

  workAdvisor(data) {
    this.modal.static(BimWorkAdvisorComponent, {
      bimInfoName: data.bimInfoName,
      bimInfoEventName: data.eventsName,
      schoolName: data.schoolName,
      teamName: data.teamName,
      itemName: data.itemName,
      workName: data.name,
      bimEventId: data.eventsId,
      workId: data.id,
      isReply: '1'
    }, 1200).subscribe((res) => {
        this.getList();
    });
  }

  eventChange() {
    this.curBimEventId = this.searchBimInfoEvents;
    this.loadItems();
  }

  replyWorkExport() {
    const param = {
      'teamName.contains': this.searchTeamName,
      'schoolName.contains': this.searchSchoolName,
      'eventsId.equals': this.searchBimInfoEvents,
      'itemId.equals': this.searchBimInfoItems
    };
    const expParam = {};
    for (const key in param) {
      if (!isNullOrUndefined(param[key]) && param[key] !== '') {
        expParam[key] = param[key].trim();
      }
    }
    this.exportReplyWork = true;
    this.workManageService.exportReplyWorkData(expParam).subscribe((data: HttpResponse<any>) => {
      this.exportReplyWork = false;
      const blob = new Blob([data.body]);
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', objectUrl);
      a.setAttribute('download', '全国答辩作品信息' + '.xlsx');
      a.click();
      URL.revokeObjectURL(objectUrl);
    },
      error => {
        console.error('StatementDailyTemperatureComponent list Error ->', error);
      },
    );
  }
}
