import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { DA_SERVICE_TOKEN, ITokenService } from "@delon/auth";
import { CacheService } from "@delon/cache";
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { GuidUtil } from "@shared/utils/guid.util";
import { NzMessageService, NzModalRef } from "ng-zorro-antd";
import { zip } from "rxjs";
import { BimInfoEventsService } from "../../bim-info/bim-info-events/bim-info-events.service";
import { BimInfoService } from "../../bim-info/bim-info.service";
import { BimTeamsService } from "../../bim-info/bim-teams/bim-teams.service";
import { BimSchoolsService } from "../../school/bim-schools.service";
import { WorkManageService } from "../../work-manage/work-manage.service";

@Component({
  selector: 'app-bim-work-add',
  templateUrl: './bim-work-add.component.html',
  styleUrls: []
})
export class BimWorkAddComponent implements OnInit {

  @ViewChild('projectUpload1') projectUpload1: any;
  @ViewChild('projectUpload2') projectUpload2: any;
  fileFk1 = GuidUtil.getNewGUIDString();
  fileFk2 = GuidUtil.getNewGUIDString();

  teamInfo: any = {name: '',schoolTop: '',schoolId: ''};
  items = [];
  loading = false;
  isView1 = false;
  isView2 = false;
  // type = 0;

  bimInfoId = '';
  bimInfoList : any = [{id: '', name: ''}];
  infoEventsId = '';
  infoEventsList: any = [{id: '', name: ''}];
  schoolId = '';
  schoolList: any = [{id: '', name: ''}];
  teamId = '';
  teamList: any = [{id: '', name: ''}];

  bimInfo: any;

  public workDocLoadParam = {
    'extField1.equals': 'team_works_word'
  };
  public workDocExtParam = {
    'extField1': 'team_works_word'
  };

  public workPptLoadParam = {
    'extField1.equals': 'team_works_ppt'
  };
  public workPptExtParam = {
    'extField1': 'team_works_ppt'
  };
  public workPptFileType = ['.ppt','.pptx','.PPT','.PPTX'];

  public workImageLoadParam = {
    'extField1.equals': 'team_works_image'
  };
  public workImageExtParam = {
    'extField1': 'team_works_image'
  };
  public workImageFileType = ['.bmp','.jpg','.jpeg','.png','.gif','.BMP','.JPG','.JPEG','.PNG','.GIF'];

  public workVideoLoadParam = {
    'extField1.equals': 'team_works_video'
  };
  public workkVideoExtParam = {
    'extField1': 'team_works_video'
  };
  public workVideoFileType = ['.mp4','.MP4'];

  isEdit: false;
  workInfo: any;

  constructor(
    @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService,
    private bimSchoolsService: BimSchoolsService,
    private thsadminFileService: ThsadminFileService,
    private msg: NzMessageService,
    private workManageService: WorkManageService,
    private nzModalRef: NzModalRef,
    private bimInfoService: BimInfoService,
    private bimInfoEventsService: BimInfoEventsService,
    private bimTeamsService: BimTeamsService,
  ) { }

  ngOnInit() {
    console.log(this.workInfo);
    this.getBimInfoEvents(this.bimInfo.id);
  }

  /**
   * 获取所有大赛
   */
  getAllBimInfo() {
    const params = {
      'status.equals': 1,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.bimInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.bimInfoList.push(...res.body);
        // if (this.bimInfoList && this.bimInfoList.length > 0) {
        //   this.bimInfoId = this.bimInfoList[0].id;
        // }
      }
    })
  }

  /**
   * 根据赛事ID获取赛项
   */
  getBimInfoEvents(value) {
    this.infoEventsId = '';
    this.infoEventsList = [];
    this.items = [];
    this.schoolId = '';
    this.schoolList = [];
    this.teamId = '';
    this.teamList = [];
    const params = {
      'bimInfoId.equals': value,
      'eventsType.equals': 0,
      'isUplaodWork.equals': 1,
      'isDelete.equals': 0,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.bimInfoEventsService.query(params).subscribe(res => {
      this.getAllSchool(value);
      if (res && res.body) {
        this.infoEventsList.push(...res.body);
        if (this.infoEventsList && this.infoEventsList.length > 0) {
          if (this.isEdit) {
            this.infoEventsId = this.workInfo.eventsId;
          } else {
            this.infoEventsId = this.infoEventsList[0].id;
          }
        }
      }
    })
  }

  getAllSchool(value) {
    if (value && value.length > 0) {
      this.schoolId = '';
      this.schoolList = [];
      this.teamId = '';
      this.teamList = [];
      const params = {
        'bimInfoId.equals': value,
      };
      this.bimSchoolsService.queryParticipatingSchools(params).subscribe(res => {
        if (res && res.body) {
          this.schoolList = res.body;
          if (this.schoolList && this.schoolList.length > 0) {
            if (this.isEdit) {
              this.schoolId = this.workInfo.schoolId;
            } else {
              this.schoolId = this.schoolList[0].id;
            }
            this.getTeam(this.infoEventsId,this.schoolId);
          }
        }
      })
    }
  }

  getTeam(eventsId,schoolId) {
    if (eventsId && eventsId.length > 0 && schoolId && schoolId.length > 0) {
      this.schoolId = schoolId;
      this.items = [];
      this.teamId = '';
      this.teamList = [];
      const params = {
        'bimInfoEventsId.equals': eventsId,
        'schoolId.equals': schoolId,
        page: 0,
        size: 1000
      };
      this.bimTeamsService.query(params).subscribe(res => {
        if (res && res.body) {
          this.teamList = res.body;
          if (this.teamList && this.teamList.length > 0) {
            if (this.isEdit) {
              this.teamId = this.workInfo.teamId;
            } else {
              this.teamId = this.teamList[0].id;
            }
            this.getBimInfoByTeamId(this.teamId);
          }
        }
      })
    }
  }


  onClose() {
    this.nzModalRef.close();
  }

  getBimInfoByTeamId(value) {
    if (value && value.length > 0) {
      this.items = [];
      this.bimSchoolsService.getBimTeamById(value).subscribe(res => {
        if (res && res.body) {
          this.teamInfo = res.body;
          this.teamId = this.teamInfo.id;
          // this.items = res.teamItems;
          this.items = [];
          // 20230330 如果团队已晋级，无论有没有第一阶段作品，第二阶段所有赛项都可上传作品
          res.body.teamItems.forEach(item => {
            if (this.isEdit) {
              if (item.itemId === this.workInfo.itemId) {
                this.items.push(item);
              }
            } else {
              // if (item.schoolTop && item.schoolTop === 1)  {
              this.items.push(item);
              // }
            }
          });
        }
      })
    }
  }
  
  submitForm() {
    // 1启用 2启用
    // 1启用 2不启用
    // 1不启用 2不启用
    // 1不启用 2启用
    if (this.projectUpload1) {
      this.projectUpload1.submitForm();
      if (this.projectUpload1.check) {
        if (this.projectUpload2) {
          this.projectUpload2.submitForm();
          if (this.projectUpload2 && this.projectUpload2.check) {
            if (this.items && this.items.length === 2) {
              const works1 = this.projectUpload1.works;
              const works2 = this.projectUpload2.works;
              this.loading = true;
              if (works1 && works1.id && works2 && works2.id) {
                zip(
                  this.workManageService.update(works1),
                  this.workManageService.update(works2)
                ).subscribe(() => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  this.nzModalRef.close();
                })
              } else if (works1 && works1.id && works2 && !works2.id) {
                zip(
                  this.workManageService.update(works1),
                  this.workManageService.create(works2)
                ).subscribe(([res1,res2]) => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  if (res2 && res2.body) {
                    const data = {
                      oldFk: this.fileFk2,
                      newFk: res2.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe();
                    this.nzModalRef.close();
                  }
                })
              } else if (works1 && !works1.id && works2 && works2.id) {
                zip(
                  this.workManageService.create(works1),
                  this.workManageService.update(works2)
                ).subscribe(([res1,res2]) => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  if (res1 && res1.body) {
                    const data = {
                      oldFk: this.fileFk1,
                      newFk: res1.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe()
                  }
                  this.nzModalRef.close();
                })
              } else {
                zip(
                  this.workManageService.create(works1),
                  this.workManageService.create(works2)
                ).subscribe(([res1,res2]) => {
                  this.loading = false;
                  this.msg.success('提交成功！');
                  console.log(res1,res2);
                  if (res1 && res1.body) {
                    const data = {
                      oldFk: this.fileFk1,
                      newFk: res1.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe();
                  }
                  if (res2 && res2.body) {
                    const data = {
                      oldFk: this.fileFk2,
                      newFk: res2.body.id,
                    };
                    this.thsadminFileService.updateFileFkBatch(data).subscribe()
                  }
                  this.nzModalRef.close();
                });
              }
            }
          }
        }
        if (this.items && this.items.length === 1) {
          this.submitWork1(true);
        }
      }
    }
  }

  submitWork1(hasCheck?: boolean) {
    if (hasCheck || this.projectUpload1) {
      this.projectUpload1.submitForm();
      if (hasCheck || this.projectUpload1.check) {
        const works1 = this.projectUpload1.works;
        this.loading = true;
        if (works1 && works1.id) {
          this.workManageService.update(works1).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
            if (hasCheck) {
              this.nzModalRef.close();
            }
          });
        } else if (works1 && !works1.id) {
          works1.workTop = 0;
          this.workManageService.create(works1).subscribe(res => {
            if (res && res.body) {
              this.projectUpload1.works.id = res.body.id;
              const data = {
                oldFk: this.fileFk1,
                newFk: res.body.id,
              };
              this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                this.loading = false;
                this.msg.success('提交成功！');
                if (hasCheck) {
                  this.nzModalRef.close();
                }
              });
            }
          });
        }
      }
    }
  }

  submitWork2() {
    if (this.projectUpload2) {
      this.projectUpload2.submitForm();
      if (this.projectUpload2.check) {
        const works2 = this.projectUpload2.works;
        this.loading = true;
        if (works2 && works2.id) {
          this.workManageService.update(works2).subscribe(() => {
            this.loading = false;
            this.msg.success('提交成功！');
          });
        } else if (works2 && !works2.id) {
          works2.workTop = 0;
          this.workManageService.create(works2).subscribe(res => {
            if (res && res.body) {
              this.projectUpload2.works.id = res.body.id;
              const data = {
                oldFk: this.fileFk2,
                newFk: res.body.id,
              };
              this.thsadminFileService.updateFileFkBatch(data).subscribe(() => {
                this.loading = false;
                this.msg.success('提交成功！');
              });
            }
          });
        }
      }
    }
  }

}