import { Injectable } from "@angular/core";
import * as JsEncryptModule from 'jsencrypt';
import { HttpAddressService } from "@shared/session/http-address.service";

@Injectable({ providedIn: 'root' })
export class RASService {
    private httpAddressService: HttpAddressService;

    constructor(
        httpAddressService: HttpAddressService
    ) {
        this.httpAddressService = httpAddressService;
    }


    public encodeByPublicKey(str: string): string {
        const publicKey = this.httpAddressService.TransferPublicKey;
        var encrypt = new JsEncryptModule.JSEncrypt();
        encrypt.setPublicKey(publicKey);
        var encryptData = encrypt.encrypt(str);
        if (encryptData) {
            encryptData = encryptData.replace(" ", "");
        }
        return encryptData;
    }
}
