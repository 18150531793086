import { Component } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimInfoEventsService } from './bim-info-events.service';
import { BimInfoEvents } from './bim-info-events.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimInfoEventsEditComponent} from './edit/edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { BimInfoEventsMutexComponent } from './bim-info-events-mutex/bim-info-events-mutex.component';
@Component({
  selector: 'app-bim-bimInfo-events',
  templateUrl: './bim-info-events-list.component.html',
  styleUrls: ['./bim-info-events-list.component.less']
})
export class BimInfoEventsListComponent implements OnInit {
 
  public BimInfoId:String;
  public BimInfoName:String;
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimInfoEvents[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  public type = null;

  isloadding = false;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    // sort固定
     sort: ['createTime,desc']
  };


  columns: SimpleTableColumn[] = [];

  constructor(
    private routeInfo: ActivatedRoute,
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimInfoEventsService: BimInfoEventsService,
  ) {
    this.routeInfo.queryParams.subscribe(parms => {
      if (parms.bimInfoId) {
         this.BimInfoId = parms.bimInfoId;
         this.getDataList();
      }

      if(parms.bimInfoName){
        this.BimInfoName = parms.bimInfoName;
      }
      if(parms.type){
        this.type = parms.type;
        this.columns = [

          { title: '', index: 'id', type: 'checkbox' },
          { title: '赛项名称', index: 'name' , width: '8%'},
          
          {
            title: '赛项分类', index: 'eventsType', className: 'text-center', width: '7%',
            format: (item: any) => {
              if (item.eventsType === 1) {
                return '个人赛';
              }  
              else{
                return '团队赛';
              }
            },
            filters: [
              { text: '团队赛', value: 0 } ,
              { text: '个人赛', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
          {
            title: '是否需要上传作品', index: 'isUplaodWork', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.isUplaodWork === 1) {
                return '是';
              }  
              else{
                return '否';
              }
            },
            filters: [
              { text: '否', value: 0 } ,
              { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
      
          {
            title: '是否需要上传回执', index: 'isUploadReceipt', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.isUploadReceipt === 1) {
                return '是';
              }  
              else{
                return '否';
              }
            },
            filters: [
              { text: '否', value: 0 } ,
              { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
      
          {
            title: '校内作品上传状态', index: 'schoolWorkUploadStatus', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.schoolWorkUploadStatus === 1) {
                return '开始';
              } else if (item.schoolWorkUploadStatus === 2) {
                return '结束';
              }   
              else{
                return '未开始';
              }
            },
            filters: [
              { text: '未开始', value: 0 } ,
              { text: '开始', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              { text: '结束', value: 2 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
          {
            title: '校内作品评分状态', index: 'schoolWorkScoreStatus', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.schoolWorkScoreStatus === 1) {
                return '开始';
              } else if (item.schoolWorkScoreStatus === 2) {
                return '结束';
              }   
              else{
                return '未开始';
              }
            },
            filters: [
              { text: '未开始', value: 0 } ,
              { text: '开始', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              { text: '结束', value: 2 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
          {
            title: '全国评委评审作品上传状态', index: 'advisorsWorkUploadStatus', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.advisorsWorkUploadStatus === 1) {
                return '开始';
              } else if (item.advisorsWorkUploadStatus === 2) {
                return '结束';
              }   
              else{
                return '未开始';
              }
            },
            filters: [
              { text: '未开始', value: 0 } ,
              { text: '开始', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              { text: '结束', value: 2 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
          {
            title: '全国评委评审作品评分状态', index: 'advisorsWorkScoreStatus', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.advisorsWorkScoreStatus === 1) {
                return '开始';
              } else if (item.advisorsWorkScoreStatus === 2) {
                return '结束';
              }   
              else{
                return '未开始';
              }
            },
            filters: [
              { text: '未开始', value: 0 } ,
              { text: '开始', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              { text: '结束', value: 2 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
          {
            title: '全国答辩材料上传状态', index: 'replyWorkUploadStatus', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.replyWorkUploadStatus === 1) {
                return '开始';
              } else if (item.replyWorkUploadStatus === 2) {
                return '结束';
              }   
              else{
                return '未开始';
              }
            },
            filters: [
              { text: '未开始', value: 0 } ,
              { text: '开始', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              { text: '结束', value: 2 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
          {
            title: '回执提交状态', index: 'receiptStatus', className: 'text-center', width: '8%',
            format: (item: any) => {
              if (item.receiptStatus === 1) {
                return '开始';
              } else if (item.receiptStatus === 2) {
                return '结束';
              }   
              else{
                return '未开始';
              }
            },
            filters: [
              { text: '未开始', value: 0 } ,
              { text: '开始', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              { text: '结束', value: 2 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
      
          { title: this.type === 'enterprise' ? '队员数量限制' : '晋级数量限制', index: 'maxTopCount' , width: '5%'},
      
          {
            title: '是否允许修改获奖信息', index: 'isUpdatePrizeInfo', className: 'text-center', width: '5%',
            format: (item: any) => {
              if (item.isUpdatePrizeInfo === 1) {
                return '是';
              }  
              else{
                return '否';
              }
            },
            filters: [
              { text: '否', value: 0 } ,
              { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
              
            ],
            filtered: true,
            filterMultiple: false,
            filter: () => true
          },
      
      
      
          {
            title: '操作', className: 'text-center',
            buttons: [
               { text: '编辑', click: (item: any) => {this.edit(item); } },
               { text: '删除', click: (item: any) => { this.remove(item); } },
               // tslint:disable-next-line:max-line-length
               { text: '互斥专项配置', click: (item: any) => {this.mutex(item); }, iif: (item, btn, col) => (item.eventsType === 0 || item.eventsType === '0') },
            ],
          }
        
        ];
      }
    });
  }

  ngOnInit() {
   
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['bimInfoId.contains'] = this.BimInfoId;
    copyParams['isDelete.equals'] = 0;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.bimInfoEventsService.query(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(BimInfoEventsEditComponent, { record: { id: null},isAdd: true ,bimInfoId:this.BimInfoId, type: this.type }, 1000)
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  edit(record) {
    this.modal
      .static(BimInfoEventsEditComponent, { record:record,isAdd: false ,bimInfoId:this.BimInfoId, type: this.type },1000)
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }




  /**
  * 删除
  */
  remove(res: any) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        res.isDelete = 1;
        this.bimInfoEventsService.update(res).subscribe(() => {
          this.getDataList();
          this.st.clearCheck();
        });
        // this.bimInfoEventsService.delete(res.id)
        //   .subscribe(() => {
        //     this.getDataList();
        //     this.st.clearCheck();
        //   });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimInfoEventsService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }

  searche(){
    this.page = 0;
    this.getDataList();
  }

  mutex(record) {
    this.modal.static(BimInfoEventsMutexComponent, { record: record, type: this.type }, 1000).subscribe(() => {
        this.getDataList();
      });
  }

}
