import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-fund-pay-view',
  templateUrl: './view.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less', './view.component.less']
})
export class ProjectFundPayViewComponent implements OnInit {

  public projectId = null;
  public prjType = null;

  constructor(
    private routeInfo: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.projectId = this.routeInfo.snapshot.queryParams['id'];
    this.prjType = this.routeInfo.snapshot.queryParams['prjType'];
  }
}
