import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { SoftwareAuth } from './auth.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SoftwareAuthService extends ThsBaseService<SoftwareAuth> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
    super(http, httpAddressService.EduServe + `/edu-software-sns`);
  }
        /** GET: 分页、多条件查询记录列表 */
    queryList (queryParams ?: any): Observable<HttpResponse<SoftwareAuth[]>> {
        return this.http.get<SoftwareAuth[]>(this.resourceUrl + '/list', {params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<SoftwareAuth[]>) => this.convertDateArrayFromServer(res)));
    }
}