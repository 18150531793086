import { Component } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OnInit, ViewChild } from '@angular/core';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { EduCertExamineResultManEditComponent } from './edit/edit.component';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { CertExamineResultManService } from './certExamineResultMan.service';
import { CertExamineResult } from './certExamineResult.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { CertResultImportComponent } from './resultImportMan/certExamineResultImport.component';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-edu-certExamineResult',
  templateUrl: './certExamineResultMan.component.html',
})
export class EertExamineResultManComponent implements OnInit {
  public page = 0;
  public size = 10;
  public total: number;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: CertExamineResult[] = [];

  public totalCallNo = 0;
  public selectedRows: SimpleTableData[] = [];

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name': '',
    'id_card': '',
    'edu_cert_channel_id': '',
    'exam_batch': '',
    'examine_subject': '',
    'auth_product': '',
    'auth_grade': '',
  };

  certChannel = [];

  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '学员姓名', index: 'name' , width: '7%'},
    { title: '身份证号', index: 'id_card' , width: '10%'},
    { title: '认证类型', index: 'edu_cert_channel_name' , width: '10%'},
    { title: '考试批次', index: 'exam_batch' , width: '10%'},
    { title: '考试科目', index: 'examine_subject' , width: '10%'},
    { title: '认证产品', index: 'auth_product' , width: '10%'},
    { title: '认证等级', index: 'auth_grade' , width: '8%'},
    {
      title: '考试类型', index: 'examine_type', className: 'text-center', width: '8%',
      format: (item: any) => {
        if (item.examine_type === '1') {
          return '考试';
        } else {
          return '补考';
        }
      },
      filters: [
        { text: '考试', value: 1 },
        { text: '补考', value: 2 }
      ]
    },
    { title: '考试时间', index: 'examine_time' , width: '8%'},
    {
      title: '是否通过考试', index: 'is_pass', className: 'text-center', width: '8%',
      format: (item: any) => {
        if (item.is_pass === '1') {
          return '是';
        } else if (item.is_pass === '0') {
          return '否';
        }
      },
      filters: [
        { text: '是', value: 1 }, // 此处值对应数据库的值，即domain对象的值
        { text: '否', value: 0 }  // 此处值对应数据库的值，即domain对象的值
      ],
    },
    {
      title: '操作', className: 'text-center',
      buttons: [
        { text: '编辑', type: 'modal', component: EduCertExamineResultManEditComponent,
         paramName: 'record', click: () => this.getDataList(), },
       /*  { text: '删除', acl: APP_PERMISSIONS.SYS_USER_DELETE, click: (item: any) => { this.remove(item); } } */
      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public certExamineResultManageService: CertExamineResultManService
  ) {}

  ngOnInit() {
    this.getDataList();
    this.getCertChannel();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      // q['sort'] = ['orderNum,asc'];
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    this.certExamineResultManageService.queryExamineResult(copyParams)
      .subscribe((res: any) => {
      console.log(res.body.data);
      this.list = res.body.data;
      this.total = res.headers.get('X-Total-Count');
      });
  }


  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(EduCertExamineResultManEditComponent, { record: { id: null } })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }


  /**
  * 编辑页面
  * @returns {NzMessageDataFilled}
  */
  edit() {
    if (this.selectedRows.length > 1) return this.msg.warning('只能选择一条数据再编辑！');
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择一条数据再编辑！');
    const tmp = this.formatIsOk(this.selectedRows[0]);
    this.modal
      .static(EduCertExamineResultManEditComponent, { record: tmp })
      //     .pipe(filter(w => w === true))
      .subscribe(() => this.getDataList());
  }

  formatIsOk (item: any) {
    if (item.isOk && item.isOk === '1') {
      item.isOk = true;
    } else if (item.isOk && item.isOk === '0') {
      item.isOk = false;
    }
    return item;
  }

  /**
  * 删除
  */
  remove(res: any) {
    if (res.userName === 'admin') {
      this.msg.info('管理员账号不能删除');
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.certExamineResultManageService.delete(res.result_id)
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }



  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }


  searchData() {
    this.page = 0;
    this.size = 10;
    this.getDataList();
  }

  getCertChannel() {
    this.certExamineResultManageService.findAllChannel().subscribe(res => {
      if (res.body && res.body.length) {
          this.certChannel = res.body;
         // console.log(this.dictDetail);
      }
    });
  }

  resultImport() {
    this.modal
    .static(CertResultImportComponent, { record: { id: null } })
    //      .pipe(filter(w => w === true))
    .subscribe(() => {
      this.getDataList();
    });
  }
}
