import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ReuseTabService } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { NicheEditComponent } from 'app/routes/business/iucce/component/niche-edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-project-closing-audit-view',
  templateUrl: './view.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less','./view.component.less']
})
export class ProjectClosingAuditViewComponent implements OnInit {

  validateForm: FormGroup;
  dataSet = [];
  dataSet1 = [{}];
  dataSet3 = [{}];
  dataSet2 = [{
    id:1,
    year: '2023',
    month: '3',
    nicheInfo: '商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况商机跟进情况',
    completionRate: '100%',
    completionInfo: 1,
    orderStatus:1,
    time: '2023-01-20',
    amount: 9600000,
    orderNumber: 'Y12345678910112',
  }];
  countRemark = 0;
  opportunitys = [];
  countRemark2 = 0;

  validateForm2: FormGroup;
  fileList = [
    {
      uid: 1,
      name: 'xxx.png',
      status: 'done',
      response: 'Server Error 500', // custom error message to show
      url: 'http://www.baidu.com/xxx.png'
    },
    {
      uid: 2,
      name: 'yyy.png',
      status: 'done',
      url: 'http://www.baidu.com/yyy.png'
    },
    {
      uid: 3,
      name: 'zzz.png',
      status: 'error',
      response: 'Server Error 500', // custom error message to show
      url: 'http://www.baidu.com/zzz.png'
    }
  ];
  showUploadListSwitch1 = true;
  showUploadListSwitch2 = true;
  showUploadListSwitch3 = true;

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private reuseTabService: ReuseTabService,
    private modal: ModalHelper,
  ) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [{value:null,disabled:true}, [Validators.required]],
      email2: [{value:null,disabled:true}, [Validators.required]],
      email3: [{value:null,disabled:true}, [Validators.required]],
      password: [{value:null,disabled:true}, [Validators.required]],
      nickname: [{value:null,disabled:true}, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [{value:null,disabled:true}, [Validators.required]],
      website: [{value:null,disabled:true}, [Validators.required]],
      captcha: [{value:null,disabled:true}, [Validators.required]],
      agree: [false]
    });
    this.validateForm2 = this.fb.group({
      email: [{value:null,disabled:true}, [Validators.required]],
      email1: [{value:null,disabled:true}, [Validators.required]],
      email2: [{value:null,disabled:true}, [Validators.required]],
      email3: [{value:null,disabled:true}, [Validators.required]],
      password: [{value:null,disabled:true}, [Validators.required]],
      nickname: [{value:null,disabled:true}, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [{value:null,disabled:true}, [Validators.required]],
      website: [{value:null,disabled:true}, [Validators.required]],
      captcha: [{value:null,disabled:true}, [Validators.required]],
      agree: [false]
    });
    this.addFormControl();
    this.addOpportunity();
  }

   addFormControl(i = 0) {
    // const newDataSet = [...this.dataSet];
    // newDataSet.splice(i, 0, { id: controlId, controlSort: controlId })
    this.dataSet = [
      ...this.dataSet,
      {
        id: this.countRemark
      }
    ];
    this.dataSet.forEach(item => {
      this.validateForm.addControl('followPerson' + item.id, new FormControl(null, Validators.required));
      this.validateForm.addControl('followNumber' + item.id, new FormControl(null, Validators.required));
      this.validateForm.addControl('followRemark' + item.id, new FormControl(null));
    })
    this.countRemark++;
  }

  removeFormControl(rid: number) {
    this.dataSet = this.dataSet.filter(item => {
      return item.id !== rid
    })
    this.validateForm.removeControl('followPerson' + rid);
    this.validateForm.removeControl('followNumber' + rid);
    this.validateForm.removeControl('followRemark' + rid);
  }


  addOpportunity() {
    this.opportunitys = [
      ...this.opportunitys,
      {
        id: this.countRemark2
      }
    ];
    this.validateForm.addControl('opportunityTime' + this.countRemark2, new FormControl(null, Validators.required));
    this.validateForm.addControl('opportunityMoney' + this.countRemark2, new FormControl(null, Validators.required));
    this.validateForm.addControl('opportunityDetail' + this.countRemark2, new FormControl(null, Validators.required));
    this.validateForm.addControl('opportunityRemark' + this.countRemark2, new FormControl(null));
    this.countRemark2++;
  }

  removeOpportunity(rid: number) {
    this.opportunitys = this.opportunitys.filter(item => {
      return item.id !== rid
    })
    this.validateForm.removeControl('opportunityTime' + rid);
    this.validateForm.removeControl('opportunityMoney' + rid);
    this.validateForm.removeControl('opportunityDetail' + rid);
    this.validateForm.removeControl('opportunityRemark' + rid);
  }

  //查看
  viewNiche(data) {
    this.modal.static(NicheEditComponent, {
      pageType: 'view',
      record:data
    }, 1080).subscribe();
  }

  submitForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    console.log(this.validateForm.value);
  }

  return() {
    this.reuseTabService.replace('/business/iucce/projectClosingAudit');
  }

}
