import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-iucce-project-report-audit-view',
  templateUrl: './view.component.html',
  styleUrls: ['../../component/project-info/view/project-info-view.component.less','./view.component.less']
})
export class ProjectReportAuditViewComponent implements OnInit {

  validateForm: FormGroup;
  dataSet = [];
  countRemark = 0;
  opportunitys = [];
  countRemark2 = 0;

  validateForm2: FormGroup;
  fileList = [
    {
      uid: 1,
      name: 'xxx.png',
      status: 'done',
      response: 'Server Error 500', // custom error message to show
      url: 'http://www.baidu.com/xxx.png'
    },
    {
      uid: 2,
      name: 'yyy.png',
      status: 'done',
      url: 'http://www.baidu.com/yyy.png'
    },
    {
      uid: 3,
      name: 'zzz.png',
      status: 'error',
      response: 'Server Error 500', // custom error message to show
      url: 'http://www.baidu.com/zzz.png'
    }
  ];
  showUploadListSwitch1 = true;
  showUploadListSwitch2 = true;
  showUploadListSwitch3 = true;

  constructor
    (private fb: FormBuilder, private message: NzMessageService) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      email2: [null, [Validators.required]],
      email3: [null, [Validators.required]],
      password: [null, [Validators.required]],
      nickname: [null, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      website: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
      agree: [false]
    });
    this.validateForm2 = this.fb.group({
      email: [null, [Validators.required]],
      email2: [null, [Validators.required]],
      email3: [null, [Validators.required]],
      password: [null, [Validators.required]],
      nickname: [null, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      website: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
      agree: [false]
    });
    this.addFormControl();
    this.addOpportunity();
  }

  addFormControl(i = 0) {
    // const newDataSet = [...this.dataSet];
    // newDataSet.splice(i, 0, { id: controlId, controlSort: controlId })
    this.dataSet = [
      ...this.dataSet,
      {
        id: this.countRemark
      }
    ];
    this.dataSet.forEach(item => {
      this.validateForm.addControl('followPerson' + item.id, new FormControl(null, Validators.required));
      this.validateForm.addControl('followNumber' + item.id, new FormControl(null, Validators.required));
      this.validateForm.addControl('followRemark' + item.id, new FormControl(null));
    })
    this.countRemark++;
  }

  removeFormControl(rid: number) {
    this.dataSet = this.dataSet.filter(item => {
      return item.id !== rid
    })
    this.validateForm.removeControl('followPerson' + rid);
    this.validateForm.removeControl('followNumber' + rid);
    this.validateForm.removeControl('followRemark' + rid);
  }

  addOpportunity() {
    this.opportunitys = [
      ...this.opportunitys,
      {
        id: this.countRemark2
      }
    ];
    this.validateForm.addControl('opportunityTime' + this.countRemark2, new FormControl(null, Validators.required));
    this.validateForm.addControl('opportunityMoney' + this.countRemark2, new FormControl(null, Validators.required));
    this.validateForm.addControl('opportunityDetail' + this.countRemark2, new FormControl(null, Validators.required));
    this.validateForm.addControl('opportunityRemark' + this.countRemark2, new FormControl(null));
    this.countRemark2++;
  }

  removeOpportunity(rid: number) {
    this.opportunitys = this.opportunitys.filter(item => {
      return item.id !== rid
    })
    this.validateForm.removeControl('opportunityTime' + rid);
    this.validateForm.removeControl('opportunityMoney' + rid);
    this.validateForm.removeControl('opportunityDetail' + rid);
    this.validateForm.removeControl('opportunityRemark' + rid);
  }

  // showModal(): void {
  //   this.isVisible = true;
  // }

  // handleOk(): void {
  //   for (const i in this.validateForm.controls) {
  //     this.validateForm.controls[i].markAsDirty();
  //     this.validateForm.controls[i].updateValueAndValidity();
  //   }
  //   // this.isConfirmLoading = true;
  //   // setTimeout(() => {
  //   //   this.isVisible = false;
  //   //   this.isConfirmLoading = false;
  //   // }, 3000);
  // }

  // handleCancel(): void {
  //   this.isVisible = false;
  // }

  submitForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    console.log(this.validateForm.value);
  }

}
