import { Component, OnInit } from "@angular/core";
import { SimpleTableColumn } from "@delon/abc";
import { NzMessageService } from "ng-zorro-antd";
import { BimItemsService } from "../../bim-items/bim-items.service";
import { ModalHelper } from "@delon/theme";
import { AddGroupComponent } from "./add-group/add-group.component";

@Component({
    selector: 'app-group-num-list',
    templateUrl: './group-num-list.component.html',
    styleUrls: []
  })
  export class GroupNumListComponent implements OnInit {
    public page  = 0;
    public size  = 10;
    public total = '0';
    public list = [];

    queryParams = {
      // 查询搜索框的字段要预置
      'name.contains': '',
      // sort固定
    };
    
    bimInfoId: null;
    bimInfoEventsList: any = [];
    bimEventId = null;

  columns: SimpleTableColumn[] = [
    { title: '参赛专项', index: 'name' , width: '70%'},
    {
      title: '操作', className: 'text-center',
      buttons: [
        { text: '组别配置', click: (item: any) => {this.groupConfig(item); } },
      ],
    }
  ];

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private bimItemsService: BimItemsService,
  ) {
  }

    ngOnInit(): void {
      this.getDataList();
    }

    /**
    * 获取数据列表
    * @param {string} url
    */
     public getDataList(isReset?: boolean) {
      const copyParams = this.getParams(isReset);
      this.bimItemsService.getAllBimItemsInBimEvents(this.bimEventId, copyParams).subscribe((res: any) => {
        this.list = res.body;
        if (this.list.length === 0 && this.page > 0) {
          this.page--;
          this.getDataList();
        }
        this.total = res.headers.get('X-Total-Count');
      });
     }
  
     getParams(isReset?: boolean) {
       const copyParams = {};
       const q = this.queryParams;
       if (isReset === true) {
         this.page = 0;
         Object.keys(q).forEach(function (key) {
           if (key.indexOf('isOk') > -1) {
             q[key] = '';
           } else {
             q[key] = null;
           }
         });
       }
       Object.keys(q).forEach(function (key) {
         if (q[key] !== '' && q[key] !== null) {
           copyParams[key] = q[key];
         }
       });
       copyParams['isDelete.equals'] = 0;
       copyParams['page'] = this.page;
       copyParams['size'] = this.size;
       copyParams['sort'] = ['type,asc', 'orderSn,asc'];
       return copyParams;
     }
  
    /**
    * 页码数量变动事件
    * @param
    */
    paginationChange(event) {
      console.log(event);
      this.page = event.pi - 1;
      this.size = event.ps;
      this.getDataList();
    }
  
    /**
    * 页码数量变动事件
    * @param
    */
    paginationChangePage(event) {
      this.page = event - 1;
      this.getDataList();
    }
  
    /**
    * 页码数量变动事件
    * @param
    */
    paginationChangeSize(event) {
      this.size = event;
      this.getDataList();
    }
  
    eventChange() {
      this.getDataList();
    }

    groupConfig(item) {
      this.modal
        .static(AddGroupComponent, {
          bimInfoId: this.bimInfoId,
          bimEventId: this.bimEventId,
          itemId: item.id,
          itemName: item.name,
        })
        .subscribe(() => {
          this.getDataList();
        });
    }
    
  }