import { HttpClient, HttpRequest, HttpResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnChanges, AfterContentInit, Input, Output, EventEmitter, OnInit, SimpleChanges, Inject, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, UploadFile, UploadFilter, UploadXHRArgs } from 'ng-zorro-antd';
import { FileUploadService } from "./fileUpload.service";
import * as moment from 'moment';
import { environment } from '@env/environment';
import { GuidUtil } from '@shared/utils/guid.util';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { isNullOrUndefined } from 'util';
import { HttpAddressService } from '@shared/session/http-address.service';
import * as OSS from 'ali-oss';
import {Md5} from 'ts-md5';
import { WorkManageService } from 'app/routes/business/bim/work-manage/work-manage.service';
import { ThsadminFileService } from '../f-upload/thsadmin-file.service';

@Component({
  selector: 'ths-file-upload',
  templateUrl: './fileUpload.component.html',
  styles  : [
    `
      .editable-row-operations a {
        margin-right: 8px;
      }
      :host ::ng-deep .ant-table-title{
        margin-top: 10px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      :host ::ng-deep .ant-list-item-content-single{
        justify-content: space-between;
      }
      :host ::ng-depp .ant-alert-icon.anticon{
        top: 8px;
      }
    `
  ]
})
export class FileUploadComponent implements OnChanges, OnInit  {

  /**
   * 文件列表左上方的标题名
   */
  @Input() fileListTitle: String = "";
  /**
   * 允许上传的文件类型
   */
  @Input() acceptFileType: String = '';
  /**
   * 是否只读，已废弃
   */
  @Input() readOnly: boolean = true;
  /**
   * 是否可上传
   */
  @Input() uploadable: boolean = true;
  /**
   * 是否可修改
   */
  @Input() editable: boolean = true; 
  /**
   * 是否可删除
   */
  @Input() deleteable: boolean = true;
  /**
   * 是否仅本人编辑
   */
  @Input() selfOnly: boolean = false;
  /**
   * 是否允许修改文件名（仅table样式下有效）
   */
  @Input() changeFileName: boolean = false;
  /**
   * 组件样式 默认table
   */
  @Input() display: "table"|"list" = "table";
  /**
   * 业务外键，根据业务外键加载，上传文件，为空时无法进行上传（页面上会有提示）
   */
  @Input() fileFk;
  /**
   * 文件数量限制，达到数量上限后无法上传（页面上会有提示） 默认无限
   */
  @Input() fileCountLimit:number = -1;
  /**
   * 单个文件大小限制，超过大小限制则无法上传（选择文件后会有提示） 默认从后台读取配置
   */
  @Input() sizeLimit:number = -1;  
  /**
   * 第一列字段值（仅table模式有效）
   */
  @Input() firstColumn:string = "";
  /**
   * 第一列字段名（仅table模式有效）
   */
  @Input() firstColumnName:string = "";
  
  /**
   * 额外参数 加载文件时会作为参数进行加载，如有需要可参考sys_files表的字段进行添加
   */
  @Input() loadExtParams: {} = {};
  /**
   * 额外参数 上传文件时会作为参数进行保存，如有需要可参考sys_files表的字段进行添加
   */
  @Input() uploadExtParams: {} = {};
  /**
   * 单个文件上传完成的回调 {success:boolean(是否成功), file: file(对应文件属性)}
   */
  @Output() uploadFinish: EventEmitter<{}> = new EventEmitter<{}>();
  /**
   * 所有文件上传完成的回调 {successFile: file[](上传成功的文件), failedFile: file[](上传失败的文件)}
   */
  @Output() allUploadFinish: EventEmitter<{}> = new EventEmitter<{}>();

  /**
   * 是否开启批量上传
   */
  @Input() fileMultiple = false;

  /**
   * 是否免考认证证书上传
   */
  @Input() bimCertFile = false;

  @Input() uploadFileType = [];
  
  /**
   * 是否需要文件描述
   */
  @Input() isNeedFileDesc = false;

  /**
   * 是否图片预览
   */
  @Input() isPreviewImg = false;
  
  /**
   * 是否大文件上传
   */
  @Input() bigFileUpload = false;

  /**
   * 是否需上传封面
   */
  @Input() isNeedCoverImg = false;

  public filters: UploadFilter[] = [
    {
      name: 'type',
      fn  : (fileList: UploadFile[]) => {
        if (this.uploadFileType.length > 0) {
          const filterFiles = fileList.filter(w => {
            const fileSuffix = w.name.substring(w.name.lastIndexOf('.'));
            return this.uploadFileType.includes(fileSuffix);
          });
          if (filterFiles.length !== fileList.length) {
            this.msg.error(`包含文件格式不正确，只支持 ${this.uploadFileType.join('、 ')} 格式`);
            return filterFiles;
          }
        }
        return fileList;
      }
    }
  ];

  uploading = false;
  fileList: UploadFile[] = [];
  uploadLength = 0;
  i = 1;
  failedFileList: UploadFile[] = [];
  successedFileList: UploadFile[] = [];
  editIndex = -1;
  dataSet = [];
  guid = new GuidUtil().toString();
  accountName = '';
  personName = '';
  tempEditData = {};

  public bimCertFilePdf = 'BIM_CERT_FILE_PDF';
  public bimCertFileImg = 'BIM_CERT_FILE_IMG';
  previewAddress: string;
  
  public ossClient;
  public ossFileUploadId;
  public ossUploadAddress: string;
  // 展示上传进度条
  showPercent = false;
  // 上传百分比
  percent = 0;

  // 上传地址
  uploadAddress: string;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    private workManageService: WorkManageService,
    private thsadminFileService: ThsadminFileService,
    ) {
      this.previewAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/preview/';
      this.ossClient = new OSS(this.httpAddressService.ossServe);
      this.ossUploadAddress = httpAddressService.apiGateway + httpAddressService.FileServer + '/sys-files/uploadFileOSS';
      this.uploadAddress = '/thsadmin/api/sys-files/upload';
    }

  ngOnInit(): void {
    console.log(this.uploadFileType);
    this.accountName = this.tokenService.get().account_name;
    this.personName = this.tokenService.get().entity_name;
    if(this.sizeLimit == -1){
      //TODO 从后台读取配置 暂定为1GB
      this.sizeLimit = 10000000;
    }
    this.initFiles();
  }

  initFiles(){
    let _this_ = this;
    if(this.fileFk != undefined && this.fileFk != ''){
      let queryParams = Object.assign({
        'fileFk.equals': this.fileFk,
        'sort': 'createdDate,asc',
      }, this.loadExtParams);
      this.fileUploadService.query(queryParams).subscribe((res) => {
        this.dataSet = res.body;
        this.dataSet.forEach(ele => {
          if (this.isNeedCoverImg) {
            this.fileUploadService.query({
              'fileFk.equals': ele.id,
              'sort': 'createdDate,asc',
            }).subscribe(res => {
              const data = res.body;
              if (data && data.length > 0) {
                ele.videoCoverId = data[0].id;
                ele.videoCover = this.previewAddress + data[0].id;
              }
            });
          }
          ele.fileSizeForRead = this.fileUploadService.readablizeBytes(ele.fileSize);
          if (this.isPreviewImg) {
            ele['imgPreviewUrl'] = this.previewAddress + ele.id;
          }
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['fileFk'] && changes['fileFk'].currentValue){
      this.initFiles();
    }
  }


  startEdit(idx: number): void {
    this.tempEditData = Object.assign({}, this.dataSet[idx]);
    this.editIndex = idx;
  }

  cancelEdit(idx: number): void {
    this.editIndex = -1;
  }

  delEdit(idx: number): void {
    const delFile = this.dataSet[idx];
    console.log(delFile);
    const id = delFile.id;
    const uid = delFile.uid;
    if (id) {
      if (delFile.extField2 === 'AliOss') {
        this.thsadminFileService.deleteOSSFileById(delFile.id).subscribe(res => {
          this.dataSet = this.dataSet.filter(d => d.id !== id);
        });
      } else {
        this.fileUploadService.delete(id).subscribe(res => {
          this.dataSet = this.dataSet.filter(d => d.id !== id);
        });
      }
      if (this.isNeedCoverImg) {
        this.fileUploadService.deleteAllByFk(id).subscribe();
      }
      if (!isNullOrUndefined(delFile.extField5) && this.bimCertFile) {
        this.fileUploadService.delete(delFile.extField5).subscribe();
      }
      this.successedFileList = this.successedFileList.filter(e => e.uid != uid);
    } else {
      this.uploadLength --;
      this.dataSet = this.dataSet.filter(d => d.uid !== uid);
    }

    this.editIndex = -1;
  }

  saveEdit(idx: number): void {
    this.dataSet[idx] = Object.assign(this.dataSet[idx], this.tempEditData);
    this.editIndex = -1;
    const id = this.dataSet[idx].id;
    const uid = this.dataSet[idx].uid;
    if(id){
      let data = this.dataSet[idx];
      this.fileUploadService.update(data).subscribe(res => {
        this.dataSet[idx] = res.body;
        this.dataSet[idx].fileSizeForRead = this.fileUploadService.readablizeBytes(this.dataSet[idx].fileSize);
      });
    }
  }


  beforeUpload = (file: UploadFile): boolean => {
    if (this.uploadFileType.length > 0) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.'));
      if (!this.uploadFileType.includes(fileSuffix)) {
        this.msg.error('请上传后缀为 ' + this.uploadFileType.join(',') + ' 的文件');
        return false;
      }
    }
    const isLt1GB = file.size / 1024 / 1024 / 1024 < 1;
    if (!isLt1GB) {
      this.msg.error('文件大小不能超过1GB!');
      return false;
    }
    this.uploadLength ++;
    file.uploader = this.personName;
    if (!this.isNeedFileDesc) {
      file.fileDesc =file.name;
    }
    file.fileName = file.name;
    file.fileType = file.name.substring(file.name.indexOf('.')+1);
    file.fileSizeForRead = this.fileUploadService.readablizeBytes(file.size);
    this.dataSet = [ file, ...this.dataSet];
    this.cdr.detectChanges();
    return false;
  }


  downFile(idx){
    const file = this.dataSet[idx];
    
    let url =  environment.FILE_URL + '/thsadmin/api/sys-files/download/' + file.id;
    if (file.extField2 === 'AliOss') {
      console.log(file);
      if (isNullOrUndefined(file.url)) {
        const filename = file.fileName;
        const response = {
          'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
        };
        url = this.ossClient.signatureUrl(file.fileSavePath, { response });
      }
      console.log(file.url);
    }
    let a = document.createElement('a');
    let filename = file.fileName;
    a.href = url;
    a.download = filename;
    a.click();

    //TODO blob会限制大小 500MB左右
    // this.fileUploadService.downloadById(file.id).subscribe((res) => {
    //   let data=res.body;
    //   let blob = new Blob([data], {type: file.fileType});
    //   let objectUrl = URL.createObjectURL(blob);
    //   let a = document.createElement('a');
    //   a.setAttribute('style', 'display:none');
    //   a.setAttribute('href', objectUrl);
    //   a.setAttribute('download', file.fileName);
    //   a.click();
    //   // URL.revokeObjectURL(objectUrl);

    // });
  }
  fileChange(event){
    console.log(event);
  }

  handleUploadBefore() {
    if (this.bigFileUpload) {
      if (this.ossClient === null) {
        this.handleUpload();
        // this.handleUploadFile();
      } else {
        console.log(this.dataSet);
        this.dataSet.forEach((file: any) => {
          const item = {
            file: file,
            action: this.ossUploadAddress
          };
          this.customOSSFileReq(item);
        });
      }
    } else {
      this.handleUpload();
    }
  }

  handleUpload(): void {
    this.uploading = true;
    this.failedFileList = [];
    this.successedFileList = [];
    if (this.bimCertFile) {
      const uploadFileList = [];
      this.dataSet.forEach((file: any) => uploadFileList.push(file));
      const nextFile = uploadFileList.pop();
      if (!isNullOrUndefined(nextFile)) {
        this.uploadFile(nextFile, uploadFileList);
      }
    } else {
      this.dataSet.forEach((file: any) => {
        this.uploadFile(file);
      });
    }
  }

  uploadFile(file: any, uploadFileList?: any) {
    let uploadList = this.uploadLength;
    if(file.id != undefined && file.id != ''){
      return;
    }
    const formData = new FormData();
    console.log(this.uploadExtParams);
    if (this.bimCertFile) {
      const fileType = file.name.substring(file.name.indexOf('.') + 1);
      this.uploadExtParams = {
        extField1: fileType === 'pdf' ? this.bimCertFilePdf : this.bimCertFileImg
      };
      this.uploadExtParams['extField1'] = fileType === 'pdf' ? this.bimCertFilePdf : this.bimCertFileImg;
    }
    //添加额外参数
    for(let p in this.uploadExtParams){
      let newP = '';
      if(p.indexOf('.') > -1){
        newP = p.substr(0, p.indexOf('.'))
      }else{
        newP = p;
      }
      formData.append(newP, this.uploadExtParams[p]);
    }
    formData.append('name', file.fileName || '');
    if (!this.bimCertFile) {
      formData.append('fileFk', this.fileFk || this.guid);
    }
    formData.append('fileDesc', file.fileDesc || file.fileName);
    formData.append('uploader', file.uploader || '');
    formData.append('extField5', file.extField5 || '');
    //plupload自己后台处理了Type和size
    formData.append('fileType', file.fileType || '');
    //文件
    formData.append('files[]', file);
    const upploadAddress = this.bimCertFile ? '/thsfiles/api/sys-files/uploadFileCert' : '/thsadmin/api/sys-files/upload';
    const req = new HttpRequest('POST', upploadAddress, formData, {
       reportProgress: true
    });
    this.http.request(req)
      .subscribe(
        (event:  HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              return `Uploading file "${file.name}" of size ${file.size}.`;

            case HttpEventType.UploadProgress:
              // Compute and show the % done:
              const percentDone = Math.round(100 * event.loaded / event.total);
              file.percent = percentDone;
              return ;

            case HttpEventType.Response:
              if(event.status == 201){
                this.uploadLength --;
                event.body.createdDate = moment(event.body.createdDate).format('YYYY-MM-DD HH:mm:ss');
                //lastModifiedDate该属性报错，莫名其妙
                delete event.body.lastModifiedDate;
                file = Object.assign(file, event.body);
                file.fileSizeForRead = this.fileUploadService.readablizeBytes(file.fileSize);
                file['imgPreviewUrl'] = this.previewAddress + file.id;
                this.successedFileList.push(file);
                this.uploadFinish.emit({success: true, file: file});
                if (this.bimCertFile) {
                  const nextFile = uploadFileList.pop();
                  if (!isNullOrUndefined(nextFile)) {
                    this.uploadFile(nextFile, uploadFileList);
                  }
                }
              }else{
                this.failedFileList.push(file);
                this.uploadFinish.emit({success: false, file: file});
                if (this.bimCertFile) {
                  const nextFile = uploadFileList.pop();
                  if (!isNullOrUndefined(nextFile)) {
                    this.uploadFile(nextFile, uploadFileList);
                  }
                }
              }
              return `File "${file.name}" was completely uploaded!`;

            default:
              if(event['status'] == 0 && event['statusText'] == "OK"){
                this.failedFileList.push(file);
                this.uploadFinish.emit({success: false, file: file});
              }
              return `File "${file.name}" surprising upload event: ${event.type}.`;
          }
        },
        err => {
          // this.uploadFinish.emit({success: false, file: file});
          // this.failedFileList.push(file);
          this.msg.error(file.name + '上传失败');
        },
        () => {
          file.percent = 0;
          uploadList--;
          if(this.uploadLength==0){
            this.allUploadFinish.emit({successFile: this.successedFileList, failedFile: this.failedFileList});
            this.fileList = this.failedFileList;
            this.uploading = false;
          }
        }
      );
  }

  updateGuidToFk(newFk: string){
    this.fileUploadService.updateFkBatch(this.guid, newFk).subscribe((res) => {});
  }


  deleteByGuid(){
    this.fileUploadService.deleteAllByFk(this.guid).subscribe((res) => {});
  }
  
  // ------------------------OSS文件上传-----------------------------

  fileUploadProgress = (p, checkPoint) => {
    // console.log(p, checkPoint);
    this.ossFileUploadId = checkPoint.uploadId;

    const objectName = checkPoint.name;
    const prefix = objectName.substring(0, objectName.lastIndexOf('.'));
    // 生成用于记录断点续传上传的文件。
    const blob = new Blob([JSON.stringify(checkPoint)]);
    const file = new File([blob], prefix.substring(0, objectName.lastIndexOf('/')) + '.txt', { type: 'text/plain;charset=utf-8' });

    const fileName = prefix + '.txt';
    this.ossClient.put(fileName, file).catch((err) => {
      if (err) {
        console.log(err);
        return;
      }
    }).then((result) => {
      console.log(result);
    });

    this.percent = Number(Number(p * 100).toFixed(0));
    checkPoint.file.percent = Number(Number(p * 100).toFixed(0));
    if (p === 1) {
      this.showPercent = false;
    }
  }

  customOSSFileReq = (item: UploadXHRArgs) => {
    if(item.file.id != undefined && item.file.id != ''){
      return;
    }
    this.uploading = true;
    this.showPercent = true;
    const fileName = item.file.name;

    const suffix = fileName.substring(fileName.lastIndexOf('.'));
    const uid = new GuidUtil().toString();
    const realName = uid + suffix;

    const nowTime = moment(new Date()).format('YYYYMMDD');
    const filePath = nowTime + '/' + realName;

    // 是否需要分片上传
    const isNeedMutipart = item.file.size > 50 * 1024 * 1024;
    // 先初始化分片上传数据 initiateMultipartUpload
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    formData.append('name', fileName || '');
    formData.append('fileType', suffix);
    formData.append('fileFk', this.fileFk || GuidUtil.getNewGUIDString());
    formData.append('fileDesc', item.file.fileDesc || item.file.fileName);
    formData.append('fileSavePath', filePath);
    formData.append('size', item.file.size.toString());
    formData.append('uploader', item.file.uploader);
    //添加额外参数
    for(let p in this.uploadExtParams){
      let newP = '';
      if(p.indexOf('.') > -1){
        newP = p.substr(0, p.indexOf('.'))
      }else{
        newP = p;
      }
      formData.append(newP, this.uploadExtParams[p]);
    }
    formData.append('extField2', 'AliOss');
    formData.append('extField3', Md5.hashStr(this.fileFk + fileName + item.file.size).toString());
    formData.append('extField4', isNeedMutipart ? '0' : '1');
    formData.append('extField5', item.file.extField5 || '');
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress : true,
      withCredentials: true
    });

    this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.Response) {
        const res = event.body;
        const data = event.body.data;
        // 不需要上传
        if (res.code === '1') {
          this.showPercent = false;
          this.fileList.pop();
          this.msg.info(res.message);
          return;
        }
        if (!isNeedMutipart) {
          this.ossClient.put(filePath, item.file).catch((err) => {
            if (err) {
              console.log(err);
              // 上传出错
              this.msg.error('上传失败，请刷新网页或更换浏览器再次尝试');
              this.fileList.pop();
              this.thsadminFileService.deleteFileById(data.id).subscribe();
              this.uploading = false;
              return;
            }
          }).then((result) => {
            if (result !== undefined) {
              this.workManageService.updateUploadDate(this.fileFk).subscribe();
              console.log(data);
              for (const key in data) {
                if (key.indexOf('Date') < 0) {
                  item.file[key] = data[key];
                }
              }
              item.file.uid = data.id;
              item.file.url = this.ossClient.signatureUrl(filePath);
              item.file.percent = 100;
              item.file.createdDate = moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss');

              this.fileList.pop();
              this.fileList.push(item.file);
            }
            this.showPercent = false;
            this.uploading = false;
            this.uploadLength --;
          });
          return;
        }
        // 续传
        if (res.code === '2') {
          const sysFile = res.data;
          const checkFilePath = sysFile.fileSavePath;
          const checkPointFilePath = checkFilePath.substring(0, checkFilePath.lastIndexOf('.')) + '.txt';


          // 检查文件是否存在
          this.ossClient.head(checkPointFilePath).catch((err) => {
            if (err.code === 'NoSuchKey') {
              // 续传文件找不到，重新上传
              this.mutipartUploadOSSFile(checkFilePath, item, event, null);
              return;
            }
          }).then((result) => {
            if (result !== undefined) {
              // 续传
              const filename = 'fileCheckpoint.txt';
              const response = {
                'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
              };
              const fileUrl = this.ossClient.signatureUrl(checkFilePath.substring(0, checkFilePath.lastIndexOf('.')) + '.txt', {response});
              const xhr = new XMLHttpRequest();
              xhr.open('get', fileUrl, true);
              xhr.onload = (fRes: any) => {
                  console.log(fRes);
                  const target = fRes.target;
                  if (target.status === 200) {
                    const text = target.responseText;
                    const checkPoint = JSON.parse(text);
                    this.mutipartUploadOSSFile(checkFilePath, item, event, checkPoint);
                  }
              };
              xhr.send();
            }
          });
        }
        // 初次上传
        if (res.code === '0') {
          this.mutipartUploadOSSFile(filePath, item, event, null);
        }
      }
    });

    return this.fileUploadService.query({ 'id.equals': '123' }).subscribe(res => {
    });
  }

  mutipartUploadOSSFile(filePath, item, event, checkPoint) {
    const data = event.body.data;
    // 通过multipartUpload上传选中的文件，并通过progress参数设置进度条。
    const options = {
      progress: this.fileUploadProgress,
      partSize: 5 * 1024 * 1024,
    };
    if (checkPoint !== null) {
      options['checkpoint'] = checkPoint;
    }
    this.ossClient.multipartUpload(filePath, item.file, options).catch((err) => {
      if (err) {
        console.log(err);
        // 上传出错
        this.msg.error('上传失败，请刷新网页或更换浏览器再次尝试');
        this.fileList.pop();
        if (checkPoint === null) {
          this.thsadminFileService.deleteFileById(data.id).subscribe();
        }
        this.uploading = false;
        return;
      }
    }).then((result) => {
      console.log(result);
      if (result !== undefined) {
        this.workManageService.updateUploadDate(this.fileFk).subscribe();
        item.file.uid = data.id;
        this.fileList.pop();
        this.fileList.push(item.file);
        data.extField4 = '1';
        this.fileUploadService.update(data).subscribe();
        
        for (const key in data) {
          if (key.indexOf('Date') < 0) {
            item.file[key] = data[key];
          }
        }
        item.file.uid = data.id;
        item.file.url = this.ossClient.signatureUrl(filePath);
        item.file.percent = 100;
        item.file.createdDate = moment(data.createdDate).format('YYYY-MM-DD HH:mm:ss');
        // 删除oss记录断点续传文件
        const objectName = data.fileSavePath;
        this.deleteOSSFile(objectName.substring(0, objectName.lastIndexOf('.')));
      }
      this.showPercent = false;
      this.uploading = false;
      this.uploadLength --;
    });
  }

  deleteOSSFile(objectName) {
    this.ossClient.delete(objectName + '.txt').catch((err) => {
      if (err) {
        console.log(err);
        return;
      }
    }).then((res) => {
      console.log(res);
    });
  }

  beforeUploadCover = (file: UploadFile): boolean => {
    const imgFormat = ['jpg', 'png', 'jpeg', 'bmp', 'ico'];
    const type = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if (imgFormat.indexOf(type) < 0) {
      this.msg.remove();
      this.msg.error('格式错误,仅支持' + imgFormat + '格式');
      return false;
    }

    if (file.size > 300 * 1024) {
      this.msg.remove();
      this.msg.error('支持300KB以下图片上传');
      return false;
    }
    return true;
  }

  // 上传封面文件参数
  fileCoverParam = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileDesc: file.name,
      uploader: this.personName,
      fileType: file.name.substring(file.name.indexOf('.') + 1),
      extField1: 'team_work_video_cover'
    };
    return tmp;
  }

  // 上传成功后把旧文件删除
  handleChangeCover(event: any, id) {
    console.log(event);
    if (event.type === 'success') {
      console.log(event);
      const file = event.file.response;
      file.fileFk = id;
      this.fileUploadService.update(file).subscribe();
      this.dataSet.forEach(e => {
        if (e.id === id) {
          // 删除之前上传的文件
          if (!isNullOrUndefined(e.videoCoverId)) {
            this.fileUploadService.delete(e.videoCoverId).subscribe();
          }
          e.videoCoverId = file.id;
          e.videoCover = this.previewAddress + file.id;
        }
      });
    }
  }
}