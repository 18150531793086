import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BimInfoQq } from './bim-info-qq.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class BimInfoQqService extends ThsBaseService<BimInfoQq> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-info-qqs`);
        console.log(this.resourceUrl);
    }


}

