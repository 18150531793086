import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CourseCatalog } from '../courseCatalog/courseCatalog.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { DictDetail } from '../../../../shared/components/dict/dictDetail.model';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CourseAuditService extends ThsBaseService<CourseCatalog> {
  private auditurl = '';

  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-course-catalogs`);
      this.auditurl = httpAddressService.EduServe + '/edu-course-audits';
  }
  findTypeFromDict(id: string): Observable<HttpResponse<DictDetail>> {
    return this.http.get<DictDetail>('/thsadmin/api/sys-dict-details/' + id, { observe: 'response' });
  }

 getRepositoryOfOptionData(keyword?: string): Observable<string[]> {

  return null;
  }
  queryCourseCatalog(queryParams?: any): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getAllCourseCatalogByIsAudit` ;
    return this.http.get<HttpResponse<any>>(url, { params: queryParams, observe: 'response' });
  }
  findCourseCatalogInfo(id: string): Observable<HttpResponse<any>> {
    const url = `${this.resourceUrl}/getCourseCatalogInfoById/` ;
    return this.http.get<DictDetail>(url + id, { observe: 'response' });
  }

  saveAuditResult (record: any): Observable<HttpResponse<any>> {
  const copy = this.convertDateFromClient(record);
  const url = `${this.auditurl}/saveCourseAuditResult` ;
  return this.http.post<any>(url, copy, { observe: 'response'})
  .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
 }
}

