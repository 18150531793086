import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimInfoSchools } from './bim-info-schools.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BimInfoSchoolsService extends ThsBaseService<BimInfoSchools> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-info-schools`);
    }



        /** POST: 添加一条新记录 */
        addSchools (bimInfoId:string, schoolIds:string): Observable<HttpResponse<any>> {
            let url = this.resourceUrl + `/addSchools/${bimInfoId}/${schoolIds}`;
            return this.http.post<any>(url, { observe: 'response'}).pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
        }


        deleteBimInfoSchools (bimInfoId:string, schoolId:string): Observable<HttpResponse<any>> {
            let url = this.resourceUrl + `/deleteBimInfoSchools/${bimInfoId}/${schoolId}`;
            return this.http.delete<HttpResponse<any>>(url, { observe: 'response'});
        }


        /**
         * 保存参赛学校信息
         * @param record 
         * @returns 
         */
        saveOrUpdateBimInfoSchools(record):Observable<HttpResponse<any>> {
            const copy = this.convertDateFromClient(record);
            return this.http.post<any>(this.resourceUrl + '/saveOrUpdateBimInfoSchools', copy, { observe: 'response'})
            .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
        }
}

