import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import {courseSummarize } from './courseSummer.model';
import {ThsBaseService } from "@shared/components/thsBaseService";
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class CourseSummerService extends ThsBaseService<courseSummarize> {
  courseType=[];
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-course-summarizes`);
      
  }

}

