import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Notification } from './notification.model';
import {ThsBaseService } from "@shared/components/thsBaseService";


@Injectable({ providedIn: 'root' })
export class NotificationService extends ThsBaseService<Notification> {

  constructor(http: HttpClient) {
      super(http, `/thsadmin/api/sys-notifications`);
  }


  getAllSysNotifications (params:any): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(this.resourceUrl, {params: params ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  /**
   * 获取待办
   * @param params 
   * @returns 
   */
  getAllNoticeThings (params:any): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>('/thsworkflow/api/wf-notice-things', {params: params ,  observe: 'response'})
    .pipe(map((res: HttpResponse<any[]>) => this.convertDateArrayFromServer(res)));
  }

  public setRead(ids: string){
    return this.http.put('/thsadmin/api/sys-notifications/read',  ids, {observe: 'response'});
  }


}

