import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { SFSchema, SFComponent, SFUISchema } from '@delon/form';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { BimSchoolsService } from '../../../school/bim-schools.service';
import { BimSchoolDetailsService } from '../../../school/bim-school-details.service';
import { BimInfoSchoolsService } from '../bim-info-schools.service';
import { BimInfoEventsService } from '../../bim-info-events/bim-info-events.service';
import { delay } from 'rxjs/operators';
import { FormGroup,FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { isNullOrUndefined } from 'util';
import { resolve } from 'url';
import * as moment from 'moment';

@Component({
  selector: 'app-bim-info-schools-add-province',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less']
})
export class BimInfoSchoolsAddProvinceComponent implements OnInit {


  public schoolTypeList = {'0': '本科',  '1' : '专科', '2': '中职'};
  public belongTypeList = {'0': '985',  '1' : '211', '2' : '其他'};

  record: any = {};
  recordSchool:any = {};
  recordDetail:any = {};
  recordInfoSchool:any = {};

   // 是否增加
  isAdd: boolean;
  // 文件id
  fileId = '';
  fileList=[];
  haveSelected = false;
  loading = false;
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';

  
  form: FormGroup;

  entityId:string;
  entityName:String;
  accountName:String;

  collegeContactList = [];
  freqContactList = [];
  recommendSchoolList = [];
  recommendContryList = [];

  bimInfoType = 'province';//省赛
  dataList = [];
  bimEventsList = [];//大赛赛项

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private cdr: ChangeDetectorRef,
    private bimSchoolsService: BimSchoolsService,
    private bimSchoolDetailsService: BimSchoolDetailsService,
    private bimInfoEventsService: BimInfoEventsService,
    private bimInfoSchoolsService: BimInfoSchoolsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      name: [null, [Validators.required]],
      college: [null, [Validators.required]],
      professional: [null, []],
      schoolType: [null, [Validators.required]],
    //   location: [null, []],
    //   province: [null, []],
      email: [null, []],
      address: [null, [Validators.required]],
      belongType: [null, []],
      userDefine1: [this.record.userDefine1, []],
      userDefine4: [this.bimInfoType, []],
      isDelete: [0, []],
      schoolDetailId: [null,[]],
      audit:['1',[]],//审核状态 1 通过 0 未通过
      // linkmanName: [null, [Validators.required, Validators.maxLength(50)]],//联系人
      // linkmanPhone: [null, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]],//联系电话
      // linkmanQq: [null, [Validators.required, Validators.pattern(/[1-9][0-9]{4,14}$/)]],//联系人qq
    });
    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;
  }

  ngOnInit() {

    this.loadData();
  }



/**
 * 加载页面data
 */
  loadData(): void {
    // this.form.patchValue(this.record);
    if(this.isAdd){
      this.addRow();
    }
    this.getEventsDataList();
    this.getBimSchools();
    this.getBimSchoolDetailList();
    this.getBimInfoSchoolInfo();
  }
  
  getBimSchools(){
    // if(!this.record || !this.record.id){
    //     return;
    // }

    // this.bimSchoolsService.find(this.record.id).subscribe(res => {
    //     const data = res.body;
    //     debugger;
    //     if(data){
    //         this.recordSchool = data;
    //         this.form.patchValue(this.recordSchool);
    //     }else{
    //       this.addRow();
    //     }
    // })
    this.recordSchool = this.record;
    this.form.patchValue(this.recordSchool);
  }
  getBimSchoolDetailList(){
    if(this.isAdd){
        return;
    }
    const params = {
        'bimInfoId.equals': this.record.bimInfoId,
        'bimSchoolId.equals': this.record.id ? this.record.id : '无',
        page:0,
        size: 1000,
        sort:['userDefine1,asc','linkmanName,asc']
    }
    
    this.bimSchoolDetailsService.query(params).subscribe(res => {
        const datas = res.body;
        if(datas && datas.length > 0){
          datas.forEach(item => {
            this.addFormControl(item);
            item['required'] = true;
          })

          this.dataList = datas;
        }else{
          this.addRow();
        }
        // const data = datas && datas.length > 0 ? datas[0] : null;
        // if(data){
        //     this.recordDetail = data;
        //     this.form.get('schoolDetailId').setValue(data.id);
        //     this.form.get('linkmanName').setValue(data.linkmanName);
        //     this.form.get('linkmanPhone').setValue(data.linkmanPhone);
        //     this.form.get('linkmanQq').setValue(data.linkmanQq);
        // }
    })
  }

  getBimInfoSchoolInfo(){
    if(this.isAdd){
      return;
    }
    const params = {
      'bimInfoId.equals': this.record.bimInfoId,
      'schoolId.equals': this.record.id
    }
    this.bimInfoSchoolsService.query(params).subscribe(res => {
      const data = res.body;
      if(data && data.length > 0){
        this.recordInfoSchool = data[0];
      }
    })
  }


  /**
   * 获取赛事赛项
   * @param isReset 
   */
  getEventsDataList(isReset?: boolean) {
    let copyParams = {sort: ['createTime,desc', 'eventsType,desc']};

    copyParams['page'] = 0;
    copyParams['size'] = 1000;
    copyParams['bimInfoId.contains'] = this.record.bimInfoId;
    copyParams['isDelete.equals'] = 0;

    this.bimInfoEventsService.query(copyParams)
      .subscribe((res: any) => {
        const data = res.body.filter(e => e.eventsType == 0);
        this.bimEventsList = res.body;
    });   
  }

  addFormControl(item){
    // this.form.addControl('link_'+ item.id + '_bimInfoEventsId' , new FormControl(item.bimInfoEventsId, Validators.required));
    // this.form.addControl('link_'+ item.id + '_linkmanName', new FormControl(item.linkmanName, [Validators.required, Validators.maxLength(50)]));
    // this.form.addControl('link_'+ item.id + '_linkmanPhone', new FormControl(item.linkmanPhone, [Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]));
    // this.form.addControl('link_'+ item.id + '_linkmanQq', new FormControl(item.linkmanQq, [ Validators.pattern(/[1-9][0-9]{4,14}$/)]));

    this.form.addControl('link_'+ item.id + '_bimInfoEventsId' , new FormControl(item.bimInfoEventsId, []));
    this.form.addControl('link_'+ item.id + '_linkmanName', new FormControl(item.linkmanName, [Validators.maxLength(50)]));
    this.form.addControl('link_'+ item.id + '_linkmanPhone', new FormControl(item.linkmanPhone, [ Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]));
    this.form.addControl('link_'+ item.id + '_linkmanQq', new FormControl(item.linkmanQq, [ Validators.pattern(/[1-9][0-9]{4,14}$/)]));
  }

  addRow(){
    const item = {
      id: new GuidUtil().toString(),
      bimInfoEventsId: null,
      bimInfoId: this.record.bimInfoId,
      bimSchoolId: this.record.id,
      linkmanName: null,
      linkmanPhone: null,
      linkmanQq: null,
      remark: null,
      required: false,//是否必填
      userDefine1: (this.dataList.length + 1),
    }
    this.dataList = [ ...this.dataList,item ];
   
    this.addFormControl(item);
  }

  removeRow(item) {
    this.dataList = this.dataList.filter(item2 => {
      return item2.id !== item.id
    })
   
    this.form.removeControl('link_'+ item.id + '_bimInfoEventsId');
    this.form.removeControl('link_'+ item.id + '_linkmanName');
    this.form.removeControl('link_'+ item.id + '_linkmanPhone');
    this.form.removeControl('link_'+ item.id + '_linkmanQq');
    
  }

  changeBimInfoEvents(value,item){
    debugger;
    const events = isNullOrUndefined(value) || value.length <= 0 ? null : this.bimEventsList.filter(w=>w.id === value);
    const eventsType = events && events.length > 0 ? events[0].eventsType : null;
    //eventsType 0为团队赛 1为个人赛
    if(eventsType == '1'){
      const _bimInfoEventsId = this.form.get('link_'+item.id+'_bimInfoEventsId');
      const _linkmanName = this.form.get('link_'+item.id+'_linkmanName');
      const _linkmanPhone = this.form.get('link_'+item.id+'_linkmanPhone');
      const _linkmanQq = this.form.get('link_'+item.id+'_linkmanQq');

      if((_linkmanName != null && _linkmanName.value != null && _linkmanName.value.trim().length > 0) 
      || (_linkmanPhone != null && _linkmanPhone.value != null && _linkmanPhone.value.trim().length > 0)
      || (_linkmanQq != null && _linkmanQq.value != null && _linkmanQq.value.trim().length > 0)){
        item.required = true;
        this.addFormControlValidator(item.id);
      }else{
          //个人赛非必填
        _bimInfoEventsId.clearValidators();
        _linkmanName.clearValidators();
        _linkmanPhone.clearValidators();
        _linkmanQq.clearValidators();

        item.required = false;

        _bimInfoEventsId.updateValueAndValidity();
        _linkmanName.updateValueAndValidity();
        _linkmanPhone.updateValueAndValidity();
        _linkmanQq.updateValueAndValidity();
      }
      
      
    }else{
      this.addFormControlValidator(item.id);
    }
  }

  addFormControlValidator(id){
    this.form.get('link_'+id+'_linkmanName').setValidators([Validators.required, Validators.maxLength(50)]);
    this.form.get('link_'+id+'_linkmanPhone').setValidators([Validators.required, Validators.pattern(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)]);
    this.form.get('link_'+id+'_linkmanQq').setValidators([ Validators.pattern(/[1-9][0-9]{4,14}$/)]);
  }

  changeLinkInfo(item){
    const _bimInfoEventsId = this.form.get('link_'+item.id+'_bimInfoEventsId');
    const _linkmanName = this.form.get('link_'+item.id+'_linkmanName');
    const _linkmanPhone = this.form.get('link_'+item.id+'_linkmanPhone');
    const _linkmanQq = this.form.get('link_'+item.id+'_linkmanQq');
    if((_linkmanName != null && _linkmanName.value != null && _linkmanName.value.trim().length > 0) 
      || (_linkmanPhone != null && _linkmanPhone.value != null && _linkmanPhone.value.trim().length > 0)
      || (_linkmanQq != null && _linkmanQq.value != null && _linkmanQq.value.trim().length > 0)){
        item.required = true;
        this.addFormControlValidator(item.id);
    }else{
      this.changeBimInfoEvents(_bimInfoEventsId.value,item);
    }
  }

  setValue(){
    const param = Object.assign({},this.recordSchool);
   
    for (const key in this.form.controls) {
      // if(key.indexOf("link_") != -1 && key.indexOf("_linkmanName") != -1){
      //   const control = this.form.get(key);
      //   if(isNullOrUndefined(control.value)){
      //     control.clearValidators();
      //   }
      // }
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      if (this.form.controls[key].value === true) {
        param[key] = '1';
      } else if (this.form.controls[key].value === false) {
        param[key] = '0';
      } else {
        param[key] = this.form.controls[key].value;
      }
    }

    return param;
  }

  saveBefore(){
    let param = this.setValue();

    if (this.form.invalid) {
      console.log(this.form.errors);
      this.msg.error('请检查学校信息是否完整');
      return new Promise((resolve,reject) => {
        reject(false);
      });
    }
    
    this.recordDetail.bimInfoId = this.record.bimInfoId;
    return param;

    // return new Promise((resolve,reject) => {
    //     //判断学校是否存在
    //     const params = {
    //         'name.equals': this.form.get('name').value,
    //         'isDelete.equals': '0'
    //     }
    //     this.bimSchoolsService.query(params).subscribe(res => {
    //         const datas = res.body.filter(w=>w.id != this.record.id);
    //         if(datas && datas.length > 0){
    //             const selDatas = res.body.filter(w=>w.id == this.record.id || w.name === param.name);
    //             this.recordSchool = !selDatas || selDatas.length <= 0 ? {} : selDatas[0];
    //             this.form.get('id').setValue(this.recordSchool.id);
    //             param = this.setValue();
    //         }
    //         resolve(param);
    //     },error => {
    //         reject(false);
    //     })
        
    // })
  }

  // 保存
  submit() {
    const res = this.saveBefore();
    if(typeof(res) === 'object' && !isNullOrUndefined(res)){
      this.loading = true;
      if(this.dataList && this.dataList.length > 0){
  
        this.dataList.forEach(item => {
          item['bimInfoId'] = item['bimInfoId'] ? item['bimInfoId'] : this.record.bimInfoId;
          if(item['createTime']){
            item['createTime'] = moment(item['createTime']);
          }
          if(item['updateTime']){
            item['updateTime'] = moment(item['updateTime']);
          }
          
          const bimInfoEventsIdKey = 'link_'+item.id +'_bimInfoEventsId' ;
          let control = this.form.get(bimInfoEventsIdKey);
          item['bimInfoEventsId'] = control ? control.value : null;
  
          const linkmanNameKey = 'link_'+item.id +'_linkmanName';
          control = this.form.get(linkmanNameKey);
          item['linkmanName'] = control ? control.value : null;
  
          const linkmanPhoneKey = 'link_'+item.id +'_linkmanPhone';
          control = this.form.get(linkmanPhoneKey);
          item['linkmanPhone'] = control ? control.value : null;
  
          const llinkmanQqKey = 'link_'+item.id +'_linkmanQq';
          control = this.form.get(llinkmanQqKey);
          item['linkmanQq'] = control ? control.value : null;
        })
      }

      res['bimSchoolDetailList'] = this.dataList;// 参赛学校-联系人
      if(res['createTime']){
        res['createTime'] = moment(res['createTime']);
      }
      if(res['updateTime']){
        res['updateTime'] = moment(res['updateTime']);
      }

      this.recordInfoSchool = {
        ...this.recordInfoSchool,
        'bimInfoId': this.record.bimInfoId,
        'bimSchools': res,
        'createTime': !this.recordInfoSchool['createTime'] ? moment(this.recordInfoSchool['createTime']) : this.recordInfoSchool['createTime'],
        'updateTime': !this.recordInfoSchool['updateTime'] ? moment(this.recordInfoSchool['updateTime']) : this.recordInfoSchool['updateTime']
      }

      this.saveInfoSchools(this.recordInfoSchool);
    }else{
        this.msg.warning(this.isAdd ? '保存失败' : '修改失败');
    }
    // this.saveBefore().then(res => {
    //     if(typeof(res) === 'object' && !isNullOrUndefined(res)){
    //         this.loading = true;

    //         if(this.dataList && this.dataList.length > 0){
        
    //           this.dataList.forEach(item => {
    //             item['bimInfoId'] = item['bimInfoId'] ? item['bimInfoId'] : this.record.bimInfoId;
    //             if(item['createTime']){
    //               item['createTime'] = moment(item['createTime']);
    //             }
    //             if(item['updateTime']){
    //               item['updateTime'] = moment(item['updateTime']);
    //             }
                
    //             const bimInfoEventsIdKey = 'link_'+item.id +'_bimInfoEventsId' ;
    //             let control = this.form.get(bimInfoEventsIdKey);
    //             item['bimInfoEventsId'] = control ? control.value : null;
        
    //             const linkmanNameKey = 'link_'+item.id +'_linkmanName';
    //             control = this.form.get(linkmanNameKey);
    //             item['linkmanName'] = control ? control.value : null;
        
    //             const linkmanPhoneKey = 'link_'+item.id +'_linkmanPhone';
    //             control = this.form.get(linkmanPhoneKey);
    //             item['linkmanPhone'] = control ? control.value : null;
        
    //             const llinkmanQqKey = 'link_'+item.id +'_linkmanQq';
    //             control = this.form.get(llinkmanQqKey);
    //             item['linkmanQq'] = control ? control.value : null;
    //           })
    //         }

    //         res['bimSchoolDetailList'] = this.dataList;// 参赛学校-联系人
    //         if(res['createTime']){
    //           res['createTime'] = moment(res['createTime']);
    //         }
    //         if(res['updateTime']){
    //           res['updateTime'] = moment(res['updateTime']);
    //         }

    //         this.recordInfoSchool = {
    //           ...this.recordInfoSchool,
    //           'bimInfoId': this.record.bimInfoId,
    //           'bimSchools': res,
    //           'createTime': !this.recordInfoSchool['createTime'] ? moment(this.recordInfoSchool['createTime']) : this.recordInfoSchool['createTime'],
    //           'updateTime': !this.recordInfoSchool['updateTime'] ? moment(this.recordInfoSchool['updateTime']) : this.recordInfoSchool['updateTime']
    //         }

    //         this.saveInfoSchools(this.recordInfoSchool);
    //     }else{
    //         this.msg.warning(this.isAdd ? '保存失败' : '修改失败');
    //     }
    // });
  }

  // addData(param: any) {
  //   this.bimSchoolsService.create(param).subscribe(res => {
  //     if (res.body) {
  //       this.recordSchool = Object.assign(this.record,res.body);
  //       this.record = Object.assign(this.record,res.body);
  //       this.saveSchoolDetails();
  //       // this.msg.info('保存成功');
  //       // this.nzModalRef.destroy();
  //       this.fileId = this.record;
  //       // this.msg.info('保存成功');
  //       // this.close(true);
  //       //this.nzModalRef.destroy();
  //     } else {
  //       this.loading = false;
  //       this.msg.error('保存失败');
  //     }
  //   },error => {
  //       this.loading = false;
  //       this.msg.error('保存失败');
  //   });
  // }

  // updateData(param: any) {
  //   this.bimSchoolsService.update(param).subscribe(res => {
  //     if (res.body) {
  //       this.recordSchool = Object.assign(this.record,res.body);
  //       this.record = Object.assign(this.record,res.body);
  //       // this.msg.info('修改成功');
  //       this.saveSchoolDetails();
  //       //this.nzModalRef.destroy();
  //       // this.close(true);
  //     } else {
  //       this.loading = false;
  //       this.msg.error('修改失败');
  //     }
  //   },error => {
  //       this.msg.error('修改失败');
  //       this.loading = false;
  //   });
  // }
  saveInfoSchools(data){
    this.bimInfoSchoolsService.saveOrUpdateBimInfoSchools(data).subscribe(res => {
      debugger;
        this.loading = false;
        if (res.body && res.body.success) {
            this.msg.info('保存成功');
            this.close(true);
        } else {
            this.msg.error(res.body.message ? res.body.message : '保存失败');
        }
    },error => {
      debugger;
        this.loading = false;
        console.log(error);
        this.msg.error('保存失败');
    })
  }

  // saveSchoolDetails(){
  //   this.recordDetail.bimSchoolId = this.record.id;
  //   this.bimSchoolDetailsService.saveOrUpdateBimSchoolDetails(this.recordDetail).subscribe(res => {
  //       this.loading = false;
  //       if (res.body) {
  //           this.msg.info('保存成功');
  //           this.recordDetail = res.body;
  //           this.close(true);
  //       } else {
  //           this.msg.error('保存失败');
  //       }
  //   },error => {
  //       this.loading = false;
  //       console.log(error);
  //       this.msg.error('保存失败');
  //   })
  // }

  close(flag?){
    this.nzModalRef.destroy(flag);
  }


     /**
   * 特殊字符转换位html标签处理
   * @param obj
   */
  ReplaceStr(obj: string) {
    if (obj != null) {
      obj = obj.replace(/\n/g, '\<br\/\>');
      obj = obj.replace(/\"/g, '&quot;');
      obj = obj.replace(/\\/g, '&#92;');


      obj = obj.replace(/\//g, '&#47;');
      obj = obj.replace(/\^/g, '&#94;');
      obj = obj.replace(/\{/g, '&#123;');
      obj = obj.replace(/\}/g, '&#125;');
      obj = obj.replace(/\+/g, '&#43;');
      obj = obj.replace(/\(/g, '&#40;');
      obj = obj.replace(/\)/g, '&#41;');
    }
    return obj;
  }

  /**
   * html标签转换位转义字符处理
   * @param obj
   */
  ReplaceHtml(obj: string) {
    // if (obj === undefined) {
    //   return;
    // }
    obj = obj.replace(/\<br\/\>/g, '\n');
    obj = obj.replace(/&quot;/g, '"');
    obj = obj.replace(/&#92;/g, '\\');

    obj = obj.replace(/&#47;/g, '/');
    obj = obj.replace(/&#94;/g, '^');
    obj = obj.replace(/&#123;/g, '{');
    obj = obj.replace(/&#125;/g, '}');
    obj = obj.replace(/&#43;/g, '+');
    obj = obj.replace(/&#40;/g, '(');
    obj = obj.replace(/&#41;/g, ')');

    return obj;
  }

}
