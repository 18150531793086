import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CertIntroduce } from './certIntroduce.model';
import {ThsBaseService } from '@shared/components/thsBaseService';
import { HttpResponse } from '@angular/common/http';
import { HttpAddressService } from '@shared/session/http-address.service';
@Injectable({ providedIn: 'root' })
export class CertIntroduceService extends ThsBaseService<CertIntroduce> {
  constructor(http: HttpClient, httpAddressService: HttpAddressService) {
      super(http, httpAddressService.EduServe + `/edu-cert-introduces`);
  }

findAllByDictNo(dictNo: string): Observable<HttpResponse<any[]>>  {
  const dictUrl = '/thsadmin/api/sys-dicts';
  const url = dictUrl + '/findAllByDictNo';
  return this.http.get<any>(`${url}/${dictNo}`, { observe: 'response' });
}

}

