import { Component, ElementRef, OnInit,AfterContentChecked, ViewChild,Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NzMessageService, NzModalRef,UploadFile } from 'ng-zorro-antd';
import { ProjectReportService } from "./../projectReport.service";
import { DictDetailService } from '@shared/components/dict/dict-detail.service';
import { DictService } from 'app/shared/components/dict/dict.service';
import { zip } from 'rxjs';
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { environment } from '@env/environment';
import { ROUTER_CONFIGURATION } from '@angular/router';
import {GuidUtil} from "@shared/utils/guid.util";
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { UserService } from "app/routes/admin/system/users/users.service";
import { ACLService } from '@delon/acl';
import { APP_PERMISSIONS_IUCCE } from '@shared/app.permissions';
import { WorkFlowHelper } from "@shared/helpers/WorkFlowHelper";
import { ProjectReportAuditService } from "./../../projectReportAudit/projectReportAudit.service";

@Component({
  selector: 'app-iucce-project-report-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class ProjectReportEditComponent implements OnInit,AfterContentChecked {

  record:any;
  submitting = false;
  saveing = false;
  validateForm: FormGroup;
  validateFormSend: FormGroup;//发送表单
  isAdd = true;
  isView = false;
  prjFlowPersonList = [];//项目跟进人
  prjFlowPersonMaxOrderNum = 0;//项目跟进人最大的排序号
  prjFlowPersonDataKeys = [];//项目跟进人id key集合
  prjFlowPersonDatas = [];//项目跟进人数据集合
  prjNicheList = [];//项目商机情况
  prjNicheMaxOrderNum = 0;//项目商机最大的排序号
  prjNicheDataKeys = [];//项目商机情况id key集合
  prjNicheDatas = [];//项目商机情况数据集合
  
  regionList: any;//大区
  provinceList: any;//省份（根据大区选择进行筛选）
  provinceResultList: any;//省份（所有数据）

  yearList: any;//年份
  batchList:any;//批次
  batchResultList: any;//批次全部数据
  prjTypeList:any;//项目类别
  prjTypeResultList: any;//项目类别全部数据

  uploadFileAddress = '';//附件上传地址
  fileList = [];
  fileListXMS = [];//项目申报书
  fileListContract = [];//合同协议书
  fileListElse = [];//其他
  showUploadListSwitch1 = false;
  showUploadListSwitch2 = false;
  showUploadListSwitch3 = false;

  entityId ='';
  entityName = '';
  APP_PERMISSIONS = APP_PERMISSIONS_IUCCE;
  isSpecialEdit = false;//特权编辑
  isCanSubmit = false;
  isCanSave = false;
  loading = false;

  constructor
    (private fb: FormBuilder, 
      private modal: NzModalRef,
      public msg: NzMessageService,
      private projectReportService: ProjectReportService,
      private projectReportAuditService: ProjectReportAuditService,
      private dictService: DictService,
      private dictDetailService: DictDetailService,
      private thsadminFileService: ThsadminFileService,
      @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
      private userService: UserService,
      private aclService: ACLService) {
        this.entityId = tokenService.get().entity_id;
        this.entityName = tokenService.get().entity_name;
        this.uploadFileAddress = projectReportService.uploadFileAddress;
     }

  ngOnInit(): void {
    
    this.isCanSubmit = this.aclService.canAbility(this.APP_PERMISSIONS.IUCCE_PROJECT_REPORT_EDIT) && (!this.record.prjStatus || this.record.prjStatus <= 20);
    this.isSpecialEdit = this.aclService.canAbility(this.APP_PERMISSIONS.IUCCE_PROJECT_SPECIAL_MODIFY);//特权编辑
    this.isCanSave = ((this.aclService.canAbility(this.APP_PERMISSIONS.IUCCE_PROJECT_REPORT_EDIT) && (!this.record.prjStatus || this.record.prjStatus <= 20)) || this.isSpecialEdit);
    this.record.id = !this.isAdd || this.isView ? this.record.id  : ('add_' + new GuidUtil().toString());
    
    this.validateForm = this.fb.group({
      id : [this.record.id],
      regionId: [null,[Validators.required]],
      region: [!this.isAdd || this.isView ? this.record.region : null, [Validators.required]],
      provinceId: [null,[Validators.required]],
      province: [!this.isAdd || this.isView ? this.record.provinceName : null, [Validators.required]],
      year: [!this.isAdd || this.isView ? (this.record.year != null ? this.record.year.toString() : null ): null, [Validators.required]],
      schoolName: [!this.isAdd || this.isView ? this.record.schoolName : null, [Validators.required]],
      projectName: [!this.isAdd || this.isView ? this.record.projectName : null, [Validators.required]],
      batchId: [!this.isAdd || this.isView ? this.record.batchId : null, [Validators.required]],
      batch: [!this.isAdd || this.isView ? this.record.batch : null, [Validators.required]],
      projectType: [!this.isAdd || this.isView ? this.record.projectType : null, [Validators.required]],
      projectTypeId: [!this.isAdd || this.isView ? this.record.projectTypeId : null, [Validators.required]],
      applicant: [!this.isAdd || this.isView ? this.record.applicant : null, [Validators.required]],
      applicantTel: [!this.isAdd || this.isView ? this.record.applicantTel : null, [Validators.required]],
      applicantDuty: [!this.isAdd || this.isView ? this.record.applicantDuty : null, [Validators.required]],
      endDate: [!this.isAdd || this.isView ? this.record.endDate : null, [Validators.required]],
      isDelay: [!this.isAdd || this.isView ? this.record.isDelay : null],
      delayEndDate: [!this.isAdd || this.isView ? this.record.delayEndDate : null,[this.validateDelayEndDate]],
      contractStatus: [!this.isAdd || this.isView ? this.record.contractStatus : null],
      prjStatus: [!this.isAdd || this.isView ? this.record.prjStatus : null],
      remark : [!this.isAdd || this.isView ? this.record.remark : null]
    });
    this.validateFormSend = this.fb.group({
      auditIdeaReturnModify :  [{ value : null, disabled : true }],
    });
    //this.initFormData();

    // Promise.all([
    //   this.initProjectData(),
    //   this.getYearListDict(),
    //   this.getRegionProvinceInfo()
    // ]).then(res => {
    //   for(const key in this.validateForm.controls){
    //     if(key == "id"){
    //       continue;
    //     }
    //     this.validateForm.controls[key].setValue(this.record[key]);
    //   }
    // }).catch(error => {
      
    // })
    
    
    this.getYearListDict();

    this.getProjectFlowPersons();
    this.getProjectNiches();
    this.getAuditIdea();
    this.getRegionProvinceInfo();
    
    if(this.isAdd){
      this.addFormControl();
      this.addOpportunity();
    }
    if(!this.isSpecialEdit){
      //清除校验
      this.validateForm.get('delayEndDate').clearValidators();
    }
    this.getFileList();
  }

  initProjectData() {
    if (this.record.id != null && this.record.id.indexOf('add_') == -1) {
      this.projectReportService.find(this.record.id).subscribe(res => {
        this.record = res.body;
      });
    }
  }

  initFormData(){
    this.validateForm = this.fb.group({
      id : [this.record.id],
      regionId: [null,[Validators.required]],
      region: [null, [Validators.required]],
      provinceId: [null,[Validators.required]],
      province: [null, [Validators.required]],
      year: [null, [Validators.required]],
      schoolName: [null, [Validators.required]],
      projectName: [null, [Validators.required]],
      batchId: [null, [Validators.required]],
      batch: [null, [Validators.required]],
      projectType: [null, [Validators.required]],
      projectTypeId: [null, [Validators.required]],
      applicant: [null, [Validators.required]],
      applicantTel: [null, [Validators.required]],
      applicantDuty: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      isDelay: [null],
      delayEndDate: [null,[this.validateDelayEndDate]],
      contractStatus: [null],
      prjStatus: [null],
      remark : [null]
    });
    // this.validateForm = this.fb.group({
    //   id : [this.record.id],
    //   regionId: [null,[Validators.required]],
    //   region: [!this.isAdd || this.isView ? this.record.region : null, [Validators.required]],
    //   provinceId: [null,[Validators.required]],
    //   province: [!this.isAdd || this.isView ? this.record.provinceName : null, [Validators.required]],
    //   year: [!this.isAdd || this.isView ? (this.record.year != null ? this.record.year.toString() : null ): null, [Validators.required]],
    //   schoolName: [!this.isAdd || this.isView ? this.record.schoolName : null, [Validators.required]],
    //   projectName: [!this.isAdd || this.isView ? this.record.projectName : null, [Validators.required]],
    //   batchId: [!this.isAdd || this.isView ? this.record.batchId : null, [Validators.required]],
    //   batch: [!this.isAdd || this.isView ? this.record.batch : null, [Validators.required]],
    //   projectType: [!this.isAdd || this.isView ? this.record.projectType : null, [Validators.required]],
    //   projectTypeId: [!this.isAdd || this.isView ? this.record.projectTypeId : null, [Validators.required]],
    //   applicant: [!this.isAdd || this.isView ? this.record.applicant : null, [Validators.required]],
    //   applicantTel: [!this.isAdd || this.isView ? this.record.applicantTel : null, [Validators.required]],
    //   applicantDuty: [!this.isAdd || this.isView ? this.record.applicantDuty : null, [Validators.required]],
    //   endDate: [!this.isAdd || this.isView ? this.record.endDate : null, [Validators.required]],
    //   isDelay: [!this.isAdd || this.isView ? this.record.isDelay : null],
    //   delayEndDate: [!this.isAdd || this.isView ? this.record.delayEndDate : null,[this.validateDelayEndDate]],
    //   contractStatus: [!this.isAdd || this.isView ? this.record.contractStatus : null],
    //   prjStatus: [!this.isAdd || this.isView ? this.record.prjStatus : null],
    //   remark : [!this.isAdd || this.isView ? this.record.remark : null]
    // });
  }

  ngAfterContentChecked():void{
    let control = $('.uploadClass');
    
    if(this.isView && control && control.length > 0 && !control.hasClass('upload-list-view')){
      control.addClass('upload-list-view');
    }
  }

  validateDelayEndDate = (control: FormControl): { [s: string]: boolean } => {
    if(!this.validateForm){
      return {};
    }
    if (this.validateForm.get('isDelay').value == '1') {
      if(!control.value){
        return { required: true };
      }
      let endDateControl = this.validateForm.get('endDate');
      let endDate = !endDateControl.value ? null : new Date(endDateControl.value);
      let delayEndDate = !control.value ?null :new Date(control.value);
      if((endDate && delayEndDate) && delayEndDate.getTime() - endDate.getTime() <= 0){
        return { compare: true,error:true };
      }
    }
  }

  getYearListDict(){
    
    this.dictService.findAllByDictNo("yearList").subscribe(res => {
      if (res.body && res.body.length) {
          this.yearList = res.body;
      }
    });
    this.dictService.query({ 'dictNo.equals':'batchList' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals':res.body[0].id ,sort:'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.batchResultList = res2.body;
              if(!this.isAdd && this.record.year != null){
                this.fnChangeYear(this.record.year.toString());
              }
              
          }
        });
      }
    });
    this.dictService.query({ 'dictNo.equals':'prjTypes' }).subscribe(res => {
      if (res.body && res.body.length) {
        this.dictDetailService.query({ 'sysDictId.equals':res.body[0].id ,sort:'orderNum,asc' }).subscribe(res2 => {
          if (res2.body && res2.body.length) {
              this.prjTypeResultList = res2.body;
              if(!this.isAdd && this.record.year != null){
                this.fnChangeYear(this.record.year.toString());
              }
          }
        });
      }
    });
  }

  /**
   * 获取项目跟进人
   */
  getProjectFlowPersons(){
    if(!this.record.id || this.record.id.indexOf("add_") == 0){
      return;
    }
    this.projectReportService.findProjectFlowinfos(this.record.id).subscribe(res => {
      this.prjFlowPersonList = res.body;
      if(this.prjFlowPersonList && this.prjFlowPersonList.length > 0){
        this.prjFlowPersonList.forEach(item => {
          this.prjFlowPersonMaxOrderNum = parseFloat(item.orderNum);
          if(isNaN(this.prjFlowPersonMaxOrderNum)){
            this.prjFlowPersonMaxOrderNum = 0;
          }
          this.addFormControl(item);
        })
      }else{
        this.addFormControl();
      }
    },(errorRes: any)=>{
              
    });
  }
  /**
   * 获取项目商机信息
   */
  getProjectNiches(){
    if(!this.record.id || this.record.id.indexOf("add_") == 0){
      return;
    }
    this.projectReportService.findProjectNicheinfos(this.record.id).subscribe(res => {
      this.prjNicheList = res.body;
      if(this.prjNicheList && this.prjNicheList.length > 0){
        this.prjNicheList.forEach(item => {
          
          this.prjNicheMaxOrderNum = parseFloat(item.orderNum);
          if(isNaN(this.prjNicheMaxOrderNum)){
            this.prjNicheMaxOrderNum = 0;
          }
          this.addOpportunity(item);
        })
      }else{
        this.addOpportunity();
      }
    },(errorRes: any)=>{
              
    });
    
  }

  /**
   * 获取立项意见
   */
  getAuditIdea(){
    if(this.record.prjStatus != 9){//只有退回修改才需要查看退回意见
      return;
    }
    const wfParams = WorkFlowHelper.getWorkFlowParams('IucceProjectReport',{});
    const pageable = { 
      page : 0,
      size : 10000
    };
            
    this.projectReportAuditService.getAuditIdeaByProjectId(this.record.id,wfParams.businessCode,pageable).subscribe(res=>{
      let resultAll = res.body;
      let result = [];
      if(result != null){
        result = resultAll.filter(w=>w.auditResult == '2');//退回修改意见
        if(result.length > 0){
          this.validateFormSend.get('auditIdeaReturnModify').setValue(result[0].auditIdea);
        }
      }
    });
  }

  /**
   * 获取大区、省份数据
   */
  getRegionProvinceInfo(){
    // 加载区域与省份数据
    const dataParams = {
      'size' : '10000',
      'sort': 'orderNum,asc'
    };
    zip(
      this.userService.queryRegionList(dataParams),
      this.userService.queryProvinceList(dataParams),
      this.projectReportService.getUserRegionAndProvince()
    ).subscribe(([regionRes,provinceRes, userDetails]) => {
      let regionDataList = regionRes.body;
      let provinceDataList = provinceRes.body;
      const regionProviceResult = userDetails.body;//当前登录用户的大区、省份数据
      if(regionProviceResult){
        let regionInfos = regionProviceResult['regionInfos'];//大区
        let provinces = regionProviceResult['provinces'];//省份
        
        //过滤大区
        if(regionInfos && regionInfos.length > 0){
          regionInfos = regionInfos.map(w=>w.id);
          regionDataList = regionDataList.filter(w=>regionInfos.includes(w.id));
        }
        //过滤省份
        if(provinces && provinces.length > 0){
          provinces = provinces.map(w=>w.id);
          provinceDataList = provinceDataList.filter(w=>provinces.includes(w.id));
        }
      }

      this.regionList = regionDataList;
      this.provinceResultList = provinceDataList;

      const control = this.validateForm.get("regionId");
      const provinceControl = this.validateForm.get("provinceId");
      
      if(this.isAdd){
        if(control && this.regionList.length == 1){
          control.setValue(this.regionList[0].id);
        }
        if(provinceControl && this.provinceResultList.length == 1){
          provinceControl.setValue(this.provinceResultList[0].id);
        }
      }else{
        if(control && this.record.regionId){
          control.setValue(this.record.regionId);
        }
        if(provinceControl && this.record.provinceId){
          provinceControl.setValue(this.record.provinceId);
        }
      }
    })
  }

  /**
   * 大区改变事件
   */
  fnChangeRegion(itemSelect){
    
    this.provinceList = [];

    if(itemSelect){
      let regionQueryList = this.regionList.filter(w=>w.id == itemSelect);
      let region = regionQueryList[0];
      
      let queryProvinces = this.provinceResultList.filter(w=>w.fkregionId == region.id);
      queryProvinces.forEach(element => {
        this.provinceList.push(element);
      });
      
      let control = this.validateForm.get("region");
      if(control){
        control.setValue(region.regionName);
      }

      control = this.validateForm.get("province");
      if(control){
        if(control.value == null || control.value == ''){
          control.setValue(this.record.province);
        }else{
          control.setValue('');
        }
      }

      control = this.validateForm.get("provinceId");
      if(control){
        if(control.value == null || control.value == ''){
          control.setValue(this.record.provinceId);
        }else{
          control.setValue('');
        }
      }
    }
  }

  fnChangeProvince(itemSelect){
    let items = this.provinceList.filter(w=>w.id === itemSelect);
    if(items && items.length > 0){
      let control = this.validateForm.get("province");
      if(control){
        control.setValue(items[0].provinceName);
      }
    }
  }

  fnChangeYear(item){
    
    this.batchList = [];
    this.prjTypeList = [];
  
    if(this.batchResultList){
      this.batchList = this.batchResultList.filter(w=>w.parentId && w.itemValue2 === item);
    }
    if(this.prjTypeResultList){
      this.prjTypeList = this.prjTypeResultList.filter(w=>w.parentId && w.itemValue2 === item);
    }

  }
  /**
   * 选择批次改变事件
   * @param item 
   */
  fnChangeBatch(item){
    if(!item){
        return;
    }
    let queryList = this.batchList.filter(w=>w.id == item);
    if(queryList && queryList.length > 0){
        let control = this.validateForm.get('batch');
        if(control){
            control.setValue(queryList[0].itemValue1);
        }
    }
  }
  /**
   * 选择项目类别改变事件
   * @param item 
   */
  fnChangePrjType(item){
    if(!item){
        return;
    }
    let queryList = this.prjTypeList.filter(w=>w.id == item);
    if(queryList && queryList.length > 0){
        let control = this.validateForm.get('projectType');
        if(control){
            control.setValue(queryList[0].itemValue1);
        }
    }
  }

  /**
   * 添加项目跟进人表单控件
   * @param i 
   */
  addFormControl(item = null) {
    // const newDataSet = [...this.dataSet];
    // newDataSet.splice(i, 0, { id: controlId, controlSort: controlId })
    this.prjFlowPersonMaxOrderNum++;
    let addId = this.prjFlowPersonMaxOrderNum ;
    let flowPersonObj = {
      id: addId,
      followPersonName:null,
      followPersonTel:null,
      remark:null
    };
    if(item){
      flowPersonObj = item;
    }else{
      this.prjFlowPersonList = [
        ...this.prjFlowPersonList,
        flowPersonObj
      ];
    }

    //const id = flowPersonObj.id + (!item ? '_addnew' : '');

    this.prjFlowPersonDataKeys.push(flowPersonObj.id);

    this.validateForm.addControl('followinfo_'+flowPersonObj.id+'_id', new FormControl(flowPersonObj.id, Validators.required));
    this.validateForm.addControl('followinfo_' + flowPersonObj.id+'_followPersonName', new FormControl(flowPersonObj.followPersonName, Validators.required));
    this.validateForm.addControl('followinfo_'+ flowPersonObj.id+'_followPersonTel', new FormControl(flowPersonObj.followPersonTel, Validators.required));
    this.validateForm.addControl('followinfo_'+flowPersonObj.id+'_remark', new FormControl(flowPersonObj.remark));
    this.validateForm.addControl('followinfo_' + flowPersonObj.id+'_orderNum', new FormControl(this.prjFlowPersonMaxOrderNum));

    // this.prjFlowPersonList.forEach(item => {
    //   this.validateForm.addControl('followinfo_'+flowPersonObj.id+'_id', new FormControl(flowPersonObj.id, Validators.required));
    //   this.validateForm.addControl('followinfo_' + flowPersonObj.id+'_followPersonName', new FormControl(flowPersonObj.followPersonName, Validators.required));
    //   this.validateForm.addControl('followinfo_'+ flowPersonObj.id+'_followPersonTel', new FormControl(flowPersonObj.followPersonTel, Validators.required));
    //   this.validateForm.addControl('followinfo_'+flowPersonObj.id+'_remark', new FormControl(flowPersonObj.remark));
    //   this.validateForm.addControl('followinfo_' + flowPersonObj.id+'_orderNum', new FormControl(this.prjFlowPersonMaxOrderNum));
    // });
  }

  removeFormControl(rid) {
    this.prjFlowPersonList = this.prjFlowPersonList.filter(item => {
      return item.id !== rid
    })
    this.prjFlowPersonDataKeys = this.prjFlowPersonDataKeys.filter(item => {
      return item !== rid
    })
    
    this.validateForm.removeControl('followinfo_'+rid+'_id');
    this.validateForm.removeControl('followinfo_'+rid+'_followPersonName');
    this.validateForm.removeControl('followinfo_'+rid+'_followPersonTel');
    this.validateForm.removeControl('followinfo_'+rid+'_remark');
    this.validateForm.removeControl('followinfo_'+rid+'_orderNum');
  }

  addOpportunity(item = null) {
    this.prjNicheMaxOrderNum++;
    let addId = this.prjNicheMaxOrderNum;
    let obj = {
      id: addId,
      estimateOrderDate:null,
      estimateOrderAmount:null,
      nicheDetails:null,
      remark:null,
      orderNum:null
    };
    if(item){
      obj = item;
    }else{
      this.prjNicheList = [
        ...this.prjNicheList,
        obj
      ];
    }

    this.prjNicheDataKeys.push(obj.id);

    this.validateForm.addControl('nicheInfo_' + obj.id+'_id', new FormControl(obj.id, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderDate', new FormControl(obj.estimateOrderDate, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_estimateOrderAmount', new FormControl(obj.estimateOrderAmount,Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_nicheDetails', new FormControl(obj.nicheDetails, Validators.required));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_remark', new FormControl(obj.remark));
    this.validateForm.addControl('nicheInfo_' + obj.id+'_orderNum', new FormControl(this.prjNicheMaxOrderNum));
 }

  removeOpportunity(rid) {
    this.prjNicheList = this.prjNicheList.filter(item => {
      return item.id !== rid
    })
    this.prjNicheDataKeys = this.prjNicheDataKeys.filter(item => {
      return item !== rid
    })
    
    this.validateForm.removeControl('nicheInfo_'+rid+'_id');
    this.validateForm.removeControl('nicheInfo_'+rid+'_estimateOrderDate');
    this.validateForm.removeControl('nicheInfo_'+rid+'_estimateOrderAmount');
    this.validateForm.removeControl('nicheInfo_'+rid+'_nicheDetails');
    this.validateForm.removeControl('nicheInfo_'+rid+'_remark');
    this.validateForm.removeControl('nicheInfo_'+rid+'_orderNum');
  }

  /**
   * 获取文件列表
   */
  getFileList(){
    if(!this.record.id || this.isAdd){
      this.showUploadListSwitch1 = false;
      this.showUploadListSwitch2 = false;
      this.showUploadListSwitch3 = false;
      this.showUploadListSwitch1 = true;
      this.showUploadListSwitch2 = true;
      this.showUploadListSwitch3 = true;
      return;
    }
    let queryParams = {
      "fileFk.equals" : this.record.id
    };
    this.thsadminFileService.queryFiles(queryParams).subscribe(res=>{
      this.fileList = res.body;
      this.showUploadListSwitch1 = false;
      this.showUploadListSwitch2 = false;
      this.showUploadListSwitch3 = false;
      this.fileList.forEach(item=>{
        item.uid = item.id;
        item.name = item.fileName;
        item.url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + item.id;
        
        if(item.extField1 === 'IucceProjectReport'){
          this.fileListXMS.push(item);
        }
        else if(item.extField1 === 'IucceProjectConstract'){
          this.fileListContract.push(item);
        }else if(item.extField1 === 'IucceProjectElse'){
          this.fileListElse.push(item);
        }
      });
      this.showUploadListSwitch1 = true;
      this.showUploadListSwitch2 = true;
      this.showUploadListSwitch3 = true;
    });
  }
  
  // 上传之前
  beforeUploadProjectReport = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('doc') || fileName.endsWith('docx') || fileName.endsWith('pdf');
    if(!isAllowUpload){
      this.msg.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.msg.error('文件不能大于100M');
      return false;
    }

    return true;
  }

  
  // 上传之前
  beforeUploadProjectContratct = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('doc') || fileName.endsWith('docx') || fileName.endsWith('pdf');
    if(!isAllowUpload){
      this.msg.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.msg.error('文件不能大于100M');
      return false;
    }

    return true;
  }

  // 上传之前
  beforeUploadProjectElse = (file: UploadFile): boolean => {
    const fileName = file.name.toLocaleLowerCase();
    const isAllowUpload = fileName.endsWith('xlsx') || fileName.endsWith('xls') || 
                          fileName.endsWith('doc') || fileName.endsWith('docx') || 
                          fileName.endsWith('pdf')  || fileName.endsWith('rar') || fileName.endsWith('zip');
    if(!isAllowUpload){
      this.msg.error('仅允许上传word,excel,pdf格式文件');
      return false;
    }
    
    const isUploadFileSize = file.size / 1024 / 1024 < 100;
    if (!isUploadFileSize) {
      this.msg.error('文件不能大于100M');
      return false;
    }
    
    return true;
  }
  

  // 上传文件参数
  fileParamProjectReport = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id.replace("add_",""),
      fileDesc: file.name,
      uploader: this.entityName,
      extField1 : 'IucceProjectReport',
      extField2 : 'IucceProject',
      extField3 : 'IucceProjectReport',//代表立项申报
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }

  // 上传文件参数
  fileParamProjectContract = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id.replace("add_",""),
      fileDesc: file.name,
      uploader: this.entityName,
      extField1 : 'IucceProjectConstract',
      extField2 : 'IucceProject',
      extField3 : 'IucceProjectReport',//代表立项申报
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }

  // 上传文件参数
  fileParamProjectElse = (file: UploadFile) => {
    const tmp = {
      name: file.name,
      fileFk: this.record.id.replace("add_",""),
      fileDesc: file.name,
      uploader: this.entityName,
      extField1 : 'IucceProjectElse',
      extField2 : 'IucceProject',
      extField3 : 'IucceProjectReport',//代表立项申报
      fileType: file.name.substring(file.name.indexOf('.') + 1)
    };
    return tmp;
  }
  handleUploadChange(item: any,param:any) {
    if(!this.isView){
      if (item.type === 'success') {
        const res = item.file.response;
        item.file.id = res.id;
        item.file.fileName = res.name;
      }else if(item.type === "removed" && 
        (item.file.id || (item.file.response != null && item.file.response.id != null && item.file.response.id != ''))){
        //删除文件
        const fileId = item.file.id ? item.file.id : item.file.response.id;
        this.projectReportService.deleteProjectFile(fileId).subscribe(res=>{
          if(res.status == 200){
            //this.fileListXMS = this.fileListXMS.filter(w=>w.id != fileId && w.response.id != fileId);
          }else{
            this.msg.error("删除文件失败！");
          }
        });
      }
    }
  }

  // showModal(): void {
  //   this.isVisible = true;
  // }

  // handleOk(): void {
  //   for (const i in this.validateForm.controls) {
  //     this.validateForm.controls[i].markAsDirty();
  //     this.validateForm.controls[i].updateValueAndValidity();
  //   }
  //   // this.isConfirmLoading = true;
  //   // setTimeout(() => {
  //   //   this.isVisible = false;
  //   //   this.isConfirmLoading = false;
  //   // }, 3000);
  // }

  // handleCancel(): void {
  //   this.isVisible = false;
  // }

  
  submitForm(isSubmit) {
    this.loading = true;
    let recordInfo = Object.assign({},this.record);
    let followinfoControls = [];
    let nicheControls = [];
    for (const i in this.validateForm.controls) {
      const control = this.validateForm.controls[i];
      if(isSubmit){
        control.markAsDirty();
        control.updateValueAndValidity();
      }else{
        if(i == 'region' || i == "province" || i == 'schoolName' || i == 'projectName' || (i == 'delayEndDate' && this.validateForm.get('isDelay').value == '1')){
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
      if(i.indexOf('followinfo_') != -1){
        control['name'] = i;
        followinfoControls.push(control);
      }else if(i.indexOf("nicheInfo_") != -1){
        control['name'] = i;
        nicheControls.push(control);
      }
      
      recordInfo[i] = control.value;
    }
      debugger;
    if((isSubmit && this.validateForm.invalid) || 
      ((this.validateForm.get('region').invalid || this.validateForm.get('province').invalid || 
        this.validateForm.get('schoolName').invalid || this.validateForm.get('projectName').invalid || 
        (this.validateForm.get('isDelay').value == '1' && this.validateForm.get('delayEndDate').invalid)))){
      this.loading = false;
      this.msg.error("请检查信息是否完整!");
      return;
    }
  
    if(isSubmit){
      //附件上传验证
      if(!this.fileListXMS || this.fileListXMS.length <= 0){
        this.loading = false;
        this.msg.error("请上传项目申报书附件!");
        return;
      }
    }
    

    this.prjFlowPersonDataKeys.forEach(idValue=>{
      let selControls = followinfoControls.filter(item=> item.name.indexOf('followinfo_'+idValue+"_") != -1 );
      if(selControls && selControls.length > 0){
        let data = {};
        selControls.forEach(control => {
          const field = control.name.replace('followinfo_'+idValue+'_','');
          data[field] = control.value;
        })
        this.prjFlowPersonDatas.push(data);
      }
    });
    this.prjNicheDataKeys.forEach(idValue=>{
      let selControls = nicheControls.filter(item=> item.name.indexOf('nicheInfo_'+idValue+"_") != -1);
      if(selControls && selControls.length > 0){
        let data = {};
        selControls.forEach(control => {
          const field = control.name.replace('nicheInfo_'+idValue+'_','');
          data[field] = control.value;
        })
        this.prjNicheDatas.push(data);
      }
    });
    
    this.saveData(recordInfo,isSubmit);
  }

  saveData(recordInfo,isSubmit) {
    //保存数据
    if(isSubmit){
      this.submitting = true;
    }else{
      this.saveing = true;
    }
    const _this_ = this;
    let flowPersonList = [];
    let nicheList = [];
    this.prjFlowPersonDatas.forEach(item=>{
      if(typeof item === 'object'){
        flowPersonList.push(item);
      }
    })
    this.prjNicheDatas.forEach(item=>{
      if(typeof item === 'object'){
        nicheList.push(item);
      }
    })
    
    return new Promise(function (resolve) {
       if (!isSubmit) {
        _this_.projectReportService.saveData(recordInfo,flowPersonList,nicheList)
          .subscribe(
            (response) => {
              _this_.loading = false;
              _this_.msg.success('保存成功');
              _this_.submitting = false;
              _this_.saveing = false;
              _this_.modal.close(true);
              resolve('保存成功');
            },
            (resError) => {
              _this_.loading = false;
              _this_.msg.success('保存失败');
              _this_.submitting = false;
              _this_.saveing = false;
              resolve('保存失败');
            }
          );
      } else {
        let param = WorkFlowHelper.getWorkFlowParams('IucceProjectReport',recordInfo);
        _this_.projectReportService.submitData(recordInfo,param,flowPersonList,nicheList)
          .subscribe((response) => {
            _this_.loading = false;
            _this_.msg.success('提交成功');
            _this_.submitting = false;
            _this_.saveing = false;
            _this_.modal.close(true);
            resolve('提交成功');
          },
          (resError) => {
            _this_.loading = false;
            _this_.msg.success('提交失败');
            _this_.submitting = false;
            _this_.saveing = false;
            resolve('提交失败');
          }
        );
      }
    });
  }

  close(){
    this.modal.close(false);
  }

}
