import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalRef, UploadFile } from 'ng-zorro-antd';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BimPersonResultEditShowInfoComponent } from "./show-info/show-info.component";
@Component({
  selector: 'app-bim-bimPerson-result-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimPersonResultEditComponent implements OnInit {

  public bimInfoEventsId = '';
  
  public loading = false;
  public uploadIsVisible = true;
  // 导入 excel
  public excelFile: UploadFile[] = [];
  // 是否上传中
  public excelUploading = false;
  // 导入接口
  public importUrl = '';

  public isAdd = false;//true 为增量导入
 

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private modalHelp: ModalHelper,
    private nzModalRef: NzModalRef,
    private fileUploadService: FileUploadService,
    private httpAddressService: HttpAddressService,
  ) {
    
   
  }

  ngOnInit(): void {

       if(this.isAdd === true)
       {
        this.importUrl = this.httpAddressService.BimServe + '/bim-person-results/importPersonResultAdd';
       }else{
        this.importUrl = this.httpAddressService.BimServe + '/bim-person-results/importPersonResult';
       }

       console.log(this.importUrl );
  }

  close() {
    this.nzModalRef.destroy();
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }

  // 打开上传弹框
  openUploadnModal() {
    this.uploadIsVisible = true;
  }

  // 关闭上传弹框
  handleCancel() {
    this.excelFile = [];
    this.uploadIsVisible = false;
    this.excelUploading = false;

    this.close();
  }

  // 上传之前
  beforeUpload = (file: UploadFile): boolean => {
    const isXlsx = file.name.endsWith('xlsx') || file.name.endsWith('xls');
    if (!isXlsx) {
      this.msg.error('仅允许上传xlsx或xls格式文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      this.msg.error('文件不能大于50M');
    }
    if (isXlsx && isLt2M) {
      this.excelFile.push(file);
    }
    return false;
  }

  // 导入个人参赛成绩信息
  handleUpload(): void {
    const formData = new FormData();
    this.excelFile.forEach((file: any) => {
      formData.append('file', file);
    });
    if (this.excelFile.length === 0) {
      this.msg.remove();
      this.msg.info('请选择导入文件！');
      return;
    }

    formData.append('eventId', this.bimInfoEventsId);
    this.excelUploading = true;
    const req = new HttpRequest('POST', this.importUrl, formData, {});
    this.http
      .request(req)
      .pipe(filter(e => e instanceof HttpResponse))
      .subscribe(
        (event: any) => {
          const result = event.body;
          this.excelUploading = false;
          if (result.success === false) {
            this.msg.warning('导入失败:' + result.message);
          } else {
            this.msg.success('导入成功');
            
          }

          //弹出导入记录
          if(result.importMessage){

            this.modalHelp.static(BimPersonResultEditShowInfoComponent, {  messageList: result.importMessage }, 700)
            .subscribe(() => {
           
            });

          
          }

          this.handleCancel();
          this.excelFile = [];
        },
        err => {
          this.excelUploading = false;
          this.msg.error('upload failed.');
        
        }
      );
  }

}
