import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ThsadminFileService } from "@shared/components/f-upload/thsadmin-file.service";
import { HttpAddressService } from "@shared/session/http-address.service";
import { NzMessageService } from "ng-zorro-antd";
import { BimWorks } from "../../../work-manage/work-manage.model";
import { WorkManageService } from "../../../work-manage/work-manage.service";
import { FileUploadService } from "@shared/components/fileUpload/fileUpload.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-work-upload',
  templateUrl: './work-upload.component.html',
  styles: []
})
export class WorkUploadComponent implements OnInit, OnChanges {
  @ViewChild('fUpload1') fUpload1: any;
  @ViewChild('fUpload2') fUpload2: any;
  @ViewChild('fUpload3') fUpload3: any;
  @ViewChild('fUpload4') fUpload4: any;
  
  @Input() title: string = '';
  @Input() fileFk: string = '';
  @Input() schoolId: string = '';
  @Input() teamId: string = '';
  @Input() itemName: string = '';
  @Input() itemId: string = '';

  public workDocLoadParam = {
    'extField1.equals': 'team_works_word'
  };
  public workDocExtParam = {
    'extField1': 'team_works_word'
  };
  public workDocList = [];

  public workPptLoadParam = {
    'extField1.equals': 'team_works_ppt'
  };
  public workPptExtParam = {
    'extField1': 'team_works_ppt'
  };
  public workPptFileType = ['.ppt','.pptx','.PPT','.PPTX'];
  public workPptList = [];

  public workImageLoadParam = {
    'extField1.equals': 'team_works_image'
  };
  public workImageExtParam = {
    'extField1': 'team_works_image'
  };
  public workImageFileType = ['.bmp','.jpg','.jpeg','.png','.gif','.BMP','.JPG','.JPEG','.PNG','.GIF'];
  public workImageList = [];

  public workVideoLoadParam = {
    'extField1.equals': 'team_works_video'
  };
  public workkVideoExtParam = {
    'extField1': 'team_works_video'
  };
  public workVideoFileType = ['.mp4','.MP4'];
  public workVideoList = [];
  
  worksId: string = '';
  works: BimWorks = new BimWorks();
  type: number = 0;
  validateForm: FormGroup;
  check = false;

  public isLoadFile = false;

  public isEditable = true;

  public isBigFile = true;
  
  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private thsadminFileService: ThsadminFileService,
    private httpAddressService: HttpAddressService,
    private workManageService: WorkManageService,
    private fileUploadService: FileUploadService,
  ) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required,Validators.maxLength(50)]],
      nickname: [null,[Validators.maxLength(200)]],
      workSubmitStatus: ['1',[Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schoolId && changes.schoolId.currentValue && changes.itemId && changes.itemId.currentValue) {
      this.getWorks();
    }
  }
  

  getWorks() {
    const params = {
      'teamId.equals': this.teamId,
      'schoolId.equals': this.schoolId,
      'itemId.equals': this.itemId,
      page: 0,
      size: 1
    };
    this.workManageService.query(params).subscribe(res => {
      this.isLoadFile = true;
      if (res && res.body && res.body.length === 1) {
        this.validateForm.setValue({
          name: res.body[0].name,
          nickname: res.body[0].introduction,
          workSubmitStatus: res.body[0].workSubmitStatus + ''
        });
        this.worksId = res.body[0].id;
        this.works = res.body[0];
        this.fileFk = res.body[0].id;
      } else {
        this.validateForm.setValue({
          name: null,
          nickname: null,
          workSubmitStatus: '1'
        });
        this.worksId = '';
        this.works = new BimWorks();
      }
    })
  }

  submitForm() {
    this.check = false;
    const status = this.validateForm.get('workSubmitStatus').value;
    // 启用 则开启校验
    if (status == '1') {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[ i ].markAsDirty();
        this.validateForm.controls[ i ].updateValueAndValidity();
      }
      if (this.validateForm.status === "VALID") {
        this.workDocList = this.fUpload1.dataSet.filter(e => !isNullOrUndefined(e.id));
        this.workImageList = this.fUpload2.dataSet.filter(e => !isNullOrUndefined(e.id));
        this.workPptList = this.fUpload3.dataSet.filter(e => !isNullOrUndefined(e.id));
        this.workVideoList = this.fUpload4.dataSet.filter(e => !isNullOrUndefined(e.id));
        console.log(this.workImageList);
        if (!this.workDocList || this.workDocList.length === 0) {
          this.msg.warning('请上传' + this.itemName + '作品资料！');
          return;
        }
        if (!this.workImageList || this.workImageList.length === 0) {
          this.msg.warning('请上传' + this.itemName + '图片！');
          return;
        } else {
          // this.workImageList.forEach(data => {
          //   this.fileUploadService.update(data).subscribe(res => {
          //   });
          // })
        }
        if (!this.workPptList || this.workPptList.length === 0) {
          this.msg.warning('请上传' + this.itemName + 'PPT！');
          return;
        }
        if (!this.workVideoList || this.workVideoList.length === 0) {
          this.msg.warning('请上传' + this.itemName + '视频！');
          return;
        } else {
          console.log(this.workVideoList);
          const data = this.workVideoList.filter(e => isNullOrUndefined(e.videoCoverId));
          if (data && data.length > 0) {
            this.msg.warning('请上传' + this.itemName + ':' + data[0].fileName + '视频封面！');
            return;
          } else {
            // this.workVideoList.forEach(data => {
            //   this.fileUploadService.update(data).subscribe(res => {});
            // });
          }
        }
        this.check = true;
      }
    } else {
      this.check = true;
    }
    this.works.name = this.validateForm.get('name').value;
    if (this.validateForm.get('nickname')) {
      this.works.introduction = this.validateForm.get('nickname').value || '';
    }
    this.works.workSubmitStatus = this.validateForm.get('workSubmitStatus').value;
    if (!this.worksId || this.worksId.length === 0) {
      this.works.itemId = this.itemId;
      this.works.schoolId = this.schoolId;
      this.works.teamId = this.teamId;
      this.works.workType = this.type;
      // this.works.workSubmitStatus = 1;
    }
  }

}